import Popup from '@components/Popup'
import {
  HAVENT_FINISHED_VIDEOS,
  HAVENT_WATCHED_VIDEO,
  REQUIRES_SUBSCRIBE_MESSAGE,
  SUBSCRIBE_NOW_MESSAGE,
  THIS_CONTENT_MESSAGE,
  UNLOCK_CONTENT_MESSAGE,
  UNLOCK_ONE_DAY,
  UNLOCK_TWO_DAYS,
  moreThanThreeDays,
} from '@src/constants/messages'
import { FC } from 'react'
import { PopupType } from './EpisodeHome'

interface CustomPopupProps {
  onPopupClose: () => void
  visible: boolean
  days?: number
  popupType: PopupType
}

export const CustomPopup: FC<CustomPopupProps> = (props) => {
  let title = ''
  let message = ''
  let accepLink = ''
  let buttonText = ''

  switch (props.popupType) {
    case 'locked':
      message = HAVENT_WATCHED_VIDEO
      break
    case 'watched':
      message = HAVENT_FINISHED_VIDEOS
      break
    case 'pending':
      title =
        props?.days === 1
          ? UNLOCK_ONE_DAY
          : props?.days === 2
          ? UNLOCK_TWO_DAYS
          : moreThanThreeDays(props?.days)
      message = UNLOCK_CONTENT_MESSAGE
      break
    case 'subscribed':
      title = THIS_CONTENT_MESSAGE
      message = REQUIRES_SUBSCRIBE_MESSAGE
      accepLink = '/parent?redirect=subscribe'
      buttonText = SUBSCRIBE_NOW_MESSAGE
      break
  }

  return (
    <Popup
      acceptLink={accepLink}
      buttonText={buttonText}
      message={message}
      onClose={props.onPopupClose}
      title={title}
      visible={props.visible}
    />
  )
}
