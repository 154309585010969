import { FC, ReactNode } from 'react'

interface TextMarkerEffectProps {
  children: ReactNode
  color?: 'orange' | 'green'
}

const DEFAULT_COLOR = 'green'

const TextMarkerEffect: FC<TextMarkerEffectProps> = ({
  children,
  color = DEFAULT_COLOR,
}) => {
  const background =
    color === 'orange' ? `before:bg-[#F29704]` : `before:bg-[#AAD742]`
  return (
    <span
      className={`relative w-fit before:h-2 before:absolute before:-inset-x-1 before:inset-y-[0.15rem] before:translate-y-3.5 text_marker_effect ${background} before:bg-opacity-20`}
    >
      {children}
    </span>
  )
}

export default TextMarkerEffect
