import useSwr from 'swr'
import { IEpisode } from '@src/types/api.interface'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { FetchError } from '@utils/FetchError'

export function useEpisode(props: { slug: string }) {
  const { kidProfile: profile } = useProfile()

  const url =
    profile?.level && profile._id
      ? `/level/${profile.level}/episodes/${props.slug}/${profile._id}/chapters`
      : null

  const { data, error, mutate } = useSwr<IEpisode, FetchError>(url)

  return {
    episode: data,
    error,
    isLoadingEpisode: !error && !data,
    mutateEpisode: mutate,
  }
}
