import {
  INotificationManager,
  NotificationManager,
} from './../../utils/notification.manager'
import { Capacitor } from '@capacitor/core'

export function usePushNotificationsV2(): INotificationManager {
  switch (Capacitor.getPlatform()) {
    case 'web':
      return NotificationManager.getInstance('web')
    case 'android':
      return NotificationManager.getInstance('android')
    case 'ios':
      return NotificationManager.getInstance('ios')
    default:
      throw Error('Push Notifications: Platform not supported')
  }
}
