import buildQueryString from '@src/helpers/apis/buildQueryString'
import {
  IChallengeRankData,
  ICompetitionNewType,
} from '@src/types/api.interface'
import { FetchError } from '@utils/FetchError'
import { useMemo } from 'react'
import useSWR from 'swr'

interface ICombinedRankData extends ICompetitionNewType, IChallengeRankData {}

export function useCompetitionRank(
  type: 'challenge' | 'competition',
  kidId: string,
  challengeId: string,
  pageNumber: number
): {
  data?: ICombinedRankData
  error?: FetchError
  isLoadingKids: boolean
} {
  const params = {
    type,
    kidId,
    challengeId,
    page: pageNumber,
    enableDate: true,
  }

  const queryString = buildQueryString(params)
  const url = `/challenges/ranks${queryString}`

  const { data, error } = useSWR<ICombinedRankData, FetchError>(url)

  return useMemo(
    () => ({
      data: Array.isArray(data) ? data[0] : data,
      error,
      isLoadingKids: !data && !error,
    }),
    [data, error]
  )
}
