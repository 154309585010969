import { FC } from 'react'
import { IAnswer, IQuestionWithAnswers } from '@src/types/api.interface'
import { ChallengeReducers } from '@hooks/use-challenge-reducer'
import {
  QuestionAnswer,
  QuestionGoNext,
  QuestionSubmit,
  QuestionText,
} from './challenge-controls'

export const ChallengeWithTextOnly: FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  question: IQuestionWithAnswers | any
  challengeReducers: ChallengeReducers
}> = (props) => {
  return (
    <div className="flex flex-col justify-between h-full min-h-screen pt-16 challengeWTxt-container">
      <div className="mt-2 sm:-mt-4 flex-1 flex justify-center items-center challengeWTxt-question-container">
        {props.question ? <QuestionText question={props.question} /> : null}
      </div>

      <div className="flex-1 challengeWTxt-answer-container">
        <div className="max-w-3xl mx-auto mt-4 md:mt-8 px-2 sm:px-4 grid gap-2 sm:gap-4 grid-cols-2 challengeWTxt-answer-wrapper">
          {props.question?.answers.map((answer: IAnswer, index: number) => {
            return (
              <QuestionAnswer
                answer={answer}
                isCorrect={
                  props.challengeReducers.state.correctAnswer === index
                }
                isSelected={
                  props.challengeReducers.state.selectedAnswer === index
                }
                key={answer.text}
                onClick={() => props.challengeReducers.onSelectAnswer(index)}
                showAnswer={
                  typeof props.challengeReducers.state.correctAnswer ===
                  'number'
                }
              />
            )
          })}
        </div>
      </div>
      <div className="flex-1 mb-4 challengeWTxt-question-submit-container">
        <div className="flex justify-center challengeWTxt-question-submit-wrapper">
          {props.challengeReducers.state.correctAnswer === null ? (
            <QuestionSubmit
              disabled={
                props.challengeReducers.state.selectedAnswer === null ||
                props.challengeReducers.state.submitting
              }
              onClick={props.challengeReducers.onSubmit}
            />
          ) : (
            <QuestionGoNext onClick={props.challengeReducers.goNext} />
          )}
        </div>
      </div>
    </div>
  )
}
