import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { ICompetitionChallenge } from '@src/types/api.interface'
import { FetchError } from '@utils/FetchError'
import { useMemo } from 'react'
import useSWR from 'swr'

export function useGetChallenges(competitionId: string): {
  challenges?: ICompetitionChallenge[]
  error?: FetchError
  isLoadingChallenges: boolean
} {
  const { kidProfile: profile } = useProfile()

  const url = `/challenges/all/scored/${competitionId}/kid/${profile._id}`

  const { data, error } = useSWR<ICompetitionChallenge[], FetchError>(url)

  return useMemo(
    () => ({
      challenges: data,
      error,
      isLoadingChallenges: !data && !error,
    }),
    [data, error]
  )
}
