import { IChallengeNew } from '@src/types/api.interface'
import { topAngle, pointIcon } from '../TopCompetitor'

interface ScoreBoardListItemProps {
  kid: IChallengeNew
  index: number
  kidId: string
  isKidInFirstFive: boolean
}

export const ScoreBoardListItem: React.FC<ScoreBoardListItemProps> = ({
  kid,
  kidId,
  isKidInFirstFive,
}) => {
  if (!kid) return
  return (
    <>
      <div
        className={`score_board_list_item-container-${kid.id} ${
          kidId === kid?.id ? `bg-v3-gray-150` : `bg-v3-brown-700`
        } h-[51px] w-[438px] xl:w-[572px] relative rounded-2xl mb-2.5 shadow-[0px_4px_1px_rgba(0,0,0,0.25)]`}
      >
        <div
          className={`score_board_list_item-wrapper-${kid.id} ${
            kidId === kid?.id ? `bg-white` : 'bg-v3-brown-600'
          } absolute left-0 translate-x-1.5 -top-0.5 w-[428px] h-12  xl:w-[560px] xl:h-[49px] rounded-2xl  flex justify-between px-1.5 items-center`}
        >
          <img
            alt=""
            className={`score_board_list_item-top-angle-img-${kid.id} absolute z-10 top-0 right-0`}
            src={topAngle}
          />

          <div
            className={`score_board_list_item-kid-rank-wrapper-${kid.id} flex items-center gap-x-2.5`}
          >
            <div
              className={`score_board_list_item-kid-rank-${kid.id} ${
                kidId === kid?.id
                  ? `bg-v3-green-800 text-v3-blue-500`
                  : `bg-v3-sky-blue text-white`
              } flex items-center justify-center z-20 rounded-full h-[26px] w-[26px] text-center text-[22px]`}
            >
              {kid?.rank}
            </div>
            <p
              className={`score_board_list_item-kid-nickname-${
                kid.id
              } z-20 text-[22px] ${
                kidId === kid.id ? `text-v3-blue-500` : `text-v3-red-100`
              }`}
            >
              {kid?.nickname}
            </p>
          </div>
          <div
            className={`score_board_list_item-kid-score-wrapper-${kid.id} flex gap-x-2.5`}
          >
            <p
              className={`score_board_list_item-kid-score-${kid.id} text-v3-red-100`}
            >
              {kid?.score}
            </p>
            <img
              alt="Point icon"
              className={`score_board_list_item-point-icon-${kid.id}`}
              src={pointIcon}
            />
          </div>
        </div>
      </div>

      {kidId === kid?.id && !isKidInFirstFive ? (
        <div className="score_board_list_item-current-kid-divider border-dashed border-b-2 border-v3-gray-450 h-1 w-full mb-2.5" />
      ) : null}
    </>
  )
}
