import { AppBar, AppContent } from '@components/Layout'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import {
  LockedVideoOverlay,
  PlayVideoOverlay,
} from '@components/Overlay/OverlayButton'
import Popup from '@components/Popup'
import { useAnonymous } from '@contexts/AnonymousContext/AnonymousContext'
import { useEpisodeInSeries } from '@hooks/data/fetchers/use-episodes-in-series'
import {
  REGISTER_NOW_MESSAGE,
  REQUIRES_REGISTRATION_MESSAGE,
  SELECTED_EPISODES,
} from '@src/constants/messages'
import { IEpisodeListItem, ISeries } from '@src/types/api.interface'
import Helper from '@utils/Helpers'
import React, { FC, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation, useParams } from 'react-router-dom'

interface LocationState {
  seriesData: ISeries
}

const SeriesAnonymous: FC = () => {
  const location = useLocation<LocationState>()
  const { logout } = useAnonymous()
  const [seriesData] = useState<ISeries>(location.state.seriesData ?? null)
  const { episodes, isLoadingEpisodes, error } = useEpisodeInSeries(
    seriesData._id
  )
  if (error.hasOwnProperty('status') && error?.status === 401) {
    logout()
  }

  const [isVisable, setIsVisable] = useState(false)

  return (
    <>
      <Helmet>
        <title>عالم زيدو - الصفحة الرئيسية من المستوى 1</title>
      </Helmet>
      {isLoadingEpisodes ? <AppProgressBar /> : null}
      <AppBar fixed hideToggleButton title={seriesData.title} />
      <AppContent bgDecoration className="pages_anonymous_series_appcontent">
        <div className="flex flex-row items-center pages_anonymous_series_div_container h-[70vh] md:h-[60vh] lg:h-full lg:justify-center px-4 sticky lg:top-[14%]">
          <div className="flex flex-col text-[#003351] ml-2 flex-shrink-0 pb-6 lg:pb-8">
            <span className="text-base sm:text-lg lg:text-3xl font-semibold pages_anonymous_series_title_text">
              {seriesData.title}
            </span>
            <span className="text-sm lg:text-2xl pages_anonymous_series_selected_text">
              {SELECTED_EPISODES}
            </span>
          </div>
          <div className="flex flex-row pages_anonymous_series_episode_container">
            {!isLoadingEpisodes
              ? episodes.map((oneEpisode, index) => {
                  return (
                    oneEpisode.isAnon && (
                      <EpisodeThumbnail
                        bigger
                        data={oneEpisode}
                        key={index}
                        locked={false}
                      />
                    )
                  )
                })
              : null}
          </div>
        </div>
        {!isLoadingEpisodes ? (
          <EpisodeSlider
            episodes={episodes.filter((a) => !a.isAnon)}
            setIsVisible={setIsVisable}
          />
        ) : null}
      </AppContent>
      <Popup
        acceptLink="/login"
        buttonText={REGISTER_NOW_MESSAGE}
        message={REQUIRES_REGISTRATION_MESSAGE}
        onClose={() => {
          setIsVisable(false)
        }}
        title=""
        visible={isVisable}
      />
    </>
  )
}

export default SeriesAnonymous

const EpisodeSlider: FC<{
  episodes: IEpisodeListItem[]
  setIsVisible: (isVisible: boolean) => void
}> = ({ episodes, setIsVisible }) => {
  return episodes.length ? (
    <div className="flex flex-row pages_anonymous_series_episode_slider_container overflow-x-auto absolute bottom-0 w-full bg-[#A9DBE7] pt-3 lg:pt-4">
      {episodes?.map((oneEpisode, index) => {
        return (
          <EpisodeThumbnail
            data={oneEpisode}
            key={index}
            locked={true}
            setIsVisible={setIsVisible}
          />
        )
      })}
    </div>
  ) : (
    <div className="text-[#434343] pages_anonymous_series_episode_slider_placeholder flex items-center justify-center h-1/3 lg:h-1/4 absolute bottom-0 bg-[#A9DBE7] w-full">
      empty
    </div>
  )
}

const EpisodeThumbnail: FC<{
  data: IEpisodeListItem
  setIsVisible?: (isVisible: boolean) => void
  locked?: boolean
  bigger?: boolean
}> = (props) => {
  const { level } = useParams<{ level: string }>()

  const url = `/anonymous-episode/anonymous-chapters/${level}/${props.data.slug}`

  const locked = props.locked

  const size = props.bigger
    ? 'w-[120px] sm:w-[130px] md:w-[140px] lg:w-[250px] aspect-square'
    : 'w-[70px] sm:w-[90px] md:w-[105px] lg:w-[200px] aspect-square'
  return (
    <div className="flex pages_anonymous_series_episode_thumb_container flex-col items-center mx-2 sm:mb-1 sm:mt-1 lg:mt-3 lg:first:mr-auto lg:last:ml-auto">
      <div
        className={`relative ${size} border-4 border-opacity-[15%] border-black rounded-2xl`}
      >
        {locked ? (
          <LockedVideoOverlay rounded setIsVisable={props.setIsVisible} />
        ) : null}
        <Link
          className="pages_anonymous_series_episode_thumb_link_container"
          to={locked ? '' : url}
        >
          <img
            alt={props.data.slug}
            className="object-cover w-full h-full rounded-xl pages_anonymous_series_episode_thumb_link_img"
            draggable="false"
            src={Helper.MediaURL(props.data.thumbnail)}
          />
          {!locked ? <PlayVideoOverlay /> : null}
        </Link>
      </div>
      <span className="text-[#434343] text-base lg:text-xl truncate text-center py-1 pages_anonymous_series_thumb_link_text">
        {props.data.title}
      </span>
    </div>
  )
}
