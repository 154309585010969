import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { Toast } from '@capacitor/toast'
import { useEffect } from 'react'
import { GtagEvents } from '@utils/gtag-events'

const EXIT_MESSAGE = 'إضغط مرة أخرى لإغلاق التطبيق'

const SECOND_CLICK_DELAY = 3000

export function useCloseAppBackButton(): unknown {
  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return
    if (Capacitor.getPlatform() === 'ios') return
    let numberOfClick = 0
    let timeout: number
    const listener = App.addListener('backButton', () => {
      numberOfClick += 1
      if (numberOfClick === 1) {
        void Toast.show({
          duration: 'short',
          text: EXIT_MESSAGE,
        })
        timeout = window.setTimeout(() => {
          numberOfClick = 0
        }, SECOND_CLICK_DELAY)
      }

      if (numberOfClick === 2) {
        if (timeout) {
          window.clearTimeout(timeout)
        }
        numberOfClick = 0
        GtagEvents.closeApp({ type: 'back-button' })
        App.exitApp()
      }
    })

    return () => {
      listener.remove()
      if (timeout) {
        window.clearTimeout(timeout)
      }
    }
  }, [])

  return null
}
