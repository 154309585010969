/* eslint-disable @typescript-eslint/no-explicit-any */
import { sendGaToFirebase } from './sendGaToFirebase'

export function selectPaymentPlan(props: { identifier: string }) {
  const event: any = {
    event: 'select_payment_plan',
    eventCategory: 'Payment',
    eventAction: 'Select payment plan',
    eventLabel: `Identifier ${props.identifier}`,
  }
  window.dataLayer?.push(event)
  sendGaToFirebase(event)
}

export function paymentSuccess(props: { identifier: string }) {
  const event: any = {
    event: 'payment_success',
    eventCategory: 'Payment',
    eventAction: 'Payment success',
    eventLabel: `Identifier ${props.identifier}`,
  }
  window.dataLayer?.push(event)
  sendGaToFirebase(event)
}

export function paymentFailed(props: { identifier: string }) {
  const event: any = {
    event: 'payment_failed',
    eventCategory: 'Payment',
    eventAction: 'Payment failed',
    eventLabel: `Identifier ${props.identifier}`,
  }
  window.dataLayer?.push(event)
  sendGaToFirebase(event)
}

export function paymentCancelled(props: { identifier: string }) {
  const event: any = {
    event: 'payment_cancelled',
    eventCategory: 'Payment',
    eventAction: 'Payment cancelled',
    eventLabel: `Identifier ${props.identifier}`,
  }
  window.dataLayer?.push(event)
  sendGaToFirebase(event)
}

export function selectSubscribePlan(props: { identifier: string }) {
  const event: any = {
    event: 'select_subscribe_plan',
    eventCategory: 'Payment',
    eventAction: 'Select Plan',
    eventLabel: `Identifier ${props.identifier}`,
  }
  window.dataLayer?.push(event)
  sendGaToFirebase(event)
}

/**
 *
 * @param props {type: string, overwrite: boolean}
 * @description when overwrite is true, the type becomes the event
 */
export function paymentBy(props: { type: string; overwrite?: boolean }) {
  const event: any = {
    event: props.overwrite ? props.type : 'payment_by',
    eventCategory: 'Payment',
    eventAction: 'Payment by',
    eventLabel: `Type ${props.type}`,
  }
  window.dataLayer?.push(event)
  sendGaToFirebase(event)
}
