import { ApexOptions } from 'apexcharts'
import { TEXT } from './data'

const labels = [TEXT.ACTIVITIES_WATCHING_TIME, TEXT.VIDEOS_WATCHING_TIME]
export const getChartOptions = (): ApexOptions => {
  return {
    colors: ['#57599C', '#70D8CC'],
    chart: {
      height: 150,
      width: '100%',
      type: 'pie',
    },
    stroke: {
      colors: ['#DEEBF5', '#DEEBF5'],
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -20,
        },
      },
    },
    labels,
    dataLabels: {
      enabled: false,
      style: {
        fontFamily: 'Inter, sans-serif',
      },
    },
    legend: {
      show: false,
      position: 'bottom',
      fontFamily: 'Inter, sans-serif',
    },
    yaxis: {
      labels: {
        formatter: (value) => value + '%',
      },
    },
    xaxis: {
      labels: {
        formatter: (value) => value + '%',
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
  }
}
