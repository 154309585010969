import { useCallback, useMemo } from 'react'
import useSWR, { KeyedMutator } from 'swr'
import { IUserAnswer } from '@src/types/api.interface'
import { FetchError } from '@utils/FetchError'
import HelperAPI from '@utils/helpers/helper.api'

export function useUserAnswers(challengeName: string | undefined): {
  userAnswers?: IUserAnswer[]
  error?: FetchError
  isLoadingUserAnswers: boolean
  mutate: KeyedMutator<IUserAnswer[]>
} {
  const url = challengeName ? `/competitions/${challengeName}/answers` : null

  const { data, error, mutate } = useSWR<IUserAnswer[], FetchError>(url)

  return useMemo(
    () => ({
      userAnswers: data,
      error,
      isLoadingUserAnswers: !!url && !data && !error,
      mutate,
    }),
    [data, error, mutate, url]
  )
}

export function useSubmitAnswer(challengeName: string | undefined) {
  const { mutate } = useUserAnswers(challengeName)

  return useCallback(
    async (props: { answer: number; questionNumber: number }) => {
      const url = `/competitions/${challengeName}/answer`
      const res = await HelperAPI.ApiRequest<{
        addedPoints: number
        total: number
        correctAnswer: number
      }>(url, {
        method: 'POST',
        body: JSON.stringify({
          questionNumber: props.questionNumber,
          answer: props.answer,
        }),
      })
      await mutate()

      return res
    },
    [challengeName, mutate]
  )
}

export function useSubmitScore(challengeName: string | undefined) {
  const { mutate } = useUserAnswers(challengeName)

  return useCallback(
    async (props: { score: number; questionNumber: number }) => {
      const url = `/competitions/${challengeName}/game-score`
      const res = await HelperAPI.ApiRequest<{
        addedPoints: number
        total: number
      }>(url, {
        method: 'POST',
        body: JSON.stringify({
          questionNumber: props.questionNumber,
          score: props.score,
        }),
      })
      await mutate()

      return res
    },
    [challengeName, mutate]
  )
}
