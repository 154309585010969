import { Capacitor } from '@capacitor/core'
import { Keyboard } from '@capacitor/keyboard'
import { ContentTitle } from '@components/ContentTitle'
import { AppBar, AppContent } from '@components/Layout'
import NUMBER_ARABIC from '@src/constants/number'
import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory, useLocation } from 'react-router-dom'
import { useInAppBrowser } from '@hooks/use-inapp-browser'
import { Profile } from '@src/types/api.interface'

const ParentalGateway = () => {
  const [backspace, setBackspace] = useState(false)
  const [pinCode, setPinCode] = useState([''])
  const [pinValue, setPinValue] = useState(new Array(4).fill(''))
  const [checker, setChecker] = useState(false)
  const history = useHistory()
  const search = useLocation().search
  const state = useLocation().state as {
    type: 'openBrowser' | 'local'
    link: string
    from: string
    kid: Profile
    popUpType: string
  }
  const openBrowser = useInAppBrowser()

  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  useEffect(() => {
    const rand = random4DigitNumber()
    setPinCode(rand.toString().split(''))
  }, [setPinCode])

  useEffect(() => {
    const platform = Capacitor.getPlatform()
    if (platform === 'ios') {
      Keyboard.setScroll({ isDisabled: true })
    }
  })

  useEffect(() => {
    if (searchParams.get('redirect') === 'subscribe' && checker) {
      history.replace('/subscription/current')
    }
    if (checker && state) {
      switch (state.type) {
        case 'openBrowser':
          if (state.link) {
            history.goBack()
            openBrowser(state.link)
          } else history.push('/list-kid')
          break
        case 'local':
          if (state.link) history.push(state.link, state)
          else history.push('/list-kid')
          break
        default:
          history.push('/list-kid')
      }
    }
    if (checker && state === undefined) history.replace('/subscription/package')
  }, [history, checker, searchParams, state, openBrowser])

  useEffect(() => {
    const cpPinValue = pinValue.join('') || ['']
    const cpPinCode = pinCode.join('')
    if (cpPinValue === cpPinCode) {
      setChecker(true)
    } else {
      setChecker(false)
    }
  }, [pinCode, pinValue])

  const keyDownHandler = (
    idx: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      setBackspace(true)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const element: any = document.querySelector(`.code_input_otp_${idx}`)
      const val = element?.value ? element?.value : ''
      if (val === '') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const nextElement: any = document.querySelector(
          `.code_input_otp_${idx - 1}`
        )
        nextElement?.select?.()
      }
    } else {
      setBackspace(false)
    }
    return false
  }
  const keyUpHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.currentTarget.value !== '') {
      e.currentTarget?.select()
    }
  }

  const inputOnChange = (
    idx: number,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const nextElementSibling = e.target
      .nextElementSibling as HTMLInputElement | null
    const previousElementSibling = e.target
      .previousElementSibling as HTMLInputElement | null

    if (e.target.value) handleOtpValue(idx, e.target.value)
    if (backspace) handleOtpValue(idx, '')

    if (nextElementSibling && !backspace) nextElementSibling?.select()
    if (previousElementSibling && backspace) previousElementSibling?.select()
  }

  const random4DigitNumber = () => {
    return Math.floor(1000 + Math.random() * 9000)
  }

  const arabicToEnglish = (s: string) => {
    const ARABIC_NUMBERS = '٠١٢٣٤٥٦٧٨٩'
    const re = /[٠-٩]/g
    const number = s.replace(re, (v) => `${ARABIC_NUMBERS.indexOf(v)}`)
    return number
  }

  const handleOtpValue = (idx: number, value: string) => {
    if (value.length > 1) return
    const cpPinValue: string[] = [...pinValue]
    cpPinValue[idx] = arabicToEnglish(value)
    setPinValue(cpPinValue)
  }

  return (
    <>
      <Helmet>
        <title>بوابة الأمان</title>
      </Helmet>
      <AppBar
        backButtonLocation={state?.from}
        fixed
        hideToggleButton
        showBackButton={true}
        showParentBackButton
      />
      <AppContent className="bg-no-repeat bg-cover bg-center bg-v3 pages_parentalgateway_appcontent">
        <ContentTitle
          className="pages_parentalgateway_contenttitle"
          noShadow
          notBold
          textColor="text-white"
          title="بوابة الأمان"
        />

        <div className="w-full mx-auto container h-screen overflow-hidden flex flex-col items-center pages_parentalgateway_container">
          <div className="w-full flex justify-center text-[1rem] xl:text-[1.4rem] mt-1 text-white pages_parentalgateway_typography">
            <h1>من فضلك اكتب الرقم الظاهر أمامك</h1>
          </div>
          <div className="w-[80%] max-w-xs h-[60px] flex items-center justify-around bg-v3-green rounded-xl text-center text-[#fff] text-[1.3rem] xl:text-[1.5rem] mt-4 pages_parentalgateway_pincode_container">
            {pinCode.map((item, index) => (
              <p
                className=" pages_parentalgateway_pincode_paragraph"
                key={index}
              >
                {NUMBER_ARABIC[parseInt(item) as keyof typeof NUMBER_ARABIC]}
              </p>
            ))}
          </div>
          <div className="w-[80%] max-w-xs h-[50px] flex items-center justify-around text-center text-[#fff] text-[1.3rem] xl:text-[1.5rem] mt-4  pages_parentalgateway_pincode_input_container">
            {pinCode.map((_, index) => (
              <input
                autoFocus={index === 0}
                className={`code-input  pages_parentalgateway_pincode_input  pages_parentalgateway_pincode_input_${index} w-[50px] h-[50px] bg-[#fff] text-black text-center  border border-[#999583] rounded-2xl code_input_otp_${index}`}
                inputMode="decimal"
                key={index}
                max="9"
                min="0"
                onBeforeInput={(e) => e.currentTarget.select()}
                onChange={(e) => inputOnChange(index, e)}
                onClick={(e) => e.currentTarget.select()}
                onFocusCapture={(e) => e.currentTarget.select()}
                onKeyDown={(e) => keyDownHandler(index, e)}
                onKeyUp={(e) => keyUpHandler(e)}
                value={pinValue[index] || ''}
              />
            ))}
          </div>
        </div>
      </AppContent>
    </>
  )
}

export default ParentalGateway
