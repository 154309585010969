import { ContentTitle } from '@components/ContentTitle'
import { AppBar } from '@components/Layout/AppBar'
import { AppContent } from '@components/Layout/AppContent'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import { useAllActivities } from '@hooks/data/fetchers/use-all-activities'
import { usePurchases } from '@hooks/use-purchases'
import { IActivity, IEpisode } from '@src/types/api.interface'
import Helper from '@utils/Helpers'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import Popup from '@components/Popup'
import {
  LockedVideoOverlay,
  PlayVideoOverlay,
} from '@components/Overlay/OverlayButton'
import {
  HAVENT_FINISHED_VIDEOS,
  REQUIRES_SUBSCRIBE_MESSAGE,
  SUBSCRIBE_NOW_MESSAGE,
  THIS_CONTENT_MESSAGE,
} from '@src/constants/messages'
import { logEvent } from '@utils/firebase-analytics'
import { DEFAULT_BOOK_THUMBNAIL } from '@utils/constants'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'

const MaktabahBook: FC = () => {
  const { kidProfile } = useProfile()
  const { allActivities, isLoadingActivities } = useAllActivities('book')
  const [isVisable, setIsVisable] = useState(false)

  const [hasProAccess, setHasProAccess] = useState(false)
  const purchases = usePurchases()

  const [isLoadingPayment, setIsLoadingPayment] = useState(true)

  useEffect(() => {
    purchases
      .isHavingProAccess(kidProfile?.level === 1 ? 'level-1' : 'level-2')
      .then(setHasProAccess)
      .then(() => {
        setIsLoadingPayment(false)
      })
  }, [kidProfile?.level, purchases])

  useEffect(() => {
    logEvent('open_book_library')
  }, [])

  return (
    <>
      <Helmet>
        <title className="maktabah_book_page-main-title">
          عالم زيدو - أطفال
        </title>
      </Helmet>
      {isLoadingActivities || isLoadingPayment ? <AppProgressBar /> : null}
      <AppBar backButtonLocation="/library" fixed showKidAvatar title="" />
      <AppContent className="bg-no-repeat bg-cover bg-center bg-blue ">
        <ContentTitle noShadow textColor="text-black" title="كتب" />
        <div className="maktabah_book_page-container w-full flex flex-col mt-5 items-start justify-center">
          {!isLoadingPayment
            ? (allActivities ?? []).map((oneEpisode: IEpisode) => {
                if (!oneEpisode?.activities.length) return null

                return (
                  <EpisodeChapters
                    activities={oneEpisode?.activities ?? []}
                    episodeWatched={oneEpisode.watched}
                    hasProAccess={hasProAccess}
                    key={oneEpisode._id}
                    setIsVisable={setIsVisable}
                    title={oneEpisode.title}
                  />
                )
              })
            : null}
        </div>

        <Popup
          acceptLink={!hasProAccess ? '/parent?redirect=subscribe' : null}
          buttonText={!hasProAccess ? SUBSCRIBE_NOW_MESSAGE : null}
          message={
            hasProAccess ? HAVENT_FINISHED_VIDEOS : REQUIRES_SUBSCRIBE_MESSAGE
          }
          onClose={() => setIsVisable(false)}
          title={hasProAccess ? '' : THIS_CONTENT_MESSAGE}
          visible={isVisable}
        />
      </AppContent>
    </>
  )
}

export default MaktabahBook

const EpisodeChapters: FC<{
  title: string
  activities: IActivity[]
  hasProAccess: boolean
  episodeWatched: boolean
  setIsVisable: (isVisible: boolean) => void
}> = ({ title, activities, hasProAccess, episodeWatched, setIsVisable }) => {
  return activities.length > 0 ? (
    <div className="maktabah_book_page-episode-chapters">
      <span className="maktabah_book_page-episode-chapters-title mr-3 font-bold pr-4 text-base md:text-2xl">
        {title}
      </span>
      <div className="maktabah_book_page-episode-chapters-activities-wrapper mx-3 pb-3 flex flex-row mt-1">
        {activities.map((oneActivity: IActivity) => {
          const locked =
            (!hasProAccess && !oneActivity.isFree) ||
            (hasProAccess && !episodeWatched)
          return (
            <BookThumbnail
              activity={oneActivity}
              key={oneActivity._id}
              locked={locked}
              setIsVisable={setIsVisable}
            />
          )
        })}
      </div>
    </div>
  ) : null
}

const BookThumbnail: FC<{
  activity: IActivity
  locked?: boolean
  setIsVisable: (isVisible: boolean) => void
}> = ({ activity, locked, setIsVisable }) => {
  const url = `/activities/${activity._id}`
  const image = Helper.MediaURL(activity.thumbnail)

  return (
    <div
      className={`maktabah_book_page-book-thumbnail-wrapper-${activity._id} w-[165px] lg:w-[295px] grid grid-cols-1 place-items-center justify-items-center sm:max-w-[165px] md:max-w-[215px] lg:max-w-[295px] mr-3 relative text-center border-[5px] p-[2px] border-blue-900 bg-blue-900 rounded-xl`}
    >
      {locked ? <LockedVideoOverlay setIsVisable={setIsVisable} /> : null}
      <Link
        className={`maktabah_book_page-book-thumbnail-link-${activity._id} grid grid-cols-1 place-items-center justify-items-center`}
        to={{
          pathname: locked ? '' : url,
          state: 'maktabah',
        }}
      >
        <div
          className={`maktabah_book_page-thumbnail-wrapper-${activity._id} h-[110px] w-[150px] lg:h-[208px] lg:w-[280px] sm:max-h-[110px] md:max-h-[145px] lg:max-h-[208px] sm:max-w-[150px] md:max-w-[200px] lg:max-w-[350px] relative rounded-xl overflow-hidden`}
        >
          <img
            alt=""
            className={`maktabah_book_page-book-thumbnail-${activity._id} w-full h-full`}
            draggable="false"
            src={image || DEFAULT_BOOK_THUMBNAIL}
          />
          {!locked ? <PlayVideoOverlay /> : null}
        </div>
        <div
          className={`maktabah_book_page-${activity.title} text-white text-base lg:text-xl truncate mt-2 w-full`}
        >
          {activity.title}
        </div>
      </Link>
    </div>
  )
}
