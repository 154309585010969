import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const PaymentIframe: FC<{
  src: string
  postMessageUrl: string
  handleLocalPaymentCompleted: () => void
}> = ({ src, postMessageUrl, handleLocalPaymentCompleted }) => {
  const history = useHistory()

  useEffect(() => {
    const handleMessageFromIframe = (event: MessageEvent) => {
      // Verify the origin of the message
      if (event.origin !== postMessageUrl) return

      if (event.data?.statusCode === 200) {
        handleLocalPaymentCompleted()
        if (event.data.orderStatus === 'UNPAID') {
          history.replace('/subscription/post-payment')
        } else {
          history.replace('/subscription/success')
        }
      }
    }

    window.addEventListener('message', handleMessageFromIframe)

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('message', handleMessageFromIframe)
    }
  }, [handleLocalPaymentCompleted, postMessageUrl, history])

  return (
    <iframe
      className="w-full h-full"
      id="local-payment-iframe"
      src={src}
      title="local-payment-iframe"
    />
  )
}

export default PaymentIframe
