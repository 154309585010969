import React, { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { AppBar, AppContent } from '@components/Layout'
import { useHistory, useParams } from 'react-router-dom'
import { useStoreKid } from '@hooks/use-store-kid'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import DatePicker from 'react-date-picker'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'
import { ContentTitle } from '@components/ContentTitle'
import { INVALID_USER_ID } from '@utils/constants'
import {
  EDUGOAL_MESSAGE,
  HOW_MANY_HOURS,
  MESSAGE_CHILD_LIMIT,
} from '@src/constants'
import HorizontalScrollInput from '@components/HorizontalScrollInput'
import GreenTickIcon from '@components/Icons/GreenTickIcon'
import CalendarIcon from '@components/Icons/CalendarIcon'
import EmptyAvatarIcon from '@components/Icons/EmptyAvatarIcon'
import GirlMobileIcon from '@components/Icons/GirlMobileIcon'
import BoyMobileIcon from '@components/Icons/BoyMobileIcon'
import NextPageIcon from '@components/Icons/NextPageIcon'
import { useMobileView } from '@hooks/useMobileView'
import InputMobileContainer from '@components/InputMobileContainer'
import { IHobbies } from '@src/types/api.interface'
import ClockSquareIcon from '@components/Icons/ClockSquareIcon'
import ArrowBullseyeIcon from '@components/Icons/ArrowBullseyeIcon'
import { useAllEdugoals } from '@hooks/data/fetchers/use-all-edugoals'

const MESSAGE_ADD_KID = 'أضف طفلاً'
const MESSAGE_EDIT_KID = 'تعديل بيانات الطفل'
const MESSAGE_BTN_EDIT_KID = 'تعديل'

type ValuePiece = Date | null
type DateValue = ValuePiece | [ValuePiece, ValuePiece]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AddKid: FC = () => {
  const [gender, setGender] = useState<string>('male')
  const [username, setName] = useState<string>('')
  const [birthdate, setBirthDate] = useState<DateValue>(
    new Date(new Date().setFullYear(new Date().getFullYear() - 4))
  )
  const { newKid, setDataBody, createLoading } = useStoreKid()
  const { parentProfile, logout } = useProfile()
  const [isValidFormInput, setIsValidFormInput] = useState<boolean>(false)
  const [editKid, setEditKid] = useState({})

  // const { hobbies, isLoadingHobbies } = useAllHobbies()
  const { edugoals, isLoadingEdugoals } = useAllEdugoals()
  const [mobileFormIndex, setMobileFormIndex] = useState(0)

  const isLoading = isLoadingEdugoals

  // TODO: remove the initial value
  const [selectedChoices, setSelectedChoices] = useState<string[]>([])

  // TODO: get the index from DB if this is edit kid
  const [selectedTimeIndex, setSelectedTimeIndex] = useState<number>(0)

  const [selectedEdugoal, setSelectedEdugoal] = useState<string>(null)

  const isMobileView = useMobileView()

  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  const formatDate = (date: DateValue): string => {
    const d = new Date(Date.parse(date.toString()))
    const days = d.getDate().toString().padStart(2, '0')
    const month = (d.getMonth() + 1).toString().padStart(2, '0')
    const year = d.getFullYear()
    return `${year}-${month}-${days}`
  }
  useEffect(() => {
    if (id) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const kid: any = parentProfile.kids.find((kid) => kid?._id === id)
      if (kid) {
        const {
          dateOfBirth,
          gender,
          nickname,
          hobbies,
          hoursUsingPhone,
          edugoal,
        } = kid
        setGender(gender ?? 'male')
        setName(sanitizeName(nickname))
        setBirthDate(dateOfBirth)
        setEditKid(kid)

        setSelectedChoices(hobbies)
        setSelectedTimeIndex(hoursUsingPhone ? hoursUsingPhone - 1 : null)
        setSelectedEdugoal(edugoal)
      } else {
        toast.dismiss('addEditKidError')
        toast.info(INVALID_USER_ID, {
          type: 'error',
          toastId: 'addEditKidError',
        })
        history.push('/list-kid')
      }
    }
    // refreshProfile(true)
  }, [history, id, parentProfile.kids])

  useEffect(() => {
    if (parentProfile?.kids[0] && newKid) {
      history.push(`/list-kid`)
    }
    if (parentProfile?.email === 'user@anonymous.firebase') {
      logout()
    }
  }, [history, parentProfile, newKid, logout])

  const formValidationFn = () => {
    if (!gender) {
      toast.info('يرجى اختيار النوع', { toastId: 'gender_required' })
      return
    }

    if (!username?.trim()) {
      toast.info('يرجى اختيار الاسم', { toastId: 'username_required' })
      return
    }

    if (!birthdate) {
      toast.info('يرجى اختيار تاريخ الميلاد', {
        toastId: 'birthdate_required',
      })
      return
    }

    if (!gender || !username || !birthdate) {
      toast.info('معلومات ناقصة', {
        toastId: 'corrupt_data',
      })
      return
    }

    const age = calculateAge(Date.parse(formatDate(birthdate)))

    if (age > 12) {
      toast.info('الحد الأقصى للعمر هو 12 سنة', {
        toastId: 'birthdate_exceeded',
      })
      return
    }

    if (age < 4) {
      toast.info('الحد الأدنى للعمر هو ٤ سنوات', {
        toastId: 'birthdate_future',
      })
      return
    }

    const level = calculateLevel(age)

    if (checkLimit(level)) {
      toast.error(MESSAGE_CHILD_LIMIT, {
        toastId: 'child_limit',
      })
      return
    }

    if (!selectedEdugoal) {
      toast.info('من فضلك اختر الهدف التربوي', {
        toastId: 'edugoal_required',
      })
      return
    }

    if (!selectedEdugoal) {
      toast.info('من فضلك اختر الهدف التربوي', {
        toastId: 'edugoal_required',
      })
      return
    }

    setIsValidFormInput(true)
    return true
  }

  function calculateLevel(age: number) {
    if (age >= 4 && age <= 6) {
      return 1
    }

    if (age >= 7 && age <= 9) {
      return 2
    }

    return 3
  }

  function checkLimit(level: number) {
    const count = parentProfile.kids.filter(
      ({ level: kidLevel, _id }) => (!id || _id !== id) && kidLevel === level
    ).length
    return count >= 3
  }

  function sanitizeName(name: string): string {
    const pattern = /[^a-zA-Z\s\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9]/g
    return name.replace(pattern, '') ?? ''
  }

  const handleChange = (event: {
    target: { name: string; value: React.SetStateAction<string | undefined> }
  }) => {
    if (event.target.name === 'gender') {
      setGender(event.target.value)
    } else if (event.target.name === 'kidname') {
      const name = event.target.value as string
      // const regex = /^[a-zA-Z\s\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9]+$/gim
      // const validName = regex.test(name)
      // if (validName || name.trim() === '')
      setName(sanitizeName(name ?? ''))
      return false
    } else if (event.target.name === 'dob') {
      // setBirthDate(event.target.value)
    }
  }

  function calculateAge(birthday: number) {
    const ageDifMs = Date.now() - birthday
    const ageDate = new Date(ageDifMs)
    return ageDate.getUTCFullYear() - 1970
  }

  const handleSubmit = () => {
    if (isMobileView && mobileFormIndex !== 1) {
      if (formValidationFn()) {
        setMobileFormIndex((prev) => prev + 1)
      }
    } else {
      const valid = formValidationFn() || isValidFormInput
      if (gender && username && birthdate && valid) {
        const sanitizeUsername = sanitizeName(username).trim()
        const newUsername = sanitizeUsername + parentProfile?._id
        setDataBody({
          ...editKid,
          gender,
          username: newUsername,
          nickname: sanitizeUsername,
          dateOfBirth: formatDate(birthdate),
          parent: parentProfile?._id,
          hobbies: selectedChoices,
          hoursUsingPhone:
            selectedTimeIndex !== null ? selectedTimeIndex + 1 : null,
          edugoal: selectedEdugoal && selectedEdugoal,
        })
      }
    }
  }

  const handleFormBackButton = () => {
    if (isMobileView) {
      setMobileFormIndex((prev) => prev - 1)
    } else {
      history.replace('/list-kid')
    }
  }

  return (
    <>
      <Helmet>
        <title className="add_kid_title">عالم زيدو - الصفحة الرئيسية</title>
      </Helmet>
      {parentProfile?.kids?.length > 0 ? (
        <AppBar
          backButtonLocation="/list-kid"
          backButtonOnclick={
            isMobileView && mobileFormIndex !== 0 ? handleFormBackButton : null
          }
          fixed
          hideToggleButton
          showBackButton={true}
          showOutsideLogoutButton={true}
          showParentBackButton
          title=""
        />
      ) : null}
      <AppContent className="bg-no-repeat bg-cover bg-center bg-v3">
        <ContentTitle
          bottomLine={!isMobileView}
          className="add_kid_content_title lg:text-[40px]"
          noShadow
          notBold
          textColor="text-white"
          title={id ? MESSAGE_EDIT_KID : MESSAGE_ADD_KID}
        />
        <div className="w-full mx-auto container overflow-visible flex flex-col items-center justify-center xl:justify-start mt-2 pb-3 lg:pb-6">
          {!isLoading ? (
            <AddKidCard
              birthdate={birthdate}
              createLoading={createLoading}
              edugoals={edugoals}
              gender={gender}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              // hobbies={hobbies}
              id={id}
              mobileFormIndex={mobileFormIndex}
              // selectedChoice={selectedChoices}
              selectedEdugoal={selectedEdugoal}
              selectedTimeIndex={selectedTimeIndex}
              setBirthDate={setBirthDate}
              setGender={setGender}
              // setSelectedChoices={setSelectedChoices}
              setSelectedEdugoal={setSelectedEdugoal}
              setSelectedTimeIndex={setSelectedTimeIndex}
              username={username}
            />
          ) : null}
        </div>
      </AppContent>
    </>
  )
}

export default AddKid

const AddKidCard: FC<{
  gender: string
  setGender: React.Dispatch<React.SetStateAction<string>>
  handleChange: (event: {
    target: { name: string; value: React.SetStateAction<string | undefined> }
  }) => void
  username: string
  birthdate: DateValue
  setBirthDate: React.Dispatch<React.SetStateAction<DateValue>>
  id: string
  createLoading: boolean
  handleSubmit: () => void
  // selectedChoice: string[]
  // setSelectedChoices: React.Dispatch<React.SetStateAction<string[]>>
  selectedTimeIndex: number
  setSelectedTimeIndex: React.Dispatch<React.SetStateAction<number>>
  mobileFormIndex: number
  // hobbies: IHobbies[]
  edugoals: IHobbies[]
  selectedEdugoal: string
  setSelectedEdugoal: React.Dispatch<React.SetStateAction<string>>
}> = ({
  setGender,
  gender,
  handleChange,
  username,
  birthdate,
  setBirthDate,
  id,
  createLoading,
  handleSubmit,
  selectedTimeIndex,
  setSelectedTimeIndex,
  mobileFormIndex,
  edugoals,
  selectedEdugoal,
  setSelectedEdugoal,
}) => {
  const isMobileView = useMobileView()

  return (
    <>
      <div
        className={`add_kid_Card flex flex-col items-center gap-y-6 p-4 xl:px-8 xl:py-4 `}
      >
        {mobileFormIndex === 0 ? (
          <div
            className={`add_kid_gender_icon_container ${
              isMobileView ? '' : 'bg-[#4E64BD]'
            } rounded-3xl lg:px-4 lg:py-6 w-full flex flex-col items-center  gap-y-6`}
          >
            <div className="add_kid_gender_icon_wrapper flex w-full justify-center gap-x-3 ">
              {/* Boy Icon */}
              <button
                className="relative add_kid_male_kid_button"
                onClick={() => {
                  setGender('male')
                }}
                type="button"
              >
                <BoyMobileIcon selected={gender === 'male'} />

                {gender === 'male' ? (
                  <div className="add_kid_male_green_tick_icon_wrapper absolute -top-2 -right-0 lg:-top-3 lg:-right-3">
                    <GreenTickIcon />
                  </div>
                ) : null}
              </button>

              {/* Girl Icon */}
              <button
                className="relative add_kid_female_kid_button"
                onClick={() => {
                  setGender('female')
                }}
                type="button"
              >
                <GirlMobileIcon selected={gender === 'female'} />

                {gender === 'female' ? (
                  <div className="add_kid_female_green_tick_icon_wrapper absolute -top-2 -right-0 lg:-top-3 lg:-right-3">
                    <GreenTickIcon />
                  </div>
                ) : null}
              </button>
            </div>
            <div className="add_kid_user_name_wrapper flex flex-row gap-x-4">
              <label className="add_kid_username_label" htmlFor="username">
                <EmptyAvatarIcon
                  classNames="add_kid_empty_avatar_icon self-center"
                  fill2="#D1E9F4"
                />
              </label>

              <input
                autoComplete="off"
                className={`add_kid_username_input border-4 h-[47px] px-6 text-base md:text-lg xl:text-xl rounded-full shadow-md w-80 md:w-96 xl:w-[440px] bg-[#DFF2FF] focus:border-[#A7DCFF] border-[#A7DCFF] text-[#515977] drop-shadow-[0px_3px_0px_#81A2B9]
                  `}
                id="username"
                name="kidname"
                onChange={handleChange}
                placeholder="الاسم"
                type="text"
                value={username ?? ''}
              />
            </div>

            <div className="add_kid_calendar_icon_wrapper flex flex-row gap-x-4 relative">
              <CalendarIcon
                classNames="add_kid_calendar_icon self-center"
                fill2="#D1E9F4"
              />
              <div
                className="add_kid_date_picker_wrapper absolute h-full cursor-pointer rounded-[50px] top-[1px] left-[66px] z-[99]"
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  const el: any =
                    document.querySelector('#calendarIconId').parentNode
                  if (el.nodeName.toLowerCase() === 'button') {
                    el.click()
                  }
                }}
                // style={{
                //   width: 'calc(100% - 115px)',
                // }}
              />
              <DatePicker
                calendarIcon={
                  <svg
                    className="add_kid_react-date-picker_calendar-button__icon react-date-picker__calendar-button__icon react-date-picker__button__icon"
                    height="19"
                    id="calendarIconId"
                    stroke="black"
                    strokeWidth="2"
                    viewBox="0 0 19 19"
                    width="19"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect fill="none" height="15" width="15" x="2" y="2" />
                    <line x1="6" x2="6" y1="0" y2="4" />
                    <line x1="13" x2="13" y1="0" y2="4" />
                  </svg>
                }
                className={` h-[47px] border-4 z-10  pl-6 pr-3 text-base md:text-lg xl:text-xl rounded-full
                     bg-[#DEFEFA] border-[#A2E3DB] text-[#515977] drop-shadow-[0px_3px_0px_#5BAFA6]
                  shadow-md w-80 md:w-96 xl:w-[440px]`}
                clearIcon={null}
                format="d/M/yyyy"
                locale="ar-EG"
                maxDate={
                  new Date(new Date().setFullYear(new Date().getFullYear() - 4))
                }
                minDate={
                  new Date(
                    new Date().setFullYear(new Date().getFullYear() - 17)
                  )
                }
                onChange={setBirthDate}
                value={birthdate}
              />
            </div>
            <div className="add_kid_arrow_bullseye_icon_wrapper flex flex-row gap-x-4 items-center ">
              <ArrowBullseyeIcon />
              <select
                className={`add_kid_select_field_${selectedEdugoal} h-[47px] lg:h-[49px] border-4  px-6 text-base md:text-base lg:text-lg rounded-full shadow-md w-80 md:w-96 xl:w-[440px]
                    bg-[#DEFEFA] border-[#A2E3DB] focus:border-[#A2E3DB] text-[#515977] drop-shadow-[0px_3px_0px_#5BAFA6]
                `}
                id=""
                name=""
                onChange={(e) => {
                  setSelectedEdugoal(e.target.value)
                }}
                value={selectedEdugoal}
              >
                <option
                  className={`add_kid_hidden_option_${EDUGOAL_MESSAGE}`}
                  hidden
                  value=""
                >
                  {EDUGOAL_MESSAGE}
                </option>
                {edugoals?.map((edu) => (
                  <option
                    className={`add_kid_select_option${edu.title}`}
                    key={edu._id}
                    value={edu._id}
                  >
                    {edu.title}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : null}

        {isMobileView && mobileFormIndex === 1 ? (
          <div className="add_kid_is_mobile_view_input_container flex gap-2">
            {/* <InputMobileContainer
              title={WHAT_HOBBIES_MESSAGE}
              icon={<GameSquareIcon />}
            >
              <DropdownMultiSelectInput
                choices={hobbies}
                selectedChoices={selectedChoice}
                setSelectedChoices={setSelectedChoices}
              />
            </InputMobileContainer> */}
            <div className="add_kid_is_mobile_view_input_mobile_container_wrapper flex-grow-0">
              <InputMobileContainer
                bg="bg-[#E0DEF3]"
                icon={<ClockSquareIcon />}
                title={HOW_MANY_HOURS}
              >
                <HorizontalScrollInput
                  isNewView
                  selectedIndex={selectedTimeIndex}
                  selections={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                  setSelectedIndex={setSelectedTimeIndex}
                />
              </InputMobileContainer>
            </div>
          </div>
        ) : !isMobileView ? (
          <>
            {/* <InputMobileContainer
              title={WHAT_HOBBIES_MESSAGE}
              icon={<GameSquareIcon />}
            >
              <DropdownMultiSelectInput
                choices={hobbies}
                selectedChoices={selectedChoice}
                setSelectedChoices={setSelectedChoices}
              />
            </InputMobileContainer> */}

            <InputMobileContainer
              bg="bg-[#E0DEF3]"
              icon={<ClockSquareIcon />}
              title={HOW_MANY_HOURS}
            >
              <HorizontalScrollInput
                isNewView
                selectedIndex={selectedTimeIndex}
                selections={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]}
                setSelectedIndex={setSelectedTimeIndex}
              />
            </InputMobileContainer>
          </>
        ) : (
          <></>
        )}
      </div>
      <button
        className="add_kid_submit_button relative xl:mt-3 xl:mb-5"
        disabled={createLoading}
        onClick={handleSubmit}
        type="button"
      >
        <AddKidSVG />
        <div className="add_kid_message_btn_edit_kid_wrapper absolute inset-0 gap-x-2  flex items-center text-[#2B3C94] text-lg sm:text-xl xl:text-2xl justify-center">
          <p className="add_kid_message_btn_edit_kid pb-1">
            {id ? MESSAGE_BTN_EDIT_KID : MESSAGE_ADD_KID}
          </p>
          {isMobileView ? <NextPageIcon /> : null}
        </div>
      </button>
    </>
  )
}

const AddKidSVG = () => {
  return (
    <svg
      className="add_kid_svg w-[116px] xl:w-[128px]"
      // width="128"
      // height="54"
      fill="none"
      viewBox="0 0 158 54"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1448_52236)">
        <rect fill="#B3D65B" height="50" rx="24" width="155" y="0.671875" />
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="53"
          id="filter0_d_1448_52236"
          width="158"
          x="0"
          y="0.671875"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dx="3" dy="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.367472 0 0 0 0 0.454167 0 0 0 0 0.149497 0 0 0 1 0"
          />
          <feBlend
            in2="BackgroundImageFix"
            mode="normal"
            result="effect1_dropShadow_1448_52236"
          />
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_1448_52236"
            mode="normal"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
