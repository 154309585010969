import Config from '@config/index'
import Cookies from 'js-cookie'
import HelperAPI from './helper.api'
import SecureLocalStorage from 'react-secure-storage'

const {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  localStorage: { tokenIndex, keyName },
} = Config

const HelperJWT = {
  getJwtToken(): string | null {
    return (Cookies.get(tokenIndex) ??
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      SecureLocalStorage.getItem(tokenIndex)) as any
  },

  setJwtToken: function (token: string): void {
    SecureLocalStorage.setItem(tokenIndex, token)
    Cookies.set(tokenIndex, token, { expires: 365 })
  },

  removeJwtToken: function (): void {
    SecureLocalStorage.removeItem(tokenIndex)
    Cookies.remove(tokenIndex)
  },

  async getAndSetEncryptionKey(token: string): Promise<void> {
    const url = '/key'
    const newKey = await HelperAPI.ApiRequest<string>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (typeof newKey !== 'string') return
    SecureLocalStorage.setItem(keyName, newKey)
  },
}

export default HelperJWT
