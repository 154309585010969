import { FC, MouseEventHandler } from 'react'

const AccessibilityButton: FC<{
  onClick: MouseEventHandler<Element> | undefined
  variant: 'next' | 'skip' | 'previous'
  className?: string
}> = ({ onClick, variant, className }) => {
  return (
    <button
      className={`component_accessibility_button_container w-[100px] h-[50px] landscape:sm:w-[100px] landscape:sm:h-[45px] landscape:2xl:w-[110px] sm:w-[110px]  cursor-pointer bg-${variant}-button landscape:2xl:h-[50px] sm:h-[50px] bg-contain bg-no-repeat ${className}`}
      onClick={onClick}
      type="button"
    />
  )
}

export default AccessibilityButton
