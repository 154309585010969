export const TYPOGRAPHY = {
  WEBSITE_TITLE: 'عالم زيدو - تسجيل الدخول',
  WELCOME_TITLE: 'مرحباً بك في عالم زيدو',
  SIGN_IN: 'تسجيل الدخول',
  LOGIN: 'دخول',
  EMAIL_PLACEHOLDER: 'البريد الإلكتروني',
  PASSWORD_PLACEHOLDER: 'كلمة المرور',
  OR: 'او',
  ACCOUNT_CREATE: 'إنشاء حساب',
  FORGOT_PASSWORD: 'نسيت كلمة المرور؟',
}
