import React, {
  FC,
  FormEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Helmet } from 'react-helmet-async'
import { IDailyMeeting, ITestMeeting } from '@src/types/api.interface'
import { YellowButton, YellowLink } from '@components/Buttons'
import { ContentTitle } from '@components/ContentTitle'
import { AppBar, AppContent } from '@components/Layout'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import {
  MaqraaRegisterModal,
  ScheduleModal,
} from '@components/maqraa/ScheduleModal'
import { useDailyMeeting } from '@hooks/data/fetchers/maqraa/use-daily-meeting'
import { useMaqraaStudent } from '@hooks/data/fetchers/maqraa/use-sudent'
import { useTestMeeting } from '@hooks/data/fetchers/maqraa/use-test-meeting'
import { useTestMeetings } from '@hooks/data/fetchers/maqraa/use-test-meetings'
import { useInAppBrowser } from '@hooks/use-inapp-browser'
import { formatScheduleDate } from '@utils/date'
import { GtagEvents } from '@utils/gtag-events'
import { logEvent } from '@utils/firebase-analytics'
import { useLocation } from 'react-router-dom'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'

type KidMaqraaStatus =
  | 'HAS_DAILY_MEETING'
  | 'HAS_TEST_MEETING'
  | 'IS_ONLY_REGISTERED'
  | 'IS_NOT_REGISTERED'
  | 'IS_ANONYMOUS'

const HELP_WELCOME = `مقرأة زيدو تسمح لكم  بالتعلم من طرف نخبة من المعلمين المتخصصين من خلال إحدى الحصص ( تلقين، تصحيح تلاوة، حفظ ومراجعة)
احجز ميعاد تحديد المستوى
`

const HELP_TEST_MEETING =
  'تم حجز موعد تحديد مستوى الطالب بالمقرأة،  المرجو الضغط على زر فتح اللقاء بالموعد المحدد.'

const HELP_DAILY_MEETING =
  'تم تسجيلكم بنجاح في الدرس اليومي، المرجو الضغط على زر فتح الدرس أسفله بالموعد.'

const HELP_REGISTERED_OK =
  'تم تسجيللك بنجاح في المقرأة سيتم التواصل معك لبدء المقرأة اليومية'

const LIMIT_REACHED = `نعتذر إليكم ؛ لأن الأماكن مشغولة الآن.
  نرجو ترك بياناتكم  لنتواصل معكم عند توفر المكان... نشكر اهتمامكم ومرحبا بكم في عالم زيدو`

const MaqraaSchedule: FC = () => {
  const { parentProfile } = useProfile()
  const { dailyMeeting, isLoadingDailyMeeting } = useDailyMeeting()
  const { testMeetings, isLoadingTestMeetings } = useTestMeetings()
  const { testMeeting, isLoadingTestMeeting, scheduleTestMeeting } =
    useTestMeeting()

  const { student, isLoadingStudent, registerStudent } = useMaqraaStudent()

  const [selectedMeeting, setSelectedMeeting] = useState<
    ITestMeeting | undefined
  >()

  const [openRegisterModal, setOpenRegisterModal] = useState(false)

  const location = useLocation()
  const url = location.pathname

  const email = parentProfile?.email || ''
  const [phone, setPhone] = useState('')

  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    logEvent('open_maqra')
  }, [])

  const onSelectMeeting = useCallback((meeting: ITestMeeting) => {
    setSelectedMeeting(meeting)
  }, [])
  const onUnSelectMeeting = useCallback(() => {
    setSelectedMeeting(undefined)
  }, [])

  const openRegister = useCallback(() => {
    setOpenRegisterModal(true)
  }, [])

  const closeRegister = useCallback(() => {
    setOpenRegisterModal(false)
  }, [])

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault()
      if (submitting) return
      setSubmitting(true)
      if (selectedMeeting) {
        await scheduleTestMeeting({ email, phone, selectedMeeting })
        GtagEvents.registerMaqraa({ type: 'schedule-test-meeting' })
      } else {
        await registerStudent({ email, phone })
        GtagEvents.registerMaqraa({ type: 'without-test-meeting' })
      }
      logEvent('register_in_daily_maqraa')
      setSelectedMeeting(undefined)
      setOpenRegisterModal(false)
      setPhone('')
      setSubmitting(false)
    },
    [
      email,
      phone,
      selectedMeeting,
      scheduleTestMeeting,
      submitting,
      registerStudent,
    ]
  )

  const status = useMemo<KidMaqraaStatus | null>(() => {
    if (dailyMeeting) return 'HAS_DAILY_MEETING'
    if (isLoadingDailyMeeting) return null
    if (testMeeting) return 'HAS_TEST_MEETING'
    if (isLoadingTestMeeting) return null
    if (student) return 'IS_ONLY_REGISTERED'
    if (isLoadingStudent) return null
    if (url === '/maqraa-anonymous') return 'IS_ANONYMOUS'
    return 'IS_NOT_REGISTERED'
  }, [
    dailyMeeting,
    testMeeting,
    student,
    isLoadingDailyMeeting,
    isLoadingStudent,
    isLoadingTestMeeting,
    url,
  ])

  const helpMessage =
    status === 'HAS_DAILY_MEETING'
      ? HELP_DAILY_MEETING
      : status === 'IS_NOT_REGISTERED' && testMeetings?.length
      ? HELP_WELCOME
      : status === 'HAS_TEST_MEETING'
      ? HELP_TEST_MEETING
      : status === 'IS_ONLY_REGISTERED'
      ? HELP_REGISTERED_OK
      : null

  const isLoading =
    submitting ||
    isLoadingTestMeeting ||
    isLoadingTestMeetings ||
    isLoadingStudent

  return (
    <>
      <Helmet>
        <title className="maqraa_schedule_page-main-title">
          عالم زيدو - المقرأة
        </title>
      </Helmet>
      {isLoading ? <AppProgressBar /> : null}
      <AppBar
        fixed
        showBackButton={true}
        showKidAvatar
        title="المقرأة"
        // backButtonLocation="/kids-home"
      />
      <AppContent className="bg-no-repeat bg-cover bg-center bg-green">
        <ContentTitle noShadow textColor="text-black" title="المقرأة" />

        <div className="maqraa_schedule_page-container px-8 lg:container mx-auto">
          {helpMessage ? <HelpMessage>{helpMessage}</HelpMessage> : null}

          {status === 'HAS_DAILY_MEETING' && dailyMeeting ? (
            <UserDailyMeeting meeting={dailyMeeting} />
          ) : null}
          {status === 'HAS_TEST_MEETING' && testMeeting ? (
            <UserTestMeeting meeting={testMeeting} />
          ) : null}

          {(status === 'IS_NOT_REGISTERED' &&
            testMeetings &&
            testMeetings.length === 0) ||
          status === 'IS_ANONYMOUS' ? (
            <div className="maqraa_schedule_page-help-message-wrapper mb-5 max-w-xl mx-auto">
              <HelpMessage>
                <div className="maqraa_schedule_page-limit-reached-wrapper px-5 mx-auto flex flex-col justify-center items-center">
                  <span className="maqraa_schedule_page-limit-reached text-center text-xl mb-2">
                    {LIMIT_REACHED}
                  </span>
                  <YellowButton
                    label="سجل في المقرأة"
                    onClick={openRegister}
                    textSizeClass="text-lg font-bold"
                  />
                </div>
              </HelpMessage>
            </div>
          ) : null}
          {status === 'IS_NOT_REGISTERED' &&
          testMeetings &&
          testMeetings.length > 0 ? (
            <TestMeetingList
              meetings={testMeetings}
              onSelectMeeting={onSelectMeeting}
            />
          ) : null}
        </div>
      </AppContent>
      <ScheduleModal
        email={email}
        meeting={selectedMeeting}
        onChangePhone={setPhone}
        onClose={onUnSelectMeeting}
        onSubmit={onSubmit}
        phone={phone}
        submitting={submitting}
      />
      <MaqraaRegisterModal
        email={email}
        isOpen={openRegisterModal}
        onChangePhone={setPhone}
        onClose={closeRegister}
        onSubmit={onSubmit}
        phone={phone}
        submitting={submitting}
      />
    </>
  )
}

export default MaqraaSchedule

const TestMeetingList: FC<{
  onSelectMeeting: (meeting: ITestMeeting) => void
  meetings: ITestMeeting[]
}> = (props) => {
  return (
    <MeetingsContainer>
      {props.meetings.map((meeting) => {
        return (
          <TestMeetingItem
            key={meeting.date}
            meeting={meeting}
            onSelect={props.onSelectMeeting}
            type="test-meeting"
          />
        )
      })}
    </MeetingsContainer>
  )
}

const TestMeetingItem: FC<
  | {
      meeting: IDailyMeeting
      type: 'daily-meeting'
    }
  | {
      meeting: ITestMeeting
      type: 'test-meeting'
      onSelect?: (meeting: ITestMeeting) => void
    }
> = (props) => {
  const hasPlace = props.meeting.kidsMaximum > props.meeting.kidsNumber

  const date =
    'dateExplanation' in props.meeting
      ? props.meeting.dateExplanation + ' , ' + props.meeting.dateDetailed
      : formatScheduleDate(props.meeting.date)

  const openBrowser = useInAppBrowser()
  const onClickLink: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      e.preventDefault()
      if (!props.meeting.link) return

      GtagEvents.openMeeting({ type: props.type })
      logEvent('open_daily_maqraa', { type: props.type })

      openBrowser(props.meeting.link)
    },
    [openBrowser, props.meeting.link, props.type]
  )

  if (!hasPlace) return null

  return (
    <div className="maqraa_schedule_page-meeting-wrapper bg-white w-full rounded-lg border-2 py-4 px-4 shadow-gray-500 shadow-lg flex flex-col">
      <span className="maqraa_schedule_page-reservation-text text-gray-800 block mb-1 font-bold text-sm md:text-lg">
        موعد الحجز الخاص بك
      </span>

      <span className="maqraa_schedule_page-reservation-date text-[#eb6600] block mb-1 font-bold text-sm md:text-lg">
        {date}
      </span>

      <span className="maqraa_schedule_page-meeting-description w-full text-sm py-1 font-bold text-gray-500">
        {props.meeting.description}
      </span>
      <div className="maqraa_schedule_page-meeting-link-wrapper mt-auto ">
        {props.meeting.link ? (
          <YellowLink
            className="maqraa_schedule_page-meeting-yellow-link block w-full font-bold"
            label="فتح الدرس بتطبيق زووم"
            onClick={onClickLink}
            paddingClass="py-2 px-4"
            rel="noopener noreferrer"
            target="_blank"
            textSizeClass="text-sm sm:text-lg"
            to={props.meeting.link}
          />
        ) : (
          <YellowButton
            className="maqraa_schedule_page-meeting-yellow-button w-full font-bold"
            disabled={!hasPlace}
            label="حجز"
            onClick={() => {
              'onSelect' in props &&
                props.onSelect &&
                props.onSelect(props.meeting)
            }}
            textSizeClass="text-lg"
          />
        )}
      </div>
    </div>
  )
}

const UserTestMeeting: FC<{
  meeting: ITestMeeting
}> = (props) => {
  return (
    <div className="maqraa_schedule_page-user-test-meeting-wrapper mt-4 flex max-w-lg item-center mx-auto justify-center">
      <TestMeetingItem meeting={props.meeting} type="test-meeting" />
    </div>
  )
}

const UserDailyMeeting: FC<{
  meeting: IDailyMeeting
}> = (props: { meeting: IDailyMeeting }) => {
  return (
    <div className="maqraa_schedule_page-user-daily-meeting mt-5 flex max-w-lg item-center mx-auto justify-center">
      <TestMeetingItem meeting={props.meeting} type="daily-meeting" />
    </div>
  )
}

const HelpMessage = (props: { children: React.ReactNode | string }) => {
  return (
    <div className="maqraa_schedule_page-help-message-wrapper p-2 md:p-3 shadow-lg rounded-xl mx-auto bg-white text-[#eb6600] w-full text-center text-md md:text-xl mt-4">
      {props.children}
    </div>
  )
}

const MeetingsContainer = ({
  children,
}: {
  children: React.ReactNode | string
}) => {
  return (
    <div className="maqraa_schedule_page-meetings-container mt-4 w-full grid grid-cols-1 sm:grid-cols-3 gap-4">
      {children}
    </div>
  )
}
