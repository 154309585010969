import { AppBar, AppContent } from '@components/Layout'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import { useMobileView } from '@hooks/useMobileView'
import {
  LINK_TO_CHANGE_PASSWORD_HAS_BEEN_SENT,
  LINK_TO_CHANGE_PASSWORD_HAS_BEEN_SENT_2,
} from '@src/constants'
import { IForgotPassword } from '@src/types/api.interface'
import { FetchError } from '@utils/FetchError'
import HelperAPI from '@utils/helpers/helper.api'
import React, { FC, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import SVG from 'react-inlinesvg'
import Config from '@config/index'
const MailIcon = `${Config.publicURL}/images/v3/mailIcon.svg`

const ForgotPassword: FC = () => {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const url = `/auth/reset-password`

  const isMobileView = useMobileView()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (
    event:
      | React.KeyboardEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    if (
      event &&
      (event as React.KeyboardEvent).key &&
      (event as React.KeyboardEvent).key.trim() === ''
    ) {
      event.preventDefault()
      return false
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value = ((event?.target as any)?.value || '').replace(/\s|\s+/gim, '')
    setEmail(value)
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (
    event
  ) => {
    event.preventDefault()

    setMessage('')
    setIsLoading(true)
    const response = await onPostEmail(email)
    setIsLoading(false)

    if (response === 200) {
      // ENGLISH: To set a new password, please click on the link sent to your email
      setMessage(
        'لتعيين كلمة مرور جديدة يرجى الضغط على الرابط المرسل إلى بريدكم الإلكتروني'
      )
    } else if (response === 404) {
      // ENGLISH: There is no account registered with this email
      toast.error('لا يوجد حساب مسجل بهذا البريد الإلكتروني')
      setMessage('')
    } else {
      // ENGLISH: Internal Server Error
      toast.error('نعتذر عن وجود مشكلة حاليا برجاء المحاولة مرة اخري')
      setMessage('')
    }
  }

  const onPostEmail = async (email: string) => {
    try {
      const response = await HelperAPI.ApiRequest<IForgotPassword>(url, {
        method: 'POST',
        body: JSON.stringify({
          email,
        }),
      })

      return response.status
    } catch (error) {
      if (error instanceof FetchError) return error.status

      return 500
    }
  }

  // const messageMarginClassString = !error && message ? 'mt-[135px]' : 'mt-2'
  return (
    <>
      <Helmet>
        <title className="forgot_password_page-main-title">
          عالم زيدو - تسجيل الدخول
        </title>
      </Helmet>
      {isLoading ? <AppProgressBar /> : null}
      <AppBar
        backButtonLocation="/"
        fixed
        hideToggleButton
        showParentBackButton
      />
      <AppContent
        className="bg-no-repeat bg-cover bg-v3 relative"
        haveFooter={!isMobileView}
      >
        <div className="forgot_password_page-container w-full  flex flex-col items-center justify-center h-[98vh] lg:h-[94vh]">
          {!isMobileView && !message ? (
            <p className="forgot_password_page-no-message text-4xl text-[#FAE266] mb-6">
              مرحباً بك في عالم زيدو
            </p>
          ) : null}
          {message ? (
            <div className="forgot_password_page-wrapper flex flex-col items-center bg-[#4E64BD] text-white rounded-3xl px-16 lg:px-40 py-2 lg:py-6">
              <h1 className="forgot_password_page-main-heading text-3xl lg:text-xl xl:text-2xl  text-center pt-1 lg:pt-4">
                نسيت كلمة المرور
              </h1>

              <h2 className="forgot_password_page-link-to-change-password-sent-one mt-6 lg:text-lg xl:text-xl text-center">
                {LINK_TO_CHANGE_PASSWORD_HAS_BEEN_SENT}
              </h2>
              <h2 className="forgot_password_page-link-to-change-password-sent-two lg:text-lg xl:text-xl text-center">
                {LINK_TO_CHANGE_PASSWORD_HAS_BEEN_SENT_2}
              </h2>

              <p className="forgot_password_page-email lg:text-lg xl:text-xl text-center pb-2 lg:pb-4">
                {email}
              </p>
            </div>
          ) : (
            <div
              className={`forgot_password_page-form-wrapper ${
                !isMobileView && 'bg-[#4E64BD]'
              } rounded-3xl text-white flex items-center justify-center py-12 w-[60%] lg:w-[40%] relative lg:pb-4`}
            >
              <form
                className="forgot_password_page-form overflow-hidden w-[80%] flex flex-col items-center justify-center pb-4"
                onSubmit={handleSubmit}
              >
                {!message ? (
                  <>
                    <h1 className="forgot_password_page-form-forgot-password-heading text-2xl lg:text-xl xl:text-2xl text-center pt-2">
                      نسيت كلمة المرور
                    </h1>
                    <h2 className="forgot_password_page-form-write-your-email mt-2 lg:mt-6 lg:text-lg xl:text-xl text-center">
                      اكتب بريدك الإلكتروني من فضلك
                    </h2>
                    <h3 className="forgot_password_page-private-message lg:text-lg xl:text-xl   text-center">
                      سوف تصلكم رسالة عبر بريدكم الخاص
                    </h3>
                    <div className="forgot_password_page-mail-icon-wrapper flex justify-center items-center gap-x-2 lg:gap-x-3 mt-4 w-full">
                      <SVG
                        className="forgot_password_page-mail-icon"
                        src={MailIcon}
                      />

                      <input
                        autoComplete="off"
                        className="forgot_password_page-form-email-input-field focus:ring-0 focus:border-v3-gray-200 placeholder:text-v3-gray-800 focus:border-4 focus:text-v3-gray-800 portrait:h-4 h-[35px] xl:h-[50px] drop-shadow-[0px_4px_0px_#81A2B9] p-4 pr-6 rounded-full bg-v3-gray-400 border-4 border-v3-gray-200 placeholder:text-xl text-xl xl:placeholder:text-2xl xl:text-2xl text-v3-gray-800 w-full"
                        name="username"
                        onChange={handleChange}
                        onKeyDown={handleChange}
                        onKeyUp={handleChange}
                        placeholder="البريد الإلكتروني"
                        required
                        type="email"
                        value={email}
                      />
                    </div>
                    <input
                      className="forgot_password_page-form-submit-button cursor-pointer lg:text-xl bg-[#B3D65B] rounded-3xl px-8 lg:px-12 py-2 mt-4 lg:mt-6 text-[#2B3C94] drop-shadow-[2px_3px_0px_#5E7426] text-center"
                      disabled={isLoading}
                      id=""
                      type="submit"
                      value="تغيير كلمة المرور"
                    />
                  </>
                ) : null}
                {/* <h3
                  className={`text-[#3bac6e] text-[13px] xl:text-[16px] xl:mb-3 2xl:mb-1 text-center ${messageMarginClassString}`}
                >
                  {message}
                </h3>
                <h3 className="text-[#ee0000]  text-[13px] xl:text-[16px] xl:mb-3 2xl:mb-1 text-center mt-0">
                  {error}
                </h3> */}
              </form>
            </div>
          )}
        </div>
      </AppContent>
    </>
  )
}

export default ForgotPassword
