import { INotificationToken } from './../types/api.interface'
import { useHistory } from 'react-router'
import {
  FirebaseMessaging,
  PermissionStatus,
} from '@capacitor-firebase/messaging'
import { messaging, firebaseApp } from './firebase'
import Config from '@config/index'
import { customAlphabet } from 'nanoid'
import * as Sentry from '@sentry/react'
import HelperProfile from './helpers/helper.profile'
import HelperLocalNotification from './helpers/helper.localnotification'
import HelperAPI from './helpers/helper.api'

export interface INotificationManager {
  addListeners: (history: ReturnType<typeof useHistory>) => Promise<void>
  registerNotifications: () => Promise<string>
  revokeToken: () => void
  setup: (history: ReturnType<typeof useHistory>) => void
  registerUserToken: (userId: string) => Promise<void>
  unregisterUserToken: () => Promise<void>
  checkPermission: () => Promise<PermissionStatus>
  requestPermission: () => Promise<PermissionStatus>
}

export const PERMISSION = {
  GRANTED: 'granted',
}

export type PlatformType = 'web' | 'android' | 'ios'

export class NotificationManager implements INotificationManager {
  private static instance: NotificationManager
  private readonly platformEnum: PlatformType

  public static getInstance(platform: PlatformType): NotificationManager {
    if (!NotificationManager.instance) {
      NotificationManager.instance = new NotificationManager(platform)
    }
    return NotificationManager.instance
  }

  private constructor(platform: PlatformType) {
    this.platformEnum = platform
  }

  public addListeners = async (history: ReturnType<typeof useHistory>) => {
    // await FirebaseMessaging.addListener('tokenReceived', () => {})

    await FirebaseMessaging.addListener('notificationReceived', (event) => {
      const { notification } = event
      const { body, title } = notification
      const id = customAlphabet('1234567890', 18)
      HelperLocalNotification.addLocalNotificationByOn(
        parseInt(id()),
        title,
        body,
        0,
        1
      )
    })

    await FirebaseMessaging.addListener(
      'notificationActionPerformed',
      (event) => {
        if (!event.notification) return
        if (!event.notification.data) return
        const { data } = event.notification.data as { data: string }
        const result = data ? (JSON.parse(data) as { path: string }) : null
        const parent = HelperProfile.getStoredProfile()
        if (!parent) {
          history.replace('/login')
          return
        }
        const path = result?.path ?? '/parent'
        if (path) {
          history.replace(path)
        }
      }
    )
  }

  public registerNotifications = async () => {
    let result = await this.checkPermission()
    if (result.receive !== PERMISSION.GRANTED) {
      result = await this.requestPermission()
    }
    return result.receive
  }

  public revokeToken = async () => {
    await FirebaseMessaging.deleteToken()
  }

  public setup = async (history: ReturnType<typeof useHistory>) => {
    if (this.platformEnum === 'web') {
      messaging(firebaseApp())
    }

    const status = await this.registerNotifications()
    if (status === PERMISSION.GRANTED) {
      await this.addListeners(history)
    }

    const token = await this.getNotificationToken()
    if (!token) return

    // Experimental, subscribe to topic, to receive notifications from all devices
    this.subscribeToTopic()

    const userId = this.getUserId()
    if (userId) {
      this.sendTokenAsUser(token, userId)
    } else {
      this.sendTokenAsAnon(token)
    }
  }

  public registerUserToken = async (userId: string) => {
    const token = await this.getNotificationToken()
    this.sendTokenAsUser(token, userId)
  }

  public unregisterUserToken = async () => {
    const token = await this.getNotificationToken()
    if (token) {
      await this.revokeToken()
      await this.deleteTokenApi(token)
    }
    const newToken = await this.getNotificationToken()
    if (newToken === token) return
    newToken && (await this.sendTokenAsAnon(newToken))
  }

  public registerToken = async () => {
    const token = await this.getNotificationToken()
    this.sendTokenAsAnon(token)
  }

  public checkPermission = async () => {
    try {
      return await FirebaseMessaging.checkPermissions()
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
    }
  }

  public requestPermission = async () => {
    try {
      return await FirebaseMessaging.requestPermissions()
    } catch (e) {
      console.error(e)
      Sentry.captureException(e)
    }
  }

  private readonly subscribeToTopic = async () => {
    try {
      await FirebaseMessaging.subscribeToTopic({
        topic: Config.firebaseNotification.topic,
      })
    } catch (err) {
      Sentry.captureException(err)
    }
  }

  private readonly getNotificationToken = async () => {
    try {
      const result = await FirebaseMessaging.getToken({
        vapidKey: Config.firebase.vapidKey,
      })
      return result.token
    } catch (err) {
      Sentry.captureException(err)
      return null
    }
  }

  private readonly sendTokenAsAnon = async (token: string) => {
    if (!token) return
    return await this.sendTokenApi(token)
  }

  private readonly sendTokenAsUser = async (token: string, userId: string) => {
    if (!token) return
    return await this.sendTokenApi(token, userId)
  }

  private readonly deleteTokenApi = async (token: string) => {
    return await HelperAPI.ApiRequest('/notification-tokens', {
      method: 'DELETE',
      body: JSON.stringify({ token }),
    })
  }

  private readonly sendTokenApi = async (token: string, userId?: string) => {
    const url = '/notification-tokens/token/' + token

    const response = await HelperAPI.ApiRequest<INotificationToken | null>(
      url,
      {
        method: 'GET',
      }
    )

    if (response && !userId) {
      return
    }

    if (response && response.user === userId) {
      return
    }

    if (response && userId) {
      const urlPut = '/notification-tokens/' + response._id
      return await HelperAPI.ApiRequest(urlPut, {
        method: 'put',
        body: JSON.stringify({
          user: userId,
          platform: this.platformEnum,
        }),
      })
    }

    return await HelperAPI.ApiRequest('/notification-tokens', {
      method: 'POST',
      body: JSON.stringify({
        token,
        user: userId,
        platform: this.platformEnum,
      }),
    })
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private readonly getUserId = (): any => {
    const user = HelperProfile.getStoredProfile()
    return user ? user._id : null
  }
}
