/* eslint-disable @typescript-eslint/no-explicit-any */
import { CloseType } from '@src/types/api.interface'
import { sendGaToFirebase } from './sendGaToFirebase'

export function openApp() {
  const event: any = {
    event: 'app_open',
    eventCategory: 'App',
    eventAction: 'Open app',
  }
  window.dataLayer?.push(event)
  sendGaToFirebase(event)
}

export function customEvent(args: {
  event: string
  eventCategory?: string
  eventAction?: string
  eventLabel?: string
  data?: { [k: string]: string | number }
}) {
  window.dataLayer?.push(args as any)
  sendGaToFirebase(args)
}

export function closeApp(props: { type: CloseType }) {
  const event: any = {
    event: 'app_close',
    eventCategory: 'App',
    eventAction: 'Close app',
    eventLabel:
      props.type === 'back-button' ? 'Close app via back-button' : 'Close app',
  }
  window.dataLayer?.push(event)
  sendGaToFirebase(event)
}
