import { FC } from 'react'
import { IAnswer, IQuestionWithAnswers } from '@src/types/api.interface'
import { OrangeButton } from '../Buttons'

export const QuestionText: FC<{ question: IQuestionWithAnswers }> = (props) => {
  return (
    <div className="flex flex-col items-center justify-center px-2 challange_ctrl-container">
      <span className="font-bold text-lg md:text-xl inline-block border-black border-2 bg-white shadow-lg shadow-gray-400 rounded-2xl sm:px-4 p-2 md:p-4 text-center challange_ctrl-btnqstn-txt">
        {props.question.text}
      </span>
      {props.question.image ? (
        <img
          alt=""
          className="w-full max-w-[480px] challange_ctrl-btnqstn-txt-img"
          src={props.question.image}
        />
      ) : null}
    </div>
  )
}

export const QuestionAnswer: FC<{
  answer: IAnswer
  onClick: () => void
  isSelected: boolean
  isCorrect: boolean
  showAnswer: boolean
}> = (props) => {
  const borderClass = props.showAnswer
    ? props.isCorrect
      ? 'border-4 border-green-500'
      : 'border-4 border-red-500'
    : props.isSelected
    ? 'border-2 border-white'
    : 'border-2 border-black'

  if (!props.answer?.text) return null

  return (
    <button
      className="challange_ctrl-qstn-ansr-btn"
      disabled={props.showAnswer}
      onClick={props.onClick}
      type="button"
    >
      <span
        className={`${borderClass}  from-blue-400 to-blue-900 bg-gradient-to-b shadow-gray-400 shadow-orange-buttons rounded-full text-center text-white text-lg sm:text-lg md:text-xl px-4 sm:px-8 py-2 whitespace-nowrap font-bold flex justify-center items-center challange_ctrl-ansr-txt`}
      >
        {props.answer.text}
      </span>
      {props.answer.image ? (
        <img
          alt=""
          className="challange_ctrl-ansr-img"
          src={props.answer.image}
        />
      ) : null}
    </button>
  )
}

export const QuestionSubmit: FC<{ disabled: boolean; onClick: () => void }> = (
  props
) => {
  return (
    <OrangeButton
      className=""
      disabled={props.disabled}
      label="أرسل إجابتي"
      onClick={props.onClick}
      textSizeClass="text-lg md:text-2xl"
    />
  )
}

export const QuestionGoNext: FC<{
  onClick: () => void
  disabled?: boolean
}> = (props) => {
  return (
    <OrangeButton
      disabled={props.disabled}
      label="التالي"
      onClick={props.onClick}
    />
  )
}
