import { ISeries } from '@src/types/api.interface'
import Helper from '@utils/Helpers'
import { FC } from 'react'

interface SingleSeriesProps {
  series: ISeries
  isSingleSeries?: boolean
  onSeriesClick?: (series: ISeries) => void
}

const SingleSeries: FC<SingleSeriesProps> = ({
  series,
  onSeriesClick,
  isSingleSeries,
}) => {
  const thumbnail = Helper.MediaURL(series.thumbnail)
  const isHidden = isSingleSeries ? 'hidden' : ''

  return (
    <div
      className={`flex pages_series_singleseries_container flex-col h-36 w-36 lg:h-56 lg:w-56 bg-white border-4 rounded-[2.25rem] cursor-pointer ${isHidden}`}
      onClick={() => {
        onSeriesClick(series)
      }}
    >
      <img
        alt={series.title}
        className="w-full rounded-[2.25rem] p-2 h-36 lg:h-44 pages_series_singleseries_img"
        src={thumbnail}
      />
      <span className="w-full text-center pages_series_singleseries_title flex items-center justify-center text-lg lg:text-2xl truncate pb-2">
        {series.title}
      </span>
    </div>
  )
}

export default SingleSeries
