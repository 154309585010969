import { ToggleButtonProps } from './type'
import useToggleButton from './use-toggle-button'

const ToggleButton: React.FC<ToggleButtonProps> = (props) => {
  const { handleClick, toggleFirst, toggleSecond, value } =
    useToggleButton(props)

  return (
    <div className="flex items-center  rounded-full p-2  bg-[#4E64BD] toggle_btn-container">
      <button
        className={`flex-1 rounded-full p-2 toggle_btn-first-button${
          value === toggleFirst.value ? 'bg-[#70C2F4] text-white' : 'text-white'
        }`}
        onClick={() => handleClick(toggleFirst.value)}
        type="button"
      >
        {toggleFirst.label}
      </button>
      <button
        className={`flex-1 rounded-full p-2 toggle_btn-second-button ${
          value === toggleSecond.value
            ? 'bg-[#70C2F4] text-white'
            : 'text-white'
        }`}
        onClick={() => handleClick(toggleSecond.value)}
        type="button"
      >
        {toggleSecond.label}
      </button>
    </div>
  )
}

export default ToggleButton
