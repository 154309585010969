import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { AppContent } from '@components/Layout'
import girlImageSrc from '../resources/images/group_11778p.png'
import boyImageSrc from '../resources/images/group_11778.png'
import parentImageSrc from '../resources/images/group_12403.png'
import { Link, useHistory, useParams } from 'react-router-dom'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import Helper from '@utils/Helpers'
import { Profile } from '@src/types/api.interface'

const MESSAGE_KID_CONTROL = 'الأطفال'
const MESSAGE_ADD_KID = 'دخول الآباء'

const DeeplinkSelectKid: FC = () => {
  const { parentProfile, setActiveKid, logout } = useProfile()
  const history = useHistory()
  const { route } = useParams<{ route: string }>()

  if (parentProfile === undefined && history.location.pathname !== 'login') {
    history.push('/login')
  }
  if (parentProfile?.email === 'user@anonymous.firebase') {
    logout()
  }
  if (!parentProfile?.kids[0]) {
    history.push('/add-kid')
  }

  const handleClick = (kid: Profile) => {
    setActiveKid(kid)
    const levelKid = kid?.level

    const href = Helper.getCurriculumPathname(levelKid)

    if (route === 'curriculum') {
      history.push(href)
    } else if (route === 'competition') {
      history.push('/competition')
    } else if (route === 'maqraa') {
      history.push('/maqraa')
    } else {
      history.replace('/home')
    }
  }

  return (
    <>
      <Helmet>
        <title> الأطفال </title>
      </Helmet>
      <AppContent className="bg-no-repeat bg-cover bg-center bg-blue pages_deeplinkkid_appcontent">
        <div className="absolute top-2 right-2">
          <Link
            className="absolute pages_deeplinkkid_parent_link w-[160px] h-[65px]  mr-4 mt-4 cursor-pointer bg-parent-gateway bg-cover bg-no-repeat"
            to="/parent"
          >
            <div className="flex items-center justify-center h-[100%] pages_deeplinkkid_parent_link_child">
              <img
                alt=""
                className="h-[30px] mx-1 pages_deeplinkkid_parent_link_img"
                src={parentImageSrc}
              />
              <p className="text-white text-center pages_deeplinkkid_text">
                {MESSAGE_ADD_KID}
              </p>
            </div>
          </Link>
        </div>
        <div className="w-full pages_deeplinkkid_div_container mx-auto container min-h-screen overflow-hidden flex flex-col items-center">
          <div className="w-full max-w-[75%] pages_deeplinkkid_typography flex items-center justify-center text-[40px] mb-5 landscape:sm:mb-5 landscape:2xl:mb-28 mt-16">
            <h1 className="text-[15px] pages_deeplinkkid_typograhy_kidcontrol_text landscape:sm:text-[20px] landscape:2xl:text-[40px] sm:text-[40px] font-bold	">
              {MESSAGE_KID_CONTROL}
            </h1>
          </div>

          <div className="grid grid-cols-3 pages_deeplinkkid_kids_container sm:gap-4 gap-3  2xl:w-[80%] xl:w-[60%] lg:w-[70%] lg:mt-5 sm:w-[540px] md:w-[88%]  mx-auto  text-center">
            {parentProfile?.kids
              ? parentProfile.kids.map((kid, index) => {
                  return (
                    <div
                      className="bg-kid-list pages_deeplinkkid_kids_list_container sm:w-[170px] sm:h-[170px] md:w-[200px] md:h-[200px] lg:w-[230px] lg:h-[230px] 2xl:w-[400px] 2xl:h-[400px] cursor-pointer items-center justify-center grid place-items-center"
                      key={index}
                      onClick={() => handleClick(kid)}
                    >
                      <div className="justify-center items-center pages_deeplinkkid_kid_list_child">
                        <img
                          alt=""
                          className="sm:h-[50px] md:h-[70px] pages_deeplinkkid_kid_list_avatar_img"
                          src={
                            kid.gender === 'male' ? boyImageSrc : girlImageSrc
                          }
                        />
                        <div className="text-[15px] pages_deeplinkkid_kid_list_text sm:text-[35px] landscape:2xl:text-[35px] landscape:sm:text-[15px] mt-3 font-bold">
                          {kid.nickname.trim().length === 0
                            ? kid.firstName
                            : kid.nickname}
                        </div>
                      </div>
                    </div>
                  )
                })
              : null}
          </div>
        </div>
      </AppContent>
    </>
  )
}

export default DeeplinkSelectKid
