import { ContentTitle } from '@components/ContentTitle'
import { AppBar } from '@components/Layout/AppBar'
import { AppContent } from '@components/Layout/AppContent'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import {
  LockedVideoOverlay,
  PlayVideoOverlay,
} from '@components/Overlay/OverlayButton'
import Popup from '@components/Popup'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { useAllEpisode } from '@hooks/data/fetchers/use-all-episodes'
import { usePurchases } from '@hooks/use-purchases'
import {
  HAVENT_FINISHED_VIDEOS,
  REQUIRES_SUBSCRIBE_MESSAGE,
  SUBSCRIBE_NOW_MESSAGE,
  THIS_CONTENT_MESSAGE,
} from '@src/constants/messages'
import { IChapterListItem, IEpisode } from '@src/types/api.interface'
import { logEvent } from '@utils/firebase-analytics'
import Helper from '@utils/Helpers'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

const MaktabahVideo: FC = () => {
  const { allEpisode, isLoadingEpisode } = useAllEpisode()
  const [isVisable, setIsVisable] = useState(false)
  const [isFreeContent, setIsFreeContent] = useState(false)

  const { kidProfile: profile, profileType } = useProfile()

  const [hasProAccess, setHasProAccess] = useState(false)

  const purchases = usePurchases()

  const [isLoadingPayment, setIsLoadingPayment] = useState(true)

  useEffect(() => {
    purchases
      .isHavingProAccess(profile?.level === 1 ? 'level-1' : 'level-2')
      .then(setHasProAccess)
      .then(() => {
        setIsLoadingPayment(false)
      })
  }, [profile?.level, purchases])

  useEffect(() => {
    logEvent('open_video_library')
  }, [])
  if (profileType !== 'child') return
  return (
    <>
      <Helmet>
        <title className="maktabah_video_page-main-title">
          عالم زيدو - أطفال
        </title>
      </Helmet>
      {isLoadingEpisode || isLoadingPayment ? <AppProgressBar /> : null}
      <AppBar backButtonLocation="/library" fixed showKidAvatar title="" />
      <AppContent className="bg-no-repeat bg-cover bg-center bg-blue ">
        <ContentTitle noShadow textColor="text-black" title="فيديوهات" />
        <div className="maktabah_video_page-container w-full flex flex-col mt-5 items-start xl:items-center justify-center">
          {!isLoadingPayment
            ? (allEpisode ?? []).map((oneEpisode: IEpisode, idx: number) => {
                if (!oneEpisode?.chapters.length) return null

                let prevEpisodeWatched = true
                if (idx !== 0) {
                  prevEpisodeWatched = allEpisode[idx - 1].watched
                }

                return (
                  <EpisodeChapters
                    chapters={oneEpisode?.chapters ?? []}
                    episodeSlug={oneEpisode?.slug ?? ''}
                    hasProAccess={hasProAccess}
                    key={oneEpisode._id}
                    kidLevel={profile.level}
                    prevEpisodeWatched={prevEpisodeWatched}
                    setIsFreeContent={setIsFreeContent}
                    setIsVisable={setIsVisable}
                    title={oneEpisode.title}
                  />
                )
              })
            : null}
        </div>

        <Popup
          acceptLink={!hasProAccess ? '/parent?redirect=subscribe' : null}
          buttonText={!hasProAccess ? SUBSCRIBE_NOW_MESSAGE : null}
          message={
            hasProAccess || isFreeContent
              ? HAVENT_FINISHED_VIDEOS
              : REQUIRES_SUBSCRIBE_MESSAGE
          }
          onClose={() => {
            setIsFreeContent(false)
            setIsVisable(false)
          }}
          title={hasProAccess || isFreeContent ? '' : THIS_CONTENT_MESSAGE}
          visible={isVisable}
        />
      </AppContent>
    </>
  )
}

export default MaktabahVideo

const EpisodeChapters: FC<{
  title: string
  episodeSlug: string
  chapters: IChapterListItem[]
  hasProAccess: boolean
  setIsVisable: (isVisible: boolean) => void
  setIsFreeContent: (isFreeContent: boolean) => void
  prevEpisodeWatched: boolean
  kidLevel?: number
}> = (props) => {
  return (
    <div className="maktabah_video_page-episode-chapters">
      <span className="maktabah_video_page-episode-chapters-title font-bold pr-4 text-base md:text-2xl">
        {props.title}
      </span>
      <div className="maktabah_video_page-episode-chapters-wrapper ml-3 pb-3 flex flex-row mt-1">
        {props.chapters.map(function (chapter: IChapterListItem, idx: number) {
          let prevChapterWatched =
            Number(props?.kidLevel) === 2
              ? props?.prevEpisodeWatched ?? false
              : true

          if (idx !== 0) {
            prevChapterWatched = props.chapters[idx - 1].watched
          }

          const locked =
            (!props.hasProAccess && (!chapter.isFree || !prevChapterWatched)) ||
            (props.hasProAccess && !prevChapterWatched)
          return (
            <ChapterThumbnail
              chapterData={chapter}
              episodeSlug={props.episodeSlug}
              key={chapter._id}
              locked={locked}
              setIsFreeContent={props.setIsFreeContent}
              setIsVisable={props.setIsVisable}
            />
          )
        })}
      </div>
    </div>
  )
}

const ChapterThumbnail: FC<{
  episodeSlug: string
  chapterData: IChapterListItem
  locked: boolean
  setIsVisable: (isVisible: boolean) => void
  setIsFreeContent: (isFreeContent: boolean) => void
}> = (props) => {
  const url = '/episode/' + props.episodeSlug + '/' + props.chapterData.slug
  const image = Helper.MediaURL(props.chapterData.thumbnail)

  return (
    <div
      className={`maktabah_video_page-chapter-thumbnail-wrapper-${url} w-[165px] lg:w-[295px] grid grid-cols-1 place-items-center justify-items-center sm:max-w-[165px] md:max-w-[215px] lg:max-w-[295px] mr-3 relative text-center border-[5px] p-[2px] border-blue-900 bg-blue-900 rounded-xl`}
    >
      {props.locked ? (
        <LockedVideoOverlay
          setIsVisable={props.setIsVisable}
          {...(props.chapterData.isFree && {
            setIsFreeContent: props.setIsFreeContent,
          })}
        />
      ) : null}
      <Link
        className={`maktabah_video_page-chapter-thumbnail-link-${url} grid grid-cols-1 place-items-center justify-items-center`}
        to={{
          pathname: props.locked ? '' : url,
          state: 'maktabah',
        }}
      >
        <div
          className={`maktabah_video_page-thumbnail-img-wrapper-${url} h-[110px] w-[150px] lg:h-[208px] lg:w-[280px] sm:max-h-[110px] md:max-h-[145px] lg:max-h-[208px] sm:max-w-[150px] md:max-w-[200px] lg:max-w-[280px] relative rounded-xl overflow-hidden`}
        >
          <img
            alt=""
            className={`maktabah_video_page-thumbnail-img-${image} w-full h-full`}
            draggable="false"
            src={image}
          />
          {!props.locked ? <PlayVideoOverlay /> : null}
        </div>
        <div
          className={`maktabah_video_page-thumbnail-${props.chapterData.title} text-white text-base lg:text-xl truncate mt-2 w-full`}
        >
          {props.chapterData.title}
        </div>
      </Link>
    </div>
  )
}
