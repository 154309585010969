import React from 'react'

const ParentBackButtonIcon = () => {
  return (
    <svg
      className="w-[35px] lg:w-[45px] drop-shadow-[3px_3px_0px_#75A012] parent_back_button_icon-svg"
      // width="51"
      // height="47"
      fill="none"
      viewBox="0 0 51 47"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.4095 0.27832C51.1636 0.27832 50.6355 15.0123 50.6355 23.4357C50.6355 31.8592 51.1636 46.5932 26.4095 46.5932H24.6489C-0.108842 46.5896 0.419342 31.8592 0.419342 23.4357C0.419342 15.0123 -0.108842 0.27832 24.6489 0.27832H26.4095Z"
        fill="#99BC2D"
      />
      <g opacity="0.15" style={{ mixBlendMode: 'screen' }}>
        <path
          d="M10.117 16.396C10.117 13.0095 12.9234 8.98633 22.0751 8.98633C31.2267 8.98633 18.5997 8.98633 24.3921 8.98633C30.1845 8.98633 28.3957 12.7214 26.3287 17.7442C23.8604 23.7345 20.1772 33.9437 14.2545 32.6062C9.2156 31.4679 10.117 22.1373 10.117 16.396Z"
          fill="white"
        />
      </g>
      <g clipPath="url(#clip0_1371_4555)">
        <path
          d="M16.0042 24.8864H37.2576C38.2948 24.8864 39.1333 24.0393 39.1333 22.9915C39.1333 21.9437 38.2948 21.0967 37.2576 21.0967H16.0042C14.967 21.0967 14.1285 21.9437 14.1285 22.9915C14.1285 24.0393 14.967 24.8864 16.0042 24.8864Z"
          fill="white"
        />
        <path
          d="M29.4934 33.4939C30.0023 33.4939 30.5112 33.2834 30.8796 32.877L38.6442 24.2694C39.2937 23.5447 39.2937 22.4431 38.6442 21.7184L30.8796 13.1108C30.1817 12.3372 28.999 12.2834 28.2332 12.9884C27.4674 13.6935 27.4141 14.8882 28.1121 15.6618L34.728 22.9964L28.1121 30.3309C27.4141 31.1045 27.4723 32.2992 28.2332 33.0043C28.5919 33.3372 29.0427 33.4988 29.4934 33.4988V33.4939Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1371_4555">
          <rect
            fill="white"
            height="21"
            transform="matrix(-1 0 0 1 39.1333 12.4941)"
            width="25"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ParentBackButtonIcon
