import useSwr from 'swr'
import { IEpisode } from '@src/types/api.interface'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { FetchError } from '@utils/FetchError'

export function useAllEpisode() {
  const { kidProfile: profile } = useProfile()

  const kidId = profile?._id

  const url = profile?.level
    ? `/level/${profile.level}/episodes/all/chapters/${kidId}`
    : null

  const { data, error, mutate } = useSwr<IEpisode[], FetchError>(url)

  return {
    allEpisode: data ?? [],
    error,
    isLoadingEpisode: !error && !data,
    mutateEpisode: mutate,
  }
}
