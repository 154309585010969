import { AppContent, BackButton } from '@components/Layout'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import { useMobileView } from '@hooks/useMobileView'
import { MESSAGE_REGISTER, MESSAGE_LOGIN } from '@src/constants'
import { IForgotPassword } from '@src/types/api.interface'
import { FetchError } from '@utils/FetchError'
import Helper from '@utils/Helpers'
import HelperAPI from '@utils/helpers/helper.api'
import React, { FC, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const ResetPassword: FC = () => {
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [showForm, setShowForm] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState(false)
  const param = useParams<{ id: string; token: string }>()
  const url = `/auth/reset-password`

  const isMobileView = useMobileView()

  const revealPasswordOnHoldButton = useRef(null)
  const revealPasswordOnHoldInput = useRef(null)
  const revealConfirmPasswordOnHoldButton = useRef(null)
  const revealConfirmPasswordOnHoldInput = useRef(null)

  useEffect(() => {
    'mousedown touchstart'.split(' ')?.forEach((e) => {
      revealPasswordOnHoldButton.current?.addEventListener(e, () => {
        revealPasswordOnHoldInput.current.type = 'text'
      })
    })

    'mouseup mouseleave touchend'.split(' ')?.forEach((e) => {
      revealPasswordOnHoldButton.current?.addEventListener(e, () => {
        revealPasswordOnHoldInput.current.type = 'password'
      })
    })

    'mousedown touchstart'.split(' ')?.forEach((e) => {
      revealConfirmPasswordOnHoldButton.current?.addEventListener(e, () => {
        revealConfirmPasswordOnHoldInput.current.type = 'text'
      })
    })

    'mouseup mouseleave touchend'.split(' ')?.forEach((e) => {
      revealConfirmPasswordOnHoldButton.current?.addEventListener(e, () => {
        revealConfirmPasswordOnHoldInput.current.type = 'password'
      })
    })
  }, [
    revealPasswordOnHoldButton,
    revealPasswordOnHoldInput,
    revealConfirmPasswordOnHoldButton,
    revealConfirmPasswordOnHoldInput,
  ])

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.name === 'password') {
      setPassword(event.target.value)
    } else if (event.target.name === 'confirm-password') {
      setConfirmPassword(event.target.value)
    }
  }

  const onHandleUpdatePasswordAPI = async (password: string) => {
    try {
      const response = await HelperAPI.ApiRequest<IForgotPassword>(url, {
        method: 'PUT',
        body: JSON.stringify({
          id: param.id,
          password,
          token: param.token,
        }),
      })

      return response.status
    } catch (error) {
      if (error instanceof FetchError) return error.status

      return 500
    }
  }

  const handleSubmitPassword: React.FormEventHandler<HTMLFormElement> = async (
    event
  ) => {
    event.preventDefault()

    const validate = Helper.validatePassword(password)

    if (validate.length > 0) {
      toast.error(validate[0])
      return
    }

    if (password !== confirmPassword) {
      // ENGLISH: Please make sure that the two fields match
      toast.error('من فضلك ادخل كلمه مرور متطابقه')
      return
    }

    setIsLoading(true)
    const response = await onHandleUpdatePasswordAPI(password)
    setIsLoading(false)

    if (response === 404) {
      // ENGLISH: The requested content is not available
      toast.error('المحتوى المطلوب غير متوفر')
    } else if (response === 200) {
      // ENGLISH: Password has been updated successfully
      toast.success('لقد تم تحديث كلمة المرور بنجاح')
      setShowForm(false)
    } else {
      toast.error('نعتذر عن وجود مشكلة حاليا برجاء المحاولة مرة اخري')
    }
  }

  return (
    <>
      <Helmet>
        <title className="reset_password_page_title">
          عالم زيدو - تسجيل الدخول
        </title>
      </Helmet>

      {isLoading ? <AppProgressBar /> : null}
      <AppContent
        className="reset_password_page_app_content bg-no-repeat bg-cover bg-center bg-v3  "
        haveFooter={!isMobileView}
      >
        <div className="reset_password_page_container absolute left-2 top-2">
          <div className="reset_password_page_wrapper flex w-full justify-end mt-1 xl:ml-14 gap-3 lg:gap-4 ">
            <Link
              className="reset_password_page_register_link cursor-pointer bg-v3-green rounded-3xl text-lg lg:text-xl text-center text-[#2B3C94] hover:text-white px-[16px] md:px-[22px] py-[8px] lg:px-[40px] lg:py-[11px] shadow-[3px_3px_0px_0px_#5E7426]"
              to="/register"
            >
              {MESSAGE_REGISTER}
            </Link>

            <Link
              className="reset_password_page_login_link cursor-pointer border hover:text-white hover:border-white border-v3-green rounded-3xl text-lg lg:text-xl text-center text-v3-green px-[16px] py-[8px] lg:px-[22px] lg:py-[11px]"
              to="/login"
            >
              {MESSAGE_LOGIN}
            </Link>
          </div>
        </div>
        <div className="reset_password_page_back_button_wrapper absolute top-2 right-2 ">
          <BackButton to="/" />
        </div>
        <div className="reset_password_page_main w-full flex items-center justify-center h-[98vh] lg:h-[94vh]">
          <div
            className={`reset_password_page_content_wrapper ${
              !isMobileView && 'bg-[#4E64BD]'
            } rounded-3xl text-white flex flex-col items-center justify-center h-full lg:h-[70%] py-4 lg:py-12 w-[60%] lg:w-[40%] relative`}
          >
            {showForm ? (
              <form
                className="reset_password_page_form overflow-hidden w-[80%] flex flex-col items-center justify-center "
                onSubmit={handleSubmitPassword}
              >
                <h1 className="reset_password_page_form_main_heading text-2xl lg:text-xl xl:text-2xl text-center pt-2">
                  إعادة تعيين كلمة المرور
                </h1>
                <h2 className="reset_password_page_form_sub_heading mt-2 lg:mt-6 lg:text-lg xl:text-xl text-center">
                  من فضلك اكتب كلمة مرور جديدة
                </h2>

                <div className="reset_password_page_form_password_main_wrapper flex w-[80%] md:w-[70%] lg:w-[80%] relative  mt-3">
                  <div className="reset_password_page_form_password_main inline-flex z-20 absolute left-[3px] portrait:sm:top-[3px] landscape:top-[1px] landscape:2xl:top-[3px] h-[20px] mt-0 top-[1px] p-4 rounded-l-full ">
                    <button
                      className="reset_password_page_form_password_reveal_button appearance-none passwordReveal w-[15px] sm:h-[15px] mt-[-5] h-[10px] landscape:mt-[-8px] landscape:2xl:mt-[0px]"
                      ref={revealPasswordOnHoldButton}
                      tabIndex={9}
                      type="button"
                    />
                  </div>
                  <input
                    autoComplete="off"
                    className="reset_password_page_form_password_input mb-2 border portrait:sm:h-[60px] landscape:h-[35px] landscape:2xl:h-[50px] p-4 pl-[3rem] pr-6 bg-[#DFF2FF] text-[#515977] border-x-4 border-y-2 focus:border-[#A7DCFF] border-[#A7DCFF] rounded-3xl drop-shadow-[0px_3px_0px_#81A2B9] w-full"
                    name="password"
                    onChange={handleChange}
                    placeholder="كلمة المرور الجديدة"
                    ref={revealPasswordOnHoldInput}
                    required
                    type="password"
                    value={password}
                  />
                </div>

                <div className="reset_password_page_form_confirm_password_main_wrapper flex relative w-[80%] md:w-[70%] lg:w-[80%] mt-6">
                  <div className="reset_password_page_form_password_main inline-flex z-20 absolute left-[3px] portrait:sm:top-[3px] landscape:top-[1px] landscape:2xl:top-[3px] h-[20px] mt-0 top-[1px] p-4 rounded-l-full ">
                    <button
                      className="reset_password_page_form_confirm_password_reveal_button appearance-none passwordReveal w-[15px] sm:h-[15px] mt-[-5] h-[10px] landscape:mt-[-8px] landscape:2xl:mt-[0px]"
                      ref={revealConfirmPasswordOnHoldButton}
                      tabIndex={9}
                      type="button"
                    />
                  </div>
                  <input
                    autoComplete="off"
                    className="reset_password_page_form_confirm_password_input mb-2 border portrait:sm:h-[60px] landscape:h-[35px] landscape:2xl:h-[50px] p-4 pl-[3rem] pr-6 bg-[#DFF2FF] text-[#515977] border-x-4 border-y-2 focus:border-[#A7DCFF] border-[#A7DCFF] rounded-3xl drop-shadow-[0px_3px_0px_#81A2B9] w-full"
                    name="confirm-password"
                    onChange={handleChange}
                    placeholder="تأكيد كلمة المرور الجديدة"
                    ref={revealConfirmPasswordOnHoldInput}
                    required
                    type="password"
                    value={confirmPassword}
                  />
                </div>
                <input
                  className="reset_password_page_form_submit_btn cursor-pointer lg:text-xl bg-[#B3D65B] rounded-3xl px-8 lg:px-12 py-2 my-4 lg:mt-6 xl:mt-16  text-[#2B3C94] drop-shadow-[2px_3px_0px_#5E7426] text-center"
                  disabled={isLoading}
                  id=""
                  type="submit"
                  value="موافق"
                />
              </form>
            ) : (
              <div className="reset_password_page_form_login_url_wrapper text-white  text-lg lg:text-xl 2xl:text-2xl text-center mt-9">
                <Link
                  className="reset_password_page_form_login_url underline"
                  to="/login"
                >
                  تسجيل الدخول
                </Link>
              </div>
            )}
            {/* <h3 className="text-[#3bac6e] lg:text-lg 2xl:text-xl  text-center mt-2">
            {message}
          </h3>
          <h3 className="text-[#ee0000]  text-center mt-2">{error}</h3> */}
          </div>
        </div>
      </AppContent>
    </>
  )
}

export default ResetPassword
