// just work for 3 part host max :
// 1 part : http://localhost/
// 2 part : http://domain.com/
// 3 part : http://sub.domain.com/
export function getDomain() {
  const full = window.location.host
  // window.location.host is subdomain.domain.com
  const parts = full.split(':')[0].split('.')
  if (parts.length === 1) return parts[0] // for localhost
  if (parts.length === 2) return `${parts[0]}.${parts[1]}` // for http://domain.com

  // We remove the first part
  parts.shift()
  return parts.join('.')
}
