import { FC } from 'react'
import { IAnswer, IQuestionWithAnswers } from '@src/types/api.interface'
import { ChallengeReducers } from '@hooks/use-challenge-reducer'
import {
  QuestionAnswer,
  QuestionGoNext,
  QuestionSubmit,
  QuestionText,
} from './challenge-controls'

export const ChallengeWithImageQuestionAndAnswers: FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  question: IQuestionWithAnswers | any
  challengeReducers: ChallengeReducers
}> = (props) => {
  return (
    <div className="flex flex-col sm:flex-row sm:justify-between min-h-screen pt-16 sm:pt-0 challenge_w_img_qstn_ansr-container">
      <div className="flex justify-center items-center sm:border-l-2 sm:w-1/2 mt-4 sm:mt-0 challenge_w_img_qstn_ansr-wrapper">
        <div className="pt-0 sm:pt-16 challenge_w_img_qstn_ansr-qstn-txt">
          {props.question ? <QuestionText question={props.question} /> : null}
        </div>
      </div>

      <div className="sm:w-1/2 flex flex-col justify-center challenge_w_img_qstn_ansr-ansr-container">
        <div className="max-w-3xl mx-auto mt-4 sm:mt-0 px-2 sm:px-4 grid gap-2 sm:gap-1 grid-cols-2 sm:grid-cols-2 challenge_w_img_qstn_ansr-ansr-wrapper">
          {props.question?.answers.map((answer: IAnswer, index: number) => {
            return (
              <QuestionAnswer
                answer={answer}
                isCorrect={
                  props.challengeReducers.state.correctAnswer === index
                }
                isSelected={
                  props.challengeReducers.state.selectedAnswer === index
                }
                key={answer.text}
                onClick={() => props.challengeReducers.onSelectAnswer(index)}
                showAnswer={
                  typeof props.challengeReducers.state.correctAnswer ===
                  'number'
                }
              />
            )
          })}
        </div>

        <div className="flex justify-center sm:bottom-0 sm:right-1/2 sm:mb-2  sm:translate-x-1/2 mt-4 sm:mt-0 sm:absolute challenge_w_img_qstn_ansr-qstn-sbmt-container">
          {props.challengeReducers.state.correctAnswer === null ? (
            <QuestionSubmit
              disabled={
                props.challengeReducers.state.selectedAnswer === null ||
                props.challengeReducers.state.submitting
              }
              onClick={props.challengeReducers.onSubmit}
            />
          ) : (
            <QuestionGoNext onClick={props.challengeReducers.goNext} />
          )}
        </div>
      </div>
    </div>
  )
}
