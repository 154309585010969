import React, { useState, FC } from 'react'
import ReactDOM from 'react-dom'

import './progress-bar.css'

export const AppProgressBar: FC = React.memo(function AppProgressBar() {
  const [progressBarRoot] = useState(() => {
    return document.getElementById('progress-bar-root')
  })

  if (!progressBarRoot) return null

  return ReactDOM.createPortal(
    <div className="fixed w-full h-2 z-[99999] bg-gray-800 component_layout_progress_bar__app_container">
      <div className="h-full shim-green" />
    </div>,
    progressBarRoot
  )
})
