import { Capacitor } from '@capacitor/core'
import { captureException } from '@sentry/react'
import {
  SingularEventAndroid,
  SingularEventIOS,
} from '@src/SingularEventCapacitorPlugin'
import { singularSdk, SingularConfig } from 'singular-sdk'

export const SingularInit = async () => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // const Singular = (window as any).singularSdk
    const config = new SingularConfig(
      'zadgroup_1362075c',
      '0a840fdabbfe2e2357d541d53e8d28d4',
      'zidoworld.com'
    )
    singularSdk.init(config)
    return await new Promise((resolve) => {
      setTimeout(() => {
        // console.log('config done', Singular.getSingularDeviceId())
        resolve(true)
      }, 3000)
    })
  } catch (err) {
    captureException('error registering singular')
    return null
    //
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const SingularEvent = (title = '', message: any = null) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Singular = (window as any).singularSdk
  // const platform = Capacitor.getPlatform()
  // console.log({ platform, title, message })
  if (Capacitor.getPlatform() === 'web') {
    Singular.event(title, message)
  }
  if (Capacitor.getPlatform() === 'android') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(SingularEventAndroid as any).event({ title, message })
  }
  if (Capacitor.getPlatform() === 'ios') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(SingularEventIOS as any).event({ title, message })
  }
}
