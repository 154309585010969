import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { IEpisodeListItem } from '@src/types/api.interface'
import { FetchError } from '@utils/FetchError'
import useSwr from 'swr'

export function useEpisodeInSeries(seriesId: string): {
  episodes?: IEpisodeListItem[]
  error?: FetchError
  isLoadingEpisodes: boolean
} {
  const { kidProfile: profile } = useProfile()

  const url = profile?.level
    ? `/level/${profile.level}/episodes/series/${seriesId}`
    : null

  const { data, error } = useSwr<IEpisodeListItem[], FetchError>(url)

  return {
    episodes: data,
    error,
    isLoadingEpisodes: !error && !data,
  }
}
