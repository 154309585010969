import RadioButton from './components/RadioButton'
import { RadioSelectionProps } from './types'

const RadioSelection: React.FC<RadioSelectionProps> = ({
  options,
  selectedValue,
  name,
  onChange,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value)
  }
  return (
    <div className="flex justify-center component_radio_selection_container">
      {options.map((option) => (
        <RadioButton
          checked={selectedValue === option.value}
          className="component_radio_selection_radio_input_element"
          key={option.value}
          label={option.label}
          name={name}
          onChange={handleChange}
          value={option.value}
        />
      ))}
    </div>
  )
}

export default RadioSelection
