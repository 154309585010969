import Config from '@config/index'

const level2Map0 = `${Config.publicURL}/images/level2_map_0.svg`
const level3Map0 = `${Config.publicURL}/images/level3_map_0.svg`
const level3Map1 = `${Config.publicURL}/images/level3_map_1.svg`
const level3Map2 = `${Config.publicURL}/images/level3_map_2.svg`
const level3Map3 = `${Config.publicURL}/images/level3_map_3.svg`
const level3Map4 = `${Config.publicURL}/images/level3_map_4.svg`

export const SVG_MAPS_LEVEL_2 = [level2Map0]

export const SVG_MAPS_LEVEL_3 = [
  level3Map0,
  level3Map1,
  level3Map2,
  level3Map3,
  level3Map4,
]

export const COMPETITION_IFRAME_ID = 'competition-challenge'

export const LOCKED_ICON = `${Config.publicURL}/images/lock-icon.svg`
export const LOCKED_ICON_NEW = `${Config.publicURL}/images/v2/locked-icon-new.png`
export const LOCKED_ICON_SMALL = `${Config.publicURL}/images/locked-icon-small.png`

export const PLAY_ICON = `${Config.publicURL}/images/play-icon.svg`
export const PLAY_ICON_NEW = `${Config.publicURL}/images/play-icon-new.png`

export const DEFAULT_THUMBNAIL = `${Config.publicURL}/images/default_thumbnail.png`
export const DEFAULT_BOOK_THUMBNAIL = `${Config.publicURL}/images/book-thumbnail.png`
export const DEFAULT_GAME_THUMBNAIL = `${Config.publicURL}/images/game-thumbnail.png`
export const GAME_ICON = `${Config.publicURL}/images/game-icon.png`
export const BOOK_ICON = `${Config.publicURL}/images/book-icon.png`
export const VIDEO_ICON_SQUARE = `${Config.publicURL}/images/video-icon-square.png`
export const GAME_ICON_SQUARE = `${Config.publicURL}/images/game-icon-square.png`
export const GAME_ICON_WHITE = `${Config.publicURL}/images/game-icon-white.png`
export const BOOK_ICON_SQUARE = `${Config.publicURL}/images/book-icon-square.png`
export const BOOK_ICON_WHITE = `${Config.publicURL}/images/book-icon-white.png`
export const SERIES_THUMBNAIL = `${Config.publicURL}/images/series-thumbnail.png`
export const TAALAM_TEXT = `${Config.publicURL}/images/v2/taalam-text@3x.png`
export const TAALAM_TEXT_GREEN = `${Config.publicURL}/images/v2/taalam-text-green@3x.png`
export const KID_AVATAR_ICON = `${Config.publicURL}/images/v3/kid-avatar.svg`
export const KID_AVATAR_MALE_NO_EYES_ICON = `${Config.publicURL}/images/v3/avatar-no-eyes-male.png`
export const KID_AVATAR_FEMALE_NO_EYES_ICON = `${Config.publicURL}/images/v3/avatar-no-eyes-female.png`
export const MINI_HANDBOOK_ICON = `${Config.publicURL}/images/v2/mini-handbook-icon.png`
export const OUTLINE_PENCIL = `${Config.publicURL}/images/outline-pencil.png`
export const OUTLINE_TRASH = `${Config.publicURL}/images/outline-trash.png`
export const ACTIVITY_ARROW = `${Config.publicURL}/images/activity_arrow.png`
export const CONNECTION_ARROW = `${Config.publicURL}/images/connection-arrow.png`
export const CONNECTION_ARROW_2X = `${Config.publicURL}/images/connection-arrow@2x.png`

export const QUESTION_MARK_ICON = `${Config.publicURL}/images/v3/question-mark-icon.png`
export const APPROACH_ICON = `${Config.publicURL}/images/v3/approach-icon.png`

export const LEFT_ARROW = `${Config.publicURL}/images/left-arrow.png`
export const RIGHT_ARROW = `${Config.publicURL}/images/right-arrow.png`

export const STAR = {
  GREEN: `${Config.publicURL}/images/star-green.png`,
  PURPLE: `${Config.publicURL}/images/star-purple.png`,
  BLUE: `${Config.publicURL}/images/star-blue.png`,
  ORANGE: `${Config.publicURL}/images/star-orange.png`,
  YELLOW: `${Config.publicURL}/images/star-yellow.png`,
}

export const subLibraryDefault = [
  {
    path: '/library/video',
    icon1X: `${Config.publicURL}/images/maktabah/icon-video@1x.png`,
    icon2X: `${Config.publicURL}/images/maktabah/icon-video@2x.png`,
    icon3X: `${Config.publicURL}/images/maktabah/icon-video@3x.png`,
    isLock: false,
    order: 1,
  },

  {
    path: '/library/book',
    icon1X: `${Config.publicURL}/images/maktabah/icon-book@1x.png`,
    icon2X: `${Config.publicURL}/images/maktabah/icon-book@2x.png`,
    icon3X: `${Config.publicURL}/images/maktabah/icon-book@3x.png`,
    isLock: false,
    order: 2,
  },
  {
    path: '/library/game',
    icon1X: `${Config.publicURL}/images/maktabah/icon-game@1x.png`,
    icon2X: `${Config.publicURL}/images/maktabah/icon-game@2x.png`,
    icon3X: `${Config.publicURL}/images/maktabah/icon-game@3x.png`,
    isLock: false,
    order: 3,
  },
  // To be used in the future
  // {
  //   path: '/library/science',
  //   icon1X: `${Config.publicURL}/images/maktabah/icon-science@1x.png`,
  //   icon2X: `${Config.publicURL}/images/maktabah/icon-science@2x.png`,
  //   icon3X: `${Config.publicURL}/images/maktabah/icon-science@3x.png`,
  //   isLock: true,
  //   order: 4,
  // },
  // {
  //   path: '/library/paint',
  //   icon1X: `${Config.publicURL}/images/maktabah/icon-paint@1x.png`,
  //   icon2X: `${Config.publicURL}/images/maktabah/icon-paint@2x.png`,
  //   icon3X: `${Config.publicURL}/images/maktabah/icon-paint@3x.png`,
  //   isLock: true,
  //   order: 5,
  // },
  // {
  //   path: '/library/language',
  //   icon1X: `${Config.publicURL}/images/maktabah/icon-language@1x.png`,
  //   icon2X: `${Config.publicURL}/images/maktabah/icon-language@2x.png`,
  //   icon3X: `${Config.publicURL}/images/maktabah/icon-language@3x.png`,
  //   isLock: true,
  //   order: 6,
  // },
  // {
  //   path: '/library/art',
  //   icon1X: `${Config.publicURL}/images/maktabah/icon-art@1x.png`,
  //   icon2X: `${Config.publicURL}/images/maktabah/icon-art@2x.png`,
  //   icon3X: `${Config.publicURL}/images/maktabah/icon-art@3x.png`,
  //   isLock: true,
  //   order: 7,
  // },
  // {
  //   path: '/library/story',
  //   icon1X: `${Config.publicURL}/images/maktabah/icon-story@1x.png`,
  //   icon2X: `${Config.publicURL}/images/maktabah/icon-story@2x.png`,
  //   icon3X: `${Config.publicURL}/images/maktabah/icon-story@3x.png`,
  //   isLock: true,
  //   order: 8,
  // },
]

// EN : Warning: This action cannot be undone. Continue?
export const DELETE_KID_MESSAGE_WARNING =
  'هذا الإجراء لا يمكن التراجع عنه. هل تريد المتابعة؟'

// EN : A) Delete profile
export const DELETE_KID_MESSAGE_BUTTON = 'المتابعة'

// Deleting the kid’s profile will remove all the kid’s activities, are you sure you want to proceed?
export const CONFIRM_DELETE_KID_MESSAGE =
  'حذف ملف الطفل سيتبعه حذف سجل الأنشطة التي قام بها طفلك'

// EN : Choice B: No, I need to keep my kid’s profile
export const CONFIRM_DELETE_KID_BUTTON = 'تأكيد'

export const DELETE_KID_TITLE = 'تحذير'

export const DELETE_ACCOUNT_BUTTON = 'حذف الحساب'

export const DELETE_ACCOUNT_TITLE = 'تحذير'

export const DELETE_ACCOUNT_WITH_SUBS_TITLE = 'تنبيه'

export const DELETE_ACCOUNT_MESSAGE_WARNING =
  'هذا الإجراء لا يمكن التراجع عنه. هل تريد المتابعة؟'

export const DELETE_ACCOUNT_MESSAGE_CONFIRMATION =
  'حذف لحساب سيتبعه حذف كافة البيانات و الأنشطة المسجلة'

export const DELETE_ACCOUNT_MSSG_BUTTON_WARNING = 'المتابعة'

export const DELETE_ACCOUNT_MSSG_BUTTON_CONFIRMATION = 'تأكيد'

export const DELETE_HAVE_SUBS_MSG =
  'لديك اشتراك فعال . هل  أنت متأكد من الرغبة في حذف الحساب؟'

export const UNSUBSCRIBE_MESSAGE =
  'سيتم توجيهكم إلى المتجر الذي قمتم بالاشتراك منه لإلغاء الاشتراك'
export const UNSUBSCRIBE_MESSAGE_WEB =
  'هل أنت متأكد من الرغبة فى إلغاء الاشتراك'

export const LOGGING_IN = 'تم تسجيل الدخول بنجاح'

export const INVALID_USER_ID = 'كود المستخدم غير صحيح'

export const CHOOSE_PACKAGE_PAGE_TITLE_1 = 'استمتع بالوصول غير المحدود'

export const CHOOSE_PACKAGE_PAGE_TITLE_2 = 'لكامل محتوانا الحصري'

export const CHOOSE_PACKAGE_SUBTITLE_1 = 'وشاهد كل أصناف المحتوى لدينا'

export const CHOOSE_PACKAGE_SUBTITLE_2 =
  'المسلسلات - الكتب - الألعاب - السباقات'

export const MESSAGE_VIEW_SUBSCRIPTION = 'الاشتراك'
export const thisYear = new Date().getFullYear()
export const COMPETITION_MESSAGE = 'انتظروا جميل المسابقات قريبا'
export const COMPETITION_POPUP_TITLE = 'تنبيه'
