import { useCallback, useEffect, useState } from 'react'

const useIFrameIsFullscreen = () => {
  const [isFullScreen, setIsFullScreen] = useState(false)

  const changeHandler = useCallback(() => {
    const isIFrameFullscreen =
      !!document.fullscreenElement ||
      !!document.webkitFullscreenElement ||
      !!document.mozFullScreenElement

    setIsFullScreen(isIFrameFullscreen)
  }, [])

  useEffect(() => {
    document.addEventListener('fullscreenchange', changeHandler)
    document.addEventListener('webkitfullscreenchange', changeHandler)
    document.addEventListener('mozfullscreenchange', changeHandler)

    return () => {
      document.removeEventListener('fullscreenchange', changeHandler)
      document.removeEventListener('webkitfullscreenchange', changeHandler)
      document.removeEventListener('mozfullscreenchange', changeHandler)
    }
  }, [changeHandler])

  return { isFullScreen }
}

export default useIFrameIsFullscreen
