import { useCallback, useEffect } from 'react'
import { useActivity } from '@hooks/data/fetchers/use-activity'
import HelperAPI from '@utils/helpers/helper.api'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { ContentActivityProps } from './types'
import {
  IResponseCompletedChapter,
  ITrackingSubmit,
} from '@src/types/api.interface'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import { useEpisodeAllActivities } from '@hooks/data/fetchers/useEpisodeAllActivities'
import { useOpenedActivities } from '@hooks/data/fetchers/use-opened-activities'

const useContentActivity = (props: ContentActivityProps) => {
  const { epiSlug } = useParams<{ epiSlug: string }>()
  const { episode, isLoadingEpisode } = useEpisodeAllActivities({
    slug: epiSlug,
  })
  const { activity, isLoadingActivity } = useActivity({ id: props.content._id })
  const { kidProfile } = useProfile()
  const {
    openedActivities = [],
    isLoadingOpenedActivities,
    mutateOpenedActivities,
  } = useOpenedActivities(kidProfile._id, episode._id, kidProfile.level)

  const openedActivity = useCallback(async () => {
    if (activity?.category && kidProfile && episode._id) {
      await HelperAPI.ApiRequest(
        `/level/${kidProfile.level}/episodes/activities/view`,
        {
          method: 'POST',
          body: JSON.stringify({
            kid: kidProfile._id,
            activity: activity._id,
            datetime: new Date(),
            episode: episode._id,
          }),
        }
      )
      mutateOpenedActivities()
    }
  }, [activity, kidProfile, episode._id, mutateOpenedActivities])

  const onTimeTrackSubmit = useCallback(
    async (data: Partial<ITrackingSubmit>) => {
      try {
        if (!kidProfile._id) return
        const url = `/trackings/track`
        await HelperAPI.ApiRequest(url, {
          method: 'POST',
          body: JSON.stringify({
            ...data,
            reference: 'activities',
            kid: kidProfile._id,
          }),
        })
      } catch (e) {
        toast.error('Something went wrong')
      }
    },
    [kidProfile]
  )

  useEffect(() => {
    const start = Date.now()
    return () => {
      const end = Date.now()
      if (episode._id && activity?._id) {
        onTimeTrackSubmit({
          timeTrack: end - start,
          referenceType: activity._id,
          episode: episode._id,
          types: activity.category.toUpperCase(),
        })
      }
    }
  }, [activity, episode._id, onTimeTrackSubmit])

  useEffect(() => {
    openedActivity()
  }, [openedActivity])

  useEffect(() => {
    if (!isLoadingOpenedActivities && !isLoadingEpisode) {
      if (openedActivities?.length >= episode?.activities?.length) {
        HelperAPI.ApiRequest<IResponseCompletedChapter>(
          `/level/${kidProfile.level}/episodes`,
          {
            method: 'POST',
            body: JSON.stringify({
              kid: kidProfile?._id,
              episode: episode._id,
              date: new Date(),
            }),
          }
        )
      }
    }
  }, [
    episode._id,
    episode?.activities?.length,
    isLoadingEpisode,
    isLoadingOpenedActivities,
    kidProfile?._id,
    kidProfile?.level,
    openedActivities,
  ])

  return {
    isLoadingActivity,
    activity,
  }
}

export default useContentActivity
