import { useCallback, useMemo } from 'react'
import useSWR, { KeyedMutator } from 'swr'
import { ITestMeeting } from '@src/types/api.interface'
import { FetchError } from '@utils/FetchError'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import HelperAPI from '@utils/helpers/helper.api'

export function useTestMeeting(): {
  testMeeting?: ITestMeeting
  error?: FetchError
  isLoadingTestMeeting: boolean
  mutateTestMeeting: KeyedMutator<ITestMeeting>
  scheduleTestMeeting: (props: {
    email: string
    phone: string
    selectedMeeting: ITestMeeting
  }) => Promise<void>
} {
  const { kidProfile: profile } = useProfile()
  const url = `/maqraa/${profile._id}/test-meeting`

  const { data, error, mutate } = useSWR<ITestMeeting, FetchError>(url, {
    shouldRetryOnError: false,
  })

  const scheduleTestMeeting = useCallback(
    async (props: {
      email: string
      phone: string
      selectedMeeting: ITestMeeting
    }) => {
      try {
        await HelperAPI.ApiRequest(url, {
          method: 'POST',
          body: JSON.stringify({
            email: props.email,
            phone: props.phone,
            meetingDate: props.selectedMeeting.date,
          }),
        })

        await mutate()
      } catch (error) {
        console.error(error)
      }
    },
    [mutate, url]
  )

  return useMemo(
    () => ({
      testMeeting: data,
      error,
      isLoadingTestMeeting: !data && !error,
      mutateTestMeeting: mutate,
      scheduleTestMeeting,
    }),
    [data, error, mutate, scheduleTestMeeting]
  )
}
