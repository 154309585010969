import { ISeries } from '@src/types/api.interface'
import { FC } from 'react'
import SingleSeries from './SingleSeries'

interface SeriesListProps {
  series: Array<ISeries & { isCompleted?: boolean }>
  onSeriesClick?: (series: ISeries, index: number) => void
}

const SeriesList: FC<SeriesListProps> = ({ series, onSeriesClick }) => {
  return (
    <div className="flex items-center justify-start flex-col gap-y-5 pages_series_serieslist_container">
      {series.map((oneSeries, index) => {
        return (
          <SingleSeries
            isSingleSeries={series?.length === 1}
            key={oneSeries.slug}
            onSeriesClick={() => {
              onSeriesClick(oneSeries, index)
            }}
            series={oneSeries}
          />
        )
      })}
    </div>
  )
}

export default SeriesList
