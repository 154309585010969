import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import HelperProfile from '@utils/helpers/helper.profile'
import { FC } from 'react'
import { RouteProps, useHistory } from 'react-router-dom'

const RedirectToAuth: FC<RouteProps> = ({
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) => {
  const { parentProfile, kidProfile } = useProfile()
  const storedParent = HelperProfile.getStoredProfile() ?? parentProfile
  const storedKid = HelperProfile.getStoredKid() ?? kidProfile
  const history = useHistory()
  if (
    rest.type === 'legacy' &&
    storedParent.email !== 'user@anonymous.firebase'
  ) {
    if (storedParent?.kids?.length > 0) history.push('/list-kid-home')
    else history.push('/add-kid')
    return null
  }
  if (
    rest.type === 'anon' &&
    storedKid &&
    storedParent.email === 'user@anonymous.firebase'
  ) {
    // disable all anon based redirects
    // history.push(`/kids-home-anonymous/${storedKid.level}`)
    return null
  }
  return null
}

export default RedirectToAuth
