import { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotate } from '@fortawesome/free-solid-svg-icons'
import { AppContent } from './Layout'
import Logo from '@pages/Login/Logo'
import SectionBackground from './SectionBackground'

const OrientationWarning: FC = () => {
  return (
    <AppContent className="bg-no-repeat 1 bg-cover bg-blue h-[100vh] grid place-content-center app_main_orientation_warning_container">
      <div className="mb-10 app_main_orientation_logo_container">
        <Logo />
      </div>
      <SectionBackground>
        <div className="p-10 app_main_orientation_text_container">
          <div className="grid place-content-center app_main_orientation_icon_text_container">
            <FontAwesomeIcon
              className="app_main_orientation_icon"
              icon={faRotate}
              size="4x"
            />
          </div>
          <h1 className="mt-5 app_main_orientation_h1">
            لمشاهدة أفضل .. أدر هاتفك بالوضع الأفقي
          </h1>
        </div>
      </SectionBackground>
    </AppContent>
  )
}

export default OrientationWarning
