import { ContentTitle } from '@components/ContentTitle'
import { AppBar } from '@components/Layout/AppBar'
import { AppContent } from '@components/Layout/AppContent'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import { useSeries } from '@hooks/data/fetchers/use-series'
import { useCompletedEpisodes } from '@hooks/use-completed-episodes'
import {
  MORE_CONTENT_COMING,
  WE_WILL_UPDATE,
  ZIDO_WORLD_TEAM,
} from '@src/constants'
import { ISeries } from '@src/types/api.interface'
import Helper from '@utils/Helpers'
import { checkArrayItems } from '@utils/array-utils'
import { logEvent } from '@utils/firebase-analytics'
import React, { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import ScrollContainer from 'react-indiana-drag-scroll'
import SeriesDetails from './SeriesDetails'
import SeriesList from './SeriesList'

const TEXT = {
  WEBSITE_TITLE: 'عالم زيدو - تعلم',
  TITLE: 'منهج زيدو',
}

const SeriesHome: FC = () => {
  const { series, isLoadingSeries } = useSeries()
  const { completedEpisodes } = useCompletedEpisodes()
  const [newSeries, setNewSeries] = useState<
    Array<ISeries & { isCompleted?: boolean }>
  >([])
  const [selectedSeries, setSelectedSeries] = useState<ISeries>()

  useEffect(() => {
    logEvent('open_curriculum')
  }, [])

  useEffect(() => {
    if (
      Array.isArray(series) &&
      Array.isArray(completedEpisodes) &&
      series.length > 0
    ) {
      const data = series
        .sort((a, b) => a.order - b.order)
        .map((oneSeries) => {
          const completedEpisodesInSeries = checkArrayItems(
            oneSeries.episodes,
            completedEpisodes
          )
          const isCompleted = completedEpisodesInSeries

          return {
            ...oneSeries,
            isCompleted,
          }
        })

      setNewSeries(data)
      setSelectedSeries(series?.[0])
    }
  }, [series, completedEpisodes])

  const selectedImage = Helper.MediaURL(selectedSeries?.image) || ''
  const style = {
    '--image-url': `url(${selectedImage ?? '/public/images/v3/bgoverlay.svg'})`,
  } as React.CSSProperties

  return (
    <>
      <Helmet>
        <title>{TEXT.WEBSITE_TITLE}</title>
      </Helmet>
      {isLoadingSeries ? <AppProgressBar /> : null}

      <AppBar
        backButtonLocation="/kids-home"
        fixed
        hideToggleButton
        showKidAvatar
        showParentBackButton
      />

      <AppContent
        className="bg-no-repeat pages_series_serieshome_container bg-cover bg-center bg-dimming-overlay-[image:var(--image-url)] overflow-hidden h-screen hide-scroll"
        haveHeader={false}
        style={style}
      >
        <ContentTitle
          className="pages_series_serieshome_content_title"
          noShadow
          notBold
          textColor="text-white"
          title={TEXT.TITLE}
        />

        {!isLoadingSeries ? (
          newSeries?.length > 0 ? (
            <div className="container mx-auto overflow-y-scroll hide-scroll pages_series_serieshome_newSeries_container">
              <div className="flex flex-row mt-8 lg:mt-20 mb-6 px-6">
                <SeriesDetails data={selectedSeries} />

                <div className="h-[275px] lg:h-[650px] pages_series_serieshome_scrollable_container">
                  <ScrollContainer
                    className="scroll-container overflow-y-scroll h-full pb-7"
                    horizontal={false}
                  >
                    <SeriesList
                      onSeriesClick={(series) => {
                        setSelectedSeries(series)
                      }}
                      series={newSeries}
                    />
                  </ScrollContainer>
                </div>
              </div>
            </div>
          ) : (
            <div className="h-safe-screen pages_series_serieshome_typography mt-4 mb-2 text-white text-xl lg:text-2xl flex flex-col justify-center items-center">
              <p className="pages_series_serieshome_more_content_text">
                {MORE_CONTENT_COMING}
              </p>
              <p className="pages_series_serieshome_update_text">
                {WE_WILL_UPDATE}
              </p>
              <p className="pages_series_serieshome_zido_world_team_text">
                {ZIDO_WORLD_TEAM}
              </p>
            </div>
          )
        ) : null}
      </AppContent>
    </>
  )
}

export default SeriesHome
