import { IOpenedActivity } from '@src/types/api.interface'
import { FetchError } from '@utils/FetchError'
import useSWR from 'swr'

export const useOpenedActivities = (
  kidId: string,
  episodeId: string,
  level: number
) => {
  const url = `/level/${level}/episodes/activities/${kidId}/${episodeId}/view`

  const { data, error, mutate } = useSWR<IOpenedActivity[], FetchError>(url)

  return {
    openedActivities: data,
    error,
    mutateOpenedActivities: mutate,
    isLoadingOpenedActivities: !error && !data,
  }
}
