import React, { useEffect, useState, useRef } from 'react'
import { Transition } from 'react-transition-group'
import { transitions } from './transitions'
import { getSafePercent } from './utils'

interface StepChildType {
  accomplished: boolean
  transitionState: string
  index: number
  position: number
}

interface StepProps {
  accomplished?: boolean
  position?: number
  index?: number
  children: (props: StepChildType) => React.ReactNode
  transition?: 'scale' | 'rotate' | 'skew'
  transitionDuration?: number
}

const Step: React.FC<StepProps> = ({
  accomplished,
  position,
  index,
  children,
  transition = null,
  transitionDuration = 300,
}) => {
  const [safePosition, setSafePosition] = useState<number>(0)
  const stepRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setSafePosition(getSafePercent(position))
  }, [position])

  let style = {
    right: `${safePosition}%`,
    transitionDuration: `${transitionDuration}ms`,
  }

  return (
    <Transition in={accomplished} timeout={transitionDuration}>
      {(state) => {
        if (transition) {
          // invariant(
          //   transitions[transition] != null,
          //   `${transition} is not listed in the built-in transitions.`
          // )
          style = {
            ...style,
            ...transitions[transition][state],
          }
        }

        return (
          <div
            className="RSPBstep component_step_progress_bar_container"
            ref={stepRef}
            style={style}
          >
            {children({
              accomplished,
              position: safePosition,
              transitionState: state,
              index,
            })}
          </div>
        )
      }}
    </Transition>
  )
}

export default Step
