import { AppBar, AppContent } from '@components/Layout'
import { INotification } from '@src/types/api.interface'
import { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'

import notificationIcon from '../resources/images/notification-icon.png'
import HelperAPI from '@utils/helpers/helper.api'
import { GrayBoxContainer } from '@components/Containers/GrayBoxContainer'
import { useHistory } from 'react-router-dom'

const Notification = () => {
  const [notifications, setNotifications] = useState<INotification[]>([])
  const history = useHistory()

  const getData = useCallback(() => {
    const url = '/notifications/me'
    HelperAPI.ApiRequest<INotification[]>(url).then(function (notifications) {
      if (!Array.isArray(notifications)) {
        return
      }
      setNotifications(notifications)
    })
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  const handleNotificationClicked = (notification: INotification) => {
    if (!notification?.metadata) return
    if (!notification?.metadata?.path) return
    history.push(notification.metadata.path + `&notification=true`)
  }

  return (
    <>
      <Helmet>
        <title className="notification_page-main-title">
          عالم زيدو - الإشعارات
        </title>
      </Helmet>
      <AppBar
        fixed
        hideToggleButton
        showBackButton={true}
        showLogoutButton
        title=""
      />
      <AppContent className="bg-no-repeat bg-cover bg-center bg-blue flex items-center justify-center">
        <GrayBoxContainer dimensionClass="notification_page-gray-box-container p-2 max-w-[620px] w-full max-h-[350px]">
          <div
            className="notification_page-container flex p-4 justify-center content-center self-center
          "
          >
            <div className="notification_page-wrapper w-full overflow-y-auto h-[250px]">
              {notifications.map((notification) => (
                <div
                  className={`notification_page-notification-${notification._id} w-[95%] flex flex-row mt-3`}
                  key={notification._id}
                  onClick={() => handleNotificationClicked(notification)}
                >
                  <div
                    className={`notification_page-notification-item-${notification._id} place-items-center `}
                  >
                    <img
                      alt=""
                      className={`notification_page-notification-img-${notification._id} items-center w-[40px] h-[40px] cursor-pointer`}
                      src={notificationIcon}
                    />
                  </div>
                  <div
                    className={`notification_page-notification-title-wrapper-${notification._id} mr-4 max-w-[85%]`}
                  >
                    <div
                      className={`notification_page-notification-title-${notification.title} font-bold sm:text-sm`}
                    >
                      {notification.title}
                    </div>
                    <div className="sm:text-xs">{notification.text}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </GrayBoxContainer>
      </AppContent>
    </>
  )
}

export default Notification
