import TextMarkerEffect from '@components/TextMarkerEffect'
import { FC, useEffect, useRef, useState } from 'react'
import SVG from 'react-inlinesvg'

interface SectionProps {
  title: string
  subTitle: string
  paragraph: string
  image: string
}

const Section: FC<SectionProps> = ({
  title,
  subTitle,
  paragraph,
  image,
}: SectionProps) => {
  const elementRef = useRef(null)
  const [hasAnimated, setHasAnimated] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !hasAnimated) {
          entry.target.classList.add('animate-custom-fadeInUp')
          setHasAnimated(true) // Set hasAnimated to true after the first animation
          observer.disconnect() // Disconnect the observer after the first animation
        }
      })
    })

    // Start observing the element when the component mounts
    observer.observe(elementRef.current)

    // Clean up the observer when the component is unmounted
    return () => observer.disconnect()
  }, [hasAnimated]) // Re-run the effect only if hasAnimated changes

  return (
    <div
      className="group pages_curriculum_section_container flex flex-col-reverse justify-center md:flex-row md:even:flex-row-reverse mt-14 lg:mt-28 gap-10 delay-100"
      ref={elementRef}
    >
      <div className="flex flex-col flex-1 justify-center items-center md:items-end group-even:items-center md:group-even:items-start">
        <div className="flex flex-col text-right w-11/12 pages_curriculum_section_typography_container">
          <TextMarkerEffect>
            <h3 className="relative text-3xl text-v3-blue-800 pages_curriculum_section_title">
              {title}
            </h3>
          </TextMarkerEffect>

          <TextMarkerEffect color="orange">
            <h4 className="text-2xl text-v3-blue-800 pages_curriculum_section_subtitle">
              {subTitle}
            </h4>
          </TextMarkerEffect>
          <p className="text-xl text-v3-purple pages_curriculum_section_text">
            {paragraph}
          </p>
        </div>
      </div>
      <div className="flex flex-1 pages_curriculum_section_svg_container justify-center md:justify-start group-even:justify-center md:group-even:justify-end transform hover:scale-105 duration-500 ease-in-out">
        <SVG
          className="w-4/5 md:w-11/12 lg:w-5/6 pages_curriculum_section_svg"
          src={image}
        />
      </div>
    </div>
  )
}

export default Section
