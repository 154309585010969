import { LOCKED_ICON_NEW, PLAY_ICON_NEW } from '@utils/constants'
import { FC } from 'react'

export const LockedVideoOverlay: FC<{
  setIsVisable?: (isVisible: boolean) => void
  setIsFreeContent?: (isFreeContent: boolean) => void
  rounded?: boolean
}> = (props) => {
  const classRounded = props?.rounded ? 'rounded-xl' : ''

  return (
    <div
      className={`z-10 absolute w-full h-full bg-black bg-opacity-40 flex items-center justify-center ${classRounded} overlay_button-wrpr`}
      onClick={() => {
        props?.setIsFreeContent(true)
        props?.setIsVisable(true)
      }}
    >
      <img
        alt=""
        className="w-1/2 h-1/2 max-w-max max-h-max overlay_button-lck-icon"
        draggable="false"
        // src={LOCKED_ICON}
        src={LOCKED_ICON_NEW}
      />
    </div>
  )
}

export const PlayVideoOverlay: FC = () => {
  return (
    <img
      alt=""
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-1/3 lg:w-16 overlay_button-play-icon"
      draggable="false"
      // src={PLAY_ICON}
      src={PLAY_ICON_NEW}
    />
  )
}
