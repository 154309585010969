import React, { FC, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { AppBar, AppContent } from '@components/Layout'
import Config from '@config/index'
import { Link, useHistory, useParams } from 'react-router-dom'
import { IFeatures, IProfilePublication } from '@src/types/api.interface'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import HelperProfile from '@utils/helpers/helper.profile'
import HelperAPI from '@utils/helpers/helper.api'
import { useAnonymous } from '@contexts/AnonymousContext/AnonymousContext'

const iconMaqraa = `${Config.publicURL}/images/kids-home/icon-maqraa-new-v2.png`
const iconMaqraa2 = `${Config.publicURL}/images/kids-home/icon-maqraa-new-v2@2x.png`
const iconMaqraa3 = `${Config.publicURL}/images/kids-home/icon-maqraa-new-v2@3x.png`

const iconCompetitionCircle = `${Config.publicURL}/images/kids-home/competition-circle.png`
const iconCompetitionCircle2 = `${Config.publicURL}/images/kids-home/competition-circle@2x.png`
const iconCompetitionCircle3 = `${Config.publicURL}/images/kids-home/competition-circle@3x.png`
const iconCompetitionContent = `${Config.publicURL}/images/kids-home/competition-content-new.png`
const iconCompetitionContent2 = `${Config.publicURL}/images/kids-home/competition-content-new@2x.png`
const iconCompetitionContent3 = `${Config.publicURL}/images/kids-home/competition-content-new@3x.png`

const iconLearnCircle = `${Config.publicURL}/images/kids-home/learn-circle.png`
const iconLearnCircle2 = `${Config.publicURL}/images/kids-home/learn-circle@2x.png`
const iconLearnCircle3 = `${Config.publicURL}/images/kids-home/learn-circle@3x.png`
const iconLearnContent = `${Config.publicURL}/images/kids-home/learn-content.png`
const iconLearnContent2 = `${Config.publicURL}/images/kids-home/learn-content@2x.png`
const iconLearnContent3 = `${Config.publicURL}/images/kids-home/learn-content@3x.png`

const KidsHomeAnonymous = () => {
  const [features, setFeatures] = useState<IFeatures[]>([])
  const { logout } = useAnonymous()

  const getData = useCallback(() => {
    const url = '/features'
    HelperAPI.ApiRequest<IFeatures[]>(url)
      .then((features) => {
        if (!Array.isArray(features) || 'error' in features) {
          return
        }
        // Filter out the library feature, we don't want it here yet.
        const filtered = features.filter(
          (feature) => feature.reference !== 'library'
        )
        setFeatures(filtered)
      })
      .catch((e) => {
        if (e.status === 401) {
          logout()
        }
      })
  }, [setFeatures, logout])

  useEffect(() => {
    if (Array.isArray(features) && features.length === 0) {
      getData()
    }
  }, [getData, features])

  const gridCol = () => {
    if (!Array.isArray(features) || features.length === 0) {
      return 'w-full max-w-[85%] grid grid-cols-3 gap-2 sm:gap-4 md:gap-8 place-items-center'
    }
    const columnLength = features.filter(({ status }) => status).length
    const widthPercentage = Math.round(
      85 / (features.length - columnLength + 1)
    )
    return `w-full max-w-[${
      widthPercentage > 85 ? 85 : widthPercentage
    }%] grid grid-cols-${columnLength} gap-2 sm:gap-4 md:gap-8 place-items-end`
  }

  return (
    <>
      <Helmet>
        <title>عالم زيدو - أطفال</title>
      </Helmet>
      <AppBar
        backButtonLocation="/"
        fixed
        hideToggleButton
        showBackButton={true}
        showLogoutButton
        title=""
      />
      <AppContent className="bg-no-repeat bg-cover bg-center bg-blue-sky pages_anonymous_kidshome_appcontent">
        <div className="w-full mx-auto max-w-[2000px] h-screen overflow-hidden flex flex-col items-center justify-center pages_anonymous_kidshome_appcontent_link_container">
          <div className={gridCol()}>
            {features.find(({ reference }) => reference === 'competition')
              ?.status ? (
              <LinkToCompetition />
            ) : null}
            {features.find(({ reference }) => reference === 'curriculum')
              ?.status ? (
              <LinkToEpisodes />
            ) : null}
            {features.find(({ reference }) => reference === 'maqraa')
              ?.status ? (
              <LinkToMaqraa />
            ) : null}
          </div>
        </div>
      </AppContent>
    </>
  )
}

export default KidsHomeAnonymous

export const levelOneKidID = '63a434017f823ca8ce1625a6'
export const levelTwoKidId = '63a433cd7f823ca8ce1625a5'
const LinkToEpisodes: FC = () => {
  const { level } = useParams<{ level: string }>()
  const history = useHistory()
  const { kidProfile, setActiveKid, parentProfile } = useProfile()

  const setKidLevel = (level: number) => ({
    _id: level === 1 ? levelOneKidID : levelTwoKidId,
    username: '',
    publications: [] as IProfilePublication[],
    level,
    nickname: 'kid',
    firstName: 'kid',
    lastName: 'kid',
    middleName: '',
    avatar: '',
    gender: 'male',
    progress: '',
    pendingUnit: { slug: '', days: 1 },
    role: 'kid',
    trackingConsent: true,
    watchNext: {
      chapter: '',
      episode: '',
      thumbnail: '',
      title: '',
    },
  })
  const handleClick = () => {
    setActiveKid(setKidLevel(parseInt(level)))
    history.push(`/series-anonymous/${level}`)

    HelperProfile.setStoredProfile(parentProfile)
    HelperProfile.setStoredKid(kidProfile)
  }

  return (
    <button
      className="pages_anonymous_kidshome_episodelink_button"
      onClick={() => handleClick()}
      type="button"
    >
      <div className="relative">
        <img
          alt="اكتشف"
          className="pages_anonymous_kidshome_episodelink_button_icon_img"
          src={iconLearnCircle}
          srcSet={`${iconLearnCircle3} 675w, ${iconLearnCircle2} 450w, ${iconLearnCircle} 225w`}
        />
        <img
          alt=""
          className="z-10 absolute bottom-0 left-0 right-0 m-auto text-center pages_anonymous_kidshome_episodelink_button_thumb_img"
          src={iconLearnContent}
          srcSet={`${iconLearnContent3} 675w, ${iconLearnContent2} 450w, ${iconLearnContent} 225w`}
        />
      </div>
    </button>
  )
}

const LinkToMaqraa: FC = () => {
  return (
    <Link
      className="pages_anonymous_kidshome_link_maqraa"
      to="/redirector/?r=/maqraa"
    >
      <img
        alt="المقرأة"
        className="pages_anonymous_kidshome_link_maqraa_img"
        src={iconMaqraa}
        srcSet={`${iconMaqraa3} 675w, ${iconMaqraa2} 450w, ${iconMaqraa} 225w`}
      />
    </Link>
  )
}

const LinkToCompetition: FC = () => {
  return (
    // Redirect to login page for anonymous user
    <Link
      className="pages_anonymous_kidshome_link_competition"
      to="/redirector?r=/competition"
    >
      <div className="relative">
        <img
          alt="المسابقات"
          className="pages_anonymous_kidshome_link_compeition_icon_img"
          src={iconCompetitionCircle}
          srcSet={`${iconCompetitionCircle3} 675w, ${iconCompetitionCircle2} 450w, ${iconCompetitionCircle} 225w`}
        />
        <img
          alt=""
          className="z-10 absolute bottom-[6px] lg:bottom-4 left-0 right-0 m-auto text-center pages_anonymous_kidshome_link_competition_thumbnail_img"
          src={iconCompetitionContent}
          srcSet={`${iconCompetitionContent3} 675w, ${iconCompetitionContent2} 450w, ${iconCompetitionContent} 225w`}
        />
      </div>
    </Link>
  )
}
