import Config from '@config/index'
import SVG from 'react-inlinesvg'

interface Episode {
  title: string
  goals: string[]
  completed: boolean
}

interface GoalProps {
  id: string
  seriesTitle: string
  episodes: Episode[]
}

interface GoalCardsListProps {
  series: GoalProps[]
}

interface EpisodeTitleProps {
  seriesTitle: string
}

interface TickIconProps {
  color: 'green' | 'gray'
}

const greenTickSVG = `${Config.publicURL}/images/v3/greenTickBtn.svg`
const TickIcon = ({ color }: TickIconProps) => {
  const isGrayScale = { filter: `grayscale(${color === 'green' ? 0 : 100}%)` }
  return (
    <div className="goal_card-tick-icon flex justify-center items-center relative h-8">
      <span className="goal_card-tick-icon-span absolute w-4 h-4 bg-white rounded-full z-0" />
      <SVG
        className="goal_card-tick-icon-green-tick-svg w-6 h-6 z-10"
        src={greenTickSVG}
        style={isGrayScale}
      />
    </div>
  )
}

const EpisodeTitle = ({ seriesTitle }: EpisodeTitleProps) => (
  <div className={`goal_card-episode-title-container-${seriesTitle} mb-4`}>
    <span
      className={`goal_card-episode-title-span-${seriesTitle} h-2 w-2 align-middle rounded-full bg-v3-purple inline-block`}
    />
    <h4
      className={`goal_card-episode-title-${seriesTitle} text-xl md:text-2xl mr-2 text-v3-blue-800 inline-block`}
    >
      {seriesTitle}
    </h4>
  </div>
)

const GoalCard = ({ title, goals, completed }: Episode) => (
  <div
    className={`goal_card-item-${title} flex flex-row bg-v3-gray-100 rounded-2xl px-4 py-3`}
  >
    <h4
      className={`goal_card-item-${title} text-xl md:text-2xl pl-14 text-v3-purple w-[20%]`}
    >
      {title}
    </h4>
    <div className="goal_card-goals-wrapper flex flex-col">
      {goals.map((goal) => (
        <div
          className={`goal_card-goal-item-wrapper-${goal} flex flex-row`}
          key={goal}
        >
          {completed ? <TickIcon color="green" /> : <TickIcon color="gray" />}
          <p
            className={`goal_card-goal-item-${goal} text-lg md:text-xl text-v3-purple mr-2`}
          >
            {goal}
          </p>
        </div>
      ))}
    </div>
  </div>
)

const Goal = ({ seriesTitle, episodes }: GoalProps) => {
  return (
    <div
      className={`goal_card-container-${seriesTitle} flex flex-col flex-1 min-w-[40%] 2xl:min-w-[30%]`}
    >
      <EpisodeTitle seriesTitle={seriesTitle} />

      <div className={`goal_card-wrapper-${seriesTitle} flex flex-col gap-2`}>
        {episodes.map((episode) => (
          <GoalCard key={episode.title} {...episode} />
        ))}
      </div>
    </div>
  )
}

export const GoalCardsList = ({ series }: GoalCardsListProps) => {
  return (
    <div className="goal_card-goal-cards-list-wrapper flex flex-col gap-4 md:flex-row flex-wrap">
      {series.map(({ id, seriesTitle, episodes }) => (
        <Goal episodes={episodes} id={id} key={id} seriesTitle={seriesTitle} />
      ))}
    </div>
  )
}
