import { Browser } from '@capacitor/browser'
import { MouseEvent, useCallback } from 'react'

export function useInAppBrowser() {
  const open = useCallback(async (url: string) => {
    await Browser.open({ url, presentationStyle: 'popover', windowName: url })
  }, [])
  return open
}

export function useOpenParentsDashboard() {
  const openBrowser = useInAppBrowser()
  return useCallback(
    (e?: MouseEvent) => {
      if (e) e.preventDefault()
      openBrowser(process.env.REACT_APP_PARENTS_URL || '')
    },
    [openBrowser]
  )
}
