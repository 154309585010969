import React, { FC, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { BOOK_ICON, GAME_ICON, LOCKED_ICON_SMALL } from '@utils/constants'
import { ActivityCategory } from '@src/types/api.interface'
import Config from '@config/index'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'

interface IActivity {
  _id: string
  thumbnail: string
  title: string
  isFree?: boolean
  category?: ActivityCategory
}

// const emptyAnonMsg = 'قم بالتسجيل لمشاهدة المزيد من المحتوى'
const emptyMsg =
  'لا توجد أنشطة مفتوحة. لفتح أنشطة جديدة, أتمم مشاهدة المزيد من القصص'

export const AllActivities: FC<{
  message?: string
  icon?: ReactElement
  activities: IActivity[]
  setIsVisable?: (isVisible: boolean) => void
  hasProAccess?: boolean
  isAnonymous?: boolean
}> = (props) => {
  const { parentProfile } = useProfile()
  const message =
    props.activities.length === 0
      ? parentProfile?.email === Config.firebase.anonUser || props.isAnonymous
        ? emptyMsg // For now show same message for both
        : emptyMsg
      : props.message
  return (
    <ActivitiesContainer icon={props.icon} message={message}>
      <ul
        className="grid grid-cols-3 gap-2 lg:gap-4 all_activities-ul"
        id="assets-panel-activitiess"
      >
        {props.activities.map((activity) => {
          return (
            <li
              className="mx-auto mt-auto all_activities-li"
              key={activity._id}
            >
              <ActivityThumbnail
                data={activity}
                isAnonymous={props.isAnonymous}
                locked={!props.hasProAccess ? !activity.isFree : null}
                setIsVisable={props.setIsVisable}
              />
            </li>
          )
        })}
      </ul>
    </ActivitiesContainer>
  )
}

export const ActivitiesContainer: FC<{
  message?: string
  icon?: ReactElement
  children?: React.ReactNode | string
}> = (props) => {
  return (
    <div className="bg-white relative rounded-3xl p-2 lg:p-4 w-full all_activities-container">
      {props.icon ? (
        <span className="text-xs sm:text-sm lg:text-2xl ml-2 text-[#c5eb11] all_activities-icon-wrpr">
          {props.icon}
        </span>
      ) : null}
      {props.message ? (
        <span className="text-[#4a4a4a] text-xs sm:text-sm lg:text-2xl all_activities-msg">
          {props.message}
        </span>
      ) : null}

      {props.children}
    </div>
  )
}

const ActivityThumbnail: FC<{
  data: IActivity
  setIsVisable?: (isVisible: boolean) => void
  locked?: boolean
  isAnonymous?: boolean
}> = (props) => {
  const data = props.data
  const category = data?.category

  const url = props.locked
    ? ''
    : props.isAnonymous
    ? '/activities-anonymous/' + data._id
    : '/activities/' + data._id
  return (
    <div className="relative rounded-3xl overflow-hidden all_activities-thumb-wrpr">
      {props.locked ? (
        <div
          className="z-10 absolute w-full h-full all_activities-locked"
          onClick={() => {
            props?.setIsVisable(true)
          }}
        />
      ) : null}
      <Link
        className="inline-flex flex-col items-center lg:mt-5 p-1 all_activities-link"
        to={url}
      >
        <div className="relative all_activities-img-container">
          <img
            alt={data.title}
            className="inline-block max-w-[30px] lg:min-w-[90px] all_activities-thumb-img"
            draggable="false"
            src={category === 'game' ? GAME_ICON : BOOK_ICON}
          />
          {props.locked ? (
            <img
              alt=""
              className="absolute -top-1 lg:-top-3 -right-2 h-6 w-6 lg:w-min lg:h-min all_activities-lock-sm"
              src={LOCKED_ICON_SMALL}
            />
          ) : null}
        </div>
        <span className="text-[#4a4a4a] text-center text-xs sm:text-sm md:text-sm lg:text-xl mt-[2px] lg:mt-1 pb-1 all_activities-title">
          {data.title}
        </span>
      </Link>
    </div>
  )
}
