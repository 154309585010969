import { useMobileView } from '@hooks/useMobileView'
import React, { FC, useRef } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/swiper.min.css'

const HorizontalScrollInput: FC<{
  selections: number[]
  selectedIndex: number
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>
  isNewView?: boolean
}> = ({ selections, selectedIndex, setSelectedIndex, isNewView }) => {
  const swiperRef = useRef(null)

  const isMobileView = useMobileView()

  const lastIndex = selections.length - 1

  const handleButtonClick = (direction: 'prev' | 'next') => {
    if (swiperRef?.current) {
      if (direction === 'prev') {
        swiperRef?.current?.slidePrev()
        selectedIndex !== 0 && setSelectedIndex((prev) => prev - 1)
      } else if (direction === 'next') {
        swiperRef?.current?.slideNext()
        selectedIndex !== lastIndex && setSelectedIndex((prev) => prev + 1)
      }
    }
  }

  return (
    <div className="flex flex-col items-center justify-center component_horizontal_scroll_container">
      <div className="hours-swiper max-w-[250px] md:max-w-[290px] component_horizontal_scroll_child_container lg:max-w-[450px] h-[56px] lg:h-[84px] items-center flex relative">
        {isNewView && !isMobileView ? (
          <div
            className="component_horizontal_scroll_non_mobile_prev_button_container"
            onClick={() => {
              handleButtonClick('prev')
            }}
          >
            <RightArrowIcon noNext={selectedIndex === 0} />
          </div>
        ) : null}
        <Swiper
          centeredSlides={isMobileView}
          centeredSlidesBounds={true}
          className="h-56 component_horizontal_scroll_swiper_container"
          initialSlide={
            Number.isNaN(selectedIndex) || selectedIndex === null
              ? 0
              : selectedIndex
          }
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          ref={swiperRef}
          slidesPerView={5} // Adjust the number of slides per view
          spaceBetween={5} // Adjust the space between slides
        >
          {selections.map((num, index) => (
            <SwiperSlide
              className="component_horizontal_scroll_swiper_slide_container"
              key={index}
            >
              <CircleSelectInput
                className="component_horizontal_scroll_circle_select_input_container"
                index={index}
                isNewView={isNewView}
                isSelected={selectedIndex === index}
                setSelectedIndex={setSelectedIndex}
                value={num}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {isNewView && !isMobileView ? (
          <div
            className="component_horizontal_scroll_non_mobile_next_button_container"
            onClick={() => {
              handleButtonClick('next')
            }}
          >
            <LeftArrowIcon noNext={selectedIndex === lastIndex} />
          </div>
        ) : null}
      </div>
      {isNewView && isMobileView ? (
        <div className="flex w-full items-center gap-x-3 justify-center component_horizontal_scroll_mobile_button_container">
          <div
            className="component_horizontal_scroll_mobile_prev_button_container"
            onClick={() => {
              handleButtonClick('prev')
            }}
          >
            <RightArrowIcon noNext={selectedIndex === 0} />
          </div>
          <div
            className="component_horizontal_scroll_next_button_container"
            onClick={() => {
              handleButtonClick('next')
            }}
          >
            <LeftArrowIcon noNext={selectedIndex === lastIndex} />
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default HorizontalScrollInput

const CircleSelectInput: FC<{
  value: number
  index: number
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>
  isSelected?: boolean
  isNewView?: boolean
  className?: string
}> = ({ value, isSelected, setSelectedIndex, index, isNewView, className }) => {
  const handleClick = () => {
    if (isSelected) {
      setSelectedIndex(null)
    } else {
      setSelectedIndex(index)
    }
  }

  let classes = ''

  if (isNewView) {
    classes = isSelected
      ? 'text-white bg-[#665EBC] text-4xl px-2 rounded-full self-center'
      : 'text-[#665EBC] text-3xl'
  } else {
    classes = isSelected
      ? 'text-4xl w-14 h-14 text-white bg-[#96B929]'
      : 'text-xl w-12 h-12 text-[#DAE7FA] bg-[#95A8CD]'
  }

  if (isNewView) {
    return (
      <div className={`${className} ${classes}  mx-2`} onClick={handleClick}>
        {value}
      </div>
    )
  }

  return (
    <div
      className={`${classes} ${className} p-2 flex items-center justify-center aspect-square rounded-full border-2 border-[#7E90B5]`}
      onClick={handleClick}
    >
      {value}
    </div>
  )
}

const LeftArrowIcon: FC<{
  noNext?: boolean
}> = ({ noNext }) => {
  return (
    <svg
      className="w-[35px] component_horizontal_scroll_left_arrow_svg"
      fill="none"
      viewBox="0 0 45 46"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.91718 23.9946C1.91718 36.1333 11.4874 45.9727 23.2857 45.9727C35.084 45.9727 44.6543 36.1333 44.6543 23.9946C44.6543 11.8559 35.0883 2.0166 23.2857 2.0166C11.4831 2.0166 1.91718 11.8559 1.91718 23.9946Z"
        fill="black"
        opacity="0.2"
      />
      <path
        d="M0.917183 22.1265C0.917183 34.2652 10.4874 44.1045 22.2857 44.1045C34.084 44.1045 43.6543 34.2652 43.6543 22.1265C43.6543 9.98777 34.0883 0.148438 22.2857 0.148438C10.4831 0.148438 0.917183 9.98777 0.917183 22.1265Z"
        fill="white"
      />
      <path
        d="M22.285 37.3435C31.4165 37.3435 38.8447 29.8898 38.8447 20.7211C38.8447 11.5524 31.4165 4.09863 22.285 4.09863C13.1534 4.09863 5.72517 11.5524 5.72517 20.7211C5.72517 29.8898 13.1491 37.3435 22.285 37.3435Z"
        fill={noNext ? '#9EA9C4' : '#665EBC'}
      />
      <path
        d="M17.6886 13.8877C18.7528 11.5827 19.9144 8.77177 21.4639 8.51654C23.0133 8.26132 27.7038 7.99664 28.4511 8.8983C28.7999 9.3678 29.0098 9.94602 29.0518 10.5536C29.0937 11.1612 28.9659 11.7684 28.6857 12.2918C28.1373 13.1975 24.0159 19.0112 24.0612 20.6177C24.0941 21.7821 26.1154 25.1862 27.3435 27.4073C27.749 28.109 28.1104 28.8411 28.425 29.5985C28.749 30.3927 28.7271 31.7229 28.1961 32.348C27.7565 32.8656 27.0836 33.1033 25.5625 33.1566C24.0415 33.2098 21.9989 33.1027 21.3613 32.6425C20.226 31.8228 18.7633 29.0238 17.9507 27.1142C17.1381 25.2046 15.5327 21.4586 15.5124 20.7377C15.4921 20.0168 16.6244 16.1927 17.6886 13.8877ZM20.3322 27.5463C21.0421 29.0884 21.9599 30.3193 22.6951 30.7883C23.0582 31.02 23.5665 31.0161 24.2551 31.0685C24.9608 31.1224 25.6551 31.156 25.9537 30.9472C26.5438 30.5349 24.7393 27.4283 23.5517 25.1969C22.3641 22.9655 21.4858 21.3558 21.4625 20.5301C21.4392 19.7044 23.2573 16.6663 24.0132 15.3055C24.7692 13.9447 25.3294 13.0724 25.9989 11.6948C26.7862 10.0746 24.2401 10.2101 23.1628 10.2478C22.0856 10.2854 21.8235 10.991 21.3782 11.9492C20.933 12.9073 19.6775 15.4933 18.8428 17.3985C18.0081 19.3038 17.6838 20.1402 17.6838 20.7913C17.6838 21.4425 19.6223 26.0043 20.3322 27.5463Z"
        fill="white"
      />
    </svg>
  )
}

const RightArrowIcon: FC<{
  noNext?: boolean
}> = ({ noNext }) => {
  return (
    <svg
      className="w-[35px] component_horizontal_scroll_right_arrow_svg"
      fill="none"
      viewBox="0 0 45 47"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.4266 24.6255C44.4266 36.7642 34.8563 46.6035 23.058 46.6035C11.2597 46.6035 1.68945 36.7642 1.68945 24.6255C1.68945 12.4868 11.2554 2.64746 23.058 2.64746C34.8606 2.64746 44.4266 12.4868 44.4266 24.6255Z"
        fill="black"
        opacity="0.2"
      />
      <path
        d="M43.4266 22.7564C43.4266 34.895 33.8563 44.7344 22.058 44.7344C10.2597 44.7344 0.689453 34.895 0.689453 22.7564C0.689453 10.6177 10.2554 0.77832 22.058 0.77832C33.8606 0.77832 43.4266 10.6177 43.4266 22.7564Z"
        fill="white"
      />
      <path
        d="M22.0578 37.9734C12.9263 37.9734 5.49805 30.5196 5.49805 21.351C5.49805 12.1823 12.9263 4.72852 22.0578 4.72852C31.1894 4.72852 38.6176 12.1823 38.6176 21.351C38.6176 30.5196 31.1937 37.9734 22.0578 37.9734Z"
        fill={noNext ? '#9EA9C4' : '#665EBC'}
      />
      <path
        d="M26.6551 14.5176C25.591 12.2126 24.4293 9.40165 22.8799 9.14643C21.3304 8.8912 16.64 8.62653 15.8926 9.52818C15.5438 9.99768 15.334 10.5759 15.292 11.1835C15.25 11.7911 15.3779 12.3983 15.6581 12.9217C16.2065 13.8274 20.3278 19.6411 20.2825 21.2476C20.2497 22.412 18.2283 25.8161 17.0002 28.0372C16.5947 28.7389 16.2334 29.471 15.9187 30.2283C15.5948 31.0226 15.6166 32.3528 16.1477 32.9779C16.5873 33.4955 17.2602 33.7332 18.7812 33.7864C20.3023 33.8397 22.3449 33.7326 22.9825 33.2724C24.1177 32.4527 25.5805 29.6537 26.3931 27.7441C27.2057 25.8345 28.811 22.0885 28.8314 21.3676C28.8517 20.6467 27.7193 16.8226 26.6551 14.5176ZM24.0116 28.1762C23.3016 29.7183 22.3838 30.9492 21.6487 31.4182C21.2856 31.6499 20.7772 31.646 20.0886 31.6984C19.3829 31.7522 18.6886 31.7859 18.39 31.5771C17.8 31.1648 19.6044 28.0582 20.792 25.8268C21.9797 23.5954 22.8579 21.9856 22.8812 21.1599C22.9045 20.3343 21.0865 17.2962 20.3305 15.9354C19.5746 14.5746 19.0143 13.7023 18.3449 12.3246C17.5576 10.7045 20.1037 10.84 21.1809 10.8776C22.2581 10.9153 22.5203 11.6209 22.9655 12.5791C23.4108 13.5372 24.6663 16.1231 25.501 18.0284C26.3356 19.9337 26.6599 20.77 26.6599 21.4212C26.6599 22.0724 24.7215 26.6342 24.0116 28.1762Z"
        fill="white"
      />
    </svg>
  )
}
