import { IChallengeNew } from '@src/types/api.interface'
import { Tabs, TabsRef } from 'flowbite-react'
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'
import { TabProps } from '../TopCompetitor'
import { TYPOGRAPHY } from '../constants'
import { LeaderBoard } from './LeaderBoard'
import { ScoreBoardListItem } from './ScoreBoardListItem'

const { RANK_LIST_TITLE, LOAD_MORE_TEXT } = TYPOGRAPHY

export interface ListComponentProps {
  tabs?: TabProps[]
  setTabs?: Dispatch<SetStateAction<TabProps[]>>
  setActiveTab?: (index: number) => void
  kids: IChallengeNew[]
  kidId?: string
  isShowLoadMore?: boolean
  loadMoreData?: () => void
  isParticipated: boolean
}

export const ListComponent: FC<ListComponentProps> = ({
  tabs,
  setActiveTab,
  kids,
  kidId,
  isShowLoadMore,
  loadMoreData,
  isParticipated,
}) => {
  const [restOfKidsAfterFifthPos, setRestOfKidsAfterFifthPos] = useState<
    IChallengeNew[]
  >([])
  const firstFive = kids.slice(0, 5)
  const isKidInFirstFive = !!firstFive.find((item) => item?.id === kidId)?.id

  useEffect(() => {
    if (kids?.length) {
      if (isKidInFirstFive) {
        setRestOfKidsAfterFifthPos(kids.slice(5))
      } else {
        setRestOfKidsAfterFifthPos(
          kids.slice(5).sort((a, b) => {
            if (a.id === kidId) {
              return -1
            } else if (b.id === kidId) {
              return 1
            } else {
              return 0
            }
          })
        )
      }
    }
  }, [kids, isKidInFirstFive, kidId])

  const tabsRef = useRef<TabsRef>(null)

  return (
    <Tabs
      aria-label="Pills"
      className="list_component-tabs tabs gap-0 items-center"
      onActiveTabChange={(tab: number) => setActiveTab(tab)}
      ref={tabsRef}
      // eslint-disable-next-line react/style-prop-object
      style="pills"
      theme={{
        tablist: {
          base: 'mb-3.5 competition-container:absolute competition-container:left-[17%] -top-[50px] lg:static',
          tabitem: {
            styles: {
              pills: {
                base: '',
                active: {
                  on: 'rounded-lg bg-[#E77F19] focus:ring-0 text-white hover:text-white hover:bg-[#E77F19]',
                  off: 'rounded-lg hover:text-white hover:bg-[#E77F19]',
                },
              },
            },
          },
        },
      }}
    >
      {tabs.map((item, index) => (
        <Tabs.Item
          className={`list_component-tab-item-${index}`}
          key={index}
          {...item}
          color="primary"
          onClick={() => tabsRef.current?.setActiveTab(index)}
        >
          <div className="flex flex-col competition-container:flex-row gap-9 lg:gap-6">
            <LeaderBoard kidId={kidId} kids={kids} />
            {restOfKidsAfterFifthPos?.length ? (
              <div className="list_component-rest-of-kids-after-fifth-pos-container xl:w-[644px] min-h-[321px] xl:min-h-[526px] lg:border-8 border-[#ffffff57] xl:bg-[#0000002E] h-auto rounded-[32px] flex flex-col justify-center">
                {!isKidInFirstFive && isParticipated ? (
                  <p className="list_component-rank-list-title text-lg text-white xl:text-2xl text-right mb-2 mr-4">
                    {RANK_LIST_TITLE}:
                  </p>
                ) : null}

                <div className="list_component-rest-of-kids-after-fifth-pos-wrapper h-[300px] xl:h-[458px] pt-2 px-3 overflow-y-scroll hide-scroll">
                  {restOfKidsAfterFifthPos?.map((kid, index) => (
                    <ScoreBoardListItem
                      index={index}
                      isKidInFirstFive={isKidInFirstFive}
                      key={index}
                      kid={kid}
                      kidId={kidId}
                    />
                  ))}

                  {isShowLoadMore ? (
                    <button
                      className="list_component-load-more-data-btn mb-4 w-24 h-12 flex items-center justify-center mx-auto text-xl text-bold text-white border-white rounded-xl bg-orange-500"
                      onClick={() => loadMoreData()}
                      type="button"
                    >
                      {LOAD_MORE_TEXT}
                    </button>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </Tabs.Item>
      ))}
    </Tabs>
  )
}
