// append colors with props
const AvatarCircleBg = ({
  className = 'h-[72px] w-[72px]',
  fill = 'currentColor',
}: {
  className?: string
  fill?: string
}) => {
  return (
    <svg
      className={`${className} absolute top-0 right-0 z-[0] component_layout_kid_card_svg `}
      //   width="71"
      //   height="72"
      fill="none"
      viewBox="0 0 71 72"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="35.4805"
        cy="36.1905"
        r="34.8164"
        stroke={fill}
        strokeDasharray="3 3"
        strokeLinecap="round"
      />
      <circle cx="35.4863" cy="36.1899" fill={fill} r="32.4145" />
    </svg>
  )
}

export const UserAvatar = ({
  image = '/images/v3/avatar-no-eyes.png',
  textColor = 'text-[#57599C]',
  className = ' w-[72px] h-[72px]',
  avatarSize = 45,
  fill,
}: {
  image?: string
  textColor?: string
  className?: string
  avatarSize?: number
  fill?: string
}) => {
  return (
    <div className="block component_kid_card_primary_container">
      <div
        className={`${className} component_kid_card_user_avatar_conainer flex items-center justify-center ${textColor} kid-avatar relative`}
      >
        <div className="user-avatar relative z-10 component_kid_card_img_container">
          <img
            alt="avatar"
            className="component_kid_card_avatar_img"
            height={avatarSize}
            src={image ?? '/images/v3/avatar-no-eyes.png'}
            width={avatarSize}
          />
        </div>
        <AvatarCircleBg
          className={`${className} component_kid_card_cirle_bg`}
          fill={fill}
        />
      </div>
    </div>
  )
}
