import React, { useCallback, useEffect, useRef, useState, FC } from 'react'
import { useParams } from 'react-router-dom'
import 'video.js/dist/video-js.min.css'
import { ContentTitle } from '@components/ContentTitle'
import { GameIFrame } from '@components/GameIframe'
import { AppBar, AppContent } from '@components/Layout'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import Modal from '@components/Modal'
import VideoPlayer from '@components/videoplayer'
import Config from '@config/index'
import Helper from '@utils/Helpers'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faGamepad } from '@fortawesome/free-solid-svg-icons'
import { IActivityDetail } from '@src/types/api.interface'
import { useJwtToken } from '@hooks/data/storage/use-jwt-token'
import { Helmet } from 'react-helmet-async'
import { useChaptersAnonymous } from '@hooks/use-chapters-anonymous'
import { useChapterAnonymous } from '@hooks/use-chapter-anonymous'

const ChapterPlay: FC = () => {
  const timeoutsRef = useRef<number[]>([])

  const { chapSlug, epiSlug, level } = useParams<{
    epiSlug: string
    chapSlug: string
    level: string
  }>()

  const { episode } = useChaptersAnonymous({
    slug: epiSlug,
    level: parseInt(level),
  })

  const { isLoadingChapter, chapter } = useChapterAnonymous({
    chapSlug,
    epiSlug,
    level: parseInt(level),
  })

  const [showModal, setShowModal] = useState(false)

  const closeModal = useCallback(() => {
    setShowModal(false)
  }, [])

  useEffect(() => {
    if (!chapter?._id) return
    Helper.accessLog('chapters', chapter._id)
  }, [chapter?._id])

  const onVideoCompleted = useCallback(async () => {
    if (!episode?._id) return

    const isUnwatchedChapter = !!episode?.chapters.some(
      (c) => c._id === chapter?._id && !c.watched
    )

    if (!isUnwatchedChapter) return

    const timeout = window.setTimeout(() => {
      setShowModal(true)
    }, 2000)

    timeoutsRef.current.push(timeout)
  }, [chapter, episode])

  useEffect(() => {
    const timeouts = timeoutsRef.current
    return () => {
      timeouts.forEach((timeout) => window.clearTimeout(timeout))
    }
  }, [])

  const prevChapter = chapter?.prev
    ? `/episode/${chapter.prev.episode}/${chapter.prev.chapter}`
    : null

  const nextChapter = chapter?.next
    ? `/episode/${chapter.next.episode}/${chapter.next.chapter}`
    : null

  return (
    <>
      <Helmet>
        <title>عالم زيدو - الفصل</title>
      </Helmet>
      {isLoadingChapter ? <AppProgressBar /> : null}
      <AppBar
        closeIconReplaceBackButton
        fixed
        hideToggleButton
        showBackButton
        showHomeLink
        showLogoutButton
        title={chapter?.chapter ?? ''}
      />

      <AppContent className="bg-[#323232] h-screen pages_anonymous_chapterplay_appcontent">
        <div className="flex flex-col lg:pt-10 max-w-[1024px] mx-auto pages_anonymous_chapterplay_div_container">
          <div className="h-[500px] max-h-screen">
            {chapter ? (
              <ChapterContent
                chapterSlug={chapter.slug}
                episode={episode}
                next={nextChapter}
                onVideoEnded={onVideoCompleted}
                prev={prevChapter}
                src={Helper.MediaURL(chapter.url, 'stream')}
              />
            ) : null}
          </div>
          <div className="pb-6">
            <ContentTitle title={chapter?.chapter ?? ''} />
          </div>
        </div>

        {showModal && level ? (
          <GoToGamesModal
            epiSlug={epiSlug}
            level={level}
            onClose={closeModal}
          />
        ) : null}
      </AppContent>
    </>
  )
}

export default ChapterPlay

const ChapterContent: FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  episode?: any
  src: string
  next?: string | null
  prev?: string | null
  gameId?: string | null
  chapterSlug: string
  showGame?: boolean
  activity?: IActivityDetail
  onVideoEnded: () => void
}> = (props) => {
  const token = useJwtToken()

  return (
    <div className="h-full w-full pages_anonymous_chapterplay_chaptercontent_container bg-transparent rounded-none lg:rounded-3xl overflow-hidden">
      {!props.showGame ? (
        <VideoPlayer
          className="pages_anonymous_chapterplay_chaptercontent_videoplayer"
          episode={props.episode}
          from="chapter-anoymous"
          next={props.next}
          onEnd={props.onVideoEnded}
          prev={props.prev}
          slug={props.chapterSlug}
          src={props.src}
        />
      ) : null}

      {props.showGame && props.gameId && props.activity ? (
        <GameIFrame
          apiUrl={Config.apiURL}
          episode={props.episode}
          from="chapterPlay"
          gameId={props.gameId}
          iframeProps={{
            src: Helper.MediaURL(props.activity.content),
            frameBorder: '0',
            allowFullScreen: true,
            width: '100%',
            height: '100%',
          }}
          token={token}
        />
      ) : null}
    </div>
  )
}

const GoToGamesModal: FC<{
  level: string
  epiSlug: string
  onClose: () => void
}> = (props) => {
  let goToUrl: string
  switch (props.level) {
    case '1':
      goToUrl = '/anonymous-episode/anonymous-chapters/1/' + props.epiSlug
      break
    case '2':
      goToUrl = '/anonymous-episode/anonymous-chapters/2/' + props.epiSlug
      break
    default:
      return null
  }

  return (
    <Modal
      button={{
        icon: <FontAwesomeIcon icon={faGamepad} />,
        text: 'إلعب الأن',
        goTo: goToUrl,
        bgColor: 'bg-[#fbf5be]',
        textColor: 'text-[#f6580f]',
        className:
          'shadow-modal-button pages_anonymous_chapterplay_modal_button',
      }}
      close={props.onClose}
      icon={
        <FontAwesomeIcon
          className="pages_anonymous_chapterplay_modal_icon"
          icon={faCheck}
        />
      }
      isOpen
      message="لقد أنهيت مشاهدة الحلقة وحان الآن وقت الكتاب التفاعلي والألعاب.."
      title="رائع.."
    />
  )
}
