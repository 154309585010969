import React, { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import { AllActivities } from '@components/AllActivities'
import { OrangeLink } from '@components/Buttons'
import { ContentTitle } from '@components/ContentTitle'
import { AppBar, AppContent } from '@components/Layout'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import { useActivities } from '@hooks/use-activities'
import { usePurchases } from '@hooks/use-purchases'
import Popup from '@components/Popup'
import {
  REQUIRES_SUBSCRIBE_MESSAGE,
  SUBSCRIBE_NOW_MESSAGE,
  THIS_CONTENT_MESSAGE,
} from '@src/constants/messages'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'

const Activities: FC = () => {
  const { chapSlug, epiSlug } = useParams<{
    epiSlug?: string
    chapSlug?: string
  }>()

  const { isLoadingActivities, activities } = useActivities({
    chapSlug,
    epiSlug,
  })

  const { kidProfile } = useProfile()

  const [isVisable, setIsVisable] = useState(false)

  const [hasProAccess, setHasProAccess] = useState(false)

  const purchases = usePurchases()

  const [isLoadingPayment, setIsLoadingPayment] = useState(true)

  useEffect(() => {
    purchases
      .isHavingProAccess(kidProfile?.level === 1 ? 'level-1' : 'level-2')
      .then(setHasProAccess)
      .then(() => {
        setIsLoadingPayment(false)
      })
  }, [purchases, kidProfile?.level])

  return (
    <>
      <Helmet>
        <title className="activities_page_title">عالم زيدو - أنشطة</title>
      </Helmet>
      {isLoadingActivities || isLoadingPayment ? <AppProgressBar /> : null}
      <AppBar
        fixed
        showHomeLink
        showLogoutButton
        title="أنشطة الحلقات"
        titleCenter
      />

      <AppContent className="activities_page_app_content bg-activities">
        <ContentTitle title="أنشطة الحلقات" />
        <div className="activities_page_container py-4 px-4 sm:px-16">
          <div className="activities_page_orange_link_wrapper flex justify-end mb-4">
            <OrangeLink
              className="activities_page_orange_link"
              label="زر المعرض"
              to="/gallery"
            />
          </div>

          {activities ? (
            <AllActivities
              activities={activities}
              hasProAccess={hasProAccess}
              setIsVisable={setIsVisable}
            />
          ) : null}
        </div>

        <Popup
          acceptLink={!hasProAccess ? '/parent?redirect=subscribe' : null}
          buttonText={!hasProAccess ? SUBSCRIBE_NOW_MESSAGE : null}
          message={hasProAccess ? '' : REQUIRES_SUBSCRIBE_MESSAGE}
          onClose={() => {
            setIsVisable(false)
          }}
          title={hasProAccess ? '' : THIS_CONTENT_MESSAGE}
          visible={isVisable}
        />
      </AppContent>
    </>
  )
}

export default Activities
