import { faClock } from '@fortawesome/free-regular-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import Modal from '@components/Modal'
import { useAppUrlListener } from '@hooks/use-app-url-listener'
import { useAppUpdateInfo } from '@hooks/use-inapp-updates'
import { useCloseAppBackButton } from '@hooks/use-native-back-button'
import {
  useScreenLandscape,
  useScreenPortrait,
} from '@hooks/use-screen-orientation'
import { AndroidFullScreen } from '@awesome-cordova-plugins/android-full-screen'

import Routes from './routes'
import { GtagEvents } from '@utils/gtag-events'
import Helper from '@utils/Helpers'
import { usePurchases } from '@hooks/use-purchases'
import { initialize } from '@utils/firebase-analytics'
import {
  LocalNotifications,
  PendingResult,
} from '@capacitor/local-notifications'
import LOCAL_NOTIFICATIONS from '@src/constants/localNotification'
import { Capacitor } from '@capacitor/core'
import { usePushNotificationsV2 } from '@hooks/data/use-push-notification-v2'
import { useHistory } from 'react-router-dom'
// https://github.com/CleverTap/clevertap-cordova
import { CleverTapHandler } from '@src/CleverTap/CleverTap'
import { ILocalNotification } from './types/api.interface'
import * as Sentry from '@sentry/react'
import OrientationWarning from '@components/OrientationWarning'
import ScriptImporter from '@components/ScriptImporter'
import HelperLocalNotification from '@utils/helpers/helper.localnotification'
import HelperAPI from '@utils/helpers/helper.api'
import { StatusBar } from '@capacitor/status-bar'
import { useOnBoarding } from '@hooks/data/fetchers/useOnBoarding'

import { SingularInit } from '@utils/analytics/Singular'
import { onClarityStart } from './ClarityPlugin'

const MESSAGE_MAX_TIME =
  'لقد انقضى الوقت المسموح به على المنصة لهذا اليوم. سنتابع غدا إن شاء الله'
const MESSAGE_MAX_TIME_TITLE = 'المعذرة'
const MESSAGE_MAX_TIME_BUTTON = 'نعم إن شاء الله'

const App: FC = () => {
  const history = useHistory()

  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (Capacitor.getPlatform() === 'android') {
      // ios is not supported in clarity
      onClarityStart()
    } else {
      ScriptImporter('/js/clarity.js')
    }
  })

  useEffect(() => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      SingularInit()
    } catch (e) {
      console.error('singular error:', e)
    }
  })
  useEffect(() => {
    const hideStatusBar = async () => {
      await StatusBar.hide()
    }

    if (Capacitor.isNativePlatform()) {
      hideStatusBar()
    }

    const handleImmersive = () => {
      if (Capacitor.isNativePlatform()) {
        if (Capacitor.getPlatform() === 'android') {
          AndroidFullScreen.immersiveMode()
        }
        hideStatusBar()
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = (callback: any) => {
      window.addEventListener('focus', callback)
      window.addEventListener('blur', callback)
      document.addEventListener('click', callback)
    }

    handleChange(handleImmersive)

    HelperAPI.ApiRequest<ILocalNotification[]>('/local-notification')
      .then((localNotification) => {
        if (Capacitor.isNativePlatform()) {
          askPermission()
          cancelAllNotification(localNotification)
          scheduleAllNotification(localNotification)
        }
      })
      .catch((error) => {
        console.error(error)
        Sentry.captureException(error)
      })

    // TODO: Patched the android crash bug. Will recheck this in the future
    // if (Capacitor.isNativePlatform()) {
    //   // if is a mobile, and is on protected routes, we need to
    //   // allow only when the user is logged in
    //   setInterval(() => {
    //     const unprotectedRoutes = [
    //       'login',
    //       'register',
    //       'anonymous-select-age',
    //       'forgot-password',
    //       'reset-password',
    //       'onboarding',
    //     ]
    //     unprotectedRoutes.forEach((r) => {
    //       const route = `/${r}`
    //       // it shouldnt be public routes to check session on.
    //       const routeCheck =
    //         history.location.pathname.startsWith(route) ||
    //         history.location.pathname === '/'
    //       if (!routeCheck && !profile) {
    //         logout()
    //       }
    //     })
    //   }, 5000)
    // }
  }, [])

  const askPermission = async () => {
    await LocalNotifications.requestPermissions()
  }

  const cancelAllNotification = async (
    localNotifications: ILocalNotification[]
  ) => {
    const pendingLocalData = []

    localNotifications.map((data) => {
      return pendingLocalData.push({
        id: data.id,
        title: data.title,
        body: data.message,
      })
    })

    pendingLocalData.push(...LOCAL_NOTIFICATIONS)

    const pendingNotifications: PendingResult = {
      notifications: pendingLocalData,
    }
    await LocalNotifications.cancel(pendingNotifications)
  }

  const scheduleAllNotification = async (
    localNotifications: ILocalNotification[]
  ) => {
    // initialize to true if local storage is empty
    // if (
    //   HelperLocalNotification.getLocalNotificationStatus('competition') === null
    // )
    //   HelperLocalNotification.setLocalNotificationStatus('competition', true)

    // if (HelperLocalNotification.getLocalNotificationStatus('maqraa') === null)
    //   HelperLocalNotification.setLocalNotificationStatus('maqraa', true)

    // if (
    //   HelperLocalNotification.getLocalNotificationStatus('curriculum') === null
    // )
    //   HelperLocalNotification.setLocalNotificationStatus('curriculum', true)

    for (const data of localNotifications) {
      const splitTime = data.time.split(':')
      const hour = Number(data && splitTime[0])
      const minute = Number(data && splitTime[1])

      if (
        HelperLocalNotification.getLocalNotificationStatus('competition') &&
        data.type === 'competition'
      ) {
        // daily (Competition)
        await HelperLocalNotification.addLocalNotificationByOn(
          data.id,
          data.title,
          data.message,
          hour,
          minute
        )
      }

      if (
        HelperLocalNotification.getLocalNotificationStatus('curriculum') &&
        data.type === 'curriculum'
      ) {
        // daily (Curriculum)
        await HelperLocalNotification.addLocalNotificationByOn(
          data.id,
          data.title,
          data.message,
          hour,
          minute
        )
      }

      if (
        HelperLocalNotification.getLocalNotificationStatus('maqraa') &&
        data.type === 'maqraa'
      ) {
        await HelperLocalNotification.addLocalNotificationByOn(
          data.id,
          data.title,
          data.message,
          hour,
          minute
        )
      }
    }

    // 1 day after last opening
    await HelperLocalNotification.addLocalNotificationByAt(
      LOCAL_NOTIFICATIONS[0].id,
      LOCAL_NOTIFICATIONS[0].title,
      LOCAL_NOTIFICATIONS[0].body,
      1
    )

    // 3 day after last opening
    await HelperLocalNotification.addLocalNotificationByAt(
      LOCAL_NOTIFICATIONS[1].id,
      LOCAL_NOTIFICATIONS[1].title,
      LOCAL_NOTIFICATIONS[1].body,
      3
    )

    // 7 day after last opening
    await HelperLocalNotification.addLocalNotificationByAt(
      LOCAL_NOTIFICATIONS[2].id,
      LOCAL_NOTIFICATIONS[2].title,
      LOCAL_NOTIFICATIONS[2].body,
      7
    )
  }

  /**
   * No more time today : show modal and log out
   */
  // const onMaxTimeElapsed = useCallback(() => {
  //   logout()
  //   setShowModal(true)
  // }, [logout])

  const closeModal = useCallback(() => {
    setShowModal(false)
  }, [])

  // useEffect(() => {
  //   if (hasTime) return
  //   onMaxTimeElapsed()
  // }, [hasTime, onMaxTimeElapsed])

  useAppUpdateInfo()
  const previousURL = useRef('')
  const urlInterval = useRef(null)

  const [onboardingsData, isLoading] = useOnBoarding()
  useEffect(() => {
    const onboardingStatus = Helper.getOnboardingStatus()
    if (!isLoading && !!onboardingsData?.length && !onboardingStatus) {
      history.replace('/onboarding')
      return
    }

    clearInterval(urlInterval.current)
    urlInterval.current = setInterval(() => {
      const isListKidHome = '/list-kid-home'
      // eslint-disable-next-line no-restricted-globals
      const { pathname } = new URL(location.href)
      const path = pathname
      if (previousURL.current !== path) {
        // temporarily hardcoded
        const paths =
          'handbook-home,parent,list-kid,add-kid,notification,settings,subscription,subscription/current,subscription/package,settings/notification-control'
        const whiteListedPathNames = paths
          ? paths
              .split(',')
              .map((e) => e.trim().toLowerCase().replace(/\//gm, ''))
          : ['']
        const routeExists = (url: string): boolean => {
          // for log
          // console.log({
          //   url,
          //   whiteListedPathNames,
          //   length: paths.split(',').length,
          // })
          if (pathname === isListKidHome) {
            return false
          }
          if (whiteListedPathNames && Array.isArray(whiteListedPathNames)) {
            const matchingUrl = url.trim().toLowerCase().replace(/\//gm, '')
            const matched = whiteListedPathNames.some((whiteListedURL) => {
              return matchingUrl.startsWith(whiteListedURL)
            })
            return matched
          }
          return false
        }

        if (
          // doesn't exist, or isn't defined
          !whiteListedPathNames ||
          // exists but is not array
          !Array.isArray(whiteListedPathNames) ||
          // is array but is empty
          (Array.isArray(whiteListedPathNames) &&
            whiteListedPathNames.length === 0) ||
          // array exists and it matches
          (Array.isArray(whiteListedPathNames) &&
            whiteListedPathNames.length > 0 &&
            routeExists(pathname))
        ) {
          Helper.setupTawkTo()
        } else {
          Helper.removeTawkTo()
        }
        previousURL.current = path
      }
    }, 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-restricted-globals
  }, [isLoading, onboardingsData])

  const purchases = usePurchases()

  useEffect(() => {
    purchases.setup()
  }, [purchases])

  useEffect(() => {
    // const isEnvProd = process.env.NODE_ENV?.toLowerCase()?.startsWith('prod')
    // removed as per 4-march
    // const hotJarFile = isEnvProd ? 'hotjar-production.js' : 'hotjar-staging.js'
    // ScriptImporter(`/js/${hotJarFile}`)
    if (Capacitor.getPlatform() === 'web') {
      ScriptImporter('/js/gtm.js', true, 'ga-gtm')
    }
    initialize()
  })

  const notification = usePushNotificationsV2()

  const isPortrait = useScreenPortrait()

  useEffect(() => {
    notification.setup(history)
  }, [notification, history])

  useScreenLandscape()

  useAppUrlListener()

  useCloseAppBackButton()

  useEventsOpenAndCloseApp()

  return (
    <div className="select-none app_main_container">
      {isPortrait ? <OrientationWarning /> : <Routes />}
      <CleverTapHandler />
      {showModal ? (
        <Modal
          button={{
            icon: (
              <FontAwesomeIcon
                className="app_main_modal_button_icon"
                icon={faCheck}
              />
            ),
            text: MESSAGE_MAX_TIME_BUTTON,
            goTo: '/',
            onClick: closeModal,
          }}
          close={closeModal}
          icon={
            <FontAwesomeIcon className="app_main_modal_icon" icon={faClock} />
          }
          isOpen
          message={MESSAGE_MAX_TIME}
          title={MESSAGE_MAX_TIME_TITLE}
        />
      ) : null}
    </div>
  )
}

export default App

function useEventsOpenAndCloseApp() {
  useEffect(() => {
    GtagEvents.openApp()
    return () => {
      GtagEvents.closeApp({ type: 'other' })
    }
  }, [])
}
