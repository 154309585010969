type CardProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

const Card = ({ children, className, ...props }: CardProps) => {
  return (
    <div
      {...props}
      className={`bg-[#EFF4F8] rounded-[22px] component_card_container ${className}`}
    >
      {children}
    </div>
  )
}

export default Card
