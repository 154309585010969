import React, { FC, useCallback, useState } from 'react'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import './style.css'
import Config from '@config/index'
import {
  moreThanThreeDays,
  UNLOCK_CONTENT_MESSAGE,
  UNLOCK_ONE_DAY,
  UNLOCK_TWO_DAYS,
} from '@src/constants/messages'
import { useScheduleKids } from '@hooks/data/fetchers/use-schedule-kids'
const pointer = `${Config.publicURL}/images/pointer.png`
const sandclock = `${Config.publicURL}/images/sandclock.svg`

const ZidoPointer: FC = () => {
  const { parentProfile } = useProfile()
  const { scheduleKid } = useScheduleKids()
  const [showMessage, setShowMessage] = useState(false)
  const isAnonymous =
    parentProfile?.email === 'user@anonymous.firebase' || !parentProfile
  const displayMessage = useCallback(() => {
    setShowMessage(true)
  }, [])
  const hideMessage = useCallback(() => {
    setShowMessage(false)
  }, [])

  if (Object.keys(scheduleKid).length === 0) return null

  if (!scheduleKid?.locked) return null

  const timeUntil =
    scheduleKid?.activeSchedule?.period - scheduleKid?.daysPeriod
  if (timeUntil <= 0) return null

  let timeMessage = `${timeUntil} يوم`
  if (timeUntil === 1) timeMessage = 'يوم واحد'
  else if (timeUntil === 2) timeMessage = 'يومين'
  else if (timeUntil <= 10) timeMessage = `${timeUntil} أيام`
  if (isAnonymous) return null // disable if anonymous profile
  return (
    <div className="component_pending_unit_container">
      <div
        className="zidoPendingUnit component_pending_unit_child_container"
        onMouseOut={hideMessage}
        onMouseOver={displayMessage}
        onTouchStart={displayMessage}
      >
        <img
          alt="pointer"
          className="component_pending_unit_image"
          src={pointer}
        />
        <div
          className={
            `component_pending_unit_message_container zidoPendingUnit-bull ` +
            (showMessage ? 'zidoPendingUnit-message' : '')
          }
        >
          <img
            alt="sand clock"
            className={`sandclock component_pending_unit_sand_clock_image ${
              showMessage ? 'absolute' : ''
            }`}
            src={sandclock}
          />
          {!showMessage ? <br /> : null}
          {!showMessage ? (
            <span className="overflow-visible whitespace-nowrap component_pending_unit_time_message_span">
              {timeMessage}
            </span>
          ) : (
            <div
              className="component_pending_unit_time_container"
              style={{ pointerEvents: 'none' }}
            >
              <p className="component_pending_unit_time_paragraph">
                {timeUntil === 1
                  ? UNLOCK_ONE_DAY
                  : timeUntil === 2
                  ? UNLOCK_TWO_DAYS
                  : moreThanThreeDays(timeUntil)}
              </p>
              <p
                className="component_pending_unit_unlock_message_paragraph"
                style={{ color: 'black' }}
              >
                {UNLOCK_CONTENT_MESSAGE}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ZidoPointer
