import { Capacitor } from '@capacitor/core'
import Config from '@config/index'
import { useMobileView } from '@hooks/useMobileView'
import { ZIDO_TYPOGRAPHY } from '@src/services'
import { FC } from 'react'
import { Link } from 'react-router-dom'

export const SettingsLinkComponent = ({
  url,
  text,
  image,
  colorClass = 'bg-white hover:bg-gray-200',
  className = 'component_settings_popover_link_container_classname_prop',
}: {
  url: string
  text: string
  image: string
  colorClass?: string
  className?: string
}) => {
  return (
    <Link
      className={`${className} component_settings_popover_link_container flex flex-row-reverse w-full h-[60px] p-2 justify-center cursor-pointer ${colorClass}`}
      to={url}
    >
      <div className="component_settings_popover_link_div_text_container flex w-full items-center px-2 pt-2">
        <div className="text-[20px] font-black block text-right component_settings_popover_div_text">
          {text}
        </div>
      </div>
      <div className="inline-block w-[55px] px-2 h-[45px] ml-2 component_settings_popover_link_div_image_container">
        <img
          alt="img"
          className="w-full h-full object-fit component_settings_popover_link_div_image"
          src={image}
        />
      </div>
    </Link>
  )
}
const SettingsPopover: FC<{
  onClickHowItWorks: () => void
}> = ({ onClickHowItWorks }) => {
  const isMobileView = useMobileView()
  const isNativePlatform = Capacitor.isNativePlatform()

  return (
    <div className="settings component_settings_popover_link_settings_container min-h-[60px] pb-2 w-[273px] block font-kidzhood mx-auto bg-white">
      <SettingsLinkComponent
        className="component_settings_popover_link_settings_container_subscribe_text"
        image={`${Config.publicURL}/images/v3/crownBtn.svg`}
        text={ZIDO_TYPOGRAPHY.SUBSCRIBE_TEXT}
        url="/subscription/current"
      />
      <SettingsLinkComponent
        className="component_settings_popover_link_settings_container_blue_book"
        image={`${Config.publicURL}/images/v3/blueBookBtn.svg`}
        text={ZIDO_TYPOGRAPHY.APPROACH_TEXT}
        url="/curriculum"
      />
      {!isNativePlatform && isMobileView ? (
        <button
          className="hover:bg-gray-200 component_settings_popover_link_settings_container_mobile_view_container"
          onClick={onClickHowItWorks}
          type="button"
        >
          <SettingsLinkComponent
            className="component_settings_popover_link_settings_container_mobile_view_setting_how_it_work"
            colorClass=""
            image="images/v3/purpleQuestionMarkBtn.svg"
            text={ZIDO_TYPOGRAPHY.HOW_IT_WORS_TEXT}
            url="#"
          />
        </button>
      ) : null}
      <SettingsLinkComponent
        className="component_settings_popover_link_settings_container_mobile_view_settings_text"
        image={`${Config.publicURL}/images/v3/SkySettingsBtn.svg`}
        text={ZIDO_TYPOGRAPHY.SETTINGS_TEXT}
        url="/settings"
      />
    </div>
  )
}

export default SettingsPopover
