import useSwr from 'swr'
import { ActivityCategory, IEpisode } from '@src/types/api.interface'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { FetchError } from '@utils/FetchError'

export function useAllActivities(category: ActivityCategory): {
  allActivities?: IEpisode[]
  error?: FetchError
  isLoadingActivities: boolean
} {
  const { kidProfile: profile } = useProfile()

  const level = profile?.level
  const kidId = profile?._id

  const url = level
    ? `/level/${level}/all-activities/${kidId}/${category}`
    : null

  const { data, error } = useSwr<IEpisode[], FetchError>(url)

  return {
    allActivities: data && Array.isArray(data) ? data : [],
    error,
    isLoadingActivities: !error && !data,
  } as {
    allActivities: IEpisode[]
    error: FetchError
    isLoadingActivities: boolean
  }
}
