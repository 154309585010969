import { ContentTitle } from '@components/ContentTitle'
import { AppBar, AppContent } from '@components/Layout'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import Config from '@config/index'
import { useActiveChallenge } from '@hooks/data/fetchers/competition/useActiveChallenge'
import { useGetChallenges } from '@hooks/data/fetchers/competition/useGetChallenges'
import { ICompetitionChallenge } from '@src/types/api.interface'
import { FC, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import SVG from 'react-inlinesvg'
import { useParams } from 'react-router'
import { useHistory, useLocation } from 'react-router-dom'
import { CHALLENGES_TYPOGRAPHY, TYPOGRAPHY } from './constants'
import Popup from '@components/Popup'

const coinIcon = `${Config.publicURL}/images/v3/coin-icon.svg`
const arrowIcon = `${Config.publicURL}/images/v3/yellow-arrow-icon.svg`

interface ChallengeCardProps extends ICompetitionChallenge {
  index: number
  activeChallengeId: string
  setIsPopupVisiable: (arg: boolean) => void
}

const CompetitionChallenges: FC = () => {
  const { id } = useParams<{ id: string }>()
  const { challenges, isLoadingChallenges } = useGetChallenges(id)
  const { activeChallenge, isLoadingActiveChallenge } = useActiveChallenge()
  const { state } = useLocation<{ url: string; title: string }>()
  const [isPopupVisiable, setIsPopupVisiable] = useState<boolean>(false)

  const activeChallengeId = activeChallenge?.[0]?._id

  return (
    <>
      <Helmet>
        <title className="competition_challenges_page-main-title">
          {TYPOGRAPHY.WEBSITE_TITLE}
        </title>
      </Helmet>

      {isLoadingChallenges && isLoadingActiveChallenge ? (
        <AppProgressBar />
      ) : null}

      <AppBar
        backButtonLocation="/competition/list"
        fixed
        hideToggleButton
        showKidAvatar
        showParentBackButton
      />

      <AppContent className="bg-no-repeat bg-cover bg-center bg-green-v3">
        <ContentTitle notBold title={state.title || ''} />
        <ContentTitle
          noShadow
          notBold
          textClassname="text-lg sm:text-xl md:text-2xl"
          title={CHALLENGES_TYPOGRAPHY.SUBTITLE}
        />

        <div className="competition_challenges_page-container flex flex-wrap justify-center w-7/12 mt-8 mb-2 lg:mb-4  pt-12 gap-x-6 gap-y-12 mx-auto">
          {challenges?.map((challenge, index) => {
            return (
              <div
                className="competition_challenges_page-wrapper relative flex flex-col justify-center items-center"
                key={challenge._id}
              >
                {challenge.score !== null ? (
                  <div
                    className={`competition_challenges_page-score-wrapper-${challenge._id} z-20 absolute -top-5 px-2 py-1 bg-white border-[#FFCF01] border-[3px] rounded-full flex flex-row items-center justify-between gap-x-2`}
                  >
                    <p
                      className={`competition_challenges_page-challenge-score-${challenge._id} text-3xl leading-3 text-black`}
                    >
                      {challenge.score}
                    </p>
                    <SVG
                      className={`competition_challenges_page-coin-icon-${challenge._id} w-5 h-5`}
                      src={coinIcon}
                    />
                  </div>
                ) : null}

                {challenge.status === 'ACTIVE' &&
                challenge.score === null &&
                challenge._id === activeChallengeId ? (
                  <SVG
                    className={`competition_challenges_page-active-challenge-svg-${
                      challenge._id
                    } z-20 absolute ${
                      challenge.score ? '-top-1/2' : '-top-1/4'
                    } 
                    -translate-y-1/4`}
                    src={arrowIcon}
                  />
                ) : null}
                <ChallengeCard
                  {...challenge}
                  activeChallengeId={activeChallengeId}
                  index={index}
                  setIsPopupVisiable={setIsPopupVisiable}
                />
              </div>
            )
          })}
        </div>

        <Popup
          buttonOnClick={() => {
            setIsPopupVisiable(false)
          }}
          buttonText={CHALLENGES_TYPOGRAPHY.POPUP_BUTTON_TEXT}
          competitionPopup
          message={CHALLENGES_TYPOGRAPHY.POPUP_MESSAGE}
          onClose={() => {
            setIsPopupVisiable(false)
          }}
          title={CHALLENGES_TYPOGRAPHY.POPUP_TITLE}
          visible={isPopupVisiable}
        />
      </AppContent>
    </>
  )
}

/**
 * There is 3 status (BASED ON THE DATE AND NOT BASED ON PARTICIPATION) + 1 derived status
 * ACTIVE: current date overlap with challenge's date
 * PAST: challenge that is already past based on date
 * FUTURE: challenge that is yet to come based on date
 * status === 'PAST' && score !== null: meaning past challenge that kid did not participate
 */

const ChallengeCard: FC<ChallengeCardProps> = ({
  status,
  isParticipated,
  score,
  index,
  _id,
  url,
  title,
  activeChallengeId,
  setIsPopupVisiable,
}) => {
  const history = useHistory()
  const onClick = async () => {
    history.push({
      pathname: `/competition/challenge/${_id}`,
      state: {
        url,
        title,
        isHideScore: status !== 'ACTIVE',
      },
    })
  }

  const order = index + 1

  if (status === 'ACTIVE' && score === null && _id === activeChallengeId) {
    return (
      <button
        className="competition_challenges_page-challenge-active-button h-24 w-24 flex justify-center items-center rounded-[2.75rem] bg-gradient-to-b from-[#FDF8E1] to-[#FCD92A]"
        onClick={onClick}
        type="button"
      >
        <div className="competition_challenges_page-active-challenge-typography-wrapper h-20 w-20 rounded-[2.25rem] bg-gradient-to-b from-[#F8881A] to-[#D47415]">
          <p className="competition_challenges_page-active-challenge-typography text-2xl absolute z-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -mt-5 text-[#FFF500]">
            {CHALLENGES_TYPOGRAPHY.CHALLENGE}
          </p>

          <p
            className={`competition_challenges_page-active-order-${order} text-4xl absolute z-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 mt-3 text-white`}
          >
            {order}
          </p>
        </div>
      </button>
    )
  }

  if (status === 'FUTURE') {
    return (
      <button
        className="competition_challenges_page-challenge-future-button h-24 w-24 flex justify-center items-center rounded-[2.75rem] bg-gradient-to-b from-[#DFE5F2] to-[#CACACA]"
        onClick={(e) => {
          e.preventDefault()
          setIsPopupVisiable(true)
        }}
        type="button"
      >
        <div className="competition_challenges_page-future-challenge-typography-wrapper h-20 w-20 rounded-[2.25rem] bg-gradient-to-b from-[#9EA9C3] to-[#9EA9C3]">
          <p className="competition_challenges_page-future-challenge-typography text-2xl absolute z-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -mt-5 text-[#FBFAFC]">
            {CHALLENGES_TYPOGRAPHY.CHALLENGE}
          </p>

          <p
            className={`competition_challenges_page-future-order-${order} text-4xl absolute z-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 mt-3 text-[#FBFAFC]`}
          >
            {order}
          </p>
        </div>
      </button>
    )
  }

  if (status === 'PAST' && !isParticipated) {
    return (
      <button
        className="competition_challenges_page-past-challenge-button h-24 w-24 flex justify-center items-center rounded-[2.75rem] bg-gradient-to-b from-white to-[#ADE88A]"
        onClick={onClick}
        type="button"
      >
        <div className="competition_challenges_page-past-challenge-wrapper h-20 w-20 rounded-[2.25rem] bg-[#697AA3]">
          <p className="competition_challenges_page-past-challenge-typography text-2xl absolute z-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -mt-5 text-[#FFF500]">
            {CHALLENGES_TYPOGRAPHY.CHALLENGE}
          </p>

          <p
            className={`competition_challenges_page-past-challenge-order-${order} text-4xl absolute z-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 mt-3 text-[#FBFAFC]`}
          >
            {order}
          </p>
        </div>
      </button>
    )
  }

  return (
    <button
      className="competition_challenges_page-default-challenge-button h-24 w-24 flex justify-center items-center rounded-[2.75rem] bg-gradient-to-b from-white to-[#ADE88A]"
      onClick={onClick}
      type="button"
    >
      <div className="competition_challenges_page-default-challenge-wrapper h-20 w-20 rounded-[2.25rem] bg-gradient-to-b from-[#99BC2D] to-[#80A219]">
        <p className="competition_challenges_page-default-challenge-typography text-2xl absolute z-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -mt-5 text-[#FFF500]">
          {CHALLENGES_TYPOGRAPHY.CHALLENGE}
        </p>

        <p
          className={`competition_challenges_page-default-order-${order} text-4xl absolute z-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 mt-3 text-white`}
        >
          {order}
        </p>
      </div>
    </button>
  )
}

export default CompetitionChallenges
