import { useMemo } from 'react'
import { ICompetition, IUserAnswer } from '@src/types/api.interface'
import { FetchError } from '@utils/FetchError'
import { useCompetition } from './use-competition'
import { useUserAnswers } from './use-user-answers'

export function useCompetitionWithUserAnswers(): {
  competition?: ICompetition
  error?: FetchError
  isLoadingCompetition: boolean
  userAnswers?: IUserAnswer[] | undefined
} {
  const { competition, isLoadingCompetition, error } = useCompetition()
  const {
    userAnswers,
    isLoadingUserAnswers,
    error: errorUserAnswers,
  } = useUserAnswers(competition?.challenges?.[0]?.uniqueName)

  const competitionWithUserAnswers: ICompetition | undefined = useMemo(() => {
    if (!competition || !userAnswers) return

    return {
      ...competition,
      challenges: competition.challenges.map((challenge) => {
        return {
          ...challenge,
          questions: challenge.questions.map((question) => {
            const answer = userAnswers.find(
              (a) => a.questionNumber === question.number
            )?.answer

            return {
              ...question,
              userAnswer: typeof answer === 'number' ? answer : null,
            }
          }),
        }
      }),
    }
  }, [competition, userAnswers])

  return useMemo(
    () => ({
      competition: competitionWithUserAnswers,
      error: error || errorUserAnswers,
      isLoadingCompetition: isLoadingCompetition || isLoadingUserAnswers,
      userAnswers,
    }),
    [
      competitionWithUserAnswers,
      error,
      errorUserAnswers,
      isLoadingUserAnswers,
      isLoadingCompetition,
      userAnswers,
    ]
  )
}
