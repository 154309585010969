import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Helmet } from 'react-helmet-async'
import Isvg from 'react-inlinesvg'
import { Link, useHistory } from 'react-router-dom'
import { ILocation, IMap } from '@src/types/api.interface'
import { ContentTitle } from '@components/ContentTitle'
import { AppBar, AppContent } from '@components/Layout'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { useMaps } from '@hooks/data/fetchers/use-maps'
import { SVG_MAPS_LEVEL_3 } from '@utils/constants'

const LV3_ACTIVITIES_BUTTON = 'الذهاب للأنشطة'

export const NotActivePage: FC = () => {
  return (
    <>
      <Helmet>
        <title>عالم زيدو - الصفحة الرئيسية من المستوى 3</title>
      </Helmet>
      <AppBar
        backButtonLocation="/kids-home"
        fixed
        showBackButton={true}
        showLogoutButton
        showProfileButton
        title=""
        titleCenter
      />
      <AppContent className="px-0 sm:px-10 lg:px-0 bg-cover bg-home-page pages_level3_home_appcontent">
        <div className="max-w-xl mx-auto h-screen flex justify-center items-center pages_level3_home_container">
          <HelpMessage>تابعنا قريبا للاستمتاع بمحتوي مناسب لسنك</HelpMessage>
        </div>
      </AppContent>
    </>
  )
}

const HelpMessage = (props: { children: React.ReactNode | string }) => {
  return (
    <div className="pages_level3_home_helpmessage_text p-2 md:p-3 shadow-lg rounded-xl mx-auto bg-white text-[#eb6600] w-full text-center text-md md:text-xl mt-4">
      {props.children}
    </div>
  )
}

const MapsHomeLevel3: FC = () => {
  const history = useHistory()
  const { kidProfile: profile, profileType } = useProfile()
  const level = profile?.level

  const pendingUnit = profile?.pendingUnit

  const { maps } = useMaps()
  const [activeMapId, setActiveMap] = useState<number>()

  const [svgLoaded, setSvgLoaded] = useState<boolean[]>([])

  const map = typeof activeMapId === 'number' ? maps?.[activeMapId] : undefined

  const firstLocked = useRef<boolean | number | undefined>(true)

  const handleClick = useCallback(
    (location: ILocation) => {
      if (!map?.episode) return
      history.push(`/episode/${map?.episode?.slug}/${location.slug}`)
    },
    [map, history]
  )

  useEffect(() => {
    if (!level) return
    if (level !== 3) return
    if (!maps) return

    let activeMapId: number | undefined

    maps.forEach((map, i) => {
      map.url = SVG_MAPS_LEVEL_3[i]
      // look for the map which will be presented to the kid initially (landing map)
      if (activeMapId === undefined)
        // enter the next loop only if the activeMapId is not yet set/found
        for (let j = 0; j < map.locations.length; j++) {
          if (!map.locations[j].watched) {
            activeMapId = i
            map.allWatched = false
            break
          }
          // allWatched is a flag to know if all locations of the map have been watched
          map.allWatched = true
        }
    })

    if (pendingUnit !== false && pendingUnit) {
      for (let index = 0; index < maps.length; index++) {
        const _slug = maps[index].episode?.slug
        if (_slug === pendingUnit.slug) {
          activeMapId = index
          break
        }
      }
    }

    setActiveMap(activeMapId ?? 0)
  }, [maps, level, pendingUnit])

  const showPrevMap = useCallback(() => {
    setActiveMap((activeMapId) => {
      if (activeMapId === undefined) return
      return activeMapId - 1
    })
  }, [])

  const showNextMap = useCallback(() => {
    setActiveMap((activeMapId) => {
      if (activeMapId === undefined) return
      return activeMapId + 1
    })
  }, [])

  const onSVGLoad = useCallback(() => {
    if (!map) return

    setSvgLoaded((loads) => {
      if (activeMapId === undefined) return loads
      loads[activeMapId] = true
      return [...loads]
    })

    const locations = map.locations

    // start by ignoring every location in the map which does not match a location from the database
    const mapLocations = document.getElementsByClassName('map-location')

    const slugs = locations.map((location) => location.slug)

    const inactiveMapLocationsIds = [] as string[]

    for (let i = 0; i < mapLocations.length; i++) {
      if (!slugs.includes(mapLocations[i].id)) {
        inactiveMapLocationsIds.push(mapLocations[i].id)
      }
    }

    if (activeMapId === firstLocked.current) firstLocked.current = true

    locations.forEach(function (item) {
      const loc = item
      const slug = loc.slug
      let unlocked = loc.unlocked
      const watched = loc.watched

      if (!unlocked && firstLocked.current === true) {
        // the first locked chapter should always be unlocked
        firstLocked.current = activeMapId // memorise the active location(with pointer arrow)
        unlocked = true
      }

      if (unlocked) {
        const elementNormal = queryHtmlElement(`#${slug} .normal`)
        const elementDisabled = queryHtmlElement(`#${slug} .disabled`)
        const elementName = queryHtmlElement(`#${slug} .name`)
        if (elementNormal) elementNormal.style.display = 'unset'
        if (elementDisabled) elementDisabled.style.display = 'none'
        if (elementName) elementName.style.display = 'unset'

        if (!watched) {
          const elementActive = queryHtmlElement(`#${slug} .active`)
          if (elementActive) {
            elementActive.style.display = 'unset'
            elementActive.setAttribute('class', 'active bounce')
          }
        }
      } else {
        const elementHover = queryHtmlElement(`#${slug} .hover`)
        const elementNormal = queryHtmlElement(`#${slug} .normal`)
        const elementDisabled = queryHtmlElement(`#${slug} .disabled`)
        const elementName = queryHtmlElement(`#${slug} .name`)

        if (elementHover) elementHover.setAttribute('class', 'force-hide')
        if (elementNormal) elementNormal.style.display = 'none'
        if (elementDisabled) elementDisabled.style.display = 'unset'
        if (elementName) elementName.style.display = 'none'
      }

      const selector = unlocked ? `#${slug}-normal` : `#${slug}-disabled`
      let domElement = queryHtmlElement(selector)

      if (domElement) {
        domElement.style.display = 'unset'
        if (unlocked || (!watched && firstLocked.current === activeMapId)) {
          domElement = queryHtmlElement(`#${slug}-hover`)
          if (domElement) {
            domElement.addEventListener('click', () => {
              handleClick(loc)
            })
          }
        }
      }
    })
  }, [handleClick, map, activeMapId])

  const mapTitle = map?.episode?.title ?? ''

  useEffect(() => {
    if (!level) return
    if (level === 3) return
    history.replace('/')
  }, [level, history])

  if (!level || level !== 3 || profileType !== 'child') {
    return null
  }

  return (
    <>
      <Helmet>
        <title>عالم زيدو - الصفحة الرئيسية من المستوى 3</title>
      </Helmet>
      {!map ? <AppProgressBar /> : null}
      <AppBar
        backButtonLocation="/kids-home"
        fixed
        showBackButton={true}
        showLogoutButton
        showProfileButton
        title={mapTitle ?? ''}
        titleCenter
      />
      <AppContent className="px-0 sm:px-10 lg:px-0 bg-cover bg-home-page pages_level3_home_appcontent_fn">
        <ContentTitle
          className="pages_level3_home_contenttitle_fn"
          title={mapTitle || ''}
        />
        {maps && map && level && typeof activeMapId === 'number' ? (
          <MapLv3
            activeMap={map}
            hasNext={
              pendingUnit === false ? activeMapId + 1 < maps.length : null
            }
            hasPrev={pendingUnit === false ? activeMapId > 0 : null}
            isLoaded={svgLoaded[activeMapId]}
            onClickNext={showNextMap}
            onClickPrev={showPrevMap}
            onSVGLoad={onSVGLoad}
            showButton={map.allWatched}
          />
        ) : null}
      </AppContent>
    </>
  )
}

export default MapsHomeLevel3

const NavigateMapContainer: FC<{
  position: 'left' | 'right'
  children: React.ReactNode | string
}> = (props) => {
  return (
    <div
      className={`pages_level3_home_map_navigate_props_child min-w-[48px] sm:px-4 flex items-center justify-center absolute sm:static top-0 ${
        props.position === 'left' ? 'left-0' : 'right-0'
      }`}
    >
      {props.children}
    </div>
  )
}

const NavigateMapButton: FC<{
  onClick: () => void
  icon: ReactElement
}> = (props) => {
  return (
    <button
      className="text-[#f8ca00] pages_level3_home_map_navigate_button text-2xl sm:text-6xl svg-icon-shadow"
      onClick={props.onClick}
      type="button"
    >
      {props.icon}
    </button>
  )
}

const MapLv3: FC<{
  activeMap: IMap
  isLoaded: boolean
  showButton: boolean
  onClickNext: () => void
  onClickPrev: () => void
  onSVGLoad: () => void
  hasPrev: boolean
  hasNext: boolean
}> = (props) => {
  const slug = props.activeMap.episode?.slug

  return (
    <div className="pt-8 pb-16 pages_level3_home_mapv3_container flex w-full items-stretch justify-between relative">
      <NavigateMapContainer position="right">
        {props.hasPrev && props.isLoaded ? (
          <NavigateMapButton
            icon={<FontAwesomeIcon icon={faChevronRight} />}
            onClick={props.onClickPrev}
          />
        ) : null}
      </NavigateMapContainer>
      <div className="w-full relative overflow-visible max-w-6xl pages_level3_home_mapv3_link_container">
        {props.showButton && props.isLoaded ? (
          <Link
            className="shadow-activity-button pages_level3_home_link_activities bg-activity-button absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-[120%] sm:-translate-y-1/2 border-none rounded-3xl text-white font-bold text-shadow text-sm sm:text-2xl px-4 sm:px-6 py-1 sm:py-2 whitespace-nowrap"
            to={`/episode/${slug}/activities`}
          >
            {LV3_ACTIVITIES_BUTTON}
          </Link>
        ) : null}

        <Isvg
          className="pages_level3_home_svg_progressbar"
          height="100%"
          key={props.activeMap.url}
          loader={<AppProgressBar />}
          onLoad={props.onSVGLoad}
          src={props.activeMap.url}
          uniquifyIDs={false}
          width="100%"
        />
      </div>
      <NavigateMapContainer position="left">
        {props.hasNext && props.isLoaded ? (
          <NavigateMapButton
            icon={
              <FontAwesomeIcon
                className="pages_level3_home_mapv3_icon"
                icon={faChevronLeft}
              />
            }
            onClick={props.onClickNext}
          />
        ) : null}
      </NavigateMapContainer>
    </div>
  )
}

function queryHtmlElement(selector: string): HTMLElement | null {
  return document.querySelector(selector)
}
