import { useCallback, useMemo } from 'react'
import useSwr from 'swr'
import { IResponseCompletedepisodes } from '@src/types/api.interface'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { FetchError } from '@utils/FetchError'

export function useCompletedEpisodes(): {
  completedEpisodes?: IResponseCompletedepisodes
  error?: FetchError
  isLoadingCompletedEpisodes: boolean
  mutateAndIsEpisodeCompleted: (episodeId?: string) => Promise<boolean>
} {
  const { kidProfile: profile } = useProfile()

  const url = profile?._id ? `/kids/${profile._id}/completedepisodes` : null

  const { data, error, mutate } = useSwr<
    IResponseCompletedepisodes,
    FetchError
  >(url)

  const mutateAndIsEpisodeCompleted = useCallback(
    async (episodeId?: string): Promise<boolean> => {
      try {
        if (!episodeId) return false
        const data = await mutate()
        return !!data && isEpisodeCompleted(data, episodeId)
      } catch (error) {
        return false
      }
    },
    [mutate]
  )

  return {
    completedEpisodes: data,
    error,
    isLoadingCompletedEpisodes: !error && !data,
    mutateAndIsEpisodeCompleted,
  }
}

export function useIsEpisodeCompleted(episodeId?: string): boolean {
  const { completedEpisodes } = useCompletedEpisodes()

  const isCompleted = useMemo(() => {
    if (!episodeId || !completedEpisodes) return false
    return isEpisodeCompleted(completedEpisodes, episodeId)
  }, [episodeId, completedEpisodes])

  return isCompleted
}

function isEpisodeCompleted(
  completedEpisodes: IResponseCompletedepisodes,
  episodeId: string
): boolean {
  return !!completedEpisodes && completedEpisodes?.includes(episodeId)
}
