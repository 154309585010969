import { Capacitor } from '@capacitor/core'
import { ContentTitle } from '@components/ContentTitle'
import Config from '@config/index'
import useIFrameFullscreenChange from '@hooks/utils/useIFrameIsFullscreen'
import FullScreenIcon from '@resources/images/v3/btn-fullscreen.svg'
import HouseIcon from '@resources/images/v3/house-icon.svg'
import SmileyIcon from '@resources/images/v3/smiley.svg'
import { IActivityNew, IChapterListItem } from '@src/types/api.interface'
import { closeFullscreen, requestFullScreen } from '@utils/fullscreen'
import { FC, useState } from 'react'
import { createPortal } from 'react-dom'
import SVG from 'react-inlinesvg'
import ContentActivity, { IFRAME_ID } from './components/ContentActivity'
import ContentVideo from './components/ContentVideo'
import { IContentModalProps } from './types'
import useContentModal from './useContentModal'

export const ContentModal: FC<IContentModalProps> = (props) => {
  return createPortal(<Content {...props} />, document.body)
}

const Content: FC<IContentModalProps> = (props) => {
  const { content, handleBgWhiteClick } = useContentModal(props)
  const [iframeFullScreen, setIframeFullScreen] = useState(false)
  const { isFullScreen } = useIFrameFullscreenChange()

  const isIOS = Capacitor.getPlatform() === 'ios'
  const exitfullscreenSvg = `${Config.publicURL}/images/exitfullscreen_button.svg`

  const getIcon = () => {
    if (isIOS) {
      return isFullScreen || iframeFullScreen
        ? exitfullscreenSvg
        : FullScreenIcon
    } else {
      return isFullScreen ? exitfullscreenSvg : FullScreenIcon
    }
  }

  return (
    <div
      className="component_content_model_container fixed w-full h-full z-[101] justify-center items-center justify-items-center top-0 bottom-0 block"
      onClick={props.onClose}
    >
      <div
        className={`component_content_model_child_container fixed w-full h-full ${
          props?.blurBackground ? 'backdrop-blur' : ''
        }`}
      />

      {props?.blurBackground ? (
        <div className="component_content_model_backdrop_container fixed bottom-0 w-full h-[60%] bg-episode bg-cover bg-no-repeat bg-center" />
      ) : null}

      <div className="component_content_model_content_title_container fixed top-0 w-full text-center text-white ">
        <ContentTitle
          className="text-3xl component_content_model_content_title"
          title={content.title}
        />
      </div>
      <div className="component_content_model_content_iframe_container fixed bottom-0 w-full h-full flex justify-center items-center">
        <div
          className={`component_content_model_content_iframe_child_container ${
            iframeFullScreen && isIOS
              ? 'h-full w-full absolute top-0 left-0 z-[100]'
              : 'relative bg-white w-[70%] h-[70%] p-2 rounded-[2rem] text-center'
          }`}
          onClick={handleBgWhiteClick}
        >
          {/* Smiley icon positioned at the top-left corner */}
          <div className="component_content_model_smily_icon_container absolute top-[-2.75rem] left-[-2.5rem]">
            <SVG
              className="rounded-full component_content_model_smily_icon"
              src={SmileyIcon}
              width="40px"
            />
          </div>

          {/* Home icon positioned at the bottom-right corner */}
          <div className="component_content_model_house_icon_container absolute bottom-[-3.5rem] right-[-4.5rem]">
            <SVG
              className="rounded-full component_content_house_icon"
              src={HouseIcon}
              width="80px"
            />
          </div>
          <div className="component_content_model_content_video_container border-4 border-blue-500 rounded-[2rem] overflow-hidden h-full flex justify-center items-center">
            {props.contentType === 'video' ? (
              <ContentVideo
                className="component_content_model_video_content_element"
                content={content as IChapterListItem}
                episode={{
                  slug: props?.epiSlug || content?._id || 'slug',
                  type: content?.category || 'game',
                }}
                index={props.index + 1}
                onCloseParent={props.onClose}
              />
            ) : (
              <ContentActivity
                className="component_content_content_activity_container"
                content={content as IActivityNew}
                episode={{
                  slug: props?.epiSlug || content?._id || 'slug',
                  type: content?.category || 'book',
                }}
                index={props.index + 1}
              />
            )}
          </div>
        </div>
      </div>
      {props.contentType === 'activity' ? (
        <div className="component_content_model_activity_button_container fixed w-48 bottom-4 left-4 z-[102] pointer-events-auto">
          <button
            aria-label="show full screen"
            className="component_content_model_activity_button"
            onClick={(e) => {
              e.stopPropagation()
              if (isFullScreen) {
                closeFullscreen()
              } else {
                requestFullScreen(IFRAME_ID)
              }
              setIframeFullScreen(!iframeFullScreen)
            }}
            type="button"
          >
            <SVG
              className="component_content_model_activity_button_icon h-8 w-h-8 lg:w-16 lg:h-16"
              src={getIcon()}
              width="100%"
            />
          </button>
        </div>
      ) : null}
    </div>
  )
}
