import React, { FC, useState } from 'react'

import { Tooltip } from 'flowbite-react'
import { Link } from 'react-router-dom'

import { Capacitor } from '@capacitor/core'
import { Keyboard } from '@capacitor/keyboard'
import Sidebar from '@components/Layout/MobileSidebar'
import NotificationPopover from '@components/Layout/NotificationPopover'
import SettingsPopover from '@components/Layout/SettingsPopover'
import Config from '@config/index'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'

import { ContentTitle } from '@components/ContentTitle'
import ExpandedNotification from './ExpandedNotification'
import Footer from './Footer'
import PopupVideoPlayer from '@components/PopupVideoplayer'
import NotificationWrapper from '@components/NotificationWrapper'

const bgBlueBubblesTop = `${Config.publicURL}/images/v2/bg-blue-cloud.png`
const bgBlueBubblesLeft = `${Config.publicURL}/images/v2/bg-blue-left.png`
const bgBlueBubblesRight = `${Config.publicURL}/images/v2/bg-blue-right.png`
const bgBlueBubblesBottom = `${Config.publicURL}/images/v2/bg-blue-bottom.png`
interface AppContentBaseProp {
  className?: string
  style?: React.CSSProperties
  id?: string
  bgDecoration?: boolean
  haveFooter?: boolean
  haveHeader?: boolean
  haveTitle?: string // this need have header to be true
  children: React.ReactNode | string
}

type AppContentProp = AppContentBaseProp &
  (
    | { haveHeader: true; headerTitle?: string }
    | { haveHeader?: false; headerTitle?: string }
  )

export const AppContent: FC<AppContentProp> = (props) => {
  const [sidebarIsVisible, setSidebarVisible] = useState(false)
  const [showFullScreenPopupNotification, setFullScreenPopupNotification] =
    useState(false)
  const isPortrait = Capacitor.getPlatform() === 'web'

  const [isVisibleHowItWorksPopup, setIsVisibleHowItWorksPopup] =
    useState(false)

  const { parentProfile, logout } = useProfile()

  const toggleHowItWorksPopup = () => {
    setIsVisibleHowItWorksPopup((isVisible) => !isVisible)
  }

  if (Capacitor.getPlatform() === 'ios') {
    const app = document.querySelector('.app-content')

    Keyboard.addListener('keyboardDidShow', () => {
      app.classList.add('keyboard-offset')
    })

    Keyboard.addListener('keyboardDidHide', () => {
      app.classList.remove('keyboard-offset')
    })
  }
  return (
    <div
      className={`component_layout_app_content_container app-content safe-area min-h-screen overflow-auto ${
        props.className ?? ''
      }`}
      data-testid="app-content"
      id={props.id ?? ''}
      style={props.style}
    >
      {props?.bgDecoration ? (
        <div className="fixed component_layout_app_content_bg_decor top-0 left-0 h-full w-full -z-10 bg-kids">
          <img
            alt=""
            className="absolute top-20 component_layout_app_content_bg_decor_img_left lg:top-40 left-0 -z-10"
            src={bgBlueBubblesLeft}
          />
          <img
            alt=""
            className="absolute top-0 right-0 z-0 component_layout_app_content_bg_decor_img_right"
            src={bgBlueBubblesRight}
          />
          <div className="absolute bottom-0 w-full z-0 component_layout_app_content_bg_decor_img_bottom_container">
            <img
              alt=""
              className="block mx-auto w-[100px] component_layout_app_content_bg_decor_img_bubbles_bottom"
              src={bgBlueBubblesBottom}
            />
          </div>
          <img
            alt=""
            className="absolute top-0 right-20 component_layout_app_content_bg_decor_img_bubbles_top"
            src={bgBlueBubblesTop}
            style={{ zIndex: '2' }}
          />
        </div>
      ) : null}

      {props?.haveFooter || props?.haveHeader ? (
        <div className="flex flex-col min-h-screen component_layout_app_content_footer_header_container">
          {props?.haveHeader && parentProfile ? (
            <>
              <Sidebar
                backdrop={() => setSidebarVisible(false)}
                onClickHowItWorks={() => {
                  setSidebarVisible(false)
                  toggleHowItWorksPopup()
                }}
                visible={sidebarIsVisible}
              />
              <div className="flex flex-col gap-y-2 component_layout_app_content_footer_header_child">
                <div className="flex flex-row justify-between mt-6 mx-10 items-center lg:px-4 overflow-hidden component_layout_app_content_footer_header_child_child">
                  <div className="flex flex-row lg:gap-x-1 h-[50px] component_layout_app_content_footer_header_tooltop_container">
                    <Tooltip
                      arrow={isPortrait}
                      className="component_layout_app_content_footer_header_tooltop_element"
                      content={
                        isPortrait && !isVisibleHowItWorksPopup ? (
                          <SettingsPopover
                            onClickHowItWorks={toggleHowItWorksPopup}
                          />
                        ) : null
                      }
                      trigger="click"
                      // eslint-disable-next-line
                      style="light"
                    >
                      <div
                        className="component_layout_app_content_footer_header_sidebar_toggle cursor-pointer bg-list-menu-v3 v3-icon v3-icon-sky bg-cover bg-no-repeat "
                        onClick={() => {
                          !isPortrait && setSidebarVisible(true)
                        }}
                      />
                    </Tooltip>
                    <Link
                      className="component_layout_app_content_footer_header_list_kid_link cursor-pointer bg-list-kids-v3 v3-icon v3-icon-blue bg-cover bg-no-repeat "
                      to="/list-kid-home"
                    />
                    <Tooltip
                      className="component_layout_app_content_footer_header_notification_wrap_portrati"
                      content={
                        isPortrait ? (
                          <NotificationWrapper>
                            {(wrapPrpp) => (
                              <NotificationPopover {...wrapPrpp} />
                            )}
                          </NotificationWrapper>
                        ) : null
                      }
                      // eslint-disable-next-line
                      style="light"
                      trigger="click"
                    >
                      <div
                        className="component_layout_app_content_footer_header_notification_portrait_toggle cursor-pointer bg-notification-button-v3 v3-icon v3-icon-purple bg-cover bg-no-repeat "
                        onClick={
                          !isPortrait
                            ? () => setFullScreenPopupNotification(true)
                            : null
                        }
                      >
                        <div className="component_layout_app_content_footer_header_notification_portrait_toogle_bg block rounded-full bg-red-600 w-[14px] h-[14px] border-[1.5px] border-white absolute top-0 right-0 z-[99]" />
                      </div>
                    </Tooltip>
                  </div>

                  {props?.headerTitle ? (
                    <ContentTitle
                      className="component_layout_app_content_header_title_content_title_element"
                      noShadow
                      notBold
                      title={props.headerTitle}
                    />
                  ) : null}

                  <div className="flex gap-x-1 h-[50px] component_layout_app_content_footer_header_header_title_container">
                    <Link
                      className="component_layout_app_content_footer_header_add_kid_link cursor-pointer bg-parent-add-kid v3-icon v3-icon-green bg-cover bg-no-repeat"
                      to="/add-kid"
                    />
                    <Link
                      className={`component_layout_app_content_footer_header_handbook_link cursor-pointer bg-parent-course-button-v3 v3-icon v3-icon-orange bg-cover bg-no-repeat  ${
                        parentProfile?.kids.length === 0 && 'opacity-60'
                      }`}
                      to={`/handbook-home/${parentProfile?.kids[0]?._id}`}
                    />

                    <div
                      className="component_layout_app_content_footer_header_logout_container cursor-pointer bg-parent-gateway-v3 v3-icon v3-icon-red bg-cover bg-no-repeat"
                      onClick={logout}
                    />
                  </div>
                </div>
                {props?.headerTitle ? (
                  <hr className="component_layout_app_content_footer_header_title_hr w-full border border-white opacity-20" />
                ) : null}
              </div>
              {showFullScreenPopupNotification ? (
                <div className="component_layout_app_content_full_screen_popup_container absolute top-0 left-0 w-full h-full bg-v3 z-[20000] block">
                  <NotificationWrapper>
                    {(extProp) => (
                      <ExpandedNotification
                        {...extProp}
                        action={() => setFullScreenPopupNotification(false)}
                      />
                    )}
                  </NotificationWrapper>
                </div>
              ) : null}
            </>
          ) : null}
          <div className="flex-1 component_layout_app_content_children_props_container">
            {props.children}
          </div>
          {props?.haveFooter && Capacitor.getPlatform() === 'web' ? (
            <div className="min-h-[33px] component_layout_app_content_footer_how_it_works_toggle_container">
              <Footer onClickHowItWorks={toggleHowItWorksPopup} />
            </div>
          ) : null}
        </div>
      ) : (
        <div className="component_layout_app_content_childred_props_container">
          {props.children}
        </div>
      )}
      {isVisibleHowItWorksPopup ? (
        <PopupVideoPlayer
          id="how-it-works-popup"
          isVisible={isVisibleHowItWorksPopup}
          onClose={toggleHowItWorksPopup}
          thumbnail="https://assets.zidoworld.com/prod/intro/intro-bg-1.png"
          url="https://files.zadapps.info/zidoworld.com/streams/intro-video/intro-video-1.mp4"
        />
      ) : null}
    </div>
  )
}
