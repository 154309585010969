// EN: This content
export const THIS_CONTENT_MESSAGE = 'هذا المحتوى'

// EN: Requires subscription
export const REQUIRES_SUBSCRIBE_MESSAGE = 'يحتاج إلى الاشتراك'

// EN: Requires registration
export const REQUIRES_REGISTRATION_MESSAGE = 'تحتاج للتسجيل لمشاهدة هذا المحتوى'

// EN: Subscribe now
export const SUBSCRIBE_NOW_MESSAGE = 'اشترك الآن'

// EN: Register now
export const REGISTER_NOW_MESSAGE = 'سجل الأن'

// EN: You haven't finished watching the videos yet
export const HAVENT_FINISHED_VIDEOS = 'الوقت المحدد للمحتوى لم يأت بعد'

// EN: You haven't watched the video yet
export const HAVENT_WATCHED_VIDEO = 'لم تنته من مشاهدة الفيديو بعد'

// EN: Selected episodes for you
export const SELECTED_EPISODES = 'حلقات مختارة لك'

// EN: You haven't finished the series yet
export const HAVENT_FINISHED_SERIES =
  'قم أولًا ، بإكمال الوحدات التعليمية من المقصد الأول'

export const UNLOCK_CONTENT_MESSAGE = `قم بمشاهدة الحلقة مرة أخرى واستمتع بالألعاب  والكتاب التفاعلي`

// EN: One more day until you can unlock the new unit... Watch the episode again and enjoy the games and the interactive book
export const UNLOCK_ONE_DAY = 'باقي يوم حتى تتمكن من فتح الوحدة الجديدة'

// EN: Two more days until you can unlock the new unit... Watch the episode again and enjoy the games and the interactive book
export const UNLOCK_TWO_DAYS = 'باقي يومان حتى تتمكن من فتح الوحدة الجديدة'

// EN: ${day} more days until you can unlock the new unit... Watch the episode again and enjoy the games and the interactive book
export const moreThanThreeDays = (day: number) =>
  `باقي ${day} أيام حتى تتمكن من فتح الوحدة الجديدة`

// EN: What is their hobbies?
export const WHAT_HOBBIES_MESSAGE = 'ما هي هوايات طفلك المفضلة؟'

// EN: How many hours does your child spend on the phone daily?
export const HOW_MANY_HOURS = 'كم ساعة يقضيها طفلك على الهاتف يوميا؟'

export const USAGE_POLICY_MESSAGE = 'سياسة الاستخدام'
export const COPYRIGHT_MESSAGE = 'جميع الحقوق محفوظة'
export const COPYRIGHT_SYMBOL = '©'

export const COMING_SOON_MESSAGE = 'ترقبوا قريبا المنهج الجديد'

export const RANKING_ORDER = [
  '0',
  'الأولى',
  'الثانية',
  'الثالثة',
  'الرابعة',
  'الخامسة',
  'السادسة',
  'السابعة',
  'الثامنة',
  'التاسعة',
  'العاشرة',
  'الحادية عشر',
  'الثانية عشر',
  'الثالثة عشر',
  'الرابعة عشر',
  'الخامسة عشر',
  'السادسة عشر',
  'السابعة عشر',
  'الثامنة عشر',
  'التاسعة عشر',
]

// Messages for Home / Intro Page
export const MESSAGE_LOGIN = 'دخول'
export const MESSAGE_REGISTER = 'إنشاء حساب'

// Messages for Forgot Password
export const LINK_TO_CHANGE_PASSWORD_HAS_BEEN_SENT = `لقد قمت بطلب تغيير كلمة السر `
export const LINK_TO_CHANGE_PASSWORD_HAS_BEEN_SENT_2 = `تم إرسال رابط تغيير كلمة المرور الى بريدك الإلكتروني`

// Messages for List Kid Home page
export const MESSAGE_KID_CONTROL = 'مرحباً بك في عالم زيدو'
export const MESSAGE_KID_CONTROL_SUBHEADLINE = 'اضغط على اسمك للدخول'
export const MESSAGE_PARENT_SECTION = 'دخول الآباء'
export const MESSAGE_VIEW_SUBSCRIPTION = 'الاشتراك'
export const WELCOME_KID_MESSAGE = 'مرحباً بك '
export const DESTINATION_MESSAGE_2 = 'في عالم زيدو'
export const QUESTION_TO_KID = 'ماذا سنفعل اليوم؟'
export const thisYear = new Date().getFullYear()
export const DESTINATION_MESSAGE = 'المقصد'
export const EDUGOAL_MESSAGE = 'الهدف التربوي'
export const MAQRAA_TITLE = 'مقرأة زيدو'
export const CURRICULUM_TITLE = 'منهج زيدو'
export const COMPETITION_TITLE = 'سباقات زيدو'
export const COURSE_LINK_TEXT = 'الدورات'
export const ARTICLES_TEXT = 'المقالات'
export const UNITES_LIST = [
  '',
  'المقصد الأول',
  'المقصد الثانى',
  'المقصد الثالث',
  'المقصد الرابع',
  'المقصد الخامس',
  'المقصد السادس',
  'المقصد السابع',
  'المقصد الثامن',
  'المقصد التاسع',
  'المقصد العاشر',
  'المقصد الحادى عشر',
  'المقصد الثانى عشر',
  'المقصد الثالث عشر',
  'المقصد الرابع عشر',
  'المقصد الخامس عشر',
  'المقصد السادس عشر',
  'المقصد السابع عشر',
  'المقصد الثامن عشر',
  'المقصد التاسع عشر',
  'المقصدالعشرون',
]

export const MESSAGE_UNCOMPLETED_EPISODE =
  'ينبغي مشاهدة فيديو وكتب وألعاب الوحدة السابقة أولا'

// EN: Child count exceeded the limit for the level
export const MESSAGE_CHILD_LIMIT = 'ثلاث أطفال في الفئة العمرية الواحدة'

// EN: We are preparing interesting educational content for your children.
export const MORE_CONTENT_COMING = 'نحن بصدد تحضير محتوى تعليمي شيق لأطفالكم.'

// EN: We will update you as soon as it becomes available!
export const WE_WILL_UPDATE = 'سنوافيكم بالجديد حال توفره!'

// EN: Zido World Team
export const ZIDO_WORLD_TEAM = 'فريق عالم زيدو'
