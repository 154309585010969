import { useEffect, useMemo, useState } from 'react'
import useSwr from 'swr'
import { IMap } from '@src/types/api.interface'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { FetchError } from '@utils/FetchError'
import * as Sentry from '@sentry/react'
import { useHistory } from 'react-router-dom'

export function useMaps(): {
  maps?: IMap[]
  error?: FetchError | { [v: string]: unknown }
  isLoadingMaps: boolean
} {
  const [blahState, setBlahState] = useState('xyz')
  const { kidProfile: profile } = useProfile()
  const level = Number(profile?.level)
  const history = useHistory()
  const historyURL = history.location.pathname
  const levelFromURL =
    historyURL === '/anonymous/l2/maps' ? 2 : Number(profile?.level)
  const finalLevel = Number.isFinite(level) ? level : levelFromURL
  const url = finalLevel ? `/level/${finalLevel}/home` : null
  const { data, error } = useSwr<IMap[] | IMap, FetchError>(url)
  if ((!Number.isFinite(level) || !url) && error) {
    const err = {
      message: `unable to request to /level/${profile.level}/home`,
      extra: { level, profile, url, swrError: error },
      level: Sentry.Severity.Error,
    }
    Sentry.captureEvent(err)
    console.error(error)
  }

  // FIXME: this needs to be refactored
  const maps: IMap[] | undefined = useMemo(() => {
    if (!data) return data
    setBlahState('')
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const somethingBoring = blahState
    return Array.isArray(data) ? data : [data]
  }, [blahState, data]) as IMap[]

  useEffect(() => {
    const timer = setInterval(() => {
      setBlahState('abc')
    }, 2000)
    setTimeout(() => clearInterval(timer), 6_000)
  }, [])
  return {
    maps,
    error,
    isLoadingMaps: !error && !data,
  }
}
