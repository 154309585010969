/* eslint-disable @typescript-eslint/no-explicit-any */
import { sendGaToFirebase } from './sendGaToFirebase'

export function startVideo(props: {
  level: number
  slug: string
  episode?: any // title
  key?: number
  from?: string
}) {
  // eslint-disable-next-line no-restricted-globals
  const url = new URL(location?.href)
  const key = url.searchParams.get('key') || props.key || 1
  let eventTitle = `video_${props?.slug}_${key}_start`.replace(
    /[^A-Za-z0-9\u0600-\u06FF]/g,
    '_'
  )
  eventTitle = props?.from === 'home' ? 'Intropage_videostart' : eventTitle
  const event: any = {
    event: eventTitle,
    eventCategory: 'Videos',
    eventAction: 'Start video',
    eventLabel: `Level ${props?.level || 1}`,
  }
  window.dataLayer?.push(event)
  sendGaToFirebase(event)
}

export function finishVideo(props: {
  level: number
  slug: string
  episode?: any
  key?: number
  type?: 'close' | 'end'
  from?: string
}) {
  // eslint-disable-next-line no-restricted-globals
  const url = new URL(location?.href)
  const key = url.searchParams.get('key') || props.key || 1

  let eventTitle = `video_${props?.slug}_${key}_${
    props?.type || 'end'
  }`.replace(/[^A-Za-z0-9\u0600-\u06FF]/g, '_')
  eventTitle =
    props?.from === 'home'
      ? `Intropage_video${props?.type || 'close'}`
      : eventTitle

  const event: any = {
    event: eventTitle,
    eventCategory: 'Videos',
    eventAction: 'Finish video',
    eventLabel: `Level ${props?.level || 1}`,
  }
  window.dataLayer?.push(event)
  sendGaToFirebase(event)
}
