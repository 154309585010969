import { FC, ReactNode } from 'react'

const SectionBackground: FC<{
  children?: ReactNode
}> = (props) => {
  return (
    <div className="bg-[#FFFFFF] bg-opacity-50 p-2 rounded-[40px] component_sections_background_container">
      <div className="bg-[#EBF6F8] rounded-[40px] border-8 border-[#FFF] h-full component_sections_background_child_container">
        {props.children}
      </div>
    </div>
  )
}

export default SectionBackground
