const GreenTickIcon = () => {
  return (
    <svg
      className="w-[26px] xl:w-[34px] green_tick_icon-svg"
      fill="none"
      height="35"
      viewBox="0 0 34 35"
      width="34"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.2512 19.0769C33.2512 27.5127 25.8441 34.3505 16.7125 34.3505C7.58094 34.3505 0.173828 27.5127 0.173828 19.0769C0.173828 10.6411 7.57761 3.80322 16.7125 3.80322C25.8474 3.80322 33.2512 10.6411 33.2512 19.0769Z"
        fill="black"
        opacity="0.2"
      />
      <path
        d="M33.2512 15.6926C33.2512 24.1284 25.8441 30.9662 16.7125 30.9662C7.58094 30.9662 0.173828 24.1284 0.173828 15.6926C0.173828 7.2568 7.57761 0.418945 16.7125 0.418945C25.8474 0.418945 33.2512 7.2568 33.2512 15.6926Z"
        fill="white"
      />
      <path
        d="M16.7123 26.2676C9.64476 26.2676 3.89551 21.0876 3.89551 14.7158C3.89551 8.34405 9.64476 3.16406 16.7123 3.16406C23.7799 3.16406 29.5291 8.34405 29.5291 14.7158C29.5291 21.0876 23.7832 26.2676 16.7123 26.2676Z"
        fill="#B3D65B"
      />
      <path
        d="M10.6201 14.7157L14.6819 18.7775L22.8048 10.6538"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
      />
    </svg>
  )
}

export default GreenTickIcon
