import { IChallengeNew } from '@src/types/api.interface'
import {
  boyAvatar,
  girlAvatar,
  starCircleFor2ndPos,
  starCircleFor3rdPos,
  pointIcon,
} from '../TopCompetitor'

export const LeaderBoardComponent: React.FC<{
  kid: IChallengeNew
  index: number
  isHighlighted: boolean
}> = ({ kid, isHighlighted }) => {
  return (
    <div
      className={`leader_board_component-container-${
        kid.id
      } flex-1 gap-x-1 flex flex-col items-center ${
        isHighlighted ? 'bg-v3-green rounded-xl p-4' : ''
      }`}
    >
      <div
        className={`leader_board_component-wrapper-${kid.id} bg-v3-orange-200 relative w-[52px] h-[56px] xl:w-[72px] xl:h-[78px] rounded-full flex items-center justify-center`}
      >
        <div
          className={`leader_board_component-item-${kid.id} bg-v3-brown-100 absolute -top-1 w-[52px] h-[56px] xl:w-[72px] xl:h-[78px] rounded-full flex items-center justify-center`}
        >
          <img
            alt="boy avatar"
            className={`leader_board_component-img-${kid.id} h-11 w-11 xl:w-[62px] xl:h-[62px]`}
            src={kid?.gender === 'male' ? boyAvatar : girlAvatar}
          />
        </div>
        <img
          alt="Star with circle"
          className={`leader_board_component-star-${kid.id} h-5 w-5 xl:w-[26px] xl:h-[26px] absolute -bottom-[6px] left-0`}
          src={kid?.rank === 2 ? starCircleFor2ndPos : starCircleFor3rdPos}
        />
        <span
          className={`leader_board_component-kid-rank-${kid.id} bg-v3-sky-blue border border-white h-6 w-6 xl:w-[30px] xl:h-[30px] text-white text-5 absolute -top-[12px] -right-2.5 drop-shadow-[0px_4px_4px_rgba(0,0,0,0.25)] rounded-full flex items-center justify-center`}
        >
          {kid?.rank}
        </span>
      </div>
      <p
        className={`leader_board_component-kid-nickname-${kid.id} text-white max-h-5 text-center truncate overflow-hidden text-[15px]`}
      >
        {kid?.nickname}
      </p>
      <div
        className={`leader_board_component-score-wrapper-${kid.id} flex items-center justify-around w-11 xl:w-[62px] gap-1.5 `}
      >
        <p
          className={`leader_board_component-kid-score-${kid.id} text-white text-xl`}
        >
          {kid?.score}
        </p>
        <img
          alt="point icon"
          className={`leader_board_component-kid-point-${kid.id} w-[15px] h-[16px]`}
          src={pointIcon}
        />
      </div>
    </div>
  )
}
