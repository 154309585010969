import { LocalNotifications } from '@capacitor/local-notifications'
import { LocalNotificationType } from '@src/types/api.interface'

const HelperLocalNotification = {
  getLocalNotificationStatus: function (
    notiName: LocalNotificationType
  ): boolean {
    try {
      const status = localStorage.getItem(notiName)

      if (!status) return null
      return status === 'true'
    } catch (error) {
      console.error(error)
      return false
    }
  },

  setLocalNotificationStatus: function (
    notiName: LocalNotificationType,
    value: boolean
  ): void {
    localStorage.setItem(notiName, value.toString())
  },

  addLocalNotificationByOn: async function (
    id: number,
    title: string,
    body: string,
    hour: number,
    minute: number
  ) {
    await LocalNotifications.schedule({
      notifications: [
        {
          title,
          body,
          id,
          schedule: {
            on: {
              hour,
              minute,
            },

            allowWhileIdle: true,
          },
        },
      ],
    })
  },

  addLocalNotificationByAt: async function (
    id: number,
    title: string,
    body: string,
    day: number
  ) {
    await LocalNotifications.schedule({
      notifications: [
        {
          title,
          body,
          id,
          schedule: {
            at: new Date(Date.now() + 1000 * 60 * 60 * 24 * day), // in an X day(s)
            allowWhileIdle: true,
          },
        },
      ],
    })
  },
}

export default HelperLocalNotification
