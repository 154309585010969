import { TimeDetail } from '@src/types/api.interface'
import ApexCharts from 'react-apexcharts'
import { TEXT } from './data'
import { getChartOptions } from './utils'

interface PieChartProps {
  data: TimeDetail
}

const getTime = (hours: number | null, minutes: number | null) =>
  `${hours || 0}:${minutes || 0} ${hours > 0 ? 'ساعة' : 'دقيقة'}`

const PieChart = ({
  data: {
    hours = 0,
    minutes = 0,
    video: { hours: videoHours = 0, minutes: videoMinutes = 0 },
    activity: { hours: activityHours = 0, minutes: activityMinutes = 0 },
  },
}: PieChartProps) => {
  const totalWatchingTime = hours * 60 + minutes
  const activitiesWatchingTime = activityHours * 60 + activityMinutes
  const videosWatchingTime = videoHours * 60 + videoMinutes

  const activitiesPercent =
    totalWatchingTime === 0
      ? 50
      : Math.round((activitiesWatchingTime / totalWatchingTime) * 100)

  const videosPercent =
    totalWatchingTime === 0
      ? 50
      : Math.round((videosWatchingTime / totalWatchingTime) * 100)

  return (
    <div className="pie_chart_component-container flex flex-col">
      <div className="pie_chart_component-wrapper flex flex-col">
        <div className="pie_chart_component-title flex text-lg md:text-xl text-v3-blue-800 justify-between">
          <h4 className="pie_chart_component-heading text-lg md:text-xl text-v3-blue-800 inline-block">
            {TEXT.TOTAL_WATCHING_TIME}
          </h4>
          <p className="pie_chart_component-text text-xl md:text-2xl">
            {getTime(hours, minutes)}
          </p>
        </div>
      </div>

      <div
        className="pie_chart_component-apex-charts-wrapper flex justify-center items-center"
        id="pie-chart"
      >
        <ApexCharts
          height={140}
          options={getChartOptions()}
          series={[activitiesPercent, videosPercent]}
          type="pie"
        />
      </div>

      <div className="pie_chart_component-watching-time-container flex flex-col">
        <div className="pie_chart_component-watching-time-wrapper flex items-center text-lg md:text-xl text-v3-blue-800 justify-between">
          <span className="pie_chart_component-watching-time-main">
            <span className="pie_chart_component-watching-time-span h-2 w-2 mt-1 rounded-full bg-[#70D8CC] inline-block" />
            <h4 className="pie_chart_component-videos-watching-time-heading mr-2 inline-block">
              {TEXT.VIDEOS_WATCHING_TIME}
            </h4>
          </span>
          <p className="pie_chart_component-videos-watching-time-tex text-xl md:text-2xl">
            {getTime(videoHours, videoMinutes)}
          </p>
        </div>

        <div className="pie_chart_component-activities-watching-time-wrapper flex items-center text-lg md:text-xl text-v3-blue-800 justify-between">
          <span className="pie_chart_component-activities-watching-time-main">
            <span className="pie_chart_component-activities-watching-time-span h-2 w-2 mt-1 rounded-full bg-[#57599C] inline-block" />
            <h4 className="pie_chart_component-activities-watching-time-heading mr-2 inline-block">
              {TEXT.ACTIVITIES_WATCHING_TIME}
            </h4>
          </span>
          <p className="pie_chart_component-activities-watching-time-text text-xl md:text-2xl">
            {getTime(activityHours, activityMinutes)}
          </p>
        </div>
      </div>
    </div>
  )
}

export default PieChart
