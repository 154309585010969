import React, { FC, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { AppContent } from '@components/Layout'
import Config from '@config/index'
import { useAnonymous } from '@contexts/AnonymousContext/AnonymousContext'
import { Link, useHistory, useParams } from 'react-router-dom'
import 'animate.css'
import { Data, usePaths } from '@hooks/use-paths'

import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const logo = `${Config.publicURL}/images/logo.svg`

const MESSAGE_LOGIN = 'دخول'
const MESSAGE_REGISTER = 'مستخدم جديد'
const MESSAGE_AGE = 'اختر الفئة العمرية'
const MESSAGE_WELCOME = 'اهلا بك'

const DeeplinkSelectAge: FC = () => {
  // TODO: find out what can be put instead of type any for element that are being rendered
  const logoContainer = useRef(null)
  const { loginAnonymous } = useAnonymous()
  const { dataBody } = usePaths()
  const { route } = useParams<{ route: string }>()
  const history = useHistory()

  const handlelevel = async (data: Data) => {
    const { level } = data

    if (level) {
      loginAnonymous()
        .then((token) => {
          if (token !== 'error') {
            if (route === 'curriculum') {
              if (Number(level) === 1 || Number(level) === 3) {
                history.push(`/anonymous-episode/${level}`)
              } else if (Number(level) === 2) {
                history.push('/anonymous/l2/maps')
              }
            } else {
              history.replace('/login')
            }
          }
        })
        .catch((e) => {
          toast.error(e.message, { toastId: 'error_login_anonymous' })
        })
    }
  }

  return (
    <>
      <Helmet>
        <title>عالم زيدو - الصفحة الرئيسية</title>
      </Helmet>
      <AppContent className="bg-no-repeat 1 bg-cover bg-blue pages_anonymous_ageselect_appcontent">
        <div className="flex justify-end mr-10 mt-5 pages_anonymous_ageselect_link_container">
          <Link className="pages_anonymous_ageselect_login_link" to="/login">
            <button
              className="bg-login-buttons bg-cover bg-no-repeat text-white  landscape:sm:w-[96px] landscape:sm:h-[55px]"
              type="button"
            >
              {MESSAGE_LOGIN}
            </button>
          </Link>
          <Link
            className="ml-3 pages_anonymous_ageselect_register_link"
            to="/register"
          >
            <button
              className="bg-register-buttons bg-cover bg-no-repeat  w-[135px] h-[55px] text-white"
              type="button"
            >
              {MESSAGE_REGISTER}
            </button>
          </Link>
        </div>
        <div className="mx-auto pages_anonymous_ageselect_div_container container h-screen overflow-hidden flex flex-col items-center landscape:sm:w-full landscape:w-4/5 landscape:sm:h-[75vh]">
          <div
            className="w-[40%] pages_anonymous_ageselect_logo_container h-[25%] flex items-center justify-center animate__backInDown absolute top-6 "
            ref={logoContainer}
          >
            <Logo />
          </div>

          <div className="mx-auto pages_anonymous_ageselect_typography flex items-center justify-center text-center bg-level bg-cover bg-no-repeat  sm:mt-16 sm:w-[640px] xl:w-[60%]  xl:mt-[150px]  ">
            <div>
              <div className="text-[25px] text-[#4a7adb] pages_anonymous_ageselect_welcome_text">
                {MESSAGE_WELCOME}
              </div>
              <div className="text-[20px] mb-8 pages_anonymous_ageselect_age_text">
                {MESSAGE_AGE}
              </div>
              <div className="flex flex-col-2 mt-2 sm:mt-12 landscape:mt-2 pages_anonymous_ageselect_age_button_container">
                {dataBody?.map((path, index) =>
                  Number(path?.level) === 3 ? null : (
                    <div key={index}>
                      <button
                        className={`w-[120px] pages_anonymous_ageselect_age_button pages_anonymous_ageselect_age_button_${index} age_button_path_${path.minAge}_${path.maxAge} h-[50px] text-[15px] sm:text-[25px] sm:w-[215px] sm:h-[70px] ml-3 bg-level-buttons shadow-[0px 5px #CA9B3B]  border-[5px] landscape:border-y-[3px] landscape:2xl:border-y-[5px]  border-t-[solid] border-t-[#D1BC78] border-b-[#D1BC78] border-l-0 border-r-0 rounded-[50px]  landscape:text-[12px] landscape:w-[90px] landscape:h-[40px] landscape:2xl:text-[25px] landscape:2xl:w-[190px] landscape:2xl:h-[80px]`}
                        onClick={async () => await handlelevel(path)}
                        type="button"
                      >
                        {path.maxAge}-{path.minAge} سنوات
                      </button>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </AppContent>
    </>
  )
}

export default DeeplinkSelectAge

const Logo: FC = () => {
  return (
    <img
      alt="عالم زيدو"
      className="w-full landscape:w-[40%] max-h-full transition-all pages_anonymous_ageselect_logo_img"
      src={logo}
    />
  )
}
