import React, { FC, useCallback, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import { ActivityContent } from '@components/ActivityDetails'
import { AppBar, AppContent, FullScreenButton } from '@components/Layout'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import { useActivity } from '@hooks/data/fetchers/use-activity'
import { logEvent } from '@utils/firebase-analytics'
import { Capacitor } from '@capacitor/core'
import { requestFullScreen } from '@utils/fullscreen'
import HelperAPI from '@utils/helpers/helper.api'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import useQuery from '@hooks/use-query'
import { ITrackingSubmit } from '@src/types/api.interface'
import { toast } from 'react-toastify'

const IFRAME_ID = 'zw-game-iframe'

const Activity: FC = () => {
  const params = useParams<{ id: string }>()
  const episodeId = useQuery().get('episode') ?? undefined
  const { activity, isLoadingActivity } = useActivity({ id: params.id })
  const { kidProfile } = useProfile()

  const openedActivity = useCallback(async () => {
    if (activity?.category && kidProfile && episodeId) {
      await HelperAPI.ApiRequest(
        `/level/${kidProfile.level}/episodes/activities/view`,
        {
          method: 'POST',
          body: JSON.stringify({
            kid: kidProfile._id,
            activity: activity._id,
            datetime: new Date(),
            episode: episodeId,
          }),
        }
      )
    }
  }, [activity, kidProfile, episodeId])

  useEffect(() => {
    openedActivity()
  }, [openedActivity])

  useEffect(() => {
    if (activity?.category) {
      const eventName = `open_episode_${activity.category}`
      logEvent(eventName)
    }

    return () => {
      if (activity?.category) {
        const eventName = `close_episode_${activity.category}`
        logEvent(eventName)
      }
    }
  }, [activity])

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return

    requestFullScreen(IFRAME_ID)
  }, [activity])

  const onTimeTrackSubmit = useCallback(
    async (data: Partial<ITrackingSubmit>) => {
      try {
        if (!kidProfile._id) return
        const url = `/trackings/track`
        await HelperAPI.ApiRequest(url, {
          method: 'POST',
          body: JSON.stringify({
            ...data,
            reference: 'activities',
            kid: kidProfile._id,
          }),
        })
      } catch (e) {
        toast.error('Something went wrong')
      }
    },
    [kidProfile]
  )

  useEffect(() => {
    const start = Date.now()
    return () => {
      const end = Date.now()
      if (episodeId && activity?._id) {
        onTimeTrackSubmit({
          timeTrack: end - start,
          referenceType: activity._id,
          episode: episodeId,
          types: activity.category.toUpperCase(),
        })
      }
    }
  }, [activity, episodeId, onTimeTrackSubmit])

  return (
    <>
      <Helmet>
        <title className="activity_page_title">عالم زيدو - نشاط</title>
      </Helmet>
      {isLoadingActivity ? <AppProgressBar /> : null}
      <AppBar
        backButtonLocation="/activities"
        closeIconReplaceBackButton
        downloadFileHref={activity?.file}
        fixed
        fullScreenNodeId={IFRAME_ID}
        hideToggleButton
        showBackButton
        showHomeLink
        title={activity?.title ?? ''}
      />

      <AppContent bgDecoration className="activity_page_app_content h-screen">
        <div className="activity_page_container flex flex-col items-center justify-center lg:pt-10 max-w-[1024px] mx-auto w-full">
          <div className="activity_page_wrapper h-[500px] max-h-screen p-6 lg:p-0 w-[80%] lg:w-full">
            {activity ? (
              <div className="activity_page_activity_content_wrapper relative h-full w-full">
                <ActivityContent
                  activity={activity}
                  iframeContainerId={IFRAME_ID}
                />
              </div>
            ) : null}
          </div>
          <div className="activity_page_full_screen_button_wrapper max-w-xl pointer-events-auto h-[32px] w-[32px] mr-2 absolute bottom-5 left-5">
            <FullScreenButton nodeId={IFRAME_ID} />
          </div>
          {/* <div className="pb-6">
            <ContentTitle
              textColor="text-[#4a4a4a]"
              noShadow
              title={activity?.title ?? ''}
            />
          </div>
          {!!activity?.description && (
            <div className="pb-6">
              <ActivityDescription description={activity.description} />
            </div>
          )} */}
        </div>
      </AppContent>
    </>
  )
}

export default Activity

// const ActivityDescription: FC<{ description: string }> = (props) => {
//   return (
//     <div className="text-[#4a4a4a] text-center text-2xl">
//       {props.description}
//     </div>
//   )
// }
