import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { AppBar, AppContent } from '@components/Layout'
import Popup from '@components/Popup'
import { useHistory } from 'react-router-dom'

const MESSAGE_SUBSCRIBED_FAILED = 'لم يت الاشتراك'
const MESSAGE_OK = 'موافق'

const PaymentFailed: FC = () => {
  const history = useHistory()

  return (
    <>
      <Helmet>
        <title className="page_payment_failed_page_title">
          عالم زيدو - {MESSAGE_SUBSCRIBED_FAILED}
        </title>
      </Helmet>
      <AppBar fixed hideToggleButton showBackButton showHomeLink title="" />

      <AppContent className="bg-no-repeat bg-cover bg-center bg-blue">
        <Popup
          acceptLink="/"
          buttonText={MESSAGE_OK}
          onClose={() => {
            history.replace('/')
          }}
          title={MESSAGE_SUBSCRIBED_FAILED}
          visible={true}
        />
      </AppContent>
    </>
  )
}

export default PaymentFailed
