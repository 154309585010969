import useSwr from 'swr'
import { IChapterDetail } from '@src/types/api.interface'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { FetchError } from '@utils/FetchError'

export function useChapter(props: { epiSlug: string; chapSlug: string }): {
  chapter?: IChapterDetail
  error?: FetchError
  isLoadingChapter: boolean
} {
  const { kidProfile: profile } = useProfile()

  const url = profile
    ? `/level/${profile.level}/episodes/${props.epiSlug}/chapters/${props.chapSlug}`
    : null

  const { data, error } = useSwr<IChapterDetail, FetchError>(url)

  return {
    chapter: data,
    error,
    isLoadingChapter: !error && !data,
  }
}
