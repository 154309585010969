import { FC } from 'react'
import { Switch } from 'react-router-dom'

import Activities from '@pages/Activities'
import Activity from '@pages/Activity'
import AddKid from '@pages/AddKid'
import Chapter from '@pages/Chapter'
import DeeplinkSelectKid from '@pages/DeeplinkSelectKid'
import Episode from '@pages/Episode/EpisodeHome'
import ForgotPassword from '@pages/ForgotPassword'
import Gallery from '@pages/Gallery'
import Home from '@pages/Home'
import KidsHome from '@pages/KidsHome'
import listKid from '@pages/ListKid'
import listKidHome from '@pages/ListKidHome'
import Login from '@pages/Login/Login'
import LoginByToken from '@pages/LoginByToken'
import NotFound from '@pages/NotFound'
import Notification from '@pages/Notification'
import Onboarding from '@pages/Onboarding'
import PDFViewerPage from '@pages/PDFViewerPage'
import parentalGateway from '@pages/ParentalGateway'
import Profile from '@pages/Profile'
import Publication from '@pages/Publication'
import Redirector from '@pages/Redirector'
import Register from '@pages/Register/Register'
import ResetPassword from '@pages/ResetPassword'
import Series from '@pages/Series'
import ChapterPlay from '@pages/anonymous/ChapterPlay'
import DeeplinkSelectAge from '@pages/anonymous/DeeplinkSelectAge'
import EpisodeLists from '@pages/anonymous/EpisodeLists'
import KidsHomeAnonymous from '@pages/anonymous/KidsHomeAnonymous'
import SeriesAnonymous from '@pages/anonymous/SeriesAnonymous'
import SeriesHomeAnonymous from '@pages/anonymous/SeriesHomeAnonmous'
import CompetitionChallenges from '@pages/competition/CompetitionChallenges'
import CompetitionDailyChallenge from '@pages/competition/CompetitionDailyChallenge'
import CompetitionHome from '@pages/competition/CompetitionHome'
import CompetitionPrizes from '@pages/competition/CompetitionPrizes'
import CompetitionScore from '@pages/competition/CompetitionScore'
import TopCompetitor from '@pages/competition/TopCompetitor'
import ChallengeGame from '@pages/competition/ChallengeGame'
import CurriculumHome from '@pages/curriculum/CurriculumHome'
import DetailedHandbook from '@pages/handbook/DetailedHandbook'
import HandbookHome from '@pages/handbook/HandbookHome'
import Theatre from '@pages/level-1/Theatre'
import MapsHomeLevel2 from '@pages/level-2/Home'
import { NotActivePage } from '@pages/level-3/Home'
import MaktabahBook from '@pages/maktabah/MaktabahBook'
import MaktabahGames from '@pages/maktabah/MaktabahGames'
import MaktabahHome from '@pages/maktabah/MaktabahHome'
import MaktabahVideo from '@pages/maktabah/MaktabahVideo'
import MaqraaSchedule from '@pages/maqraa/MaqraaSchedule'
import InAppBrowser from '@pages/parent/InAppBrowser'
import ReportHome from '@pages/report/ReportHome'
import SeriesHome from '@pages/series/SeriesHome'
import NotificationControl from '@pages/settings/NotificationControl'
import SettingsHome from '@pages/settings/SettingsHome'
import CheckoutPage from '@pages/subscription/CheckoutPage'
import ChoosePackage from '@pages/subscription/ChoosePackage'
import CurrentSubscription from '@pages/subscription/CurrentSubscription'
import PaymentFailed from '@pages/subscription/PaymentFailed'
import PaymentSucceeded from '@pages/subscription/PaymentSucceeded'
import Router from './router-components/Router'
import CompetitionList from '@pages/competition/CompetitionList'
import PostPayment from '@pages/subscription/PostPayment'

import { Capacitor } from '@capacitor/core'

const isIOS = Capacitor.getPlatform() === 'ios'

const Routes: FC = () => (
  <Switch>
    {/* route */}
    <Router component={Redirector} path="/redirector" publicRoute />
    <Router authRoutes component={Login} exact path="/login" publicRoute />
    <Router
      authRoutes
      component={Register}
      exact
      path="/register"
      publicRoute
    />
    <Router
      authRoutes
      component={LoginByToken}
      path="/login/:username/:authToken"
      publicRoute
    />
    <Router authRoutes component={Home} exact path="/" publicRoute />
    <Router
      component={DeeplinkSelectAge}
      exact
      path="/anonymous-select-age/:route"
      publicRoute
    />
    <Router
      component={DeeplinkSelectAge}
      exact
      path="/anonymous-select-age/:route"
      publicRoute
    />
    <Router component={Onboarding} exact path="/onboarding" publicRoute />
    <Router
      authRoutes
      component={ForgotPassword}
      exact
      path="/forgot-password"
      publicRoute
    />
    <Router
      authRoutes
      component={ResetPassword}
      exact
      path="/reset-password/:id/:token"
      publicRoute
    />
    {/* protected */}
    <Router component={KidsHome} exact path="/kids-home" />
    <Router component={SeriesHome} exact path="/series-home" />
    <Router component={Series} exact path="/series/:slug" />
    <Router component={CompetitionHome} exact path="/competition" />
    <Router component={TopCompetitor} exact path="/top-competitor" />
    <Router component={TopCompetitor} exact path="/top-competitor/:id" />
    <Router component={CompetitionScore} exact path="/competition/scores/" />
    <Router component={ChallengeGame} exact path="/competition/challenge/:id" />
    <Router component={CompetitionChallenges} path="/competition/list/:id" />
    <Router component={CompetitionPrizes} exact path="/competition/prizes" />
    <Router
      component={CompetitionDailyChallenge}
      exact
      path="/competition/daily-challenge"
    />

    <Router component={CompetitionList} exact path="/competition/list" />
    <Router component={MaqraaSchedule} exact path="/maqraa" />
    <Router component={MapsHomeLevel2} path="/l2/maps" />
    <Router component={NotActivePage} path="/l3/maps" />
    <Router component={Profile} path="/profile" />
    <Router component={Activities} exact path="/activities" />
    <Router component={Activity} exact path="/activities/:id" />
    <Router component={Theatre} exact path="/theatre" />
    <Router component={Gallery} exact path="/gallery" />
    <Router component={Publication} path="/gallery/publications/:id" />
    <Router component={Episode} path="/episode/:epiSlug/chapters" />
    <Router component={Activities} path="/episode/:epiSlug/activities" />
    <Router
      component={Activities}
      path="/episode/:epiSlug/:chapSlug/activities"
    />
    <Router component={Chapter} path="/episode/:epiSlug/:chapSlug" />

    <Router component={AddKid} path="/add-kid" />
    <Router component={AddKid} path="/edit-kid/:id" />
    <Router component={listKid} path="/list-kid" />
    <Router component={HandbookHome} exact path="/handbook-home" />
    <Router component={HandbookHome} path="/handbook-home/:id" />
    <Router component={DetailedHandbook} path="/handbook-detail" />
    <Router component={listKidHome} path="/list-kid-home" />
    <Router component={DeeplinkSelectKid} path="/select-kid-home/:route" />
    <Router component={parentalGateway} path="/parent" publicRoute={!!isIOS} />
    <Router component={ReportHome} path="/kids-report/:id" />

    <Router component={CurrentSubscription} path="/subscription/current" />
    <Router component={ChoosePackage} path="/subscription/package" />
    <Router component={CheckoutPage} path="/subscription/checkout/:packageId" />
    <Router component={PaymentSucceeded} path="/subscription/success" />
    <Router component={PaymentFailed} path="/subscription/failed" />
    <Router component={PostPayment} path="/subscription/post-payment" />

    <Router component={Notification} exact path="/notification" />

    <Router component={MaktabahHome} exact path="/library" />
    <Router component={MaktabahVideo} path="/library/video" />

    <Router component={MaktabahGames} path="/library/game" />
    <Router component={MaktabahBook} path="/library/book" />
    <Router component={InAppBrowser} path="/app-browser" />
    <Router component={PDFViewerPage} path="/pdf-viewer" />

    <Router
      component={NotificationControl}
      exact
      path="/settings/notification-control"
    />
    <Router component={SettingsHome} exact path="/settings" />
    {/* anonymouse protected */}

    <Router
      anonOnly
      component={KidsHomeAnonymous}
      exact
      path="/kids-home-anonymous/:level"
    />

    <Router
      anonOnly
      component={Activities}
      exact
      path="/activities-anonymous"
    />
    <Router
      anonOnly
      component={Activity}
      exact
      path="/activities-anonymous/:id"
    />

    <Router anonOnly component={MapsHomeLevel2} path="/anonymous/l2/maps" />

    <Router
      anonOnly
      component={MaqraaSchedule}
      exact
      path="/maqraa-anonymous"
    />

    <Router
      anonOnly
      component={MaqraaSchedule}
      exact
      path="/maqraa-anonymous"
    />

    <Router
      anonOnly
      component={SeriesHomeAnonymous}
      exact
      path="/series-anonymous/:level"
    />

    <Router
      anonOnly
      component={SeriesAnonymous}
      exact
      path="/series-anonymous/:level/slug/:slug"
    />

    <Router
      anonOnly
      component={EpisodeLists}
      exact
      path="/anonymous-episode/:epiSlug/chapters"
    />
    <Router
      anonOnly
      component={EpisodeLists}
      exact
      path="/anonymous-episode/anonymous-chapters/:level/:epiSlug"
    />
    <Router
      anonOnly
      component={ChapterPlay}
      path="/anonymous-episode/:epiSlug/:chapSlug/:level"
    />

    <Router component={CurriculumHome} path="/curriculum" publicRoute />

    {/* 404 */}
    <Router component={NotFound} path="*" publicRoute={true} />
  </Switch>
)

export default Routes
