import { Capacitor } from '@capacitor/core'
import {
  PurchaseManagerAndroid,
  PurchaseManagerIOS,
  PurchaseManagerWeb,
} from '../services/purchases'
import {
  IPurchaseManager,
  IPurchaseManagerPayment,
} from '@src/services/purchases/types'

export function usePurchases(): IPurchaseManager | IPurchaseManagerPayment {
  switch (Capacitor.getPlatform()) {
    case 'web':
      return PurchaseManagerWeb.getInstance()
    case 'android':
      return PurchaseManagerAndroid.getInstance()
    case 'ios':
      return PurchaseManagerIOS.getInstance()
    default:
      throw Error('RevenueCat: Platform not supported')
  }
}
