import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { IScoreBoard } from '@src/types/api.interface'
import { FetchError } from '@utils/FetchError'
import { useMemo } from 'react'
import useSWR from 'swr'

export function useGetScoreBoard(): {
  scores?: IScoreBoard[]
  error?: FetchError
  isLoadingScores: boolean
} {
  const { kidProfile: profile } = useProfile()

  const url = `/challenges/score-board?kidId=${profile._id}`

  const { data, error } = useSWR<Array<{ user: IScoreBoard[] }>, FetchError>(
    url
  )

  return useMemo(
    () => ({
      scores: data?.[0]?.user,
      error,
      isLoadingScores: !data && !error,
    }),
    [data, error]
  )
}
