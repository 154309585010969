import Config from '@config/index'
import { FC } from 'react'
const logo = `${Config.publicURL}/images/logo.svg`

const Logo: FC = () => {
  return (
    <img
      alt="عالم زيدو"
      className="block mx-auto top-0 left-0 main_pages_logo_zido"
      height={87}
      src={logo}
      width={113}
    />
  )
}
export default Logo
