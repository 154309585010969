import useSwr from 'swr'
import { IActivityDetail } from '@src/types/api.interface'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { FetchError } from '@utils/FetchError'

export function useActivity(props: { id: string | null | undefined }): {
  activity?: IActivityDetail
  error?: FetchError
  isLoadingActivity: boolean
} {
  const { kidProfile: profile } = useProfile()

  const url =
    profile && props.id
      ? `/level/${profile.level}/activities/${props.id}`
      : null

  const { data, error } = useSwr<IActivityDetail, FetchError>(url)

  return {
    activity: data,
    error,
    isLoadingActivity: !error && !data,
  }
}
