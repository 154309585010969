import { useNotificatiion } from '@hooks/data/fetchers/use-notifications'
import { dateTimeFormatNotification } from '@utils/date'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'

const useNotificationWrapper = () => {
  const { notifications, isLoadingNotification } = useNotificatiion()

  const [oldNotifications, setOldNotifications] = useState([])
  const [currentNotifications, setCurrentNotifications] = useState([])

  useEffect(() => {
    if (notifications?.length > 0) {
      const currentLocalDate = dayjs().format('YYYY-MM-DD')
      const restructure = notifications.map((e, k) => ({
        uncheckedNotification: k === 0,
        notificationText: e.text,
        notificationTime: dateTimeFormatNotification(e.createdAt),
        // TODO: Need to revisit, right now we only has this two. Refactor if all type available
        image: e.name === 'Retool' ? 'general' : 'maqraa',
        createdAt: e.createdAt,
      }))
      const changeIndex = restructure.findIndex(
        (e) => dayjs(e.createdAt).format('YYYY-MM-DD') !== currentLocalDate
      )
      const todays = restructure.slice(0, changeIndex)
      const olds = restructure.slice(changeIndex)

      setOldNotifications(olds)
      setCurrentNotifications(todays)
    }
  }, [notifications, setCurrentNotifications, setOldNotifications])

  return {
    notifications,
    loading: isLoadingNotification,
    oldNotifications,
    currentNotifications,
  }
}

export default useNotificationWrapper
