import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { IActiveChallenge } from '@src/types/api.interface'
import { FetchError } from '@utils/FetchError'
import { useMemo } from 'react'
import useSWR, { KeyedMutator } from 'swr'

export function useActiveChallenge(): {
  activeChallenge?: IActiveChallenge[]
  error?: FetchError
  isLoadingActiveChallenge: boolean
  mutate: KeyedMutator<IActiveChallenge[]>
} {
  const { kidProfile: profile } = useProfile()

  const url = `/challenges/active-challenge?pathId=${profile.path}&kidId=${profile._id}`

  const { mutate, data, error } = useSWR<IActiveChallenge[], FetchError>(url)

  return useMemo(
    () => ({
      activeChallenge: data,
      error,
      isLoadingActiveChallenge: !data && !error,
      mutate,
    }),
    [mutate, data, error]
  )
}
