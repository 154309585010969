import { ReactNode } from 'react'

export const BlueBoxContainer = ({
  children,
  dimensionClass,
  classNames,
}: {
  children?: ReactNode
  dimensionClass?: string
  classNames?: string
}): React.ReactElement => {
  return (
    <div
      className={`blue_box_container ${
        dimensionClass ??
        'max-w-[640px] w-[90%] landscape:max-w-[420px] sm:landscape:h-[170px] '
      } ${classNames}`}
    >
      <div className="blue_box_container-child-wrpr">{children}</div>
    </div>
  )
}
