import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { ISeries } from '@src/types/api.interface'
import { FetchError } from '@utils/FetchError'
import useSwr from 'swr'

export function useSeries() {
  const { kidProfile: profile } = useProfile()

  //   const kidId = profile?._id

  const url = profile?.level ? `/level/${profile.level}/series/kids` : null

  const { data, error, mutate } = useSwr<ISeries[], FetchError>(url)

  return {
    series: data ?? [],
    error,
    isLoadingSeries: !error && !data,
    mutateEpisode: mutate,
  }
}
