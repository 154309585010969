import React, { FC, useEffect, useRef } from 'react'

import videojs from 'video.js'
import { Capacitor } from '@capacitor/core'

const PopupVideoPlayer: FC<{
  url: string
  id: string
  isVisible: boolean
  onClose: () => void
  thumbnail?: string
}> = ({ url, id, isVisible, onClose, thumbnail }) => {
  const playerRef = useRef(null)
  const videoRef = useRef(null)

  useEffect(() => {
    const video = videoRef.current

    if (!video) return

    const initializePlayer = () => {
      videojs(video, {
        autoplay: false,
        controls: true,
        fill: true,
        poster: thumbnail,
        controlBar: {
          playToggle: true,
          durationDisplay: true,
          progressControl: true,
          remainingTimeDisplay: false,
          volumePanel: !Capacitor.isNativePlatform(),
          fullscreenToggle: true,
        },
        bigPlayButton: true,
        sources: [{ src: url }],
      }).ready(function () {
        playerRef.current = this

        this.on('contextmenu', function (e) {
          e.preventDefault()
        })
      })
    }

    if (playerRef.current) {
      // Check if the poster has changed and update it
      if (playerRef.current.poster() !== thumbnail) {
        playerRef.current.poster(thumbnail)
      }

      // Check if the video source has changed and update it
      if (playerRef.current.currentSrc() !== url) {
        playerRef.current.src([{ src: url }])
      }
    } else {
      // Create Video.js player instance
      initializePlayer()
    }
  }, [url, thumbnail])

  useEffect(() => {
    return () => {
      const player = playerRef.current
      if (player) {
        player.dispose()
      }
    }
  }, [])

  return (
    <div
      className={`fixed  flex items-center justify-center z-50 top-0 right-0 border border-black w-screen bg-black bg-opacity-70 h-screen popup_video_player-container ${
        isVisible ? 'block' : 'hidden'
      }`}
    >
      <div className="border border-white w-[80%] h-[80%] relative bg-white bg-opacity-100 popup_video_player-btn-wrpr">
        <button
          className="icon-close-popup absolute -top-5 -right-5 z-30 popup_video_player-btn"
          onClick={() => {
            const player = playerRef.current
            if (player) {
              player.dispose()
            }
            onClose()
          }}
          type="button"
        />

        <div className="popup_video_player-vjs-player" data-vjs-player>
          <video
            className="vjs-matrix video-js vjs-big-play-centered vjs-fill popup_video_player-vjs-fill"
            id={id}
            ref={videoRef}
          />
        </div>
      </div>
    </div>
  )
}

export default PopupVideoPlayer
