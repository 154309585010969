import { ContentTitle } from '@components/ContentTitle'
import { AppBar, AppContent } from '@components/Layout'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import Config from '@config/index'
import { useGetScoreBoard } from '@hooks/data/fetchers/competition/useGetScoreBoard'
import useDraggableSlider from '@hooks/utils/useDraggableSlider'
import { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import SVG from 'react-inlinesvg'
import { SCORES_TYPOGRAPHY } from './constants'

const scoreCardSVG = `${Config.publicURL}/images/v3/competition-score-card.svg`
const scoreCardGrayedSVG = `${Config.publicURL}/images/v3/competition-score-card-grayed.svg`
const warningIcon = `${Config.publicURL}/images/v3/competition-warning-icon.svg`
const characterSVG = `${Config.publicURL}/images/v3/pencil-character.svg`

const CompetitionScore: FC = () => {
  const { isLoadingScores, scores } = useGetScoreBoard()
  useDraggableSlider()

  return (
    <>
      <Helmet>
        <title className="competition_score_page-main-title">
          {SCORES_TYPOGRAPHY.WEBSITE_TITLE}
        </title>
      </Helmet>

      {isLoadingScores ? <AppProgressBar /> : null}

      <AppBar
        backButtonLocation="/competition"
        fixed
        hideToggleButton
        showKidAvatar
        showParentBackButton
        title={SCORES_TYPOGRAPHY.TITLE}
      />
      <AppContent className="flex flex-col items-center bg-no-repeat bg-cover bg-center bg-green-v3">
        <ContentTitle notBold title={SCORES_TYPOGRAPHY.TITLE} />

        <div
          className="competition_score_page-container flex flex-row w-11/12 overflow-x-scroll hide-scroll pt-12 cursor-grab"
          id="draggable-container"
        >
          {!isLoadingScores
            ? scores?.map((score) => {
                const hasRank = score?.score !== null

                if (hasRank) {
                  return (
                    <div
                      className="competition_score_page-scores-wrapper relative flex flex-col justify-center items-center"
                      key={score.id}
                    >
                      <p className="competition_score_page-scores-typography text-2xl absolute z-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -mt-7">
                        {SCORES_TYPOGRAPHY.CHALLANGE}
                      </p>
                      <p
                        className={`competition_score_page-score-title-${score?.title} text-2xl absolute z-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 mt-5 max-w-[110px] leading-[1.20rem] text-center`}
                      >
                        {score.title}
                      </p>
                      <p
                        className={`competition_score_page-score-${score?.score} text-4xl text-white absolute z-20 left-1/2 -translate-x-1/2 bottom-7`}
                      >
                        {score?.score}
                      </p>
                      <ScoreCard hasRank={hasRank} />
                    </div>
                  )
                } else {
                  return (
                    <div
                      className="competition_score_page-no-rank-wrapper relative flex flex-col justify-center items-center"
                      key={score.id}
                    >
                      <SVG
                        className="competition_score_page-no-rank-warning-icon z-20 absolute top-1/4 -translate-y-1/4 mt-5"
                        src={warningIcon}
                      />
                      <p className="competition_score_page-no-score-typography text-2xl absolute z-20 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 mt-5 text-white text-center">
                        {SCORES_TYPOGRAPHY.NO_SCORE}
                      </p>

                      <ScoreCard hasRank={hasRank} />
                    </div>
                  )
                }
              })
            : null}

          {!isLoadingScores && !scores ? (
            <div className="competition_score_page-safe-screen h-safe-screen flex flex-col items-center justify-center w-full">
              <SVG
                className="competition_score_page-safe-screen-character-svg z-10 h-48 lg:h-96 w-full -mb-3"
                src={characterSVG}
              />
              <div className="competition_score_page-safe-screen-title-wrapper self-center bg-[#05602E] py-3 lg:pb-2 lg:pt-1 px-12 rounded-3xl border-4 border-[#A8C64D] w-9/12 xl:w-6/12">
                <h1 className="competition_score_page-safe-screen-title text-2xl lg:text-4xl text-center text-white">
                  {SCORES_TYPOGRAPHY.EMPTY_MESSAGE}
                </h1>
              </div>
            </div>
          ) : null}
        </div>
      </AppContent>
    </>
  )
}

interface ScoreCardProps {
  hasRank: boolean
}

const ScoreCard: FC<ScoreCardProps> = ({ hasRank }) => {
  if (hasRank) {
    return (
      <SVG
        className="competition_score_page-has-rank-svg z-10"
        src={scoreCardSVG}
      />
    )
  } else {
    return (
      <SVG
        className="zcompetition_score_page-has-no-rank-svg -10"
        src={scoreCardGrayedSVG}
      />
    )
  }
}

export default CompetitionScore
