import { ContentTitle } from '@components/ContentTitle'
import { AppBar, AppContent } from '@components/Layout'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { useAllSeries } from '@hooks/data/fetchers/use-all-series'
import { useEpisodesInSeriesParent } from '@hooks/data/fetchers/use-episodes-in-series-parent'
import { useLatestSeriesEpisode } from '@hooks/data/fetchers/use-latest-series-episodes'
import { useMobileView } from '@hooks/useMobileView'
import {
  MORE_CONTENT_COMING,
  RANKING_ORDER,
  WE_WILL_UPDATE,
  ZIDO_WORLD_TEAM,
} from '@src/constants'
import { IEpisodeListItem, ISeries, Profile } from '@src/types/api.interface'
import {
  KID_AVATAR_FEMALE_NO_EYES_ICON,
  KID_AVATAR_MALE_NO_EYES_ICON,
} from '@utils/constants'
import { GtagEvents } from '@utils/gtag-events'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { Link, useParams } from 'react-router-dom'

const HandbookHome: FC = () => {
  const { id } = useParams<{ id: string }>()

  const { parentProfile, toggleHandbookIdicator } = useProfile()

  const [selectedKidId, setSelectedKidId] = useState<string>(id)
  const [selectedSeriesId, setSelectedSeriesId] = useState<string>()
  const [filteredSeries, setFilteredSeries] = useState<ISeries[]>()

  const isMobileView = useMobileView()

  const [selectedIndex, setSelectedIndex] = useState(0)

  const handleChangeSeries = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedSeriesId(e.target.value)
  }

  // Get all the series
  const { data: allSeries, isLoadingAllSeries } = useAllSeries()

  // Get all episodes based on the selected series id
  const { episodes, isLoadingEpisodes } =
    useEpisodesInSeriesParent(selectedSeriesId)

  // Get latest series and episode id based on selected kid id
  const { latestSeriesEpisode, isLoadingLatestSeriesEpisodes } =
    useLatestSeriesEpisode(selectedKidId)

  // set the latest episode index
  useEffect(() => {
    const index = episodes
      ?.filter((a) => !a.isAnon)
      .findIndex(
        (episode) => episode?._id === latestSeriesEpisode?.latestEpisodeId
      )

    setSelectedIndex(index < 0 ? 0 : index)
  }, [latestSeriesEpisode?.latestEpisodeId, episodes])

  // filter the series by the selected kid level
  useEffect(() => {
    const selectedKid = parentProfile.kids.find(
      (kid) => kid?._id === selectedKidId
    )
    const seriesFiltered = allSeries?.filter(
      (series) => Number(series.path.level) === selectedKid?.level
    )

    if (selectedKid.handbookIndicatorFlag) {
      toggleHandbookIdicator(selectedKid, false)
    }

    setFilteredSeries(seriesFiltered)

    if (seriesFiltered?.length > 0)
      setSelectedSeriesId(
        latestSeriesEpisode?.latestSeriesId ?? seriesFiltered[0]._id
      )
  }, [
    isLoadingAllSeries,
    allSeries,
    parentProfile?.kids,
    selectedKidId,
    latestSeriesEpisode?.latestSeriesId,
    toggleHandbookIdicator,
  ])

  const handleKidSelect = (kid: Profile) => {
    const seriesFiltered = allSeries
      ?.filter((series) => Number(series.path.level) === kid?.level)
      .sort((a, b) => a.order - b.order)

    setSelectedKidId(kid?._id)
    setFilteredSeries(seriesFiltered)

    if (kid.handbookIndicatorFlag) {
      toggleHandbookIdicator(kid, false)
    }

    if (seriesFiltered?.length > 0) {
      setSelectedSeriesId(
        latestSeriesEpisode?.latestSeriesId ?? seriesFiltered[0]._id
      )
    } else {
      setSelectedSeriesId('undefined')
    }
  }
  const handleBookOpen = (episode: IEpisodeListItem) => {
    if (episode?.handbook) {
      GtagEvents.handbookOpened({
        episode,
        childId: selectedKidId,
        handbookId: episode?.handbook,
        episodeId: episode?._id,
        parentId: parentProfile?._id,
        contentId: episode?.series?._id,
      })
    }
  }
  // const handleBookRead = (episode: IEpisodeListItem) => {
  //   GtagEvents.contentOpened({
  //     episode,
  //     parentId: parentProfile?._id,
  //     childId: selectedKidId,
  //     episodeId: episode?._id,
  //     handBookId: episode?.handbook,
  //     contentId: episode?.series?._id,
  //   })
  // }
  return (
    <>
      {isLoadingAllSeries ||
      isLoadingLatestSeriesEpisodes ||
      isLoadingEpisodes ? (
        <AppProgressBar />
      ) : null}
      <AppBar
        backButtonLocation="/list-kid"
        fixed
        hideToggleButton
        showParentBackButton
        title=""
      />

      <AppContent
        className="handbook_home_page_app_content bg-no-repeat bg-cover bg-center bg-v3 overflow-y-scroll h-screen hide-scroll"
        haveFooter={!isMobileView}
      >
        <ContentTitle
          bottomLine={!isMobileView}
          noShadow
          notBold
          textColor="text-white"
          title="دليل المربي"
        />

        <ScrollContainer
          className={`handbook_home_page_scroll_container w-full flex gap-x-3 ${
            parentProfile?.kids?.length === 1
              ? 'justify-center'
              : ' items-center flex-nowrap whitespace-nowrap px-3'
          } mt-6 overflow-x-auto hide-scroll `}
        >
          {parentProfile?.kids.map((kid, idx) => (
            <KidButton
              isSelected={kid?._id === selectedKidId}
              key={idx}
              kid={kid}
              onSelect={handleKidSelect}
            />
          ))}
        </ScrollContainer>

        {!isLoadingAllSeries &&
        !isLoadingLatestSeriesEpisodes &&
        !isLoadingEpisodes ? (
          <>
            <SeriesDropDown
              disabled={!selectedKidId}
              handleChangeSeries={handleChangeSeries}
              selectedSeries={selectedSeriesId}
              seriesList={filteredSeries}
            />
            {episodes?.length === 0 && filteredSeries?.length === 0 ? (
              <div className="handbook_home_page_no_episode_wrapper text-xl md:text-2xl flex flex-col text-white w-full items-center justify-center pb-2 mt-4 lg:mt-6 lg:h-[40vh]">
                <span className="handbook_home_page_more_content_message">
                  {MORE_CONTENT_COMING}
                </span>
                <span className="handbook_home_page_we_will_update_message">
                  {WE_WILL_UPDATE}
                </span>
                <span className="handbook_home_page_zido_world_team">
                  {ZIDO_WORLD_TEAM}
                </span>
              </div>
            ) : (
              <div className="handbook_home_page_episodes_wrapper grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-8 place-items-center justify-items-center overflow-scroll pb-6 hide-scroll mb-4 lg:mb-2 px-4 xl:px-8 mt-4 lg:mt-6">
                {episodes
                  ?.filter((a) => !a.isAnon)
                  .map((oneEpisode, index) => (
                    <EpisodeCard
                      currentSeries={
                        oneEpisode?.series?._id ===
                        latestSeriesEpisode?.latestSeriesId
                      }
                      currentSeriesOrder={oneEpisode?.series?.order}
                      episode={oneEpisode}
                      handbookOpen={() => handleBookOpen(oneEpisode)}
                      // handbookRead={() => handleBookRead(oneEpisode)}
                      index={index}
                      key={index}
                      latestSeriesOrder={latestSeriesEpisode?.latestSeriesOrder}
                      outOfBound={
                        latestSeriesEpisode?.cycleMoreThanTotalEpisodes
                      }
                      selectedIndex={selectedIndex}
                    />
                  ))}
              </div>
            )}
          </>
        ) : null}
      </AppContent>
    </>
  )
}

const SeriesDropDown: FC<{
  seriesList: ISeries[]
  selectedSeries: string
  handleChangeSeries: (e: ChangeEvent<HTMLSelectElement>) => void
  disabled?: boolean
}> = ({ seriesList, selectedSeries, handleChangeSeries, disabled }) => {
  return (
    <div className="flex gap-x-2 items-center px-8 mt-6">
      <p className="text-white">حدد المقصد</p>
      <div className="bg-[#A9DAF8]  rounded-3xl px-4 flex items-center justify-center">
        <select
          className={`appearance-none text-xl lg:text-lg bg-[#A9DAF8] py-1 border-none outline-none text-[#003351] ${
            disabled && 'opacity-20'
          }`}
          disabled={disabled}
          id="handbook-select"
          name="handbook-select"
          onChange={handleChangeSeries}
          value={selectedSeries}
        >
          {seriesList?.map((oneSeries, index) => (
            <option key={index} value={oneSeries._id}>
              {oneSeries.title}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

const KidButton: FC<{
  kid: Profile
  isSelected: boolean
  onSelect: (kid: Profile) => void
}> = ({ kid, isSelected, onSelect }) => {
  const handleButtonClick = () => {
    onSelect({ ...kid })
  }

  return (
    <button
      className="flex-shrink-0 flex flex-col items-center justify-center  first:mr-auto last:ml-auto"
      onClick={handleButtonClick}
      type="button"
    >
      <div
        className={`flex items-end justify-end px-2 py-1 ${
          isSelected
            ? 'bg-[#E5F6B1] border-[#99BC2D]'
            : 'bg-[#E1F4FF] border-[#AFD8EF]'
        } border-4 rounded-3xl`}
      >
        <img
          alt="avatar"
          className="w-12 aspect-square"
          src={
            kid.gender === 'male'
              ? KID_AVATAR_MALE_NO_EYES_ICON
              : KID_AVATAR_FEMALE_NO_EYES_ICON
          }
        />
      </div>
      <span className={` ${isSelected ? 'text-[#99BC2D]' : 'text-white'}`}>
        {kid.nickname ?? kid.firstName}
      </span>
    </button>
  )
}

const EpisodeCard: FC<{
  episode: IEpisodeListItem
  selectedIndex?: number
  currentSeries?: boolean
  index?: number
  handbookOpen?: () => void
  currentSeriesOrder?: number
  latestSeriesOrder?: number
  outOfBound?: boolean
}> = ({
  episode,
  index,
  selectedIndex,
  currentSeries,
  handbookOpen,
  currentSeriesOrder,
  latestSeriesOrder,
  outOfBound,
}) => {
  const disableCard =
    currentSeriesOrder < latestSeriesOrder
      ? false
      : !currentSeries || index > selectedIndex

  const selected = index === selectedIndex && currentSeries && !outOfBound

  const isMobileView = useMobileView()

  return (
    <ConditionalEpisodeCardWrapper
      disableCard={disableCard}
      episode={episode}
      handbookOpen={handbookOpen}
      isMobileView={isMobileView}
    >
      <div
        className={`w-full ${
          isMobileView
            ? 'drop-shadow-[-3px_6px_0px_rgba(0,0,0,0.30)]'
            : 'hover:drop-shadow-[-3px_7px_0px_rgba(0,0,0,0.30)]'
        } border-2 rounded-3xl py-2 px-3 lg:px-4 text-[#003351] ${
          disableCard ? 'bg-[#E4E4E4]' : 'bg-white'
        } ${
          disableCard && 'opacity-80'
        } border-[#B9D6E7] flex flex-col items-center justify-center`}
      >
        <div className="w-full rounded-2xl h-[120px] lg:h-[160px] xl:h-[180px] 2xl:h-[200px] flex overflow-hidden relative">
          <img alt="" className="w-full" src={episode?.thumbnail} />
          {selected ? (
            <span className="absolute left-0 top-3 px-1 pr-2 rounded-r-full text-white bg-[#EC7802]">
              الحالي
            </span>
          ) : null}
        </div>

        <div className="flex justify-between items-center w-full mt-1">
          <div className="flex flex-col">
            <span className="text-xl">
              الوحدة {RANKING_ORDER[episode.order]}
            </span>
            <span className="lg:text-lg text-[#4F7C97]">{episode.title}</span>
          </div>

          {/* Open PDF */}
          {!isMobileView ? (
            <Link
              className={`bg-[#B3D65B] rounded-full h-full px-4 py-1 lg:text-xl drop-shadow-[3px_3px_0px_#75A012] ${
                (disableCard || !episode.handbook) &&
                'opacity-50 cursor-default'
              }`}
              onClick={handbookOpen}
              to={{
                pathname: disableCard || !episode.handbook ? '' : '/pdf-viewer',
                state: {
                  episode,
                  url: episode.handbook,
                  title: `الوحدة ${RANKING_ORDER[episode.order]}`,
                },
              }}
            >
              <span>عرض</span>
            </Link>
          ) : null}
          {/* NOTE: this code is disabled for now. May need to reneable later. DO NOT DELETE */}
          {/* <Link
          onClick={handbookRead}
          to={{
            pathname:
              disableCard || !episode?.detailedHandbookFlag
                ? ''
                : '/handbook-detail',
            state: {
              episode,
            },
          }}
          className={`w-1/3 outlineButton min-w-[120px] flex items-center justify-center ${
            (disableCard || !episode?.detailedHandbookFlag) &&
            'opacity-50 cursor-default'
          }`}
        >
          <img
            className="w-4 h-4 ml-2 md:h-5 md:w-5 lg:w-4 lg:h-4"
            src={rightArrowIcon}
            alt=""
          />
          <span>المزيد</span>
        </Link> */}
        </div>
      </div>
    </ConditionalEpisodeCardWrapper>
  )
}

const ConditionalEpisodeCardWrapper: FC<{
  episode: IEpisodeListItem
  handbookOpen: () => void
  disableCard: boolean
  isMobileView: boolean
  children: React.ReactNode
}> = ({ episode, handbookOpen, disableCard, isMobileView, children }) => {
  if (isMobileView) {
    return (
      <Link
        className="w-full"
        onClick={handbookOpen}
        to={{
          pathname: disableCard || !episode.handbook ? '' : '/pdf-viewer',
          state: {
            url: episode.handbook,
            title: `الوحدة ${RANKING_ORDER[episode.order]}`,
          },
        }}
      >
        {children}
      </Link>
    )
  }

  return <>{children}</>
}

export default HandbookHome
