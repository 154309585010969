function buildQueryString(params: unknown) {
  const queryString = Object.entries(params)
    .filter(
      ([_, value]) =>
        value !== undefined &&
        value !== 'undefined' &&
        value !== null &&
        value !== ''
    )
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&')

  return queryString ? `?${queryString}` : ''
}

export default buildQueryString
