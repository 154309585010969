import React, { createContext, useCallback, useContext, useState } from 'react'
import { isFetchError } from '@utils/FetchError'
import Helper from '@utils/Helpers'
import {
  AnonymousContextType,
  LoginResponse,
} from './types/AnonymousContext.type'
import HelperJWT from '@utils/helpers/helper.jwt'
import HelperAPI from '@utils/helpers/helper.api'
import { useHistory } from 'react-router-dom'
import HelperProfile from '@utils/helpers/helper.profile'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { toast } from 'react-toastify'

export const AnonymousContext = createContext<AnonymousContextType>({
  userToken: undefined,
  logout: (_redirect?: unknown) => null,
  loginAnonymous: async () => null,
  isLoaded: false,
  setIsLoaded: (_status: boolean) => null,
  setUserToken: (_status) => null,
})

export const AnonymousProvider = (props: {
  children: React.ReactNode | string
}) => {
  const history = useHistory()
  const { storedProfile } = useProfile()
  const [userToken, setUserToken] = useState<string | undefined>(
    HelperJWT.getJwtToken() ?? ''
  )
  const [isLoaded, setIsLoaded] = useState(!!userToken)

  const loginAnonymous = useCallback(async (): Promise<string | null> => {
    const data = {
      accessToken: '',
    }
    try {
      const response = await HelperAPI.ApiRequest<LoginResponse>(
        '/firebase_auth/anonymous-register-and-login',
        {
          method: 'POST',
          body: JSON.stringify(data),
        }
      )
      const loginResponse = response
      HelperJWT.setJwtToken(loginResponse.token)
      await storedProfile()
      setUserToken(loginResponse.token)
      toast.dismiss('anonLogin')
      HelperProfile.setProfileType('anon')
      return loginResponse.token || null
    } catch (err) {
      if (isFetchError(err)) {
        console.error(err.message)
      }
      return 'error'
    } finally {
      setIsLoaded(true)
    }
    // TODO: Revisit this for proper useEffect and  useCallback implementation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logout = (redirect = true) => {
    Helper.logOut('anonymouscontext-logout')
    if (redirect) {
      history.push('/')
    }
  }

  return (
    <AnonymousContext.Provider
      value={{
        userToken,
        setUserToken,
        logout,
        loginAnonymous,
        isLoaded,
        setIsLoaded,
      }}
    >
      {props.children}
    </AnonymousContext.Provider>
  )
}

export function useAnonymous(): AnonymousContextType {
  return useContext(AnonymousContext)
}
