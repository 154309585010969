import { ContentTitle } from '@components/ContentTitle'
import { AppBar, AppContent } from '@components/Layout'
import { RANKING_ORDER } from '@src/constants/messages'
import {
  IActivityListItem,
  IChapterListItem,
  IEpisodeListItem,
} from '@src/types/api.interface'
import {
  BOOK_ICON_SQUARE,
  GAME_ICON_SQUARE,
  VIDEO_ICON_SQUARE,
} from '@utils/constants'
import whiteEyeIcon from '@resources/images/white-eye.png'
import React, { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'

const DetailedHandbook: FC = () => {
  const { episode } = useLocation<{ episode: IEpisodeListItem }>().state

  return (
    <>
      <AppBar fixed hideToggleButton showParentBackButton title="" />
      <AppContent
        className="detailed_handbook_page_app_content bg-no-repeat bg-cover bg-center bg-v3"
        haveFooter
      >
        <ContentTitle
          bottomLine
          noShadow
          notBold
          textColor="text-white"
          title={`الوحدة ${RANKING_ORDER[episode.order ?? 1]}`}
        />
        <DetailedHandbookContainer episode={episode} />
      </AppContent>
    </>
  )
}

export default DetailedHandbook

const DetailedHandbookContainer: FC<{ episode: IEpisodeListItem }> = ({
  episode,
}) => {
  return (
    <div className="detailed_handbook_page_episode_wrapper bg-[#EBF6F8] border-2 border-[#CCCCCC] rounded-lg p-4 mx-8 mt-10 mb-2">
      <div className="detailed_handbook_page_episode_content flex">
        <img
          alt=""
          className="detailed_handbook_page_episode_thumbnail rounded-lg w-[120px]"
          src={episode.thumbnail}
        />
        <div className="detailed_handbook_page_episode_order_wrapper flex flex-col justify-start mr-4">
          <span className="detailed_handbook_page_episode_order text-2xl lg:text-lg text-text">
            {`الوحدة ${RANKING_ORDER[episode.order]}`}
          </span>
          <span className="detailed_handbook_page_episode_title text-text text-xl lg:text-base">
            {episode.title}
          </span>
        </div>
      </div>
      <hr className="detailed_handbook_page_episode_hidden_hr hidden lg:block border-1 my-4 border-[#CCCCCC]" />
      <div className="detailed_handbook_page_episode_chapters grid grid-cols-1 lg:grid-cols-3">
        {episode.chapters.map((chapter) => (
          <DetailedHandbookCard
            content={chapter}
            episodeOrder={episode.order}
            key={chapter._id}
            type="video"
          />
        ))}

        {episode.activities.map((activity) => (
          <DetailedHandbookCard
            content={activity}
            episodeOrder={episode.order}
            key={activity._id}
            type={activity.category}
          />
        ))}
      </div>
    </div>
  )
}

const DetailedHandbookCard: FC<{
  content: IChapterListItem | IActivityListItem
  type: 'video' | 'game' | 'book'
  episodeOrder: number
}> = ({ content, type, episodeOrder }) => {
  return (
    <>
      <hr className="detailed_handbook_card_container block lg:hidden border-1 my-4 border-[#CCCCCC]" />
      <div className="detailed_handbook_card_wrapper my-1 flex lg:bg-white lg:rounded-lg pt-2 pr-2 m-1 items-center justify-between">
        <div className="detailed_handbook_card_img_wrapper flex items-center">
          <img
            alt=""
            className="detailed_handbook_card_img w-[40px] h-[40px] lg:w-[50px] lg:h-[50px]"
            src={
              type === 'video'
                ? VIDEO_ICON_SQUARE
                : type === 'game'
                ? GAME_ICON_SQUARE
                : BOOK_ICON_SQUARE
            }
          />
          <span className="detailed_handbook_card_title mr-4 text-text text-xl lg:text-base">
            {content.title}
          </span>
        </div>
        {content?.handbook ? (
          <Link
            className={`detailed_handbook_card_content_handBook_link greenButton min-w-[120px] w-1/3 h-[56px] text-white flex items-center justify-center ${
              !content?.handbook && 'opacity-50 cursor-default'
            }`}
            to={{
              pathname: content?.handbook ? '/pdf-viewer' : '',
              state: {
                url: content.handbook,
                title: `الوحدة ${RANKING_ORDER[episodeOrder]}`,
              },
            }}
          >
            <img
              alt=""
              className="detailed_handbook_card_white_eye_icon_with_link w-4 h-3 ml-2 mt-1 md:w-5 md:h-4 lg:w-4 lg:h-3"
              src={whiteEyeIcon}
            />
            <span>عرض</span>
          </Link>
        ) : (
          <div
            className={`detailed_handbook_card_green_button greenButton min-w-[120px] w-1/3 h-[56px] text-white flex items-center justify-center ${
              !content?.handbook && 'opacity-50 cursor-default'
            }`}
          >
            <img
              alt=""
              className="detailed_handbook_card_white_eye_icon_without_link w-4 h-3 ml-2 mt-1 md:w-5 md:h-4 lg:w-4 lg:h-3"
              src={whiteEyeIcon}
            />
            <span>عرض</span>
          </div>
        )}
      </div>
    </>
  )
}
