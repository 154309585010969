/* eslint-disable no-constant-condition */
import {
  CSSProperties,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Helmet } from 'react-helmet-async'
import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/pagination'

import AccessibilityButton from '@components/Buttons/AccessibilityButton'
import { AppContent } from '@components/Layout'
import { useOnBoarding } from '@hooks/data/fetchers/useOnBoarding'
import Helper from '@utils/Helpers'
import { checkUrlTypeImage } from '@utils/check-url-image'
import HelperProfile from '@utils/helpers/helper.profile'
import { useHistory } from 'react-router-dom'
import { Swiper as SwiperType } from 'swiper/types'
import { GtagEvents } from '@utils/gtag-events'

const MESSAGE_FINISH_ONBOARDING = 'التالي'
const DEEPLINK_BUTTON = 'جرب الان'

const paginationStyle = {
  '--swiper-pagination-color': '#0C9BE2',
  '--swiper-pagination-bullet-border': '10px solid red',
  '--swiper-pagination-bullet-inactive-color': '#0C9BE2',
  '--swiper-pagination-bullet-inactive-opacity': '1',
  '--swiper-pagination-bullet-size': '1rem',
} as CSSProperties

const Onboarding: FC = () => {
  const history = useHistory()
  const swiperRef = useRef(null)

  const [startSlide, setStartSlide] = useState(true)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isLastSlide, setIsLastSlide] = useState(false)

  const onFinish = useCallback(() => {
    Helper.setOnboardingStatus(true)
    history.push('/')
  }, [history])

  const [onboardingsData, isLoading] = useOnBoarding(onFinish)

  useEffect(() => {
    if (!isLoading && onboardingsData?.length === 1) {
      setIsLastSlide(true)
    }
    GtagEvents.customEvent({ event: 'Intropage_initiation' })
  }, [isLoading, onboardingsData?.length])

  useEffect(() => {
    if (Helper.getOnboardingStatus()) {
      history.push('/')
    }
  }, [history])

  const handleSwipe = (swiper: SwiperType) => {
    setCurrentIndex(swiper.realIndex)
    setStartSlide(swiper.isBeginning)
    setIsLastSlide(swiper.isEnd)
  }

  const handlePrevious = () => {
    if (swiperRef.current) {
      swiperRef?.current?.swiper.slidePrev()
    }
  }

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef?.current?.swiper.slideNext()
    }
  }

  const action = (deepLink: string) => {
    try {
      const url = new URL(deepLink)
      const path = url.pathname
      HelperProfile.setStoredRedirectLocation(path)
    } catch (e) {
      HelperProfile.setStoredRedirectLocation(deepLink)
    } finally {
      history.push('/login')
    }
  }

  return (
    <>
      <Helmet>
        <title className="onboarding_page-main-title">Onboarding</title>
      </Helmet>
      <AppContent
        bgDecoration
        className="bg-no-repeat bg-cover bg-center  grid place-items-center h-screen bg-kids"
      >
        <div className="onboarding_page-accessibility-button-skip-wrapper absolute left-5 top-5 z-50">
          <AccessibilityButton onClick={onFinish} variant="skip" />
        </div>
        <div className="onboarding_page-slider-wrapper grid">
          <Swiper
            className="onboarding_page-swiper h-[75vh] w-[70vw]"
            modules={[Pagination]}
            onSlideChange={handleSwipe}
            pagination={true}
            ref={swiperRef}
            style={paginationStyle}
          >
            {onboardingsData?.map((onboarding, index) => (
              <SwiperSlide key={index}>
                <div className="onboarding_page-swiper-file-viewer-wrapper grid place-items-center p-4 grid-cols-1 grid-rows-1 gap-2 h-full pb-10">
                  {onboarding.fileUrl ? (
                    <FileViewer
                      currentIndex={currentIndex}
                      image={checkUrlTypeImage(onboarding.fileUrl)}
                      index={index}
                      url={onboarding.fileUrl}
                    />
                  ) : (
                    ''
                  )}
                  <div className="onboarding_page-onboarding-title-wrapper text-center mt-4">
                    <h1 className="onboarding_page-onboarding-title text-2xl font-bold text-black">
                      {onboarding.title}
                    </h1>
                  </div>
                  {onboarding.deepLink ? (
                    <div className="onboarding_page-deep-link-wrapper grid place-items-center">
                      <button
                        className="onboarding_page-deep-link-button w-[100px] h-[50px] landscape:sm:w-[100px] landscape:sm:h-[45px] landscape:2xl:w-[110px] sm:w-[110px]  cursor-pointer bg-parent-gateway landscape:2xl:h-[50px] sm:h-[50px] bg-cover bg-no-repeat"
                        onClick={() => action(onboarding.deepLink)}
                        type="button"
                      >
                        <p className="onboarding_page-deep-link-button-text text-white font-light text-2xl text-center mb-3">
                          {DEEPLINK_BUTTON}
                        </p>
                      </button>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {onboardingsData?.length === 0 ? (
            <FinishButton onClick={onFinish} />
          ) : (
            ''
          )}
        </div>
        <div className="onboarding_page-accessibility-button-next-wrapper absolute left-5 bottom-5 z-50">
          {!isLastSlide ? (
            <AccessibilityButton onClick={handleNext} variant="next" />
          ) : (
            ''
          )}
        </div>
        <div className="onboarding_page-accessibility-button-prev-wrapper absolute right-5 bottom-5 z-50">
          {!startSlide ? (
            <AccessibilityButton onClick={handlePrevious} variant="previous" />
          ) : (
            ''
          )}
        </div>
      </AppContent>
    </>
  )
}

export default Onboarding

interface FinishButtonProps {
  onClick: () => void
}

const FinishButton = (props: FinishButtonProps) => {
  return (
    <div className="onboarding_page-finish-button-wrapper grid place-items-center">
      <button
        className="onboarding_page-finish-button w-[90px] h-[40px] landscape:sm:w-[100px] landscape:sm:h-[45px] landscape:2xl:w-[110px] sm:w-[110px]  cursor-pointer bg-parent-gateway landscape:2xl:h-[50px] sm:h-[50px] bg-cover bg-no-repeat"
        onClick={props.onClick}
        type="button"
      >
        <p className="onboarding_page-finish-button-message text-white text-center">
          {MESSAGE_FINISH_ONBOARDING}
        </p>
      </button>
    </div>
  )
}

interface FileViewerProps {
  image: boolean
  url: string
  currentIndex: number
  index: number
}

const FileViewer = (props: FileViewerProps) => {
  const { image, url } = props
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (props.currentIndex === props.index) {
      if (videoRef.current) {
        videoRef.current.play()
        GtagEvents.customEvent({ event: 'Intropage_videostart' })
      }
    } else {
      if (videoRef.current) {
        videoRef.current.pause()
        GtagEvents.customEvent({ event: 'Intropage_videopause' })
      }
    }
    return () => {
      GtagEvents.customEvent({ event: 'Intropage_videoclose' })
    }
  }, [props.currentIndex, props.index])

  return image ? (
    <img
      alt="onboarding"
      className="onboarding_page-file-viewer-icon object-contain h-full w-full"
      src={url}
    />
  ) : (
    <video
      autoPlay
      className={`onboarding_page-file-viewer-video-${url} h-full w-full`}
      controls
      loop
      ref={videoRef}
    >
      <source className={`onboarding_page-source-${url}`} src={url} />
      Your browser does not support the video tag. I suggest you upgrade your
      browser.
    </video>
  )
}
