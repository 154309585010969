import { Purchases } from '@awesome-cordova-plugins/purchases'
import { FetchError } from '@utils/FetchError'
import { logEvent } from '@utils/firebase-analytics'
import HelperAPI from '@utils/helpers/helper.api'
import HelperProfile from '@utils/helpers/helper.profile'
import { SUBSCRIPTION_ID_PACKAGES_LABELS } from './constants'
import {
  IPurchaseManagerPayment,
  ISubscriptions,
  StripeIntent,
  SubscriptionInfo,
  SubscriptionPackage,
} from './types'

export class PurchaseManagerWeb implements IPurchaseManagerPayment {
  private static instance: PurchaseManagerWeb
  private readonly userId: string | undefined

  public static getInstance(): PurchaseManagerWeb {
    if (!PurchaseManagerWeb.instance) {
      PurchaseManagerWeb.instance = new PurchaseManagerWeb()
    }
    return PurchaseManagerWeb.instance
  }

  private async revenueRestApi(
    method: string,
    path: string,
    body?: string | undefined
  ): Promise<Response> {
    const headers = new Headers()
    const url = `https://api.revenuecat.com/v1/${path}`
    headers.append('Content-Type', 'application/json')
    headers.append('X-Platform', 'stripe')
    headers.append('Authorization', `Bearer ${this.getApiKey()}`)
    const options = { method, body, headers }
    return await fetch(url, options)
  }

  private getUserId(): string | undefined {
    if (!this.userId) {
      return HelperProfile.getStoredProfile()?._id
    }
    return this.userId
  }

  private async getStripeIntent(packageId: string): Promise<
    | StripeIntent
    | {
        clientSecret: string | null | undefined
        subscriptionId: string | null | undefined
      }
  > {
    try {
      const response = await HelperAPI.ApiRequest(`/payments/create-payment`, {
        method: 'POST',
        body: JSON.stringify({
          packageId,
        }),
      })
      return response as {
        clientSecret: string | null | undefined
        subscriptionId: string | null | undefined
      }
    } catch (e) {
      console.error(e)
      return { clientSecret: null, subscriptionId: null }
    }
  }

  getApiKey(): string {
    return process.env.REACT_APP_REVENUECAT_STRIPE_API_KEY! // TODO: Needs error handling
  }

  async setup(userId?: string): Promise<void> {
    Purchases.setDebugLogsEnabled(process.env.REACT_APP_ENABLE_LOG === 'true')
    if (this.getUserId() || userId) {
      return Purchases.configureWith({
        apiKey: this.getApiKey(),
        appUserID: this.getUserId() || userId,
      })
    } else {
      return Purchases.configureWith({ apiKey: this.getApiKey() })
    }
  }

  async getCurrentPackages(): Promise<SubscriptionPackage[]> {
    const packages = await HelperAPI.ApiRequest<SubscriptionPackage[]>(
      '/products'
    )
    return packages
  }

  async getSubscriptionInfo(): Promise<SubscriptionInfo | null> {
    const id = await this.getUserId()

    if (!id) return null

    const response = await this.revenueRestApi('GET', `subscribers/${id}`)
    const data = await this.getSubscriptionFromBackend()

    if (response.status !== 200) return null

    const { subscriber } = await response.json()
    if (Object.keys(subscriber.entitlements).length === 0) return null

    const { 'all-access': allAccess, 'age-group-access': ageGroupAccess } =
      subscriber.entitlements
    const { subscriptions } = subscriber
    const identifier = allAccess
      ? allAccess.product_identifier
      : ageGroupAccess.product_identifier
    const activeSubscription = subscriptions[identifier]
    const { unsubscribe_detected_at: unsubscribeDetectedAt, store } =
      activeSubscription
    const label = SUBSCRIPTION_ID_PACKAGES_LABELS[identifier]

    const { expires_date: expiresDate, purchase_date: purchaseDate } =
      allAccess || ageGroupAccess

    if (new Date(expiresDate) < new Date()) return null

    const resp = {
      label,
      expiryDate: new Date(expiresDate),
      requestDate: new Date(purchaseDate),
      managementUrl: subscriber?.managementUrl,
      unsubscribeDetectedAt,
      accessType: data?.subscriptionType || 'allAccess',
      store,
    }
    return resp
  }

  async isHavingProAccess(contentLevel?: string): Promise<boolean> {
    const subInfo = await this.getSubscriptionInfo()
    const period = subInfo !== null && subInfo.requestDate <= subInfo.expiryDate
    if (subInfo?.accessType === 'allAccess' || !contentLevel) {
      return period
    }

    if (contentLevel === 'level-1') {
      return subInfo?.accessType === 'ageGroupOneAccess' && period
    }

    return subInfo?.accessType === 'ageGroupTwoAccess' && period
  }

  async updateRevenueCat(
    subscriptionId: string,
    userId: string
  ): Promise<void> {
    const response = await this.revenueRestApi(
      'POST',
      'receipts',
      JSON.stringify({
        fetch_token: subscriptionId,
        app_user_id: userId,
      })
    )
    if (!response.ok) {
      const isJson = response.headers
        .get('content-type')
        ?.includes('application/json')
      const data = isJson ? await response.json() : response.statusText
      throw new FetchError(response.status, data)
    }
  }

  async cancelSubscription(): Promise<string | null> {
    const URL = '/payments/cancel-subscription'
    const OPTIONS = { method: 'POST' }
    logEvent('cancelSubscription')

    try {
      const response = await HelperAPI.ApiRequest<string>(URL, OPTIONS)
      return response
    } catch (error) {
      logEvent('cancelSubscriptionError')
    }

    return null
  }

  async resumeSubscription(): Promise<string | null> {
    const URL = '/payments/resume-subscription'
    const OPTIONS = { method: 'POST' }
    logEvent('resumeSubscription')

    try {
      const response = await HelperAPI.ApiRequest<string>(URL, OPTIONS)
      return response
    } catch (error) {
      logEvent('resumeSubscriptionError')
    }

    return null
  }

  // @typescript-eslint/no-empty-function @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  revoke() {}

  /**
   * Only returns clientSecret and subscriptionId if the purchase is using stripe (default)
   * For other local payment like Fawry (Egypt) it will not return anything but change the db subscriptionPlatform === 'FAWRY'
   * Other web local payment can be added here
   */
  async makePurchase(
    packageId: string,
    userId: string | undefined,
    levelSubs?: string,
    localPayment?: 'FAWRY'
  ): Promise<StripeIntent> {
    const selectedPackages = await this.getCurrentPackages()
    const selectedPackage = selectedPackages.find(
      (subs) => subs.identifier === packageId
    )

    if (userId) {
      await this.updateSubscriptionBackend(
        selectedPackage.description.includes('12') ? 'yearly' : 'monthly',
        selectedPackage.type === 'allAccess'
          ? selectedPackage.type
          : levelSubs === 'level-1'
          ? 'ageGroupOneAccess'
          : 'ageGroupTwoAccess',
        localPayment
      )

      // if payment is using stripe and not country local payment
      if (!localPayment) {
        const { clientSecret, subscriptionId } = await this.getStripeIntent(
          packageId
        )

        return { clientSecret, subscriptionId }
      }
    }

    return { clientSecret: null, subscriptionId: null }
  }

  /**
   * @description Get Fawry checkout link (Egpyt local payment gateway)
   */
  async getFawryCheckoutLink(
    productId: string
    // levelSubs?: string,
    // description?: string
  ) {
    const { checkoutLink } = await HelperAPI.ApiRequest<{
      checkoutLink: string
    }>('/payments/initiate-fawry', {
      method: 'post',
      body: JSON.stringify({ productId }),
    })

    return checkoutLink
  }

  async updateSubscriptionBackend(
    subscriptionPeriod = 'monthly',
    subscriptionType = 'allAccess',
    subscriptionPlatform = 'STRIPE'
  ) {
    await HelperAPI.ApiRequest('/subscriptions/user', {
      method: 'POST',
      body: JSON.stringify({
        subscriptionPlatform: subscriptionPlatform ?? 'STRIPE',
        subscriptionPeriod,
        subscriptionType,
      }),
    })
  }

  async getSubscriptionFromBackend() {
    const data: ISubscriptions = await HelperAPI.ApiRequest(
      '/subscriptions/user'
    )
    return data
  }
}
