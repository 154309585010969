import React from 'react'

const BookIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <div className="book_icon-wrapper">
      <svg
        className="book_icon-svg"
        fill="none"
        height="9"
        viewBox="0 0 14 9"
        width="14"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path d="M1.78 5.743c-.003.033-.013.134.067.214.079.08.189.082.23.083h.054c.48.006 1.204.014 1.66.077.791.107 1.496.287 2.154.55.095.039.199.077.308.116.248.09.503.184.71.299.004.002.009.001.013.003.007.004.015.004.023.006.023.008.046.019.07.019l.005-.001h.003c.025 0 .048-.01.071-.018.008-.003.016-.002.023-.006.004-.002.01 0 .014-.003.205-.115.461-.208.709-.299.109-.04.213-.077.307-.115a8.985 8.985 0 012.154-.551c.458-.063 1.182-.071 1.662-.077h.053c.041-.001.152-.002.23-.083.08-.08.07-.181.067-.215-.053-.555-.104-1.11-.154-1.665L11.91.774a5.011 5.011 0 01-.031-.33c-.01-.199-.15-.327-.366-.336-1.276-.048-2.35.135-3.285.556-.44.198-.832.452-1.154.736A4.595 4.595 0 005.92.664C5.749.587 5.568.526 5.387.465l-.123 1.768a.194.194 0 01-.134.166.218.218 0 01-.22-.043l-.403-.365-.527.311a.223.223 0 01-.216.006.187.187 0 01-.104-.173l.086-2A9.138 9.138 0 002.635.108c-.215.008-.356.136-.365.336a5.29 5.29 0 01-.033.34L1.94 4.027a390.12 390.12 0 01-.159 1.716zm9.525-1.207c-.021.103-.132.169-.242.15A3.853 3.853 0 008.5 5.1a.22.22 0 01-.285-.064.181.181 0 01.07-.262 4.297 4.297 0 012.856-.46c.113.02.186.12.164.223zm-.129-1.3c-.022.103-.133.168-.243.15a3.853 3.853 0 00-2.561.413.22.22 0 01-.285-.065.181.181 0 01.07-.26 4.292 4.292 0 012.855-.461c.112.02.186.12.164.223zm-3.15-1.063a4.29 4.29 0 012.856-.46c.113.02.186.12.164.223-.021.103-.133.169-.242.15a3.85 3.85 0 00-2.562.413.223.223 0 01-.108.027.212.212 0 01-.178-.091.182.182 0 01.07-.262zm-4.888 2.14a4.287 4.287 0 012.855.46c.098.055.13.171.07.262a.212.212 0 01-.177.091.224.224 0 01-.108-.027 3.847 3.847 0 00-2.562-.413c-.109.02-.22-.047-.243-.15-.021-.103.052-.203.165-.223z" />
        <path d="M4.682 1.612l.198.18.102-1.45a6.867 6.867 0 00-.82-.161l-.07 1.603.331-.196a.222.222 0 01.259.024z" />
        <path d="M13.204 6.077l-.013-.135c-.076-.8-.15-1.6-.223-2.401l-.063-.693c-.032-.356-.065-.711-.094-1.066a.711.711 0 00-.609-.65l.27 2.925c.05.555.1 1.11.153 1.664a.475.475 0 01-.132.395.575.575 0 01-.414.16l-.06.001c-.441.006-1.182.014-1.625.074-.768.105-1.45.28-2.088.534-.096.04-.204.078-.316.119-.24.088-.484.177-.67.28l-.043.025-.073.014a.18.18 0 01-.066.02l-.01.004H7.022l-.012-.004a.475.475 0 01-.095-.027l-.042-.009-.042-.02c-.19-.107-.434-.196-.67-.282-.116-.042-.224-.081-.32-.12a8.747 8.747 0 00-2.088-.534c-.443-.06-1.183-.07-1.626-.074h-.052a.58.58 0 01-.42-.161.476.476 0 01-.133-.396c.054-.57.107-1.141.159-1.712l.263-2.861c-.33.066-.58.3-.607.635-.029.355-.062.71-.094 1.066l-.048.525a606.12 606.12 0 01-.237 2.569l-.013.135c-.024.24-.057.59-.057.656 0 .216.07.385.21.5.166.137.414.188.756.156.023 0 2.293-.162 3.67.578.344.607.851.902 1.551.903.7-.001 1.207-.296 1.576-.931 1.353-.712 3.622-.552 3.645-.55.343.032.59-.02.755-.156.14-.115.211-.284.211-.5a18.923 18.923 0 00-.058-.656z" />
      </svg>
    </div>
  )
}

export default BookIcon
