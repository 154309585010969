import React, { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'
import { IPublicationDetail } from '@src/types/api.interface'
import { ContentTitle } from '@components/ContentTitle'
import { AppBar, AppContent } from '@components/Layout'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import { usePublication } from '@hooks/data/fetchers/use-publication'
import Config from '@config/index'
import Helper from '@utils/Helpers'
const fAvatar = `${Config.publicURL}/images/avatars/f_avatar.png`
const mAvatar = `${Config.publicURL}/images/avatars/m_avatar.png`

const Publication: FC = () => {
  const { id } = useParams<{ id: string }>()

  const { isLoadingPublication, publication } = usePublication({ id })

  useEffect(() => {
    if (!publication?._id) return

    Helper.accessLog('publications', publication._id)
  }, [publication?._id])

  return (
    <>
      <Helmet>
        <title>عالم زيدو - النشر</title>
      </Helmet>
      {isLoadingPublication ? <AppProgressBar /> : null}
      <AppBar
        backButtonLocation="/gallery"
        fixed
        showBackButton
        showHomeLink
        showLogoutButton
        title="المعرض"
        titleCenter
      />
      <AppContent className="bg-[#ffe4b8] pages_publication_appcontent">
        <ContentTitle
          className=" pages_publication_contenttitle"
          noShadow
          textColor="text-[#4a4a4a]"
          title="المعرض"
        />
        {publication ? (
          <div className="pt-8 px-4 sm:px-16 flex items-center justify-center  pages_publication_container">
            <div className="max-w-xl pb-2  pages_publication_content">
              <PublicationContent publication={publication} />
              <div className="mt-4  pages_publication_description">
                <PublicationDescription publication={publication} />
              </div>
              <div className="mt-4  pages_publication_owner">
                <PublicationOwner publication={publication} />
              </div>
            </div>
          </div>
        ) : null}
      </AppContent>
    </>
  )
}

export default Publication

const PublicationOwner: FC<{ publication: IPublicationDetail }> = (props) => {
  const avatarImg =
    props.publication.kid.gender === 'female' ? fAvatar : mAvatar
  const displayName = props.publication.kid.nickname
    ? props.publication.kid.nickname
    : props.publication.kid.middleName
    ? `${props.publication.kid.firstName} ${props.publication.kid.middleName} ${props.publication.kid.lastName}`
    : `${props.publication.kid.firstName} ${props.publication.kid.lastName}`
  return (
    <div className="relative  pages_publication_owner_element py-1 border bg-[#907c28] text-center border-gray-500 rounded-full text-white text-lg">
      <span className=" pages_publication_owner_element_span">
        {displayName}
      </span>
      <img
        alt=""
        className="absolute  pages_publication_owner_element_img top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2 rounded-full inline-block w-12 h-12"
        src={avatarImg}
      />
    </div>
  )
}

const PublicationContent: FC<{ publication: IPublicationDetail }> = (props) => {
  return (
    <img
      alt=""
      className="mb-2 outline  pages_publication_content_element_img border-[5px] outline-[5px] border-[#713825] outline-[#874c38]"
      draggable="false"
      src={Helper.MediaURL(props.publication.content)}
    />
  )
}

const PublicationDescription: FC<{ publication: IPublicationDetail }> = (
  props
) => {
  return (
    <div className="publication-description  pages_publication_desc_element_text">
      {props.publication.description}
    </div>
  )
}
