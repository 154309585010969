import { useCallback, useEffect, useRef, FC } from 'react'
import { useHistory, useParams } from 'react-router'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import { AppBar } from '@components/Layout'
import { Helmet } from 'react-helmet-async'
import { GtagEvents } from '@utils/gtag-events'
const LoginByToken: FC = () => {
  const params = useParams<{ username: string; authToken: string }>()
  const history = useHistory()

  const paramsRef = useRef(params)

  const { logout, login } = useProfile()

  const quickAuth = useCallback(async () => {
    const params = paramsRef.current
    if (!params.username || !params.authToken) return

    logout()
    try {
      const profile = await login({
        authToken: params.authToken,
        username: params.username,
        isChild: true,
      })

      if (!profile || typeof profile === 'string') {
        history.replace('/login')
        return
      }

      GtagEvents.login({ level: profile.level, type: 'token' })
      history.push('/')
    } catch (error) {
      history.replace('/login')
    }
  }, [logout, login, history])

  useEffect(() => {
    quickAuth()
  }, [quickAuth])

  return (
    <>
      <Helmet>
        <title>عالم زيدو - تسجيل الدخول من لوحة القيادة</title>
      </Helmet>
      <AppProgressBar />
      <AppBar fixed title="" />
    </>
  )
}

export default LoginByToken
