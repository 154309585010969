import { FC } from 'react'

const LockIcon: FC<{ className?: string }> = (props) => {
  return (
    <div className={`lock_icon-container ${props?.className}`}>
      <svg
        className="lock_icon-svg"
        fill="none"
        height="35"
        viewBox="0 0 29 35"
        width="29"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M23.9156 9.8461V12.412C26.7978 13.3125 28.8984 15.9219 28.8984 19.0401V27.3018C28.8984 31.1615 25.6998 34.2898 21.7547 34.2898H7.59132C3.64123 34.2898 0.444336 31.1599 0.444336 27.3018V19.0384C0.444336 15.9219 2.54659 13.3125 5.42714 12.412V9.8461C5.44388 4.8566 9.57808 0.814453 14.6463 0.814453C19.783 0.814453 23.9156 4.8566 23.9156 9.8461ZM14.6797 3.72513C18.1327 3.72513 20.9396 6.47011 20.9396 9.8461V12.0521H8.4031V9.81263C8.41984 6.45338 11.2267 3.72513 14.6797 3.72513ZM16.1593 25.0088C16.158 25.2033 16.1182 25.3957 16.0421 25.5748C15.966 25.7539 15.8552 25.9161 15.7161 26.0521C15.577 26.1881 15.4123 26.2953 15.2315 26.3673C15.0508 26.4393 14.8575 26.4747 14.663 26.4716C14.4697 26.4732 14.2779 26.4365 14.0988 26.3638C13.9197 26.291 13.7567 26.1836 13.6192 26.0476C13.4817 25.9117 13.3725 25.75 13.2977 25.5717C13.2229 25.3934 13.184 25.2021 13.1834 25.0088V21.3164C13.1834 20.518 13.8462 19.8686 14.663 19.8686C15.4965 19.8686 16.1593 20.518 16.1593 21.3164V25.0088Z"
          fill="#4758C5"
          fillRule="evenodd"
        />
      </svg>
    </div>
  )
}

export default LockIcon
