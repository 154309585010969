import React, { useState, useEffect } from 'react'
import { getSafePercent, getStepPosition } from './utils'

interface ProgressBarProps {
  percent: number
  children: React.ReactNode
  stepPositions?: number[]
  unfilledBackground?: string
  filledBackground?: string
  width?: number
  height?: number
  hasStepZero?: boolean
  text?: string
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  percent,
  children,
  stepPositions = [],
  unfilledBackground = null,
  filledBackground = null,
  width = null,
  height = null,
  hasStepZero = true,
  text = null,
}) => {
  const [safePercent, setSafePercent] = useState<number>(0)

  useEffect(() => {
    setSafePercent(getSafePercent(percent))
  }, [percent])

  const startPointBackgroundColor =
    percent > 0 ? 'bg-[#263D9A]' : 'bg-[#B0B6BB]'
  const isCompleted = percent === 100

  return (
    <div
      className="RSPBprogressBar"
      style={{ background: unfilledBackground, width, height }}
    >
      <div className={`h-5 w-5 rounded-full ${startPointBackgroundColor}`} />

      {React.Children.map(children, (step, index) => {
        const position =
          stepPositions.length > 0
            ? stepPositions[index]
            : getStepPosition(
                React.Children.count(children),
                index,
                hasStepZero
              )

        return React.cloneElement(step as React.ReactElement, {
          accomplished: position <= safePercent,
          position,
          index,
        })
      })}

      {text ? <div className="RSPBprogressBarText">{text}</div> : null}

      <div
        className="RSPBprogression"
        style={{
          background: filledBackground,
          width: `${safePercent}%`,
        }}
      />

      {!isCompleted ? (
        <div className="h-5 w-5 rounded-full bg-[#B0B6BB]" />
      ) : null}
    </div>
  )
}

export default ProgressBar
