import { COPYRIGHT_MESSAGE, COPYRIGHT_SYMBOL } from '@src/constants/messages'
import { PRIVACY_POLICY_LINK, USAGE_POLICY_LINK } from '@src/constants/link'
import { ZIDO_TYPOGRAPHY } from '@src/services'
import { FC } from 'react'
import { Link } from 'react-router-dom'

const URL = '/curriculum'

const Footer: FC<{
  onClickHowItWorks: () => void
  className?: string
}> = ({ onClickHowItWorks, className = 'component_layout_footer_main' }) => {
  return (
    <div
      className={`${className}_container relative left-0 bottom-0 w-full mb-2 px-8`}
    >
      <hr
        className={`${className}_hr_child border-white border-dashed border-1 opacity-30`}
      />
      <footer
        className={`flex w-full text-white ${className}_footer_element_container`}
      >
        <div
          className={`flex-1 mr-2 flex gap-x-4 ${className}_footer_element_child`}
        >
          <Link
            className={`flex items-center justify-center gap-x-1 cursor-pointer ${className}_footer_element_link`}
            to={URL}
          >
            <svg
              className={`${className}_footer_element_svg_element`}
              fill="none"
              height="16"
              viewBox="0 0 42 38"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.7506 0.570312C41.77 0.570312 41.3457 12.3655 41.3457 19.1089C41.3457 25.8523 41.7728 37.6475 21.7506 37.6475H20.3267C0.304516 37.6446 0.731672 25.8523 0.731672 19.1089C0.731672 12.3655 0.304516 0.570312 20.3267 0.570312H21.7506Z"
                fill="#4FC4F9"
              />
              <path
                d="M28.3112 6.98633H16.1892C13.5223 6.98633 11.3403 9.1683 11.3403 11.8352V26.3816C11.3403 29.0485 13.5223 31.2305 16.1892 31.2305H28.3112C29.6447 31.2305 30.7356 30.1395 30.7356 28.8061V9.41074C30.7356 8.07731 29.6447 6.98633 28.3112 6.98633ZM18.6136 11.8352H23.4624C24.1897 11.8352 24.6746 12.32 24.6746 13.0474C24.6746 13.7747 24.1897 14.2596 23.4624 14.2596H18.6136C17.8863 14.2596 17.4014 13.7747 17.4014 13.0474C17.4014 12.32 17.8863 11.8352 18.6136 11.8352ZM28.3112 28.8061H16.1892C14.8557 28.8061 13.7647 27.7151 13.7647 26.3816C13.7647 25.0482 14.8557 23.9572 16.1892 23.9572H28.3112V28.8061Z"
                fill="white"
              />
            </svg>
            <p>{ZIDO_TYPOGRAPHY.APPROACH_TEXT}</p>
          </Link>
          <button
            className={`${className}_footer_element_botton_how_it_works flex justify-center items-center gap-x-1`}
            onClick={onClickHowItWorks}
            type="button"
          >
            <svg
              className={`${className}_footer_element_svg_how_it_works`}
              fill="none"
              height="16"
              viewBox="0 0 42 39"
              width="20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.5521 0.970703C41.5715 0.970703 41.1472 12.7659 41.1472 19.5093C41.1472 26.2526 41.5743 38.0479 21.5521 38.0479H20.1283C0.106029 38.045 0.533186 26.2526 0.533186 19.5093C0.533186 12.7659 0.106029 0.970703 20.1283 0.970703H21.5521Z"
                fill="#5774EA"
              />
              <path
                d="M14.9263 15.9612C16.239 15.9612 17.2918 14.9087 17.2918 13.5957C17.2918 11.644 18.8885 10.0474 20.8401 10.0474C22.8035 10.0474 24.3884 11.644 24.3884 13.5957C24.3884 15.5471 22.8152 17.1321 20.8401 17.144C19.4854 17.144 18.3632 18.2992 18.4864 19.7223C18.4864 19.7815 18.5101 21.875 18.5101 21.875C18.5101 23.1759 19.5744 24.2405 20.8756 24.2405C22.1766 24.2405 23.2411 23.1759 23.2411 21.875V21.532C26.7066 20.4909 29.1194 17.2978 29.1194 13.5957C29.1194 9.04197 25.4054 5.31641 20.8401 5.31641C16.2746 5.31641 12.5608 9.04197 12.5608 13.5957C12.5608 14.9087 13.6253 15.9612 14.9263 15.9612ZM20.8756 33.7026C22.8389 33.7026 24.4238 32.1176 24.4238 30.1543C24.4238 28.2026 22.8389 26.606 20.8756 26.606C18.924 26.606 17.3273 28.2026 17.3273 30.1543C17.3273 32.1176 18.924 33.7026 20.8756 33.7026Z"
                fill="white"
              />
            </svg>
            <p className={`${className}_footer_element_paragraph_how_it_works`}>
              {ZIDO_TYPOGRAPHY.HOW_IT_WORS_TEXT}
            </p>
          </button>
        </div>
        <div className={`${className}_footer_element_copyright_container`}>
          {COPYRIGHT_MESSAGE} {COPYRIGHT_SYMBOL} {new Date().getFullYear()}
        </div>
        <div
          className={`flex-1 ml-2 flex gap-x-4 justify-end ${className}_footer_element_link_privacy_container`}
        >
          <Link
            className={`${className}_footer_element_link_privacy_text`}
            target="_blank"
            to={{ pathname: `${PRIVACY_POLICY_LINK}` }}
          >
            <p>{ZIDO_TYPOGRAPHY.PRIVACY_TEXT}</p>
          </Link>
          <Link
            className={`${className}_footer_element_link_terms_text`}
            target="_blank"
            to={{ pathname: `${USAGE_POLICY_LINK}` }}
          >
            <p>{ZIDO_TYPOGRAPHY.TERMS_TEXT}</p>
          </Link>
        </div>
      </footer>
    </div>
  )
}

export default Footer
