import { FC } from 'react'
import VideoPlayer from '@components/videoplayer'
import { CircleLoader } from 'react-spinners'
import GoToGamesModal from '../GoToGamesModal'
import { ContentVideoProps } from './type'
import useContentVideo from './useContentVideo'

const ContentVideo: FC<ContentVideoProps> = (props) => {
  const {
    showModal,
    onVideoCompleted,
    isLoadingChapter,
    chapter,
    level,
    epiSlug,
    closeModal,
    handleParentClose,
    episode,
  } = useContentVideo(props)

  return (
    <div className={props.className}>
      {isLoadingChapter ? (
        <CircleLoader className={`${props.className}_circle_loader`} />
      ) : (
        <VideoPlayer
          className={`${props.className}_video_player_container`}
          episode={episode}
          from="content-modal"
          index={props.index}
          onEnd={onVideoCompleted}
          slug={chapter.slug}
          src={chapter.url}
        />
      )}
      {showModal && level ? (
        <GoToGamesModal
          className={`${props.className}_video_goto_game_model_container`}
          epiSlug={epiSlug}
          level={level.toString()}
          onClose={closeModal}
          onCloseParent={handleParentClose}
        />
      ) : null}
    </div>
  )
}

export default ContentVideo
