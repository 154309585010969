import React, { FC } from 'react'

const EmptyAvatarIcon: FC<{
  classNames?: string
  fill1?: string
  fill2?: string
}> = ({ classNames, fill1, fill2 }) => {
  return (
    <svg
      className={`${classNames} w-[30px] empty_avatar_icon-svg`}
      fill={fill1 || 'none'}
      height="47"
      viewBox="0 0 38 47"
      width="38"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.058 24.6781C30.5712 22.4945 33.6352 18.2293 33.6352 13.3269C33.6352 6.20205 27.1607 0.424316 19.1785 0.424316C11.1963 0.424316 4.71692 6.20205 4.71692 13.3269C4.71692 18.2487 7.80538 22.5236 12.3478 24.7024C7.70292 26.3414 3.79477 29.6096 1.83826 34.0402C0.101304 37.9698 0.111062 42.342 1.8529 46.2668L36.5237 46.2279C40.2659 37.751 35.5381 28.0339 26.0532 24.6733L26.058 24.6781Z"
        fill={fill2 || '#4758C5'}
      />
    </svg>
  )
}

export default EmptyAvatarIcon
