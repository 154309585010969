import { FetchError } from '@utils/FetchError'
import useSwr from 'swr'

interface ILatestSeriesEpisode {
  latestSeriesId: string
  latestEpisodeId: string
  latestSeriesOrder: number
  latestEpisodeOrder: number
  cycleMoreThanTotalEpisodes: boolean
}

export function useLatestSeriesEpisode(kidId: string): {
  latestSeriesEpisode?: ILatestSeriesEpisode
  error?: FetchError
  isLoadingLatestSeriesEpisodes: boolean
} {
  const url = `/series/latest/series-episode/${kidId}`

  const { data, error } = useSwr<ILatestSeriesEpisode, FetchError>(url)

  return {
    latestSeriesEpisode: data,
    error,
    isLoadingLatestSeriesEpisodes: !error && !data,
  }
}
