import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import { analytics, firebaseApp } from './firebase'

export const initialize = async () => {
  const promise = await analytics(firebaseApp())
  return promise
}
export const getCurrentUser = async () => {
  const result = await FirebaseAuthentication.getCurrentUser()
  return result.user
}
export const getIdToken = async () => {
  const currentUser = getCurrentUser()
  if (!currentUser) {
    return
  }
  const result = await FirebaseAuthentication.getIdToken()
  return result.token
}
export const setLanguageCode = async (languageCode = 'en-US') => {
  await FirebaseAuthentication.setLanguageCode({ languageCode })
}
export const signInWithFacebook = async () => {
  const result = await FirebaseAuthentication.signInWithFacebook()
  return result
}
export const signInWithGoogle = async () => {
  const result = await FirebaseAuthentication.signInWithGoogle()
  return result
}
export const signInWithApple = async () => {
  const result = await FirebaseAuthentication.signInWithApple()
  return result
}
export const signOut = async () => {
  await FirebaseAuthentication.signOut()
}
