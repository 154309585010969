import { Capacitor } from '@capacitor/core'
import { ContentTitle } from '@components/ContentTitle'
import { GameIFrame } from '@components/GameIframe'
import { AppBar, AppContent } from '@components/Layout'
import Popup from '@components/Popup'
import Config from '@config/index'
import { useActiveChallenge } from '@hooks/data/fetchers/competition/useActiveChallenge'
import {
  useSubmitParticipation,
  useUpdateParticipation,
} from '@hooks/data/fetchers/competition/useParticipation'
import { useJwtToken } from '@hooks/data/storage/use-jwt-token'
import useIFrameFullscreenChange from '@hooks/utils/useIFrameIsFullscreen'
import FullScreenIcon from '@resources/images/v3/btn-fullscreen.svg'
import { COMPETITION_IFRAME_ID } from '@utils/constants'
import { closeFullscreen, requestFullScreen } from '@utils/fullscreen'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import SVG from 'react-inlinesvg'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { CURRENT_COMPETITION_TYPOGRAPHY } from './constants'

const ChallengeGame: FC = () => {
  const [score, setScore] = useState<number>()
  const token = useJwtToken()
  const { id } = useParams<{ id: string }>()
  const { state } = useLocation<{
    url: string
    title: string
    isHideScore?: boolean
  }>()
  const history = useHistory()

  const submitParticipation = useSubmitParticipation(id)
  const updateParticipation = useUpdateParticipation()
  const { activeChallenge } = useActiveChallenge()
  const { isFullScreen } = useIFrameFullscreenChange()
  const [iframeFullScreen, setIframeFullScreen] = useState(false)

  const isIOS = Capacitor.getPlatform() === 'ios'
  const exitfullscreenSvg = `${Config.publicURL}/images/exitfullscreen_button.svg`

  useEffect(() => {
    const updateIsParticipatedAndScore = async () => {
      await submitParticipation()
      if (id === activeChallenge?.[0]?._id) {
        await updateParticipation({ score, challengeId: id })
      }
    }

    if (id && score !== undefined) {
      updateIsParticipatedAndScore()
    }
  }, [activeChallenge, id, score, submitParticipation, updateParticipation])

  const onClose = () => {
    history.goBack()
  }

  useEffect(() => {
    function onMessage(message: MessageEvent<{ score: number }>) {
      if (!state?.url?.startsWith(message.origin)) {
        return
      }

      if ('score' in message.data) {
        const score = Number(message.data.score)
        if (isNaN(score)) return

        setScore(score)
      }
    }

    window.addEventListener('message', onMessage)

    return () => {
      window.removeEventListener('message', onMessage)
    }
  }, [state?.url])

  const iframeProps = {
    // eslint-disable-next-line no-useless-computed-key
    ['data-src']: state?.url,
    src: 'about:blank',
    frameBorder: '0',
    allowFullScreen: true,
    width: '100%',
    height: '100%',
    sandbox: 'allow-same-origin allow-scripts',
    referrerpolicy: 'no-referrer',
    credentialless: 'true',
  }

  const {
    WEBSITE_TITLE,
    TITLE,
    MODAL_TITLE,
    MODAL_MESSAGE,
    MODAL_MESSAGE_2,
    MODAL_POINT,
    ACCEPT,
  } = CURRENT_COMPETITION_TYPOGRAPHY

  const title = state?.title || ''
  const message = state?.isHideScore ? (
    <span className="challenge_game_page-is-hide-score-message-wrapper">
      <p className="challenge_game_page-is-hide-score-message">{`${MODAL_MESSAGE} ${title}`}</p>
    </span>
  ) : (
    <span className="challenge_game_page-is-not-hide-score-message-wrapper">
      <p className="challenge_game_page-is-not-hide-score-message">{`${MODAL_MESSAGE} ${title} ${MODAL_MESSAGE_2}`}</p>
      <p className="challenge_game_page-score-text">{`${score} ${MODAL_POINT}`}</p>
    </span>
  )

  const getIcon = () => {
    if (isIOS) {
      return isFullScreen || iframeFullScreen
        ? exitfullscreenSvg
        : FullScreenIcon
    } else {
      return isFullScreen ? exitfullscreenSvg : FullScreenIcon
    }
  }

  return (
    <>
      <Helmet>
        <title className="challenge_game_page-main-title">
          {WEBSITE_TITLE}
        </title>
      </Helmet>

      <AppBar
        backButtonLocation="/competition"
        fixed
        hideToggleButton
        showKidAvatar
        showParentBackButton
        title={state.title || TITLE}
      />
      <AppContent className="flex flex-col items-center bg-no-repeat bg-cover bg-center bg-green-v3">
        <ContentTitle notBold title={state.title || TITLE} />
        <div
          className={`challenge_game_page-iframe-full-screen-wrapper ${
            iframeFullScreen && isIOS
              ? 'h-full w-full absolute top-0 left-0 z-[100]'
              : 'aspect-video w-7/12 mt-4 lg:mt-12 bg-transparent rounded-3xl overflow-hidden'
          }`}
        >
          <GameIFrame
            apiUrl={Config.apiURL}
            episode={{
              type: 'gameChallenge',
              slug: id,
            }}
            from="challengeGame"
            gameId={id}
            iframeContainerId={COMPETITION_IFRAME_ID}
            iframeProps={iframeProps}
            index={1}
            token={token}
          />

          <div className="challenge_game_page-full-screen-button-wrapper fixed w-28 bottom-4 left-4 z-[102] pointer-events-auto">
            <button
              aria-label="show full screen"
              className="challenge_game_page-full-screen-button"
              onClick={(e) => {
                e.stopPropagation()
                if (isFullScreen) {
                  closeFullscreen()
                } else {
                  requestFullScreen(COMPETITION_IFRAME_ID)
                }
                setIframeFullScreen(!iframeFullScreen)
              }}
              type="button"
            >
              <SVG
                className="challenge_game_page-full-screen-svg-icon h-8 w-h-8 lg:w-16 lg:h-16"
                src={getIcon()}
                width="100%"
              />
            </button>
          </div>
        </div>

        <Popup
          buttonOnClick={onClose}
          buttonText={ACCEPT}
          competitionPopup
          message={message}
          onClose={onClose}
          title={MODAL_TITLE}
          visible={score !== undefined}
        />
      </AppContent>
    </>
  )
}

export default ChallengeGame
