import React, { FC } from 'react'
import { IActivityDetail } from '@src/types/api.interface'
import Config from '@config/index'
import { useJwtToken } from '@hooks/data/storage/use-jwt-token'
import Helper from '@utils/Helpers'
import { GameIFrame } from './GameIframe'

export const ActivityContent: FC<{
  iframeContainerId: string
  activity: IActivityDetail
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  episode?: any
  index?: number
}> = (props) => {
  const token = useJwtToken()

  const cacheKey = getQueryParamForCache(props.activity)

  return (
    <GameIFrame
      apiUrl={Config.apiURL}
      episode={props.episode || {}}
      from="activityDetail"
      gameId={props.activity._id}
      iframeContainerId={props.iframeContainerId}
      iframeProps={{
        src: `${Helper.MediaURL(props.activity.content)}?&${cacheKey}`,
        frameBorder: '0',
        allowFullScreen: true,
        width: '100%',
        height: '100%',
      }}
      index={props.index}
      token={token}
    />
  )
}

const now = Date.now()
function getQueryParamForCache(activity: IActivityDetail): string {
  if (activity.updatedAt) return activity.updatedAt
  return `${now}`
}
