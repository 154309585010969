import { Capacitor } from '@capacitor/core'
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
  FC,
} from 'react'
import { Link } from 'react-router-dom'
import videojs from 'video.js'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import './style.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowRight,
  faRepeat,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons'
import { GtagEvents } from '@utils/gtag-events'
import HelperJWT from '@utils/helpers/helper.jwt'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
;(window as any).videojs = videojs as any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
;(window.videojs as any).Vhs.xhr.beforeRequest = function (options: any) {
  if (options.uri.endsWith('streaming')) {
    if (!options.headers) options.headers = {}
    options.headers.Authorization = `Bearer ${HelperJWT.getJwtToken()}`
  }
  return options
}

const VideoPlayer: FC<{
  index?: number
  from?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  episode?: any
  src: string
  onEnd?: () => void
  next?: string | null
  prev?: string | null
  slug?: string
  autoplay?: boolean
  playingVideo?: boolean
  thumbnail?: string
  className?: string
}> = (props) => {
  const { kidProfile: profile } = useProfile()
  const [showButtons, setShowButtons] = useState(false)
  const videoRef = useRef<HTMLVideoElement>(null)
  const playerRef = useRef<videojs.Player>()

  const startEventSlug = useRef<string>()

  if (!props.playingVideo) {
    videoRef?.current?.pause()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const timer = useRef(null)
  const handlePlay = useCallback(() => {
    // console.log('the video is played from page', props.from)
    clearTimeout(timer.current)
    if (props.from === 'home') {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timer.current = setTimeout(() => {
        // console.log(props)
        GtagEvents.startVideo({
          slug: '',
          level: 1,
          from: 'home',
        })
      }, 1000)
    }
    if (!profile?.level) return
    setShowButtons(false)

    if (!props?.slug) return

    if (startEventSlug.current !== props?.slug) {
      startEventSlug.current = props?.slug
      clearTimeout(timer.current)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timer.current = setTimeout(() => {
        // console.log({ episode: props.episode })
        // console.log('the video event was triggered from page', props.from)
        GtagEvents.startVideo({
          level: profile?.level,
          slug: props?.slug,
          episode: props?.episode,
          key: props?.index,
          from: props.from,
        })
      }, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  const onEnd = props?.onEnd
  const handleEnded = useCallback(() => {
    // console.log('the video is ended from page', props.from)
    if (props.from === 'home') {
      GtagEvents.finishVideo({
        level: 1,
        from: props.from,
        slug: '',
        type: 'end',
      })
    }
    if (!profile?.level) return
    onEnd()
    setShowButtons(true)

    if (!props?.slug) return

    clearTimeout(timer.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timer.current = setTimeout(() => {
      // console.log('the video end event was triggered from page', props.from)
      GtagEvents.finishVideo({
        level: profile?.level,
        slug: props?.slug,
        episode: props?.episode,
        key: props?.index,
        type: 'end',
        from: props.from,
      })
    }, 1000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onEnd, props])

  const play = useCallback(() => {
    playerRef.current?.play()
  }, [])

  useEffect(() => {
    const video = videoRef.current

    if (!video) return

    const initializePlayer = () => {
      videojs(video, {
        autoplay: props?.autoplay ?? true,
        controls: true,
        fill: true,
        poster: props?.thumbnail,
        controlBar: {
          playToggle: true,
          durationDisplay: true,
          progressControl: true,
          remainingTimeDisplay: false,
          volumePanel: !Capacitor.isNativePlatform(),
          fullscreenToggle: true,
        },
        bigPlayButton: true,
        sources: [{ src: props.src }],
      }).ready(function () {
        playerRef.current = this
        this.on('play', handlePlay)
        this.on('ended', handleEnded)
        this.on('contextmenu', function (e) {
          e.preventDefault()
        })
      })
    }

    if (playerRef.current) {
      // Check if the poster has changed and update it
      if (playerRef.current.poster() !== props?.thumbnail) {
        playerRef.current.poster(props?.thumbnail)
      }

      // Check if the video source has changed and update it
      if (playerRef.current.currentSrc() !== props.src) {
        playerRef.current.src([
          // { src: props.src, type: 'application/x-mpegURL' },
          { src: props.src },
        ])
      }

      // Remove and re-add event listeners
      // playerRef.current.off('ended')
      playerRef.current.on('ended', handleEnded)
      // playerRef.current.off('play')
      playerRef.current.on('play', handlePlay)
    } else {
      // Create Video.js player instance
      initializePlayer()
    }
  }, [props.src, handlePlay, handleEnded, props?.autoplay, props?.thumbnail])

  useEffect(() => {
    return () => {
      const player = playerRef.current
      if (player) {
        GtagEvents.finishVideo({
          level: profile?.level,
          slug: props?.slug,
          episode: props?.episode,
          key: props?.index,
          type: 'close',
          from: props.from,
        })
        player.dispose()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // in level 3, when the video finishes, we show the game straigh away, kid cannot move to next video if he does not answer the questions successfully
  const notLevel3 = profile && profile.level !== 3
  const hasNextButton = notLevel3 && props.next
  const hasReplayButton = notLevel3
  const hasPrevButton = notLevel3 && props.prev

  return (
    <div className={props.className} data-vjs-player>
      <video
        className={`${props.className}_video_tag vjs-matrix video-js vjs-big-play-centered vjs-fill`}
        ref={videoRef}
      />
      <div
        className={`${props.className}_flex_video_button_container flex container__video-buttons`}
      >
        {hasNextButton ? (
          <VideoNavButton
            className={`${props.className}_flex_video_next_button`}
            href={props.next}
            icon={<FontAwesomeIcon icon={faArrowRight} />}
            visible={showButtons}
          />
        ) : null}
        {hasReplayButton ? (
          <VideoNavButton
            className={`${props.className}_flex_video_reply_button`}
            href="#replay"
            icon={<FontAwesomeIcon icon={faRepeat} />}
            onClick={play}
            visible={showButtons}
          />
        ) : null}

        {hasPrevButton ? (
          <VideoNavButton
            className={`${props.className}_flex_video_prev_button`}
            href={props.prev ?? ''}
            icon={<FontAwesomeIcon icon={faArrowLeft} />}
            visible={showButtons}
          />
        ) : null}
      </div>
    </div>
  )
}

export default VideoPlayer

const VideoNavButton: FC<{
  icon: ReactElement
  href?: string | null
  visible: boolean
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  className?: string
}> = (props) => {
  return (
    <Link
      className={`
      ${props.className}
      ${
        props.visible
          ? 'video-button mx-2 text-3xl sm:text-4xl inline-flex items-center justify-center'
          : 'vjs-big-prev-link vjs-hidden'
      }`}
      onClick={props.onClick}
      replace
      to={props.href ?? ''}
    >
      {props.icon}
    </Link>
  )
}
