import { Capacitor } from '@capacitor/core'
import { ContentTitle } from '@components/ContentTitle'
import { AppBar, AppContent } from '@components/Layout'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import Config from '@config/index'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { useCompetitionRank } from '@hooks/data/fetchers/competition/use-competition-rank'
import { useMobileView } from '@hooks/useMobileView'
import { IChallengeNew, IChallengeRankData } from '@src/types/api.interface'
import HelperProfile from '@utils/helpers/helper.profile'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import SVG from 'react-inlinesvg'
import { useHistory, useParams } from 'react-router-dom'
import { ListComponent } from './components/ListComponent'
import { TYPOGRAPHY } from './constants'

export const topAngle = `${Config.publicURL}/images/competition/frame-bg.svg`
export const boyAvatar = `${Config.publicURL}/images/competition/boy-avatar.svg`
export const girlAvatar = `${Config.publicURL}/images/competition/girl-avatar.svg`
export const starCircle = `${Config.publicURL}/images/competition/star-circle.svg`
export const starCircleFor2ndPos = `${Config.publicURL}/images/competition/star-circle-2.svg`
export const starCircleFor3rdPos = `${Config.publicURL}/images/competition/star-circle-3.svg`
export const pointIcon = `${Config.publicURL}/images/competition/point-icon.svg`

const {
  COMPETITION_TEXT,
  TODAY_TEXT,
  EMPTY_MESSAGE,
  TOP_COMPETITOR_TITLE,
  BE_TOP_COMPETITOR,
} = TYPOGRAPHY

export interface TabProps {
  title: string
  type: string
  active: boolean
}

const characterSVG = `${Config.publicURL}/images/v3/pencil-character.svg`
const isNativePlatform = Capacitor.isNativePlatform()

const uniqueKids = (allKids: IChallengeNew[]) => {
  if (allKids.length >= 1) {
    const ids = Array.from(new Set(allKids.filter((e) => e).map((e) => e?.id)))
    return ids.map((e: string) => {
      return allKids.find((s) => s.id === e)
    })
  }
  return allKids
}

const TopCompetitor: FC = () => {
  const history = useHistory()
  const isMobileView = useMobileView()
  const { id: challengeId } = useParams<{ id: string }>()
  const [activeTab, setActiveTab] = useState<number>(challengeId ? 0 : 1)
  const { kidProfile: profile } = useProfile()
  const [kids, setKids] = useState<IChallengeNew[]>([])
  const [pageNumber, setPageNumber] = useState(1)
  const [nextPageNumber, setNextPageNumber] = useState(1)
  const [mutate, setMutate] = useState(false)

  const challengeTab = {
    title: TODAY_TEXT,
    type: 'challenge',
    active: activeTab === 0,
  }

  const competitionTab = {
    title: COMPETITION_TEXT,
    type: 'competition',
    active: activeTab === 1,
  }

  const defaultTabs = challengeId
    ? [challengeTab, competitionTab]
    : [competitionTab]

  const [tabs, setTabs] = useState<TabProps[]>(defaultTabs)

  const kidFromStorage = HelperProfile.getStoredKid() ?? profile
  const kidId = kidFromStorage._id
  if (!kidId) {
    history.push('/login')
  }

  const handleActiveTab = (idx: number) => {
    setTabs((prevTabs) =>
      prevTabs.map((tab, index) => ({
        ...tab,
        active: index === idx,
      }))
    )
    setActiveTab(idx)
    setNextPageNumber(1)
    setPageNumber(1)
    setMutate(false)
  }

  const {
    data: kidsData = {
      user: [],
      items: [],
      pages: 0,
    } as IChallengeRankData,
    isLoadingKids,
  } = useCompetitionRank(
    activeTab === 0 ? 'challenge' : 'competition',
    kidId,
    challengeId,
    pageNumber
  )

  useEffect(() => {
    const { user = [], items = [], pages = 0 } = kidsData
    const userKidInfo = user?.[0]?.kidInfo
    const kidInfo = {
      score: user?.[0]?.challenge?.score,
      gender: userKidInfo?.gender,
      nickname:
        userKidInfo?.nickname ||
        userKidInfo?.firstName ||
        userKidInfo?.middleName ||
        userKidInfo?.lastName ||
        userKidInfo?.username,
      id: activeTab === 1 ? userKidInfo?._id : userKidInfo?.id,
      rank: user?.[0]?.challenge?.rank,
    }

    const data = items?.map((item) => ({
      score: item?.challenge?.score,
      gender: item?.kidInfo?.gender,
      nickname:
        item?.kidInfo?.nickname ||
        item?.kidInfo?.firstName ||
        item?.kidInfo?.middleName ||
        item?.kidInfo?.lastName ||
        item?.kidInfo?.username,
      id: activeTab === 1 ? item?.kidInfo?._id : item?.kidInfo?.id,
      rank: item?.challenge?.rank,
    }))

    if (mutate) {
      const allKids = [...kids, ...data].sort((a, b) => a.rank - b.rank)
      setKids(uniqueKids(allKids))
      if (pages !== nextPageNumber) {
        setNextPageNumber(pageNumber)
      }
    } else {
      if (userKidInfo || data?.length >= 1) {
        const allKids = kidInfo?.id ? [...data, kidInfo] : data
        const sortedKids = allKids.sort((a, b) => a.rank - b.rank)
        setKids(uniqueKids(sortedKids))
      }
    }

    if (pages) {
      setNextPageNumber(pages)
    }

    if (pages !== pageNumber) {
      setNextPageNumber(pages)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kidsData, activeTab, mutate])

  const loadMoreData = () => {
    setMutate(true)
    if (pageNumber + 1 <= kidsData.pages) {
      setPageNumber(pageNumber + 1)
    }
  }

  const isKidParticipated = !!kidsData?.user?.length

  return (
    <>
      <Helmet>
        <title className="top_competitor_page-main-title">
          عالم زيدو - الأوائل
        </title>
      </Helmet>
      {isLoadingKids ? <AppProgressBar /> : null}
      <AppBar
        backButtonLocation="/competition"
        fixed
        hideToggleButton={true}
        showKidAvatar={!isMobileView ? !isNativePlatform : null}
        showParentBackButton
        title="المتصدرين"
      />
      <AppContent className="bg-center bg-green-v3  overflow-y-scroll h-screen">
        <ContentTitle
          className="w-full competition-container:w-1/2 lg:w-full"
          notBold
          textClassname="flex justify-center mb-6 text-white text-center"
          title={TOP_COMPETITOR_TITLE}
        />

        <div className="top_competitor_page-container relative w-full mx-auto max-w-[2000px] h-full flex flex-col competition-container:flex-row gap-x-9 xl:gap-x-6 justify-center">
          {kids?.length === 0 && !isLoadingKids ? (
            <div className="top_competitor_page-kids-wrapper h-[80vh] flex flex-col items-center justify-center w-full">
              <SVG
                className="top_competitor_page-character-svg z-10 h-48 lg:h-96 w-full"
                src={characterSVG}
              />
              <div className="top_competitor_page-title-wrapper self-center bg-[#05602E] py-3 lg:pb-2 lg:pt-1 px-12 rounded-3xl border-4 border-[#A8C64D] w-9/12 xl:w-6/12">
                <h1 className="top_competitor_page-title text-2xl lg:text-4xl text-center text-white">
                  {challengeId ? BE_TOP_COMPETITOR : EMPTY_MESSAGE}
                </h1>
              </div>
            </div>
          ) : (
            <ListComponent
              isParticipated={isKidParticipated}
              isShowLoadMore={pageNumber !== nextPageNumber}
              kidId={kidId}
              kids={kids}
              loadMoreData={loadMoreData}
              setActiveTab={handleActiveTab}
              tabs={tabs}
            />
          )}
        </div>
      </AppContent>
    </>
  )
}

export default TopCompetitor
