import { useCallback, useEffect, useState } from 'react'
import HelperAPI from '@utils/helpers/helper.api'
export interface Data {
  _id: string
  title: string
  level: string
  description: string
  minAge?: string
  maxAge?: string
}
export function usePaths() {
  const [dataBody, setDataBody] = useState<[Data]>()

  const callApi = useCallback(async () => {
    const response = await HelperAPI.ApiRequest<[Data]>('/paths', {
      method: 'GET',
    })
    setDataBody(response)
  }, [])

  useEffect(() => {
    callApi()
  }, [callApi])

  return {
    dataBody: dataBody || [],
    setDataBody,
  }
}
