/*
  A list of product IDs mapped with the human-friendly labels.
  RevenueCat API returns only these product identifiers,
  Use this to convert to the real labels for the UI.
 */
export const SUBSCRIPTION_ID_LABELS: { [key: string]: string } = {
  zido_1month_sub_base: '1 شهر',
  zido_3month_sub_base: '3 شهور',
  zido_1year_sub_base: '12 شهر',
  prod_MqVk7XXZ7zn87j: '1 شهر',
  prod_MqVdhf6BD0etcJ: '3 شهور',
  prod_MqVlTGO6BwNjuo: '12 شهر',
  prod_NgduvXaEONEYKL: '1 شهر',
  prod_NgdvVylavsTbm2: '3 شهور',
  prod_NgduqmqVK0bjgw: '12 شهر',
  prod_PLaZoDOPAzq9px: '1 شهر',
  prod_PLaYukAcUno9xd: '12 شهر',
}

export const SUBSCRIPTION_ID_PACKAGES_LABELS: { [key: string]: string } = {
  zido_1month_sub_base: 'الباقة الشهرية',
  zido_3month_sub_base: 'الباقة الربع سنوية',
  zido_1year_sub_base: 'الباقة السنوية',
  prod_MqVk7XXZ7zn87j: 'الباقة الشهرية',
  prod_MqVdhf6BD0etcJ: 'الباقة الربع سنوية',
  prod_MqVlTGO6BwNjuo: 'الباقة السنوية',
  prod_NgduvXaEONEYKL: 'الباقة الشهرية',
  prod_NgdvVylavsTbm2: 'الباقة الربع سنوية',
  prod_NgduqmqVK0bjgw: 'الباقة السنوية',
  prod_PLaZoDOPAzq9px: 'الباقة الشهرية', // monthly,
  prod_PLaYukAcUno9xd: 'الباقة السنوية', // yearly,
  'zido_1year_sub_base:zido-1year-sub-std': 'الباقة السنوية',
  'zido_1month_sub_base:zido-1month-sub-std': 'الباقة الشهرية',
}

export const PURCHASES_ERROR_CODES: { [key: string]: string } = {
  CANCELLED: 'PurchaseCancelledError',
  IN_PROGRESS: 'OPERATION_ALREADY_IN_PROGRESS_FOR_PRODUCT_ERROR',
  NO_SELECTION: 'package_selected_none',
}

/*
    A list of package type enums mapped with the human-friendly labels.
    RevenueCat API uses this package types for listing products
   */
export const PACKAGE_TYPE_LABELS = {
  MONTHLY: '1 شهر',
  THREE_MONTH: '3 شهور',
  ANNUAL: '12 شهر',
  UNKNOWN: '',
  CUSTOM: '',
  LIFETIME: '',
  SIX_MONTH: '',
  TWO_MONTH: '',
  WEEKLY: '',
}

export const PACKAGE_TYPE_TITLES = {
  ANNUAL: 'سنوي',
  THREE_MONTH: 'ربع سنوي',
  MONTHLY: 'شهري',
  UNKNOWN: '',
  CUSTOM: '',
  LIFETIME: '',
  SIX_MONTH: '',
  TWO_MONTH: '',
  WEEKLY: '',
}

export const PACKAGE_TYPE_SAVES = {
  ANNUAL: '+ 50% توفير',
  THREE_MONTH: '+ 20% توفير',
  MONTHLY: 'EMPTY',
  UNKNOWN: '',
  CUSTOM: '',
  LIFETIME: '',
  SIX_MONTH: '',
  TWO_MONTH: '',
  WEEKLY: '',
}

export const ZIDO_TYPOGRAPHY = {
  SUBSCRIBE_TEXT: 'الاشتراك',
  SETTINGS_TEXT: 'إعدادات الحساب',
  NEW_NOTIFICATION_TEXT: 'جديدة',
  OLD_NOTIFICATION_TEXT: 'الأقدم',
  NO_NOTIFICATION_TEXT: 'لا يوجد لديك إشعارات',
  MARK_READ: 'تمميز الكل كمقروء',
  PRIVACY_TEXT: 'سياسة الخصوصية',
  TERMS_TEXT: 'الشروط والأحكام',
  APPROACH_TEXT: 'منهجنا',
  HOW_IT_WORS_TEXT: 'كيف يعمل عالم زيدو',
  COUNTS: 'اشعارات',
}
