import { Capacitor } from '@capacitor/core'
import { useEffect, useState } from 'react'

export function useScreenLandscape() {
  useEffect(() => {
    if (
      Capacitor.isNativePlatform() &&
      window &&
      window.hasOwnProperty('screen')
    ) {
      try {
        window.screen.orientation.lock('landscape')
      } catch (error) {
        // window.screen.orientation.lock may not be supported, not bad
        console.error(error)
      }
    }
  }, [])
}

const getOrientation = (): 'portrait' | 'landscape' => {
  let orientation: 'portrait' | 'landscape' = 'portrait'
  try {
    if (window?.hasOwnProperty('matchMedia')) {
      orientation = window.matchMedia('(orientation: portrait)').matches
        ? 'portrait'
        : 'landscape'
    } else {
      orientation = 'portrait'
    }
  } catch (e) {
    orientation = 'portrait'
  }
  return orientation
}

export function useScreenPortrait() {
  const [isPortrait, setIsPortrait] = useState<boolean>(
    getOrientation() === 'portrait'
  )

  useEffect(() => {
    const isNative = Capacitor.isNativePlatform()
    if (isNative) {
      setIsPortrait(false)
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleOrientationChange = (_event: any) => {
      const screenWidth = window.innerWidth
      const screenHeight = window.innerHeight
      const aspectRatio = screenWidth / screenHeight
      const landscape =
        aspectRatio > 0.98 || (screenHeight >= 700 && screenWidth >= 700)

      const isPortraitMedia = !landscape

      // // validation
      // console.log({
      //   isPortraitMedia,
      //   aspectRatio,
      //   portrait: window.matchMedia('(orientation: portrait)').matches,
      // })

      setIsPortrait(
        isPortraitMedia || window.matchMedia('(orientation: portrait)').matches
      )
    }

    const orientationMediaQuery = window.matchMedia('(orientation: portrait)')
    window.addEventListener('resize', handleOrientationChange)
    window.addEventListener('orientationchange', handleOrientationChange)
    if (orientationMediaQuery.addEventListener) {
      orientationMediaQuery.addEventListener('change', handleOrientationChange)
    }
    handleOrientationChange(orientationMediaQuery) // first invoke
    return () => {
      window.removeEventListener('resize', handleOrientationChange)
      window.removeEventListener('orientationchange', handleOrientationChange)
      if (orientationMediaQuery.removeEventListener) {
        orientationMediaQuery.removeEventListener(
          'change',
          handleOrientationChange
        )
      }
    }
  }, [])

  return isPortrait
}
