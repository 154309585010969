import Config from '@config/index'
import { ParentProfile, Profile } from '@src/types/api.interface'
import SecureLocalStorage from 'react-secure-storage'
import * as Sentry from '@sentry/react'
import Cookies from 'js-cookie'

const {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  localStorage: { whoamiIndex, activeKidIndex },
} = Config

const HelperProfile = {
  getStoredProfile(): ParentProfile | undefined {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const me: any = SecureLocalStorage.getItem(whoamiIndex)
      if (!me) return
      return JSON.parse(me)
    } catch (error) {
      console.error(error)
      Sentry.captureException(error)
    }
  },
  setProfileCategory: function (
    type: 'parent' | 'child' | 'anonymous' = 'parent'
  ): void {
    SecureLocalStorage.setItem(`${whoamiIndex}_cat`, type)
  },
  getProfileCategory: function ():
    | 'parent'
    | 'child'
    | 'anonymous'
    | undefined {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return SecureLocalStorage.getItem(`${whoamiIndex}_cat`) as any
  },

  setProfileType: function (type: 'anon' | 'legacy' = 'legacy'): void {
    SecureLocalStorage.setItem(`${whoamiIndex}_type`, type)
  },
  getProfileType: function (): 'anon' | 'legacy' | undefined {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (SecureLocalStorage.getItem(`${whoamiIndex}_type`) ?? '') as any
  },
  setStoredProfile: function (profile: ParentProfile): void {
    SecureLocalStorage.setItem(whoamiIndex, JSON.stringify(profile))
  },

  getStoredRedirectLocation: function (): string {
    return (SecureLocalStorage.getItem(`${whoamiIndex}_redirectTo`) ??
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      '') as any
  },
  setStoredRedirectLocation: function (location: string): void {
    SecureLocalStorage.setItem(`${whoamiIndex}_redirectTo`, location)
  },
  removeStoredRedirectLocation: function (): void {
    SecureLocalStorage.removeItem(`${whoamiIndex}_redirectTo`)
  },

  removeStoredProfile: function (): void {
    SecureLocalStorage.removeItem(whoamiIndex)
  },

  getStoredKid(): Profile | undefined {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const kid: any =
        Cookies.get(activeKidIndex) ??
        SecureLocalStorage.getItem(activeKidIndex)
      const parsed = JSON.parse(kid)
      if (typeof parsed !== 'object') return undefined
      return parsed
    } catch (error) {
      Sentry.captureException(error)
      return undefined
    }
  },
  removeStoredKid: (): void => {
    SecureLocalStorage.removeItem(activeKidIndex)
    Cookies.remove(activeKidIndex)
  },
  setStoredKid: function (kidProfile: Profile | null | undefined): void {
    try {
      if (typeof kidProfile !== 'object') {
        SecureLocalStorage.setItem(activeKidIndex, kidProfile)
        Cookies.set(activeKidIndex, kidProfile, { expires: 365 })
      } else {
        SecureLocalStorage.setItem(activeKidIndex, JSON.stringify(kidProfile))
        Cookies.set(activeKidIndex, JSON.stringify(kidProfile), {
          expires: 365,
        })
      }
    } catch (e) {
      Sentry.captureException(e)
      console.error(e)
    }
  },
}

export default HelperProfile
