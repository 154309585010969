import Config from '@config/index'
import { IActivityNew } from '@src/types/api.interface'
import Helper from '@utils/Helpers'
import { FC } from 'react'
import { TYPOGRAPHY } from './constants'
import GameIcon from '@components/Icons/GameIcon'
import BookIcon from '@components/Icons/BookIcon'
import VideoIcon from '@components/Icons/VideoIcon'

const lockIcon = `${Config.publicURL}/images/v3/orange-lock.svg`

interface ActivityCardProps {
  data: IActivityNew
  setIsVisable?: (isVisible: boolean, key?: number) => void
  isLocked?: boolean
  isWatched?: boolean
  index?: number
}

interface TypeLabelProps {
  type: 'game' | 'book' | 'video' | string
  isWatched?: boolean
}

const TypeLabel: FC<TypeLabelProps> = ({ type = 'video', isWatched }) => {
  const isWatchedStyle = isWatched ? 'text-gray-300' : 'text-white'
  if (type === 'book') {
    return (
      <div className="flex absolute top-3 right-4 z-10 pages_episode_activitycard_book_container">
        <BookIcon
          className={`w-6 h-7 lg:h-9 lg:w-8 fill-current pages_episode_activitycard_book_icon ${isWatchedStyle}`}
        />
        <p
          className={` ${isWatchedStyle} text-lg pr-3 lg:pr-4 lg:text-3xl pages_episode_activitycard_text`}
        >
          {TYPOGRAPHY.BOOK}
        </p>
      </div>
    )
  }

  if (type === 'game') {
    return (
      <div className="flex absolute top-3 right-4 z-10 pages_episode_activitycard_game_container">
        <GameIcon
          className={`w-6 h-7 lg:h-9 lg:w-8 fill-current pages_episode_activitycard_game_icon  ${isWatchedStyle}`}
        />
        <p
          className={` ${isWatchedStyle} text-lg pages_episode_activitycard_game_text pr-3 lg:pr-4 lg:text-3xl`}
        >
          {TYPOGRAPHY.GAME}
        </p>
      </div>
    )
  }

  return (
    <div className="flex absolute top-3 right-4 z-10 pages_episode_activitycard_video_container">
      <VideoIcon
        className={`w-6 h-7 lg:h-9 lg:w-8 fill-current pages_episode_activitycard_video_icon ${isWatchedStyle}`}
      />
      <p
        className={` ${isWatchedStyle} text-lg pr-3 lg:pr-4 pages_episode_activitycard_video_text lg:text-3xl`}
      >
        {TYPOGRAPHY.VIDEO}
      </p>
    </div>
  )
}

const ActivityCard: FC<ActivityCardProps> = ({
  index,
  data,
  isLocked,
  setIsVisable,
  isWatched,
}) => {
  const thumbnail = Helper.MediaURL(data?.thumbnail)

  return (
    <Card
      image={thumbnail}
      isLocked={isLocked}
      isWatched={isWatched}
      onClick={() => setIsVisable(isLocked, index)}
      title={data.title}
      type={data.category}
    />
  )
}

interface CardProps {
  title: string
  type: 'game' | 'book' | 'video' | string
  isLocked: boolean
  image: string
  onClick?: () => void
  isWatched?: boolean
}

export const Card: FC<CardProps> = ({
  title,
  type,
  isLocked,
  image,
  onClick,
  isWatched,
}) => {
  const thumbnail = Helper.MediaURL(image)
  const isLockedStyle = isLocked
    ? 'bg-disable-overlay'
    : 'bg-dimming-overlay-[image:var(--image-url)] flex rounded-[20px] bg-cover bg-center'

  const isWatchedStyle = isWatched ? 'bg-gray-300' : 'bg-white'

  const isLockedText = isLocked ? 'text-v3-gray-900' : ''
  const style = {
    '--image-url': `url(${thumbnail})`,
  } as React.CSSProperties

  return (
    <div
      className="pages_episode_activitycard_card_container"
      onClick={onClick}
    >
      <div
        className={`flex flex-col w-52 lg:w-80 ${isWatchedStyle} rounded-3xl shadow-2xl`}
      >
        <div
          className={`relative pages_episode_activitycard_card_img_container flex mx-2 mt-2 h-28 lg:h-40 ${isLockedStyle} `}
          style={style}
        >
          <TypeLabel isWatched={isWatched} type={type} />

          {isLocked ? (
            <>
              <img
                alt="lock-icon"
                className="absolute pages_episode_activitycard_card_img_lock top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 rotate-15 z-10"
                src={lockIcon}
              />
              <img
                alt={title}
                className="flex w-full h-full pages_episode_activitycard_card_img_thumb rounded-[20px] object-cover"
                src={thumbnail}
              />
            </>
          ) : null}
        </div>

        <span
          className={`w-full text-center flex items-center pages_episode_activitycard_card_text justify-center text-xl lg:text-4xl truncate pt-2 pb-3 ${isLockedText}`}
        >
          {title}
        </span>
      </div>
    </div>
  )
}

export default ActivityCard
