import BreadCrumb from '@components/BreadCrumb'
import { ContentTitle } from '@components/ContentTitle'
import { AppBar, AppContent } from '@components/Layout'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import { useReports } from '@hooks/data/fetchers/useReports'
import { useMobileView } from '@hooks/useMobileView'
import { TimeDetail } from '@src/types/api.interface'
import { FC, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory, useParams } from 'react-router-dom'
import { GoalCardsList } from './GoalCard'
import PieChart from './PieChart'
import ReportCard from './ReportCard'
import StepProgressBar from './StepProgressBar'
import { CARDS, TEXT } from './data'

const ReportHome: FC = () => {
  const params = useParams<{ id: string }>()
  const isMobileView = useMobileView()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [selectedUnitIndex, setSelectedUnitIndex] = useState(0)

  const { reports, isLoadingReports } = useReports({ id: params.id })

  const seriesTitles = reports?.series?.map(({ title }) => title) || []
  const episodesData = reports?.series[selectedUnitIndex]?.episodes?.map(
    ({ title, completedStatus }) => ({
      title,
      completed: completedStatus,
    })
  )

  const episodesTitles = episodesData?.map(({ title }) => title) || []
  const completedSteps = episodesData?.filter(
    ({ completed }) => !!completed
  ).length

  const series = reports?.series?.map(
    ({ id, title, episodes: SeriesEpisodes }) => {
      const episodes = SeriesEpisodes?.map(
        ({ id, title, eduTargets, completedStatus }) => ({
          id,
          title,
          goals: eduTargets,
          completed: completedStatus,
        })
      )

      return {
        id,
        seriesTitle: title,
        episodes,
      }
    }
  )

  const statisticsTabs = Object.keys(reports?.tracking || {}) as
    | ['today', 'week', 'month']

  const trackingData: TimeDetail =
    reports?.tracking[statisticsTabs[selectedIndex]]
  const episodeTitle = `مقصد ${seriesTitles[selectedUnitIndex] || ''}`
  const percent = (100 / episodesTitles.length) * completedSteps
  const loadChart =
    !isLoadingReports &&
    !!Object.getOwnPropertyNames(reports?.tracking || {}).length
  const history = useHistory()
  return (
    <>
      <Helmet>
        <title className="report_home_page-main-title">
          {TEXT.WEBSITE_TITLE}
        </title>
      </Helmet>

      {isLoadingReports ? <AppProgressBar /> : null}

      {isMobileView ? (
        <AppBar
          backButtonOnclick={() => history.goBack()}
          fixed
          hideToggleButton
          showBackButton={true}
          showLogoutButton
          showParentBackButton
          title=""
        />
      ) : null}

      <AppContent
        className="bg-no-repeat bg-cover bg-center bg-v3 overflow-y-scroll h-screen"
        haveFooter={!isMobileView}
        haveHeader={!isMobileView}
        headerTitle={!isMobileView ? TEXT.TITLE : null}
      >
        {isMobileView ? (
          <ContentTitle
            noShadow
            notBold
            textColor="text-white"
            title={TEXT.TITLE}
          />
        ) : (
          <BreadCrumb title={TEXT.TITLE} />
        )}

        <div className="report_home_page-container flex flex-col flex-wrap w-11/12 xl:flex-row container mx-auto justify-center mt-6">
          <div className="report_home_page-wrapper flex flex-col xl:w-full xl:flex-row xl:gap-x-6">
            <ReportCard
              className={`report_home_page-report-card-${CARDS.WATCHING_TIME.TITLE} flex flex-1`}
              icon={CARDS.WATCHING_TIME.IMAGE}
              onTabClick={(index) => {
                setSelectedIndex(index)
              }}
              selectedTabIndex={selectedIndex}
              tabs={CARDS.WATCHING_TIME.TABS}
              title={CARDS.WATCHING_TIME.TITLE}
            >
              {loadChart ? <PieChart data={trackingData} /> : null}
            </ReportCard>

            <ReportCard
              className={`report_home_page-report-card-${CARDS.UNITES_PROGRESS.TITLE} flex`}
              icon={CARDS.UNITES_PROGRESS.IMAGE}
              onTabClick={(index) => {
                setSelectedUnitIndex(index)
              }}
              selectedTabIndex={selectedUnitIndex}
              tabs={seriesTitles}
              title={CARDS.UNITES_PROGRESS.TITLE}
            >
              <span
                className={`report_home_page-span-${episodeTitle} h-2 w-2 align-middle rounded-full bg-v3-purple inline-block`}
              />
              <h4
                className={`report_home_page-episode-title-${episodeTitle} text-xl md:text-2xl mr-2 mb-20 text-v3-blue-800 inline-block`}
              >
                {episodeTitle}
              </h4>
              <div
                className={`report_home_page-step-progress-bar-wrapper-${episodeTitle} px-9 h-14`}
              >
                <StepProgressBar percent={percent} steps={episodesTitles} />
              </div>
            </ReportCard>
          </div>

          <ReportCard
            className={`report_home_page-report-card-${CARDS.EDUCATIONAL_GOALS.TITLE} mx-auto`}
            icon={CARDS.EDUCATIONAL_GOALS.IMAGE}
            title={CARDS.EDUCATIONAL_GOALS.TITLE}
          >
            {!!series?.length && !isLoadingReports ? (
              <GoalCardsList series={series} />
            ) : null}
          </ReportCard>
        </div>
      </AppContent>
    </>
  )
}

export default ReportHome
