import Config from '@config/index'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import useIFrameFullscreenChange from '@hooks/utils/useIFrameIsFullscreen'
import { closeFullscreen } from '@utils/fullscreen'
import { GtagEvents } from '@utils/gtag-events'
import { FC, IframeHTMLAttributes, useEffect, useRef } from 'react'
import SVG from 'react-inlinesvg'
const exitfullscreenSvg = `${Config.publicURL}/images/exitfullscreen_button.svg`

export const GameIFrame: FC<{
  index?: number
  from?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  episode?: any
  iframeContainerId?: string
  gameId: string
  token: string
  apiUrl: string
  iframeProps: IframeHTMLAttributes<HTMLIFrameElement>
}> = (props) => {
  const { parentProfile, kidProfile } = useProfile()
  const iframeRef = useRef<HTMLIFrameElement>(null)

  const { isFullScreen } = useIFrameFullscreenChange()

  useEffect(() => {
    const iframe = iframeRef.current
    if (!iframe) return
    function listener() {
      const iframe = iframeRef.current
      if (!iframe) return

      iframe.contentWindow?.postMessage(
        {
          gameId: props.gameId,
          token: props.token,
          api: props.apiUrl,
        },
        '*'
      )
    }
    iframe.addEventListener('load', listener)

    GtagEvents.contentOpened({
      index: props.index,
      episode: props?.episode,
      parentId: parentProfile?._id,
      childId: kidProfile?._id,
      contentId: props?.iframeContainerId,
      episodeId: props?.episode.id || props?.episode?._id,
      gameId: props?.gameId,
    })
    window.addEventListener('beforeunload', function () {
      GtagEvents.contentClosed({
        index: props.index,
        episode: props?.episode,
        parentId: parentProfile?._id,
        childId: kidProfile?._id,
        contentId: props?.iframeContainerId,
        episodeId: props?.episode.id || props?.episode?._id,
        gameId: props?.gameId,
      })
    })
    return () => {
      GtagEvents.contentClosed({
        index: props.index,
        episode: props?.episode,
        parentId: parentProfile?._id,
        childId: kidProfile?._id,
        contentId: props?.iframeContainerId,
        episodeId: props?.episode.id || props?.episode?._id,
        gameId: props?.gameId,
      })
      iframe.removeEventListener('load', listener)
    }
  }, [props, parentProfile, kidProfile])

  useEffect(() => {
    const iframe = iframeRef.current
    const iframeNode = iframe.parentElement
    if (!iframe && iframeNode) return
    const src = iframe.getAttribute('data-src')
    if (!src) return
    iframe.style.display = 'none'
    iframe.src = src
    const newIframe = document.createElement('iframe')
    newIframe.src = src
    newIframe.setAttribute('sandbox', 'allow-same-origin allow-scripts')
    newIframe.setAttribute('allowFullScreen', 'true')
    newIframe.setAttribute('frameBorder', '0')
    newIframe.setAttribute('credentialless', 'true')
    newIframe.setAttribute('width', '100%')
    newIframe.setAttribute('height', '100%')
    newIframe.setAttribute('id', 'gameIframe')
    iframe.replaceWith(newIframe)
  }, [])

  return (
    <div
      className="relative drop-shadow-[0px_5px_5px_rgba(0,0,0,0.95)] w-full h-full max-h-screen mx-auto game_iframe-container"
      id={props.iframeContainerId}
    >
      {isFullScreen ? <ExitFullScreenActivity /> : null}
      <div
        className="relative drop-shadow-[0px_5px_5px_rgba(0,0,0,0.95)] w-full h-full max-h-screen mx-auto game_iframe-container-id"
        id={props.iframeContainerId}
      >
        <iframe
          className="game_iframe"
          ref={iframeRef}
          title="game-iframe"
          {...props.iframeProps}
        />
      </div>
    </div>
  )
}

const ExitFullScreenActivity: FC = () => {
  return (
    <span
      className="absolute bottom-5 left-5 w-[36px] z-[9999] game_iframe-cls-btn"
      onClick={closeFullscreen}
      role="button"
    >
      <SVG src={exitfullscreenSvg} />
    </span>
  )
}
