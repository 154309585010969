import React, { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { AppBar, AppContent } from '@components/Layout'
import Popup from '@components/Popup'
import { useHistory, useLocation } from 'react-router-dom'
import { usePurchases } from '@hooks/use-purchases'
import * as queryString from 'query-string'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { IPurchaseManagerPayment } from '@src/services/purchases/types'

const MESSAGE_SUBSCRIBED_SUCCESSFULLY = 'لقد تم الإشتراك بنجاح'
const MESSAGE_OK = 'موافق'
const POPUP_TITLE = 'مبروك'

const PaymentSucceeded: FC = () => {
  const history = useHistory()
  const query = queryString.parse(useLocation().search)
  const purchase = usePurchases()
  const { parentProfile } = useProfile()

  useEffect(() => {
    if (query.subscriptionId) {
      const instance = purchase as IPurchaseManagerPayment
      instance.updateRevenueCat(
        query.subscriptionId as string,
        parentProfile?._id
      )
    }
  })

  return (
    <>
      <Helmet>
        <title className="page_payment_succeeded_page_title">
          عالم زيدو - {MESSAGE_SUBSCRIBED_SUCCESSFULLY}
        </title>
      </Helmet>
      <AppBar fixed hideToggleButton showBackButton showHomeLink title="" />

      <AppContent className="bg-no-repeat bg-cover bg-center bg-v3">
        <Popup
          acceptLink="/"
          buttonText={MESSAGE_OK}
          message={MESSAGE_SUBSCRIBED_SUCCESSFULLY}
          onClose={() => {
            history.replace('/')
          }}
          title={POPUP_TITLE}
          visible={true}
        />
      </AppContent>
    </>
  )
}

export default PaymentSucceeded
