import React, { FC } from 'react'

const CalendarIcon: FC<{
  classNames?: string
  fill1?: string
  fill2?: string
}> = ({ classNames, fill1, fill2 }) => {
  return (
    <svg
      className={`${classNames} h-[30px] w-[30px] calendar_icon-svg`}
      fill={fill1 || 'none'}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.3257 14.2145H0.945062C1.02141 12.9364 1.11492 11.6745 1.2256 10.429C1.3955 8.51652 2.93315 7.01286 4.84891 6.8857C6.04898 6.80605 7.2606 6.73661 8.48378 6.67739V2.52451C8.48378 1.44847 9.35608 0.576172 10.4321 0.576172C11.5082 0.576172 12.3805 1.44847 12.3805 2.52451V6.52444C14.93 6.4473 17.5278 6.41281 20.1738 6.42098C22.8316 6.42918 25.4294 6.48026 27.9672 6.57421V2.52451C27.9672 1.44847 28.8395 0.576172 29.9155 0.576172C30.9915 0.576172 31.8638 1.44847 31.8638 2.52451V6.75479C33.055 6.82137 34.2323 6.89789 35.3957 6.98433C37.2694 7.12351 38.7769 8.57982 38.9807 10.4475C39.116 11.6868 39.231 12.9425 39.3257 14.2145ZM39.5504 18.1112C39.6164 19.7102 39.652 21.3338 39.6572 22.982C39.6709 27.3392 39.4504 31.5245 38.9957 35.5378C38.7853 37.395 37.2858 38.8412 35.4223 38.9843C30.3627 39.373 25.2799 39.5593 20.1738 39.5431C15.0676 39.5269 9.93821 39.3083 4.78574 38.8872C2.89729 38.7329 1.39356 37.2426 1.22236 35.3556C0.867739 31.4469 0.69043 27.3224 0.69043 22.982C0.69043 21.3342 0.715987 19.7106 0.767101 18.1112H39.5504ZM8.48378 22.0079V25.9046H12.3805V22.0079H8.48378ZM18.2255 22.0079V25.9046H22.1221V22.0079H18.2255ZM27.9672 22.0079V25.9046H31.8638V22.0079H27.9672ZM8.48378 29.8012V33.6979H12.3805V29.8012H8.48378ZM18.2255 29.8012V33.6979H22.1221V29.8012H18.2255ZM27.9672 29.8012V33.6979H31.8638V29.8012H27.9672Z"
        fill={fill2 || '#4758C5'}
      />
    </svg>
  )
}

export default CalendarIcon

// className="self-center h-[30px] w-[30px] xl:h-[40px] xl:w-[40px]"
