import { useHistory } from 'react-router-dom'
import NotificationPopover, { Notification } from './NotificationPopover'
import { ZIDO_TYPOGRAPHY } from '@src/services'

const ExpandedNotification = ({
  currentNotifications = [],
  oldNotifications = [],
  action = null,
  loading = true,
}: {
  currentNotifications: Notification[]
  oldNotifications?: Notification[]
  action?: () => unknown
  loading?: boolean
}) => {
  const history = useHistory()

  return (
    <div className="v3-bg w-full p-3 h-full relative component_layout_expanded_notification_container">
      <div
        className="cancel-back-button v3-icon v3-back-button cursor-pointer absolute top-[10px] right-[0px] component_layout_expanded_notification_go_back_conainer"
        onClick={
          action && typeof action === 'function'
            ? action
            : () => history.goBack()
        }
      />
      <div className="mt-[40px] bg-white max-h-native-notification-screen  lg:max-h-[750px] h-native-notification-screen lg:h-[400px] overflow-auto rounded-xl block component_layout_expanded_notification__child_container">
        <div className="h-[50px] border-b-[1px] border-[#ccc] flex flex-row-reverse justify-between font-kidzhood component_layout_expanded_notification_typography_container">
          <div className="opacity-0 w-[155px] h-[24px] text-left whitespace-nowrap pl-4 pt-4 cursor-pointer text-[#4FC4F9] text-[15px] component_layout_expanded_notification__marked_read">
            {ZIDO_TYPOGRAPHY.MARK_READ}
          </div>
          <div className="w-[110px] h-[24px] pt-2 flex component_layout_expanded_notification_mark_count_container">
            <div className="text-[#625959] text-[12px] pt-2 pl-2 mr-4 component_layout_expanded_notification_mark_count_div">
              {ZIDO_TYPOGRAPHY.COUNTS}
            </div>
            <div className="bg-[#665EBC] rounded-full w-[24px] h-[24px] mt-1 cursor-pointer pt-1 text-center text-white component_layout_expanded_notification_total_count">
              {[...currentNotifications, ...oldNotifications].length}
            </div>
          </div>
        </div>
        <NotificationPopover
          currentNotifications={currentNotifications}
          fullscreen
          loading={loading}
          oldNotifications={oldNotifications}
        />
      </div>
    </div>
  )
}

export default ExpandedNotification
