import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { IProfilePublication } from '@src/types/api.interface'
import { ContentTitle } from '@components/ContentTitle'
import { AppBar, AppContent } from '@components/Layout'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import Config from '@config/index'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import Helper from '@utils/Helpers'

const fAvatar = `${Config.publicURL}/images/avatars/f_avatar.png`
const mAvatar = `${Config.publicURL}/images/avatars/m_avatar.png`
const playIcon = `${Config.publicURL}/images/play-icon.svg`

const Profile = () => {
  const { kidProfile: profile, profileType } = useProfile()

  const isLoading = !profile
  if (profileType !== 'child') return <p>Unauthorized</p>
  return (
    <>
      <Helmet>
        <title className="profile_page-main-title">عالم زيدو - صفحتي</title>
        <link href={`${Config.publicURL}/css/profile.css`} type="stylesheet" />
      </Helmet>
      {isLoading ? <AppProgressBar /> : null}
      <AppBar
        fixed
        showBackButton
        showHomeLink
        showLogoutButton
        title="صفحتي"
        titleCenter
      />

      <AppContent className="bg-profile " id="profile-page">
        <ContentTitle noShadow textColor="text-[#4a4a4a]" title="صفحتي" />
        {profile ? (
          <ul
            className="bg-profile-bottom pt-10 px-4 sm:px-16 pb-[100px]"
            id="profile-page-body"
          >
            <li className="profile_page-nav-item-gender-container">
              <div className="profile_page-nav-item-gender-wrapper" id="top">
                <AvatarPreview gender={profile.gender} />
                <div className="profile_page-user-details" id="user-details">
                  <UserDetails
                    firstName={profile.firstName}
                    lastName={profile.lastName}
                    middleName={profile.middleName ? profile.middleName : ''}
                    nickname={profile.nickname ? profile.nickname : ''}
                  />
                  <ProgressBar progress={profile.progress} />
                </div>
                <VideoSuggestion next={profile.watchNext} />
              </div>
            </li>
            <li className="profile_page-nav-item-my-publications-container">
              <div
                className="profile_page-nav-item-my-publications-wrapper"
                id="my-publications"
              >
                <span
                  className="profile_page-nav-item-my-publications-title"
                  id="my-publications-title"
                >
                  {' '}
                  أنشطتي في المتحف{' '}
                </span>
                {profile?.publications ? (
                  <MyPublications
                    max={Config.maxProfilePublications}
                    publications={profile.publications}
                  />
                ) : null}

                <nav
                  className="profile_page-all-my-publications-nav-main"
                  id="all-my-publications"
                >
                  <Link
                    className="profile_page-nav-item-gallery-link"
                    to="/gallery"
                  >
                    <div className="profile_page-publication">
                      {' '}
                      {profile.publications.length >
                      Config.maxProfilePublications
                        ? 'المزيد'
                        : 'المعرض'}{' '}
                    </div>
                  </Link>
                </nav>
              </div>
            </li>
            <li className="profile_page-nav-item-bg-bottom">
              <div className="profile_page-bg-bottom" id="bg-bottom" />
            </li>
          </ul>
        ) : null}
      </AppContent>
    </>
  )
}

export default Profile

const AvatarPreview: FC<{ gender: string }> = (props) => {
  const avatarImg = props.gender === 'female' ? fAvatar : mAvatar
  return (
    <div className="profile_page-profile-avatar-container profile-avatar">
      <div className="profile_page-profile-avatar-wrapper inner-div">
        <img
          alt="user Avatar"
          className="profile_page-user-avatar-icon"
          src={avatarImg}
        />
      </div>
    </div>
  )
}

const ProgressBar: FC<{ progress: string }> = (props) => {
  const progress = props.progress
  return (
    <div className="profile_page-progress-wrapper" id="progress">
      <div className="profile_page-i-have-watched-text">لقد شاهدت</div>
      <div className="profile_page-progress-bar progress-bar">
        <div
          className="profile_page-progress-bar-done done"
          style={{ backgroundColor: '#CBE232', width: progress + '%' }}
        >
          {' '}
          <span className="profile_page-progress-bar-percentage">
            {progress}%
          </span>
        </div>
      </div>
    </div>
  )
}

const VideoSuggestion: FC<{
  next: { episode: string; chapter: string; thumbnail: string; title: string }
}> = (props) => {
  const url = '/episode/' + props.next.episode + '/' + props.next.chapter

  return (
    <ul className="profile_page-video-suggestion-" id="video-suggestion">
      <div className="profile_page-a-witness">شاهد</div>

      <li
        className={`profile_page-asset-btn-${props.next.chapter} asset-btn`}
        id={`asset-${props.next.chapter}`}
      >
        <nav className="profile_page-chapter-asset-wrapper chapter-asset">
          <Link className={`profile_page-chapter-asset-link-${url}`} to={url}>
            <div className={`profile_page-chapter-asset-${url} chapter-asset`}>
              <div
                className={`profile_page-asset-over-layer-${url} asset-overLayer asset-overLayer-play`}
              >
                <img
                  alt=""
                  className={`profile_page-asset-over-layer-icon-${url} asset-overLayer-icon`}
                  draggable="false"
                  src={playIcon}
                />
              </div>
              <img
                alt={props.next.title}
                className={`profile_page-${props.next.title} asset-btn-img`}
                draggable="false"
                src={Helper.MediaURL(props.next.thumbnail)}
              />
              <div
                className={`profile_page-asset-btn-title-wrapper-${props.next.title} asset-btn-title`}
              >
                <span
                  className={`profile_page-asset-btn-title-${props.next.title}`}
                >
                  {props.next.title}
                </span>
              </div>
            </div>
          </Link>
        </nav>
      </li>
    </ul>
  )
}
const UserDetails: FC<{
  nickname: string
  firstName: string
  lastName: string
  middleName: string
}> = (props) => {
  const currentNickname = props.nickname
  const userName =
    props.firstName + ' ' + props.middleName + ' ' + props.lastName

  return (
    <div>
      <div className={`profile_page-${currentNickname} user-alias`}>
        {currentNickname}
      </div>
      <div className={`profile_page-${userName} user-name`}>{userName}</div>
    </div>
  )
}

const MyPublicationThumbnail: FC<{
  publication: IProfilePublication
}> = (props) => {
  const url = '/gallery/publications/' + props.publication._id
  return (
    <Link className="profile_page-my-publications-link" to={url}>
      <img
        alt=""
        className="profile_page-my-publications-thumbnail asset-btn-img"
        draggable="false"
        src={Helper.MediaURL(props.publication.thumbnail)}
      />
      <div className="asset-btn-title" />
    </Link>
  )
}

const MyPublications: FC<{
  publications: IProfilePublication[]
  max: number
}> = (props) => {
  if (props.publications.length === 0) {
    return (
      <div className="profile_page-assets-panel" id="assets-panel">
        ليس لديك مشاركات
      </div>
    )
  }

  const max = props.max || 0
  return (
    <ul className="profile_page-publications-wrapper" id="assets-panel">
      {props.publications.map(function (publication, idx) {
        if (max > 0 && idx >= max) return null
        return (
          <li
            className={`profile_page-${publication._id} animate-fadein`}
            key={publication._id}
          >
            <MyPublicationThumbnail publication={publication} />
          </li>
        )
      })}
    </ul>
  )
}
