import React from 'react'
import { JSX } from 'react/jsx-runtime'

const GameIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      className="game_icon-svg"
      fill="none"
      height="9"
      viewBox="0 0 12 9"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11.526 4.256c-.102-.46-.204-.91-.278-1.318C11.05 1.855 10.6 1.17 9.908.9a1.915 1.915 0 00-1.417.031c-1.182.583-2.058.528-2.061.527l-.812-.002-.05.002c-.008 0-.885.055-2.084-.535-.07-.031-.7-.297-1.4-.023-.692.27-1.143.954-1.34 2.037-.074.407-.176.859-.278 1.318-.483 2.16-.737 3.505.096 3.93.147.075.31.112.486.112.291 0 .623-.1.99-.303.291-.159.562-.367.828-.635l.085-.086c.373-.387.952-.964 2.547-.955H6.566c1.554 0 2.107.574 2.475.955l.085.086c.266.268.537.476.827.635.592.325 1.09.39 1.477.191.832-.425.578-1.77.096-3.93zM5 4.34h-.498v.396c0 .218-.223.395-.499.395-.275 0-.498-.177-.498-.395V4.34h-.498c-.275 0-.498-.178-.498-.396 0-.218.223-.396.498-.396h.498v-.395c0-.219.223-.396.498-.396.276 0 .498.177.498.396v.395H5c.275 0 .498.178.498.396 0 .218-.223.396-.498.396zm2.49 0c-.275 0-.498-.178-.498-.396 0-.218.223-.396.498-.396s.498.178.498.396c0 .218-.223.396-.498.396zm.997.791c-.275 0-.499-.177-.499-.395 0-.219.224-.396.499-.396s.498.177.498.396c0 .218-.223.395-.498.395zm0-1.583c-.275 0-.499-.177-.499-.395 0-.219.224-.396.499-.396s.498.177.498.396c0 .218-.223.395-.498.395zm.996.792c-.275 0-.498-.178-.498-.396 0-.218.223-.396.498-.396s.498.178.498.396c0 .218-.223.396-.498.396z" />
    </svg>
  )
}

export default GameIcon
