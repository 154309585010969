import { Capacitor } from '@capacitor/core'

const HeaderLine = () => {
  if (Capacitor.getPlatform() === 'web') {
    return (
      <hr className="border my-3 opacity-[21%] component_layout_dashed_line_hr" />
    )
  }
}

export default HeaderLine
