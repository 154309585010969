import Config from '@config/index'
import { AppContent } from '@components/Layout'

const BgChime = `${Config.publicURL}/images/v2/bg-chime.png`
const modalRibbon = `${Config.publicURL}/images/v2/modal-ribbon.png`
const bgBlueBubblesTop = `${Config.publicURL}/images/v2/bg-blue-cloud.png`
const standingPen = `${Config.publicURL}/images/v2/standing-pen.png`
const bgGreenBtn = `${Config.publicURL}/images/v2/bg-green-button.png`
const bgBlueBubblesLeft = `${Config.publicURL}/images/v2/bg-blue-left.png`
const bgBlueBubblesRight = `${Config.publicURL}/images/v2/bg-blue-right.png`
const bgBlueBubblesBottom = `${Config.publicURL}/images/v2/bg-blue-bottom.png`

export const BlueRibbonContainerConfig = ({
  children,
  dimensionClass = 'w-min-[320px] h-[302px] w-[420px]',
  classNames,
  title = 'مرحبا',
  action,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any
  dimensionClass?: string
  classNames?: string
  title?: string
  action: () => void
}): React.ReactElement => {
  return (
    <div
      className={`h-full relative w-full flex justify-center content-center self-center mt-9 blue_rbn_container ${dimensionClass}`}
      style={{ zIndex: '9' }}
    >
      <div
        className="block blue_rbn_container-content"
        style={{
          zIndex: '99',
        }}
      >
        <div
          className={`bg-[#1BA4D9] relative p-2 bg-opacity-1 rounded-[20%] border-[5px] border-[#99EBF9] blue_rbn_container-dimension ${
            dimensionClass ??
            'max-w-[640px] w-[90%] landscape:max-w-[420px] sm:landscape:h-[170px] '
          } ${classNames}`}
        >
          <div className="relative block blue_rbn_container-img-wrpr">
            <img
              alt=""
              className="absolute w-[120px] top-[-80px] right-[-130px] blue_rbn_container-bubbles-top"
              src={bgBlueBubblesTop}
              style={{ zIndex: '2' }}
            />
            <img
              alt=""
              className="absolute right-[-80px] top-0 z-10 blue_rbn_container-standing-pen"
              src={standingPen}
            />
            <div className="absolute top-[-55px] left-[0] w-full blue_rbn_container-title-wrpr">
              <p className="absolute top-0 left-0 w-full text-center text-[30px] text-[#01446F] blue_rbn_container-title">
                {title}
              </p>
              <img
                alt=""
                className="block mx-auto w-[250px] blue_rbn_container-modal-rbn"
                src={modalRibbon}
              />
            </div>
          </div>
          <div
            className={`relative ${dimensionClass} text-left overflow-hidden rounded-[20%] p-8 blue_rbn_container-mx-blnd-wrpr`}
          >
            <div
              className="mix-blend-color-dodge blue_rbn_container-mx-blnd"
              style={{
                backgroundImage: `url(${BgChime})`,
                backgroundSize: `contain`,
                backgroundRepeat: 'no-repeat',
                width: '80%',
                height: '80%',
                position: 'absolute',
                top: '20px',
                left: '60px',
                zIndex: '0',
              }}
            />
            <div className="relative block p-3 pt-1 text-center w-full blue_rbn_container-link-wrpr">
              {children}
              <a
                className="text-center mt-1 block text-[17px] underline blue_rbn_container-link"
                href="/register"
                onClick={action}
              >
                ليس لديك حساب؟ سجل الان
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const BlueRibbonContainer = ({
  title = 'مرحبا',
  message = 'استمتع باجمل المسابقات قم بالتسجيل الان',
  action,
}: {
  title?: string
  message?: string | React.ReactElement
  action?: () => void
}) => {
  return (
    <AppContent
      className="bg-no-repeat bg-cover bg-center flex items-center justify-center overflow-y-auto"
      style={{
        backgroundColor: '#D9F6FF',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="absolute top-0 left-0 h-full w-full">
        <img
          alt=""
          className="absolute top-0 left-0 z-0 blue_rbn_container-bbls-left"
          src={bgBlueBubblesLeft}
        />
        <img
          alt=""
          className="absolute top-0 right-0 z-0 blue_rbn_container-bbls-right"
          src={bgBlueBubblesRight}
        />
        <div className="absolute bottom-0 w-full z-0 blue_rbn_container-bbls-btm-wrpr">
          <img
            alt=""
            className="block mx-auto w-[100px] blue_rbn_container-bbls-btm"
            src={bgBlueBubblesBottom}
          />
        </div>
      </div>
      <BlueRibbonContainerConfig action={action} title={title}>
        <div className="flex justify-center content-center align-middle ml-2 mt-2 mx-auto text-center blue_rbn_container-config">
          <div className="block blue_rbn_container-config-msg-wrpr">
            <p className=" mt-6 block text-[30px] text-white w-full font-light text-center mx-auto blue_rbn_container-config-msg">
              {message}
            </p>
            <button
              className="mx-auto block mt-6 blue_rbn_container-actn-btn"
              onClick={action}
              style={{
                appearance: 'none',
                backgroundImage: `url("${bgGreenBtn}")`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                width: '121px',
                height: '64.3px',
                fontSize: '30px',
                textAlign: 'center',
              }}
              type="button"
            >
              دخول
            </button>
          </div>
        </div>
      </BlueRibbonContainerConfig>
    </AppContent>
  )
}

export default BlueRibbonContainer
