import React from 'react'

const NextPageIcon = () => {
  return (
    <svg
      className="w-[12px] next_page_icon-svg"
      //   width="17"
      //   height="15"
      fill="none"
      viewBox="0 0 17 15"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1623_1819)">
        <path
          d="M14.9784 8.68125H1.37625C0.712423 8.68125 0.175781 8.11655 0.175781 7.41802C0.175781 6.71949 0.712423 6.15479 1.37625 6.15479H14.9784C15.6422 6.15479 16.1789 6.71949 16.1789 7.41802C16.1789 8.11655 15.6422 8.68125 14.9784 8.68125Z"
          fill="#2B3C94"
        />
        <path
          d="M6.34504 14.4194C6.01933 14.4194 5.69362 14.2791 5.45787 14.0082L0.488507 8.26976C0.0728416 7.78666 0.0728416 7.05222 0.488507 6.56913L5.45787 0.830727C5.90456 0.314989 6.66144 0.279083 7.15155 0.749122C7.64167 1.21916 7.67579 2.01562 7.2291 2.53136L2.9949 7.42107L7.2291 12.3108C7.67579 12.8265 7.63856 13.623 7.15155 14.093C6.92201 14.315 6.63352 14.4227 6.34504 14.4227V14.4194Z"
          fill="#2B3C94"
        />
      </g>
      <defs>
        <clipPath id="clip0_1623_1819">
          <rect
            fill="white"
            height="14"
            transform="translate(0.175781 0.419434)"
            width="16"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default NextPageIcon
