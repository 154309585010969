import { FC } from 'react'
import HeaderLine from './Layout/DashedLine'

export const ContentTitle: FC<{
  title: string
  textColor?: string
  noShadow?: boolean
  className?: string
  textClassname?: string
  textBg?: boolean
  fixedPosition?: boolean
  notBold?: boolean
  bottomLine?: boolean
}> = (props) => {
  const bold = props.notBold ? '' : 'font-bold'
  const textColor = props.textColor ?? 'text-white'
  const textShadow = props.noShadow ? '' : 'text-shadow'
  const position = props.fixedPosition
    ? 'fixed mt-4 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
    : 'block'

  const textBg = props.textBg
    ? 'px-4 rounded-2xl shadow-md shadow-gray-500 bg-[#32c8e8]'
    : ''
  return (
    <div
      className={`${
        props.className ?? ''
      } block text-center ${bold} text-lg sm:text-xl md:text-3xl pt-4 ${textColor} ${textShadow} ContentTitle-container`}
    >
      <span
        className={`ContentTitle-text ${position} ${textBg} ${
          props.textClassname ?? ''
        }`}
      >
        {props.title}
      </span>
      {props.bottomLine ? <HeaderLine /> : null}
    </div>
  )
}
