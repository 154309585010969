import { IEpisode } from '@src/types/api.interface'
import { useCallback, useEffect, useState } from 'react'
import { useAnonymous } from '@contexts/AnonymousContext/AnonymousContext'
import HelperAPI from '@utils/helpers/helper.api'

export function useChaptersAnonymous(props: { slug: string; level: number }): {
  episode?: IEpisode
  isLoadingEpisode: boolean
} {
  const [url, setUrl] = useState<string | null>()
  const [episode, setEpisode] = useState<IEpisode>()
  const { userToken } = useAnonymous()

  useEffect(() => {
    if (props.level && userToken) {
      setUrl(
        `/level/${props.level}/anonymous-episodes/${props.slug}/anonymous-chapters`
      )
    }
  }, [props.slug, props.level, userToken])

  const callApi = useCallback(async () => {
    if (url) {
      const response = await HelperAPI.ApiRequest<IEpisode>(url, {
        method: 'GET',
      })
      setEpisode(response)
    }
  }, [url])

  useEffect(() => {
    callApi()
  }, [callApi, url])

  return {
    episode,
    isLoadingEpisode: !episode,
  }
}
