import { Capacitor } from '@capacitor/core'
import {
  AppUpdate,
  AppUpdateAvailability,
  AppUpdateInfo,
  FlexibleUpdateInstallStatus,
  FlexibleUpdateState,
} from '@capawesome/capacitor-app-update'
import { useEffect, useState } from 'react'

export function useAppUpdateInfo() {
  const [info, setInfo] = useState<AppUpdateInfo>()

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return
    async function initInfo() {
      const result = await AppUpdate.getAppUpdateInfo()
      setInfo(result)
    }
    initInfo()

    performImmediateUpdate()
  }, [])

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return
    AppUpdate.addListener(
      'onFlexibleUpdateStateChange',
      async (state: FlexibleUpdateState) => {
        if (state.installStatus === FlexibleUpdateInstallStatus.INSTALLED) {
          await AppUpdate.completeFlexibleUpdate()
        }
      }
    )
  }, [])

  return {
    appUpdateInfo: info,
  }
}

export async function openAppStore() {
  await AppUpdate.openAppStore()
}

const performImmediateUpdate = async () => {
  const result = await AppUpdate.getAppUpdateInfo()
  if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
    return
  }
  if (result.immediateUpdateAllowed) {
    await AppUpdate.performImmediateUpdate()
  }
}
