import CompetitionListItemCard from '@components/Icons/CompetitionListItemCard'
import SmileLockIcon from '@components/Icons/SmileLockIcon'
import { AppBar, AppContent } from '@components/Layout'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import Popup from '@components/Popup'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { useCompetitionNew } from '@hooks/data/fetchers/competition/use-competition-new'
import { useMobileView } from '@hooks/useMobileView'
import { CompetitionsNew } from '@src/types/api.interface'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'
import { TYPOGRAPHY, COMPETITION_LIST_TYPOGRAPHY } from './constants'
import { ContentTitle } from '@components/ContentTitle'
import SVG from 'react-inlinesvg'
import Config from '@config/index'

const characterSVG = `${Config.publicURL}/images/v3/pencil-character.svg`

const today = new Date()

interface CompetitionItemProps {
  index: number
  item: CompetitionsNew
  showPopup: boolean
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>
}

const CompetitionItem: React.FC<CompetitionItemProps> = ({
  index,
  item,
  showPopup,
  setShowPopup,
}) => {
  const isMobile = useMobileView()
  const history = useHistory()

  const handleClick = (id: string) => {
    if (new Date(item?.startDate) < today) {
      history.push({
        pathname: `/competition/list/${id}`,
        state: {
          title: item?.title,
        },
      })
    } else {
      setShowPopup(!showPopup)
    }
  }

  return (
    <div className="component_competition_list-container h-full flex items-center justify-center">
      <div
        className="component_competition_list-wrapper relative w-[160px] lg:w-[195px] h-[157px] lg:h-[190px] flex items-center justify-center flex-col"
        onClick={() => handleClick(item?._id)}
      >
        <CompetitionListItemCard
          classNames="component_competition_list-competition-list-item-card absolute inset-0 z-20 object-cover"
          fillColor={
            new Date(item?.startDate) < today ? '#FFFDE0' : '#ffffff26'
          }
          strokeColor={
            new Date(item?.startDate) < today ? '#D3E7B3' : '#ffffff26'
          }
          strokeWidth={isMobile ? 5 : 0}
        />
        <div className="component_competition_list-competition-list-item-card-wrapper absolute -top-6 z-30 right-0 h-[62px] w-[68px]">
          <CompetitionListItemCard
            classNames="component_competition_list-competition-list-item-card absolute inset-0 z-20 object-cover"
            fillColor="#EBF0B3"
            haveShadow={false}
            strokeColor="#ffffff91"
            strokeWidth={5}
          />
          <p className="component_competition_list-item-index text-v3-purple-100 z-30 absolute h-12 w-full flex items-center justify-center text-[54px] drop-shadow-[0px_2px_4px_rgba(0,0,0,0.10)]">
            {index < 9 ? `0${index + 1}` : index + 1}
          </p>
        </div>
        <div className="component_competition_list-smile-lock-icon-wrapper absolute top-0 left-0 h-[120px] lg:h-[160px] w-[162px] lg:w-[195px] flex items-center justify-center flex-col z-30">
          {new Date(item?.startDate) > today ? (
            <SmileLockIcon
              classNames="component_competition_list-smile-lock-icon z-20"
              height={47}
              width={35}
            />
          ) : null}
          <p
            className={`component_competition_list-item-title ${
              new Date(item?.startDate) < today
                ? `text-v3-green-900`
                : `text-white`
            } line-clamp-2 max-w-[90%] leading-9 text-center  text-3xl px-[18px] drop-shadow-[0px_2px_4px_rgba(0,0,0,0.10)]`}
          >
            {item?.title}
          </p>
        </div>
      </div>
    </div>
  )
}

const CompetitionList: React.FC = () => {
  const history = useHistory()
  const { kidProfile: profile } = useProfile()
  !profile && history.push('/login')

  const { competitions, isLoadingCompetitions } = useCompetitionNew(
    profile?.level
  )

  const [showPopup, setShowPopup] = useState<boolean>(false)
  return (
    <>
      <Helmet>
        <title className="competition_list-page-title">
          {COMPETITION_LIST_TYPOGRAPHY.WEBSITE_TITLE}
        </title>
      </Helmet>
      {isLoadingCompetitions ? <AppProgressBar /> : null}
      <AppBar
        backButtonLocation="/competition"
        fixed
        hideToggleButton
        showKidAvatar
        showParentBackButton
      />
      <AppContent className="mx-auto bg-no-repeat bg-cover bg-center bg-green-v3 overflow-y-scroll h-screen hide-scroll">
        <ContentTitle
          notBold
          title={COMPETITION_LIST_TYPOGRAPHY.COMPETITION_DETAILS_TITLE}
        />

        {!isLoadingCompetitions &&
        (!competitions || competitions?.length === 0) ? (
          <div className="competition_list-character-svg-wrapper  h-[80vh]  flex flex-col items-center justify-center w-full">
            <SVG
              className="competition_list-character-svg z-10 h-48 lg:h-96 w-full -mb-3"
              src={characterSVG}
            />
            <div className="competition_list-empty-message-wrapper self-center bg-[#05602E] py-3 lg:pb-2 lg:pt-1 px-12 rounded-3xl border-4 border-[#A8C64D] w-9/12 xl:w-6/12">
              <h1 className="competition_list-empty-message-title text-2xl lg:text-4xl text-center text-white">
                {TYPOGRAPHY.EMPTY_MESSAGE}
              </h1>
            </div>
          </div>
        ) : null}

        {!isLoadingCompetitions && competitions?.length > 0 ? (
          <div className="competition_list-wrapper px-4 pt-20 flex gap-x-8 overflow-x-auto lg:overflow-x-hidden overflow-y-hidden lg:gap-x-6 lg:flex-wrap justify-start lg:justify-center max-w-5xl mx-auto">
            {competitions?.length > 0
              ? competitions.map((item: CompetitionsNew, index: number) => (
                  <CompetitionItem
                    index={index}
                    item={item}
                    key={item?._id}
                    setShowPopup={setShowPopup}
                    showPopup={showPopup}
                  />
                ))
              : null}
          </div>
        ) : null}

        <Popup
          buttonOnClick={() => setShowPopup(!showPopup)}
          buttonText={COMPETITION_LIST_TYPOGRAPHY.COMPETITION_POPUP_BUTTON_TEXT}
          competitionPopup={true}
          message={COMPETITION_LIST_TYPOGRAPHY.COMPETITION_MESSAGE}
          onClose={() => {
            setShowPopup(false)
          }}
          title={COMPETITION_LIST_TYPOGRAPHY.COMPETITION_POPUP_TITLE}
          visible={showPopup}
        />
      </AppContent>
    </>
  )
}

export default CompetitionList
