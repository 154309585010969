/* eslint-disable @typescript-eslint/no-explicit-any */
import { sendGaToFirebase } from './sendGaToFirebase'

export function handbookOpened({
  episode,
  parentId,
  childId,
  handbookId,
  episodeId,
  contentId,
}: {
  episode?: any
  parentId: string
  childId: string
  handbookId?: string
  episodeId?: string
  contentId?: string
}) {
  const event: any = {
    event: episode
      ? `handbook_${episode?.title || ''}/${episode?.series?.title || ''}/${
          episodeId || ''
        }/Start`
      : 'handbook_opened',
    // event: 'handbook_opened',
    eventAction: 'handbook_opened',
    eventCategory: 'Handbook',
    data: {
      date: new Date().toUTCString(),
      parentId,
      childId,
      handbookId,
      episodeId,
      contentId,
    },
  }
  window.dataLayer.push(event)
  sendGaToFirebase(event)
}

export function handbookClosed({
  episode,
  parentId,
  childId,
  handbookId,
  episodeId,
  contentId,
}: {
  episode?: any
  parentId: string
  childId: string
  handbookId?: string
  episodeId?: string
  contentId?: string
}) {
  const event: any = {
    event: episode
      ? `handbook_${episode?.title || ''}/${episode?.series?.title || ''}/${
          episodeId || ''
        }/Close`
      : 'handbook_closed',
    // event: 'handbook_closed',
    eventAction: 'handbook_closed',
    eventCategory: 'Handbook',
    data: {
      date: new Date().toUTCString(),
      parentId,
      childId,
      handbookId,
      episodeId,
      contentId,
    },
  }
  window.dataLayer.push(event)
  sendGaToFirebase(event)
}
