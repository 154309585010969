import { CleverTap } from '@ionic-native/clevertap'
import { Capacitor } from '@capacitor/core'
import {
  CleverTapEventAndroid,
  CleverTapEventIOS,
} from '@src/CleverTapCapacitorPlugin'

export const fetchConfig = async () => await CleverTap.fetch()
export const activateRecentConfig = async () => await CleverTap.activate()
export const autoFetchActivateConfig = async () => {
  return await CleverTap.fetchAndActivate()
}
// ** more on https://github.com/CleverTap/clevertap-cordova/blob/master/docs/Usage.md
export const createNotificationChannel = async ({
  id,
  channel,
  description,
  importance = 1,
  badge = true,
}: {
  id: string
  channel: string
  description: string
  importance?: number
  badge?: boolean
}) =>
  await CleverTap.createNotificationChannel(
    id,
    channel,
    description,
    importance,
    badge
  )
export const deleteNotificationChannel = async (channel: string) =>
  await CleverTap.deleteNotificationChannel(channel)

export const deleteNotificationChannels = async (channels: string[]) => {
  for (const channel of channels) {
    await deleteNotificationChannel(channel)
  }
}

export const createNotificationGroup = async (
  groupId: string,
  channel: string
) => await CleverTap.createNotificationChannelGroup(groupId, channel)

export const deleteNotificationGroup = async (groupId: string) =>
  await CleverTap.deleteNotificationChannelGroup(groupId)

export const deleteNotificationGroups = async (channels: string[]) => {
  for (const channel of channels) {
    await deleteNotificationGroup(channel)
  }
}

export const deleteMessageById = async (id: string) =>
  await CleverTap.deleteInboxMessageForId(id)
export const deleteMessageByIds = async (ids: string[]) => {
  for (const id of ids) {
    await deleteMessageById(id)
  }
}
export const markReadMessageById = async (id: string) =>
  await CleverTap.markReadInboxMessageForId(id)

export const markReadMessageByIds = async (ids: string[]) => {
  for (const id of ids) {
    await markReadMessageById(id)
  }
}

export const getMessageById = async (id: string) =>
  await CleverTap.getInboxMessageForId(id)

export const getMessageByIds = async (ids: string[]) => {
  for (const id of ids) {
    await getMessageById(id)
  }
}

export const getAllMessages = async () => await CleverTap.getAllInboxMessages()

export const getMessageCount = async () =>
  await CleverTap.getInboxMessageCount()

// web enabled
export const trackEvent = async (eventName: string) => {
  if (Capacitor.isNativePlatform()) {
    if (Capacitor.getPlatform() === 'android') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(CleverTapEventAndroid as any).event({ title: eventName })
    } else if (Capacitor.getPlatform() === 'ios') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(CleverTapEventIOS as any).event({ title: eventName })
    }
    // await CleverTap.recordEventWithName(eventName)
  } else {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await (window as any).clevertap?.event?.push(eventName)
    } catch (e) {
      try {
        await CleverTap?.recordEventWithName(eventName)
      } catch (e) {
        console.error('could not capture clevertap event', e)
      }
    }
  }
}

export const trackEventWithProps = async (
  eventName: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props?: { [k: string]: any }
) => {
  if (Capacitor.isNativePlatform()) {
    if (Capacitor.getPlatform() === 'android') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(CleverTapEventAndroid as any).event({
        title: eventName,
        params: props || {},
      })
    } else if (Capacitor.getPlatform() === 'ios') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(CleverTapEventIOS as any).event({
        title: eventName,
        params: props || {},
      })
    }
    // await CleverTap.recordEventWithNameAndProps(eventName, props)
  } else {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      await (window as any).clevertap?.event?.push(eventName, props || {})
    } catch (e) {
      try {
        await CleverTap?.recordEventWithNameAndProps(eventName, props || {})
      } catch (e) {
        console.error('could not capture clevertap event', e)
      }
    }
  }
}

export const setProfileLocation = async (lat: number, long: number) =>
  await CleverTap.setLocation(lat, long)

export const getCleverProfileId = async () =>
  await CleverTap.profileGetCleverTapID()

// web enabled
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setUserLoggedInProfile = async (profile: { [k: string]: any }) => {
  if (Capacitor.isNativePlatform()) {
    if (Capacitor.getPlatform() === 'android') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(CleverTapEventAndroid as any).profile({ profile })
    } else if (Capacitor.getPlatform() === 'ios') {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ;(CleverTapEventIOS as any).profile({ profile })
    }
    // await CleverTap.onUserLogin(profile)
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(window as any).clevertap.profile.push(profile)
  }
}

export const removeProfileValueByIndex = async (index: string) =>
  await CleverTap.profileRemoveValueForKey(index)

export const updateUserLoggedInprofile = async (profile: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [k: string]: any
}) => await CleverTap.profileSet(profile)

export const generateAppInbox = async ({
  tabs = ['Notification'],
  navBarTitle = 'My App Inbox',
  navBarTitleColor = '#FF0000',
  navBarColor = '#FFF',
  inboxBackgroundColor = '#AED6F1',
  backButtonColor = '#00ff00',
  unselectedTabColor = '#0000ff',
  selectedTabColor = '#ff0000',
  selectedTabIndicatorColor = '#000',
  noMessageText = 'No Message(s)',
  noMessageTextColor = '#ff0000',
}: {
  tabs?: string[]
  navBarTitle?: string
  navBarTitleColor?: string
  navBarColor?: string
  inboxBackgroundColor?: string
  backButtonColor?: string
  unselectedTabColor?: string
  selectedTabColor?: string
  selectedTabIndicatorColor?: string
  noMessageText?: string
  noMessageTextColor?: string
}) => {
  return await CleverTap.showInbox({
    tabs,
    navBarTitle,
    navBarColor,
    navBarTitleColor,
    inboxBackgroundColor,
    backButtonColor,
    unselectedTabColor,
    selectedTabColor,
    selectedTabIndicatorColor,
    noMessageTextColor,
    noMessageText,
  })
}
