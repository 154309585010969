import React, { useEffect, FC, useState, useRef } from 'react'
import { Link, useParams } from 'react-router-dom'
import { IChapterListItem } from '@src/types/api.interface'
import { ContentTitle } from '@components/ContentTitle'
import { AppBar, AppContent } from '@components/Layout'
// import ZidoPointer from '@components/zidoPendingUnit'
import { useActivities } from '@hooks/use-activities'
import Helper from '@utils/Helpers'
import { Helmet } from 'react-helmet-async'
import Config from '@config/index'
import { useChaptersAnonymous } from '@hooks/use-chapters-anonymous'
import Popup from '@components/Popup'
import {
  REGISTER_NOW_MESSAGE,
  REQUIRES_REGISTRATION_MESSAGE,
} from '@src/constants/messages'
import { LEFT_ARROW, PLAY_ICON_NEW, RIGHT_ARROW } from '@utils/constants'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import ActivitySmallThumbnail from '@components/ActivitySmallThumbnail'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'

const lockIcon = `${Config.publicURL}/images/lock-icon.svg`

const EpisodeLists: FC = () => {
  const { epiSlug, level } = useParams<{ epiSlug: string; level: string }>()
  const { activities, isLoadingActivities } = useActivities({ epiSlug })

  const { episode, isLoadingEpisode } = useChaptersAnonymous({
    slug: epiSlug,
    level: parseInt(level),
  })

  const [isVisable, setIsVisable] = useState(false)

  useEffect(() => {
    if (!episode?._id) return
    Helper.accessLog('episodes', episode._id)
  }, [episode?._id])

  // carousel starts
  const [carouselIndex, setCarouselIndex] = useState(0)

  const carouselSlideNum =
    activities?.length === 1 ? 1 : activities?.length === 2 ? 2 : 3

  const lastIndex =
    activities?.length - carouselSlideNum < 0
      ? 0
      : activities?.length - carouselSlideNum

  const swiperRef = useRef(null)

  const handleScrollRight = () => {
    if (swiperRef.current) {
      swiperRef?.current?.swiper.slidePrev()
    }
  }

  const handleScrollLeft = () => {
    if (swiperRef.current) {
      swiperRef?.current?.swiper.slideNext()
    }
  }
  // carousel ends

  if (!epiSlug) return null
  if (level === '3') return null

  return (
    <>
      <Helmet>
        <title>عالم زيدو - حلقة</title>
      </Helmet>
      {isLoadingActivities || isLoadingEpisode ? <AppProgressBar /> : null}
      <AppBar fixed hideToggleButton title={episode?.title ?? ''} />

      <AppContent
        bgDecoration
        className="pages_anonymous_episodelist_appcontent"
      >
        <ContentTitle
          className="pages_anonymous_episodelist_contenttitle"
          noShadow
          textColor="text-[#4a4a4a]"
          title={episode?.title ?? ''}
        />
        {!isLoadingActivities && !isLoadingEpisode ? (
          <div className="flex flex-col items-center py-4 px-4 sm:px-16 pages_anonymous_episodelist_div_container">
            <div className="lg:mt-16 3xl:mt-24 w-full flex justify-center pages_anonymous_episodelist_episodechapter_container">
              <EpisodeChapters
                chapters={episode?.chapters ?? []}
                episodeSlug={epiSlug}
              />
            </div>
            {activities ? (
              <div className="flex items-center pages_anonymous_episodelist_carousel_control">
                {carouselIndex !== 0 ? (
                  <button
                    className="z-30 pages_anonymous_episodelist_carousel_control_button"
                    onClick={handleScrollRight}
                    type="button"
                  >
                    <img
                      alt=""
                      className="ml-4 h-8 w-8 lg:h-16 lg:w-16 pages_anonymous_episodelist_carousel_control_img"
                      src={RIGHT_ARROW}
                    />
                  </button>
                ) : (
                  <div className="ml-4 w-8 lg:w-16 pages_anonymous_episodelist_carousel_control_placeholder" />
                )}

                <div className="lg:mt-4 pages_anonymous_episodelist_swiper_container_div hide-scroll flex p-1 max-w-[340px] sm:max-w-[410px] md:max-w-[440px] lg:max-w-[760px] xl:max-w-[1000px] overflow-x-auto">
                  <Swiper
                    className="pages_anonymous_episodelist_swiper_container"
                    onSlideChange={(swiper) => {
                      setCarouselIndex(swiper.activeIndex)
                    }}
                    ref={swiperRef}
                    slidesPerView={carouselSlideNum}
                  >
                    {activities.map((oneActivity, index) => {
                      return (
                        <SwiperSlide
                          className="flex justify-center items-center pages_anonymous_episodelist_swiper_slide"
                          key={index}
                        >
                          <div className="flex shrink-0">
                            <ActivitySmallThumbnail
                              data={oneActivity}
                              index={index}
                              isAnonymous
                              key={index}
                              newUI
                              noStars
                              setIsVisable={setIsVisable}
                              // locked={!oneActivity.isFree}
                            />
                          </div>
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>
                </div>

                {carouselIndex !== lastIndex ? (
                  <button
                    className="z-30 pages_anonymous_episodelist_carousel_control_button_left"
                    onClick={handleScrollLeft}
                    type="button"
                  >
                    <img
                      alt=""
                      className="mr-4 h-8 w-8 lg:h-16 lg:w-16 pages_anonymous_episodelist_control_button_left_img"
                      src={LEFT_ARROW}
                    />
                  </button>
                ) : (
                  <div className="mr-4 w-8 lg:w-16 pages_anonymous_episodelist_control_button_left_placeholder" />
                )}
              </div>
            ) : null}
          </div>
        ) : null}
      </AppContent>
      <Popup
        acceptLink="/login"
        buttonText={REGISTER_NOW_MESSAGE}
        message={REQUIRES_REGISTRATION_MESSAGE}
        onClose={() => {
          setIsVisable(false)
        }}
        title=""
        visible={isVisable}
      />
      {/* <ZidoPointer /> */}
    </>
  )
}

export default EpisodeLists

const EpisodeChapters: FC<{
  episodeSlug: string
  chapters: IChapterListItem[]
}> = (props) => {
  const { level } = useParams<{ level: string }>()
  return (
    <ul className="grid pages_anonymous_episodelist_episodechapters_ul_container grid-cols-1 place-items-center w-[65%] lg:max-w-2xl">
      {props.chapters.map(function (chapter) {
        // const locked = !chapter.watched && idx > 0
        const locked = false
        return (
          <ChapterThumbnail
            chapterData={chapter}
            episodeSlug={props.episodeSlug}
            key={chapter._id}
            level={level}
            locked={locked}
          />
        )
      })}
    </ul>
  )
}

const ChapterThumbnail: FC<{
  episodeSlug: string
  chapterData: IChapterListItem
  locked: boolean
  level: string
}> = (props) => {
  const url =
    '/anonymous-episode/' +
    props.episodeSlug +
    '/' +
    props.chapterData.slug +
    '/' +
    props.level
  const image = Helper.MediaURL(props.chapterData.thumbnail)

  return (
    <li className="w-[70%] sm:w-[80%] pages_anonymous_episodelist_chapter_li_container lg:w-full mt-4 rounded-3xl border-4 border-white relative">
      {props.locked ? <LockedVideoOverlay /> : null}
      <Link
        className="pages_anonymous_episodelist_li_link"
        to={props.locked ? '' : url}
      >
        <img
          alt={props.chapterData.chapter}
          className="h-[140px] pages_anonymous_episodelist_chapter_li_img sm:h-[155px] md:h-[160px] lg:h-[280px] w-full object-cover rounded-[20px]"
          draggable="false"
          src={image}
        />
        {/* <div className="text-white text-xl text-center pb-2 absolute w-full bottom-0">
          {props.chapterData.title}
        </div> */}
        {!props.locked ? <PlayVideoOverlay /> : null}
      </Link>
    </li>
  )
}

const LockedVideoOverlay: FC = () => {
  return (
    <div className="z-10 pages_anonymous_episodelist_lockedvideo_overlay_container absolute w-full h-full bg-black bg-opacity-40 flex items-center justify-center rounded-[20px]">
      <img
        alt=""
        className="pages_anonymous_episodelist_video_overlay_img"
        draggable="false"
        src={lockIcon}
      />
    </div>
  )
}

const PlayVideoOverlay: FC = () => {
  return (
    <img
      alt=""
      className="absolute top-1/2 pages_anonymous_episodelist_playvideo_overlay_img left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-16 "
      draggable="false"
      src={PLAY_ICON_NEW}
    />
  )
}
