import React, { useEffect, useRef, FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { IPrize } from '@src/types/api.interface'
import { ContentTitle } from '@components/ContentTitle'
import { AppBar, AppContent } from '@components/Layout'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import { useCompetition } from '@hooks/data/fetchers/competition/use-competition'
import { GtagEvents } from '@utils/gtag-events'

const CompetitionPrizes: FC = () => {
  const { competition, isLoadingCompetition } = useCompetition()

  const eventSent = useRef(false)
  useEffect(() => {
    if (eventSent.current) return
    if (!competition) return
    GtagEvents.openCompetitionPrize({
      level: competition.level,
      competitionName: competition.uniqueName,
    })

    eventSent.current = true
  }, [competition])

  return (
    <>
      <Helmet>
        <title className="competition_prizes_page-main-title">
          عالم زيدو - جوائز المسابقات
        </title>
      </Helmet>
      {isLoadingCompetition ? <AppProgressBar /> : null}
      <AppBar
        backButtonLocation="/competition"
        fixed
        showBackButton={true}
        showLogoutButton
        showProfileButton
        title="جوائز المسابقات"
        withBgTheatre
      />
      <AppContent className="bg-no-repeat bg-cover bg-center bg-red">
        <div className="competition_prizes_page-container h-screen bg-theatre-sides">
          <ContentTitle title="جوائز المسابقات" />
          {competition?.prizesImage ? (
            <img
              alt=""
              className="competition_prizes_page-img absolute bottom-0 max-w-[85%] max-h-[85%] left-1/2 -translate-x-1/2"
              src={competition.prizesImage}
            />
          ) : null}
          {competition && !competition.prizesImage ? (
            <div className="competition_prizes_page-prize-item-container pt-10 flex justify-center">
              <div className="competition_prizes_page-prize-item-wrapper max-w-xl px-4 flex flex-col w-full items-center justify-center">
                {competition?.prizes.map((prize) => {
                  return <PrizeItem key={prize.uniqueName} prize={prize} />
                })}
              </div>
            </div>
          ) : null}
        </div>
      </AppContent>
    </>
  )
}

export default CompetitionPrizes

const PrizeItem: FC<{ prize: IPrize }> = (props) => {
  return (
    <div
      className={`competition_prizes_page-prize-wrapper-${props.prize.name} bg-white rounded-lg border-2 p-2 w-full border-[#f6580f] mb-4 flex items-center justify-between `}
    >
      <span
        className={`competition_prizes_page-prize-name-${props.prize.name} font-bold text-2xl flex-1 w-full`}
      >
        {props.prize.name}
      </span>
      <img
        alt=""
        className={`competition_prizes_page-prize-img-${props.prize.name} max-w-[150px] w-full`}
        src={props.prize.image}
      />
    </div>
  )
}
