import { AppContent } from '@components/Layout'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { Profile } from '@src/types/api.interface'
import { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'
import parentImageSrc from '../resources/images/group_13139.png'
// using dependencies
import KidCardsSlider from '@components/Layout/KidCard/KidCardsSlider'
import { UserAvatar } from '@components/Layout/KidCard/UserAvatar'
import { useMobileView } from '@hooks/useMobileView'
import {
  MESSAGE_KID_CONTROL,
  MESSAGE_KID_CONTROL_SUBHEADLINE,
  MESSAGE_PARENT_SECTION,
} from '@src/constants'
import {
  KID_AVATAR_FEMALE_NO_EYES_ICON,
  KID_AVATAR_MALE_NO_EYES_ICON,
} from '@utils/constants'
import 'swiper/css'
import { SwiperSlide } from 'swiper/react'

interface CardProps {
  kid: Profile
  index: string
  handleClick: (activity: Profile) => void
}

const ListKidHome: FC = () => {
  const history = useHistory()
  const isMobileView = useMobileView()
  const { parentProfile, setActiveKid, logout } = useProfile()

  if (parentProfile === undefined && history.location.pathname !== 'login') {
    history.push('/login')
  }
  if (parentProfile?.email === 'user@anonymous.firebase') {
    logout()
  }
  if (!parentProfile?.kids[0]) {
    history.push('/add-kid')
  }

  const handleClick = (kid: Profile) => {
    setActiveKid(kid)
    history.push('/kids-home')
  }

  const numberOfKids = parentProfile?.kids?.length ?? 0

  const goToParentPage = () => {
    history.push('/parent', {
      type: 'local',
      from: history.location.pathname,
    })
  }

  return (
    <>
      <Helmet>
        <title className="list_kid_home_page-main-title"> الأطفال </title>
      </Helmet>
      <AppContent className="mx-auto bg-no-repeat bg-cover bg-center bg-green-v3 overflow-y-scroll h-screen hide-scroll">
        <div className="list_kid_home_page-container justify-center min-h-full	lg:min-h-max relative mx-auto container flex flex-col items-center">
          <div className="list_kid_home_page-wrapper self-start flex flex-row overflow-hidden">
            <div
              className="list_kid_home_page-main w-[143px] h-[50px] mt-[10px] lg:mt-[20px] cursor-pointer bg-parent-gateway  bg-no-repeat"
              onClick={goToParentPage}
              role="button"
            >
              <div className="list_kid_home_page-item flex items-center justify-center h-full">
                <img
                  alt=""
                  className="list_kid_home_page-img h-[30px] mx-1"
                  src={parentImageSrc}
                />
                <p className="list_kid_home_page-message-parent-section text-white text-center text-base xl:text-xl mr-1">
                  {MESSAGE_PARENT_SECTION}
                </p>
              </div>
            </div>
          </div>
          <div className="list_kid_home_page-message-kid-control-wrapper w-full max-w-[100%] flex items-center justify-center text-[40px] mb-2 md:mb-5 lg:mb-14 mt-3 lg:mt-8 flex-col gap-y-2 md:gap-y-5 lg:gap-y-10	">
            <h1 className="list_kid_home_page-message-kid-control-heading text-[25px] lg:text-3xl font-bold text-yellow">
              {MESSAGE_KID_CONTROL}
            </h1>
            <p className="list_kid_home_page-message-kid-control-sub-headline text-xl text-white">
              {MESSAGE_KID_CONTROL_SUBHEADLINE}
            </p>
          </div>
          <div className="list_kid_home_page-parent-profile-kids-container relative w-[360px] lg:w-[600px] min-[1025px]:w-full xl:w-[1280px]">
            {parentProfile?.kids && parentProfile?.kids.length > 0 ? (
              <div className="list_kid_home_page-parent-profile-kids-wrapper sm:gap-x-4 gap-y-8 w-full flex items-center lg:pt-8 xl:pt-12 lg:mt-5 mx-auto text-center xl:pb-16">
                <KidCardsSlider
                  centerInsufficientSlides={numberOfKids < 4}
                  isSingleChild={numberOfKids === 1}
                  pagination={false}
                  slidesPerView={isMobileView ? 2 : 4}
                >
                  {parentProfile.kids.map((kid) => {
                    return (
                      <SwiperSlide key={kid?._id}>
                        <Card
                          handleClick={handleClick}
                          index={kid._id}
                          kid={kid}
                        />
                      </SwiperSlide>
                    )
                  })}
                </KidCardsSlider>
              </div>
            ) : null}
          </div>
        </div>
      </AppContent>
    </>
  )
}

const Card: FC<CardProps> = ({ kid, index, handleClick }) => {
  return (
    <div
      className={`list_kid_home_page-kid-card-${kid.nickname} flex shrink-0`}
    >
      <div
        className={`list_kid_home_page-kid-card-wrapper-${kid.nickname} flex items-center justify-center bg-[#F6F5E9] rounded-[40px] h-[160px] w-[170px] lg:w-[220px] lg:h-[200px] xl:h-[252px] xl:w-[265px] cursor-pointer`}
        key={index}
        onClick={() => handleClick(kid)}
      >
        <div
          className={`list_kid_home_page-grid-item-${kid.nickname} grid grid-rows-1 place-items-center justify-items-center`}
        >
          <UserAvatar
            avatarSize={60}
            className={`list_kid_home_page-user-avatar-${kid.nickname} h-[80px] w-[80px] lg:h-[97px] lg:w-[97px]`}
            fill="#81C2A1"
            image={
              kid.gender === 'male'
                ? KID_AVATAR_MALE_NO_EYES_ICON
                : KID_AVATAR_FEMALE_NO_EYES_ICON
            }
          />
          <div
            className={`list_kid_home_page-kid-nickname-${kid.nickname} text-[20px] md:text-[20px] lg:text-[30px] mt-1 xl:mt-3 font-medium`}
          >
            {kid.nickname.trim().length === 0 ? kid.firstName : kid.nickname}
          </div>
          <div
            className={`list_kid_home_page-kid-level-${kid.nickname} text-lightGrey text-[15px] md:text-[17px]`}
          >
            المستوى : {kid.level}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListKidHome
