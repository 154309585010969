import { IEdugoals } from '@src/types/api.interface'
import { FetchError } from '@utils/FetchError'
import useSwr from 'swr'

export function useAllEdugoals() {
  const url = '/edugoals/all'

  const { data, error } = useSwr<IEdugoals[], FetchError>(url)

  return {
    edugoals: data ?? [],
    error,
    isLoadingEdugoals: !error && !data,
  }
}
