import React, { useEffect, FC, useState } from 'react'

import { Link, Redirect } from 'react-router-dom'
import { AppBar, AppContent } from '@components/Layout'
import { ContentTitle } from '@components/ContentTitle'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'

import Helper from '@utils/Helpers'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import { IEpisodeListItem } from '@src/types/api.interface'
import { useEpisodes } from '@hooks/data/fetchers/use-episodes'
import { Helmet } from 'react-helmet-async'
import Config from '@src/config'
import Popup from '@components/Popup'
import { usePurchases } from '@hooks/use-purchases'
import {
  REQUIRES_SUBSCRIBE_MESSAGE,
  SUBSCRIBE_NOW_MESSAGE,
  THIS_CONTENT_MESSAGE,
} from '@src/constants/messages'

const Theatre: FC = () => {
  const { kidProfile: profile } = useProfile()
  const level = profile?.level

  const { episodes, isLoadingEpisodes } = useEpisodes()

  const [isVisable, setIsVisable] = useState(false)

  const [hasProAccess, setHasProAccess] = useState(false)

  const [loading, setLoading] = useState(true)

  const purchases = usePurchases()

  useEffect(() => {
    purchases
      .isHavingProAccess(level === 1 ? 'level-1' : 'level-2')
      .then(setHasProAccess)
      .then(() => setLoading(false))
  }, [level, purchases])

  useEffect(() => {
    if (!episodes) return
    Helper.accessLog('episodes', null, 'episodes list', true)
  }, [episodes])

  if (level === 3 || level === 2) return <Redirect push={true} to="/notfound" />

  if (level === 1 && profile?.pendingUnit) {
    return (
      <Redirect
        push={true}
        to={`/episode/${profile.pendingUnit.slug}/chapters`}
      />
    )
  }

  return (
    <>
      <Helmet>
        <title className="label_one_page_title">
          عالم زيدو - الصفحة الرئيسية من المستوى 1
        </title>
      </Helmet>
      {loading || isLoadingEpisodes ? <AppProgressBar /> : null}
      <AppBar
        backButtonLocation="/kids-home"
        fixed
        showBackButton={true}
        showLogoutButton
        showProfileButton
        title="حلقات تامر و تمورة"
        titleCenter
        withBgTheatre
      />
      <AppContent className="label_one_page_app_content bg-theatre">
        <ContentTitle
          noShadow
          textColor="text-[#4a4a4a]"
          title="حلقات تامر و تمورة"
        />
        {episodes && !loading ? (
          <Episodes
            data={episodes}
            hasProAccess={hasProAccess}
            setIsVisable={setIsVisable}
          />
        ) : null}
        <Popup
          acceptLink={!hasProAccess ? '/parent?redirect=subscribe' : null}
          buttonText={!hasProAccess ? SUBSCRIBE_NOW_MESSAGE : null}
          message={REQUIRES_SUBSCRIBE_MESSAGE}
          onClose={() => setIsVisable(false)}
          title={THIS_CONTENT_MESSAGE}
          visible={isVisable}
        />
      </AppContent>
    </>
  )
}

export default Theatre

const Episodes: FC<{
  data: IEpisodeListItem[]
  setIsVisable: (isVisible: boolean) => void
  hasProAccess: boolean
}> = (props) => {
  return (
    <div className="label_one_page_episodes_wrapper px-2 sm:px-10 flex justify-center">
      <ul className="label_one_page_episodes_lists w-full md:w-[75%] mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-4 transition-all duration-200">
        {props.data.map(function (episode) {
          return (
            <li
              className={`label_one_page_episodes_list_${episode.slug} animate-fadein`}
              key={episode.slug}
            >
              <EpisodeThumbnail
                data={episode}
                hasProAccess={props.hasProAccess}
                setIsVisable={props.setIsVisable}
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

const EpisodeThumbnail: FC<{
  data: IEpisodeListItem
  setIsVisable: (isVisible: boolean) => void
  hasProAccess: boolean
}> = (props) => {
  const url = '/episode/' + props.data.slug + '/chapters'
  const locked = !props.hasProAccess && !props.data.isFree
  return (
    <div
      className={`relative label_one_page_episode_thumbnail_${props.data.slug}`}
    >
      {locked ? <LockedVideoOverlay setIsVisable={props.setIsVisable} /> : null}
      <Link
        className={`label_one_page_episode_thumbnail_link_${props.data.slug} flex-row w-full inline-flex items-center justify-center sm:flex-col bg-white rounded-xl transition-all duration-200`}
        to={locked ? '' : url}
      >
        <img
          alt={props.data.slug}
          className={`label_one_page_episode_thumbnail_img_${props.data.slug} h-[180px] w-[288px] sm:min-h-[180px] md:min-h-[162px] lg:min-h-[230px] 2xl:min-h-[324px] sm:max-h-[180px] md:max-h-[200px] lg:max-h-[450px] min-w-[100px] sm:w-full border-[5px] border-white rounded-xl transition-all duration-200`}
          draggable="false"
          src={Helper.MediaURL(props.data.thumbnail)}
        />
        <div
          className={`label_one_page_episode_thumbnail_locked_${props.data.slug} w-full p-1 text-[#4a4a4a] text-right sm:text-center text-lg sm:text-xl font-bold`}
        >
          {locked}
        </div>
      </Link>
    </div>
  )
}

const lockIcon = `${Config.publicURL}/images/lock-icon.svg`

const LockedVideoOverlay: FC<{ setIsVisable: (isVisible: boolean) => void }> = (
  props
) => {
  return (
    <div
      className="label_one_page_episode_thumbnail_lock_icon_wrapper cursor-pointer z-10 absolute w-full h-full bg-black  rounded-xl bg-opacity-40 flex items-center justify-center"
      onClick={() => props.setIsVisable(true)}
    >
      <img
        alt=""
        className="label_one_page_episode_thumbnail_lock_icon"
        draggable="false"
        src={lockIcon}
      />
    </div>
  )
}
