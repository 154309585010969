import { usePushNotificationsV2 } from '@hooks/data/use-push-notification-v2'
import { usePurchases } from '@hooks/use-purchases'
import * as Sentry from '@sentry/react'
import { cleverTapEvents } from '@src/CleverTap/CleverTap'
import { ParentProfile, Profile } from '@src/types/api.interface'
import { isFetchError } from '@utils/FetchError'
import Helper from '@utils/Helpers'
import { signOut } from '@utils/firebase-auth'
import HelperAPI from '@utils/helpers/helper.api'
import HelperJWT from '@utils/helpers/helper.jwt'
import HelperProfile from '@utils/helpers/helper.profile'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { mutate } from 'swr'
import Config from '../../config'
import {
  IChangePasswordProps,
  IProfileContext,
  LoginProps,
  LoginResponse,
  ProfileProps,
} from './types/ProfileContext.types'

export const ProfileContext = createContext<IProfileContext>({
  setActiveKid: () => null,
  parentProfile: undefined,
  logout: () => null,
  login: async () => null,
  register: async () => null,
  changePassword: async () => null,
  updateUnlockingTime: async () => null,
  storedProfile: async () => null,
  toggleHandbookIdicator: async () => null,
  removeKid: async () => null,
  removeAccount: async () => null,
  kidProfile: null,
  profileType: null,
  setParentProfile: async () => null,
  setProfileType: (_type: 'parent' | 'child' | 'anonymous') => null,
  isLoaded: false,
})

export const protectedRoutes = [
  'maqraa',
  'l2',
  'l3',
  'profile',
  'activities',
  'gallery',
  'theatre',
  'episode',
  'add-kid',
  'list-kid',
  'list-kid-home',
  'select-kid-home',
  'select-kid',
  'parent',
  'kids-report',
  'subscription',
  'notification',
  'library',
  'app-browser',
  'settings',
  'kids-home-anonymous',
  'kids-home',
  'anonymous',
  'maqraa-anonymous',
  'anonymous-episode',
  'series-home',
  'series',
  'competition',
]

// TODO change API to call Parent information API
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ProfileProvider = (props: any) => {
  const purchase = usePurchases()
  const notification = usePushNotificationsV2()
  const history = useHistory()
  const getStoredKidProfileTopLevel = HelperProfile.getStoredKid()

  const [kidProfile, setActiveKid] = useState<Profile>(
    getStoredKidProfileTopLevel
  )
  const getStoredProfileTopLevel = HelperProfile.getStoredProfile()
  const [profile, setProfile] = useState<ParentProfile>(
    getStoredProfileTopLevel
  )
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [profileType, setProfileType] = useState<
    'parent' | 'child' | 'anonymous'
  >(HelperProfile.getProfileCategory() ?? 'parent')

  const anonUserEmail = Config.firebase.anonUser
  /**
   * Get parent profile from local storage
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const storedProfile = async (
    forced = false
  ): Promise<ParentProfile | undefined> => {
    const getStoredProfile = HelperProfile.getStoredProfile()
    const swrRoute = forced
      ? '/level/parentInfo'
      : !getStoredProfile?._id
      ? '/level/parentInfo'
      : null
    const firstUrlSegment =
      new URL(window.location?.href).pathname.split('/').find((e) => e) ?? ''
    const isProtectedRoute = protectedRoutes.includes(firstUrlSegment)
    const redirect = () => {
      // eslint-disable-next-line no-self-compare, no-constant-condition
      if (isProtectedRoute && !getStoredProfile) {
        history.replace('/')
      }
    }
    if (swrRoute) {
      try {
        const data: ParentProfile = await HelperAPI.ApiRequest(swrRoute)
        if (data.email === anonUserEmail) {
          setProfileType('anonymous')
        }
        HelperProfile.setStoredProfile(data)

        return data
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        if (isFetchError(e) || e.response.status === 401 || e.status === 401) {
          redirect()
        }
        console.error(e)
        Sentry.captureException(e)
        return getStoredProfile
      }
    } else {
      redirect()
    }
    setIsLoaded(true)
    HelperProfile.setStoredProfile(getStoredProfile)
    return getStoredProfile
  }

  /**
   * Get profile from backend
   */

  /**
   * Get kid profile from localStorage
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/no-explicit-any
  // const kidProfile = () => {
  //   if (activeKid) return activeKid
  //   // get it from cookie in reload
  //   return HelperProfile.getStoredKid()
  // }

  /**
   * logout method for profile context
   */
  const login = useCallback(
    async (props: LoginProps): Promise<ProfileProps> => {
      const isFirebase = props.provider === 'firebase'
      const URL = !isFirebase
        ? '/auth/login'
        : '/firebase_auth/register-and-login'
      try {
        const response = await HelperAPI.ApiRequest<LoginResponse>(URL, {
          method: 'POST',
          body: isFirebase
            ? JSON.stringify({ accessToken: props.accessToken })
            : JSON.stringify(props),
        })
        const loginResponse = response
        HelperJWT.setJwtToken(loginResponse.token)
        const profile = await storedProfile()
        await Helper.accessLog(null, null, 'login', true)
        purchase.setup(profile._id)
        await notification.registerUserToken(profile._id)
        setProfile(profile)
        setProfileType('parent')
        HelperProfile.setProfileType('legacy')
        HelperProfile.setStoredProfile(profile)
        // GtagEvents.login({
        //   level: profile.level,
        //   type: isFirebase ? 'token' : 'password',
        //   parentId: profile._id,
        // })
        cleverTapEvents.setUserLoggedInProfile({
          parentId: profile._id,
          level: profile.level,
          type: isFirebase ? 'token' : 'password',
          email: profile.email,
          name: profile.firstName,
        })
        return profile ?? null
      } catch (err) {
        Sentry.captureException(err)
        console.error(err)
        if (isFirebase) {
          signOut()
        }
        if (isFetchError(err)) {
          toast.error(err.message, { toastId: 'loginError' })
          return err.message
        }
        return 'حدث خطأ'
      }
    },
    // TODO: Revisit this for proper useEffect and  useCallback implementation
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notification]
  )

  const register = useCallback(
    async (props: LoginProps): Promise<ProfileProps> => {
      const bodyToSend = {
        email: props.username,
        password: props.password,
        firstName: props.username ? props.username.split('@')[0] : '',
      }
      try {
        const URL = '/auth/register'
        const response = await HelperAPI.ApiRequest<LoginResponse>(URL, {
          method: 'POST',
          body: JSON.stringify(bodyToSend),
        })
        toast.info('جاري تسجيلك كمستخدم جديد ...', {
          toastId: 'registeringUser',
          autoClose: false,
        })
        const registerResponse = response
        HelperJWT.setJwtToken(registerResponse.token)
        const profile = await storedProfile()
        await Helper.accessLog(null, null, 'register', true)
        purchase.setup(profile._id)
        await notification.registerUserToken(profile._id)
        setProfile(profile)
        setProfileType(profile.email === anonUserEmail ? 'anonymous' : 'parent')
        HelperProfile.setProfileType('legacy')
        HelperProfile.setStoredProfile(profile)
        // GtagEvents.register({
        //   level: profile.level,
        //   parentId: profile._id,
        //   type: 'password',
        // })
        cleverTapEvents.setUserLoggedInProfile({
          parentId: profile._id,
          level: profile.level,
          email: profile.email,
          name: profile.firstName,
          type: 'password',
        })
        return profile ?? null
      } catch (err) {
        if (isFetchError(err)) {
          if (err.data && err.data !== '' && err.message !== 'Unauthorized') {
            // eslint-disable-next-line array-callback-return
            Object.values(err.data).map((message, key) => {
              toast.error(message, { toastId: key })
            })
            history.replace('/register')
          } else {
            toast.success('تم التسجيل بنجاح حاول تسجيل  الدخول الان', {
              toastId: 'registerSuccess',
            })
          }
          return err.message
        }
        return 'حدث خطأ'
      } finally {
        toast.dismiss('registeringUser')
      }
    },
    // TODO: Revisit this for proper useEffect and  useCallback implementation
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notification, history]
  )

  /**
   * logout method for profile context
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const logout = useCallback(() => {
    const type = HelperProfile.getProfileType()
    const location = type === 'anon' ? '/' : '/login'
    try {
      Helper.logOut('profileContext-logoutDedup')
      if (profile.email !== 'user@anonymous.firebase') {
        notification.unregisterUserToken()
        purchase.revoke()
      }
      cleverTapEvents.updateUserLoggedInprofile({})
      signOut()
      HelperJWT.removeJwtToken()
      localStorage.removeItem('provider')
      setTimeout(() => {
        window.location.replace(location)
      }, 1000)
    } catch (error) {
      console.error(JSON.stringify(error))
      Sentry.captureException(error)
      window.location.replace(location)
    }
  }, [notification, profile, purchase])

  /**
   * Change password method.
   */
  const changePassword = useCallback(async (data: IChangePasswordProps) => {
    try {
      const url = '/auth/change-password'
      await HelperAPI.ApiRequest(url, {
        method: 'PUT',
        body: JSON.stringify(data),
      })
    } catch (e) {
      if (isFetchError(e)) {
        toast.error((e.data as { message?: string })?.message ?? e.message)
        throw e
      }
      Sentry.captureEvent(e)
    }
  }, [])

  const updateUnlockingTime = async (slug: string): Promise<void> => {
    try {
      await HelperAPI.ApiRequest(`/pmooc/${kidProfile._id}/startunit/${slug}`, {
        method: 'POST',
      })
      mutate(`/schedule-kids/${profile._id}/kid`)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const toggleHandbookIdicator = async (
    kid: Profile,
    value: boolean,
    slug?: string
  ): Promise<void> => {
    try {
      const kidId = kid._id
      await HelperAPI.ApiRequest(`/level/${kid.level}/handbook`, {
        method: 'POST',
        body: JSON.stringify({
          kidId,
          value,
          slug,
        }),
      })
    } catch (error) {
      console.error(error)
    }
    storedProfile(true) // refresh profile
  }

  const removeAccount = async (): Promise<void> => {
    try {
      await HelperAPI.ApiRequest(`/accounts/${profile._id}`, {
        method: 'DELETE',
      })
      logout()
    } catch (e) {
      Sentry.captureException(e)
      logout()
    }
  }

  const removeKid = async (kidProfileParam: Profile): Promise<void> => {
    try {
      await HelperAPI.ApiRequest(`/kids/${kidProfileParam._id}`, {
        method: 'DELETE',
      })
      const newKids = profile.kids.filter(
        (kid) => kid._id !== kidProfileParam._id
      )
      const newParentProfile = {
        ...profile,
        kids: newKids,
      }
      setProfile(newParentProfile)
      HelperProfile.setStoredProfile(newParentProfile)
      if (kidProfileParam._id === kidProfile._id) {
        setKidProfile(null)
        HelperProfile.setStoredKid(null)
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  const dedup = useRef(null)
  useEffect(() => {
    clearTimeout(dedup.current)
    dedup.current = setTimeout(() => {
      HelperProfile.setProfileCategory(profileType)
    }, 500)
  }, [profileType])

  /**
   * Set active kid profile in the localsotrage
   */
  const setKidProfile = (profile: Profile) => {
    if (profile) {
      setActiveKid(profile)
      setProfileType('child')
    } else {
      setActiveKid(null)
      setProfileType('parent')
    }
  }
  useEffect(() => {
    HelperProfile.setStoredKid(kidProfile)
  }, [kidProfile])

  return (
    <ProfileContext.Provider
      value={{
        setActiveKid: setKidProfile,
        kidProfile,
        profileType,
        setProfileType,
        parentProfile: profile,
        setParentProfile: setProfile,
        isLoaded,
        logout,
        login,
        register,
        changePassword,
        updateUnlockingTime,
        storedProfile, // refresh profile
        toggleHandbookIdicator,
        removeKid,
        removeAccount,
      }}
    >
      {props.children}
    </ProfileContext.Provider>
  )
}

export function useProfile(): IProfileContext {
  return useContext(ProfileContext)
}
