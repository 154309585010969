import { ContentModal } from '@components/ContentModal'
import { AppBar, AppContent } from '@components/Layout'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import Config from '@config/index'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { useEpisode } from '@hooks/data/fetchers/use-episode'
import { useScheduleKids } from '@hooks/data/fetchers/use-schedule-kids'
import { useEpisodeAllActivities } from '@hooks/data/fetchers/useEpisodeAllActivities'
import { usePurchases } from '@hooks/use-purchases'
import { IActivityNew, IChapterListItem } from '@src/types/api.interface'
import Helper from '@utils/Helpers'
import { logEvent } from '@utils/firebase-analytics'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import ScrollContainer from 'react-indiana-drag-scroll'
import SVG from 'react-inlinesvg'
import { useHistory, useParams } from 'react-router-dom'
import 'swiper/css'
import ActivityCard from './ActivityCard'
import { CustomPopup } from './CustomPopup'
import EpisodeChapters from './EpisodeChapters'
import { TYPOGRAPHY } from './constants'
import { useOpenedActivities } from '@hooks/data/fetchers/use-opened-activities'

const arrowIcon = `${Config.publicURL}/images/v3/decoration-arrow.svg`
const smileIcon = `${Config.publicURL}/images/v3/decoration-smile-sun.svg`
const houseIcon = `${Config.publicURL}/images/v3/decoration-house.svg`

export type PopupType = 'watched' | 'pending' | 'subscribed' | 'locked'

const Episode: FC = () => {
  const { epiSlug } = useParams<{ epiSlug: string }>()
  const { scheduleKid } = useScheduleKids()
  const purchases = usePurchases()

  const { episode, isLoadingEpisode } = useEpisodeAllActivities({
    slug: epiSlug,
  })

  // TODO: NEEDS TO REMOVE THIS ENDPOINT AND ADD THE CHAPTERS PROPERTY TO THE NEW EPISODE
  const {
    episode: legacyEpisode,
    isLoadingEpisode: isLoadingLegacyEpisode,
    mutateEpisode,
  } = useEpisode({ slug: epiSlug })

  const { kidProfile: profile } = useProfile()

  const { openedActivities, isLoadingOpenedActivities } = useOpenedActivities(
    profile?._id,
    episode?._id,
    profile?.level
  )

  const isLoading =
    isLoadingEpisode || isLoadingLegacyEpisode || isLoadingOpenedActivities

  const [hasProAccess, setHasProAccess] = useState(false)
  const [isVisable, setIsVisable] = useState(false)
  const [isLoadingPayment, setIsLoadingPayment] = useState(true)
  const [popupType, setPopupType] = useState<PopupType>('subscribed')

  const [bookLocked, setBookLocked] = useState(0)
  const [gameLocked, setGameLocked] = useState(0)
  const [freeSlots, setFreeSlots] = useState(false)
  const [firstDay, setFirstDay] = useState(false)
  const [lastDay, setLastDay] = useState(false)
  const history = useHistory()

  const [isVideoWatched, setIsVideoWatched] = useState(
    !!legacyEpisode?.chapters?.[0]?.watched
  )

  const [showContentPlayer, setShowContentPlayer] = useState(false)
  const [activeContent, setActiveContet] = useState<
    IChapterListItem | IActivityNew
  >()
  const [activeContentType, setActiveContentType] = useState<
    'activity' | 'video'
  >()

  const image = legacyEpisode?.chapters?.[0]?.thumbnail || ''
  const selectedImage = Helper.MediaURL(image)
  const style = {
    '--image-url': `url(${selectedImage})`,
  } as React.CSSProperties

  const level = profile?.level

  // reorder activities according to distribution
  const [orderedActivities, setOrderedActivities] = useState<IActivityNew[]>([])

  useEffect(() => {
    if (episode?.activities) {
      const distributions = scheduleKid?.activeSchedule?.distributions.filter(
        (dist) => dist.type !== 'video' && dist.type !== 'free'
      )

      const filteredActivities = [...(episode?.activities || [])]
      const orderedActivities = []

      if (distributions) {
        for (const distribution of distributions) {
          const matchedIndex = filteredActivities?.findIndex(
            (activity) => activity.category === distribution.type
          )

          if (matchedIndex !== -1) {
            orderedActivities.push(filteredActivities[matchedIndex])
          }
        }
      }

      const allActivities = [...orderedActivities, ...filteredActivities]

      const uniqueOderedActivities = allActivities.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t._id === value._id)
      )

      setOrderedActivities([...uniqueOderedActivities])
    }
  }, [scheduleKid, episode?.activities])

  useEffect(() => {
    if (!episode?._id) return
    Helper.accessLog('episodes', episode._id)
  }, [episode?._id])

  useEffect(() => {
    purchases
      .isHavingProAccess(profile?.level === 1 ? 'level-1' : 'level-2')
      .then(setHasProAccess)
      .then(() => {
        setIsLoadingPayment(false)
      })
  }, [profile?.level, purchases])

  useEffect(() => {
    logEvent('open_episode')
  }, [episode])

  useEffect(() => {
    if (
      scheduleKid?.activeSchedule?.distributionStatus &&
      scheduleKid?.activeSchedule?.enabled
    ) {
      // const daysPassed =
      //   scheduleKid.activeSchedule.period - scheduleKid.activePeriod
      const daysPassed = scheduleKid?.activePeriod + 1
      const distributions = scheduleKid?.activeSchedule?.distributions
        .filter((d) => d.day <= daysPassed)
        .sort((a, b) => a.day - b.day)

      // book
      const bookCount = distributions?.filter((d) => d.type === 'book').length
      if (bookCount > 0) setBookLocked(bookCount)
      // game
      const gameCount = distributions?.filter((d) => d.type === 'game').length
      if (gameCount > 0) setGameLocked(gameCount)
      // free slots
      setFreeSlots(distributions[daysPassed]?.type === 'free')
      // last day
      setLastDay(daysPassed >= scheduleKid?.activeSchedule.period)
      // first day
      setFirstDay(daysPassed === 1)
      if (
        scheduleKid?.activeSchedule.distributions.length <= 1 ||
        daysPassed < 0
      )
        setLastDay(true)
    } else {
      setLastDay(true)
    }
  }, [profile, scheduleKid])

  const onChapterClick = (chapter: IChapterListItem) => {
    if (!chapter.isFree && !hasProAccess) {
      return openSubscribePopup()
    }
    setShowContentPlayer(true)
    setActiveContet(chapter)
    setActiveContentType('video')
  }

  useEffect(() => {
    setIsVideoWatched(!!legacyEpisode?.chapters?.[0]?.watched)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [legacyEpisode?.chapters?.[0]?.watched])

  const [activityIndex, setActivityIndex] = useState(0)
  const onActivityClick = (
    activity: IActivityNew,
    status: boolean,
    index?: number
  ) => {
    if (status && !isVideoWatched) return openLockedPopup()
    if (hasProAccess && status) return openWatchedPopup()
    if (!activity.isFree && !hasProAccess) return openSubscribePopup()
    if (status) return openWatchedPopup()
    setShowContentPlayer(true)
    setActiveContet(activity)
    setActiveContentType('activity')
    setActivityIndex(index)
  }

  const openSubscribePopup = () => {
    setPopupType('subscribed')
    setIsVisable(true)
  }

  const openWatchedPopup = () => {
    setPopupType('watched')
    setIsVisable(true)
  }

  const openLockedPopup = () => {
    setPopupType('locked')
    setIsVisable(true)
  }

  if (!epiSlug) return null
  if (level === 3) return null

  const handleBookLocked = (
    activity: IActivityNew,
    activities: IActivityNew[]
  ) => {
    if (bookLocked === 0) return true
    return !activities
      .filter((a) => a.category === 'book')
      .slice(0, bookLocked)
      .some((a) => a._id === activity._id)
  }

  const handleGameLocked = (
    activity: IActivityNew,
    activities: IActivityNew[]
  ) => {
    if (gameLocked === 0) return true
    const offset = freeSlots ? 1 : 0
    return !activities
      .filter((a) => a.category === 'game')
      .slice(0, gameLocked - offset)
      .some((a) => a._id === activity._id)
  }

  function handleIsWatched(activity: IActivityNew) {
    if (openedActivities?.some((e) => e?.activity === activity?._id)) {
      return true
    }
    return false
  }

  function handleLockedActivity(
    activity: IActivityNew,
    activities: IActivityNew[]
  ): boolean {
    if (
      scheduleKid?.activeSchedule?.enabled &&
      epiSlug === scheduleKid?.lockedEpisode
    ) {
      if (firstDay) return true
      if (!hasProAccess && !activity.isFree) return true
      if (lastDay) return false
      switch (activity.category) {
        case 'book':
          return handleBookLocked(activity, activities)
        case 'game':
          return handleGameLocked(activity, activities)
        default:
          return false
      }
    }
    return !hasProAccess && !activity.isFree
  }

  return (
    <>
      <Helmet>
        <title>{TYPOGRAPHY.WEBSTIE_TITLE}</title>
      </Helmet>

      {isLoading || isLoadingPayment ? <AppProgressBar /> : null}
      <AppBar
        backButtonOnclick={() => history.goBack()}
        fixed
        hideToggleButton
        showBackButton
        showKidAvatar
        showLogoutButton
        showParentBackButton
        showProfileButton
        title={episode?.title ?? ''}
      />
      <AppContent
        className="bg-no-repeat pages_episodehome_appcontent bg-cover bg-center bg-dimming-overlay-green-[image:var(--image-url)] h-screen hide-scroll"
        haveFooter={false}
        haveHeader={false}
        style={style}
      >
        <div className="flex flex-col justify-between h-full pages_episodehome_container">
          <div className="flex flex-col justify-center items-center w-full pages_episodehome_title_container">
            <div className="relative">
              <h1 className="text-6xl md:text-8xl mt-10 xl:mt-24 text-white pages_episodehome_title_text">
                {episode?.title ?? ''}
              </h1>
              <SVG
                className="absolute -right-28 lg:-right-36 lg:scale-150 pages_episodehome_title_icon"
                src={arrowIcon}
              />
            </div>
          </div>

          <SVG
            className="absolute pages_episodehome_smile_icon top-3/4 -translate-y-3/4 left-4 lg:scale-150 lg:top-[65%] lg:-translate-y-[65%]"
            src={smileIcon}
          />
          <SVG
            className="absolute bottom-2 pages_episodehome_house_icon right-1 lg:scale-150 lg:bottom-8"
            src={houseIcon}
          />

          {!isLoading && !isLoadingPayment && episode?.activities ? (
            <ScrollContainer
              className="flex overflow-scroll pages_episodehome_scroll_container hide-scroll gap-x-3 lg:gap-x-6 px-10 pb-7 lg:pb-16"
              hideScrollbars
            >
              <EpisodeChapters
                chapters={legacyEpisode?.chapters ?? []}
                hasProAccess={hasProAccess}
                onChapterClick={onChapterClick}
              />
              {orderedActivities?.map((activity, index, arr) => (
                <ActivityCard
                  data={activity}
                  index={index}
                  isLocked={
                    handleLockedActivity(activity, arr) || !isVideoWatched
                  }
                  isWatched={handleIsWatched(activity)}
                  key={index}
                  setIsVisable={(status) =>
                    onActivityClick(activity, status, index)
                  }
                />
              ))}
            </ScrollContainer>
          ) : null}
        </div>
      </AppContent>

      {showContentPlayer ? (
        <ContentModal
          content={activeContent}
          contentType={activeContentType}
          epiSlug={epiSlug}
          episode={legacyEpisode}
          index={activityIndex}
          onClose={() => {
            setShowContentPlayer(false)
            mutateEpisode()
          }}
        />
      ) : null}

      <CustomPopup
        onPopupClose={() => {
          setIsVisable(false)
        }}
        popupType={popupType}
        visible={isVisable}
      />
    </>
  )
}

export default Episode
