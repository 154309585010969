// THIS HOOK IS DIFFERENT THAN THE OTHER use-episode, IT RETURNS THE THE ACTIVITIES UNCONDITIONALLY
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { IEpisodeNew } from '@src/types/api.interface'
import { FetchError } from '@utils/FetchError'
import useSwr from 'swr'

export function useEpisodeAllActivities(props: { slug: string }) {
  const { kidProfile: profile } = useProfile()

  const url =
    profile?.level && profile._id
      ? `/episodes/${props.slug}/episode-activities`
      : null

  const { data, error, mutate } = useSwr<IEpisodeNew, FetchError>(url)

  return {
    episode: data,
    error,
    isLoadingEpisode: !error && !data,
    mutateEpisode: mutate,
  }
}
