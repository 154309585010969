import React, { useCallback, useEffect, useRef, useState, FC } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import 'video.js/dist/video-js.min.css'
import { ContentTitle } from '@components/ContentTitle'
import { GameIFrame } from '@components/GameIframe'
import { AppBar, AppContent } from '@components/Layout'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import Modal from '@components/Modal'
import VideoPlayer from '@components/videoplayer'
import Config from '@config/index'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import Helper from '@utils/Helpers'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faGamepad } from '@fortawesome/free-solid-svg-icons'
import { useChapter } from '@hooks/data/fetchers/use-chapter'
import { useEpisode } from '@hooks/data/fetchers/use-episode'
import {
  IActivityDetail,
  IResponseCompletedChapter,
  ITrackingSubmit,
} from '@src/types/api.interface'
// import { useCompletedEpisodes } from '@hooks/data/fetchers/use-completed-episodes'
import { useJwtToken } from '@hooks/data/storage/use-jwt-token'
import { useActivity } from '@hooks/data/fetchers/use-activity'
import { Helmet } from 'react-helmet-async'
import { logEvent } from '@utils/firebase-analytics'
import HelperAPI from '@utils/helpers/helper.api'
import { toast } from 'react-toastify'

/**
 * @deprecated Move from page to modal style.
 * Replacement component is <ContentModal/>
 */
const Chapter: FC = () => {
  const location = useLocation()
  const fromLocation = location.state

  const [fromLocationState, setFromLocationState] = useState('')

  useEffect(() => {
    if (fromLocation === 'maktabah') {
      setFromLocationState('maktabah')
    }
  }, [fromLocation])

  const timeoutsRef = useRef<number[]>([])

  const { chapSlug, epiSlug } = useParams<{
    epiSlug: string
    chapSlug: string
  }>()

  const { episode, mutateEpisode } = useEpisode({ slug: epiSlug })

  const {
    kidProfile: profile,
    updateUnlockingTime,
    toggleHandbookIdicator,
  } = useProfile()

  const { isLoadingChapter, chapter } = useChapter({ chapSlug, epiSlug })

  const openedChapter = useCallback(async () => {
    if (profile && chapter && episode) {
      if (!profile.handbookIndicatorFlag) {
        await toggleHandbookIdicator(profile, true, chapter.slug)
      }

      await HelperAPI.ApiRequest(
        `/level/${profile.level}/episodes/chapters/view`,
        {
          method: 'POST',
          body: JSON.stringify({
            kid: profile._id,
            chapter: chapter._id,
            datetime: new Date(),
            episode: episode._id,
          }),
        }
      )
    }
  }, [profile, chapter, episode, toggleHandbookIdicator])

  useEffect(() => {
    openedChapter()
  }, [openedChapter])

  const logOpenEpisodeVideo = () => {
    logEvent('open_episode_video')
  }

  const logCloseEpisodeVideo = () => {
    logEvent('close_episode_video')
  }

  const onTimeTrackSubmit = useCallback(
    async (data: Partial<ITrackingSubmit>) => {
      try {
        if (!profile._id) return
        const url = `/trackings/track`
        await HelperAPI.ApiRequest(url, {
          method: 'POST',
          body: JSON.stringify({
            ...data,
            reference: 'chapters',
            types: 'VIDEO',
            kid: profile._id,
          }),
        })
      } catch (e) {
        toast.error('Something went wrong')
      }
    },
    [profile]
  )

  useEffect(() => {
    const start = Date.now()
    return () => {
      const end = Date.now()
      if (episode && chapter) {
        onTimeTrackSubmit({
          referenceType: chapter._id,
          episode: episode._id,
          timeTrack: end - start,
        })
      }
    }
  }, [episode, chapter, onTimeTrackSubmit])

  useEffect(() => {
    if (episode && chapter) {
      logOpenEpisodeVideo()
    }
    return () => {
      if (episode && chapter) {
        logCloseEpisodeVideo()
      }
    }
  }, [chapter, episode])

  const gameId = profile?.level === 3 ? chapter?.activities?.[0] : undefined

  const { activity: level3Activity } = useActivity({
    id: gameId,
  })

  // const { mutateAndIsEpisodeCompleted } = useCompletedEpisodes()

  const [showModal, setShowModal] = useState(false)
  const [showGame, setShowGame] = useState(false)

  const level = profile?.level

  const closeModal = useCallback(() => {
    setShowModal(false)
  }, [])

  useEffect(() => {
    if (!chapter?._id) return
    Helper.accessLog('chapters', chapter._id)
  }, [chapter?._id])

  const onVideoCompletedLevel3 = useCallback(async () => {
    if (!level3Activity?._id) return
    setShowGame(true)
    Helper.accessLog('activities', level3Activity._id)
  }, [level3Activity?._id])

  const onVideoCompleted = useCallback(async () => {
    if (!episode?._id) return

    logEvent('finish_episode_video')

    const response = await HelperAPI.ApiRequest<IResponseCompletedChapter>(
      `/level/${level}/episodes/chapters`,
      {
        method: 'POST',
        body: JSON.stringify({
          kid: profile?._id,
          episode: episode._id,
          chapter: chapter?._id,
          date: new Date(),
        }),
      }
    )

    if (response) {
      await updateUnlockingTime(epiSlug)
    }

    if (
      chapter &&
      Object.prototype.hasOwnProperty.call(chapter, 'next') &&
      !chapter?.next
    ) {
      await HelperAPI.ApiRequest<IResponseCompletedChapter>(
        `/level/${level}/episodes`,
        {
          method: 'POST',
          body: JSON.stringify({
            kid: profile?._id,
            episode: episode._id,
            date: new Date(),
          }),
        }
      )
    }

    void mutateEpisode()

    if (level === 3) {
      onVideoCompletedLevel3()
      return
    }

    // const isCompleted = await mutateAndIsEpisodeCompleted(
    //   completedChapter?.episode
    // )

    // const isUnwatchedChapter = !!episode?.chapters.some(
    //   (c) => c._id === chapter?._id && !c.watched
    // )

    // if (isUnwatchedChapter) return

    // TODO: Cannot use completedEpisodes as when watching last chapter, the completedEpisodes is not yet updated
    // if (!completedEpisodes.includes(episode._id)) return

    // if (!isCompleted) return

    // !NOTE: Rather than checking if all chapter is watched, just check if not last chapter, return
    // !      Coz we watch video in sequence
    if (chapter.next) return

    if (fromLocation === 'maktabah') return

    const timeout = window.setTimeout(() => {
      setShowModal(true)
    }, 2000)

    timeoutsRef.current.push(timeout)
  }, [
    chapter,
    profile,
    level,
    episode,
    // mutateAndIsEpisodeCompleted,
    mutateEpisode,
    onVideoCompletedLevel3,
    epiSlug,
    updateUnlockingTime,
    fromLocation,
  ])

  useEffect(() => {
    const timeouts = timeoutsRef.current
    return () => {
      timeouts.forEach((timeout) => window.clearTimeout(timeout))
    }
  }, [])

  const prevChapter = chapter?.prev
    ? `/episode/${chapter.prev.episode}/${chapter.prev.chapter}`
    : null

  const nextChapter = chapter?.next
    ? `/episode/${chapter.next.episode}/${chapter.next.chapter}`
    : null

  return (
    <>
      <Helmet>
        <title>عالم زيدو - الفصل</title>
      </Helmet>
      {isLoadingChapter ? <AppProgressBar /> : null}
      <AppBar
        backButtonLocation={
          fromLocationState === 'maktabah' ? '/library/video' : undefined
        }
        closeIconReplaceBackButton
        fixed
        hideToggleButton
        showBackButton
        showHomeLink
        showLogoutButton
        title={chapter?.chapter ?? ''}
      />

      <AppContent className="bg-[#323232] h-screen pages_chapter_appcontent">
        <div className="flex flex-col lg:pt-10 max-w-[1024px] mx-auto pages_chapter_container">
          <div className="h-[500px] max-h-screen pages_chapter_chapter_container">
            {chapter ? (
              <ChapterContent
                activity={level3Activity}
                chapterSlug={chapter.slug}
                episode={episode ?? null}
                gameId={gameId}
                next={nextChapter}
                onVideoEnded={onVideoCompleted}
                prev={prevChapter}
                showGame={showGame}
                src={Helper.MediaURL(chapter.url, 'stream')}
              />
            ) : null}
          </div>
          <div className="pb-6 pages_chapter_title_container">
            <ContentTitle title={chapter?.chapter ?? ''} />
          </div>
        </div>

        {showModal && level ? <GoToGamesModal onClose={closeModal} /> : null}
      </AppContent>
    </>
  )
}

export default Chapter

const ChapterContent: FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  episode?: any
  src: string
  next?: string | null
  prev?: string | null
  gameId?: string | null
  chapterSlug: string
  showGame: boolean
  activity?: IActivityDetail
  onVideoEnded: () => void
}> = (props) => {
  const token = useJwtToken()
  return (
    <div className="h-full pages_chapter_chapter_content w-full bg-transparent rounded-none lg:rounded-3xl overflow-hidden">
      {!props.showGame ? (
        <VideoPlayer
          className="pages_chapter_chapter_content_video"
          episode={props?.episode}
          from="chapter"
          next={props.next}
          onEnd={props.onVideoEnded}
          prev={props.prev}
          slug={props.chapterSlug}
          src={props.src}
        />
      ) : null}

      {props.showGame && props.gameId && props.activity ? (
        <GameIFrame
          apiUrl={Config.apiURL}
          episode={props?.episode}
          from="chapter"
          gameId={props.gameId}
          iframeProps={{
            src: Helper.MediaURL(props.activity.content),
            frameBorder: '0',
            allowFullScreen: true,
            width: '100%',
            height: '100%',
          }}
          token={token}
        />
      ) : null}
    </div>
  )
}

const GoToGamesModal: FC<{
  onClose: () => void
}> = (props) => {
  const history = useHistory()

  return (
    <Modal
      button={{
        icon: <FontAwesomeIcon icon={faGamepad} />,
        text: 'إلعب الأن',
        onClick: () => {
          history.goBack()
        },
        bgColor: 'bg-[#fbf5be]',
        textColor: 'text-[#f6580f]',
        className: 'shadow-modal-button pages_chapter_gotomodal_button',
      }}
      close={props.onClose}
      icon={
        <FontAwesomeIcon
          className="pages_chapter_gotomodal_icon"
          icon={faCheck}
        />
      }
      isOpen
      message="لقد أنهيت مشاهدة الحلقة وحان الآن وقت الكتاب التفاعلي والألعاب.."
      title="رائع.."
    />
  )
}
