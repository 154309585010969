import { ZIDO_TYPOGRAPHY } from '@src/services'

export interface Notification {
  uncheckedNotification?: boolean
  notificationText: string
  notificationTime: string
  image: string
  fullscreen?: boolean
  className?: string
}

const NotificationList = ({
  uncheckedNotification = false,
  notificationText = '',
  notificationTime = '',
  image = '',
  fullscreen = false,
  className = 'component_layout_notification_popover_list',
}: Notification) => {
  const types = ['maqraa', 'competition', 'curriculum', 'general']
  if (types.includes(image)) {
    switch (image) {
      case 'maqraa':
        image = '/images/v3/openBookBtn.svg'
        break
      case 'competition':
        image = '/images/v3/statsBtn.svg'
        break
      case 'curriculum':
        image = '/images/v3/blueVideoBtn.svg'
        break
      case 'general':
        image = '/images/v3/general-icon.svg'
        break
      default:
        image = '/images/v3/general-icon.svg'
    }
  }
  return (
    <div
      className={`${className}_conatainer notification h-[60px] w-full block mx-auto ${
        uncheckedNotification ? 'unchecked-notification' : ''
      }`}
    >
      <div
        className={`${className}_child flex flex-row-reverse  w-full h-[60px] p-2 justify-center`}
      >
        <div
          className={`${
            fullscreen
              ? 'inline-flex justify-between pt-4 pl-2'
              : 'inline-block'
          } w-full ${className}_child_typography`}
        >
          <div
            className={`notification-heading text-[13px] ${className}_notification_heading_Text`}
          >
            {notificationText}
          </div>
          <div
            className={`notification-subtext text-[12px] opacity-60 ${className}_notification_time_text`}
          >
            {notificationTime}
          </div>
        </div>
        <div
          className={`inline-block w-[55px] px-2 h-[45px] ml-2 ${className}_notification_image_container`}
        >
          <img
            alt="img"
            className={`w-full h-full object-fit ${className}_notification_img`}
            src={image}
          />
        </div>
      </div>
    </div>
  )
}

const NotificationWrapper = ({
  fullscreen,
  notifications,
  text,
  className = 'component_layout_notification_popover_wrapper',
}: {
  fullscreen: boolean
  notifications: Notification[]
  text: string
  className?: string
}) => {
  return (
    <>
      <h1
        className={`${className}_text_h1 text-[#4758C5] text-[16px] w-full h-[30px] my-2 mt-4 pr-2`}
      >
        {text}
      </h1>
      <div
        className={`notification-container ${className}_notification_container`}
      >
        {notifications.map((notif: Notification, index) => (
          <NotificationList key={index} {...notif} fullscreen={fullscreen} />
        ))}
      </div>
    </>
  )
}

const NotificationPopover = ({
  currentNotifications = [],
  oldNotifications = [],
  loading = true,
  fullscreen = false,
  className = 'component_layout_notification_popover',
}: {
  currentNotifications: Notification[]
  oldNotifications?: Notification[]
  loading?: boolean
  fullscreen?: boolean
  className?: string
}) => {
  return (
    <div
      className={`block ${className}_container ${
        fullscreen ? '' : 'w-[496px]'
      } h-64 lg:h-80  pb-[30px] font-kidzhood mx-0 px-0 relative z-[100]`}
    >
      <div
        className={`block h-full overflow-auto ${className}_child ${
          fullscreen ? '' : 'max-h-[600px]'
        }`}
      >
        {loading ? (
          <div className={`flex flex-1 ${className}_loading_container`}>
            <h1 className={`${className}_loading_h1`}>Loading...</h1>
          </div>
        ) : !currentNotifications?.length && !oldNotifications?.length ? (
          <NotificationWrapper
            fullscreen={fullscreen}
            notifications={[]}
            text={ZIDO_TYPOGRAPHY.NO_NOTIFICATION_TEXT}
          />
        ) : (
          <>
            {currentNotifications && currentNotifications?.length > 0 ? (
              <>
                <NotificationWrapper
                  fullscreen={fullscreen}
                  notifications={currentNotifications}
                  text={ZIDO_TYPOGRAPHY.NEW_NOTIFICATION_TEXT}
                />
                <hr className="mt-10" />
              </>
            ) : null}
            {oldNotifications && oldNotifications?.length > 0 ? (
              <NotificationWrapper
                fullscreen={fullscreen}
                notifications={oldNotifications}
                text={ZIDO_TYPOGRAPHY.OLD_NOTIFICATION_TEXT}
              />
            ) : null}
          </>
        )}
      </div>

      {!fullscreen ? (
        <div
          className={`${className}_no_full_screen_mark_read_container notification-more text-center absolute z-[50] bg-[#fff] pt-1 border-t-[1px] border-[#ccc] h-[30px] bottom-0 left-0 w-full`}
        >
          <span
            className={`${className}_no_full_screen_span_read hidden cursor-pointer w-full text-[#4cf4f9] text-[12px]`}
          >
            {ZIDO_TYPOGRAPHY.MARK_READ}
          </span>
        </div>
      ) : null}
    </div>
  )
}

export default NotificationPopover
