import { FC } from 'react'
import Config from '@config/index'
const flyingIpad = `${Config.publicURL}/images/flying_ipad.png`

export const FlyingIpad: FC = () => {
  return (
    <img
      alt=""
      className="component_images_flying_ipad_img select-none pointer-events-none w-full max-w-3xl max-h-full p-8 sm:p-4 md:p-0 transition-all animate-float"
      src={flyingIpad}
    />
  )
}
