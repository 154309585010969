import React, { useCallback, useEffect, useRef, useState, FC } from 'react'
import { Helmet } from 'react-helmet-async'
import Isvg from 'react-inlinesvg'
import { useHistory } from 'react-router-dom'
import { ILocation, ParentProfile, Profile } from '@src/types/api.interface'
import { AppBar, AppContent } from '@components/Layout'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { useMaps } from '@hooks/data/fetchers/use-maps'
import { SVG_MAPS_LEVEL_2 } from '@utils/constants'
import { usePurchases } from '@hooks/use-purchases'
import Popup from '@components/Popup'
import * as Sentry from '@sentry/react'

import {
  levelOneKidID,
  levelTwoKidId,
} from '@pages/anonymous/KidsHomeAnonymous'
import {
  HAVENT_FINISHED_VIDEOS,
  REQUIRES_SUBSCRIBE_MESSAGE,
  THIS_CONTENT_MESSAGE,
} from '@src/constants/messages'
import HelperProfile from '@utils/helpers/helper.profile'

const checkIfAnonymousUserOrRoute = (
  profile: Profile,
  parentProfile: ParentProfile,
  url: string
): boolean => {
  const anonyousKidIds = [levelOneKidID, levelTwoKidId]
  return (
    anonyousKidIds.includes(profile?._id) ||
    parentProfile?.email === 'user@anonymous.firebase' ||
    url.includes('anonymous')
  )
}

const MapsHomeLevel2: FC = () => {
  const { kidProfile: profile, parentProfile } = useProfile()

  const history = useHistory()
  const url = history.location.pathname
  const level = url === '/anonymous/l2/maps' ? 2 : Number(profile?.level)
  const shouldAddAnonynousRoute = checkIfAnonymousUserOrRoute(
    profile,
    parentProfile,
    url
  )
  const pendingUnit = profile?.pendingUnit
  const { maps } = useMaps()
  const [activeMapId, setActiveMap] = useState<number>(0)
  const map = typeof activeMapId === 'number' ? maps?.[activeMapId] : undefined

  const [hasProAccess, setHasProAccess] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isVisable, setIsVisable] = useState(false)

  const purchases = usePurchases()

  useEffect(() => {
    purchases
      .isHavingProAccess(profile?.level === 1 ? 'level-1' : 'level-2')
      .then(setHasProAccess)
      .then(() => setLoading(false))
  }, [profile?.level, purchases])

  const firstLocked = useRef<boolean | number | undefined>(true)
  const handleClick = useCallback(
    (location: ILocation) => {
      HelperProfile.setStoredProfile(parentProfile)
      HelperProfile.setStoredKid(profile)
      if ((!location.isFree && !hasProAccess) || !location.unlocked) {
        setIsVisable(true)
        return
      }
      if (shouldAddAnonynousRoute) {
        return history.push(
          `/anonymous-episode/anonymous-chapters/2/${location.slug}`
        )
      }
      history.push(`/episode/${location.slug}/chapters`)
    },
    // TODO: Recheck dependency array, while profile is excluded.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      hasProAccess,
      history,
      setIsVisable,
      parentProfile,
      shouldAddAnonynousRoute,
    ]
  )

  const errorDedup = useRef(null)
  useEffect(() => {
    clearTimeout(errorDedup.current)
    errorDedup.current = setTimeout(() => {
      if (!level || level !== 2 || !maps || !map) {
        const err = {
          message: 'unable to load level 2',
          extra: { maps, level, map },
          level: Sentry.Severity.Error,
        }
        Sentry.captureEvent(err)
        console.error(err)
      }
    }, 5000)
    let activeMapId: number | undefined
    if (!maps || !level || level !== 2 || !map) return
    maps.forEach((map, i) => {
      map.url = SVG_MAPS_LEVEL_2[i]
      // look for the map which will be presented to the kid initially (landing map)
      if (activeMapId === undefined)
        // enter the next loop only if the activeMapId is not yet set/found
        for (let j = 0; j < map.locations.length; j++) {
          if (!map.locations[j].watched) {
            activeMapId = i
            map.allWatched = false
            break
          }
          // allWatched is a flag to know if all locations of the map have been watched
          map.allWatched = true
        }
    })

    if (pendingUnit !== false && pendingUnit) {
      for (let index = 0; index < maps.length; index++) {
        const _slug = maps[index].episode?.slug
        if (_slug === pendingUnit.slug) {
          activeMapId = index
          break
        }
      }
    }

    setActiveMap(activeMapId ?? 0)
  }, [maps, level, pendingUnit, map])

  const onSVGLoad = useCallback(() => {
    if (!map) return

    const locations = map.locations

    // start by ignoring every location in the map which does not match a location from the database
    const mapLocations = document.getElementsByClassName('map-location')

    const slugs = locations.map((location) => location.slug)

    const inactiveMapLocationsIds = [] as string[]

    for (let i = 0; i < mapLocations.length; i++) {
      if (!slugs.includes(mapLocations[i].id)) {
        inactiveMapLocationsIds.push(mapLocations[i].id)
      }
    }

    if (activeMapId === firstLocked.current) firstLocked.current = true

    locations.forEach(function (item) {
      const loc = item
      const slug = loc.slug
      let unlocked = loc.unlocked
      const watched = loc.watched

      if ((!unlocked || item.isFree) && firstLocked.current === true) {
        firstLocked.current = activeMapId
        item.unlocked = true
        unlocked = true
      }

      if (item.isFree) item.unlocked = true

      if (unlocked) {
        const elementNormal = queryHtmlElement(`#${slug} .normal`)
        const elementDisabled = queryHtmlElement(`#${slug} .disabled`)
        const elementName = queryHtmlElement(`#${slug} .name`)
        if (elementNormal) elementNormal.style.display = 'unset'
        if (elementDisabled) elementDisabled.style.display = 'none'
        if (elementName) elementName.style.display = 'unset'

        if (!watched) {
          const elementActive = queryHtmlElement(`#${slug} .active`)
          if (elementActive) {
            elementActive.style.display = 'unset'
            elementActive.setAttribute('class', 'active bounce')
          }
        } else {
          const elementDone = queryHtmlElement(`#${slug} .done`)
          if (elementDone) elementDone.style.display = 'unset'
        }
      } else {
        const elementNormal = queryHtmlElement(`#${slug} .normal`)
        const elementDisabled = queryHtmlElement(`#${slug} .disabled`)

        if (elementNormal) elementNormal.style.display = 'none'
        if (elementDisabled && !hasProAccess)
          elementDisabled.style.display = 'unset'
      }

      const selector = unlocked ? `#${slug}-normal` : `#${slug}-disabled`
      let domElement = queryHtmlElement(selector)

      if (domElement) {
        domElement.style.display = 'unset'
        domElement = queryHtmlElement(`#${slug}`)
        if (domElement)
          domElement.addEventListener('click', () => {
            handleClick(loc)
          })
      }
    })

    inactiveMapLocationsIds.forEach((item) => {
      const elementNormal = queryHtmlElement(`#${item} .normal`)
      const elementDisabled = queryHtmlElement(`#${item} .disabled`)
      const elementName = queryHtmlElement(`#${item} .name`)
      const elementHover = queryHtmlElement(`#${item} .hover`)
      if (elementNormal) elementNormal.style.display = 'unset'
      if (elementDisabled) elementDisabled.style.display = 'none'
      if (elementName) elementName.style.display = 'none'
      if (elementHover) elementHover.style.display = 'none'
    })
  }, [handleClick, map, activeMapId, hasProAccess])

  const mapTitle = map?.episode?.title ?? ''

  useEffect(() => {
    if (!level) return
    if (level === 2) return
    history.replace('/')
  }, [level, history])

  if (!level || level !== 2) {
    return null
  }

  return (
    <>
      <Helmet>
        <title>عالم زيدو - الصفحة الرئيسية من المستوى 2</title>
      </Helmet>
      {!map || loading ? <AppProgressBar /> : null}
      <AppBar
        backButtonLocation={
          shouldAddAnonynousRoute ? `/kids-home-anonymous/2` : '/kids-home'
        }
        fixed
        showBackButton={true}
        showLogoutButton
        showProfileButton
        title={mapTitle || ''}
        titleCenter
      />
      <AppContent className="px-0 lg:px-0  bg-home-page bg-cover h-screen pages_level2_appcontent_home_container">
        <div className="flex flex-col lg:pt-10 max-w-[1024px] m-auto h-full">
          <div className="max-h-screen h-full">
            {!loading &&
            maps &&
            map &&
            level &&
            typeof activeMapId === 'number' ? (
              <div className="h-full flex justify-center items-center mx-auto pages_level2_appcontent_home_progressbar_container">
                <Isvg
                  className="p-1 max-h-screen drop-shadow-[0px_5px_5px_rgba(0,0,0,0.95)] pages_level2_appcontent_home_progressbar_svg"
                  height="100%"
                  loader={<AppProgressBar />}
                  onLoad={onSVGLoad}
                  src={map.url}
                  uniquifyIDs={false}
                  width="100%"
                />
              </div>
            ) : null}
          </div>
        </div>
      </AppContent>
      <Popup
        acceptLink={!hasProAccess ? '/subscription/current' : null}
        buttonText={!hasProAccess ? 'تسجيل الان' : null}
        message={
          hasProAccess ? HAVENT_FINISHED_VIDEOS : REQUIRES_SUBSCRIBE_MESSAGE
        }
        onClose={() => setIsVisable(false)}
        title={hasProAccess ? '' : THIS_CONTENT_MESSAGE}
        visible={isVisable}
      />
    </>
  )
}

export default MapsHomeLevel2

function queryHtmlElement(selector: string): HTMLElement | null {
  return document.querySelector(selector)
}
