import { useCallback, useEffect, useState } from 'react'
import {
  IResponseCompletedChapter,
  ITrackingSubmit,
} from '@src/types/api.interface'
import { useChapter } from '@hooks/data/fetchers/use-chapter'
import { useParams } from 'react-router-dom'

import { useEpisode } from '@hooks/data/fetchers/use-episode'
import { logEvent } from '@utils/firebase-analytics'
import HelperAPI from '@utils/helpers/helper.api'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { ContentVideoProps } from './type'
import { toast } from 'react-toastify'

const useContentVideo = (props: ContentVideoProps) => {
  const { epiSlug } = useParams<{ epiSlug: string }>()
  const { episode } = useEpisode({ slug: epiSlug })
  const [showModal, setShowModal] = useState(false)
  const { isLoadingChapter, chapter } = useChapter({
    chapSlug: props.content.slug,
    epiSlug,
  })
  const {
    kidProfile: profile,
    updateUnlockingTime,
    toggleHandbookIdicator,
  } = useProfile()

  const level = profile?.level

  const closeModal = useCallback(() => {
    setShowModal(false)
  }, [])

  const handleParentClose = () => {
    return props.onCloseParent()
  }

  const onVideoCompleted = useCallback(async () => {
    if (!episode?._id) return

    logEvent('finish_episode_video')

    const response = await HelperAPI.ApiRequest<IResponseCompletedChapter>(
      `/level/${level}/episodes/chapters`,
      {
        method: 'POST',
        body: JSON.stringify({
          kid: profile?._id,
          episode: episode._id,
          chapter: chapter?._id,
          date: new Date(),
        }),
      }
    )

    if (response) {
      await updateUnlockingTime(epiSlug)
    }

    setShowModal(true)
  }, [chapter, profile, level, episode, epiSlug, updateUnlockingTime])

  const onTimeTrackSubmit = useCallback(
    async (data: Partial<ITrackingSubmit>) => {
      try {
        if (!profile._id) return
        const url = `/trackings/track`
        await HelperAPI.ApiRequest(url, {
          method: 'POST',
          body: JSON.stringify({
            ...data,
            reference: 'chapters',
            types: 'VIDEO',
            kid: profile._id,
          }),
        })
      } catch (e) {
        toast.error('Something went wrong')
      }
    },
    [profile]
  )

  useEffect(() => {
    const start = Date.now()
    return () => {
      const end = Date.now()
      if (episode && chapter) {
        onTimeTrackSubmit({
          referenceType: chapter._id,
          episode: episode._id,
          timeTrack: end - start,
        })
      }
    }
  }, [episode, chapter, onTimeTrackSubmit])

  const openedChapter = useCallback(async () => {
    if (profile && chapter && episode) {
      if (!profile.handbookIndicatorFlag) {
        await toggleHandbookIdicator(profile, true, chapter.slug)
      }

      await HelperAPI.ApiRequest(
        `/level/${profile.level}/episodes/chapters/view`,
        {
          method: 'POST',
          body: JSON.stringify({
            kid: profile._id,
            chapter: chapter._id,
            datetime: new Date(),
            episode: episode._id,
          }),
        }
      )
    }
  }, [profile, chapter, episode, toggleHandbookIdicator])

  useEffect(() => {
    openedChapter()
  }, [openedChapter])

  return {
    showModal,
    isLoadingChapter,
    chapter,
    level,
    epiSlug,
    closeModal,
    onVideoCompleted,
    handleParentClose,
    episode,
  }
}

export default useContentVideo
