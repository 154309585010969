import React, { FC, FormEventHandler } from 'react'
import { ITestMeeting } from '@src/types/api.interface'
import Modal from '@components/Modal'
import { formatScheduleDate } from '@utils/date'
import { YellowButton } from '../Buttons'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
export const ScheduleModal: FC<{
  meeting: ITestMeeting | undefined
  onClose: () => void
  email: string
  onChangePhone: (value: string) => void
  phone: string
  onSubmit: FormEventHandler<HTMLFormElement>
  submitting: boolean
}> = (props) => {
  return (
    <Modal
      close={props.onClose}
      isOpen={!!props.meeting}
      message={
        <div className="maqraa_schedule_modal-container">
          <div className="maqraa_schedule_modal-title">معاد الحجز</div>
          <span className="font-bold text-[#eb6600] maqraa_schedule_modal-date">
            {props.meeting ? formatScheduleDate(props.meeting.date) : null}
          </span>
        </div>
      }
      title={
        <div className="text-gray-800 font-bold">
          سجل للقاء تحديد مستوى الطالب
        </div>
      }
    >
      <MaqraaForm
        email={props.email}
        formId="zoom-email-form"
        onChangePhone={props.onChangePhone}
        onSubmit={props.onSubmit}
        phone={props.phone}
        submitting={props.submitting}
      />
    </Modal>
  )
}

export const MaqraaRegisterModal: FC<{
  isOpen: boolean
  onClose: () => void
  email: string
  onChangePhone: (value: string) => void
  phone: string
  onSubmit: FormEventHandler<HTMLFormElement>
  submitting: boolean
}> = (props) => {
  return (
    <Modal
      close={props.onClose}
      isOpen={!!props.isOpen}
      title={<div className="text-gray-800 font-bold">سجل في المقرأة</div>}
    >
      <MaqraaForm
        email={props.email}
        formId="register-maqraa-email-form"
        onChangePhone={props.onChangePhone}
        onSubmit={props.onSubmit}
        phone={props.phone}
        submitting={props.submitting}
      />
    </Modal>
  )
}

const MaqraaForm: FC<{
  formId: string
  email: string
  onChangePhone: (value: string) => void
  phone: string
  onSubmit: FormEventHandler<HTMLFormElement>
  submitting: boolean
}> = (props) => {
  return (
    <form id={props.formId} onSubmit={props.onSubmit}>
      <div className="flex flex-col mb-6">
        <label className="block text-gray-500 mb-2" htmlFor="inline-full-name">
          البريد الإلكتروني لولي الأمر
        </label>

        <div className="flex-1">
          <input
            className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-[#f6580f]"
            disabled={true}
            id="inline-full-name"
            placeholder="البريد"
            type="email"
            value={props.email}
          />
        </div>

        <label className="block text-gray-500 mb-2" htmlFor="phone">
          المرجو إدخال رقم هاتف ولي الأمر
        </label>

        <div className="flex-1 flex items-center">
          <PhoneInput
            className="appearance-none border-2 border-gray-200 rounded w-full py-2 px-2 text-gray-700 leading-tight"
            countrySelectProps={{
              className: 'w-10',
            }}
            defaultCountry="SA"
            focusInputOnCountrySelection={false}
            id="phone"
            numberInputProps={{
              className: 'focus:outline-none w-[10px]',
            }}
            onChange={props.onChangePhone}
            placeholder="رقم الهاتف"
            style={{ direction: 'ltr' }}
            value={props.phone}
          />
        </div>
      </div>
      <YellowButton
        className="font-bold w-full"
        disabled={!props.email || !props.phone || props.submitting}
        label="اتمام"
        textSizeClass="text-lg"
      />
    </form>
  )
}
