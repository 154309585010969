import { logEvent } from '@utils/firebase-analytics'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sendGaToFirebase(event: any) {
  try {
    logEvent(event.event, event)
  } catch (e) {
    console.error('unable to send event', event)
  }
}
