import { useEffect, useState } from 'react'

export function useMobileView() {
  const initialScreenWidth = window.innerWidth
  //   this should target all window sizes from 0 to 1024 (which is the large media query)
  const isMobileViewDefault = initialScreenWidth <= 1024

  const [isMobileView, setIsMobileView] = useState(isMobileViewDefault)

  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth
      const isLargeOrLess = screenWidth <= 1024
      if (isLargeOrLess) {
        setIsMobileView(true)
      } else {
        setIsMobileView(false)
      }
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  return isMobileView
}
