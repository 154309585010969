import { IActivity } from '@src/types/api.interface'
import {
  BOOK_ICON_WHITE,
  GAME_ICON_WHITE,
  LOCKED_ICON_SMALL,
  STAR,
} from '@utils/constants'
import { FC } from 'react'
import { Link } from 'react-router-dom'

const ACTIVITY_ROADMAP = [
  {
    COLOR_FROM: 'from-[#9ED953]',
    COLOR_TO: 'to-[#7DBA30]',
    STAR_IMAGE: STAR.GREEN,
    DESC: 'GREEN',
  },
  {
    COLOR_FROM: 'from-[#D493FC]',
    COLOR_TO: 'to-[#A45FCE]',
    STAR_IMAGE: STAR.PURPLE,
    DESC: 'PINK',
  },
  {
    COLOR_FROM: 'from-[#1DC8FC]',
    COLOR_TO: 'to-[#24A7CF]',
    STAR_IMAGE: STAR.BLUE,
    DESC: 'BLUE',
  },
  {
    COLOR_FROM: 'from-[#FCB64E]',
    COLOR_TO: 'to-[#D7983C]',
    STAR_IMAGE: STAR.ORANGE,
    DESC: 'ORANGE',
  },
]

const ActivitySmallThumbnail: FC<{
  data: IActivity
  setIsVisable?: (isVisible: boolean) => void
  locked?: boolean
  isAnonymous?: boolean
  episodeId?: string
  index?: number
  newUI?: boolean
  noStars?: boolean
  className?: string
}> = (props) => {
  const data = props.data
  const category = data?.category
  props.className = props.className || 'component_activity_small_thumbnail'

  const index = props.index ?? 0 // Use provided index or default to 0 if not provided

  // Ensure that the index is within bounds
  const validIndex = (index + ACTIVITY_ROADMAP.length) % ACTIVITY_ROADMAP.length

  const currentRoadmap = ACTIVITY_ROADMAP[validIndex]

  const url = props.locked
    ? ''
    : props.isAnonymous
    ? '/activities-anonymous/' + data._id
    : props.episodeId
    ? '/activities/' + data._id + '?episode=' + props.episodeId
    : '/activities/' + data._id
  return (
    <div className={`${props.className}_container flex relative rounded-3xl`}>
      {props.locked ? (
        <div
          className={`z-10 absolute w-full h-full ${props.className}_visible_toggle_container`}
          onClick={() => {
            props?.setIsVisable(true)
          }}
        />
      ) : null}
      {!props.newUI ? (
        <Link to={url}>
          <div className={`relative mx-3 ${props.className}_old_ui_thumb`}>
            {props.locked ? (
              <img
                alt=""
                className={`absolute w-7 h-8 -top-0 right-0 lg:w-7 lg:h-8 ${props.className}`}
                src={LOCKED_ICON_SMALL}
              />
            ) : null}

            <div
              // className={`bg-activity-thumb-blue aspect-square  rounded-full grid grid-rows-2 place-items-center justify-items-center mt-1 p-2 `}
              className={`${
                category === 'game'
                  ? 'bg-activity-thumb-blue'
                  : 'bg-activity-thumb-green'
              } ${
                props.className
              }_game_thumb_container w-[88px] h-[88px] lg:w-24 lg:h-24 aspect-square grid grid-rows-2 place-items-center justify-items-center overflow-hidden p-2`}
            >
              <img
                alt=""
                className={`w-6 h-4 lg:w-8 lg:h-6 ${props.className}_game_thumb_img`}
                src={category === 'game' ? GAME_ICON_WHITE : BOOK_ICON_WHITE}
              />
              <span
                className={`${props.className}_game_thumb_span text-center text-[14px] lg:text-[16px] text-white mt-1 overflow-hidden px-2 pb-2`}
              >
                {data.title}
              </span>
            </div>
          </div>
        </Link>
      ) : (
        <Link to={url}>
          <div
            className={`relative mx-1 lg:scale-150 xl:scale-[2] xl:my-12 xl:px-12 lg:my-6 lg:px-6 ${props.className}_new_ui_thumb_container`}
          >
            {props.locked ? (
              <img
                alt=""
                className={`absolute w-5 h-6 -top-0 left-1 lg:left-6 xl:left-12 lg:w-7 lg:h-8 -rotate-45 ${props.className}_new_ui_thumb_img`}
                src={LOCKED_ICON_SMALL}
              />
            ) : null}
            {!props.noStars ? (
              <div
                className={`absolute -top-1 -right-1 lg:-top-0 lg:right-6 xl:right-11 ${props.className}_new_ui_nostars_container`}
              >
                <div
                  className={`relative text-center ${props.className}_new_ui_no_stars_child_container`}
                >
                  <img
                    alt=""
                    className={`${props.className}_new_ui_no_stars_img`}
                    src={currentRoadmap.STAR_IMAGE}
                  />
                  <span
                    className={`absolute text-[12px] text-white top-[43%] left-1/2 -translate-x-1/2 -translate-y-1/2 ${props.className}_new_ui_no_stars_span`}
                  >
                    {index + 2}
                  </span>
                </div>
              </div>
            ) : null}

            <div
              className={`bg-gradient-to-b ${props.className}_new_ui_gradiant_title_container ${currentRoadmap?.COLOR_FROM} ${currentRoadmap?.COLOR_TO} aspect-square border-[5px] w-[75px] h-[75px] lg:w-24 lg:h-24 border-[#D5E9F7] rounded-full grid grid-rows-2 place-items-center justify-items-center mt-1 p-2 overflow-hidden`}
            >
              <img
                alt=""
                className={`w-6 h-4 lg:w-8 lg:h-6 ${props.className}_new_ui_gradiant_img`}
                src={category === 'game' ? GAME_ICON_WHITE : BOOK_ICON_WHITE}
              />
              <span
                className={`text-center text-[10px] lg:text-[14px] text-white mt-1 overflow-ellipsis ${props.className}_new_ui_gradiant_span`}
              >
                {data.title}
              </span>
            </div>
          </div>
        </Link>
      )}
    </div>
  )
}

export default ActivitySmallThumbnail
