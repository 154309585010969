import Config from '@config/index'

export const TEXT = {
  WEBSITE_TITLE: 'عالم زيدو - تقرير شامل',
  TITLE: 'تقرير شامل',
  TOTAL_WATCHING_TIME: 'اجمالي اوقات المشاهدة',
  VIDEOS_WATCHING_TIME: 'وقت مشاهدة الفيديوهات',
  ACTIVITIES_WATCHING_TIME: 'وقت الأنشطة والتفاعل',
}

export const CARDS = {
  WATCHING_TIME: {
    IMAGE: `${Config.publicURL}/images/v3/Time.svg`,
    TITLE: 'وقت المشاهدة',
    TABS: ['اليوم', 'الاسبوع', 'الشهر'],
  },
  UNITES_PROGRESS: {
    IMAGE: `${Config.publicURL}/images/v3/Movie.svg`,
    TITLE: 'مستوى تقدم الوحدات',
  },
  EDUCATIONAL_GOALS: {
    IMAGE: `${Config.publicURL}/images/v3/Goal.svg`,
    TITLE: 'الأهداف التربوية',
  },
}
