import { PRIVACY_POLICY_LINK, USAGE_POLICY_LINK } from '@src/constants'
import { ZIDO_TYPOGRAPHY } from '@src/services'
import { Link } from 'react-router-dom'
import { HorizontalSeparator } from './KidCard'
import { SettingsLinkComponent } from './SettingsPopover'

const Sidebar = ({
  visible = false,
  backdrop,
  onClickHowItWorks,
}: {
  visible?: boolean
  backdrop: () => void
  onClickHowItWorks: () => void
}) => {
  return (
    <div
      className={`component_layout_mobile_sidebar_container absolute top-0 left-0 w-full h-full z-[1000002] ${
        visible ? '' : 'hidden'
      }`}
    >
      <div
        className="component_layout_mobile_sidebar_child absolute top-0 right-0 w-[345px] h-full block bg-[#E4EEF3] z-[1001]"
        style={{
          borderRadius: '50px 0px 0px 50px',
        }}
      >
        <div className="component_layout_mobile_sidebar_main_container sidebar-main pt-[50px] h-full w-full">
          <div className="sidebar-controlls component_layout_mobile_sidebar_main_controls absolute top-[23px] left-[19px] p-2">
            <div
              className="sidebar-close cursor-pointer component_layout_mobile_sidebar_main_img_container"
              onClick={backdrop}
            >
              <img
                alt="close"
                className="component_layout_mobile_sidebar_main_img"
                height={36}
                src="/images/v3/blueCrossBtn.svg"
                width={36}
              />
            </div>
          </div>
          <div className="component_layout_mobile_sidebar_content_container sidebar-content flex justify-center items-center h-[75%]">
            <div className="settings component_layout_mobile_sidebar_settings_container min-h-[60px] pb-2 w-full block font-kidzhood mx-auto">
              <SettingsLinkComponent
                className="component_layout_mobile_sidebar_settings_component_link_crown"
                colorClass=""
                image="images/v3/crownBtn.svg"
                text={ZIDO_TYPOGRAPHY.SUBSCRIBE_TEXT}
                url="/subscription/current"
              />
              <HorizontalSeparator />
              <SettingsLinkComponent
                className="component_layout_mobile_sidebar_settings_component_link_blue_btn"
                colorClass=""
                image="images/v3/blueBookBtn.svg"
                text={ZIDO_TYPOGRAPHY.APPROACH_TEXT}
                url="/curriculum"
              />
              <HorizontalSeparator />
              <button
                className="hover:bg-gray-200 component_layout_mobile_sidebar_button_how_it_works"
                onClick={onClickHowItWorks}
                type="button"
              >
                <SettingsLinkComponent
                  className="component_layout_mobile_sidebar_settings_component_link_how_it_works"
                  colorClass=""
                  image="images/v3/purpleQuestionMarkBtn.svg"
                  text={ZIDO_TYPOGRAPHY.HOW_IT_WORS_TEXT}
                  url="#"
                />
              </button>
              <HorizontalSeparator />
              <SettingsLinkComponent
                className="component_layout_mobile_sidebar_settings_component_link_setting_text"
                colorClass=""
                image="images/v3/SkySettingsBtn.svg"
                text={ZIDO_TYPOGRAPHY.SETTINGS_TEXT}
                url="/settings"
              />
            </div>
          </div>
        </div>
        <div className="footer-sidebar component_layout_mobile_sidebar_footer_sidebar_container">
          <div className="flex component_layout_mobile_sidebar_footer_sidebar_child justify-around font-kidzhood w-full h-30px absolute bottom-[20px]">
            <Link
              className="block text-[15px] text-[#383430] component_layout_mobile_sidebar_footer_sidebar_terms_link"
              target="_blank"
              to={{ pathname: `${USAGE_POLICY_LINK}` }}
            >
              {ZIDO_TYPOGRAPHY.TERMS_TEXT}
            </Link>
            <Link
              className="block text-[15px] text-[#383430] component_layout_mobile_sidebar_footer_sidebar_privacy_link"
              target="_blank"
              to={{ pathname: `${PRIVACY_POLICY_LINK}` }}
            >
              {ZIDO_TYPOGRAPHY.PRIVACY_TEXT}
            </Link>
          </div>
        </div>
      </div>
      <div
        className="absolute component_layout_mobile_sidebar_footer_backdrop_container cursor-pointer top-0 left-0 w-full h-full bg-black opacity-70 z-[101]"
        onClick={backdrop}
      />{' '}
    </div>
  )
}

export default Sidebar
