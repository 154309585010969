import useSwr from 'swr'
import { IActivityListItem } from '@src/types/api.interface'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { FetchError } from '@utils/FetchError'
import HelperProfile from '@utils/helpers/helper.profile'

export function useActivities(props: { epiSlug?: string; chapSlug?: string }): {
  activities?: IActivityListItem[]
  error?: FetchError
  isLoadingActivities: boolean
} {
  const { kidProfile: profile } = useProfile()
  const isAnon = HelperProfile.getProfileType() === 'anon'

  const id = profile._id
  const level = profile?.level

  const { chapSlug, epiSlug } = props
  const url = level
    ? isAnon
      ? `/level/${level}/episodes/${epiSlug}/activities`
      : epiSlug && chapSlug
      ? `/level/${level}/episodes/${epiSlug}/chapters/${chapSlug}/activities`
      : epiSlug
      ? `/level/${level}/episodes/${epiSlug}/${id}/activities`
      : `/level/${level}/activities`
    : null

  const { data, error } = useSwr<
    { activities: IActivityListItem[] } | IActivityListItem[],
    FetchError
  >(url)

  return {
    activities:
      data && Array.isArray(data)
        ? data
        : (data as IActivityListItem)?.activities,
    error,
    isLoadingActivities: !error && !data,
  } as {
    activities: IActivityListItem[]
    error: FetchError
    isLoadingActivities: boolean
  }
}
