import SVG from 'react-inlinesvg'
import { DeleteAccountButton } from './SettingsHome'
import { FC } from 'react'
import GreenButton from '@components/Buttons/GreenButton'

interface CardContentProps {
  onClick: () => void
  settings: {
    icon: string
    title: string
    subtitle: string
    buttonText: string
  }
}

export const CardContentDelete: FC<CardContentProps> = ({
  onClick,
  settings,
}) => {
  return (
    <div
      className="flex justify-between w-full gap-x-2 pages_settings_cardcontent_delete_container"
      key={settings.title}
    >
      <div className="flex items-center gap-x-2 pages_settings_cardcontent_delete_typography">
        <SVG
          className="pages_settings_cardcontent_delete_svg"
          src={settings.icon}
        />
        <div className="flex flex-col pages_settings_cardcontent_delete_text_container">
          <p className="text-[#1C349A] text-xl xl:text-3xl pages_settings_cardcontent_delete_title_text">
            {settings.title}
          </p>
          <p className="text-[#4758C5] font-extralight pages_settings_cardcontent_delete_subtitle_text">
            {settings.subtitle}
          </p>
        </div>
      </div>

      <button
        className="pages_settings_cardcontent_delete_button"
        onClick={onClick}
        type="button"
      >
        <DeleteAccountButton />
      </button>
    </div>
  )
}

export const CardContentEdit: FC<CardContentProps> = ({
  onClick,
  settings,
}) => {
  return (
    <div
      className="flex justify-between w-full gap-x-2 items-center pages_settings_cardcontent_edit_container"
      key={settings.title}
    >
      <div className="flex items-center gap-x-4 pages_settings_cardcontent_edit_typography">
        <SVG
          className="pages_settings_cardcontent_edit_svg"
          src={settings.icon}
        />
        <div className="flex flex-col pages_settings_cardcontent_edit_text_container">
          <p className="text-[#1C349A] text-xl xl:text-3xl pages_settings_cardcontent_edit_title_text">
            {settings.title}
          </p>
          <p className="text-[#4758C5] font-extralight pages_settings_cardcontent_edit_subtitle_text">
            {settings.subtitle}
          </p>
        </div>
      </div>
      <GreenButton
        className="px-11 pages_settings_cardcontent_edit_button"
        label={settings.buttonText}
        onClick={onClick}
      />
    </div>
  )
}
