/* eslint-disable @typescript-eslint/no-explicit-any */
import { sendGaToFirebase } from './sendGaToFirebase'

export function reportOpened({
  parentId,
  childId,
  reportId,
  episodeId,
  videoId,
  bookId,
  gameId,
}: {
  parentId: string
  childId: string
  reportId?: string
  episodeId?: string
  videoId?: string
  bookId?: string
  gameId?: string
}) {
  const event: any = {
    event: 'report_opened',
    eventAction: 'report_opened',
    eventCategory: 'Handbook',
    data: {
      date: new Date().toUTCString(),
      parentId,
      childId,
      reportId,
      episodeId,
      videoId,
      bookId,
      gameId,
    },
  }
  window.dataLayer.push(event)
  sendGaToFirebase(event)
}

export function reportClosed({
  parentId,
  childId,
  reportId,
  episodeId,
  videoId,
  bookId,
  gameId,
}: {
  parentId: string
  childId: string
  reportId?: string
  episodeId?: string
  videoId?: string
  bookId?: string
  gameId?: string
}) {
  const event: any = {
    event: 'report_closed',
    eventAction: 'report_closed',
    eventCategory: 'Handbook',
    data: {
      date: new Date().toUTCString(),
      parentId,
      childId,
      reportId,
      episodeId,
      videoId,
      bookId,
      gameId,
    },
  }
  window.dataLayer.push(event)
  sendGaToFirebase(event)
}
