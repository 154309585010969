import Config from '@config/index'
import { UNITES_LIST } from '@src/constants'
import { ISeries } from '@src/types/api.interface'
import Helper from '@utils/Helpers'
import { FC } from 'react'
import SVG from 'react-inlinesvg'
import { Link } from 'react-router-dom'

const playIcon = `${Config.publicURL}/images/v3/yellow-play-icon.svg`

interface SeriesDetailsProps {
  data: ISeries
}

const TEXT = {
  START_NOW: 'ابدأ الآن',
}

const SeriesDetails: FC<SeriesDetailsProps> = ({ data }) => {
  const url = `/series/${data.slug}`
  const thumbnail = Helper.MediaURL(data.thumbnail)
  const episodesCount = `${data.episodes.length} وحدات`

  return (
    <div className="flex pages_series_seriesdetail_container flex-col w-[70%] text-right text-white pb-24 overflow-y-scroll h-screen hide-scroll">
      <img
        alt={data.title}
        className="pages_series_seriesdetail_img"
        src={thumbnail}
        width={150}
      />
      <h3 className="text-2xl md:text-3xl pages_series_seriesdetail_list_order">
        {UNITES_LIST?.[data.order]}
      </h3>
      <h1 className="text-4xl md:text-5xl -mt-1 pages_series_seriesdetail_title">
        {data.title}
      </h1>
      <h3 className="text-2xl md:text-3xl pages_series_seriesdetail_episode_counts">
        {episodesCount}
      </h3>
      <p className="w-2/3 text-xl md:text-2xl hidden lg:block pages_series_seriesdetail_desc">
        {data.description}
      </p>

      <Link
        className="flex flex-row w-fit pages_series_seriesdetail_link_state items-center justify-between rounded-full bg-v3-orange px-3 py-2 mt-4"
        to={{
          pathname: url,
          state: { seriesData: data },
        }}
      >
        <h3 className="pl-5 pr-5 text-2xl pages_series_seriesdetail_link_state_startnow_text md:text-3xl text-v3-blue-800">
          {TEXT.START_NOW}
        </h3>
        <SVG
          className="pages_series_seriesdetail_link_state_icon"
          src={playIcon}
        />
      </Link>
    </div>
  )
}

export default SeriesDetails
