import { FC, useCallback, useState } from 'react'
import {
  BackButton,
  DownloadButton,
  FullScreenButtonInAppBar,
  GoToGalleryBtn,
  HomeButton,
  LogoHomeLink,
  LogoutButton,
  ProfileButton,
} from '.'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { KidAvatar, ToggleBarButton } from './AppBarButtons'
import HelperProfile from '@utils/helpers/helper.profile'
import { Capacitor } from '@capacitor/core'

export const AppBar: FC<{
  title?: string
  backButtonLocation?: string
  backButtonOnclick?: () => void
  titleCenter?: boolean
  showGalleryLink?: boolean
  showHomeLink?: boolean
  showBackButton?: boolean
  showParentBackButton?: boolean
  showLogoutButton?: boolean
  showOutsideLogoutButton?: boolean
  showProfileButton?: boolean
  hideToggleButton?: boolean
  showHomeButton?: boolean
  closeIconReplaceBackButton?: boolean
  showKidAvatar?: boolean
  fixed?: boolean
  withBgTheatre?: boolean
  fullScreenNodeId?: string
  downloadFileHref?: string
  leftComponent?: React.ReactNode
  children?: React.ReactNode | string
}> = (props) => {
  const { kidProfile: profile, logout, parentProfile } = useProfile()

  const profileFromStorage = HelperProfile.getStoredProfile() ?? parentProfile
  const kidFromStorage = HelperProfile.getStoredKid() ?? profile

  const profileType = HelperProfile.getProfileType()
  const displayName = kidFromStorage
    ? kidFromStorage.nickname || kidFromStorage.firstName
    : 'User'
  const bgTheatreClasses = props.withBgTheatre ? 'bg-theatre-appbar' : ''
  const [hide, setHide] = useState(true)

  const fixedClasses = props.fixed ? 'fixed top-0 w-full _appbar-fixed' : ''

  const toggle = useCallback(() => {
    setHide((h) => !h)
  }, [])

  return (
    <div className="component_layout_app_bar_div_container">
      <ul
        className={`bg-[#32c8e8] component_layout_app_bar_ul_container w-full z-50 px-2 sm:px-4 pt-3 pb-2 flex items-center justify-between ${fixedClasses} ${bgTheatreClasses} ${
          hide ? 'translate-y-[-200px]' : ''
        } transition-all`}
      >
        <li className="component_layout_app_bar_li_container">
          <div className="ml-4 sm:ml-0 component_layout_app_bar_li_div_child">
            {props.showBackButton ? (
              <BackButton to={props.backButtonLocation} />
            ) : (
              <LogoHomeLink />
            )}
          </div>
        </li>
        <li className="flex-1 component_layout_app_bar_li_flex_app_title_container">
          <AppBarTitle center={props.titleCenter} title={props?.title || ''} />
        </li>
        {profileFromStorage ? (
          <li className="flex component_layout_app_bar_storage_profile_li_container">
            {props.showLogoutButton ? (
              <span className="ml-1 sm:ml-4 h-full block component_layout_app_bar_show_logout_span_container">
                <LogoutButton
                  displayName={profileType === 'legacy' ? displayName : ''}
                  onLogout={logout}
                />
              </span>
            ) : null}
            {props.downloadFileHref ? (
              <span className="ml-1 sm:ml-4 component_layout_app_bar_download_file_span_container">
                <DownloadButton file={props.downloadFileHref} />
              </span>
            ) : null}
            {props.fullScreenNodeId ? (
              <span className="ml-1 sm:ml-4 component_layout_app_bar_full_screen_node_in_app_container">
                <FullScreenButtonInAppBar nodeId={props.fullScreenNodeId} />
              </span>
            ) : null}
            {props.showGalleryLink ? (
              <span className="ml-1 sm:ml-4 component_layout_app_bar_show_gallery_link_span">
                <GoToGalleryBtn level={kidFromStorage?.level ?? 1} />
              </span>
            ) : null}

            {props.showProfileButton ? (
              <span className="ml-1 sm:ml-4 component_layout_app_bar_show_profile_profile_button_container">
                <ProfileButton gender={kidFromStorage?.gender ?? ''} />
              </span>
            ) : null}

            {props.showHomeLink ? (
              <span className="component_layout_app_bar_span_home_button_show_home_container">
                <HomeButton />
              </span>
            ) : null}
          </li>
        ) : null}
        <li className="mr-2 mb-2 component_layout_app_bar_li_toggle_click_container">
          <ToggleBarButton onClick={toggle} />
        </li>
        {props.children}
      </ul>
      <div className="component_layout_app_bar_buttons_container pointer-events-none z-40 px-2 sm:px-4 py-2  fixed top-0 left-0 w-full flex items-center justify-between">
        {props.showHomeButton ? (
          <div className="pointer-events-auto component_layout_app_bar_home_button_list_kid_container">
            <HomeButton newHomeIcon to="/list-kid-home" />
          </div>
        ) : props.showParentBackButton ? (
          <div className="pointer-events-auto component_layout_app_bar_parent_show_back_button_container">
            <BackButton
              closeIcon={props.closeIconReplaceBackButton}
              onClick={props.backButtonOnclick}
              parentBackButton
              to={props.backButtonLocation}
            />
          </div>
        ) : (
          <div className="pointer-events-auto component_layout_app_bar_back_button_close_container">
            <BackButton
              closeIcon={props.closeIconReplaceBackButton}
              to={props.backButtonLocation}
            />
          </div>
        )}

        {props.leftComponent ? (
          <div className="pointer-events-auto component_layout_app_bar_left_component_container">
            {props.leftComponent}
          </div>
        ) : null}

        {props.showKidAvatar ? (
          <div className="pointer-events-auto component_layout_app_bar_show_kid_avatar_container">
            <KidAvatar
              gender={kidFromStorage?.gender ?? 'male'}
              kidName={displayName}
            />
          </div>
        ) : (
          !props.hideToggleButton && (
            <div className="pointer-events-auto mb-2 component_layout_app_bar_visible_toggle_button_toggle_container">
              <ToggleBarButton onClick={toggle} />
            </div>
          )
        )}
        {props.showOutsideLogoutButton && Capacitor.isNativePlatform ? (
          <div
            className="mb-2 pointer-events-auto cursor-pointer bg-parent-gateway-v3 v3-icon v3-icon-red bg-cover bg-no-repeat component_layout_app_bar_native_logout_container"
            onClick={logout}
          />
        ) : null}
      </div>

      {!hide ? (
        <div
          className="z-40 fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-40 component_layout_app_bar_toggle_container"
          onClick={toggle}
        />
      ) : null}
    </div>
  )
}

const AppBarTitle: FC<{ title?: string; center?: boolean }> = (props) => {
  return (
    <div
      className={`text-white text-xl component_layout_app_bar_app_title_bar_element_container  font-bold md:text-2xl lg:text-4xl text-shadow px-4 ${
        props.center ? 'text-center' : 'text-right'
      } hidden sm:block`}
    >
      {props?.title || ''}
    </div>
  )
}
