import { ContentTitle } from '@components/ContentTitle'
import { AppBar, AppContent } from '@components/Layout'
import PDFViewer from '@components/PDFViewer/PDFViewer'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import useQuery from '@hooks/use-query'
import { useMobileView } from '@hooks/useMobileView'
import { GtagEvents } from '@utils/gtag-events'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

const PDFViewerPage = () => {
  const { parentProfile, kidProfile } = useProfile()
  const isMobileView = useMobileView()

  const history = useHistory()
  const location = useLocation<{
    url: string
    title: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    episode?: any
  }>()
  const [pdfUrl, setPdfUrl] = useState<string>(location.state?.url ?? null)
  const [pdfTitle, setPdfTitle] = useState<string>(
    location.state?.title ?? 'PDF Viewer'
  )
  const encryptedUrl = useQuery().get('pdf')
  const title = useQuery().get('title')

  useEffect(() => {
    if (encryptedUrl && title) {
      const url = atob(encryptedUrl)
      setPdfUrl(url)
      setPdfTitle(title)
    }
  }, [encryptedUrl, title])

  useEffect(() => {
    if (!location.state?.url && !encryptedUrl) history.push('/list-kid-home')
  }, [encryptedUrl, history, location.state?.url])

  useEffect(() => {
    window.addEventListener('beforeunload', function () {
      GtagEvents.handbookClosed({
        episode: location?.state?.episode ?? {
          title: 'handbook',
          slug: location?.state?.url,
          series: { title: 'handbook' },
        },
        childId: kidProfile?._id,
        parentId: parentProfile?._id,
        handbookId: location?.state?.url,
      })
    })
  })

  return (
    <>
      <AppBar
        backButtonLocation="/list-kid"
        fixed
        hideToggleButton
        showParentBackButton
        title=""
      />
      <AppContent className="bg-no-repeat bg-cover bg-center bg-v3 pages_pdfviewerpage_appcontent">
        <ContentTitle
          className="pages_pdfviewerpage_contentitle"
          noShadow
          textColor="text-white"
          title={pdfTitle}
        />
        <div className="absolute bottom-0 mx-auto right-0 left-0 bg-handbook pages_pdfviewerpage_container">
          <div className={!isMobileView ? 'px-20' : 'px-[10rem]'}>
            {pdfUrl ? <PDFViewer url={pdfUrl} /> : null}
          </div>
        </div>
      </AppContent>
    </>
  )
}

export default PDFViewerPage
