import React, { FC, MouseEventHandler, useCallback, useState } from 'react'
import Helper from '@utils/Helpers'
import { AppProgressBar } from './Layout/ProgressBar/AppProgressBar'

/**
 * This component allows to download a private asset from the api :

 * This is made like this because we want to keep some asset private
 *
 */
const AssetDownloadLink: FC<{
  file: string
  fileName?: string | null
  children?: React.ReactNode | string
}> = (props) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleDownloadClick: MouseEventHandler = useCallback(
    async (e) => {
      e.preventDefault()
      if (isLoading) {
        // when the href is set we let the default click do his work
        return
      }

      setIsLoading(true)

      // we ask the shareable link
      // then we fetch the file
      // then we convert it to a blob and give it to the anchor tag

      try {
        const link = await Helper.share(props.file)
        if (typeof link !== 'string') {
          setIsLoading(false)
          return
        }

        const res = await fetch(link)
        const blob = await res.blob()
        const objectUrl = URL.createObjectURL(blob)

        const a = document.createElement('a')
        a.href = objectUrl
        a.download = props.fileName ?? ''
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        setIsLoading(false)
      } catch (error) {
        console.error(error)
        setIsLoading(false)
      }
    },
    [props.file, props.fileName, isLoading]
  )

  if (!props.file) return null

  return (
    <>
      {isLoading ? <AppProgressBar /> : null}
      <button
        className={`flex relative w-full h-full asset_download_link-btn ${
          isLoading ? 'opacity-60' : ''
        }`}
        onClick={handleDownloadClick}
        type="button"
      >
        {props.children}
      </button>
    </>
  )
}

export default AssetDownloadLink
