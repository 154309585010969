import { BlueBoxContainer } from '@components/Containers/BlueBoxContainer'
import { ContentTitle } from '@components/ContentTitle'
import { AppBar, AppContent } from '@components/Layout'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import { useAnonymous } from '@contexts/AnonymousContext/AnonymousContext'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { LoginProps } from '@contexts/ProfileContext/types/ProfileContext.types'
import { useMobileView } from '@hooks/useMobileView'
import Helper from '@utils/Helpers'
import { logEvent } from '@utils/firebase-analytics'
import HelperJWT from '@utils/helpers/helper.jwt'
import HelperProfile from '@utils/helpers/helper.profile'
import React, { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import RegisterForm from './RegisterForm'
import { TYPOGRAPHY } from './constants'
import { SingularEvent } from '@utils/analytics/Singular'
import { GtagEvents } from '@utils/gtag-events'

const loginButton = (
  <Link className="register_page-login-button-link" to="/login">
    <div className="register_page-typography-sign-in-wrapper border px-4 py-1 text-v3-green rounded-full flex justify-center items-center border-v3-green text-lg xl:text-2xl">
      <p className="register_page-typography-sign-in">{TYPOGRAPHY.SIGN_IN}</p>
    </div>
  </Link>
)

const Register = () => {
  const params = useParams<{ username: string; authToken: string }>()

  const { register, parentProfile } = useProfile()
  const { logout } = useAnonymous()
  const [username, setUsername] = useState(params.username || '')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isChecked, setIsChecked] = useState<boolean>(false)
  // const [isVisiblePolicy, setIsVisiblePolicy] = useState(false)
  // const [isVisibleApple, setIsVisibleApple] = useState(false)

  const history = useHistory()

  useEffect(() => {
    // make sure no user is logged in when register page is loaded
    Helper.logOut()
  }, [])

  useEffect(() => {
    if (parentProfile && parentProfile?.email !== 'user@anonymous.firebase') {
      if (parentProfile?.kids?.length > 0) return history.push('/list-kid-home')
      return history.push('/add-kid')
    }
  }, [history, parentProfile])

  // const getFromPath = useCallback(() => {
  //   if (location.state?.from?.pathname) {
  //     return location.state.from.pathname
  //   }
  //   return '/kids-home'
  // }, [location])

  // eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/no-explicit-any
  const handleRedirect = (profileFromResponse: any) => {
    if (
      profileFromResponse?.email &&
      profileFromResponse?.email !== 'user@anonymous.firebase'
    ) {
      if (profileFromResponse?.kids?.length > 0)
        return history.push('/list-kid-home')
      return history.push('/add-kid')
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resetProfile = () => {
    const redirectToUrl = HelperProfile.getStoredRedirectLocation()
    HelperProfile.setStoredProfile(null)
    HelperProfile.setStoredKid(null)
    HelperProfile.removeStoredKid()
    HelperProfile.removeStoredProfile()
    HelperJWT.removeJwtToken()
    if (
      redirectToUrl &&
      typeof redirectToUrl === 'string' &&
      redirectToUrl.trim().length > 0
    ) {
      HelperProfile.setStoredRedirectLocation(redirectToUrl)
    }
    logout(false)
  }

  const doRegister = useCallback(
    (data: LoginProps) => {
      setIsLoading(true)

      register(data).then((response) => {
        setIsLoading(false)
        if (!response) {
          return
        }
        if (typeof response === 'string') {
          return
        }
        SingularEvent('sng_complete_registration')
        logEvent('register_with_email', {
          level: response.level,
          type: 'email',
        })
        handleRedirect(response)
        // const to = getFromPath()
        // history.push(to)
      })
    },
    [handleRedirect, register]
  )

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (event.target.name === 'username') {
        setUsername(event.target.value)
      } else if (event.target.name === 'password') {
        setPassword(event.target.value)
      }
    },
    []
  )

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault()
      resetProfile()
      GtagEvents.customEvent({ event: 'EmailPWD_regstart' })
      const validate = [
        Helper.validatePassword(password),
        Helper.validateEmail(username),
        Helper.validateCheckBox(isChecked),
      ].filter((e) => e.length > 0)
      if (validate.length > 0) {
        validate.forEach((e, i) => {
          if (typeof e === 'object' && Array.isArray(e)) {
            e.forEach((s, k) => {
              toast(s, { toastId: `error_validation_${k}` })
            })
          } else {
            toast(e, { toastId: `error_validation_${i}` })
          }
        })
        return
      }
      if (username && password) {
        doRegister({ username, password, isChild: false })
      }
    },
    [resetProfile, password, username, doRegister, isChecked]
  )

  const isMobileView = useMobileView()

  return (
    <>
      <Helmet>
        <title className="register_page-main-title">
          {TYPOGRAPHY.WEBSITE_TITLE}
        </title>
      </Helmet>

      {isLoading ? <AppProgressBar /> : null}

      <AppBar
        backButtonLocation="/"
        fixed
        hideToggleButton
        leftComponent={loginButton}
        showParentBackButton
      />

      <AppContent
        className="bg-no-repeat bg-cover bg-center bg-v3 overflow-y-scroll h-screen hide-scroll"
        haveFooter={!isMobileView}
      >
        <ContentTitle
          noShadow
          notBold
          textClassname={isMobileView ? '' : 'text-5xl'}
          textColor={isMobileView ? 'text-white' : 'text-[#FAE266]'}
          title={
            isMobileView ? TYPOGRAPHY.ACCOUNT_CREATE : TYPOGRAPHY.WELCOME_TITLE
          }
        />

        <BlueBoxContainer
          dimensionClass={
            isMobileView
              ? `mx-auto w-[475px] h-auto mt-6`
              : `mx-auto w-[522px] h-auto pt-7 pb-5 px-6 bg-[#4E64BD] rounded-[40px] mt-16`
          }
        >
          {!isMobileView ? (
            <h1 className="register_page-typography-account-create text-white text-3xl text-center mb-12">
              {TYPOGRAPHY.ACCOUNT_CREATE}
            </h1>
          ) : null}
          <div className="register_page-register-form-wrapper w-full h-auto flex justify-center content-center self-center">
            <RegisterForm
              email={username}
              isChecked={isChecked}
              // isVisibleApple={isVisibleApple}
              // isVisiblePolicy={isVisiblePolicy}
              // onChangeConfirmPassword={handleChange}
              onChangePassword={handleChange}
              onChangeUsername={handleChange}
              onSubmit={handleSubmit}
              password={password}
              setIsChecked={setIsChecked}
              // setIsVisibleApple={setIsVisibleApple}
              // setIsVisiblePolicy={setIsVisiblePolicy}
            />
          </div>
        </BlueBoxContainer>
      </AppContent>
    </>
  )
}

export default Register
