interface RoundedButtonProps
  extends React.DetailedHTMLProps<
      React.ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    React.AriaAttributes {}

const RoundedButton = ({
  children,
  className,
  ...props
}: RoundedButtonProps) => {
  return (
    <button
      type="button"
      {...props}
      className={`pages_subscription_rounded_button rounded-full flex py-3 px-16 items-center ${className}`}
    >
      {children}
    </button>
  )
}

export default RoundedButton
