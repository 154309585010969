import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { IScheduleKid } from '@src/types/api.interface'
import { FetchError } from '@utils/FetchError'
import useSwr from 'swr'

export function useScheduleKids() {
  const { kidProfile: profile } = useProfile()

  //   const kidId = profile?._id

  const url = `/schedule-kids/${profile._id}/kid`

  const { data, error, mutate } = useSwr<IScheduleKid, FetchError>(url)

  return {
    scheduleKid: data ?? null,
    error,
    isLoadingScheduleKids: !error && !data,
    mutateScheduleKid: mutate,
  }
}
