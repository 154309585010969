import { useAnonymous } from '@contexts/AnonymousContext/AnonymousContext'
import HelperJWT from '@utils/helpers/helper.jwt'
import { FC, useEffect } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

const ProtectAnonymousRoute: FC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { userToken, isLoaded, setIsLoaded, logout } = useAnonymous()
  // eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/no-explicit-any
  const handleStorageEvent = (event: any) => {
    const { key } = event
    const newValue = HelperJWT.getJwtToken()
    if (key === '@secure.s.JWT_Token' && newValue !== userToken) {
      // console.log({ newValue, oldValue: userToken })
      setIsLoaded(false)
      logout()
    }
  }

  useEffect(() => {
    window.addEventListener('storage', handleStorageEvent)
    return () => window.removeEventListener('storage', handleStorageEvent)
  }, [handleStorageEvent])

  if (!isLoaded && !userToken) {
    return
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        return !userToken ? (
          <Redirect
            push={true}
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        ) : (
          Component && <Component {...props} />
        )
      }}
    />
  )
}

export default ProtectAnonymousRoute
