import Config from '@config/index'
import { COOKIE_NAME } from '@src/constants/cookies'
import Cookies from 'js-cookie'
import HelperJWT from './helper.jwt'
import { FetchError, isFetchError } from '@utils/FetchError'

const { apiURL } = Config

const HelperAPI = {
  ApiRequest: async function <T = Record<string, unknown>>(
    url: string,
    options: RequestInit = { cache: 'no-cache' }
  ): Promise<T> {
    // test if real url
    const {
      screen: { width, height },
      devicePixelRatio,
    } = window
    const u = new URL(url.includes('http') ? url : `${apiURL}${url}`)
    url = u.origin + u.pathname.replace(/\/+\//gim, '/') + u.search
    if (Cookies.get(COOKIE_NAME)) options.credentials = 'include'
    const headers = new Headers(options.headers)
    headers.append('Accept', 'application/json')
    headers.append('Content-Type', 'application/json')
    headers.append('accept-language', 'ar')
    headers.append(
      'Screen-Resolution',
      `${width * devicePixelRatio} X ${height * devicePixelRatio}`
    )
    headers.append('Application-Id', 'kids-platform')
    if (!headers.has('Authorization')) {
      const token = HelperJWT.getJwtToken()
      token && headers.append('Authorization', `Bearer ${token}`)
    }
    options.headers = headers
    const response = await fetch(url, options)
    try {
      const isJson = response.headers
        .get('content-type')
        ?.includes('application/json')
      const data = isJson ? await response.json() : await response.text()
      if (response.ok) {
        return data
      }

      if (response.status >= 400) {
        throw new FetchError(response.status, data)
      }

      const err = { url, options, response }
      console.error(err)
      // Sentry.captureException({ url, options, response })
      return null
    } catch (e) {
      const err = { url, options, response, e }
      console.error(err)
      if (isFetchError(e)) {
        throw e
      }
      // Sentry.captureException(err)
      return null
    }
  },
}

export default HelperAPI
