import Config from '@config/index'
import React, { FC, Fragment } from 'react'

const closeIcon = `${Config.publicURL}/images/v2/close-icon.png`

const PopupWrapper: FC<{
  isVisible: boolean
  children: React.ReactNode
  closePopup: () => void
}> = ({ isVisible, children, closePopup }) => {
  if (!isVisible) return <></>

  return (
    <div className="fixed top-0 right-0 z-[99] flex h-screen w-screen items-center justify-center overflow-y-auto overflow-x-hidden bg-black bg-opacity-70 outline-none">
      <div className="relative p-8 bg-blue-gray-200">
        <button
          className="absolute -top-0 -right-0 "
          onClick={() => closePopup()}
          type="button"
        >
          <img
            alt="close-icon"
            className="w-[2em] h-[2em] text-red-800 text-2xl"
            src={closeIcon}
          />
        </button>
        {children}
      </div>
    </div>
  )
}

export default PopupWrapper
