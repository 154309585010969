import { DEFAULT_THUMBNAIL } from '@utils/constants'
import React, { FC } from 'react'

const Thumbnail: FC<{
  src: string
}> = ({ src }) => {
  const classname = src
    ? 'object-cover w-full h-full'
    : 'object-contain h-min w-min'

  return (
    <img
      alt=""
      className={`thumbnail-img ${classname}`}
      draggable="false"
      src={src || DEFAULT_THUMBNAIL}
    />
  )
}

export default Thumbnail
