import { ActivityContent } from '@components/ActivityDetails'
import { FC } from 'react'
import { CircleLoader } from 'react-spinners'
import { ContentActivityProps } from './types'
import useContentActivity from './useContentActivity'

export const IFRAME_ID = 'zw-game-iframe'

const ContentActivity: FC<ContentActivityProps> = (props) => {
  const { activity, isLoadingActivity } = useContentActivity(props)
  return (
    <div className={props.className}>
      {isLoadingActivity ? (
        <CircleLoader />
      ) : (
        <ActivityContent
          activity={activity}
          episode={props.episode}
          iframeContainerId={IFRAME_ID}
          index={props.index}
        />
      )}
    </div>
  )
}

export default ContentActivity
