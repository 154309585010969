import Config from '@config/index'

export const TEXT = {
  WEBSITE_TITLE: 'عالم زيدو - منهجنا',
  TITLE: 'منهجنا',
  INTRO: {
    TITLE: 'منهج عالم زيدو',
    PARAGRAPH:
      'يهدف منهج عالم زيدو إلى المساهمة في تنشئة طفلٍ إيجابي، معتز بدينهِ وهويتهِ، متفاعل مع عصرهِ وبيئته، ويرتكز منهج عالم زيدو على خمسِ مقاصد تغطي مجالات الإدراك والتفاعل في حياة الطفل. ويقوم على مصفوفة تشمل أكثر من 3000 هدف ومؤشر تربوي تغطي مجالاتٍ متنوعة من الإيمان والسلوك والأخلاقِ والآداب والمعارف والمهارات وغيرها.',
  },
}

export const SECTIONS = [
  {
    title: 'تكامل الأهداف التربوية',
    subTitle: 'لإخراجِ طفلٍ مرنٍ ومتوازن',
    paragraph:
      'يغطي منهج عالم زيدو مجموعة من المجالات المناسبة للأطفال في الفئات العمرية المستهدفة، وتتنوع هذه الأهداف والموضوعات لتشمل ما يتعلق بهويته وماهيته، ويتعرفُ من خلالها على خالقهِ ونبيه، وعلى عائلتهِ بيئتهِ ومن حوله، على جسدهِ وصحتهِ وحواسه، كما يُغطي المنهج موضوعات آخرى تتعلق بالأخلاق والسلوك، والمعارفِ والفنون والمهارات الحياتية.',
    image: `${Config.publicURL}/images/v3/curriculum-1.svg`,
  },
  {
    title: 'منهج تراكمي متجدد',
    subTitle: 'لترسيخ القيم في وجدانِ الطفل',
    paragraph:
      'صُمم منهج عالم زيدو بطريقة حلزونية تعمل على تكرار ذاتِ المفاهيم مع تقدم المنهج على مستوى أكثر عمقاً واتساعاً، لتثبيت هذه المفاهيم في نفوس الأطفال وترسيخها في وجدانهم مع تقدمهم في الفئات العمرية المختلفة التي يستهدفها عالم زيدو (4-6) (7-9) (10-12). مع إضافة مفاهيم جديدة تتناسبُ مع المراحل العمرية الجديدة. كما تساهم هذه المنهجية في التعليم والتربية على اتساع عقولِ الأطفال، وتراكم خبراتهم الإيمانية والمعرفية والمهارية.',
    image: `${Config.publicURL}/images/v3/curriculum-2.svg`,
  },
  {
    title: 'تنوع الوسائط التعليمية',
    subTitle: 'لتعزيز تجربة التعلم الشاملة',
    paragraph:
      'لتحقيق أفضل نتائج ممكنة من منهجِ عالم زيدو، قام فريقنا التعليمي بتحويل الموضوعات التربوية والتعليمية إلى قصصٍ وأنشطة ومقاطع فيديو ورسومٍ متحركة وكتبٍ تفاعلية ومسابقاتٍ وألعاب وأناشيد، لتحفيزِ فضولِ الأطفال وتنمية إبداعهم. يتم تقديم هذه الموضوعات في صورة وحدات تعليمية لكل فئة عمرية، وتتضمنُ كلُ وحدة تعليمية أهدافاً تربوية محددة مع “دليل للمُربي” لمساعدة الأبناء في رحلتهم التربوية.',
    image: `${Config.publicURL}/images/v3/curriculum-3.svg`,
  },
  {
    title: 'تشجيع دور الوالدين',
    subTitle: 'لربط الطفلِ بوالديهِ وأسرته',
    paragraph:
      'يعدُ دور الوالدين جوهرياً في تطبيق منهج عالم زيدو، حيث صُمِمَ المنهج بطريقة تعززُ مشاركة الوالدين في شرح واستعراضِ الوحدات التعليمية لعالم زيدو مع أطفالهم، كما تم تصميم “دليل المُربي” وهو دليلٌ إرشادي لكل وحدةٍ تعليمية يتضمن أهدافها التربوية وأفكار وأدوات تساعد الوالدين على تنمية وعي الطفل بمحتوى هذه الوحدة، مع إجابات على أبرز الأسئلة التي قد يطرحها الطفل في هذا الموضوع ليكون الوالدين على أتم استعداد.',
    image: `${Config.publicURL}/images/v3/curriculum-4.svg`,
  },
]
