/* eslint-disable react/style-prop-object */
import { AppContent } from '@components/Layout'
import KidCard from '@components/Layout/KidCard'
import KidCardsSlider from '@components/Layout/KidCard/KidCardsSlider'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import Popup from '@components/Popup'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { useMobileView } from '@hooks/useMobileView'
import { ARTICLES_TEXT } from '@src/constants'
import { ARTICLES_LINK } from '@src/constants/link'
import { Profile } from '@src/types/api.interface'
import {
  CONFIRM_DELETE_KID_BUTTON,
  CONFIRM_DELETE_KID_MESSAGE,
  DELETE_KID_MESSAGE_BUTTON,
  DELETE_KID_MESSAGE_WARNING,
  DELETE_KID_TITLE,
  KID_AVATAR_FEMALE_NO_EYES_ICON,
  KID_AVATAR_MALE_NO_EYES_ICON,
} from '@utils/constants'
import HelperProfile from '@utils/helpers/helper.profile'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useHistory } from 'react-router-dom'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { SwiperSlide } from 'swiper/react'

import Config from '@config/index'

const ListKid: FC = () => {
  const {
    parentProfile,
    logout,
    removeKid,
    toggleHandbookIdicator,
    storedProfile,
    setParentProfile,
  } = useProfile()
  const history = useHistory()

  const [showPopup, setShowPopup] = useState(false)
  const [popupMessage, setPopupMessage] = useState(DELETE_KID_MESSAGE_WARNING)
  const [popupButton, setPopupButton] = useState(DELETE_KID_MESSAGE_BUTTON)
  const [confirmPopup, setConfirmPopup] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [selectedKid, setSelectedKid] = useState<Profile>()
  const [loading, setIsLoading] = useState(false)
  const clicker = useRef(0)
  const timer = useRef(null)

  // const graduationIcon = `${Config.publicURL}/images/v3/graduation.svg`
  const globeIcon = `${Config.publicURL}/images/v3/globe.svg`

  const isMobileView = useMobileView()

  const updateKidList = () => {
    const getStoredProfile = HelperProfile.getStoredProfile()
    setParentProfile(getStoredProfile)
  }

  useEffect(() => {
    clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      updateKidList()
    }, 10_000) // every 10 seconds update kid list
    return () => {
      clearTimeout(timer.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const performDelete = () => {
    if (confirmPopup && confirmDelete && selectedKid && updateKidList) {
      clicker.current = 0
      setShowPopup(false)
      setConfirmPopup(false)
      setConfirmDelete(false)
      removeKid(selectedKid).then(() => {
        updateKidList()
        setIsLoading(false)
      })
    }
  }

  const numberOfKids = parentProfile?.kids?.length ?? 0

  useEffect(() => {
    performDelete()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmDelete])

  useEffect(() => {
    storedProfile(true)
  }, [storedProfile])

  useEffect(() => {
    if (confirmPopup) {
      handleConfirmDeleteKid()
    }
  }, [showPopup, confirmPopup])

  const handleConfirmDeleteKid = async () => {
    setPopupMessage(CONFIRM_DELETE_KID_MESSAGE)
    setPopupButton(CONFIRM_DELETE_KID_BUTTON)
    setShowPopup(true)
  }

  if (parentProfile === undefined && history.location.pathname !== 'login') {
    history.push('/')
  }
  if (parentProfile?.email === 'user@anonymous.firebase') {
    logout()
  }
  if (!parentProfile?.kids[0]) {
    history.push('/add-kid')
  }

  const openHandbookPage = async ({ kid }: { kid: Profile }) => {
    const kidId = kid?._id

    if (kid.handbookIndicatorFlag) {
      toggleHandbookIdicator(kid, false)
    }

    history.replace(`/handbook-home/${kidId}`)
  }

  const showDeletePopup = useCallback(
    (kid: Profile) => {
      clicker.current = 0
      setSelectedKid(kid)
      setIsLoading(true)
      setShowPopup(true)
      setPopupMessage(DELETE_KID_MESSAGE_WARNING)
      setPopupButton(DELETE_KID_MESSAGE_BUTTON)
      history.replace(history.location.state, undefined)
    },
    [history]
  )

  useEffect(() => {
    if (history.location.state !== undefined) {
      const state: { kid?: Profile } | undefined = history?.location?.state
      if (state?.kid) {
        showDeletePopup(state?.kid)
      }
    }
  }, [history?.location?.state, showDeletePopup])

  const hideDeletePopup = async () => {
    clicker.current = 0
    setIsLoading(false)
    setSelectedKid(null)
    setShowPopup(false)
    setConfirmPopup(false)
    setPopupButton(DELETE_KID_MESSAGE_BUTTON)
    setPopupMessage(DELETE_KID_MESSAGE_WARNING)
  }

  const handlePopupClick = () => {
    const clicked = clicker.current

    if (clicked === 0 && !confirmPopup) {
      setConfirmPopup(true)
    } else {
      setConfirmDelete(true)
    }

    clicker.current++
  }

  return (
    <>
      <Helmet>
        <title>عالم زيدو - إدارة الأطفال </title>
      </Helmet>

      <AppContent
        className="bg-no-repeat bg-cover bg-center bg-v3 h-full pages_listkid_appcontent"
        haveFooter={!isMobileView}
        haveHeader
      >
        {loading ? (
          <>
            <AppProgressBar />
            <div className="absolute block pages_listkid_backdrop top-0 left-0 h-full w-full z-[100] bg-slate-600 opacity-25 blur-lg" />
          </>
        ) : null}
        <div className="flex lg:h-safe-screen justify-center content-center pages_listkid_slider_container">
          <div className="w-full overflow-y-scroll flex items-center hide-scroll">
            {parentProfile?.kids && parentProfile?.kids.length > 0 ? (
              <div className="sm:gap-x-4 pages_listkid_kid_slider_child gap-y-8 w-full flex items-center lg:pt-8 xl:pt-12 lg:mt-5 mx-auto text-center xl:pb-16 max-w-[400px]    min-[1024px]:max-w-[520px] min-[1025px]:max-w-[900px] xl:max-w-[1200px] ">
                <KidCardsSlider
                  isSingleChild={numberOfKids === 1}
                  pagination={true}
                  slidesPerView={isMobileView ? 1 : 2}
                >
                  {parentProfile.kids.map((kid) => {
                    return (
                      <SwiperSlide key={kid?._id}>
                        <KidCard
                          avatarUrl={
                            kid.gender === 'male'
                              ? KID_AVATAR_MALE_NO_EYES_ICON
                              : KID_AVATAR_FEMALE_NO_EYES_ICON
                          }
                          openBook={async () => await openHandbookPage({ kid })}
                          profile={kid}
                          showDeletePopup={() => showDeletePopup(kid)}
                        />
                      </SwiperSlide>
                    )
                  })}
                </KidCardsSlider>
              </div>
            ) : null}
          </div>
        </div>
        <div className="absolute bottom-0 xl:bottom-12 left-0 right-0 w-full pages_listkid_article_link_container">
          <div className="flex flex-row justify-end w-full px-14 items-center">
            {/* <Link to={{ pathname: `${COURSE_LINK}` }} target="_blank">
              <img
                className="h-9 w-12 xl:h-full xl:w-full"
                src={graduationIcon}
                alt="Graduation Icon"
              />
              <p className="text-white text-xl xl:text-[32px] leading-[38px]">
                {COURSE_LINK_TEXT}
              </p>
            </Link> */}
            <Link
              className="pages_listkid_article_link"
              target="_blank"
              to={{ pathname: `${ARTICLES_LINK}` }}
            >
              <img
                alt="Globe Icon"
                className="h-9 w-12 xl:h-full xl:w-full pages_listkid_article_link_img"
                src={globeIcon}
              />
              <p className="text-white text-lg xl:text-[32px] leading-[38px] pages_listkid_article_link_text">
                {ARTICLES_TEXT}
              </p>
            </Link>
          </div>
        </div>
      </AppContent>
      <Popup
        buttonOnClick={handlePopupClick}
        buttonText={popupButton}
        message={popupMessage}
        onClose={hideDeletePopup}
        title={DELETE_KID_TITLE}
        visible={showPopup}
      />
    </>
  )
}

export default ListKid
