import BreadCrumb from '@components/BreadCrumb'
import Card from '@components/Card'
import { AppBar, AppContent } from '@components/Layout'
import Loading from '@components/Loading'
import Popup from '@components/Popup'
import RadioSelection from '@components/RadioSelection'
import ToggleButton from '@components/ToggleButton'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { usePurchases } from '@hooks/use-purchases'
import { useMobileView } from '@hooks/useMobileView'
import { SUBSCRIBE_NOW_MESSAGE } from '@src/constants'
import { SubscriptionPackage } from '@src/services/purchases'
import { SingularEvent } from '@utils/analytics/Singular'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import SVG from 'react-inlinesvg'
import { useHistory } from 'react-router-dom'
import RoundedButton from './RoundedButton'
import {
  CHOOSE_PACKAGE_BENEFITS,
  CHOOSE_PACKAGE_FEATURES,
  CHOOSE_PACKAGE_IMAGES,
  CHOOSE_PACKAGE_TEXT,
} from './constants'
import { getDiscountPercent } from './helpers'
import { logEvent } from '@utils/firebase-analytics'

const MESSAGE_NOT_HAVE_KID =
  'لايوجد لديك أبناء على هذه الفئة العمرية. هل تريد متابعة الإشتراك'

const ChoosePackage: FC = () => {
  const isMobileView = useMobileView()
  const purchase = usePurchases()
  const history = useHistory()
  const profile = useProfile()

  const [packages, setPackages] = useState<SubscriptionPackage[]>()
  const [toggleValue, setToggleValue] = useState('default')
  const [radioValue, setRadioValue] = useState('level-1')
  const [redirect, setRedirect] = useState('')
  const [dialog, setDialog] = useState(false)

  useEffect(() => {
    purchase.getSubscriptionInfo().then((info) => {
      if (info) {
        history.replace('/subscription/current')
      }
    })
  }, [history, purchase])

  useEffect(() => {
    purchase.getCurrentPackages().then((packagesList) => {
      setPackages(packagesList)
      logEvent('view_subscribe_plan')
      SingularEvent('sng_checkout_initiated')
    })
  }, [purchase])

  const handlePopup = (link: string) => {
    if (toggleValue !== 'default') {
      const kids = profile.parentProfile.kids
      const kidLevel = radioValue === 'level-1' ? 1 : 2
      const kidAvailablity = kids.filter(({ level }) => level === kidLevel)
      if (kidAvailablity.length <= 0) {
        setDialog(true)
        setRedirect(link)
      } else {
        setDialog(false)
        setRedirect('')
        handleRedirect(link)
      }
    } else {
      setDialog(false)
      setRedirect('')
      handleRedirect(link)
    }
  }

  const handleRedirect = (link?: string) => {
    if (redirect?.length > 0) {
      return history.push(redirect)
    }
    if (link?.length > 0) {
      return history.push(link)
    }
  }

  const handleChooseSubscription = (value: string) => {
    const link = `/subscription/checkout/${value}${
      toggleValue === 'default' ? '' : `?level=${radioValue}`
    }`
    if (toggleValue !== 'default') {
      handlePopup(link)
    } else {
      handleRedirect(link)
    }
  }

  const savingPercent = getDiscountPercent(packages)
    ? `خصم ${getDiscountPercent(packages)}%`
    : ''

  return (
    <>
      <Helmet>
        <title className="page_subscription_choose_package_main_title">
          {CHOOSE_PACKAGE_TEXT.WEBSITE_TITLE}
        </title>
      </Helmet>

      {isMobileView ? (
        <AppBar
          fixed
          hideToggleButton
          showBackButton
          showHomeLink
          showParentBackButton
          title=""
        />
      ) : null}

      <AppContent
        className="page_subscription_choose_package_app_content bg-no-repeat bg-cover bg-center bg-v3 flex flex-col h-screen"
        haveFooter={!isMobileView}
        haveHeader={!isMobileView}
        headerTitle={!isMobileView ? CHOOSE_PACKAGE_TEXT.HEADER_TITLE : null}
      >
        {!isMobileView ? (
          <BreadCrumb title={CHOOSE_PACKAGE_TEXT.HEADER_TITLE} />
        ) : null}

        <div className="page_subscription_choose_package_main flex flex-col justify-center items-center w-full">
          <div className="page_subscription_choose_package_container container flex justify-center self-center">
            <div className="page_subscription_choose_package_text_title_wrapper pt-3 text-center">
              <div className="page_subscription_choose_package_text_title_content text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl pb-2 text-v3-blue-100">
                {CHOOSE_PACKAGE_TEXT.TITLE}
              </div>

              <div className="page_subscription_choose_package_text_subtitle_content text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl text-white">
                {CHOOSE_PACKAGE_TEXT.SUBTITLE}
              </div>
            </div>
          </div>

          <Card className="page_subscription_choose_package_card container justify-center self-center gap-x-2 gap-y-5 flex flex-wrap max-w-[700px] mt-6 px-14 py-3 max-lg:hidden">
            {CHOOSE_PACKAGE_FEATURES.map(({ id, title, icon }) => {
              return (
                <div
                  className={`page_subscription_choose_package_feature_${title} flex w-44 gap-x-2`}
                  key={id}
                >
                  <SVG
                    className={`page_subscription_choose_package_svg_${title}`}
                    src={icon}
                  />
                  <p
                    className={`page_subscription_choose_package_feature_title_${title}`}
                  >
                    {title}
                  </p>
                </div>
              )
            })}
          </Card>
          <>
            <div className="container page_subscription_choose_package_isNativePlatform_container flex justify-center self-center pt-10">
              <div className="pt-4 page_subscription_choose_package_isNativePlatform_wrapper">
                <div className="page_subscription_choose_package_isNativePlatform_text text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-white">
                  حدد الفئة العمرية الخاصة بك
                </div>
                <div className="p-3 page_subscription_choose_package_isNativePlatform_toggle_button_wrapper">
                  <ToggleButton
                    onChange={(value) => {
                      setToggleValue(value)
                      if (value === 'default') setRadioValue('')
                    }}
                    toggleFirst={{
                      label: 'فئة عمرية واحده',
                      value: 'age-group',
                    }}
                    toggleSecond={{
                      label: 'فئتين عمريتين',
                      value: 'default',
                    }}
                    value={toggleValue}
                  />
                </div>
                {toggleValue !== 'default' ? (
                  <div className="page_subscription_choose_package_isNativePlatform_radio_selection_wrapper">
                    <RadioSelection
                      name="selection"
                      onChange={(value) => setRadioValue(value)}
                      options={[
                        {
                          label: '6-4',
                          value: 'level-1',
                        },
                        {
                          label: '9-7',
                          value: 'level-2',
                        },
                      ]}
                      selectedValue={radioValue}
                    />
                  </div>
                ) : null}
              </div>
            </div>

            <div className="flex page_subscription_choose_packages_wrapper justify-center items-center container self-center gap-5 max-lg:-mt-8 max-lg:scale-75 max-sm:flex-col mb-14">
              {packages ? (
                packages
                  .filter(({ type }) =>
                    toggleValue === 'default'
                      ? type === 'allAccess'
                      : type === 'ageGroupAccess'
                  )
                  .map((pkg) => {
                    const isYearlyPackage =
                      pkg.identifier === 'ONE_YEAR' ||
                      pkg.identifier === 'AGE_ONE_YEAR' ||
                      pkg.identifier === '$rc_annual'

                    return (
                      <Card
                        className="flex page_subscription_choose_package_card justify-center flex-col w-[340px] pt-1 pb-11 mt-6 relative max-lg:w-[310px]"
                        key={pkg.identifier}
                      >
                        <div className="flex justify-center mb-3 page_subscription_choose_package_card_child">
                          <SVG
                            className="page_subscription_choose_package_card_icon"
                            src={
                              isYearlyPackage
                                ? CHOOSE_PACKAGE_IMAGES.cardBackground
                                : CHOOSE_PACKAGE_IMAGES.cardBackgroundLight
                            }
                            width={isMobileView ? 297 : 327}
                          />

                          <div className="z-10 absolute page_subscription_choose_package_typography">
                            <div className="flex justify-between px-7 w-[330px] pt-5">
                              <p className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl page_subscription_choose_package_title">
                                {pkg.title}
                              </p>
                              <span>
                                <p
                                  className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl page_subscription_choose_package_price"
                                  style={{ direction: 'ltr' }}
                                >
                                  {pkg.priceString
                                    .replace('.00', '')
                                    .replace(/ /g, '')}
                                </p>
                                {isYearlyPackage && !!savingPercent ? (
                                  <p className="absolute text-base -bottom-6 text-white bg-v3-purple-600 rounded-full px-2 h-6 whitespace-nowrap left-6 page_subscription_choose_package_yearly_saving">
                                    {savingPercent}
                                  </p>
                                ) : null}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-col page_subscription_choose_package_benefits">
                          {CHOOSE_PACKAGE_BENEFITS.map((item) => {
                            return (
                              <div
                                className="flex page_subscription_choose_package_benefit_container flex-row justify-between items-center px-6 text-base sm:text-base md:text-lg lg:text-xl xl:text-xl"
                                key={item}
                              >
                                <p className="page_subscription_choose_package_benefit_item_text">
                                  {item}
                                </p>
                                <SVG
                                  className="page_subscription_choose_package_benefit_icon"
                                  src={
                                    isYearlyPackage
                                      ? CHOOSE_PACKAGE_IMAGES.cardCheckGreen
                                      : CHOOSE_PACKAGE_IMAGES.cardCheckBlue
                                  }
                                />
                              </div>
                            )
                          })}

                          <RoundedButton
                            className={`${
                              isYearlyPackage
                                ? 'bg-v3-green v3-shadow-green shadow-[4px_4px_0px_0px_#5E7426] group'
                                : 'bg-v3-blue v3-shadow-blue shadow-[4px_4px_0px_0px_#4399CE] group'
                            } absolute -bottom-7 self-center page_subscription_choose_package_benefit_button`}
                            onClick={async () =>
                              handleChooseSubscription(pkg.identifier)
                            }
                          >
                            <p className="text-lg page_subscription_choose_package_benefit_subscribe_text sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-v3-purple group-hover:text-white">
                              {CHOOSE_PACKAGE_TEXT.SUBSCRIBE}
                            </p>
                          </RoundedButton>
                        </div>
                      </Card>
                    )
                  })
              ) : (
                <Loading />
              )}
            </div>
          </>
        </div>
      </AppContent>
      <Popup
        buttonOnClick={handleRedirect}
        buttonText={SUBSCRIBE_NOW_MESSAGE}
        message={MESSAGE_NOT_HAVE_KID}
        onClose={() => setDialog(false)}
        title=""
        visible={dialog}
      />
    </>
  )
}

export default ChoosePackage
