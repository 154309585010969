import FeatureCard from '@components/FeatureCard'
import { AppBar, AppContent } from '@components/Layout'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import { useAnonymous } from '@contexts/AnonymousContext/AnonymousContext'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { useScheduleKids } from '@hooks/data/fetchers/use-schedule-kids'
import {
  DESTINATION_MESSAGE_2,
  QUESTION_TO_KID,
  WELCOME_KID_MESSAGE,
} from '@src/constants'
import { IFeatures } from '@src/types/api.interface'
import HelperAPI from '@utils/helpers/helper.api'
import HelperProfile from '@utils/helpers/helper.profile'
import { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'

const KidsHome = () => {
  useScheduleKids()
  const history = useHistory()
  const { logout } = useAnonymous()
  const anonLocationExists = HelperProfile.getStoredRedirectLocation()
  const { kidProfile: profile } = useProfile()
  const kidFromStorage = HelperProfile.getStoredKid() ?? profile
  !profile && logout()

  const displayName = kidFromStorage
    ? kidFromStorage.nickname || kidFromStorage.firstName
    : ''

  useEffect(() => {
    if (
      anonLocationExists &&
      typeof anonLocationExists === 'string' &&
      anonLocationExists.trim() !== ''
    ) {
      HelperProfile.removeStoredRedirectLocation()
      history.push(anonLocationExists)
    }
  }, [anonLocationExists, history])

  const [isLoadingFeatures, setIsLoadingFeatures] = useState<boolean>(true)
  const [features, setFeatures] = useState<IFeatures[]>([])
  const getData = useCallback(() => {
    const url = '/features'
    HelperAPI.ApiRequest<IFeatures[]>(url)
      .then((features) => {
        if (!Array.isArray(features) || 'error' in features) {
          return
        }
        setFeatures(features?.filter((feature) => feature?.status))
        setIsLoadingFeatures(false)
      })
      .catch((e) => {
        if (e.status === 401) {
          logout()
        }
      })
  }, [logout])

  useEffect(() => {
    if (Array.isArray(features) && features.length === 0) {
      getData()
    }
  }, [getData, features])

  return (
    <>
      <Helmet>
        <title>عالم زيدو - أطفال</title>
      </Helmet>
      {isLoadingFeatures ? <AppProgressBar /> : null}
      <AppBar
        backButtonLocation="/list-kid-home"
        fixed
        showKidAvatar
        showParentBackButton
        title=""
      />
      <AppContent className="bg-no-repeat bg-cover bg-center bg-green-v3 safe-area pages_kidhome_appcontent">
        <div className="flex-row items-center justify-center mt-40px lg:mt-[104px] pages_kidhome_container">
          <div className="w-full pages_kidhome_typography max-w-[100%] flex items-center justify-center text-[40px] mb-2 md:mb-5 lg:mb-14 mt-12 flex-col gap-y-2 md:gap-y-5 lg:gap-y-10	">
            <h1 className="text-[25px] lg:text-3xl pages_kidhome_typography_welcome_text font-bold text-yellow">
              {`${WELCOME_KID_MESSAGE} ${displayName} ${DESTINATION_MESSAGE_2}`}
            </h1>
            <p className="text-xl text-white pages_kidhome_typography_question_text">
              {QUESTION_TO_KID}
            </p>
          </div>
          <div className="h-[235px] pages_kidhome_feature_container lg:h-[315px] mt-4 md:mt-6 mx-auto w-[360px] lg:w-[813px] justify-center items-center lg:mt-[90px]">
            {features?.length ? (
              <div className="flex items-center pages_kidhome_feature_child justify-center gap-x-5 lg:gap-x-[62px]">
                {features.map(({ reference, _id }) => (
                  <FeatureCard key={_id} reference={reference} />
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </AppContent>
    </>
  )
}

export default KidsHome
