import React, { FC, useEffect, useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExpand,
  faCompress,
  faArrowLeft,
  faArrowRight,
  //   faExpandArrowsAlt,
  //   faArrowsAltV,
  // faArrowsAltH,
} from '@fortawesome/free-solid-svg-icons'
import './PDFViewer.css'

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const PDFViewer: FC<{ url: string }> = ({ url }) => {
  const [numPages, setNumPages] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [loadError, setLoadError] = useState(false)
  const [fitToHeight, setFitToHeight] = useState(false)
  const [fitToWidth, setFitToWidth] = useState(true)
  const [height, setHeight] = useState(0)
  const [width, setWidth] = useState(0)

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
    setIsLoading(false)
    setLoadError(false)
  }

  const handleFitToHeight = (forced?: null | boolean) => {
    const con = typeof forced === 'boolean' ? forced : !fitToHeight
    setFitToHeight(con)
  }

  const handleFitToWidth = (forced?: null | boolean) => {
    setFitToWidth(typeof forced === 'boolean' ? forced : !fitToWidth)
  }

  const handleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen()
      handleFitToHeight(true)
      handleFitToWidth(true)
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
        handleFitToHeight(false)
        handleFitToWidth(true)
      }
    }

    setIsFullScreen(!isFullScreen)
  }

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1)
  }

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1)
  }

  const handleLoadError = () => {
    setIsLoading(false)
    setLoadError(true)
  }

  useEffect(() => {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }, [])

  return (
    <div
      className={`pdf-container pt-[30px] component_pdf_viewer_container ${
        isFullScreen ? 'fullscreen' : ''
      } ${fitToHeight ? `h-[${height}]` : 'h-[70vh] lg:h-[82vh]'} ${
        fitToWidth ? `w-[${width}]` : 'w-[99vw]'
      } `}
    >
      <div className="pdf-controls component_pdf_viewer_pdf_controls_container">
        {/* <button className="control-button" onClick={() => handleFitToWidth()}>
          <FontAwesomeIcon icon={faArrowsAltH} />
        </button> */}
        {/* <button className="control-button" onClick={() => handleFitToHeight()}>
          <FontAwesomeIcon icon={faArrowsAltV} />
        </button> */}
        <button
          className="control-button component_pdf_viewer_control_button_fullscreen"
          onClick={handleFullScreen}
          type="button"
        >
          {isFullScreen ? (
            <FontAwesomeIcon icon={faCompress} />
          ) : (
            <FontAwesomeIcon icon={faExpand} />
          )}
        </button>
        <button
          className="control-button component_pdf_viewer_control_button_next"
          disabled={currentPage >= numPages}
          onClick={handleNextPage}
          type="button"
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
        <button
          className="control-button component_pdf_viewer_control_button_prev"
          disabled={currentPage <= 1}
          onClick={handlePreviousPage}
          type="button"
        >
          <FontAwesomeIcon
            className="component_pdf_viewer_control_button_prev_icon"
            icon={faArrowLeft}
          />
        </button>
      </div>
      <div className="pdf-content component_pdf_viewer_content">
        {isLoading ? (
          <div
            className="pdf-loading ltr component_pdf_viewer_loading_container"
            style={{ direction: 'ltr' }}
          >
            Loading...
          </div>
        ) : null}
        {loadError ? (
          <div
            className="pdf-error component_pdf_viewer_error_container"
            style={{ direction: 'ltr' }}
          >
            Failed to load PDF.
          </div>
        ) : null}
        {!loadError ? (
          <Document
            className="component_pdf_viewer_document_canvas_container"
            file={url}
            onLoadError={handleLoadError}
            onLoadSuccess={onDocumentLoadSuccess}
            renderMode="canvas"
          >
            <Page
              className="component_pdf_viewer_document_canvas_page"
              height={fitToHeight ? height : 500}
              pageNumber={currentPage}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              scale={1}
              width={fitToWidth ? width : 500}
            />
          </Document>
        ) : null}
      </div>
    </div>
  )
}

export default PDFViewer
