/**
 * Checks if all items in array1 exist in array2.
 *
 * @param array1 - The first array to check.
 * @param array2 - The second array to check.
 * @returns True if all items in array1 exist in array2, false otherwise.
 */
export const checkArrayItems = (
  array1: string[],
  array2: string[]
): boolean => {
  // If array2 is empty, return false
  if (Array.isArray(array2) && array2.length === 0) return false

  // Use a set to store all items in array2 (for O(1) lookup time)
  const set2 = new Set(array2)

  // Iterate through array1 and check if each item exists in set2
  for (let i = 0; i < array1.length; i++) {
    if (!set2.has(array1[i])) {
      return false
    }
  }

  // If all items in array1 exist in set2, return true
  return true
}
