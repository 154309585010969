import { useMemo } from 'react'
import useSWR from 'swr'
import { CompetitionsNew } from '@src/types/api.interface'
import { FetchError } from '@utils/FetchError'

export function useCompetitionNew(level: number): {
  competitions: CompetitionsNew[]
  error: FetchError
  isLoadingCompetitions: boolean
} {
  const url = `/competitionsNew/all/${level}`

  const { data, error } = useSWR<CompetitionsNew[], FetchError>(url)

  return useMemo(
    () => ({
      competitions: data,
      error,
      isLoadingCompetitions: !data && !error,
    }),
    [data, error]
  )
}
