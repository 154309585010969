import { useHistory } from 'react-router-dom'
import { FC, useEffect, useRef, ReactNode } from 'react'
import GreenButton from './Buttons/GreenButton'

const Popup: FC<{
  title: string
  message?: string | ReactNode
  buttonText?: string
  buttonOnClick?: () => void
  visible: boolean
  onClose: () => void
  acceptLink?: string
  competitionPopup?: boolean
}> = (props) => {
  const history = useHistory()
  const popupRef = useRef(null)

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        props.onClose()
      }
    }
    if (props.visible && props?.competitionPopup) {
      document.addEventListener('mousedown', handleOutsideClick)
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [props.visible, props?.competitionPopup, props])

  return (
    <div
      className={`fixed w-full h-full z-[101] justify-center items-center justify-items-center top-0 bottom-0 popup-container ${
        props.visible
          ? 'block'
          : 'translate-y-[2000px] opacity-0 pointer-events-none hidden'
      }`}
    >
      <div className="popup-box flex justify-center relative w-full h-full items-center overflow-auto px-6 top-0 left-0 bg-black bg-opacity-70 popup-box-wrapper">
        {!props?.competitionPopup ? (
          <div className="pencil-icon-v3 popup_box-img-wrpr">
            <img
              alt="pencil"
              className="popup_box-img"
              src="/images/v3/pencil.png"
            />
          </div>
        ) : null}
        <div
          className={`box relative popup_box-bg-container ${
            props?.competitionPopup
              ? `max-w-[427px] h-[258px] lg:max-w-[637px] lg:h-[292px] bg-white border-v3-green-850`
              : `max-w-[340px] h-[257px] bg-v3-purple-200 border-v3-purple-500`
          }  rounded-[50px] border-8   top-[25px]`}
          ref={popupRef}
        >
          <div
            className={`popup_box-bg-popup-v3 ${
              props?.competitionPopup ? `bg-popup-competition` : `bg-popup-v3`
            }`}
          >
            {!props?.competitionPopup ? (
              <span
                className="bg-icon-close-v3 cursor-pointer popup_box-close-icon"
                onClick={() => props.onClose()}
              />
            ) : null}
            <div className="flex justify-center items-center h-[100%] popup_box-main">
              <div className="grid place-items-center break-normal text-center h-full popup_box-container">
                <div className="text-[20px] w-[80%] popup_box-title-wrapper">
                  {props.title ? (
                    <div
                      className={`popup_box-title ${
                        props?.competitionPopup
                          ? `popup-title-competition`
                          : `popup-title-v3`
                      } ${
                        props?.competitionPopup
                          ? `w-[217px] lg:w-[319px] lg-[42px]`
                          : `w-[197px]  h-12`
                      }  z-20 ${
                        props?.competitionPopup ? `-top-5` : `top-[-30px]`
                      } bg-v3-orange-300 rounded-[15px] block absolute  ${
                        props?.competitionPopup
                          ? `left-[94px] lg:left-[153px] right-auto text-v3-purple-100 text-3xl pb-4 lg:text-4xl`
                          : `right-[65px] text-4xl`
                      } text-v3-purple-300 overflow-hidden text-ellipsis px-4 text-center`}
                    >
                      {props.title}
                    </div>
                  ) : null}
                </div>
                {props.message ? (
                  <div
                    className={`popup_box-msg-wrpr ${
                      props?.competitionPopup
                        ? `w-[337px] lg:w-[450px] h-[86px] lg:h-[93px] lg:px-16`
                        : `h-[145px] w-[261px]`
                    } rounded-[40px] mt-4 flex flex-1 justify-center items-center flex-col`}
                    style={
                      !props?.competitionPopup
                        ? { background: 'rgba(148, 207, 243, 0.2)' }
                        : {}
                    }
                  >
                    {!props?.competitionPopup ? (
                      <img
                        alt="yellowTick"
                        className="popup_box-img"
                        height={60}
                        src="/images/v3/yellowTick.svg"
                        width={60}
                      />
                    ) : null}
                    <div
                      className={`popup_box-msg ${
                        props?.competitionPopup
                          ? `text-black text-[32px]`
                          : `text-white text-xl`
                      } `}
                    >
                      {props.message}
                    </div>
                  </div>
                ) : null}
                {props.buttonText && !props.acceptLink ? (
                  <GreenButton
                    className={`px-11 ${
                      props?.competitionPopup
                        ? `w-[208px] h-[48px] shadow-[3px_3px_0px_0px_#8BB425]`
                        : ``
                    }`}
                    label={props.buttonText}
                    onClick={props.buttonOnClick}
                  />
                ) : null}
                {props.buttonText && props.acceptLink ? (
                  <GreenButton
                    className="px-11"
                    label={props.buttonText}
                    onClick={() => history.push(props.acceptLink)}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Popup
