/* eslint-disable @typescript-eslint/no-explicit-any */
import { MeetingType } from '@src/types/api.interface'
import { sendGaToFirebase } from './sendGaToFirebase'
type RegisterType = 'schedule-test-meeting' | 'without-test-meeting'

export function openMeeting(props: { type: MeetingType }) {
  const event: any = {
    event: 'maqraa_meeting',
    eventCategory: 'Maqraa',
    eventAction: 'Open meeting',
    eventLabel:
      props.type === 'daily-meeting' ? 'Daily meeting' : 'Test meeting',
  }
  window.dataLayer?.push(event)
  sendGaToFirebase(event)
}

export function registerMaqraa(props: { type: RegisterType }) {
  const event: any = {
    event: 'maqraa_register',
    eventCategory: 'Maqraa',
    eventAction: 'Register',
    eventLabel:
      props.type === 'without-test-meeting'
        ? 'Register without test meeting'
        : 'Register and schedule test meeting',
  }
  window.dataLayer?.push(event)
  sendGaToFirebase(event)
}
