import React, { useEffect, useMemo, useRef, FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'
import { OrangeLink } from '@components/Buttons'
import { ChallengeContainer } from '@components/challenges/challenge-container'
import { ChallengeWithGame } from '@components/challenges/challenge-with-game'
import { ChallengeWithImageQuestionAndAnswers } from '@components/challenges/challenge-with-image-question-and-answers'
import { ChallengeWithImageQuestionOnly } from '@components/challenges/challenge-with-image-question-only'
import { ChallengeWithTextOnly } from '@components/challenges/challenge-with-text-only'
import { ContentTitle } from '@components/ContentTitle'
import { AppBar, AppContent } from '@components/Layout'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import { useCompetitionWithUserAnswers } from '@hooks/data/fetchers/competition/use-competition-with-user-answers'
import { useChallengeReducer } from '@hooks/use-challenge-reducer'
import { COMPETITION_IFRAME_ID } from '@utils/constants'
import { GtagEvents } from '@utils/gtag-events'
import { logEvent } from '@utils/firebase-analytics'

const CompetitionDailyChallenge: FC = () => {
  const history = useHistory()
  const { competition, error, isLoadingCompetition, userAnswers } =
    useCompetitionWithUserAnswers()

  const challenge = competition?.challenges?.[0]
  const challengeReducers = useChallengeReducer(challenge)

  const score = useMemo(() => {
    if (!userAnswers) return null
    return userAnswers.reduce((prev, cur) => {
      return prev + cur.points
    }, 0)
  }, [userAnswers])

  useEffect(() => {
    logEvent('open_daily_competition')
    return () => {
      logEvent('close_daily_competition')
    }
  }, [competition, challenge])

  const currentQuestion =
    challenge?.questions[challengeReducers.state.questionIndex]

  const isQuestionWithGame =
    currentQuestion && 'gameUrl' in currentQuestion && !!currentQuestion.gameUrl

  const isQuestionWithTextOnly =
    currentQuestion &&
    'text' in currentQuestion &&
    !!currentQuestion.text &&
    !currentQuestion.image &&
    currentQuestion.answers?.[0] &&
    !currentQuestion.answers[0].image

  const isQuestionWithImageQuestionOnly =
    currentQuestion &&
    'text' in currentQuestion &&
    !!currentQuestion.text &&
    !!currentQuestion.image &&
    currentQuestion.answers?.[0] &&
    !currentQuestion.answers[0].image

  const isQuestionWithImageQuestionAndAnswers =
    currentQuestion &&
    'text' in currentQuestion &&
    !!currentQuestion.text &&
    !!currentQuestion.image &&
    currentQuestion.answers?.[0] &&
    !!currentQuestion.answers[0].image

  useEffect(() => {
    if (!error) return
    history.replace('/competition')
  }, [error, history])

  const eventSent = useRef(false)
  useEffect(() => {
    if (eventSent.current) return
    if (!competition || !challenge) return
    GtagEvents.openDailyCompetition({
      level: competition.level,
      challengeName: challenge.uniqueName,
      competitionName: competition.uniqueName,
    })
    eventSent.current = true
  }, [competition, challenge])

  const isFinished =
    !!challenge &&
    (challenge.questions.length <= challengeReducers.state.questionIndex ||
      challengeReducers.state.questionIndex === -1)

  const noChallenge = !isLoadingCompetition && !challenge
  return (
    <>
      <Helmet>
        <title className="competition_daily_challenge_page-main-title">
          عالم زيدو - التحدي اليومي
        </title>
      </Helmet>
      {isLoadingCompetition ? <AppProgressBar /> : null}

      {challengeReducers.state.submitting ? <AppProgressBar /> : null}

      {isFinished || noChallenge ? (
        <AppBar fixed showBackButton showHomeLink showLogoutButton title="" />
      ) : null}
      <AppContent
        className="bg-no-repeat bg-cover bg-center bg-episodes"
        style={{
          backgroundImage: currentQuestion
            ? `url(${currentQuestion?.background})`
            : '',
        }}
      >
        {noChallenge ? (
          <div className="competition_daily_challenge_page-content-title-wrapper h-screen flex justify-center items-center">
            <ContentTitle
              noShadow
              textColor="text-black"
              title="لا يوجد تحد متاح الآن"
            />
          </div>
        ) : null}
        {currentQuestion ? (
          <ChallengeContainer
            gameNodeId={isQuestionWithGame ? COMPETITION_IFRAME_ID : null}
            score={score}
            title={currentQuestion?.title ?? ''}
          >
            {isQuestionWithTextOnly ? (
              <ChallengeWithTextOnly
                challengeReducers={challengeReducers}
                question={currentQuestion}
              />
            ) : isQuestionWithImageQuestionOnly ? (
              <ChallengeWithImageQuestionOnly
                challengeReducers={challengeReducers}
                question={currentQuestion}
              />
            ) : isQuestionWithImageQuestionAndAnswers ? (
              <ChallengeWithImageQuestionAndAnswers
                challengeReducers={challengeReducers}
                question={currentQuestion}
              />
            ) : isQuestionWithGame ? (
              <ChallengeWithGame
                challengeReducers={challengeReducers}
                gameNodeId={COMPETITION_IFRAME_ID}
                question={currentQuestion}
              />
            ) : null}
          </ChallengeContainer>
        ) : null}
        {isFinished ? <FinishedMessage score={score} /> : null}
      </AppContent>
    </>
  )
}

export default CompetitionDailyChallenge

const FinishedMessage: FC<{ score: number | null }> = (props) => {
  useEffect(() => {
    logEvent('finish_daily_competition')
  }, [])

  return (
    <div className="competition_daily_challenge_page-finished-message-wrapper flex flex-col text-center my-auto items-center justify-center mt-10">
      <span className="competition_daily_challenge_page-finished-message font-bold text-2xl mb-10">
        تهانينا ! لقد أنهيت هذا التحدي برصيد{' '}
        <span className="competition_daily_challenge_page-props-score">
          {props.score}.
        </span>
      </span>
      <LinkToScore />
    </div>
  )
}

const LinkToScore: FC = () => {
  return (
    <OrangeLink
      label="درجات"
      paddingClass="competition_daily_challenge_page-orange-link px-4 py-1 sm:px-8 sm:py-2 block w-[200px]"
      textSizeClass="text-lg sm:text-2xl"
      to="/competition/score"
    />
  )
}
