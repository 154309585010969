import { SubscriptionPackage } from '@src/services'

export const getDiscountPercent = (packages: SubscriptionPackage[]) => {
  if (!Array.isArray(packages)) return ''
  const monthlyPrice = Number(
    packages
      .find(
        (pkg) =>
          pkg.identifier === 'ONE_MONTH' ||
          pkg.identifier === 'AGE_ONE_MONTH' ||
          pkg.identifier.includes('monthly')
      )
      ?.priceString.replace(/\D/g, '')
  )

  const yearlyPrice = Number(
    packages
      .find(
        (pkg) =>
          pkg.identifier === 'ONE_YEAR' ||
          pkg.identifier === 'AGE_YEAR' ||
          pkg.identifier.includes('annual')
      )
      ?.priceString.replace(/\D/g, '')
  )

  const fullPrice = monthlyPrice * 12
  const percent = ((fullPrice - yearlyPrice) / fullPrice) * 100

  return Math.round(percent)
}
