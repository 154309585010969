const LOCAL_NOTIFICATIONS = [
  // {
  //   // daily 5pm (Competition)
  //   id: 0,
  //   title: '',
  //   // body: 'لا تضيع فرصه الفوز في المسابقة قم بحل أسئلة اليوم',
  //   body: 'testiong Competition',
  //   type: 'competition',
  // },
  // {
  //   // daily 7pm (Curriculum)
  //   id: 1,
  //   title: '',
  //   // body: 'هيا لنشاهد مغامرات عمر ومريم',
  //   body: 'testiong Curriculum',

  //   type: 'curriculum',
  // },
  // {
  //   // student daily maqraa meeting
  //   id: 2,
  //   title: '',
  //   body: 'اقترب ميعادك اليومي للمقرأة استعد لجني الحسنات',
  //   type: 'maqraa',
  // },
  // {
  //   // 1 day from last opening
  //   id: 3,
  //   title: '',
  //   body: 'عالم زيدو بانتظارك',
  //   type: 'opening',
  // },
  {
    // 3 days from last opening
    id: 4,
    title: '',
    body: 'لا تتأخروا علينا أكثر، مهام الأسبوع أوشكت على الانتهاء وسوف تأتيكم مهام جديدة الأسبوع القادم إن شاء الله.',
    type: 'opening',
  },
  {
    // 7 days from last opening
    id: 5,
    title: '',
    body: 'هل نسيت شيئا يخص أطفالك؟ نعم! إنه عالم زيدو المليء بالكتب والأنشطة والفيديوهات ومجالس القرآن... لا تحرم نفسك وأولادك من هذا الخير العظيم.',
    type: 'opening',
  },
]

export default LOCAL_NOTIFICATIONS
