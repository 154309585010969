import { FC } from 'react'
import { FadeLoader } from 'react-spinners'

const Loading: FC = () => {
  return (
    <div className="w-full h-24 flex justify-center items-center loading-container">
      <FadeLoader
        aria-label="Loading Spinner"
        color="#808080"
        data-testid="loader"
        loading={true}
      />
    </div>
  )
}

export default Loading
