import * as appAnalytics from './analytics/app'
import * as authAnalytics from './analytics/auth'
import * as competitionAnalytics from './analytics/competition'
import * as maqraaMeetingsAnalytics from './analytics/maqraa-meeting'
import * as paymentAnalytics from './analytics/payment'
import * as videoANalytics from './analytics/video'
import * as handbookAnalytics from './analytics/handbook'
import * as contentAnalytics from './analytics/content'
import * as reportAnalytics from './analytics/report'

export const GtagEvents = {
  ...appAnalytics,
  ...authAnalytics,
  ...competitionAnalytics,
  ...maqraaMeetingsAnalytics,
  ...paymentAnalytics,
  ...videoANalytics,
  ...handbookAnalytics,
  ...contentAnalytics,
  ...reportAnalytics,
}
