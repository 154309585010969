import HelperAPI from '@utils/helpers/helper.api'
import { useCallback } from 'react'
import { useActiveChallenge } from './useActiveChallenge'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'

const URL = '/challenges/record-kid-participation'

export function useSubmitParticipation(challengeId: string) {
  const { mutate } = useActiveChallenge()
  const { kidProfile } = useProfile()

  const kidId = kidProfile?._id

  return useCallback(async () => {
    const payload = {
      method: 'POST',
      body: JSON.stringify({
        kidId,
        participatedDate: new Date(), // <-- this is required both times, so we dont update score of user that is playing old challenges,
        challengeId,
      }),
    }

    const res = await HelperAPI.ApiRequest(URL, payload)
    await mutate()
    return res
  }, [challengeId, kidId, mutate])
}

export function useUpdateParticipation() {
  const { mutate } = useActiveChallenge()
  const { kidProfile } = useProfile()

  const kidId = kidProfile?._id

  interface Props {
    score: number
    challengeId: string
  }

  return useCallback(
    async ({ score, challengeId }: Props) => {
      const payload = {
        method: 'PUT',
        body: JSON.stringify({
          kidId,
          score: `${score}`,
          challengeId,
        }),
      }

      const res = await HelperAPI.ApiRequest(URL, payload)
      await mutate()
      return res
    },
    [kidId, mutate]
  )
}
