/* eslint-disable no-restricted-globals */
import { logEvent } from '@utils/firebase-analytics'
import { getIdToken } from '@utils/firebase-auth'
import { toast } from 'react-toastify'
import { cleverTapEvents } from '@src/CleverTap/CleverTap'

const FACEBOOK_ERROR =
  'لا يمكن التسجيل الان بواسطه facebookاستخدمgoogle او البريد الالكتروتي'

export const loginAction = async (
  log:
    | 'login_using_facebook'
    | 'login_using_google'
    | 'login_using_apple'
    | 'register_with_facebook'
    | 'register_with_google'
    | 'register_with_apple',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  signInMethod: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loginCallback: (e?: any) => Promise<any>
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  await signInMethod().then(async (e: any) => {
    const token = await getIdToken().then((token) => {
      return token
    })
    await logEvent(log)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleRedirect = (parentFromStorage: any) => {
      if (
        parentFromStorage?.email &&
        parentFromStorage?.email !== 'user@anonymous.firebase'
      ) {
        cleverTapEvents.setUserLoggedInProfile({
          email: 'user@anonymouse.firebase',
          name: 'anonymous',
          type: 'anonymous',
        })
        if (parentFromStorage?.kids?.length > 0)
          return location.replace('/list-kid-home')
        return location.replace('/add-kid')
      }
    }

    if (e.user) {
      loginCallback({
        username: e.user.displayName,
        provider: 'firebase',
        providerType: log,
        accessToken: token,
      }).then((response) => {
        if (!response) {
          return
        }
        if (typeof response === 'string') {
          return
        }
        cleverTapEvents.setUserLoggedInProfile({
          ...e.user,
          type: 'firebase',
        })
        handleRedirect(response)
        localStorage.setItem('provider', 'firebase')
      })
    } else {
      toast.error(FACEBOOK_ERROR, { toastId: `error_${log}` })
    }
  })
}
