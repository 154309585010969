import { ReactNode, FC } from 'react'
import { Link } from 'react-router-dom'
import { LocationDescriptor } from 'history'

export const OrangeLink: FC<{
  to: LocationDescriptor
  label: string | ReactNode
  textSizeClass?: string
  paddingClass?: string
  className?: string
}> = (props) => {
  const textClass = props.textSizeClass ?? 'text-2xl'
  const paddingClass = props.paddingClass ?? 'px-8 py-2'
  return (
    <Link
      className={`action_button_orange_link_container bg-[#f6580f] shadow-orange-buttons rounded-3xl text-center text-white whitespace-nowrap ${textClass} ${paddingClass} ${
        props.className ?? ''
      }`}
      to={props.to}
    >
      {props.label}
    </Link>
  )
}

export const OrangeButton: FC<{
  onClick: React.MouseEventHandler<HTMLButtonElement>
  label: string | ReactNode
  className?: string
  disabled?: boolean
  textSizeClass?: string
}> = (props) => {
  const textSizeClass = props.textSizeClass ?? 'text-2xl'
  return (
    <button
      className={`action_button_orange_button_container bg-[#f6580f] disabled:shadow-none disabled:bg-opacity-50 shadow-orange-buttons rounded-3xl text-center text-white px-8 py-2 whitespace-nowrap ${
        props.className ?? ''
      } ${textSizeClass}`}
      disabled={props.disabled}
      onClick={props.onClick}
      type="button"
    >
      {props.label}
    </button>
  )
}

export const OrangeButtonOutlined: FC<{
  onClick: React.MouseEventHandler<HTMLButtonElement>
  label: string
  className?: string
  disabled?: boolean
  textSizeClass?: string
}> = (props) => {
  const textSizeClass = props.textSizeClass ?? 'text-2xl'
  return (
    <button
      className={`action_button_orange_button_outlined_container border-2 border-[#f6580f] shadow-orange-300 shadow-lg hover:bg-gray-200 disabled:bg-gray-200 disabled:border-gray-300 disabled:shadow-none disabled:text-gray-500 rounded-3xl text-center text-black px-2 py-1 whitespace-nowrap ${
        props.className ?? ''
      } ${textSizeClass} `}
      disabled={props.disabled}
      onClick={props.onClick}
      type="button"
    >
      {props.label}
    </button>
  )
}

export const YellowButton: FC<{
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  label: string | ReactNode
  className?: string
  disabled?: boolean
  textSizeClass?: string
}> = (props) => {
  const textSizeClass = props.textSizeClass ?? 'text-2xl'
  return (
    <button
      className={`action_button_yellow_button_container bg-[#fdbd20] shadow-yellow-buttons rounded-xl text-center text-white px-8 py-2 whitespace-nowrap ${
        props.className ?? ''
      } ${textSizeClass} disabled:bg-gray-200 disabled:border-gray-300 disabled:shadow-none disabled:text-gray-500 `}
      disabled={props.disabled}
      onClick={props.onClick}
      type="button"
    >
      {props.label}
    </button>
  )
}

export const YellowLink: FC<{
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  to: LocationDescriptor
  target?: string
  rel?: string
  label: string | ReactNode
  textSizeClass?: string
  paddingClass?: string
  className?: string
}> = (props) => {
  const textClass = props.textSizeClass ?? 'text-2xl'
  const paddingClass = props.paddingClass ?? 'px-8 py-2'
  return (
    <Link
      className={`action_button_yellow_link_container bg-[#fdbd20] shadow-yellow-buttons rounded-xl text-center text-white whitespace-nowrap ${textClass} ${paddingClass} ${
        props.className ?? ''
      }`}
      onClick={props.onClick}
      rel={props.rel}
      target={props.target}
      to={props.to}
    >
      {props.label}
    </Link>
  )
}
