import ParentBackButtonIcon from '@components/Icons/ParentBackButtonIcon'
import Config from '@config/index'
import { faSignOut } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  KID_AVATAR_MALE_NO_EYES_ICON,
  KID_AVATAR_FEMALE_NO_EYES_ICON,
} from '@utils/constants'
import { requestFullScreen } from '@utils/fullscreen'
import React, { FC, useCallback } from 'react'
import SVG from 'react-inlinesvg'
import { useHistory, useLocation } from 'react-router-dom'
import AssetDownloadLink from '../AssetDownloadLink'
import { UserAvatar } from './KidCard/UserAvatar'
// const btnBack = `${Config.publicURL}/images/btn-back.png`
const btnTgl = `${Config.publicURL}/images/toggle-button.png`
const downloadSvg = `${Config.publicURL}/images/btn-download.svg`
const fullscreenSvg = `${Config.publicURL}/images/btn-fullscreen.svg`
const btnHome = `${Config.publicURL}/images/btn-home.svg`
const newBtnHome = `${Config.publicURL}/images/v2/home-button-new@3x.png`
const navGallery = `${Config.publicURL}/images/nav-gallery.svg`
const userFemale = `${Config.publicURL}/images/user-female.svg`
const userMale = `${Config.publicURL}/images/user-male.svg`
const user = `${Config.publicURL}/images/user.svg`
const newBtn = `${Config.publicURL}/images/btn-back.png`
const newBtn2 = `${Config.publicURL}/images/v3/backBtnWthShadow.svg`
const closeIcon = `${Config.publicURL}/images/v2/close-icon.png`

export const BackButton: FC<{
  to: string | undefined
  onClick?: () => void
  newVisual?: boolean
  closeIcon?: boolean
  parentBackButton?: boolean
}> = (props) => {
  const history = useHistory()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const hookLocation: any = useLocation()

  const { onClick, newVisual } = props
  const handleClick: React.MouseEventHandler = useCallback(() => {
    if (onClick) {
      onClick()
      return
    } else if (props.to) {
      // eslint-disable-next-line no-restricted-globals
      location.replace(props.to)
      return
    }
    const isDeepLink =
      window.performance.navigation.type === 1 ||
      hookLocation?.state?.fromDeepLink

    if (isDeepLink) {
      // eslint-disable-next-line no-restricted-globals
      location.replace('/')
    } else {
      history.goBack()
    }
  }, [onClick, props.to, hookLocation.state, history])

  const size = newVisual ? '80px' : '48px'
  return (
    <button
      className="rounded-full component_layout_appbar_button_parent_back_container"
      draggable={false}
      onClick={handleClick}
      style={{
        width: size,
        height: size,
      }}
      type="button"
    >
      {props.parentBackButton ? (
        <ParentBackButtonIcon />
      ) : (
        <img
          alt="Go back"
          className="component_layout_appbar_button_image_go_back"
          src={newVisual ? newBtn : props.closeIcon ? closeIcon : newBtn2}
        />
      )}
    </button>
  )
}

export const LogoHomeLink: FC<{ onClick?: () => void }> = (props) => {
  return (
    <AppBarButton className="component_layout_appbar_logo_home_link_container">
      <a
        className="component_layout_appbar_logo_home_link_anchor"
        href="/"
        onClick={props.onClick}
      >
        <img
          alt="عالم زيدو"
          className="component_layout_appbar_logo_home_link_img"
          src={`${Config.publicURL}/images/logo.svg`}
        />
      </a>
    </AppBarButton>
  )
}

export const ToggleBarButton: FC<{ onClick: () => void }> = (props) => {
  return (
    <button
      className="text-[#F6580F] text-3xl rounded-full w-[52px] h-[52px] component_layout_appbar_toggle_bar_button_tag"
      onClick={props.onClick}
      type="button"
    >
      <img
        alt="Toggle"
        className="component_layout_appbar_toggle_bar_img"
        src={btnTgl}
      />
    </button>
  )
}

export const GoToGalleryBtn: FC<{ level: number }> = () => {
  return (
    <AppBarButton className="component_layout_appbar_goto_gallery_btn_container">
      <a
        className="component_layout_appbar_goto_gallery_button_anchor"
        href="/gallery"
      >
        <img
          alt="Go to gallery"
          className="component_layout_appbar_goto_gallery_img"
          src={navGallery}
        />
      </a>
    </AppBarButton>
  )
}

export const HomeButton: FC<{
  to?: string
  newHomeIcon?: boolean
}> = ({ to, newHomeIcon }) => {
  return (
    <AppBarButton className="component_layout_appbar_home_button_container">
      <a
        className="component_layout_appbar_home_button_anchor"
        href={to ?? '/kids-home'}
      >
        <img
          alt="Go to home page"
          className="component_layout_appbar_home_button_img"
          src={newHomeIcon ? newBtnHome : btnHome}
        />
      </a>
    </AppBarButton>
  )
}

export const KidAvatar: FC<{
  kidName: string
  gender: string
}> = ({ kidName, gender }) => {
  return kidName.length > 0 ? (
    <div className="component_layout_appbar_kid_avatar_container flex items-center justify-between h-[46px] border py-1 px-2 rounded-full bg-transparent border-[#81C2A1] w-[150px] lg:max-w-lg">
      <UserAvatar
        avatarSize={25}
        className="h-[36px] w-[36px] component_layout_appbar_kid_user_avatar_Tag"
        fill="#81C2A1"
        image={
          gender === 'male'
            ? KID_AVATAR_MALE_NO_EYES_ICON
            : KID_AVATAR_FEMALE_NO_EYES_ICON
        }
      />

      <span className="component_layout_appbar_kid_name_span flex-auto text-lg xl:text-xl text-white truncate px-4">
        {kidName}
      </span>
    </div>
  ) : null
}

export const ProfileButton: FC<{ gender: string }> = (props) => {
  const avatar =
    props.gender === 'female'
      ? userFemale
      : props.gender === 'male'
      ? userMale
      : user

  return (
    <AppBarButton className="component_layout_appbar_profile_button_container">
      {/* <Link to="/profile"> */}
      <img
        alt="avatar"
        className="component_layout_appbar_avatar_img"
        src={avatar}
      />
      {/* </Link> */}
    </AppBarButton>
  )
}

export const LogoutButton: FC<{
  displayName: string
  onLogout: () => void
}> = (props) => {
  return (
    <>
      <div className="block sm:hidden component_layout_appbar_primary_signout_button_container">
        <AppBarButton className="component_layout_appbar_primary_signout_appbar_container">
          <button
            aria-label="خروج"
            className="component_layout_appbar_primary_signout_button flex items-center justify-center rounded-full h-[48px] w-[48px] bg-[#f6580f] button-inner-shadow text-white text-[28px]"
            onClick={props.onLogout}
            type="button"
          >
            <FontAwesomeIcon
              className="component_layout_appbar_primary_signout_button_icon"
              icon={faSignOut}
            />
          </button>
        </AppBarButton>
      </div>
      <div className="hidden sm:flex items-center component_layout_appbar_username_signout_container">
        <div className="component_layout_appbar_username_signout_child_container flex flex-col justify-center h-full">
          <div className="component_layout_appbar_username_div text-white text-center text-shadow mb-1 hidden sm:block">
            {props.displayName}
          </div>
          <button
            className="component_layout_appbar_signout_button_container flex items-center text-sm text-white rounded-3xl px-6 pb-2 pt-1 bg-[#f6580f] button-inner-shadow"
            onClick={props.onLogout}
            type="button"
          >
            <FontAwesomeIcon
              className="component_layout_appbar_signout_icon"
              icon={faSignOut}
            />
            <span className="mr-1 component_layout_appbar_signout_text_span">
              خروج
            </span>
          </button>
        </div>
      </div>
    </>
  )
}

export const FullScreenButtonInAppBar: FC<{ nodeId: string }> = (props) => {
  return (
    <AppBarButton className="component_layout_appbar_fullscreen_button_inappbar_container">
      <FullScreenButton nodeId={props.nodeId} />
    </AppBarButton>
  )
}

export const FullScreenButton: FC<{ nodeId: string }> = (props) => {
  return (
    <button
      aria-label="show full screen"
      className="component_layout_appbar_fullscreen_button_container"
      onClick={() => requestFullScreen(props.nodeId)}
      type="button"
    >
      <SVG
        className="rounded-full component_layout_appbar_fullscreen_button_icon"
        src={fullscreenSvg}
        width="100%"
      />
    </button>
  )
}

export const DownloadButton: FC<{ file: string }> = (props) => {
  return (
    <AppBarButton className="component_layout_appbar_download_button_container">
      <AssetDownloadLink
        file={props.file}
        fileName={props.file ? props.file.split('/').pop() : null}
      >
        <SVG
          className="component_layout_appbar_button_download_button_icon"
          src={downloadSvg}
        />
      </AssetDownloadLink>
    </AppBarButton>
  )
}

const AppBarButton = (props: {
  children: React.ReactNode | string
  className?: string
}) => {
  return (
    <span
      className={`${
        props.className || 'component_layout_appbar_button_appBarButton_span'
      }  inline-block w-[48px] h-[48px]`}
    >
      {props.children}
    </span>
  )
}
