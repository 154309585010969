import { AnonymousProvider } from '@contexts/AnonymousContext/AnonymousContext'
import { ProfileProvider } from '@contexts/ProfileContext/ProfileContext'
import HelperProfile from '@utils/helpers/helper.profile'
import { Route } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import ProtectAnonymousRoute from './ProtectAnonymousRoutes'
import RedirectToAuth from './RedirectToAuth'

const Router = ({
  component,
  kidOnly = false,
  anonOnly = false,
  publicRoute = false,
  authRoutes = false,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) => {
  // from memory
  // const { profileType: typeFromHook } = useProfile()
  const loggedInUserType = HelperProfile.getProfileType()
  const profile = HelperProfile.getStoredProfile()
  // const kid = HelperProfile.getStoredKid()
  // const profileType = HelperProfile.getProfileCategory()
  // console.log({
  //   component: component.name,
  //   path: rest.path,
  //   from: 'router',
  //   profile,
  //   loggedInUserType,
  //   kid,
  //   profileType,
  //   typeFromHook,
  //   publicRoute,
  // })
  /* temporary disable anon based auth login */
  if (profile && authRoutes && loggedInUserType === 'legacy') {
    return (
      <ProfileProvider>
        <AnonymousProvider>
          <RedirectToAuth
            component={component}
            kidOnly={kidOnly}
            type={loggedInUserType}
            {...rest}
          />
        </AnonymousProvider>
      </ProfileProvider>
    )
  }
  if (publicRoute) {
    return (
      <ProfileProvider>
        <AnonymousProvider>
          <Route component={component} {...rest} />
        </AnonymousProvider>
      </ProfileProvider>
    )
  } else if (!anonOnly && !publicRoute) {
    return (
      <ProfileProvider>
        <AnonymousProvider>
          <PrivateRoute
            anonOnly={anonOnly}
            authRoutes={authRoutes}
            component={component}
            kidOnly={kidOnly}
            loggedInUserType={loggedInUserType}
            {...rest}
          />
        </AnonymousProvider>
      </ProfileProvider>
    )
  } else if (anonOnly && !publicRoute) {
    return (
      <ProfileProvider>
        <AnonymousProvider>
          <ProtectAnonymousRoute
            component={component}
            kidOnly={kidOnly}
            {...rest}
          />
        </AnonymousProvider>
      </ProfileProvider>
    )
  }
}

export default Router
