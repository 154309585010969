import Config from '@config/index'
import { useAnonymous } from '@contexts/AnonymousContext/AnonymousContext'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { LoginProps } from '@pages/Login/LoginForm'
import { loginAction } from '@pages/Login/LoginMethods'

import { PRIVACY_POLICY_LINK, USAGE_POLICY_LINK } from '@src/constants'
import {
  initialize,
  signInWithApple,
  signInWithFacebook,
  signInWithGoogle,
} from '@utils/firebase-auth'
import HelperJWT from '@utils/helpers/helper.jwt'
import HelperProfile from '@utils/helpers/helper.profile'
import React, { FC, MouseEvent, SetStateAction, useState } from 'react'
import { Link } from 'react-router-dom'
import { TYPOGRAPHY } from './constants'
import { GtagEvents } from '@utils/gtag-events'

const apple = `${Config.publicURL}/images/v3/apple.svg`
const envelope = `${Config.publicURL}/images/v3/envelope.svg`
const google = `${Config.publicURL}/images/v3/google.svg`
const lock = `${Config.publicURL}/images/v3/lock.svg`

const LoginWithSocials = ({ provider }: LoginProps) => {
  const { login } = useProfile()
  const { logout } = useAnonymous()
  const resetProfile = () => {
    const redirectToUrl = HelperProfile.getStoredRedirectLocation()
    HelperProfile.setStoredProfile(null)
    HelperProfile.setStoredKid(null)
    HelperProfile.removeStoredKid()
    HelperProfile.removeStoredProfile()
    HelperJWT.removeJwtToken()
    if (
      redirectToUrl &&
      typeof redirectToUrl === 'string' &&
      redirectToUrl.trim().length > 0
    ) {
      HelperProfile.setStoredRedirectLocation(redirectToUrl)
    }
    logout(false)
  }

  const doSocialRegister = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    resetProfile()
    await initialize()
    if (provider === 'facebook') {
      await loginAction('register_with_facebook', signInWithFacebook, login)
    }
    if (provider === 'google') {
      GtagEvents.customEvent({ event: 'Google_regstart' })
      await loginAction('register_with_google', signInWithGoogle, login)
    }
    if (provider === 'apple') {
      GtagEvents.customEvent({ event: 'Apple_regstart' })
      await loginAction('register_with_apple', signInWithApple, login)
    }
  }

  return (
    <button
      className="register_form-do-social-register-button flex gap-x-4 text-white text-xl font-normal px-5 items-center justify-end bg-v3-blue-400 rounded-[22px] w-[138px] h-[44px] lg:w-[138px] lg:h-[44px] 2xl:w-[138px] 2xl:h-[44px] shadow-[4px_4px_0px_0px_#0D1F63]"
      onClick={doSocialRegister}
      type="button"
    >
      <span className="register_form-do-register-provider">
        {provider === 'google'
          ? 'Google'
          : provider === 'apple'
          ? 'Apple'
          : provider === 'facebook'
          ? 'Facebook'
          : ''}
      </span>
      {provider === 'google' ? (
        <img
          alt="Google Icon"
          className="register_form-do-register-google-icon"
          src={google}
        />
      ) : provider === 'apple' ? (
        <img
          alt="Apple Icon"
          className="register_form-do-register-apple-icon"
          src={apple}
        />
      ) : (
        ''
      )}
    </button>
  )
}
const RegisterForm: FC<{
  email?: string
  onChangeUsername: React.ChangeEventHandler<HTMLInputElement>
  password: string
  onChangePassword: React.ChangeEventHandler<HTMLInputElement>
  // onChangeConfirmPassword: React.ChangeEventHandler<HTMLInputElement>
  onSubmit: React.FormEventHandler<HTMLFormElement>
  isChecked: boolean
  setIsChecked: React.Dispatch<SetStateAction<boolean>>
  // isVisiblePolicy: boolean
  // isVisibleApple: boolean
  // setIsVisiblePolicy: (isVisible: boolean) => void
  // setIsVisibleApple: (isVisible: boolean) => void
}> = (props) => {
  const [revealPasswordOnHoldInput, setRevealPasswordOnHoldInput] =
    useState('password')

  const revealPasswordOnHoldButton = () => {
    setRevealPasswordOnHoldInput(
      revealPasswordOnHoldInput === 'text' ? 'password' : 'text'
    )
  }

  return (
    <form
      className="register_form-form w-full flex flex-col gap-y-2.5 lg:gap-y-5"
      onSubmit={props.onSubmit}
    >
      <div className="register_form-social-section flex gap-2.5 content-center justify-center">
        <LoginWithSocials provider="apple" />
        <LoginWithSocials provider="google" />
      </div>

      <span className="register_form-typography-or-section w-[97%] self-center flex text-center text-2xl text-white">
        <span className="register_form-typography-or-wrapper w-[45%] border-b-[1px] border-[#6C81D6] leading-[0.1rem] my-3" />
        <h2 className="register_form-typography-or w-[10%] text-center text-2xl text-white">
          {TYPOGRAPHY.OR}
        </h2>
        <span className="register_form-typography-or-border w-[45%] border-b-[1px] border-[#6C81D6] leading-[0.1rem] my-3" />
      </span>

      <div className="register_form-username-wrapper flex gap-x-4 items-center">
        <img
          alt="Envelope"
          className="register_form-email-icon"
          src={envelope}
        />
        <input
          autoComplete="off"
          className="register_form-username-input focus:ring-0 focus:border-v3-gray-200 placeholder:text-v3-gray-800 focus:border-4 focus:text-v3-gray-800 portrait:h-4 h-[35px] xl:h-[50px] drop-shadow-[0px_4px_0px_#81A2B9] p-4 pr-6 rounded-full bg-v3-gray-400 border-4 border-v3-gray-200 placeholder:text-xl text-xl xl:placeholder:text-2xl xl:text-2xl text-v3-gray-800 w-full"
          name="username"
          onChange={props.onChangeUsername}
          placeholder={TYPOGRAPHY.EMAIL_PLACEHOLDER}
          type="text"
          value={props.email}
        />
      </div>

      <div className="register_form-password-section flex relative flex-col">
        <div className="register_form-password-container flex gap-x-4 items-center">
          <img alt="Lock" className="register_form-lock-icon" src={lock} />

          <div className="register_form-wrapper flex items-center absolute left-5 z-10 rounded-full">
            <button
              className="register_form-reveal-password-button appearance-none passwordReveal w-4 xl:w-6 h-3 xl:h-4"
              onClick={revealPasswordOnHoldButton}
              tabIndex={9}
              type="button"
            />
          </div>
          <input
            autoComplete="off"
            className="register_form-password-field focus:ring-0 focus:border-v3-gray-200 placeholder:text-v3-gray-800 focus:border-4 focus:text-v3-gray-800 portrait:h-5 h-[35px] xl:h-[50px] drop-shadow-[0px_4px_0px_#81A2B9] p-4 pr-6 rounded-full bg-v3-gray-400 border-4 border-v3-gray-200 placeholder:text-xl text-xl xl:placeholder:text-2xl xl:text-2xl text-v3-gray-800 w-full"
            name="password"
            onChange={props.onChangePassword}
            placeholder={TYPOGRAPHY.PASSWORD_PLACEHOLDER}
            type={revealPasswordOnHoldInput}
            value={props.password}
          />
        </div>
      </div>

      <div className="register_form-checkbox-wrapper pr-12 text-v3-gray-300 font-normal  text-lg xl:text-xl flex items-center">
        <input
          checked={props.isChecked}
          className="register_form-checkbox ml-2 border-px rounded-md border-white bg-v3-gray-300 items-center"
          id=""
          name=""
          onChange={(e) => props.setIsChecked(e.target.checked)}
          type="checkbox"
        />
        <span className="register_form-i-agree-text text-white">
          {TYPOGRAPHY.I_AGREE_TO}
        </span>
        <span
          className="register_form-terms-of-use-text text-v3-green mr-px cursor-pointer"
          // onClick={() => props.setIsVisibleApple(true)}
        >
          <Link
            className="register_form-terms-of-use-url"
            target="_blank"
            to={{ pathname: `${USAGE_POLICY_LINK}` }}
          >
            {TYPOGRAPHY.TERMS_OF_USE}
          </Link>
        </span>
        <span className="register_form-typography-and mx-1 text-white">
          {TYPOGRAPHY.AND}
        </span>
        <span className="register_form-privacy-policy-wrapper text-v3-green mr-px cursor-pointer">
          <Link
            className="register_form-privacy-policy-link"
            target="_blank"
            to={{ pathname: `${PRIVACY_POLICY_LINK}` }}
          >
            {TYPOGRAPHY.PRIVACY_POLICY}
          </Link>
        </span>
      </div>

      <input
        className="register_form-create-account-button cursor-pointer bg-v3-green mx-auto w-auto max-w-max rounded-3xl text-xl  text-center text-v3-purple-200 mt-3 px-16 py-[11px] shadow-[3px_3px_0px_0px_#5E7426] mb-3"
        id=""
        type="submit"
        value={TYPOGRAPHY.CREATE_ACCOUNT}
      />
    </form>
  )
}

export default RegisterForm
