import { Capacitor } from '@capacitor/core'
import BreadCrumb from '@components/BreadCrumb'
import { ContentTitle } from '@components/ContentTitle'
import { AppBar, AppContent } from '@components/Layout'
import { HorizontalSeparator } from '@components/Layout/KidCard'
import Loading from '@components/Loading'
import Popup from '@components/Popup'
import Config from '@config/index'
import { usePurchases } from '@hooks/use-purchases'
import { useMobileView } from '@hooks/useMobileView'
import {
  IPurchaseManagerPayment,
  SUBSCRIPTION_ID_PACKAGES_LABELS,
  SubscriptionInfo,
  SubscriptionPackage,
} from '@src/services/purchases'
import { UNSUBSCRIBE_MESSAGE, UNSUBSCRIBE_MESSAGE_WEB } from '@utils/constants'
import { FC, ReactNode, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import SVG from 'react-inlinesvg'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

const MESSAGE_SUBSCRIPTION = 'الاشتراك'
const MESSAGE_YOU_ARE_NOT_CURRENTLY_SUBSCRIBED = 'أنت غير مشترك حاليا'
const MESSAGE_EXPIRY_DATE = 'تاريخ انتهاء الاشتراك: '
const MESSAGE_RENEW_DATE = 'تاريخ تجديد الاشتراك: '
const MESSAGE_CANCEL_REQUEST =
  'تم استقبال طلبك بنجاح وسيتم الإلغاء خلال ٢٤ ساعة'
const MESSAGE_RESUME_REQUEST =
  'تم استقبال طلبك بنجاح وسيتم الاستئناف خلال ٢٤ ساعة'
const MESSAGE_UNSUBSCRIBE = 'إيقاف الاشتراك'
const MESSAGE_RESUME = 'استئناف الاشتراك'
const MESSAGE_CANCEL_IOS = 'برجاء استخدام تطبيق هاتف ابل لإلغاء الاشتراك'
const MESSAGE_CANCEL_ANDROID =
  'برجاء استخدام تطبيق هاتف الاندرويد لإلغاء الاشتراك'
const RESUME_MESSAGE_WEB = 'هل أنت متأكد من الرغبة فى استئناف الاشتراك'
const CONFIRM = 'تأكيد'

const MESSAGE_ONE_AGE_SUBS = 'أنت الآن مشترك على باقة الفئة العمرية الواحدة'
const MESSAGE_MULTI_AGE_SUBS = 'أنت الأن مشترك على باقة الفئات العمرية المتعددة'

const TEXT = {
  TITLE: 'تفاصيل خطة الاشتراك',
  ANNUAL_FEES: 'الرسوم السنوية',
  MONTHLY_FEES: 'الرسوم الشهرية',
  ANNUAL: 'سنويًا',
  MONTHLY: 'شهريًا',
}

const CurrentSubscription: FC = () => {
  const isMobileView = useMobileView()
  const [isCancelPending, setIsCancelPending] = useState(false)
  const [isResumePending, setIsResumePending] = useState(false)
  const [packages, setPackages] = useState<SubscriptionPackage[]>()
  const [subscriptionInfo, setSubscriptionInfo] =
    useState<SubscriptionInfo | null>(null)
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [showPopup, setShowPopup] = useState(false)
  const [showResumePopup, setShowResumePopup] = useState(false)

  const purchase = usePurchases()
  const history = useHistory()

  useEffect(() => {
    purchase.getCurrentPackages().then(setPackages)
  }, [purchase])

  const getPackageText = (): ReactNode => {
    const isAnnual =
      subscriptionInfo.label ===
      SUBSCRIPTION_ID_PACKAGES_LABELS.zido_1year_sub_base
    const isMonthly =
      subscriptionInfo.label ===
      SUBSCRIPTION_ID_PACKAGES_LABELS.zido_1month_sub_base

    const filterFunc = (pkg: SubscriptionPackage) => {
      if (isAnnual) {
        return pkg.identifier === 'ONE_YEAR' || pkg.identifier === '$rc_annual'
      } else if (isMonthly) {
        return (
          pkg.identifier === 'ONE_MONTH' || pkg.identifier === '$rc_monthly'
        )
      } else {
        return null
      }
    }

    return packages.filter(filterFunc).map((pkg) => {
      if (isAnnual) {
        return (
          <p className="text-3xl" key={pkg.title}>
            <span>{`${TEXT.ANNUAL_FEES}:`}</span>
            <span style={{ direction: 'ltr' }}>
              {pkg.priceString.replace('.00', '').replace(/ /g, '')}
            </span>
            <span>{TEXT.ANNUAL}</span>
          </p>
        )
      } else if (isMonthly) {
        return (
          <p className="text-3xl" key={pkg.identifier}>
            <span>{`${TEXT.MONTHLY_FEES}: `}</span>
            <span className="text-v3-brown" style={{ direction: 'ltr' }}>
              {pkg.priceString.replace('.00', '').replace(/ /g, '')}
            </span>
            <span className="text-v3-brown">{TEXT.MONTHLY}</span>
          </p>
        )
      } else {
        return null
      }
    })
  }

  const store = subscriptionInfo?.store?.toLowerCase() || null
  const isPlatformWeb = Capacitor.getPlatform() === 'web'
  const isPlatformIOS = Capacitor.getPlatform() === 'ios'
  const isPlatformAndroid = Capacitor.getPlatform() === 'android'

  const isStripeStore = store === 'stripe'
  const isAndroidStore = store === 'play_store'
  const isIOSStore = store === 'app_store'

  const managementURL = subscriptionInfo?.managementUrl
  const isSubscribedMobile = !!managementURL
  const isCanceled = !!subscriptionInfo?.unsubscribeDetectedAt
  const isSubscribed = !!store

  const isAndroid = (isPlatformWeb || isPlatformIOS) && isAndroidStore // VIEWING FROM WEB OR IOS - ANDROID SUBSCRIPTION
  const isIOS = (isPlatformWeb || isPlatformAndroid) && isIOSStore // VIEWING FROM WEB OR ANDROID - IOS SUBSCRIPTION

  const ios = isPlatformIOS && isIOSStore
  const android = isPlatformAndroid && isAndroidStore

  useEffect(() => {
    purchase.getSubscriptionInfo().then((info) => {
      if (info == null) {
        history.replace('/subscription/package')
        return
      }

      setSubscriptionInfo(info)
      setIsLoaded(true)
    })
  }, [history, purchase])

  const handleProceed = async () => {
    if (isSubscribedMobile) {
      const win = window.open(managementURL, '_blank')
      win?.focus()
    }

    if (isStripeStore) {
      const instance = purchase as IPurchaseManagerPayment
      const response = await instance.cancelSubscription()
      if (!response) {
        toast.error('حدث خطأ حاول مرة أخرى لاحقا')
        setShowPopup(false)
        return
      }
      setIsCancelPending(true)
    }

    setShowPopup(false)
  }

  const resumeSubscription = async () => {
    const instance = purchase as IPurchaseManagerPayment
    const response = await instance.resumeSubscription()
    if (!response) {
      toast.error('حدث خطأ حاول مرة أخرى لاحقا')
      setShowResumePopup(false)
      return
    }

    setIsResumePending(true)
    setShowResumePopup(false)
  }

  const handleShowResumePopup = () => {
    if (isStripeStore) {
      setShowResumePopup(true)
    }

    if (ios || android) {
      const win = window.open(managementURL, '_blank')
      win?.focus()
    }
  }

  const render = () => {
    if (isCancelPending) {
      return (
        <p className="page_current_subscription_render_cancel_request text-base">
          {MESSAGE_CANCEL_REQUEST}
        </p>
      )
    }

    if (isResumePending) {
      return (
        <p className="page_current_subscription_render_resume_request text-base">
          {MESSAGE_RESUME_REQUEST}
        </p>
      )
    }

    if (isIOS) {
      return (
        <p className="page_current_subscription_render_ios_cancel_message text-base">
          {MESSAGE_CANCEL_IOS}
        </p>
      )
    }

    if (isAndroid) {
      return (
        <p className="page_current_subscription_render_android_cancel_message text-base">
          {MESSAGE_CANCEL_ANDROID}
        </p>
      )
    }

    if (isCanceled && (ios || android || isStripeStore)) {
      return (
        <span
          className="page_current_subscription_render_message_resume text-black underline xl:text-lg hover:text-[#EA553D] hover:cursor-pointer"
          onClick={handleShowResumePopup}
        >
          {MESSAGE_RESUME}
        </span>
      )
    }

    if (isSubscribed) {
      return (
        <span
          className="page_current_subscription_render_unsubscribe_message underline hover:text-[#EA553D] hover:cursor-pointer"
          onClick={() => {
            setShowPopup(true)
          }}
        >
          {MESSAGE_UNSUBSCRIBE}
        </span>
      )
    }
  }

  const expireDate = `${subscriptionInfo?.expiryDate.toLocaleDateString(
    'en-US'
  )}`

  return (
    <>
      <Helmet>
        <title className="page_current_subscription_page_title">
          عالم زيدو - {MESSAGE_SUBSCRIPTION}
        </title>
      </Helmet>
      {isMobileView ? (
        <AppBar
          fixed
          hideToggleButton
          showBackButton={true}
          showLogoutButton
          showParentBackButton
          showProfileButton
          title={MESSAGE_SUBSCRIPTION}
        />
      ) : null}

      <AppContent
        className="page_current_subscription_app_content bg-no-repeat bg-cover bg-center bg-v3 flex flex-col h-screen overflow-hidden"
        haveFooter={!isMobileView}
        haveHeader={!isMobileView}
        headerTitle={!isMobileView ? MESSAGE_SUBSCRIPTION : null}
      >
        {isMobileView ? (
          <ContentTitle
            noShadow
            notBold
            textColor="text-white"
            title={MESSAGE_SUBSCRIPTION}
          />
        ) : null}

        {!isMobileView ? <BreadCrumb title={MESSAGE_SUBSCRIPTION} /> : null}

        <div className="page_current_subscription_container mx-auto container flex justify-center mt-6 lg:mt-14">
          <div className="page_current_subscription_wrapper flex flex-col w-full lg:w-2/3 bg-white rounded-[21px]">
            <div className="page_current_subscription_svg_crown_wrapper flex flex-row gap-x-2 px-5 py-3 text-text text-3xl">
              <SVG
                className="page_current_subscription_svg_crown"
                src={`${Config.publicURL}/images/v3/crown.svg`}
              />
              <p className="page_current_subscription_text_title">
                {TEXT.TITLE}
              </p>
            </div>

            <HorizontalSeparator />

            {!isLoaded ? <Loading /> : null}
            {isLoaded ? (
              <>
                {subscriptionInfo === null ? (
                  <p className="page_current_subscription_not_currently_subscribed_message text-base mt-2 md:text-base lg:text-lg  md:mt-4">
                    {MESSAGE_YOU_ARE_NOT_CURRENTLY_SUBSCRIBED}
                  </p>
                ) : (
                  <div className="page_current_subscription_get_package_text_wrapper flex flex-col py-6 px-5 ">
                    {packages ? getPackageText() : null}

                    <p className="page_current_subscription_message_expiry_date_wrapper text-3xl">
                      <span className="page_current_subscription_message_canceled">{`${
                        isCanceled ? MESSAGE_EXPIRY_DATE : MESSAGE_RENEW_DATE
                      } `}</span>
                      <span className="page_current_subscription_expiry_date text-v3-brown">
                        {expireDate}
                      </span>
                    </p>
                  </div>
                )}

                <div className="page_current_subscription_subscription_info_container flex flex-col py-6 px-5 ">
                  <p className="page_current_subscription_subscription_info_wrapper text-3xl">
                    <span className="page_current_subscription_message">
                      {subscriptionInfo?.accessType === 'allAccess'
                        ? MESSAGE_MULTI_AGE_SUBS
                        : MESSAGE_ONE_AGE_SUBS}
                    </span>
                  </p>
                </div>

                {isAndroidStore || isIOSStore || isStripeStore ? (
                  <div className="flex page_current_subscription_render_wrapper flex-row border-t-[1px] w-full justify-center lg:justify-start border-gray-400 py-3 px-5 text-2xl text-v3-gray mb-1">
                    {render()}
                  </div>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </AppContent>

      <Popup
        buttonOnClick={handleProceed}
        buttonText={CONFIRM}
        message={
          isSubscribedMobile ? UNSUBSCRIBE_MESSAGE : UNSUBSCRIBE_MESSAGE_WEB
        }
        onClose={() => {
          setShowPopup(false)
        }}
        title={MESSAGE_UNSUBSCRIBE}
        visible={showPopup}
      />

      <Popup
        buttonOnClick={resumeSubscription}
        buttonText={CONFIRM}
        message={RESUME_MESSAGE_WEB}
        onClose={() => {
          setShowResumePopup(false)
        }}
        title={MESSAGE_RESUME}
        visible={showResumePopup}
      />
    </>
  )
}
export default CurrentSubscription
