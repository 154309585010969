import Config from '@config/index'
import { useAnonymous } from '@contexts/AnonymousContext/AnonymousContext'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import {
  initialize,
  signInWithApple,
  signInWithFacebook,
  signInWithGoogle,
} from '@utils/firebase-auth'
import HelperJWT from '@utils/helpers/helper.jwt'
import HelperProfile from '@utils/helpers/helper.profile'
import { FC, MouseEvent, memo, useState } from 'react'
import { Link } from 'react-router-dom'
import { loginAction } from './LoginMethods'
import { TYPOGRAPHY } from './constants'
import { GtagEvents } from '@utils/gtag-events'

const apple = `${Config.publicURL}/images/v3/apple.svg`
const envelope = `${Config.publicURL}/images/v3/envelope.svg`
const google = `${Config.publicURL}/images/v3/google.svg`
const lock = `${Config.publicURL}/images/v3/lock.svg`

export interface LoginProps {
  provider: 'google' | 'facebook' | 'twitter' | 'apple'
}

const LoginWithSocials = ({ provider }: LoginProps) => {
  const { login } = useProfile()
  const { logout } = useAnonymous()
  const resetProfile = () => {
    const redirectToUrl = HelperProfile.getStoredRedirectLocation()
    HelperProfile.setStoredProfile(null)
    HelperProfile.setStoredKid(null)
    HelperProfile.removeStoredKid()
    HelperProfile.removeStoredProfile()
    HelperJWT.removeJwtToken()
    if (
      redirectToUrl &&
      typeof redirectToUrl === 'string' &&
      redirectToUrl.trim().length > 0
    ) {
      HelperProfile.setStoredRedirectLocation(redirectToUrl)
    }
    logout(false)
  }
  // let sizesString =
  //   'w-[40px] h-[40px] lg:w-[60px] lg:h-[60px] 2xl:h-[100px] 2xl:w-[100px] mx-2'
  // if (provider === 'apple') {
  //   sizesString =
  //     'w-[40px] h-[40px] lg:w-[60px] lg:h-[60px] 2xl:h-[100px] 2xl:w-[100px] mx-2'
  // }
  // const classString = `appearance-none ${sizesString} ${className}`

  const doSocialLogin = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    await initialize()
    resetProfile()
    if (provider === 'facebook') {
      await loginAction('login_using_facebook', signInWithFacebook, login)
    }
    if (provider === 'google') {
      GtagEvents.customEvent({ event: 'Google_regstart' })
      await loginAction('login_using_google', signInWithGoogle, login)
    }
    if (provider === 'apple') {
      GtagEvents.customEvent({ event: 'Apple_regstart' })
      await loginAction('login_using_apple', signInWithApple, login)
    }
  }

  return (
    <button
      className="login_form_component-social-login-button flex gap-x-4 text-white text-xl font-normal px-5 items-center justify-end bg-v3-blue-400 rounded-[22px] w-[138px] h-[44px] lg:w-[138px] lg:h-[44px] 2xl:w-[138px] 2xl:h-[44px] shadow-[4px_4px_0px_0px_#0D1F63]"
      onClick={doSocialLogin}
      type="button"
    >
      <span className="login_form_component-provider">
        {provider === 'google'
          ? 'Google'
          : provider === 'apple'
          ? 'Apple'
          : provider === 'facebook'
          ? 'Facebook'
          : ''}
      </span>
      {provider === 'google' ? (
        <img
          alt="Google Icon"
          className="login_form_component-google-icon"
          src={google}
        />
      ) : provider === 'apple' ? (
        <img
          alt="Apple Icon"
          className="login_form_component-apple-icon"
          src={apple}
        />
      ) : (
        ''
      )}
    </button>
  )
}
const LoginForm: FC<{
  username?: string
  onChangeUsername: React.ChangeEventHandler<HTMLInputElement>
  password: string
  onChangePassword: React.ChangeEventHandler<HTMLInputElement>
  onSubmit: React.FormEventHandler<HTMLFormElement>
}> = (props) => {
  const [revealPasswordOnHoldInput, setRevealPasswordOnHoldInput] =
    useState('password')

  const revealPasswordOnHoldButton = () => {
    setRevealPasswordOnHoldInput(
      revealPasswordOnHoldInput === 'text' ? 'password' : 'text'
    )
  }

  return (
    <form
      className="login_form_component-form w-full flex flex-col gap-y-2.5 lg:gap-y-5"
      onSubmit={props.onSubmit}
    >
      <div className="login_form_component-social-icon-wrapper flex gap-2.5 content-center justify-center">
        <LoginWithSocials provider="apple" />
        <LoginWithSocials provider="google" />
      </div>

      <span className="login_form_component-or-typography-wrapper w-[97%] self-center flex text-center text-2xl text-white">
        <span className="login_form_component-border-one w-[45%] border-b-[1px] border-[#6C81D6] leading-[0.1rem] my-3" />
        <h2 className="login_form_component-or-typography w-[10%] text-center text-2xl text-white">
          {TYPOGRAPHY.OR}
        </h2>
        <span className="login_form_component-border-two w-[45%] border-b-[1px] border-[#6C81D6] leading-[0.1rem] my-3" />
      </span>

      <div className="login_form_component-email-field-wrapper flex gap-x-4 items-center">
        <img
          alt="Envelope"
          className="login_form_component-envelope-svg"
          src={envelope}
        />
        <input
          autoComplete="off"
          className="login_form_component-email-field focus:ring-0 focus:border-v3-gray-200 placeholder:text-v3-gray-800 focus:border-4 focus:text-v3-gray-800 portrait:h-4 h-[35px] xl:h-[50px] drop-shadow-[0px_4px_0px_#81A2B9] p-4 pr-6 rounded-full bg-v3-gray-400 border-4 border-v3-gray-200 placeholder:text-xl text-xl xl:placeholder:text-2xl xl:text-2xl text-v3-gray-800 w-full"
          enterKeyHint="next"
          id="email-input"
          name="username"
          onChange={props.onChangeUsername}
          placeholder={TYPOGRAPHY.EMAIL_PLACEHOLDER}
          required
          type="text"
          value={props.username}
        />
      </div>

      <div className="login_form_component-reveal-password-button-container flex relative flex-col">
        <div className="login_form_component-reveal-password-button-wrapper flex gap-x-4 items-center">
          <img
            alt="Lock"
            className="login_form_component-lock-icon"
            src={lock}
          />

          <div className="login_form_component-reveal-password-button-main-wrapper flex items-center absolute left-5 z-10 rounded-full">
            <button
              className="login_form_component-reveal-password-button appearance-none passwordReveal w-6 h-4"
              onClick={revealPasswordOnHoldButton}
              tabIndex={9}
              type="button"
            />
          </div>
          <input
            autoComplete="off"
            className="login_form_component-form-password-field focus:ring-0 focus:border-v3-gray-200 placeholder:text-v3-gray-800 focus:border-4 focus:text-v3-gray-800 portrait:h-5 h-[35px] xl:h-[50px] drop-shadow-[0px_4px_0px_#81A2B9] p-4 pr-6 rounded-full bg-v3-gray-400 border-4 border-v3-gray-200 placeholder:text-xl text-xl xl:placeholder:text-2xl xl:text-2xl text-v3-gray-800 w-full"
            enterKeyHint="enter"
            id="password-input"
            name="password"
            onChange={props.onChangePassword}
            placeholder={TYPOGRAPHY.PASSWORD_PLACEHOLDER}
            required
            type={revealPasswordOnHoldInput}
            value={props.password}
          />
        </div>
      </div>

      <input
        className="login_form_component-form-login-submit cursor-pointer bg-v3-green mx-auto w-auto max-w-max rounded-3xl text-xl  text-center text-v3-purple-200 mt-3 px-16 py-[11px] shadow-[3px_3px_0px_0px_#5E7426]"
        id=""
        type="submit"
        value={TYPOGRAPHY.LOGIN}
      />

      <div className="login_form_component-form-forgot-password-link-wrapper self-center mb-3">
        <Link
          className="login_form_component-form-forgot-password-link"
          to="/forgot-password"
        >
          <h3 className="login_form_component-form-forgot-password-typography text-center text-v3-green text-lg xl:text-2xl">
            {TYPOGRAPHY.FORGOT_PASSWORD}
          </h3>
        </Link>
      </div>
    </form>
  )
}

export default memo(LoginForm)
