import { AppBar } from '@components/Layout/AppBar'
import { AppContent } from '@components/Layout/AppContent'
import React, { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useParams } from 'react-router-dom'

import { useSeries } from '@hooks/data/fetchers/use-series'
import { ISeries } from '@src/types/api.interface'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import Helper from '@utils/Helpers'

import Thumbnail from '@components/Thumbnail'
import { logEvent } from '@utils/firebase-analytics'
import { TAALAM_TEXT_GREEN } from '@utils/constants'
import { useAnonymous } from '@contexts/AnonymousContext/AnonymousContext'

const SeriesHomeAnonymous: FC = () => {
  const { logout } = useAnonymous()
  const { series, isLoadingSeries, error } = useSeries()
  const [loaded, setLoaded] = useState(false)
  if (error?.hasOwnProperty('status') && error?.status === 401) {
    logout()
  }
  const [newSeries, setNewSeries] = useState<ISeries[]>([])

  useEffect(() => {
    logEvent('open_curriculum')
  }, [])

  useEffect(() => {
    if (Array.isArray(series) && series.length > 0) {
      const data = series.sort((a, b) => a.order - b.order)
      setNewSeries(data)
    }
    setLoaded(true)
  }, [series, newSeries])

  if (!loaded) {
    return <></>
  }
  return (
    <>
      <Helmet>
        <title>عالم زيدو - أطفال</title>
      </Helmet>
      {isLoadingSeries ? <AppProgressBar /> : null}
      <AppBar
        fixed
        hideToggleButton
        showBackButton={true}
        showLogoutButton
        showProfileButton
        title=""
      />
      <AppContent
        bgDecoration
        className="pages_anonymous_serieshome_appcontent"
        style={{
          backgroundColor: '#D9F6FF',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="absolute left-1/2 transform -translate-x-1/2 mt-2">
          <img
            alt="TAALAM"
            className="max-w-20 max-h-12 pages_anonymous_serieshome_talam_img"
            src={TAALAM_TEXT_GREEN}
          />
        </div>
        {isLoadingSeries ? null : (
          <div className="w-full flex flex-row pages_anonymous_serieshome_series_div_container flex-wrap mt-20 gap-y-3 items-start justify-center lg:justify-center mb-4">
            <SeriesList series={newSeries} />
          </div>
        )}
      </AppContent>
    </>
  )
}

export default SeriesHomeAnonymous

const SeriesList: FC<{
  series: Array<ISeries & { isCompleted?: boolean }>
}> = ({ series }) => {
  return (
    <div className="pages_anonymous_serieshome_serieslist_container">
      {series.map((oneSeries) => {
        return <SeriesThumbnail key={oneSeries.slug} series={oneSeries} />
      })}
    </div>
  )
}

const SeriesThumbnail: FC<{
  series: ISeries
}> = ({ series }) => {
  const { level } = useParams<{ level: string }>()

  const url = `/series-anonymous/${level}/slug/${series.slug}`
  const image = Helper.MediaURL(series.thumbnail)

  return (
    <Link
      className="mx-2 pages_anonymous_serieshome_thumb_link"
      to={{
        pathname: url,
        state: { seriesData: series },
      }}
    >
      <div className="grid relative pages_anonymous_serieshome_thumb_link_child  place-items-center h-[200px] w-[200px] md:h-[240px] md:w-[240px] lg:h-[288px] lg:w-[288px] bg-[#DCDCDC] border-4 rounded-[3rem] overflow-hidden">
        <Thumbnail src={image} />
        <span className="bg-white pages_anonymous_serieshome_thumb_title_text w-full text-text text-center h-full flex items-center justify-center text-xl lg:text-2xl truncate px-2">
          {series.title}
        </span>
      </div>
    </Link>
  )
}
