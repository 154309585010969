import { FC } from 'react'
import Config from '@config/index'
import { FullScreenButton } from '../Layout'
const medal = `${Config.publicURL}/images/competition/medal.png`

export const ChallengeContainer: FC<{
  title: string | null
  score: number | null
  gameNodeId: string | null
  children: React.ReactNode | string
}> = (props) => {
  return (
    <div className="relative min-h-screen h-full component_gamechallenge_container">
      <div className="absolute w-full top-0 left-0 flex justify-between mt-2 pl-2 pointer-events-none">
        <div className="flex-1 pl-2 component_gamechallenge_title_container">
          <ChallengeTitle title={props.title} />
        </div>
        <div className="sm:absolute sm:left-1/2 sm:-translate-x-1/2 component_gamechallenge_score_container">
          <ChallengeScore score={props.score} />
        </div>
        {props.gameNodeId ? (
          <div className="max-w-xl pointer-events-auto h-[64px] w-[64px] mr-2 component_gamechallenge_fullscreen_control_container">
            <FullScreenButton nodeId={props.gameNodeId} />
          </div>
        ) : null}
      </div>

      <div className="component_gamechallenge_props_child">
        {props.children}
      </div>
    </div>
  )
}

const ChallengeScore: FC<{ score: null | number }> = (props) => {
  if (props.score === null) return null
  return (
    <span className="relative inline-block w-16 h-16 component_gamechallenge_challenge_score_span_container">
      <img
        alt=""
        className="absolute w-full h-full component_gamechallenge_challenge_score_img"
        src={medal}
      />
      <span className="text-orange-900 component_gamechallenge_score_span_text font-bold text-2xl flex justify-center items-center absolute w-full h-full">
        {props.score}
      </span>
    </span>
  )
}

const ChallengeTitle: FC<{ title: string | null }> = (props) => {
  if (!props.title) return null
  return (
    <div className="sm:text-lg md:text-xl component_gamechallenge_challenge_title_div text-white py-2 font-bold shadow-md shadow-gray-400 pr-2 pl-6 rounded-l-full inline-flex items-center justify-center bg-[#1575a2]">
      <span className="component_gamechallenge_challenge_title_span_text">
        {props.title || ''}
      </span>
    </div>
  )
}
