import { FirebaseApp, initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getMessaging } from 'firebase/messaging'

export const firebaseConfig = {
  apiKey: 'AIzaSyDEgXsv63U5gaQN8ZwYdRpBGl70UuBaN14',
  authDomain: 'kids-zidoworld.firebaseapp.com',
  projectId: 'kids-zidoworld',
  storageBucket: 'kids-zidoworld.appspot.com',
  messagingSenderId: '444106992373',
  appId: '1:444106992373:web:b1a82815aabc2a4f1ff3bc',
  measurementId: 'G-7H9W420FJZ',
}

// Initialize Firebase
export const firebaseApp = () => initializeApp(firebaseConfig)
export const analytics = (firebaseInit: FirebaseApp) =>
  getAnalytics(firebaseInit)
export const messaging = (firebaseInit: FirebaseApp) =>
  getMessaging(firebaseInit)
