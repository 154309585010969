// todo: put icons in shared folder

import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { Profile } from '@src/types/api.interface'
import { useHistory } from 'react-router-dom'
import { UserAvatar } from './UserAvatar'

// append currentColor with props
const EditBtnIcon = ({ onClick }: { onClick?: () => unknown }) => {
  return (
    <div
      className="w-70 h-70 text-[#4758C5] mr-[20px] editBtn cursor-pointer component_layout_kidcard_editbtn_container"
      onClick={onClick}
    >
      <svg
        className="component_layout_kidcard_editbtn_container_svg"
        fill="none"
        height="27"
        viewBox="0 0 27 27"
        width="27"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M5.19454 6.77665C4.70116 6.77665 4.22799 6.97264 3.87912 7.32151C3.53025 7.67038 3.33426 8.14355 3.33426 8.63693V21.8362C3.33426 22.8648 4.16591 23.6965 5.19454 23.6965H18.3916C19.4202 23.6965 20.2519 22.8648 20.2519 21.8362V18.0434C20.2519 17.6371 20.4133 17.2474 20.7006 16.9601C20.9879 16.6728 21.3776 16.5114 21.7839 16.5114C22.1902 16.5114 22.5798 16.6728 22.8672 16.9601C23.1545 17.2474 23.3159 17.6371 23.3159 18.0434V21.8362C23.3159 23.1422 22.7971 24.3947 21.8736 25.3182C20.9501 26.2416 19.6976 26.7604 18.3916 26.7604H5.19454C3.88854 26.7604 2.63603 26.2416 1.71255 25.3182C0.78907 24.3947 0.270264 23.1422 0.270264 21.8362V8.63912C0.270264 7.33312 0.78907 6.08061 1.71255 5.15713C2.63603 4.23365 3.88854 3.71484 5.19454 3.71484H8.98732C9.39363 3.71484 9.7833 3.87625 10.0706 4.16356C10.3579 4.45086 10.5193 4.84053 10.5193 5.24684C10.5193 5.65315 10.3579 6.04282 10.0706 6.33013C9.7833 6.61743 9.39363 6.77884 8.98732 6.77884H5.19235L5.19454 6.77665Z"
          fill="currentColor"
          fillRule="evenodd"
        />
        <path
          d="M24.1399 1.29559L25.6347 2.79257C26.4267 3.58504 26.8716 4.65958 26.8716 5.77996C26.8716 6.90034 26.4267 7.97488 25.6347 8.76735L25.2233 9.18318L17.7493 1.70704L18.1607 1.29559C18.5532 0.903106 19.0191 0.591769 19.5319 0.379357C20.0446 0.166945 20.5942 0.0576172 21.1492 0.0576172C21.7043 0.0576172 22.2538 0.166945 22.7666 0.379357C23.2794 0.591769 23.7475 0.903106 24.1399 1.29559ZM23.0566 11.3477L15.5848 3.87591L8.78493 10.6758C8.08459 11.3761 7.65344 12.2953 7.56371 13.2802L7.18509 17.4516C7.15722 17.7601 7.19747 18.0709 7.30299 18.3621C7.40851 18.6533 7.57673 18.9177 7.79574 19.1367C8.01475 19.3558 8.2792 19.524 8.5704 19.6295C8.8616 19.735 9.17242 19.7753 9.4809 19.7474L13.6501 19.3688C14.635 19.279 15.5564 18.8479 16.2545 18.1475L23.0566 11.3477Z"
          fill="currentColor"
        />
      </svg>
    </div>
  )
}
// append currentColor with props
const DeleteBtnIcon = ({ onClick }: { onClick?: () => unknown }) => {
  return (
    <div
      className="w-70 h-70 text-[#4758C5] mr-[20px] deleteBtn cursor-pointer component_layout_kidcard_delete_container"
      onClick={onClick}
    >
      <svg
        className="component_layout_kidcard_delete_container_svg"
        fill="none"
        height="26"
        viewBox="0 0 23 26"
        width="23"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M21.6833 4.24268C22.1685 4.24268 22.5704 4.64453 22.5704 5.15586V5.62863C22.5718 5.86674 22.4796 6.09586 22.3137 6.26666C22.1477 6.43746 21.9214 6.53627 21.6833 6.54181H1.06458C0.826316 6.53659 0.599647 6.43792 0.433469 6.26709C0.267291 6.09626 0.174921 5.86695 0.176284 5.62863V5.15586C0.176284 4.64453 0.579378 4.24268 1.06458 4.24268H4.69243C5.42895 4.24268 6.06967 3.71891 6.23514 2.9799L6.42549 2.13141C6.72159 0.975628 7.692 0.208008 8.80424 0.208008H13.9424C15.0422 0.208008 16.0251 0.975628 16.3088 2.07045L16.5115 2.97866C16.5864 3.33373 16.7802 3.6526 17.061 3.88247C17.3418 4.11234 17.6926 4.23942 18.0555 4.24268H21.6833ZM19.8408 21.5247C20.219 17.9952 20.8821 9.6098 20.8821 9.5252C20.8936 9.40036 20.8797 9.27449 20.8412 9.15517C20.8027 9.03586 20.7405 8.92558 20.6582 8.83099C20.575 8.74071 20.4743 8.66833 20.3622 8.61828C20.2501 8.56822 20.129 8.54154 20.0063 8.53986H2.75161C2.50278 8.53986 2.26516 8.6481 2.09969 8.83099C1.93428 9.02323 1.84959 9.27197 1.86331 9.5252L1.92925 10.3301C2.10591 12.5235 2.59734 18.6334 2.91583 21.5247C3.13977 23.6546 4.53816 24.9933 6.56234 25.0418C8.12495 25.0779 9.73484 25.0904 11.3796 25.0904C12.9297 25.0904 14.5023 25.0779 16.1134 25.0418C18.2085 25.0058 19.6032 23.6907 19.8408 21.5247Z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </svg>
    </div>
  )
}

const UserInfo = ({
  name = '',
  level = '1',
}: {
  name: string
  level?: number | string
}) => {
  return (
    <div className="mr-[15px] component_layout_kidcard_userinfo_container">
      <div className="w-24 h-24  flex content-center component_layout_kidcard_userinfo_container_span">
        <div className="block pt-4 component_layout_kidcard_userinfo_container_span_child">
          <span
            className="kids-name-block  font-asmaa text-[#4758C5] float-right text-[35px] inline-block w-[150px] overflow-hidden whitespace-nowrap text-ellipsis text-right component_layout_kidcard_userinfo_container_span_name"
            style={{ lineHeight: '35px' }}
          >
            {name}
          </span>
          <span
            className="inline-block whitespace-pre bg-[transparent] w-[100px] float-right text-[#66C5BA] text-[17px] text-right overflow-hidden text-ellipsis component_layout_kidcard_userinfo_container_span_level"
            style={{ lineHeight: '35px' }}
          >
            المستوى: {' ' + level + ' ' ?? ' 1 '}
          </span>
        </div>
      </div>
    </div>
  )
}

export const HorizontalSeparator = () => {
  return (
    <div
      className="kids-line-separator w-full block mx-auto h-[4px] opacity-[0.3] bg-repeat-x component_layout_kidcard_userinfo_container_hr"
      style={{
        backgroundImage:
          'linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%)',
        backgroundPosition: 'bottom',
        backgroundSize: '16px 1px',
      }}
    />
  )
}

export const VerticalSeparator = () => {
  return (
    <div
      className="kids-line-vertical-separator w-[3px] h-[85px] opacity-[0.3] bg-repeat-y component_layout_kidcard_userinfo_container_vr"
      style={{
        backgroundImage: 'linear-gradient(black 33%, rgba(255,255,255,0) 0%)',
        backgroundPosition: 'right',
        backgroundSize: '1px 16px',
      }}
    />
  )
}

const IconActionButton = ({
  image = '/images/v3/closedBookBtn.svg',
  alt = 'img',
  onClick,
  subtext = 'دليل المربي',
}: {
  image: string
  onClick?: () => unknown
  subtext: string
  alt?: string
}) => {
  return (
    <div
      className="block w-1/3 h-[85px] cursor-pointer component_layout_kidcard_userinfo_container_action_icon"
      onClick={onClick}
    >
      <img
        alt={alt}
        className="mx-auto mb-2 w-[55px] h-[55px] xl:w-[80px] xl:h-[80px] component_layout_kidcard_userinfo_container_action_icon_img"
        height={55}
        src={image ?? '/images/v3/closedBookBtn.svg'}
        width={55}
      />
      <span
        className="actionText font-kidzhood text-[#4758C5]  text-center block pt-2 text-[15px] xl:text-[20px] component_layout_kidcard_userinfo_container_action_icon_span_subtext"
        style={{ lineHeight: '10px' }}
      >
        {subtext ?? 'دليل المربي'}
      </span>
    </div>
  )
}

const KidCard = ({
  profile,
  avatarUrl,
  showDeletePopup,
  openBook,
}: {
  avatarUrl?: string
  profile?: Profile
  showDeletePopup: () => void
  openBook: () => Promise<void>
}) => {
  const { nickname: name, level } = profile
  const { setActiveKid } = useProfile()

  const history = useHistory()

  const action = (link: string) => {
    if (profile) {
      setActiveKid(profile)
      history.push(link)
    }
  }

  return (
    <div
      className="kids-card w-[380px] component_layout_kidcard_kidcard_container mb-8 h-[250px] xl:w-[550px] xl:h-[280px] bg-[#EFF4F8] block px-[20px] rounded-[40px]"
      style={{
        boxShadow: '5px 5px 0px 0px rgba(0, 0, 0, 0.19)',
      }}
    >
      <div className="kids-name-row pt-2 w-[340px] h-[102px] xl:w-[510px] flex items-center flex-row-reverse justify-between">
        <div className="block w-[105px] controlSection">
          <div className="flex">
            <EditBtnIcon onClick={() => action(`edit-kid/${profile._id}`)} />
            <DeleteBtnIcon onClick={showDeletePopup} />
          </div>
        </div>
        <div className="block w-2/3 nameAvatarSection">
          <div>
            <div className="w-[100px] h-70  flex items-center">
              <UserAvatar image={avatarUrl} />
              <UserInfo level={level} name={name} />
            </div>
          </div>
        </div>
      </div>
      <HorizontalSeparator />
      <div className="kids-btn-section flex items-center h-[144px] flex-row w-[340px] xl:w-[510px]">
        <div className="block w-[340px] xl:w-[510px] h-[85px] mx-auto">
          <div className="flex w-full justify-between flex-row-reverse">
            <IconActionButton
              image="/images/v3/closedBookBtn.svg"
              onClick={openBook}
              subtext="دليل المربي"
            />
            <VerticalSeparator />
            <IconActionButton
              image="/images/v3/blueReportBtn.svg"
              onClick={() => action(`kids-report/${profile._id}`)}
              subtext="تقرير شامل"
            />
            <VerticalSeparator />
            <IconActionButton
              image="/images/v3/magnify.svg"
              onClick={() => action('/kids-home')}
              subtext="تصفح المحتوى"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default KidCard
