import { RadioButtonProps } from '../types'

import React, { useRef } from 'react'

const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  value,
  name,
  checked,
  onChange,
  className,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const handleDivClick = () => {
    inputRef.current?.click()
  }

  return (
    <div
      className={`${className} text-2xl m-1 flex items-center border border-white p-1 rounded-full ${
        checked ? 'bg-white' : ''
      }`}
      onClick={handleDivClick}
    >
      <input
        aria-label="radio"
        checked={checked}
        className={`${className}_input_tag mx-2 outline-none appearance-none h-6 w-6 border border-white rounded-full bg-transparent checked:bg-white checked:border-[6px] checked:border-[#2A40A0] focus:ring-0`}
        name={name}
        onChange={onChange}
        ref={inputRef}
        type="radio"
        value={value}
      />
      <span
        className={`mx-2 ${className}_label_span  ${
          checked ? 'text-[#2A40A0]' : ' text-white '
        }`}
      >
        {label}
      </span>
    </div>
  )
}

export default RadioButton
