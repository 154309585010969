import useNotificationWrapper from './use-notification-wrapper'
import { IWrapperProp } from './types'

const NotificationWrapper = ({
  children,
}: {
  children: (wrapProp: IWrapperProp) => JSX.Element
}) => {
  const wrapProp = useNotificationWrapper()

  return children(wrapProp)
}

export default NotificationWrapper
