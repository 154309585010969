export const GrayBoxContainer = ({
  children,
  dimensionClass,
  classNames,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any
  dimensionClass?: string
  classNames?: string
}): React.ReactElement => {
  return (
    <div
      className={`bg-[#FFFFFF] bg-opacity-50 p-1 rounded-[40px] gray_box_container ${
        dimensionClass ??
        'max-w-[640px] w-[90%] landscape:max-w-[420px] sm:landscape:h-[170px] '
      } ${classNames}`}
    >
      <div className="bg-[#EBF6F8] p-1 rounded-[40px] border-[5px] border-white h-full w-full gray_box_container-child-wrpr">
        {children}
      </div>
    </div>
  )
}
