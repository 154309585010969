import useSwr from 'swr'
import { IPublicationListItem } from '@src/types/api.interface'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { FetchError } from '@utils/FetchError'

export function usePublications(props?: { onlyUser?: boolean }): {
  publications?: IPublicationListItem[]
  error?: FetchError
  isLoadingPublications: boolean
} {
  const { kidProfile: profile } = useProfile()

  const url = profile?.level
    ? props?.onlyUser
      ? `/level/${profile.level}/publications/me`
      : `/level/${profile.level}/publications`
    : null

  const { data, error } = useSwr<IPublicationListItem[], FetchError>(url)

  return {
    publications: data,
    error,
    isLoadingPublications: !error && !data,
  }
}
