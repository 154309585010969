import { useMemo } from 'react'
import useSWR from 'swr'
import { ITestMeeting } from '@src/types/api.interface'
import { FetchError } from '@utils/FetchError'

export function useTestMeetings(): {
  testMeetings?: ITestMeeting[]
  error?: FetchError
  isLoadingTestMeetings: boolean
} {
  const url = `/maqraa/test-meetings`

  const { data, error } = useSWR<ITestMeeting[], FetchError>(url)

  return useMemo(
    () => ({
      testMeetings: data,
      error,
      isLoadingTestMeetings: !data && !error,
    }),
    [data, error]
  )
}
