import Config from '@config/index'

export const CHOOSE_PACKAGE_TEXT = {
  WEBSITE_TITLE: 'عالم زيدو - إعدادات الحساب',
  TITLE: 'إعدادات الحساب',
}

export const SETTINGS = [
  {
    icon: `${Config.publicURL}/images/v3/purpleLockBtn.svg`,
    title: 'كلمة المرور',
    subtitle: '******************',
    buttonText: 'تعديل',
  },
  {
    icon: `${Config.publicURL}/images/v3/redUserBtn.svg`,
    title: 'حذف الحساب نهائياََ',
    subtitle: 'لا يمكن التراجع في هذه الخطوة',
    buttonText: 'حذف الحساب',
  },
]
