/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-console */
import ScriptImporter from '@components/ScriptImporter'
import { CleverTap } from '@ionic-native/clevertap'
import { useEffect, useRef } from 'react'
import * as customCleverTapEvents from './CleverTapEvents'

export const CleverTapHandler = ({
  children,
}: {
  children?: React.ReactElement
}) => {
  const handleDeepLink = () => {}
  const handleOnPushNotification = () => {}
  const onNotificationDismissed = () => {}
  const onInit = () => {}
  const cleverTapLoaded = useRef(false)
  useEffect(() => {
    if (!cleverTapLoaded.current) {
      try {
        ScriptImporter('/js/clevertap-web.js')
      } catch (e) {
        //
      }
      cleverTapLoaded.current = true
    }
    const debugLevel = {
      off: 0,
      info: 1,
      debug: 2,
    }
    CleverTap.setDebugLevel(debugLevel.debug)
    CleverTap.setOffline(false)
    CleverTap.notifyDeviceReady()
    // Create notification channel for Android O and above
    const importance = 1 //  5 >= high <= 1
    const showNotificationBadge = true
    CleverTap.createNotificationChannel(
      'channelId-zido',
      'channelName-zido',
      'channelDescription-zido',
      importance,
      showNotificationBadge
    )

    // initialize the App Inbox
    CleverTap.initializeInbox()
    CleverTap.registerPush() // for IOS

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    document.addEventListener('onCleverTapProfileDidInitialize', (e: any) => {
      console.log('onCleverTapProfileDidInitialize')
      console.log(e.CleverTapID)
      onInit()
    })

    // deep link handling
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    document.addEventListener('onDeepLink', (e: any) => {
      console.log('onDeepLink')
      console.log(e?.deeplink)
      handleDeepLink()
    })
    // push notification payload handling
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    document.addEventListener('onPushNotification', (e: any) => {
      console.log('onPushNotification')
      console.log(JSON.stringify(e.notification))
      handleOnPushNotification()
    })

    // on notification dismissed
    document.addEventListener(
      'onCleverTapInAppNotificationDismissed',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (e: any) => {
        console.log('onCleverTapInAppNotificationDismissed')
        console.log(JSON.stringify(e.extras))
        console.log(JSON.stringify(e.actionExtras))
        onNotificationDismissed()
      }
    )
    /**
     * deviceready
     * onCleverTapProfileSync
     * onCleverTapInboxDidInitialize
     * onCleverTapInboxMessagesDidUpdate
     * onCleverTapInboxButtonClick
     * onCleverTapInboxItemClick
     * onCleverTapInAppButtonClick
     * onCleverTapFeatureFlagsDidUpdate
     * onCleverTapProductConfigDidInitialize
     * onCleverTapProductConfigDidFetch
     * onCleverTapProductConfigDidActivate
     * onCleverTapExperimentsUpdated
     * onCleverTapDisplayUnitsLoaded
     */
  }, [])

  return <div className="clevertap_main_props_child_container">{children}</div>
}

export const cleverTapEvents = customCleverTapEvents
