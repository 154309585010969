import { IOnboarding } from '@src/types/api.interface'
import HelperAPI from '@utils/helpers/helper.api'
import { useCallback, useEffect, useState } from 'react'

const url = '/onboarding?status=true'

type onFinishType = () => void

export function useOnBoarding(
  onFinish?: onFinishType
): [IOnboarding[], boolean] {
  const [onboardingsData, setOnboardingsData] = useState<IOnboarding[]>()
  const [isLoading, setIsLoading] = useState(false)

  const getOnboardingsData = useCallback(() => {
    HelperAPI.ApiRequest<IOnboarding[]>(url)
      .then(function (onboardings) {
        if (typeof onboardings !== 'object' || 'error' in onboardings) {
          return
        }
        if (onboardings.length === 0) onFinish?.()
        setOnboardingsData(onboardings.sort((a, b) => a.orderNo - b.orderNo))
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [onFinish])

  useEffect(() => {
    if (!onboardingsData) {
      setIsLoading(true)
      getOnboardingsData()
    }
  }, [onboardingsData, getOnboardingsData])

  return [onboardingsData, isLoading]
}
