import { ContentTitle } from '@components/ContentTitle'
import { AppBar } from '@components/Layout/AppBar'

import { AppContent } from '@components/Layout/AppContent'
import {
  ILocalNotification,
  LocalNotificationType,
} from '@src/types/api.interface'
import { FC, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import {
  LocalNotifications,
  PendingResult,
} from '@capacitor/local-notifications'
import { useLocalNotification } from '@hooks/data/fetchers/use-local-notification'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import HelperLocalNotification from '@utils/helpers/helper.localnotification'

const NotificationControl: FC = () => {
  const { localNotification, isLoadingLocalNotification } =
    useLocalNotification()

  return (
    <>
      <Helmet>
        <title>عالم زيدو - المنبة</title>
      </Helmet>

      {isLoadingLocalNotification ? <AppProgressBar /> : null}

      <AppBar
        backButtonLocation="/settings"
        fixed
        hideToggleButton
        showBackButton={true}
        showLogoutButton
        title=""
      />
      <AppContent className="bg-no-repeat bg-cover bg-center bg-blue pages_settings_notificationcontrol_container">
        <ContentTitle
          className="pages_settings_notificationcontrol_contenttitle"
          noShadow
          textColor="text-black"
          title="المنبة"
        />
        <div className="flex justify-center items-center h-[80vh]">
          <div className="w-full login-bg mx-auto max-w-[2000px] h-[60vh] sm:h-[70vh] lg:h-[50vh] max-h-[320px]  overflow-hidden flex flex-col items-center justify-center gap-5 pages_settings_notificationcontrol_child">
            {!isLoadingLocalNotification ? (
              <>
                <div className="flex flex-row justify-between w-[80%] sm:w-[60%]  max-w-[500px] items-center pages_settings_notificationcontrol_maqraa">
                  <span className="text-xl md:text-2xl lg:text-3xl pages_settings_notificationcontrol_maqraa_text">
                    المقرأة
                  </span>
                  <SwitchButton
                    className="pages_settings_notificationcontrol_maqraa_switchbutton"
                    notificationData={localNotification?.find(
                      (data) => data.type === 'maqraa'
                    )}
                    notificationType="maqraa"
                    state={HelperLocalNotification.getLocalNotificationStatus(
                      'maqraa'
                    )}
                  />
                </div>
                <div className="flex flex-row justify-between w-[80%] sm:w-[60%]  max-w-[500px] items-center pages_settings_notificationcontrol_curriculum">
                  <span className="text-xl md:text-2xl lg:text-3xl pages_settings_notificationcontrol_curriculum_text">
                    المنهج
                  </span>
                  <SwitchButton
                    className="pages_settings_notificationcontrol_curriculum_switchbutton"
                    notificationData={localNotification?.find(
                      (data) => data.type === 'curriculum'
                    )}
                    notificationType="curriculum"
                    state={HelperLocalNotification.getLocalNotificationStatus(
                      'curriculum'
                    )}
                  />
                </div>
                <div className="flex flex-row justify-between w-[80%] sm:w-[60%]  max-w-[500px] items-center pages_settings_notificationcontrol_competition">
                  <span className="text-xl md:text-2xl lg:text-3xl pages_settings_notificationcontrol_competition_text">
                    المسابقات
                  </span>
                  <SwitchButton
                    className="pages_settings_notificationcontrol_competition_switchbutton"
                    notificationData={localNotification?.find(
                      (data) => data.type === 'competition'
                    )}
                    notificationType="competition"
                    state={HelperLocalNotification.getLocalNotificationStatus(
                      'competition'
                    )}
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </AppContent>
    </>
  )
}

const SwitchButton: FC<{
  notificationType: LocalNotificationType
  state: boolean
  notificationData: ILocalNotification
  className?: string
}> = ({
  notificationType,
  state,
  notificationData,
  className = 'pages_settings_notificationcontrol_switchbutton',
}) => {
  const [enabled, setEnabled] = useState(state ?? false)

  const pendingNotifications: PendingResult = {
    notifications: [
      {
        id: notificationData.id,
        title: notificationData.title,
        body: notificationData.message,
      },
    ],
  }

  const splitTime = notificationData?.time.split(':')
  const hour = Number(splitTime?.[0])
  const minute = Number(splitTime?.[1])

  return (
    <div className={`flex ${className}`}>
      <label className="inline-flex relative items-center mr-1 cursor-pointer">
        <input
          checked={enabled}
          className="sr-only peer"
          readOnly
          type="checkbox"
        />
        <div
          className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"
          onClick={async () => {
            setEnabled(!enabled)
            HelperLocalNotification.setLocalNotificationStatus(
              notificationType,
              !enabled
            )

            if (!enabled) {
              //   cancel specified notication
              //   then add specified notication
              await LocalNotifications.cancel(pendingNotifications)
              await HelperLocalNotification.addLocalNotificationByOn(
                notificationData.id,
                notificationData.title,
                notificationData.message,
                hour, // hour
                minute // minutes
              )
            } else if (enabled) {
              //   cancel specified notication
              await LocalNotifications.cancel(pendingNotifications)
            }
          }}
        />
      </label>
    </div>
  )
}

export default NotificationControl
