import useSwr from 'swr'
import { ILocalNotification } from '@src/types/api.interface'
import { FetchError } from '@utils/FetchError'

export function useLocalNotification(): {
  localNotification?: ILocalNotification[]
  error?: FetchError
  isLoadingLocalNotification: boolean
} {
  const url = `/local-notification`

  const { data, error } = useSwr<ILocalNotification[], FetchError>(url)

  return {
    localNotification: data,
    error,
    isLoadingLocalNotification: !error && !data,
  }
}
