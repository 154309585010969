/* eslint-disable no-restricted-globals */
import { useCallback, useEffect } from 'react'
import { App, URLOpenListenerEvent } from '@capacitor/app'
import { useHistory } from 'react-router-dom'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { useAnonymous } from '@contexts/AnonymousContext/AnonymousContext'
import Helper from '@utils/Helpers'
import { ParentProfile } from '@src/types/api.interface'
import HelperProfile from '@utils/helpers/helper.profile'
import { cleverTapEvents } from '@src/CleverTap/CleverTap'

export function useAppUrlListener(): void {
  const history = useHistory()
  const { setActiveKid } = useProfile()
  const { loginAnonymous } = useAnonymous()

  const getNewPathname = useCallback(
    (
      pathname: string,
      parentProfile: ParentProfile | undefined,
      loggedIn: boolean
    ): string => {
      const levelKid = parentProfile?.kids[0]?.level

      // curriculum
      if (pathname === '/theatre') {
        if (!loggedIn) {
          pathname = '/anonymous-select-age/curriculum'
          return pathname
        }

        if (parentProfile?.kids.length === 1) {
          setActiveKid(parentProfile?.kids[0])

          pathname = Helper.getCurriculumPathname(levelKid)

          return pathname
        } else if (parentProfile && parentProfile?.kids.length > 1) {
          pathname = '/select-kid-home/curriculum'
        }
      }

      if (pathname === '/competition' || pathname === '/maqraa') {
        if (!loggedIn) {
          if (pathname === '/maqraa') {
            pathname = '/maqraa-anonymous'
            return pathname
          }
          pathname = '/login'
          return pathname
        }

        if (parentProfile?.kids.length === 1) {
          setActiveKid(parentProfile?.kids[0])
        } else if (parentProfile && parentProfile?.kids.length > 1) {
          pathname = `/select-kid-home${pathname}`
        }
      }

      return pathname
    },
    [setActiveKid]
  )

  const directTo = useCallback(
    (url: string): void => {
      // TODO: add check for validating if url is valid url, containing www
      // http, and https
      const parent = HelperProfile.getStoredProfile()

      const loggedIn = parent && parent.email !== 'user@anonymous.firebase'
      let pathname = url
      if (url.includes('http') || url.includes('.com')) {
        pathname = new URL(url).pathname
      }
      let newPathname = ''

      if (
        pathname === '/maqraa' ||
        pathname === '/competition' ||
        pathname === '/theatre'
      ) {
        newPathname = getNewPathname(pathname, parent, loggedIn)

        if (!loggedIn && (pathname === '/theatre' || pathname === '/maqraa')) {
          loginAnonymous()
            .then((token) => {
              if (token !== 'error') {
                location.replace(newPathname)
              }
            })
            .catch((error) => {
              if (process.env.NODE_ENV === 'development') {
                console.error(error)
              }
            })
        }
        location.replace(newPathname)
      } else {
        location.replace(pathname)
      }
    },
    [getNewPathname, loginAnonymous]
  )

  useEffect(() => {
    const listener = App.addListener(
      'appUrlOpen',
      (event: URLOpenListenerEvent) => {
        if (event.url.includes('zidoworld.com')) {
          const slug = event.url.split('zidoworld.com')
          directTo(slug.slice(1).join(''))
        }
      }
    )

    App.getLaunchUrl()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((data: any) => {
        if (data?.url) {
          setTimeout(() => {
            cleverTapEvents.trackEventWithProps('deepLink', data.url)
            // handleDeepLink(data.url)
          }, 200)
        }
      })

    return () => {
      listener.remove()
    }
  }, [directTo, history])
}
