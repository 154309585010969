import React from 'react'
import * as ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import './styles/globals.css'

import { SWRConfig } from 'swr'
import { apiFetcher } from '@hooks/data/fetchers/api-fetcher'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { GlobalDebug } from '@utils/remove-console'

if (process.env.REACT_APP_SENTRY_ENVIRONMENT === 'production') {
  Sentry.init({
    dsn: 'https://fc73cc341fb4488d81388ee8d71a0556@o4504294231769088.ingest.sentry.io/4504321042808832',
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    tracesSampleRate: 1.0,
  })
  GlobalDebug({ debugOn: false, suppressAll: true })
}

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const root = (ReactDOM as any)?.createRoot(container)
document.ontouchmove = (event) => {
  event.preventDefault()
}

// Firebase worker error
const webWorker = new Worker('firebase-messaging-sw.js')
webWorker.onerror = (event) => {
  Sentry.captureException(event)
}

root.render(
  <BrowserRouter>
    <SWRConfig
      value={{
        fetcher: apiFetcher,
      }}
    >
      <HelmetProvider>
        <Helmet>
          <title className="main_title">عالم زيدو</title>
          <meta
            className="main_meta_viewport"
            content="width=device-width,initial-scale=1.0, shrink-to-fit=yes, user-scalable=no, maximum-scale=1.0"
            name="viewport"
          />
        </Helmet>
        <noscript className="main_gtm_noscript_iframe">
          <iframe
            className="main_gtm_script_iframe"
            height="0"
            src="https://www.googletagmanager.com/ns.html?id=GTM-WBX6Q5XK"
            style={{ display: 'none', visibility: 'hidden' }}
            title="gtm"
            width="0"
          />
        </noscript>
        <App />
        <ToastContainer
          autoClose={5000}
          className="main_toast_container_parent"
          closeOnClick
          draggable
          hideProgressBar={false}
          newestOnTop={false}
          pauseOnFocusLoss
          pauseOnHover
          position="top-right"
          rtl={false}
        />
      </HelmetProvider>
    </SWRConfig>
  </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()
