import { AppBar, AppContent } from '@components/Layout'
import Popup from '@components/Popup'
import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'

const MESSAGE_OK = 'موافق'
const POPUP_TITLE = 'مبروك'
const MESSAGE_WAITING_PAY_AT_FAWRY = `في انتظار الدفع عن طريق فوري
من فضلك قم بزيارة أقرب منفذ فوري لدفع المصاريف المطلوبة`

const PostPayment: FC = () => {
  const history = useHistory()
  return (
    <>
      <Helmet>
        <title>عالم زيدو</title>
      </Helmet>
      <AppBar
        fixed
        hideToggleButton
        showBackButton
        showHomeLink
        showParentBackButton
        title=""
      />

      <AppContent className="bg-no-repeat bg-cover bg-center bg-v3">
        <Popup
          acceptLink="/"
          buttonText={MESSAGE_OK}
          message={MESSAGE_WAITING_PAY_AT_FAWRY}
          onClose={() => {
            history.replace('/')
          }}
          title={POPUP_TITLE}
          visible={true}
        />
      </AppContent>
    </>
  )
}

export default PostPayment
