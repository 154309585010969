import React, { FormEvent, useEffect, useMemo, useState } from 'react'
import {
  Appearance,
  loadStripe,
  // StripeElementsOptions,
} from '@stripe/stripe-js'
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { Helmet } from 'react-helmet-async'
import { AppBar, AppContent } from '@components/Layout'
import SectionBackground from '@components/SectionBackground'
import Loading from '@components/Loading'
import { useParams } from 'react-router'
import { Capacitor } from '@capacitor/core'
import { usePurchases } from '@hooks/use-purchases'
import { useHistory } from 'react-router-dom'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import {
  PURCHASES_ERROR_CODES,
  PurchaseManagerWeb,
  SubscriptionPackage,
} from '@src/services/purchases'
import * as Sentry from '@sentry/react'
import { toast } from 'react-toastify'
import RoundedButton from './RoundedButton'
import {
  paymentBy,
  paymentCancelled,
  paymentFailed,
  paymentSuccess,
  selectPaymentPlan,
  selectSubscribePlan,
} from '@utils/analytics/payment'
import { SingularEvent } from '@utils/analytics/Singular'
import useQuery from '@hooks/use-query'
import { useIpLocation } from '@hooks/data/fetchers/use-ip-location'
import PopupWrapper from '@components/PopupWrapper'
import PaymentIframe from '@components/PaymentIframe'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import Config from '@config/index'

const stripeKey = process.env.REACT_APP_STRIPE_PUBLIC_API_KEY ?? ''
if (!stripeKey) {
  Sentry.captureEvent({
    message: `Stripe key not found: ${stripeKey}`,
    level: Sentry.Severity.Critical,
  })
}
const stripePromise = loadStripe(stripeKey)

const TITLE_CREDIT_CARD = 'بطاقة الائتمان'
const MESSAGE_MAKE_PAYMENT = 'تسديد مبلغ'
const MESSAGE_CONFIRM_PAYMENT = 'تأكيد الدفع'
const MESSAGE_PROCESSING_PAYMENT = 'معالجة الدفع الخاص بك'

const TITLE_CARD_NUMBER = 'رقم الكارت'
const TITLE_CARD_EXPIRATION = 'تأريخ الانتهاء'
const TITLE_CARD_CVC = 'CVC'
const CONTENT_CARD_POLICY =
  'باعطاء بيانات الكارت فانت تسمح لعالم زيدو بسحب الاشتراك في المستقبل طبقا للشروط و الأحكام'

const CheckoutPage = () => {
  const { packageId } = useParams<{ packageId: string }>()

  const levelSubs = useQuery().get('level') || null
  const history = useHistory()
  const { parentProfile } = useProfile()

  const [clientSecret, setClientSecret] = useState('')
  const [selectedPackage, setSelectedPackage] = useState<SubscriptionPackage>()
  const [subscriptionId, setSubscriptionId] = useState('')
  const [error, setError] = useState('')
  const purchase = usePurchases()
  const platform = Capacitor.getPlatform()
  const { countryInfo, isLoadingIpLocation } = useIpLocation()

  const isNativePlatform = Capacitor.isNativePlatform()
  const [localPaymentUrl, setLocalPaymentUrl] = useState('')

  const egpytISO = 'EG'

  function logPaymentEvent(status: 'success' | 'failed', platform: string) {
    switch (status) {
      case 'success':
        paymentSuccess({ identifier: platform })
        SingularEvent('sng_ecommerce_purchase')
        break
      case 'failed':
        paymentFailed({ identifier: platform })
        break
    }
    switch (platform) {
      case 'android':
        paymentBy({ type: 'payment_by_google', overwrite: true })
        break
      case 'ios':
        paymentBy({ type: 'payment_by_apple', overwrite: true })
        break
      case 'web':
        paymentBy({ type: 'payment_by_stripe', overwrite: true })
        break
    }
  }

  useEffect(() => {
    if (!packageId || !parentProfile) {
      toast.error('something went wrong')
      return
    }
    if (platform === 'android' || platform === 'ios') {
      /* Payment are recognized by their identifiers */
      selectPaymentPlan({ identifier: platform })
      selectSubscribePlan({ identifier: platform })

      purchase
        .makePurchase(packageId, parentProfile?._id, levelSubs)
        .then((purchased) => {
          if ('status' in purchased) {
            const { errorCode } = purchased
            if (errorCode !== 'none' || !purchased.status) {
              setError(errorCode ?? 'error')
            }
            if (PURCHASES_ERROR_CODES.IN_PROGRESS === errorCode) {
              // prevent duplicate trigger
              return
            }
            if (purchased.status && !purchased.userCancelled) {
              logPaymentEvent('success', platform)
              setError('')
              history.replace('/subscription/success')
            } else if (!purchased.status && purchased.userCancelled) {
              paymentCancelled({ identifier: platform })
            } else {
              // TODO: note
              // the status and user cancelled is called even before the then body
              // the retrigger is causing app to redirect to home
              // status and user cancel is also  not fully showing
              // accurate information
              // it shows false, false in both side when the checkout page
              // is visited, and when the modal is actually closed,
              // total of two times.
              logPaymentEvent('failed', platform)
            }
          }
        })
        .catch(() => {
          // console.log(
          //   'error from checkout',
          //   JSON.stringify(e),
          //   packageId,
          //   parentProfile?._id
          // )
        })
    } else if (platform === 'web') {
      purchase.getCurrentPackages().then((packages) => {
        selectSubscribePlan({ identifier: 'web' })
        setSelectedPackage(packages.find((p) => p.identifier === packageId))
      })

      const localPayment = countryInfo?.iso === egpytISO ? 'FAWRY' : null

      purchase
        .makePurchase(
          packageId.toString(),
          parentProfile?._id,
          levelSubs,
          localPayment
        )
        .then((data) => {
          if ('clientSecret' in data) {
            setClientSecret(data.clientSecret ?? '')
            setSubscriptionId(data.subscriptionId ?? '')
          }
        })
    }
  }, [
    countryInfo?.iso,
    history,
    levelSubs,
    packageId,
    parentProfile,
    platform,
    purchase,
  ])

  // Note: local payment are only for web
  useEffect(() => {
    const makePurchaseLocal = async () => {
      if (
        countryInfo?.iso === egpytISO &&
        selectedPackage?.identifier &&
        platform === 'web'
      ) {
        try {
          const checkoutLink =
            await PurchaseManagerWeb.getInstance().getFawryCheckoutLink(
              selectedPackage.identifier
            )
          setLocalPaymentUrl(checkoutLink)
        } catch (error) {
          console.error(error)
        }
      }
    }

    makePurchaseLocal()
  }, [countryInfo?.iso, selectedPackage?.identifier, platform])

  const appearance: Appearance = useMemo(
    () => ({
      theme: 'stripe',
    }),
    []
  )

  const options = useMemo(
    () => ({
      clientSecret,
      appearance,
    }),
    [clientSecret, appearance]
  )

  const handleLocalPaymentCompleted = () => {
    setLocalPaymentUrl('') // close popup
  }

  return (
    <>
      <Helmet>
        <title className="page_checkout_page_main_title">
          عالم زيدو - الباقات
        </title>
      </Helmet>
      {isLoadingIpLocation ? <AppProgressBar /> : null}
      <AppBar
        fixed
        hideToggleButton
        showBackButton
        showHomeLink
        showParentBackButton
        title=""
      />

      <AppContent className="bg-no-repeat bg-cover bg-center bg-v3 flex flex-col h-screen">
        <div className="page_checkout_page_main h-full mx-auto container  grid place-items-center">
          {!isLoadingIpLocation ? (
            <SectionBackground>
              <div className="page_checkout_page_credit_card_title_wrapper flex flex-col items-center p-2 w-[25em] lg:w-[40rem] md:p-4 lg:p-10">
                <h1 className="page_checkout_page_credit_card_title text-md font-bold lg:text-3xl">
                  {TITLE_CREDIT_CARD}
                </h1>
                {selectedPackage ? (
                  <h1 className="page_checkout_page_make_payment_title text-md mt-2 lg:text-2xl lg:mt-5">
                    {MESSAGE_MAKE_PAYMENT}
                    <span className="font-bold page_checkout_page_selected_package_price_span">
                      {' '}
                      {selectedPackage.priceString}
                    </span>
                  </h1>
                ) : null}
                <div className="mt-1 w-full lg:mt-5 page_checkout_page_client_secret_wrapper">
                  {localPaymentUrl ? (
                    <></>
                  ) : clientSecret ? (
                    <Elements options={options} stripe={stripePromise}>
                      <StripeCheckoutForm
                        clientSecret={clientSecret}
                        logPaymentEvent={logPaymentEvent}
                        subscriptionId={subscriptionId}
                      />
                    </Elements>
                  ) : !error ? (
                    <Loading />
                  ) : (
                    <>
                      <div className="block w-full page_checkout_page_error_message_wrapper text-center font-lamar text-[17px] mx-auto">
                        <p className="page_checkout_page_error_message">
                          لم توفر بيانات الدفع لباقة الاشتراك، الرجاء معاودة
                          اختيار باقة اشتراك
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </SectionBackground>
          ) : null}
        </div>
      </AppContent>
      {!isNativePlatform && localPaymentUrl ? (
        <PopupWrapper
          closePopup={() => {
            setLocalPaymentUrl(null)
            history.replace('/subscription/package')
          }}
          isVisible={!!localPaymentUrl}
        >
          <div className="w-[90vw] lg:w-[70vw] h-[80vh]">
            <PaymentIframe
              handleLocalPaymentCompleted={handleLocalPaymentCompleted}
              postMessageUrl={Config.fawryUrl}
              src={localPaymentUrl}
            />
          </div>
        </PopupWrapper>
      ) : null}
    </>
  )
}

const StripeCheckoutForm = (props: {
  clientSecret: string
  subscriptionId: string
  logPaymentEvent: (
    status: 'success' | 'failed',
    platform: 'android' | 'ios' | 'web'
  ) => void
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const history = useHistory()

  const [message, setMessage] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!stripe) {
      return
    }

    const clientSecret = props.clientSecret

    if (!clientSecret) {
      return
    }

    stripe.retrievePaymentIntent(clientSecret).catch((error) => {
      if (error) console.error(error)
      setMessage('Something Happen')
    })
  }, [stripe, props.clientSecret])

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    setIsLoading(true)

    const { paymentIntent } = await stripe.confirmCardPayment(
      props.clientSecret,
      {
        payment_method: {
          // TODO: add props
          card: elements.getElement(CardNumberElement),
        },
        return_url: `${process.env.REACT_APP_KIDS_URL}/subscription/success?subscriptionId=${props.subscriptionId}`,
      }
    )

    if (paymentIntent?.status === 'succeeded') {
      props.logPaymentEvent('success', 'web')
      history.replace(
        `/subscription/success?subscriptionId=${props.subscriptionId}`
      )
    }

    if (paymentIntent?.status === 'canceled') {
      props.logPaymentEvent('failed', 'web')
      history.replace('/subscription/failed')
    }

    setIsLoading(false)
  }

  return (
    <form
      className="page_checkout_page_stripe_checkout_form w-full"
      id="payment-form"
      onSubmit={handleSubmit}
    >
      <div className="page_checkout_page_stripe_checkout_container m-2">
        <div className="page_checkout_page_stripe_checkout_wrapper">
          <h1 className="page_checkout_page_title_card_number text-xs lg:text-lg">
            {TITLE_CARD_NUMBER}
          </h1>
          <CardNumberElement
            className="page_checkout_page_card_number_element p-2 mt-1 text-xs bg-white shadow rounded-lg lg:p-4 "
            id="cardNumber"
            options={{ showIcon: true, style: { base: { fontSize: '16px' } } }}
          />
        </div>

        <div className="page_checkout_page_title_card_expiration_container flex flex-row mt-1 gap-3 lg:gap-5 lg:my-3">
          <div className="page_checkout_page_title_card_expiration_wrapper basis-1/2">
            <h1 className="page_checkout_page_title_card_expiration_title text-xs lg:text-lg">
              {TITLE_CARD_EXPIRATION}
            </h1>
            <CardExpiryElement
              className="page_checkout_page_title_card_expiration_expiry_element p-2 mt-1 text-xl bg-white shadow rounded-lg lg:p-4 "
              id="cardExpiry"
            />
          </div>
          <div className="page_checkout_page_title_card_cvc_wrapper basis-1/2">
            <h1 className="page_checkout_page_title_card_cvc text-xs lg:text-lg">
              {TITLE_CARD_CVC}
            </h1>
            <CardCvcElement
              className="page_checkout_page_title_card_cvc_element p-2 mt-1 text-xl bg-white shadow rounded-lg lg:p-4 "
              id="cardCvc"
            />
          </div>
        </div>
        <div className="page_checkout_page_content_card_policy mt-3 text-[#5e5f6b] text-xs lg:text-md">
          {CONTENT_CARD_POLICY}
        </div>
      </div>

      <RoundedButton
        className="page_checkout_page_rounded_button bg-v3-green v3-shadow-green shadow-[4px_4px_0px_0px_#5E7426] group block mx-auto mt-10"
        disabled={isLoading || !stripe || !elements}
        id="submit"
      >
        <p className="page_checkout_page_payment_loading_message text-lg xl:text-2xl text-v3-purple group-hover:text-white">
          {isLoading ? MESSAGE_PROCESSING_PAYMENT : MESSAGE_CONFIRM_PAYMENT}
        </p>
      </RoundedButton>

      {message && !isLoading ? (
        <div
          className="page_checkout_page_payment_message text-center text-red-500 mt-4 font-bold"
          id="payment-message"
        >
          {message}
        </div>
      ) : null}
    </form>
  )
}

export default CheckoutPage
