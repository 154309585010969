/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-console */
export const GlobalDebug = (function () {
  const savedConsole = console
  /**
   * @param {boolean} debugOn
   * @param {boolean} suppressAll
   */
  return function ({
    debugOn,
    suppressAll = false,
  }: {
    debugOn: boolean
    suppressAll?: boolean
  }) {
    const suppress = suppressAll || false
    if (!debugOn) {
      // supress the default console functionality
      console.log = function () {}
      // supress all type of consoles
      if (suppress) {
        console.info = function () {}
        console.warn = function () {}
        console.error = function () {}
      } else {
        console.info = savedConsole.info
        console.warn = savedConsole.warn
        console.error = savedConsole.error
      }
    } else {
      // eslint-disable-next-line no-global-assign
      console = savedConsole
    }
  }
})()
