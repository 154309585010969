export function requestFullScreen(iframeId: string) {
  const iframe = document.getElementById(iframeId)
  if (!iframe) return
  if (iframe.requestFullscreen) {
    iframe.requestFullscreen()
  } else if (typeof iframe.msRequestFullscreen === 'function') {
    iframe.msRequestFullscreen()
  } else if (typeof iframe.mozRequestFullScreen === 'function') {
    iframe.mozRequestFullScreen()
  } else if (typeof iframe.webkitRequestFullScreen === 'function') {
    iframe.webkitRequestFullScreen()
  }
}

export function closeFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen()
  } else if (typeof document.mozCancelFullScreen === 'function') {
    /* Firefox */
    document.mozCancelFullScreen()
  } else if (typeof document.webkitExitFullscreen === 'function') {
    /* Chrome, Safari and Opera */
    document.webkitExitFullscreen()
  } else if (typeof document.msExitFullscreen === 'function') {
    /* IE/Edge */
    document.msExitFullscreen()
  }
}
