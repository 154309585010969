import { ChangeEventHandler, FC, useState } from 'react'
import LockIcon from './LockIcon'

interface InputBoxProps {
  placeholder: string
  value: string
  onChange?: ChangeEventHandler<HTMLInputElement>
}

const InputBox: FC<InputBoxProps> = (props) => {
  const [revealPasswordOnHoldInput, setRevealPasswordOnHoldInput] =
    useState('password')

  const revealPasswordOnHoldButton = () => {
    setRevealPasswordOnHoldInput(
      revealPasswordOnHoldInput === 'text' ? 'password' : 'text'
    )
  }

  return (
    <div className="flex items-center input_box-container">
      <LockIcon />
      <div className="w-[10px] input_box-wrpr" />
      <div className="relative bg-transparent flex-grow input_box-trnsprnt">
        <div className="flex h-full items-center justify-center absolute left-5 z-10 rounded-full input_box-rvl-pass-btn-wrpr">
          <button
            className="appearance-none passwordReveal w-6 h-4 input_box-rvl-pass-btn"
            onClick={revealPasswordOnHoldButton}
            tabIndex={9}
            type="button"
          />
        </div>
        <input
          className="absolute inset-0 rounded-full placeholder:text-white placeholder:text-opacity-60 text-white text-opacity-100 bg-[#4758C5] border-[#4758C5]  border-[5px] shadow-[inset_6px_0px_5px_#3141A7] input_box-rvl-pass-input"
          onChange={props?.onChange}
          placeholder={props.placeholder}
          type={revealPasswordOnHoldInput}
          value={props.value}
        />
        <div className="w-full p-6 shadow-[0px_4px_0px_0px_#2E3FA6] rounded-full  focus:ring input_box-shadow-elmnt" />
      </div>
    </div>
  )
}

export default InputBox
