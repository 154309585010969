import BlueRibbonContainer from '@components/Containers/BlueRibbonContainer'
import { AppBar } from '@components/Layout'
import HelperProfile from '@utils/helpers/helper.profile'
import { useHistory } from 'react-router-dom'

const Redirector = () => {
  const history = useHistory()
  const dummyURL = `http://localhost/${history.location.search}`
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const url = new URL(dummyURL as any)
  const redirectTo = url.searchParams.get('r')

  const messageContent = redirectTo?.toLowerCase().includes('maqraa') ? (
    <>
      <p className="redirector_download">تعلّم مُحكَم التنزيل</p>
      <p className="redirector_register_maqraa">سجّل الآن في المقرأة</p>
    </>
  ) : (
    <>
      <p className="redirector_competitions">استمتع باجمل المسابقات</p>
      <p className="redirector_register_now"> قم بالتسجيل الان</p>
    </>
  )

  const action = () => {
    if (redirectTo) {
      HelperProfile.setStoredRedirectLocation(redirectTo)
      history.push('/login')
    }
  }
  return (
    <>
      <AppBar
        fixed
        hideToggleButton
        showBackButton={true}
        showHomeLink={false}
        title=""
      />
      <BlueRibbonContainer action={action} message={messageContent} />
    </>
  )
}

export default Redirector
