const ScriptImporter = (src: string, head = false, id = '') => {
  if (id && id.trim() !== '' && document.querySelector(`#${id}`)) {
    return
  }
  const script = document.createElement('script')
  script.src = src
  if (id) script.id = id
  if (!head) {
    document.body.appendChild(script)
  }
  document.head.appendChild(script)
}
export default ScriptImporter
