/* eslint-disable @typescript-eslint/no-explicit-any */
// ClarityPlugin

import { Capacitor, registerPlugin } from '@capacitor/core'

export const CleverTapEventAndroid: any = registerPlugin('ClarityPluginAndroid')
// eslint-disable-next-line @typescript-eslint/no-empty-function
const emptyFn = () => {}
export const CleverTapEventIOS: any = {
  handleInit: emptyFn,
  handleOnStart: emptyFn,
  handleOnStop: emptyFn,
  handleOnDestroy: emptyFn,
}
// export const CleverTapEventIOS: any = registerPlugin('ClarityPluginIos')
const platform = Capacitor.getPlatform() as 'android' | 'ios' | 'web'
// Initialize Clarity with project ID
async function initClarity() {
  const projectId = 'l5t737smvw' // only used in mobile
  if (platform === 'ios') {
    await CleverTapEventIOS.handleInit({ projectId })
  } else if (platform === 'android') {
    await CleverTapEventAndroid.handleInit({ projectId })
  } else {
    // web
  }
}

export async function onClarityStart() {
  if (platform === 'ios') {
    await CleverTapEventIOS.handleOnStart()
  } else if (platform === 'android') {
    await CleverTapEventAndroid.handleOnStart()
  } else {
    // web
  }
}

export async function onClarityStop() {
  if (platform === 'ios') {
    await CleverTapEventIOS.handleOnStop()
  } else if (platform === 'android') {
    await CleverTapEventAndroid.handleOnStop()
  } else {
    // web
  }
}

export async function onClarityDestroyed() {
  if (platform === 'ios') {
    await CleverTapEventIOS.handleOnDestroy()
  } else if (platform === 'android') {
    await CleverTapEventAndroid.handleOnDestroy()
  } else {
    // web
  }
}

// Call initClarity when your app starts
initClarity()
