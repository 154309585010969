import Card from '@components/Card'
import { HorizontalSeparator } from '@components/Layout/KidCard'
import { FC, ReactNode } from 'react'
import SVG from 'react-inlinesvg'

interface ReportCardProps {
  children?: ReactNode
  title: string
  icon: string
  tabs?: string[]
  selectedTabIndex?: number
  onTabClick?: (index: number) => void
  className?: string
}

const ReportCard: FC<ReportCardProps> = ({
  children,
  icon,
  title,
  tabs = [],
  selectedTabIndex = 0,
  onTabClick,
  className,
}: ReportCardProps) => {
  const isSingleTab = tabs?.length === 1

  return (
    <Card
      className={`report_card-card flex flex-col w-full mb-5 pb-6 ${className}`}
    >
      <div className="report_card-wrapper flex flex-row justify-between px-4 pt-3 pb-2">
        <div className="report_card-main flex flex-row">
          <SVG className="report_card-svg" src={icon} />
          <h3 className="report_card-title text-2xl md:text-3xl text-v3-blue-800 mx-3 md:mx-5 truncate">
            {title}
          </h3>
        </div>

        {isSingleTab ? (
          <span className="report_card-is-single-tab-wrapper flex items-center px-5 rounded-full bg-v3-purple-500 align">
            <h4 className="report_card-is-single-tab text-lg md:text-xl text-white">
              {tabs[0]}
            </h4>
          </span>
        ) : null}

        {!!tabs.length && !isSingleTab ? (
          <div className="report_card-tabs-wrapper flex flex-row bg-v3-gray-100 text-center justify-center items-center px-1.5 rounded-full cursor-pointer">
            {tabs.map((tab, index) => {
              const isActive = selectedTabIndex === index
              const tabStyle = isActive ? 'rounded-full bg-v3-purple-500' : ''
              const titleStyle = isActive ? 'text-white' : 'text-v3-gray-500'

              return (
                <span
                  className={`report_card-tab-wrapper-${tab} flex items-center px-5 ${tabStyle}`}
                  key={tab}
                  onClick={() => {
                    onTabClick(index)
                  }}
                >
                  <h4
                    className={`report_card-tab-title-${tab} text-lg md:text-xl ${titleStyle}`}
                  >
                    {tab}
                  </h4>
                </span>
              )
            })}
          </div>
        ) : null}
      </div>
      <HorizontalSeparator />

      <div className="report_card-children-wrapper px-4 pt-6">{children}</div>
    </Card>
  )
}
export default ReportCard
