import { Purchases, PurchasesError } from '@awesome-cordova-plugins/purchases'
import { logEvent } from '@utils/firebase-analytics'
import HelperAPI from '@utils/helpers/helper.api'
import HelperProfile from '@utils/helpers/helper.profile'
import {
  PACKAGE_TYPE_LABELS,
  PACKAGE_TYPE_SAVES,
  PACKAGE_TYPE_TITLES,
  PURCHASES_ERROR_CODES,
  SUBSCRIPTION_ID_PACKAGES_LABELS,
} from './constants'
import {
  IPurchaseManager,
  ISubscriptions,
  SubscriptionInfo,
  SubscriptionPackage,
} from './types'

export class PurchaseManagerAndroid implements IPurchaseManager {
  private static instance: PurchaseManagerAndroid
  private readonly userId: string | undefined

  public static getInstance(): PurchaseManagerAndroid {
    if (!PurchaseManagerAndroid.instance) {
      PurchaseManagerAndroid.instance = new PurchaseManagerAndroid()
    }
    return PurchaseManagerAndroid.instance
  }

  private getUserId(): string | undefined {
    if (!this.userId) {
      return HelperProfile.getStoredProfile()?._id
    }
    return this.userId
  }

  setup(userId?: string): void {
    Purchases.setDebugLogsEnabled(process.env.REACT_APP_ENABLE_LOG === 'true')
    if (userId || this.getUserId()) {
      Purchases.configureWith({
        apiKey: this.getApiKey(),
        appUserID: this.getUserId() || userId,
      })
    } else {
      Purchases.configureWith({ apiKey: this.getApiKey() })
    }
  }

  getApiKey(): string {
    return process.env.REACT_APP_REVENUECAT_ANDROID_API_KEY! // TODO: Needs error handling
  }

  async getCurrentPackages(): Promise<SubscriptionPackage[]> {
    const userId = this.getUserId()
    userId && (await Purchases.logIn(userId))
    const offerings = await Purchases.getOfferings()
    const defaultPackages = offerings?.all?.default?.availablePackages ?? []
    const agePackages =
      offerings?.all['Age Group Access']?.availablePackages ?? []
    const packages = [...defaultPackages, ...agePackages]
    return packages.map((pkg): SubscriptionPackage => {
      return {
        identifier: pkg.identifier,
        priceString: pkg.product.priceString,
        description: PACKAGE_TYPE_LABELS[pkg.packageType] ?? pkg.packageType,
        title: PACKAGE_TYPE_TITLES[pkg.packageType] ?? pkg.packageType,
        promotion: PACKAGE_TYPE_SAVES[pkg.packageType] ?? '',
        type:
          pkg.offeringIdentifier === 'default' ? 'allAccess' : 'ageGroupAccess',
      }
    })
  }

  async getSubscriptionInfo(): Promise<SubscriptionInfo | null> {
    const userId = this.getUserId()
    if (userId) {
      await Purchases.logIn(userId).catch((e) =>
        console.error(
          'getSubscriptionInfo - user not found in purchase: ',
          JSON.stringify(e)
        )
      )
    }
    const info = await Purchases.getCustomerInfo()
    const IS_MOBILE_STORE = !!info?.managementURL
    const activeEntitlements = Object.values(info.entitlements.active)
    const data = await this.getSubscriptionFromBackend()

    if (activeEntitlements.length === 0) {
      return null
    }
    if (info.latestExpirationDate === null) {
      return null
    }

    const activeEntitlementInfo = activeEntitlements.find((entitlement) => {
      if (IS_MOBILE_STORE) {
        return info.activeSubscriptions[0]?.includes(
          entitlement.productIdentifier
        )
      } else {
        return entitlement.productIdentifier === info.activeSubscriptions[0]
      }
    })

    const label =
      SUBSCRIPTION_ID_PACKAGES_LABELS[info.activeSubscriptions[0]] ??
      info.activeSubscriptions[0]

    const response: SubscriptionInfo = {
      label,
      requestDate: new Date(info.requestDate),
      expiryDate: new Date(info.latestExpirationDate),
      managementUrl: info.managementURL,
      unsubscribeDetectedAt:
        activeEntitlementInfo?.unsubscribeDetectedAt?.toLowerCase() || null,
      accessType: data?.subscriptionType || 'allAccess',
      store: activeEntitlementInfo?.store?.toLowerCase() || null,
    }
    return response
  }

  async isHavingProAccess(contentLevel?: string): Promise<boolean> {
    const subInfo = await this.getSubscriptionInfo()
    const period = subInfo !== null && subInfo.requestDate <= subInfo.expiryDate

    if (subInfo?.accessType === 'allAccess' || !contentLevel) {
      return period
    }

    if (contentLevel === 'level-1') {
      return subInfo?.accessType === 'ageGroupOneAccess' && period
    }

    return subInfo?.accessType === 'ageGroupTwoAccess' && period
  }

  async cancelSubscription(): Promise<string | null> {
    // THIS WILL CANCEL SUBSCRIPTION ON STRIPE
    const URL = '/payments/cancel-subscription'
    const OPTIONS = { method: 'POST' }
    logEvent('cancelSubscription')

    try {
      const response = await HelperAPI.ApiRequest<string>(URL, OPTIONS)
      return response
    } catch (error) {
      logEvent('cancelSubscriptionError')
    }

    return null
  }

  async resumeSubscription(): Promise<string | null> {
    // THIS WILL RESUME SUBSCRIPTION ON STRIPE

    const URL = '/payments/resume-subscription'
    const OPTIONS = { method: 'POST' }
    logEvent('resumeSubscription')

    try {
      const response = await HelperAPI.ApiRequest<string>(URL, OPTIONS)
      return response
    } catch (error) {
      logEvent('resumeSubscriptionError')
    }

    return null
  }

  async revoke(): Promise<void> {
    await Purchases.logOut()
  }

  async makePurchase(
    packageId: string,
    userId: string | undefined,
    levelSubs?: string
  ): Promise<{
    status: boolean
    userCancelled: boolean
    errorCode: string | undefined
  }> {
    try {
      userId && (await Purchases.logIn(userId))
      const offerings = await Purchases.getOfferings()
      const defaultPackages = offerings?.all?.default?.availablePackages ?? []
      const agePackages =
        offerings?.all['Age Group Access']?.availablePackages ?? []
      const packages = [...defaultPackages, ...agePackages]
      const selectedPackage = packages.find(
        (pkg) => pkg.identifier === packageId
      )
      if (selectedPackage === undefined) {
        return {
          status: false,
          userCancelled: false,
          errorCode: PURCHASES_ERROR_CODES.NO_SELECTION,
        }
      }
      let errorCode = 'none'
      await Purchases.purchasePackage(selectedPackage).catch((e) => {
        errorCode = e.readableErrorCode
      })
      await this.updateSubscriptionBackend(
        selectedPackage.identifier.toLowerCase().includes('annual')
          ? 'yearly'
          : 'monthly',
        selectedPackage.offeringIdentifier === 'default'
          ? 'allAccess'
          : levelSubs === 'level-1'
          ? 'ageGroupOneAccess'
          : 'ageGroupTwoAccess'
      )
      // Assume all successful purchases doesn't return errors, since it was implied in RevenueCat documentations.
      // We just returns true since there's no null check involved.
      return {
        status: errorCode === 'none',
        userCancelled: false,
        errorCode,
      }
    } catch (e: PurchasesError | unknown) {
      const errorCode = (e as PurchasesError).readableErrorCode
      if (e && errorCode === PURCHASES_ERROR_CODES.CANCELLED) {
        return {
          status: false,
          userCancelled: true,
          errorCode,
        }
      }
      return { status: false, userCancelled: false, errorCode }
    }
  }

  async updateSubscriptionBackend(
    subscriptionPeriod = 'monthly',
    subscriptionType = 'allAccess'
  ) {
    await HelperAPI.ApiRequest('/subscriptions/user', {
      method: 'POST',
      body: JSON.stringify({
        subscriptionPlatform: 'PLAY_STORE',
        subscriptionPeriod,
        subscriptionType,
      }),
    })
  }

  async getSubscriptionFromBackend() {
    const data: ISubscriptions = await HelperAPI.ApiRequest(
      '/subscriptions/user'
    )
    return data
  }
}
