import { FC, MouseEventHandler } from 'react'

const GreenButton: FC<{
  label: string
  className?: string
  onClick?: MouseEventHandler<HTMLButtonElement>
}> = (props) => {
  return (
    <button
      className={`component_green_button_container px-10 py-1 text-center rounded-full text-2xl bg-[#B3D65B] text-[#2B3C94] shadow-[3px_3px_0px_0px_#5E7426] ${
        props.className || ''
      }`}
      onClick={props?.onClick}
      type="button"
    >
      {props.label}
    </button>
  )
}

export default GreenButton
