import { FirebaseAnalytics } from '@capacitor-firebase/analytics'
import { analytics, firebaseApp } from './firebase'
import { cleverTapEvents } from '@src/CleverTap/CleverTap'

export const initialize = async () => {
  await analytics(firebaseApp())
}

export const logEvent = async (
  name: string,
  params?: Record<string, unknown>
) => {
  try {
    delete params?.event
    delete params?.['gtm.uniqueEventId']
  } catch (e) {}
  // if (process.env.NODE_ENV !== 'production') console.log({ name, params })
  try {
    await FirebaseAnalytics.logEvent({ name, params })
    await cleverTapEvents.trackEventWithProps(name, params)
  } catch (e) {
    console.error('issue with logEvent', e)
  }
}

export const setUserId = async () => {
  await FirebaseAnalytics.setUserId({
    userId: '123',
  })
}

export const setUserProperty = async () => {
  await FirebaseAnalytics.setUserProperty({
    key: 'language',
    value: 'en',
  })
}

export const setCurrentScreen = async () => {
  await FirebaseAnalytics.setCurrentScreen({
    screenName: 'Login',
    screenClassOverride: 'LoginPage',
  })
}

export const setSessionTimeoutDuration = async () => {
  await FirebaseAnalytics.setSessionTimeoutDuration({
    duration: 120,
  })
}

export const setEnabled = async () => {
  await FirebaseAnalytics.setEnabled({
    enabled: true,
  })
}

export const isEnabled = async () => {
  const { enabled } = await FirebaseAnalytics.isEnabled()
  return enabled
}

export const resetAnalyticsData = async () => {
  await FirebaseAnalytics.resetAnalyticsData()
}
