import { FC } from 'react'
import Modal from '@components/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faGamepad } from '@fortawesome/free-solid-svg-icons'

const GoToGamesModal: FC<{
  level: string
  epiSlug: string
  onClose: () => void
  onCloseParent: () => void
  className?: string
}> = (props) => {
  let goToUrl: string

  switch (props.level) {
    case '1':
      goToUrl = '/anonymous-episode/anonymous-chapters/1/' + props.epiSlug
      break
    case '2':
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      goToUrl = '/anonymous-episode/anonymous-chapters/2/' + props.epiSlug
      break
    default:
      return null
  }

  return (
    <div className={props.className}>
      <Modal
        button={{
          icon: <FontAwesomeIcon icon={faGamepad} />,
          text: 'إلعب الأن',
          onClick: props.onCloseParent,
          bgColor: 'bg-[#fbf5be]',
          textColor: 'text-[#f6580f]',
          className: 'shadow-modal-button',
        }}
        close={props.onClose}
        icon={<FontAwesomeIcon icon={faCheck} />}
        isOpen
        message="لقد أنهيت مشاهدة الحلقة وحان الآن وقت الكتاب التفاعلي والألعاب.."
        title="رائع.."
      />
    </div>
  )
}

export default GoToGamesModal
