import React, { FC } from 'react'

const ArrowBullseyeIcon: FC<{
  classNames?: string
  fill?: string
}> = ({ classNames, fill }) => {
  return (
    <svg
      className={`${classNames} h-[35px] w-[35px] arrow_bullseye_icon-svg`}
      //   width="34"
      //   height="31"
      fill="none"
      viewBox="0 0 34 31"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3392 15.9519C18.5471 16.4458 18.729 16.9396 18.859 17.4334C19.3268 19.0449 19.6647 21.1242 19.2229 22.9177C19.0669 23.5934 18.807 24.2172 18.3911 24.7631C16.5977 27.1803 13.1408 26.6344 10.7756 25.4648C9.96987 25.0749 9.19013 24.5551 8.54034 23.9573C5.44734 21.1242 3.26405 16.2898 4.43367 12.0792C5.47333 8.31039 9.81392 8.05048 12.777 9.58398C13.8426 10.1298 14.8043 10.8836 15.636 11.7673L17.0916 10.5717C17.0396 10.5197 16.9876 10.4677 16.9616 10.4157C16.2858 9.68795 15.584 8.98617 14.8303 8.38837C10.6457 4.9055 3.05612 3.42398 0.976794 9.94786C-0.400759 14.2625 1.39266 19.6687 3.83587 23.2815C4.06979 23.5934 4.30371 23.9313 4.53764 24.2172C6.95485 27.1543 10.6457 30.4032 14.6743 30.7411C19.5348 31.131 22.0559 26.5305 22.0559 22.2159C22.0559 19.6427 21.3022 17.1475 20.1066 14.8863L18.3392 15.9519Z"
        fill={fill ?? '#D1E9F4'}
      />
      <path
        d="M10.5954 16.1338L11.4012 15.1981L13.8184 13.2228C12.5708 12.0532 10.8294 11.1694 9.21788 11.8972C7.32049 12.7549 6.85264 15.3281 7.39847 17.1475C8.12623 19.6427 10.2835 22.2678 12.8827 22.8397C13.1946 22.8916 13.4805 22.9436 13.7924 22.9436C16.5475 22.8656 17.0413 20.3964 16.5215 18.1612L16.2876 17.3035L13.8184 18.8889C13.5585 19.0709 13.2466 19.2008 12.9347 19.2528C12.7007 19.2788 12.4668 19.2788 12.2329 19.2528C11.6611 19.1489 11.1413 18.837 10.8034 18.3691C10.4655 17.9013 10.3355 17.3035 10.4135 16.7316C10.4395 16.5237 10.4915 16.3158 10.5954 16.1338Z"
        fill={fill ?? '#D1E9F4'}
      />
      <path
        d="M28.2161 5.97167L27.8002 0.56543L22.6539 4.28222C21.7702 4.93201 21.2243 5.94568 21.1724 7.03733L21.0684 9.19463L12.1533 16.4983C11.8414 16.7322 11.7634 17.174 11.9973 17.4859C12.2313 17.7978 12.6731 17.8758 12.985 17.6419L22.5759 11.4559L24.8372 12.1057C25.9028 12.4176 27.0205 12.2096 27.9042 11.5599L33.0505 7.84306L28.2161 5.97167Z"
        fill={fill ?? '#D1E9F4'}
      />
    </svg>
  )
}

export default ArrowBullseyeIcon
