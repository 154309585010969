import { useCallback, useMemo } from 'react'
import useSWR, { KeyedMutator } from 'swr'
import { IMaqraaStudent } from '@src/types/api.interface'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { FetchError } from '@utils/FetchError'
import HelperAPI from '@utils/helpers/helper.api'

export function useMaqraaStudent(): {
  student?: IMaqraaStudent
  error?: FetchError
  isLoadingStudent: boolean
  mutateStudent: KeyedMutator<IMaqraaStudent>
  registerStudent: (props: { email: string; phone: string }) => Promise<void>
} {
  const { kidProfile: profile } = useProfile()
  const url = profile?._id ? `/maqraa/${profile._id}/me` : null

  const { data, error, mutate } = useSWR<IMaqraaStudent, FetchError>(url, {
    shouldRetryOnError: false,
  })

  const registerStudent = useCallback(
    async (props: { email: string; phone: string }) => {
      try {
        await HelperAPI.ApiRequest(`/maqraa/${profile._id}/register`, {
          method: 'POST',
          body: JSON.stringify({
            email: props.email,
            phone: props.phone,
          }),
        })

        await mutate()
      } catch (error) {
        console.error(error)
      }
    },
    [mutate, profile?._id]
  )

  return useMemo(
    () => ({
      student: data,
      error,
      isLoadingStudent: url && !data && !error,
      mutateStudent: mutate,
      registerStudent,
    }),
    [data, error, mutate, registerStudent, url]
  )
}
