import { useAnonymous } from '@contexts/AnonymousContext/AnonymousContext'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { captureException } from '@sentry/react'
import HelperJWT from '@utils/helpers/helper.jwt'
import HelperProfile from '@utils/helpers/helper.profile'
import Cookies from 'js-cookie'
import { FC, useEffect } from 'react'
import { Route, RouteProps, useHistory } from 'react-router-dom'
import Config from '@config/index'
import secureLocalStorage from 'react-secure-storage'
const {
  localStorage: { tokenIndex },
} = Config

const PrivateRoute: FC<RouteProps & { kidOnly?: boolean }> = ({
  component: Component,
  kidOnly = false,
  ...rest
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/no-explicit-any
  const { anonOnly, authRoutes, loggedInUserType }: any = rest

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { parentProfile, logout, kidProfile } = useProfile()
  const { userToken } = useAnonymous()
  const history = useHistory()
  if (!anonOnly && !authRoutes && loggedInUserType === 'anon') {
    logout()
  }

  useEffect(() => {
    const newValue = secureLocalStorage.getItem(tokenIndex) as string
    const currentCookie = Cookies.get(tokenIndex)
    if (!currentCookie && newValue) {
      Cookies.set(tokenIndex, newValue, { expires: 365 })
    }
    if (!newValue && currentCookie) {
      HelperJWT.setJwtToken(currentCookie)
    }
  }, [])
  // eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/no-explicit-any
  const handleStorageEvent = (event: any) => {
    const { key } = event
    const newValue = HelperJWT.getJwtToken()
    const newKid = HelperProfile.getStoredKid()

    if (key === '@secure.s.activeKid') {
      if (newKid?._id !== kidProfile?._id) {
        history.push('/list-kid-home')
      }
    }
    if (key === '@secure.s.JWT_Token' && newValue !== userToken) {
      // console.log({ newValue, oldValue: userToken })
      logout()
    }
  }

  useEffect(() => {
    window.addEventListener('storage', handleStorageEvent)
    return () => window.removeEventListener('storage', handleStorageEvent)
  }, [handleStorageEvent])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  useEffect(() => {
    if (kidOnly && !kidProfile) {
      captureException('kicked out because the parent profile does not exist')
      logout()
    } else if (!kidOnly && !parentProfile) {
      captureException('kicked out because the parent profile does not exist')
      logout()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Route
      {...rest}
      render={(props) => {
        return Component && parentProfile && <Component {...props} />
      }}
    />
  )
}

export default PrivateRoute
