const GoldPlayIcon = () => {
  return (
    <svg
      className="gold_play_icon-svg"
      fill="none"
      height="44"
      viewBox="0 0 47 44"
      width="47"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.0882 0.750977C46.7921 0.750977 46.3076 14.2646 46.3076 21.9903C46.3076 29.7161 46.7921 43.2297 24.0882 43.2297H22.4735C-0.233624 43.2264 0.250811 29.7161 0.250811 21.9903C0.250811 14.2646 -0.233624 0.750977 22.4735 0.750977H24.0882Z"
        fill="#FFD356"
      />
      <path
        d="M11.0059 21.9904V16.1647C11.0059 8.93177 15.804 5.96987 21.6753 9.58634L26.4121 12.4992L31.149 15.412C37.0202 19.0285 37.0202 24.9523 31.149 28.5687L26.4121 31.4816L21.6753 34.3944C15.804 38.0109 11.0059 35.049 11.0059 27.816V21.9904Z"
        fill="#F6FFC7"
      />
    </svg>
  )
}

export default GoldPlayIcon
