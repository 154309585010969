import { IChapterDetail } from '@src/types/api.interface'
import { useCallback, useEffect, useState } from 'react'
import { useAnonymous } from '@contexts/AnonymousContext/AnonymousContext'
import HelperAPI from '@utils/helpers/helper.api'

export function useChapterAnonymous(props: {
  epiSlug: string
  chapSlug: string
  level: number
}): {
  chapter?: IChapterDetail
  isLoadingChapter: boolean
} {
  const [url, setUrl] = useState<string | null>()
  const [chapter, setChapter] = useState<IChapterDetail>()
  const { userToken } = useAnonymous()

  useEffect(() => {
    if (props.level && userToken) {
      setUrl(
        `/level/${props.level}/anonymous-episodes/${props.epiSlug}/anonymous-chapters/${props.chapSlug}`
      )
    }
  }, [props.level, userToken, props.epiSlug, props.chapSlug])

  const callApi = useCallback(async () => {
    if (url) {
      const response = await HelperAPI.ApiRequest<IChapterDetail>(url, {
        method: 'GET',
      })
      setChapter(response)
    }
  }, [url])

  useEffect(() => {
    callApi()
  }, [callApi, url])

  return {
    chapter,
    isLoadingChapter: !chapter,
  }
}
