import { useCallback, useEffect, useReducer, useRef } from 'react'
import { IChallenge } from '@src/types/api.interface'
import { useRanks } from './data/fetchers/competition/use-ranks'
import {
  useSubmitAnswer,
  useSubmitScore,
} from './data/fetchers/competition/use-user-answers'

// An enum with all the types of actions to use in our reducer
enum ChallengeActions {
  SELECT_ANSWER = 'SELECT_ANSWER',
  SUBMIT_ANSWER = 'SUBMIT_ANSWER',
  GO_NEXT = 'GO_NEXT',
  SET_CORRECT_ANSWER = 'SET_CORRECT_ANSWER',
  INIT = 'INIT',
}

// An interface for our actions
type ChallengeAction =
  | {
      type: ChallengeActions.INIT
      payload: IChallenge
    }
  | {
      type: ChallengeActions.GO_NEXT
    }
  | {
      type: ChallengeActions.SELECT_ANSWER
      payload: number
    }
  | {
      type: ChallengeActions.SUBMIT_ANSWER
    }
  | {
      type: ChallengeActions.SET_CORRECT_ANSWER
      payload: number
    }

// An interface for our state
interface ChallengeState {
  questionIndex: number
  selectedAnswer: number | null
  correctAnswer: number | null
  submitting: boolean
}

// Our reducer function that uses a switch statement to handle our actions
function challengeReducer(
  state: ChallengeState,
  action: ChallengeAction
): ChallengeState {
  switch (action.type) {
    case ChallengeActions.INIT:
      if (typeof state.correctAnswer === 'number') {
        return state
      }
      return {
        questionIndex: initQuestionIndex(action.payload),
        correctAnswer: null,
        selectedAnswer: null,
        submitting: false,
      }
    case ChallengeActions.GO_NEXT:
      return {
        questionIndex: state.questionIndex + 1,
        correctAnswer: null,
        selectedAnswer: null,
        submitting: false,
      }
    case ChallengeActions.SELECT_ANSWER:
      return {
        ...state,
        selectedAnswer:
          state.selectedAnswer === action.payload ? null : action.payload,
      }
    case ChallengeActions.SUBMIT_ANSWER:
      return {
        ...state,
        submitting: true,
      }
    case ChallengeActions.SET_CORRECT_ANSWER:
      return {
        ...state,
        submitting: false,
        correctAnswer: action.payload,
      }
    default:
      return state
  }
}

export interface ChallengeReducers {
  state: ChallengeState
  goNext: () => void
  onSelectAnswer: (index: number) => void
  onSubmit: (props?: { score: number }) => void
}
export function useChallengeReducer(
  challenge: IChallenge | undefined
): ChallengeReducers {
  const [state, dispatch] = useReducer(
    challengeReducer,
    challenge,
    (challenge) => ({
      correctAnswer: null,
      questionIndex: challenge ? initQuestionIndex(challenge) : 0,
      selectedAnswer: null,
      submitting: false,
    })
  )

  const challengeName = challenge?.uniqueName
  const submitAnswer = useSubmitAnswer(challengeName)
  const submitScore = useSubmitScore(challengeName)

  const { mutate: mutateRanks } = useRanks(challengeName)

  const goNext = useCallback(() => {
    dispatch({ type: ChallengeActions.GO_NEXT })
  }, [])

  const onSelectAnswer = useCallback((index: number) => {
    dispatch({ type: ChallengeActions.SELECT_ANSWER, payload: index })
  }, [])

  const onSubmit = useCallback(
    async (props?: { score: number }) => {
      if (!challenge) return
      const currentQuestion = challenge.questions[state.questionIndex]

      const questionNumber = currentQuestion?.number
      const selectedAnswer = state.selectedAnswer

      if (typeof questionNumber !== 'number') return

      if (props?.score) {
        // dispatch({ type: ChallengeActions.SUBMIT_ANSWER })
        await submitScore({ score: props.score, questionNumber })
      } else {
        if (selectedAnswer === null) return

        dispatch({ type: ChallengeActions.SUBMIT_ANSWER })
        const res = await submitAnswer({
          questionNumber,
          answer: selectedAnswer + 1,
        })
        dispatch({
          type: ChallengeActions.SET_CORRECT_ANSWER,
          payload: res.correctAnswer - 1,
        })
      }

      void mutateRanks()
    },
    [
      submitAnswer,
      submitScore,
      challenge,
      state.questionIndex,
      state.selectedAnswer,
      mutateRanks,
    ]
  )

  const isInit = useRef(false)

  useEffect(() => {
    if (!challenge || isInit.current) return
    isInit.current = true

    dispatch({ type: ChallengeActions.INIT, payload: challenge })
  }, [challenge])

  return {
    state,
    goNext,
    onSelectAnswer,
    onSubmit,
  }
}

function initQuestionIndex(challenge: IChallenge): number {
  return challenge.questions.findIndex((c) => typeof c.userAnswer !== 'number')
}
