import Cookies from 'js-cookie'
import { IResponseAssetShare } from '@src/types/api.interface'
import Config from '@config/index'
import SecureLocalStorage from 'react-secure-storage'
import * as Sentry from '@sentry/react'
import HelperProfile from './helpers/helper.profile'
import { getDomain } from './helpers/helper.domain'
import { COOKIE_NAME } from '@src/constants/cookies'
import HelperAPI from './helpers/helper.api'
import HelperJWT from './helpers/helper.jwt'
import HelperLocalNotification from './helpers/helper.localnotification'

const {
  apiURL,
  tawkto,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  localStorage: { tokenIndex: tokenKey, parentKey, kidKey, onboardingKey },
} = Config
// TODO: add to ENV

const Helper = {
  /**
   * get a presigned url for the file given in parameter
   */
  share: async function (file: string): Promise<string | null> {
    try {
      const data = await HelperAPI.ApiRequest<IResponseAssetShare>(
        `/assets/share?file=${file}`
      )
      return data?.link
    } catch (error) {
      Sentry.captureException(error)
      console.error(error)
      return null
    }
  },

  MediaURL: function (resource: string, medium = 'file') {
    const isMongoId = /^[a-f\d]{24}$/i

    if (!isMongoId.test(resource)) return resource // not a database ID
    if (resource.includes('http')) return resource // not a database ID

    let accessToken = <string>SecureLocalStorage.getItem(tokenKey)
    if (accessToken) accessToken = '?access_token=' + accessToken

    return `${apiURL}/media/${medium}/${resource}${accessToken}&`
  },

  setupTawkTo() {
    if (!tawkto) return

    const id = 'tawkto-script'
    // eslint-disable-next-line @typescript-eslint/naming-convention, prefer-const, @typescript-eslint/no-explicit-any
    let Tawk_API = (window as any).Tawk_API || { widgetPosition: () => 0 }
    const existingScript = document.getElementById(id)
    let c = 50
    const intrv: string | number | NodeJS.Timer = setInterval(() => {
      // eslint-disable-next-line @typescript-eslint/naming-convention, prefer-const, @typescript-eslint/no-explicit-any
      const hasWidget = document.querySelector(
        '.widget-visible iframe:not(.done)'
      )
      const path = window.location.pathname
      if (hasWidget) {
        const style = hasWidget.getAttribute('style')
        hasWidget.setAttribute('style', style.replace('1000001', '10'))
        if (path === '/list-kid') {
          hasWidget.setAttribute(
            'style',
            style.replace('right:20px !important; bottom:20px !important;', '')
          )
          hasWidget.classList.add('custom-positions')
        }
        hasWidget.classList.add('done')
        clearInterval(intrv)
      }
      if (c <= 0) {
        clearInterval(intrv)
      }
      c--
    }, 1000)
    if (existingScript) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (Tawk_API) {
        try {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Tawk_API.showWidget()
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          Tawk_API.customStyle = { zIndex: 10 }
        } catch (e) {
          Sentry.captureException(e)
        }
      }
      return
    }

    const script = document.createElement('script')
    script.id = id
    script.innerHTML = `
    var Tawk_API = window.Tawk_API || {},
      Tawk_LoadStart = new Date();
    Tawk_API.customStyle = {zIndex: 10};
    ;(function () {
      var s1 = document.createElement('script'),
        s0 = document.getElementsByTagName('script')[0]
      s1.async = true
      s1.src = '${tawkto}'
      s1.charset = 'UTF-8'
      s1.setAttribute('crossorigin', '*')
      s0.parentNode.insertBefore(s1, s0)
    })()`

    document.body.append(script)
  },
  removeTawkTo() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window as any)?.Tawk_API) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ;(window as any).Tawk_API.hideWidget()
      } catch (e) {
        Sentry.captureException(e)
      }
      // if (document.getElementById('tawkto-script')) {
      //   document.getElementById('tawkto-script').remove()
      // }
    }
  },

  logOut(_from?: string): void {
    // const stack = new Error().stack.split('\n')
    // const callerIndex = stack.findIndex((line) =>
    //   line.includes('at lastFunction')
    // )
    // const previousPlace = stack[callerIndex + 2].match(/at\s+(.*)/)[1]

    // console.log(`logout is called from ${from}`, previousPlace)
    const onboarding = Helper.getOnboardingStatus()
    const competition =
      HelperLocalNotification.getLocalNotificationStatus('competition')
    const curriculum =
      HelperLocalNotification.getLocalNotificationStatus('curriculum')
    const maqraa = HelperLocalNotification.getLocalNotificationStatus('maqraa')
    const redirectToUrl = HelperProfile.getStoredRedirectLocation()

    Cookies.remove(COOKIE_NAME, { path: '/', domain: getDomain() })
    HelperProfile.removeStoredProfile()
    HelperProfile.removeStoredKid()
    HelperProfile.setProfileType('legacy')
    HelperJWT.removeJwtToken()
    SecureLocalStorage.clear()

    // Reinitialized wanted local storage item
    Helper.setOnboardingStatus(onboarding)
    HelperLocalNotification.setLocalNotificationStatus(
      'competition',
      competition ?? false
    )
    HelperLocalNotification.setLocalNotificationStatus(
      'curriculum',
      curriculum ?? false
    )
    HelperLocalNotification.setLocalNotificationStatus(
      'maqraa',
      maqraa ?? false
    )
    if (
      redirectToUrl &&
      typeof redirectToUrl === 'string' &&
      redirectToUrl.trim().length > 0
    ) {
      HelperProfile.setStoredRedirectLocation(redirectToUrl)
    }
  },

  getOnboardingStatus(): boolean {
    try {
      const status = SecureLocalStorage.getItem(onboardingKey)
      return !!status
    } catch (error) {
      Sentry.captureException(error)
      console.error(error)
      return false
    }
  },
  removeOnboardingStatus() {
    try {
      SecureLocalStorage.removeItem(onboardingKey)
    } catch (e) {
      //
    }
  },

  setOnboardingStatus: function (value: boolean): void {
    SecureLocalStorage.setItem(onboardingKey, value.toString())
  },

  accessLog: async function (
    category: string | null,
    resource: string | null,
    description = null as string | null,
    isAction = false
  ): Promise<void> {
    const me = await HelperProfile.getStoredProfile()
    if (!me) return

    const body: {
      isAction: boolean
      kid: string
      category?: string
      resource?: string
      description?: string
    } = {
      isAction: !!isAction,
      kid: me._id,
    }

    if (category) body.category = category
    if (resource) body.resource = resource
    if (description) body.description = description

    const url = '/logs/'
    await HelperAPI.ApiRequest(url, {
      method: 'POST',
      body: JSON.stringify(body),
    }).catch((e) => {
      Sentry.captureException(e)
      // log can fail when token as expired, no problem
      console.error(e)
    })
  },

  validatePassword: function (p: string) {
    const errors: string[] = []
    if (p.length === 0) {
      errors.push('لا يمكن أن يكون حقل كلمة المرور فارغًا')
    }
    if (p.length < 8) {
      // ENGLISH: Please choose a password of at least 8 characters
      // errors.push("المرجو اختيار رمز سري يتكون من 8 رموز على الأقل");
      errors.push('يرجى اختيار كلمة مرور مكونة من 8 رموز على الأقل')
    }
    if (p.search(/[a-z]/i) < 0) {
      // ENGLISH: The new password must contain at least one character
      // errors.push("المرجو اختيار رمز سري يضم على الأقل حرفا واحدا");
      errors.push('يجب أن تحتوي كلمة المرور الجديدة على حرف واحد على الأقل')
    }
    if (p.search(/[0-9]/) < 0) {
      // ENGLISH: The new password must contain at least one number
      // errors.push("المرجو اختيار رمز سري يضم على الأقل رقما واحدا");
      errors.push('يجب أن تحتوي كلمة المرور الجديدة على رقم واحد على الأقل')
    }

    return errors
  },
  validateEmail: function (e: string) {
    const errors: string[] = []
    if (!e.length) {
      errors.push('لا يمكن أن يكون حقل البريد الإلكتروني فارغًا')
    }
    return errors
  },
  validateCheckBox: function (c: boolean) {
    const errors: string[] = []
    if (!c) {
      errors.push('يرجى وضع علامة في هذا المربع إذا كنت تريد المتابعة')
    }

    return errors
  },

  getCurriculumPathname: function (level: number | undefined) {
    return level === 1
      ? '/theatre'
      : level === 2
      ? '/l2/maps'
      : level === 3
      ? '/l3/maps'
      : '/'
  },

  // in case we need to have a dom
  stringToHTML: (str: string) => {
    const support = (function () {
      if (!window.DOMParser) return false
      const parser = new DOMParser()
      try {
        parser.parseFromString('x', 'text/html')
      } catch (err) {
        return false
      }
      return true
    })()
    if (support) {
      const parser = new DOMParser()
      const doc = parser.parseFromString(str, 'text/html')
      return doc
    }

    const dom = document.createElement('div')
    dom.innerHTML = str
    return dom
  },
  getCurrentURLFolder: (urlString: string, levels = 1) => {
    const url = new URL(urlString)
    const pathArray = url.pathname.split('/').filter(Boolean)
    const currentPath = pathArray.slice(0, -levels)
    const currentPathString =
      currentPath.length > 0 ? currentPath.join('/') + '/' : '/'
    return `${url.origin}/${currentPathString}`
  },
  makeBlobUrlFromHtml: function (
    htmlString: string,
    baseUrl: string
    // absolute = false
  ) {
    const baseUrlStr = this.getCurrentURLFolder(baseUrl)
    const html = this.stringToHTML(htmlString)
    const head = html.querySelector('head')
    const baseElement = document.createElement('base')
    baseElement.href = baseUrlStr
    head.prepend(baseElement)
    const updatedHtmlString = html.querySelector('html').outerHTML

    // if (absolute) {
    //   const urlRegex = /(?:src|href)=(["'])(.*?)\1/gi
    //   const absoluteHtml = updatedHtmlString.replace(
    //     urlRegex,
    //     (match, quote, url) => {
    //       if (url.startsWith('http://') || url.startsWith('https://')) {
    //         return match
    //       }
    //       const src = match.trim().toLowerCase().startsWith('src')
    //       const href = match.trim().toLocaleLowerCase().startsWith('href')
    //       const link = src ? 'src=' : 'href='
    //       const absoluteUrl = new URL(url, this.getCurrentURLFolder(baseUrl))
    //         .href
    //       console.log(absoluteUrl)
    //       return `${link}${quote}${absoluteUrl}${quote}`
    //     }
    //   )
    // }
    // absoluteHTML converts relative to absolute paths
    // <base href='https://example.com/'>

    const blob = new Blob([updatedHtmlString], { type: 'text/html' })
    const blobUrl = URL.createObjectURL(blob)
    return blobUrl
  },
}

export default Helper
