import { AppBar } from '@components/Layout/AppBar'
import { AppContent } from '@components/Layout/AppContent'
import Loading from '@components/Loading'
import React, { FC, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'

const InAppBrowser: FC = () => {
  const [loading, setLoading] = useState(true)

  const hideSpinner = () => {
    setLoading(false)
  }

  const history = useHistory<{ url: string }>()
  const { url } = history.location?.state || {}

  return (
    <>
      <Helmet>
        <title className="parent_page_in_app_browser-main-title">
          عالم زيدو
        </title>
      </Helmet>
      <AppContent className="bg-[#1f5560] h-screen">
        <AppBar
          backButtonLocation="/list-kid"
          fixed
          hideToggleButton
          showBackButton
          title=""
        />
        <div className="parent_page_in_app_browser-container relative h-[500px] md:h-screen w-[screen] max-w-screen max-h-screen pt-10 lg:pt-6 2xl:pt-1 flex items-center justify-center ">
          {loading ? (
            <div className="parent_page_in_app_browser-loading-wrapper absolute z-10">
              <Loading />
            </div>
          ) : null}

          <iframe
            height="85%"
            onLoad={hideSpinner}
            src={url}
            title="in-app-browser"
            width="100%"
          />
        </div>
      </AppContent>
    </>
  )
}

export default InAppBrowser
