/* eslint-disable @typescript-eslint/no-explicit-any */
import { sendGaToFirebase } from './sendGaToFirebase'

export function openDailyCompetition(props: {
  level: number
  competitionName: string
  challengeName: string
}) {
  const event: any = {
    event: 'competition_open_daily',
    eventCategory: 'Competition',
    eventAction: 'Open daily competition',
    eventLabel: `Level ${props.level}, ${props.competitionName} ${props.challengeName}`,
  }
  window.dataLayer?.push(event)
  sendGaToFirebase(event)
}

export function openCompetitionScore(props: {
  level: number
  competitionName: string
}) {
  const event: any = {
    event: 'competition_open_score',
    eventCategory: 'Competition',
    eventAction: 'Open competition score',
    eventLabel: `Level ${props.level}, ${props.competitionName}`,
  }
  window.dataLayer?.push(event)
  sendGaToFirebase(event)
}

export function openCompetitionPrize(props: {
  level: number
  competitionName: string
}) {
  const event: any = {
    event: 'competition_open_prize',
    eventCategory: 'Competition',
    eventAction: 'Open competition prize',
    eventLabel: `Level ${props.level}, ${props.competitionName}`,
  }
  window.dataLayer?.push(event)
  sendGaToFirebase(event)
}
