import { useMemo } from 'react'
import useSWR from 'swr'
import { ICompetition } from '@src/types/api.interface'
import { FetchError } from '@utils/FetchError'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'

export function useCompetition(): {
  competition?: ICompetition
  error?: FetchError
  isLoadingCompetition: boolean
} {
  const { kidProfile: profile } = useProfile()

  const url = `/competitions/current/${profile.level}`

  const { data, error } = useSWR<ICompetition, FetchError>(url)

  return useMemo(
    () => ({
      competition: data,
      error,
      isLoadingCompetition: !data && !error,
    }),
    [data, error]
  )
}
