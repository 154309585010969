import { ContentTitle } from '@components/ContentTitle'
import { AppBar } from '@components/Layout/AppBar'
import { AppContent } from '@components/Layout/AppContent'
import { ISubLibrary } from '@src/types/api.interface'
import { LOCKED_ICON, subLibraryDefault } from '@utils/constants'
import { logEvent } from '@utils/firebase-analytics'
import { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

const subLibraries: ISubLibrary[] = subLibraryDefault

const MaktabahHome: FC = () => {
  useEffect(() => {
    logEvent('open_library')
  }, [])

  return (
    <>
      <Helmet>
        <title className="maktabah_home_page-main-title">
          عالم زيدو - أطفال
        </title>
      </Helmet>
      <AppBar
        backButtonLocation="/kids-home"
        fixed
        showBackButton={true}
        showKidAvatar
        title=""
      />
      <AppContent className="bg-no-repeat bg-cover bg-center bg-blue ">
        <ContentTitle noShadow textColor="text-black" title="المكتبة" />
        <div className="maktabah_home_page-container w-full mx-auto max-w-[2000px] h-[80vh] overflow-hidden flex flex-col items-center justify-center">
          <div className="maktabah_home_page-wrapper w-full max-w-[85%] grid grid-cols-3 gap-2 sm:gap-4 md:gap-8 content-center">
            {subLibraries.map((subLibrary: ISubLibrary, index: number) => {
              return (
                <LinkToSubLibrary
                  key={index}
                  locked={subLibrary.isLock}
                  subLibrary={subLibrary}
                />
              )
            })}
          </div>
        </div>
      </AppContent>
    </>
  )
}

export default MaktabahHome

const LinkToSubLibrary: FC<{
  subLibrary: ISubLibrary
  locked?: boolean
}> = ({ subLibrary, locked }) => {
  return (
    <div
      className={`maktabah_home_page-link-to-sub-library-${subLibrary.path} relative`}
    >
      {locked ? <LockedIconOverlay /> : null}
      <Link
        className={`maktabah_home_page-sub-library-link-${subLibrary.path}`}
        to={subLibrary.path}
      >
        <img
          alt="المقرأة"
          className={`maktabah_home_page-subLibrary-img-${subLibrary.path}`}
          src={subLibrary.icon1X}
          srcSet={`${subLibrary.icon3X} 675w, ${subLibrary.icon2X} 450w, ${subLibrary.icon1X} 225w`}
        />
      </Link>
    </div>
  )
}

const LockedIconOverlay: FC = () => {
  return (
    <div className="maktabah_home_page-locked-icon-overlay z-10 absolute rounded-2xl md:rounded-3xl w-full h-full bg-black bg-opacity-40 flex items-center justify-center">
      <img
        alt=""
        className="maktabah_home_page-locked-icon"
        draggable="false"
        src={LOCKED_ICON}
      />
    </div>
  )
}
