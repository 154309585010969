import { FC } from 'react'

interface CompetitionListItemCardProps {
  width?: number
  height?: number
  strokeWidth?: number
  strokeColor?: string
  fillColor?: string
  haveShadow?: boolean
  classNames?: string
}

const CompetitionListItemCard: FC<CompetitionListItemCardProps> = ({
  width,
  height,
  fillColor,
  strokeColor,
  strokeWidth,
  haveShadow = true,
  classNames,
}) => {
  return (
    <svg
      className={`competition_list_item_card-svg ${classNames}`}
      fill="none"
      height={height}
      viewBox="0 0 186 182"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M96.8046 2.5C135.682 2.5 153.973 14.7869 162.819 29.8576C171.526 44.6929 171.51 62.8061 171.498 77.0605C171.498 77.5916 171.497 78.1174 171.497 78.6375C171.497 79.1613 171.498 79.691 171.498 80.2261C171.511 94.479 171.527 112.586 162.823 127.417C153.979 142.488 135.687 154.775 96.8046 154.775H91.1958C91.1957 154.775 91.1956 154.775 91.1954 154.775C52.3127 154.769 34.021 142.482 25.177 127.413C16.4734 112.583 16.4893 94.4788 16.5019 80.2261C16.5024 79.691 16.5028 79.1613 16.5028 78.6375C16.5028 78.1136 16.5024 77.5839 16.5019 77.0488C16.4893 62.7959 16.4734 44.6887 25.177 29.8575C34.0211 14.787 52.3127 2.5 91.1954 2.5H96.8046Z"
        fill={fillColor}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
      {haveShadow ? (
        <>
          <g filter="url(#filter0_f_208_5906)">
            <ellipse
              cx="92.6532"
              cy="152.953"
              fill="black"
              fillOpacity="0.26"
              rx="76.1365"
              ry="12.0472"
            />
          </g>
          <defs>
            <filter
              colorInterpolationFilters="sRGB"
              filterUnits="userSpaceOnUse"
              height="56.6943"
              id="filter0_f_208_5906"
              width="184.873"
              x="0.216724"
              y="124.606"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                in="SourceGraphic"
                in2="BackgroundImageFix"
                mode="normal"
                result="shape"
              />
              <feGaussianBlur
                result="effect1_foregroundBlur_208_5906"
                stdDeviation="8.15"
              />
            </filter>
          </defs>
        </>
      ) : null}
    </svg>
  )
}

export default CompetitionListItemCard
