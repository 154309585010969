const Config = {
  apiURL: process.env.REACT_APP_API_URL || 'http://localhost:3000/api/',
  enableLog: process.env.REACT_APP_ENABLE_LOG === 'true',
  maxProfilePublications: 4,
  whoamiExpiry: 120,
  maxSpentTimeInterval: 60 * 1000, // Check the kid max timeout every minute
  whiteListedTawkRoutes:
    process.env.REACT_APP_WHITE_LISTED_TAWK_ROUTES ||
    'parent,list-kid,add-kid,notification,settings,subscription,subscription/current,subscription/package,settings/notification-control',
  localStorage: {
    keyLength: 64,
    keyName: 'zidoWorldStorage',
    tokenKey: 'token',
    parentKey: 'parentData',
    kidKey: 'kidData',
    onboardingKey: 'Onboarding',
    tokenIndex: 'JWT_Token',
    whoamiIndex: 'Profile',
    activeKidIndex: 'activeKid',
  },
  tawkto: process.env.REACT_APP_TAWK_TO,
  commercialWebsite: 'https://www.zidoworld.com',
  galleryConfig: {
    itemsPerPage: 12,
  },
  publicURL: process.env.PUBLIC_URL,
  firebase: {
    anonUser: 'user@anonymous.firebase',
    vapidKey: process.env.REACT_APP_FIREBASE_VAPID,
  },
  firebaseNotification: {
    topic: process.env.REACT_APP_FIREBASE_TOPIC ?? 'all-device-zido',
  },
  fawryUrl: process.env.REACT_APP_FAWRY_URL,
}

export default Config
