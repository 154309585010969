/* eslint-disable @typescript-eslint/no-explicit-any */
import { sendGaToFirebase } from './sendGaToFirebase'

export function contentOpened({
  episode,
  parentId,
  childId,
  contentId,
  episodeId,
  handBookId,
  gameId,
  index,
}: {
  episode?: any
  parentId: string
  childId: string
  contentId?: string
  episodeId?: string
  videoId?: string
  handBookId?: string
  gameId?: string
  index?: number
}) {
  const eventTitle = (
    episode.type === 'gameChallenge'
      ? `${'competition_challenge'}_${episode.slug || 'slug'}_start`
      : `${episode.type || 'game'}_${episode.slug || 'slug'}_${index}_start`
  ).replace(/[^A-Za-z0-9\u0600-\u06FF]/g, '_')
  const event: any = {
    event: eventTitle,
    // event: episode
    //   ? `content_${title || 'game_content'}/${
    //       episode?.series?.title || 'game_content'
    //     }/${episodeId || 'game_content'}/Start`
    //   : 'content_opened',
    // event: 'content_opened',
    eventAction: 'content_opened',
    eventCategory: 'Content',
    data: {
      date: new Date().toUTCString(),
      parentId,
      childId,
      contentId,
      episodeId,
      handBookId,
      gameId,
    },
  }
  window.dataLayer.push(event)
  sendGaToFirebase(event)
}

export function contentClosed({
  episode,
  parentId,
  childId,
  contentId,
  episodeId,
  handBookId,
  gameId,
  index,
}: {
  episode?: any
  parentId: string
  childId: string
  contentId?: string
  episodeId?: string
  handBookId?: string
  gameId?: string
  index?: number
}) {
  const eventTitle = (
    episode.type === 'gameChallenge'
      ? `${'competition_challenge'}_${episode.slug || 'slug'}_close`
      : `${episode.type || 'game'}_${episode.slug || 'slug'}_${index}_close`
  ).replace(/[^A-Za-z0-9\u0600-\u06FF]/g, '_')
  const event: any = {
    event: eventTitle,
    // event: episode
    //   ? `content_${title || 'game_content'}/${
    //       episode?.series?.title || 'game_content'
    //     }/${episodeId || 'game_content'}/Close`
    //   : 'content_closed',
    // event: 'content_closed',
    eventAction: 'content_closed',
    eventCategory: 'Content',
    data: {
      date: new Date().toUTCString(),
      parentId,
      childId,
      contentId,
      episodeId,
      handBookId,
      gameId,
    },
  }
  window.dataLayer.push(event)
  sendGaToFirebase(event)
}
