import useSwr from 'swr'
import { IAccountReportsResponse } from '@src/types/api.interface'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { FetchError } from '@utils/FetchError'

export function useReports(props: { id: string | null | undefined }): {
  reports?: IAccountReportsResponse
  error?: FetchError
  isLoadingReports: boolean
} {
  const { kidProfile: profile } = useProfile()
  const url = profile && props.id ? `/reports/${props.id}` : null

  const { data, error } = useSwr<IAccountReportsResponse, FetchError>(url)

  return {
    reports: data,
    error,
    isLoadingReports: !error && !data,
  }
}
