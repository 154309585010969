import { IEpisodeListItem } from '@src/types/api.interface'
import { FetchError } from '@utils/FetchError'
import useSwr from 'swr'

export function useEpisodesInSeriesParent(seriesId: string): {
  episodes?: IEpisodeListItem[]
  error?: FetchError
  isLoadingEpisodes: boolean
} {
  const url = `/series/episodes/${seriesId}`

  const { data, error } = useSwr<IEpisodeListItem[], FetchError>(url)

  return {
    episodes: data,
    error,
    isLoadingEpisodes: !error && !data,
  }
}
