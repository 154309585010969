export const TYPOGRAPHY = {
  TOP_COMPETITOR_TITLE: 'المتصدرون',
  TODAY_TEXT: 'اليوم',
  POINT_TEXT: 'نقطة',
  COMPETITION_TEXT: ' سباق',
  RANK_LIST_TITLE: 'ترتيبك في القائمة',
  WEBSITE_TITLE: 'عالم زيدو - السباقات',
  TITLE: 'سباقات زيدو',
  NO_COMPETITION: 'لا يوجد حاليا سباق جديد, ترقب مزيداً من التحديات بإذن الله',
  TOP_COMPETITORS: 'المتصدرون',
  MY_SCORES: 'درجاتي',
  MY_COMPETITIONS: 'سباقاتي',
  EMPTY_MESSAGE: 'لا يوجد حاليا سباق جديد, ترقب مزيداً من التحديات بإذن الله',
  BE_TOP_COMPETITOR: 'كن اول المتصدرين وابدا المسابقة',
  FINISHED_TODAY_COMPETITION:
    'لقد أنهيت التحدي، ترقب مزيداً من التحديات بإذن الله',
  PARTICIPATE_COMPETITION_MESSAGE: 'كن أول المتصدرين وابدأ السباق',
  POPUP_TITLE: 'المتصدرين',
  POPUP_MESSAGE: 'لا يوجد سباقات حاليا لعرض الاوائل',
  POPUP_BUTTON_TEXT: 'حسنا',
  LOAD_MORE_TEXT: 'عرض المزيد',
}

export const COMPETITION_LIST_TYPOGRAPHY = {
  WEBSITE_TITLE: 'عالم زيدو - سباقات زيدو',
  COMPETITION_DETAILS_TITLE: 'سباقات زيدو',
  COMPETITION_POPUP_TITLE: 'المعذرة',
  COMPETITION_MESSAGE: `لا يمكنك البدء بهذا السباق الآن .!
سيُتَاح قريبا بإذن الله`,
  COMPETITION_POPUP_BUTTON_TEXT: 'موافق',
}

export const SCORES_TYPOGRAPHY = {
  WEBSITE_TITLE: 'عالم زيدو - درجاتي',
  TITLE: 'درجاتي',
  CHALLANGE: 'سباق',
  NO_SCORE: 'لم تحصل على درجات بعد',
  EMPTY_MESSAGE: 'لا يوجد درجات',
}

export const CURRENT_COMPETITION_TYPOGRAPHY = {
  WEBSITE_TITLE: 'عالم زيدو - تحدى',
  TITLE: 'تحدى',
  MODAL_TITLE: 'أكملت التحدي',
  MODAL_MESSAGE: 'لقد قمت باِجتياز التحدي',
  MODAL_MESSAGE_2: 'وحصلت علي',
  MODAL_POINT: 'نقطة',
  ACCEPT: 'موافق',
}

export const CHALLENGES_TYPOGRAPHY = {
  WEBSITE_TITLE: 'عالم زيدو - تحديات',
  TITLE: 'تحديات',
  SUBTITLE: 'استدرك ما فاتك من السباقات السابقة',
  CHALLENGE: 'التحدى',
  POPUP_TITLE: 'تنبيه',
  POPUP_MESSAGE: 'لم يأت وقت السباق بعد',
  POPUP_BUTTON_TEXT: 'حسنا',
}
