const ClockSquareIcon = () => {
  return (
    <svg
      className="w-[40px] mt-1 clock_square_icon"
      // width="55"
      // height="51"
      fill="none"
      viewBox="0 0 55 51"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.6245 0.978516C55.3995 0.978516 54.8321 16.7541 54.8321 25.773C54.8321 34.792 55.4034 50.5675 28.6245 50.5675H26.7202C-0.0586271 50.5637 0.512675 34.792 0.512675 25.773C0.512675 16.7541 -0.0586271 0.978516 26.7202 0.978516H28.6245Z"
        fill="#665EBC"
      />
      <path
        d="M18.2622 9.57351C17.7118 8.75677 16.611 8.54371 15.7942 9.09412C14.2495 10.1239 12.8646 11.349 11.6751 12.7162C11.0181 13.4619 11.1069 14.5804 11.8349 15.2196C12.1722 15.5215 12.5983 15.6635 13.0067 15.6635C13.5038 15.6635 14.001 15.4504 14.3383 15.0598C15.3326 13.9235 16.4867 12.9115 17.7828 12.0415C18.5995 11.4911 18.8126 10.3902 18.2622 9.57351ZM43.6697 12.7162C42.4801 11.349 41.0952 10.1239 39.5505 9.09412C38.7338 8.54371 37.6329 8.75677 37.0825 9.57351C36.5321 10.3902 36.7452 11.4911 37.5619 12.0415C38.858 12.9115 40.0121 13.9235 41.0064 15.0598C41.3438 15.4504 41.8409 15.6635 42.338 15.6635C42.7464 15.6635 43.1725 15.5215 43.5099 15.2196C44.2378 14.5804 44.3266 13.4619 43.6697 12.7162Z"
        fill="white"
      />
      <path
        d="M27.6729 10.7988C18.8664 10.7988 11.6934 17.9719 11.6934 26.7784C11.6934 35.5849 18.8664 42.7579 27.6729 42.7579C36.4794 42.7579 43.6525 35.5849 43.6525 26.7784C43.6525 17.9719 36.4794 10.7988 27.6729 10.7988ZM32.5378 31.1106C32.1827 31.5012 31.7033 31.6965 31.2062 31.6965C30.7978 31.6965 30.3717 31.5545 30.0343 31.2527L26.5011 28.11C26.1105 27.7727 25.8974 27.2933 25.8974 26.7784V19.6764C25.8974 18.6998 26.6964 17.9009 27.6729 17.9009C28.6494 17.9009 29.4484 18.6998 29.4484 19.6764V25.9794L32.3958 28.5894C33.1237 29.2463 33.1947 30.3649 32.5378 31.1106Z"
        fill="white"
      />
    </svg>
  )
}

export default ClockSquareIcon
