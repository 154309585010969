import { AppBar, AppContent } from '@components/Layout'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import Config from '@config/index'
import { useActiveChallenge } from '@hooks/data/fetchers/competition/useActiveChallenge'
import { IActiveChallenge } from '@src/types/api.interface'
import { logEvent } from '@utils/firebase-analytics'
import { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import SVG from 'react-inlinesvg'
import { Link, useHistory } from 'react-router-dom'
import { TYPOGRAPHY } from './constants'

const characterSVG = `${Config.publicURL}/images/v3/pencil-character.svg`
const partySVG = `${Config.publicURL}/images/v3/competition-celebrate.svg`
const medalSVG = `${Config.publicURL}/images/v3/competition-medal.svg`
const listSVG = `${Config.publicURL}/images/v3/competition-list.svg`
const calendarSVG = `${Config.publicURL}/images/v3/competition-calendar.svg`
const startButtonSVG = `${Config.publicURL}/images/v3/competition-start-btn.svg`

const CompetitionHome: FC = () => {
  const history = useHistory()
  const { activeChallenge, isLoadingActiveChallenge } = useActiveChallenge()

  const activeChallengeData: IActiveChallenge = activeChallenge?.[0]
  const isParticipated = activeChallengeData?.participated

  const onClick = async () => {
    history.push({
      pathname: `/competition/challenge/${activeChallengeData?._id}`,
      state: {
        url: activeChallengeData?.url,
        title: activeChallengeData?.title,
      },
    })
  }

  useEffect(() => {
    logEvent('open_competition')
  }, [])

  return (
    <>
      <Helmet>
        <title className="competition_home_page-main-title">
          {TYPOGRAPHY.WEBSITE_TITLE}
        </title>
      </Helmet>

      {isLoadingActiveChallenge ? <AppProgressBar /> : null}

      <AppBar
        backButtonLocation="/kids-home"
        fixed
        hideToggleButton
        showKidAvatar
        showParentBackButton
      />

      <AppContent className="bg-no-repeat bg-cover bg-center bg-green-v3 hide-scroll overflow-hidden">
        <div className="competition_home_page-container w-full h-screen flex flex-col items-center justify-center">
          <div className="competition_home_page-wrapper flex text-center items-center w-full h-full justify-center text-white font-bold text-3xl mt-4">
            <div className="competition_home_page-main flex flex-col w-3/12 lg:w-2/12 items-center h-full justify-end">
              <Link
                className="competition_home_page-competition-link flex flex-col items-center pb-5 lg:pb-10"
                draggable={false}
                to="/competition/list"
              >
                <SVG
                  className="competition_home_page-calendar-svg z-10 w-12 lg:w-full"
                  src={calendarSVG}
                />
                <p className="competition_home_page-my-competition-typography text-xl lg:text-3xl">
                  {TYPOGRAPHY.MY_COMPETITIONS}
                </p>
              </Link>
            </div>

            <div className="competition_home_page-active-challenge-container flex flex-col w-10/12 h-full justify-center items-center">
              {!!activeChallenge?.length &&
              !isParticipated &&
              !isLoadingActiveChallenge ? (
                <div className="competition_home_page-active-challenge-wrapper flex flex-col w-full h-full justify-between items-center">
                  <div className="competition_home_page-active-challenge-party-svg-wrapper flex basis-5/6 flex-col relative w-full justify-center items-center">
                    <SVG
                      className="competition_home_page-active-challenge-party-svg w-full flex-grow"
                      src={partySVG}
                    />
                    <div className="competition_home_page-active-challenge-lights-svg-wrapper flex absolute max-h-[150px] lg:max-h-[650px] items-center">
                      <LightsSVG />
                    </div>

                    <img
                      alt={activeChallengeData.title}
                      className="competition_home_page-active-challenge-img z-10 w-5/12 absolute"
                      src={activeChallengeData.thumbnail}
                    />
                  </div>

                  <button
                    className="competition_home_page-active-challenge-button w-28 lg:w-48 relative flex basis-1/6"
                    onClick={onClick}
                    type="button"
                  >
                    <SVG
                      className="competition_home_page-active-challenge-start-button-svg w-28 lg:w-48"
                      src={startButtonSVG}
                    />
                  </button>
                </div>
              ) : null}

              {!isLoadingActiveChallenge &&
              (isParticipated || !activeChallenge?.length) ? (
                <div className="competition_home_page-no-active-challenge-svg-wrapper flex flex-col justify-center items-center w-full">
                  <SVG
                    className="competition_home_page-no-active-challenge-svg h-48 lg:h-96 w-full"
                    src={characterSVG}
                  />
                  <div className="competition_home_page-no-active-challenge-title-wrapper self-center bg-[#05602E] py-3 lg:pb-2 lg:pt-1 px-12 rounded-3xl border-4 border-[#A8C64D] w-9/12 xl:w-6/12">
                    <h1 className="competition_home_page-no-active-challenge-title text-2xl lg:text-4xl">
                      {isParticipated
                        ? TYPOGRAPHY.FINISHED_TODAY_COMPETITION
                        : TYPOGRAPHY.EMPTY_MESSAGE}
                    </h1>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="competition_home_page-link-wrapper flex flex-col w-3/12 lg:w-2/12 items-center h-full justify-end gap-y-5 lg:gap-y-10">
              <Link
                className="competition_home_page-link flex flex-col items-center"
                draggable={false}
                to={`/top-competitor${
                  activeChallengeData?._id ? `/${activeChallengeData?._id}` : ``
                }`}
              >
                <SVG
                  className="competition_home_page-medal-svg z-10 w-12 lg:w-full"
                  src={medalSVG}
                />
                <p className="competition_home_page-top-competitor-typography text-xl lg:text-3xl">
                  {TYPOGRAPHY.TOP_COMPETITORS}
                </p>
              </Link>

              <Link
                className="competition_home_page-score-link flex flex-col items-center pb-5 lg:pb-10"
                draggable={false}
                to="/competition/scores"
              >
                <SVG
                  className="competition_home_page-list-svg z-10 w-12 lg:w-full"
                  src={listSVG}
                />
                <p className="competition_home_page-my-scores-typography text-xl lg:text-3xl">
                  {TYPOGRAPHY.MY_SCORES}
                </p>
              </Link>
            </div>
          </div>
        </div>
      </AppContent>
    </>
  )
}

const LightsSVG = () => (
  <svg
    className="competition_home_page-light-svg w-[400px] lg:w-[825px]"
    fill="none"
    height="800"
    viewBox="0 0 825 800"
    width="825"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M261.292 19.9715L400.957 454.941L163.154 28.1102L57.2407 39.3274L399.014 459.789L25.4706 133.683L0.0507812 263.941L401.9 468.544L75.6946 409.302L90.2037 479.222L401.881 473.373L100.845 514.163L110.524 565.612L405.768 480.165L153.128 620.005L251.863 675.34L403.824 488.919L335.108 683.113L418.334 687L408.654 489.881L466.729 687L583.861 680.208L413.484 484.052L621.616 659.813L719.369 602.534L419.315 479.222L783.275 579.215L788.105 500.579L421.259 473.373L780.369 469.506L760.049 379.21L421.259 473.392L824.051 239.255L785.931 147.17L413.523 463.695L732.974 75.2879L638.665 -38L409.655 456.884C409.655 456.884 516.915 47.6586 516.915 50.5831C516.915 53.5077 427.955 18.4708 427.955 21.3761C427.955 24.2814 408.693 451.054 408.693 451.054L347.462 41.5786L261.292 19.9715Z"
      fill="url(#paint0_radial_3860_102289)"
      style={{ mixBlendMode: 'screen' }}
    />
    <defs>
      <radialGradient
        cx="0"
        cy="0"
        gradientTransform="translate(415.447 351.369) scale(330.817 330.775)"
        gradientUnits="userSpaceOnUse"
        id="paint0_radial_3860_102289"
        r="1"
      >
        <stop offset="0.05" stopColor="white" />
        <stop offset="0.429035" stopColor="#033C1D" />
        <stop offset="1" />
      </radialGradient>
    </defs>
  </svg>
)

export default CompetitionHome
