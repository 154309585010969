import GreenButton from '@components/Buttons/GreenButton'
import InputBox from '@components/InputBox'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { GtagEvents } from '@utils/gtag-events'
import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useState,
} from 'react'
import { createPortal } from 'react-dom'
import { toast } from 'react-toastify'

const MODAL_TITLE = 'تعديل كلمة المرور'
const OLD_PASSWORD = 'كلمة المرور القديمة'
const NEW_PASSWORD = 'كلمة المرور الجديدة'
const CONFIRM_NEW_PASSWORD = 'اعادة كلمة المرور الجديدة'
const ENTER_PASSWORD = 'أدخل كلمة المرور'
const NEW_PASSWORD_NOT_MATCH = 'كلمة المرور غير متطابقة'
const IS_EMPTY_ERROR = 'برجاء ادخال كلمة المرور'
const PASSWORD_CHANGED_SUCCESS = 'تم تغير كلمة المرور بنجاح'
const SUBMIT_BUTTON = 'تعديل'
const VALIDATE_USER_TITLE = 'رجاءاً ! أدخل كلمة المرور للتحقق'

interface ModalContentProps {
  onClose?: () => void
  validatingUser?: boolean
  setShowPopup?: Dispatch<SetStateAction<boolean>>
}

const ModalForm: FC<ModalContentProps> = (props) => {
  return createPortal(<ModalContent {...props} />, document.body)
}

export const ModalContent: FC<ModalContentProps> = (props) => {
  const { login, changePassword, logout } = useProfile()
  const [changePasswordObject, setChangePasswordObject] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  })

  const [password, setPassword] = useState('')
  const handleInput =
    (type: 'oldPassword' | 'newPassword' | 'confirmNewPassword' | 'password') =>
    (event: ChangeEvent<HTMLInputElement>) => {
      props.validatingUser
        ? setPassword(event.target.value)
        : setChangePasswordObject((prevState) => ({
            ...prevState,
            [type]: event.target.value,
          }))
    }

  const submitChangePassword = async () => {
    const {
      newPassword,
      confirmNewPassword,
      oldPassword: currentPassword,
    } = changePasswordObject
    try {
      if (
        !newPassword.trim().length ||
        !confirmNewPassword.trim().length ||
        !currentPassword.trim().length
      ) {
        return toast.error(IS_EMPTY_ERROR)
      }

      if (confirmNewPassword.trim() !== newPassword.trim()) {
        return toast.error(NEW_PASSWORD_NOT_MATCH)
      }

      await changePassword({
        currentPassword,
        newPassword,
      })
      toast.success(PASSWORD_CHANGED_SUCCESS)
      props.onClose()
      logout()
    } catch (e) {
      return null
    }
  }
  const { parentProfile } = useProfile()

  // eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/no-explicit-any
  const handleSuccessValidation = (profileFromResponse: any) => {
    if (
      profileFromResponse?.email &&
      profileFromResponse?.email !== 'user@anonymous.firebase'
    ) {
      props.onClose()
      props.setShowPopup(true)
    }
  }

  const doLogin = useCallback(
    (data: { username: string; password: string; isChild: boolean }) => {
      login(data).then((response) => {
        if (!response) {
          return
        }
        if (typeof response === 'string') {
          return
        }
        toast.dismiss('loginError')
        GtagEvents.login({
          level: response.level,
          type: 'password',
          parentId: response._id,
        })
        handleSuccessValidation(response)
      })
    },
    [login, handleSuccessValidation]
  )
  const handleUserValidation = async () => {
    if (!password.trim().length) {
      return toast.error(IS_EMPTY_ERROR)
    }
    doLogin({ username: parentProfile.email, password, isChild: false })
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 modal_form-wrapper">
      <div
        className="fixed inset-0 bg-black opacity-50 modal_form-cls-btn"
        onClick={props.onClose}
      />
      <div className="absolute lg:top-20 z-10 w-full max-w-md bg-white rounded-[44px] shadow-lg modal_form-abslt-cls-btn">
        <div
          className="absolute -top-4 -right-4 rounded-full w-12 h-12 cursor-pointer modal_form-cls-btn-pointer"
          onClick={props.onClose}
        >
          <img
            alt="img"
            className="w-full h-full modal_form-cls-btn-icon"
            src="images/v3/closeBtn.svg"
          />
        </div>
        <div className="m-3 modal_form-title-wrpr">
          <h2 className="text-2xl font-semibold text-center mb-4 text-[#1C349A] modal_form-title">
            {props?.validatingUser ? VALIDATE_USER_TITLE : MODAL_TITLE}
          </h2>
        </div>
        <hr className="w-full border-t modal_form-hr" />
        <div className=" mx-8 my-6 modal_form-input-container">
          {!props?.validatingUser ? (
            <>
              <div className="mb-4 modal_form-input-old-pass">
                <InputBox
                  onChange={handleInput('oldPassword')}
                  placeholder={OLD_PASSWORD}
                  value={changePasswordObject.oldPassword}
                />
              </div>

              <div className="mb-4 modal_form-input-new-pass">
                <InputBox
                  onChange={handleInput('newPassword')}
                  placeholder={NEW_PASSWORD}
                  value={changePasswordObject.newPassword}
                />
              </div>

              <div className="mb-6 modal_form-input-cnfrm-pass">
                <InputBox
                  onChange={handleInput('confirmNewPassword')}
                  placeholder={CONFIRM_NEW_PASSWORD}
                  value={changePasswordObject.confirmNewPassword}
                />
              </div>

              <div className="flex justify-center modal_form-chng-pass-btn">
                <GreenButton
                  label={SUBMIT_BUTTON}
                  onClick={submitChangePassword}
                />
              </div>
            </>
          ) : (
            <>
              <div className="mb-6 modal_form-pass">
                <InputBox
                  onChange={handleInput('password')}
                  placeholder={ENTER_PASSWORD}
                  value={password}
                />
              </div>

              <div className="flex justify-center modal_form-vldt-btn">
                <GreenButton
                  label={SUBMIT_BUTTON}
                  onClick={handleUserValidation}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ModalForm
