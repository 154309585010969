import { BlueBoxContainer } from '@components/Containers/BlueBoxContainer'
import { ContentTitle } from '@components/ContentTitle'
import { AppBar, AppContent } from '@components/Layout'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import { useAnonymous } from '@contexts/AnonymousContext/AnonymousContext'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { LoginProps } from '@contexts/ProfileContext/types/ProfileContext.types'
import { useMobileView } from '@hooks/useMobileView'
import { LOGGING_IN } from '@utils/constants'
import { logEvent } from '@utils/firebase-analytics'
import { GtagEvents } from '@utils/gtag-events'
import HelperJWT from '@utils/helpers/helper.jwt'
import HelperProfile from '@utils/helpers/helper.profile'
import React, { useCallback, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory, useParams } from 'react-router'
import { toast } from 'react-toastify'
import LoginForm from './LoginForm'
import { TYPOGRAPHY } from './constants'
import { Capacitor } from '@capacitor/core'

const isIOS = Capacitor.getPlatform() === 'ios'

const Login = () => {
  const params = useParams<{ username: string; authToken: string }>()
  const { login, setProfileType } = useProfile()
  const { logout } = useAnonymous()
  const [username, setUsername] = useState(params.username || '')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  const goToParentPage = (link: string) => {
    if (isIOS) {
      history.push('/parent', {
        type: 'local',
        link,
        from: history.location.pathname,
      })
    } else {
      history.push(link)
    }
  }

  const registerButton = (
    <div
      className="login_page-register-button"
      onClick={() => goToParentPage('/register')}
      role="button"
    >
      <div className="login_page-register-button-typography-wrapper border px-4 py-1 text-v3-green rounded-full flex justify-center items-center border-v3-green text-lg xl:text-2xl">
        <p className="login_page-register-button-typography">
          {TYPOGRAPHY.ACCOUNT_CREATE}
        </p>
      </div>
    </div>
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/no-explicit-any
  const handleRedirect = (profileFromResponse: any) => {
    if (
      profileFromResponse?.email &&
      profileFromResponse?.email !== 'user@anonymous.firebase'
    ) {
      if (profileFromResponse?.kids?.length > 0)
        return history.push('/list-kid-home')
      return history.push('/add-kid')
    }
  }

  const doLogin = useCallback(
    (data: LoginProps) => {
      setIsLoading(true)
      login(data).then((response) => {
        setIsLoading(false)
        HelperProfile.setProfileType('legacy')
        if (!response) {
          return
        }
        if (typeof response === 'string') {
          return
        }
        toast.dismiss('loginError')
        toast.success(LOGGING_IN)
        GtagEvents.login({ level: response.level, type: 'password' })
        handleRedirect(response)
      })
    },
    [handleRedirect, login]
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const emailInput = document.getElementById('email-input')
    const passwordInput = document.getElementById('password-input')

    if (event.target.name === 'username') {
      emailInput.scrollIntoView()
      setUsername(event.target.value)
    } else {
      passwordInput.scrollIntoView()
      setPassword(event.target.value)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const resetProfile = () => {
    const redirectToUrl = HelperProfile.getStoredRedirectLocation()
    HelperProfile.setStoredProfile(null)
    HelperProfile.setStoredKid(null)
    HelperProfile.removeStoredKid()
    HelperProfile.removeStoredProfile()
    HelperJWT.removeJwtToken()
    if (
      redirectToUrl &&
      typeof redirectToUrl === 'string' &&
      redirectToUrl.trim().length > 0
    ) {
      HelperProfile.setStoredRedirectLocation(redirectToUrl)
    }
    logout(false)
  }
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault()
      resetProfile()
      logEvent('login_using_email')
      doLogin({ username, password, isChild: false })
      setProfileType('parent')
    },
    [resetProfile, doLogin, username, password, setProfileType]
  )

  const isMobileView = useMobileView()

  return (
    <>
      <Helmet>
        <title className="login_page-main-title">
          {TYPOGRAPHY.WEBSITE_TITLE}
        </title>
      </Helmet>

      {isLoading ? <AppProgressBar /> : null}

      <AppBar
        backButtonLocation="/"
        fixed
        hideToggleButton
        leftComponent={registerButton}
        showParentBackButton
      />

      <AppContent
        className="bg-no-repeat bg-cover bg-center bg-v3 overflow-y-scroll h-screen hide-scroll"
        haveFooter={!isMobileView}
      >
        <ContentTitle
          noShadow
          notBold
          textClassname={isMobileView ? '' : 'text-5xl'}
          textColor={isMobileView ? 'text-white' : 'text-[#FAE266]'}
          title={isMobileView ? TYPOGRAPHY.SIGN_IN : TYPOGRAPHY.WELCOME_TITLE}
        />

        <BlueBoxContainer
          dimensionClass={
            isMobileView
              ? `mx-auto w-[475px] h-auto mt-6`
              : `mx-auto w-[522px] h-auto pt-7 pb-5 px-6 bg-[#4E64BD] rounded-[40px] mt-16`
          }
        >
          {!isMobileView ? (
            <h1 className="login_page-sign-in-typography text-white text-3xl text-center mb-12">
              {TYPOGRAPHY.SIGN_IN}
            </h1>
          ) : null}
          <div className="login_page-login-form-wrapper w-full h-auto flex justify-center content-center self-center">
            <LoginForm
              onChangePassword={handleChange}
              onChangeUsername={handleChange}
              onSubmit={handleSubmit}
              password={password}
              username={username}
            />
          </div>
        </BlueBoxContainer>
      </AppContent>
    </>
  )
}

export default Login
