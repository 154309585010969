import { IChapterListItem } from '@src/types/api.interface'
import { FC } from 'react'
import { Card } from './ActivityCard'
import Helper from '@utils/Helpers'

interface EpisodeChaptersProps {
  chapters: IChapterListItem[]
  onChapterClick: (chapter: IChapterListItem) => void
  hasProAccess?: boolean
}

const EpisodeChapters: FC<EpisodeChaptersProps> = (props) => {
  return (
    <div>
      {props.chapters.map(function (chapter, idx, arr) {
        let locked = true
        if (!props.hasProAccess) {
          locked = !chapter.isFree
        } else {
          locked = Array.isArray(arr) && idx > 0
        }

        return (
          <ChapterThumbnail
            chapterData={chapter}
            key={chapter._id}
            locked={locked}
            onChapterClick={() => props.onChapterClick(chapter)}
          />
        )
      })}
    </div>
  )
}

interface ChapterThumbnailProps {
  chapterData: IChapterListItem
  locked: boolean
  onChapterClick: () => void
}

const ChapterThumbnail: FC<ChapterThumbnailProps> = (props) => {
  const image = Helper.MediaURL(props.chapterData.thumbnail)

  return (
    <Card
      image={image}
      isLocked={props.locked}
      isWatched={props.chapterData.watched}
      onClick={props.onChapterClick}
      title={props.chapterData.title}
      type="video"
    />
  )
}

export default EpisodeChapters
