import { FC } from 'react'
import { Link } from 'react-router-dom'

const TEXT = {
  MAIN: 'الرئيسية',
}

const URL = '/list-kid'

interface BreadCrumbProps {
  title: string
}

const BreadCrumb: FC<BreadCrumbProps> = ({ title }: BreadCrumbProps) => {
  return (
    <div className="self-start mr-16 flex mt-4 gap-x-2 text-lg text-white items-center breadcrumb-container">
      <BreadcrumIcon />
      <Link
        className="flex items-center justify-center gap-x-1 cursor-pointer breadcrumb-link"
        to={URL}
      >
        <p className="text-v3-blue-200  cursor-pointer breadcrumb-text">
          {TEXT.MAIN}
        </p>
      </Link>
      <p>/</p>
      <p className="breadcrumb-title">{title}</p>
    </div>
  )
}

const BreadcrumIcon = () => {
  return (
    <svg
      className="breadcrumb-svg"
      fill="none"
      height="19"
      viewBox="0 0 19 19"
      width="19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33263 18.8496H10.3236C13.4255 18.8496 14.9764 18.8496 16.034 17.939C17.0915 17.0284 17.3109 15.504 17.7496 12.4553L18.0004 10.7117C18.342 8.33833 18.5127 7.15167 18.0263 6.13706C17.5399 5.12245 16.5045 4.50572 14.4339 3.27225L13.1875 2.52979C11.3072 1.40967 10.367 0.849609 9.32812 0.849609C8.28925 0.849609 7.34906 1.40967 5.4687 2.52979L4.22234 3.27224C2.15169 4.50572 1.11637 5.12245 0.629955 6.13706C0.143541 7.15167 0.314289 8.33833 0.655785 10.7117L0.906678 12.4553C1.34535 15.504 1.56469 17.0284 2.62225 17.939C3.6798 18.8496 5.23076 18.8496 8.33263 18.8496Z"
        fill="#4FC4F9"
      />
      <path
        d="M7.0311 12.9062C6.7316 12.6842 6.30887 12.7471 6.08687 13.0465C5.86488 13.346 5.92771 13.7687 6.22719 13.9908C7.10237 14.6395 8.17269 15.0235 9.3291 15.0235C10.4856 15.0235 11.5559 14.6395 12.4311 13.9908C12.7306 13.7687 12.7934 13.346 12.5714 13.0465C12.3494 12.7471 11.9267 12.6842 11.6272 12.9062C10.9717 13.3921 10.1804 13.6735 9.3291 13.6735C8.47779 13.6735 7.6866 13.3921 7.0311 12.9062Z"
        fill="white"
      />
    </svg>
  )
}

export default BreadCrumb
