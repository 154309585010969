import { useCallback, useEffect, useState } from 'react'
import { Profile } from '@src/types/api.interface'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { isFetchError } from '@utils/FetchError'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { useHistory, useParams } from 'react-router-dom'
import { logEvent } from '@utils/firebase-analytics'
import HelperAPI from '@utils/helpers/helper.api'
import HelperProfile from '@utils/helpers/helper.profile'
import { GtagEvents } from '@utils/gtag-events'

interface Data {
  gender: string
  username: string
  nickname: string
  dateOfBirth: string
  parent?: string
  path?: string
  hobbies?: string[]
  hoursUsingPhone?: number
  edugoal?: string
}
export function useStoreKid() {
  const [createLoading, setCreateLoaded] = useState(false)
  const [dataBody, setDataBody] = useState<Data>()
  const [newKid, setNewKid] = useState<string>()
  const { parentProfile, setParentProfile: setProfile, logout } = useProfile()
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const callApi = useCallback(
    async (data: Data | undefined) => {
      if (data) {
        try {
          setCreateLoaded(true)
          const response = await HelperAPI.ApiRequest<Profile>('/kids', {
            method: id ? 'PUT' : 'POST',
            body: JSON.stringify(data),
          })

          setNewKid(response.nickname)
          if (!id) {
            // GtagEvents.createChild({
            //   level: response.level,
            //   creationDate: Date().toString(),
            //   parentId: parentProfile._id,
            //   childId: response._id,
            // })
            if (parentProfile.kids.length === 0) {
              logEvent('add_first_kid')
            }
            logEvent('add_kid')
            parentProfile.kids.push(response)
          } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            parentProfile.kids = parentProfile.kids.map((e): any => {
              if (e._id === id) {
                return { ...e, ...response }
              }
              return e
            })
            toast.success('تم تحديث بيانات الطفل بنجاح.', {
              toastId: 'updateUser',
            })
            GtagEvents.editChild({
              level: response.level,
              creationDate: Date().toString(),
              parentId: parentProfile._id,
              childId: response._id,
            })
          }
          setProfile(parentProfile)
          HelperProfile.setStoredProfile(parentProfile)
          setCreateLoaded(false)
          // await refreshProfile(true)
        } catch (error) {
          if (isFetchError(error)) {
            if (error.status && error.status === 401) {
              logout()
              history.replace('/')
            }
            if (error.data && error.data !== '') {
              // eslint-disable-next-line array-callback-return
              Object.values(error.data).map((message) => {
                if (
                  message &&
                  typeof message === 'string' &&
                  message.toLowerCase().includes('username') &&
                  message.toLowerCase().includes('dup')
                ) {
                  // allow multiple errors per user multiple actions, i.e pressing submit button multiple times
                  toast.info('لديك طفل مسجل بالفعل تحت حسابك بنفس الاسم!', {
                    type: 'error',
                  })
                } else {
                  toast.info(message as string, { type: 'error' })
                }
              })
            }
            const msg = error.message
            if (
              msg &&
              typeof msg === 'string' &&
              msg.toLowerCase().includes('username') &&
              msg.toLowerCase().includes('dup')
            ) {
              return 'لديك طفل مسجل بالفعل تحت حسابك بنفس الاسم!'
            } else {
              return msg
            }
          }
          return 'حدث خطأ'
        } finally {
          setCreateLoaded(false)
          toast.dismiss('addEditKids')
        }
      }
    },
    [id, setProfile, parentProfile, logout, history]
  )

  useEffect(() => {
    callApi(dataBody)
  }, [callApi, dataBody])

  return {
    newKid,
    setDataBody,
    createLoading,
  }
}
