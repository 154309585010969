import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
  FC,
} from 'react'
import { Link } from 'react-router-dom'
import { IPublicationListItem } from '@src/types/api.interface'
import { ContentTitle } from '@components/ContentTitle'
import { AppBar, AppContent } from '@components/Layout'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import Config from '@config/index'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import Helper from '@utils/Helpers'
import { OrangeButton } from '@components/Buttons'
import { usePublications } from '@hooks/data/fetchers/use-publications'
import { Helmet } from 'react-helmet-async'
const fAvatar = `${Config.publicURL}/images/avatars/f_avatar.png`
const mAvatar = `${Config.publicURL}/images/avatars/m_avatar.png`

const localeInfo = {
  // Pagination.jsx
  prev_page: 'الصفحة السابقة',
  next_page: 'الصفحة التالية',
}

const Gallery: FC = () => {
  const { kidProfile: profile, profileType } = useProfile()
  const { publications } = usePublications()

  const [filter, setFilter] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [startItem, setStartItem] = useState(0)

  const pageSize = Config.galleryConfig.itemsPerPage

  const btnTitle = filter ? 'جميع الإنجازات' : 'إنجازاتي'

  const onClick = useCallback(() => {
    setFilter((filter) => !filter)
    setCurrentPage(1)
    setStartItem(0)
  }, [])

  const hasLoaded = !!publications
  useEffect(() => {
    if (!hasLoaded) return
    Helper.accessLog('publications', null, 'gallery page', true)
  }, [hasLoaded])

  const onChangePage = useCallback((page: number, pageSize: number) => {
    setCurrentPage(Number(page))
    setStartItem((Number(page) - 1) * pageSize)
  }, [])

  const filteredPublications = useMemo(() => {
    if (!profile?._id) return
    if (!publications) return

    return publications
      .filter((publication) => {
        if (!publication.confirmed) return false
        if (filter && publication.kid._id !== profile._id) return false
        return true
      })
      .slice(startItem, startItem + pageSize)
  }, [publications, filter, profile?._id, startItem, pageSize])

  if (profileType !== 'child') return <p>Unauthorized</p>
  return (
    <>
      <Helmet>
        <title>عالم زيدو - المعرض</title>
      </Helmet>
      {!filteredPublications ? <AppProgressBar /> : null}
      <AppBar
        fixed
        showBackButton
        showHomeLink
        showLogoutButton
        showProfileButton
        title="المعرض"
        titleCenter
      />

      <AppContent className="bg-[#ffe4b8] bg-gallery pb-[100px] pages_gallery_appcontent_container">
        <ContentTitle
          className="pages_gallery_appcontent_contenttitle"
          noShadow
          textColor="text-[#4a4a4a]"
          title="المعرض"
        />

        <div className="py-4 px-4 sm:px-16 pages_gallery_appcontent_child">
          <OrangeButton
            className="mr-auto block pages_gallery_appcontent_orange_button"
            label={btnTitle}
            onClick={onClick}
          />

          <ul className="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 sm:gap-6 md:gap-8 pages_gallery_appcontent_ul_container">
            {filteredPublications?.map(function (publication) {
              return (
                <li
                  className="animate-fadein flex items-center justify-center pages_gallery_appcontent_li_container"
                  key={publication._id}
                >
                  <PublicationThumbnail data={publication} />
                </li>
              )
            })}
          </ul>
          <div className="flex justify-center my-6 pages_gallery_appcontent_filtered_container">
            {filteredPublications ? (
              <Pagination
                className="pages_gallery_appcontent_pagination_container"
                current={currentPage}
                hideOnSinglePage
                itemRender={textItemRender}
                locale={localeInfo}
                onChange={onChangePage}
                pageSize={pageSize}
                total={publications?.length}
              />
            ) : null}
          </div>
        </div>
      </AppContent>
    </>
  )
}

export default Gallery

function textItemRender(
  page: number,
  type: string,
  element: ReactNode
): ReactNode {
  if (type === 'prev') {
    return <PaginationButton label="السابق" />
  }
  if (type === 'next') {
    return <PaginationButton label="التالي" />
  }
  return element
}

const PaginationButton: FC<{ label: string }> = (props) => {
  return <span className="font-body">{props.label}</span>
}

const PublicationThumbnail: FC<{ data: IPublicationListItem }> = (props) => {
  const avatarImg = props.data.kid.gender === 'female' ? fAvatar : mAvatar
  const url = '/gallery/publications/' + props.data._id

  const kidName = `${props.data.kid.firstName ?? ''} ${
    props.data.kid.middleName ?? ''
  } ${props.data.kid.lastName ?? ''}`

  const owner = props.data.kid.nickname ?? kidName
  return (
    <Link
      className="flex flex-col items-center pages_gallery_link_container"
      to={url}
    >
      <img
        alt=""
        className="mb-2 outline border-[5px] pages_gallery_link_img outline-[5px] border-[#713825] outline-[#874c38]"
        draggable="false"
        src={Helper.MediaURL(props.data.thumbnail)}
      />
      <div className="w-full pr-4 mt-1">
        <div className="relative py-1 border bg-[#907c28] text-center border-gray-500 rounded-full text-white text-lg pages_gallery_link_user_container">
          <span className="pages_gallery_link_user_text">{owner}</span>
          <img
            alt=""
            className="pages_gallery_link_user_img absolute top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2 rounded-full inline-block w-12 h-12"
            src={avatarImg}
          />
        </div>
      </div>
    </Link>
  )
}
