import { useEffect, useState, FC } from 'react'
import { IQuestionWithGame } from '@src/types/api.interface'
import { ChallengeReducers } from '@hooks/use-challenge-reducer'
import { GameIFrame } from '../GameIframe'
import { QuestionGoNext } from './challenge-controls'
import { logEvent } from '@utils/firebase-analytics'

export const ChallengeWithGame: FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  question: IQuestionWithGame | any
  challengeReducers: ChallengeReducers
  gameNodeId: string
}> = (props) => {
  const [hasAnswered, setHasAnswered] = useState(false)

  const onSubmit = props.challengeReducers.onSubmit

  useEffect(() => {
    if (hasAnswered) {
      logEvent('finish_daily_competition')
      return
    }
    setHasAnswered(false)
  }, [props.question.gameUrl, hasAnswered])

  useEffect(() => {
    function onMessage(message: MessageEvent<{ score: number }>) {
      if (!props.question?.gameUrl?.startsWith(message.origin)) {
        return
      }

      if ('score' in message.data) {
        const score = Number(message.data.score)
        if (isNaN(score)) return

        onSubmit({ score })
        setHasAnswered(true)
      }
    }

    window.addEventListener('message', onMessage)

    return () => {
      window.removeEventListener('message', onMessage)
    }
  }, [onSubmit, props.question.gameUrl])

  return (
    <div className="pt-16 component_challenges_chalengewithgame_container">
      <div className="flex flex-col max-w-[1024px] mx-auto">
        <div className="h-[500px] max-h-screen p-6">
          <GameIFrame
            apiUrl=""
            episode={{
              slug: props?.question.gameUrl ?? 'game_activity',
              title: props?.gameNodeId ?? 'game_activity',
              series: { title: 'game_activity' },
            }}
            from="challenge-with-game"
            gameId=""
            iframeContainerId={props.gameNodeId}
            iframeProps={{
              src: props.question.gameUrl,
              frameBorder: '0',
              allowFullScreen: true,
              width: '100%',
              height: '100%',
            }}
            token=""
          />
        </div>
      </div>

      <div className="flex-1 mb-4 component_challenges_chalengewithgame_no_answer_go_next">
        <div className="flex justify-center component_challenges_chalengewithgame_no_answer_child">
          <QuestionGoNext
            disabled={!hasAnswered}
            onClick={props.challengeReducers.goNext}
          />
        </div>
      </div>
    </div>
  )
}
