import useSwr from 'swr'
import { IPublicationDetail } from '@src/types/api.interface'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import { FetchError } from '@utils/FetchError'

export function usePublication(props: { id: string }): {
  publication?: IPublicationDetail
  error?: FetchError
  isLoadingPublication: boolean
} {
  const { kidProfile: profile } = useProfile()

  const url = profile?.level
    ? `/level/${profile.level}/publications/${props.id}`
    : null

  const { data, error } = useSwr<IPublicationDetail, FetchError>(url)

  return {
    publication: data,
    error,
    isLoadingPublication: !error && !data,
  }
}
