import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { FlyingIpad } from '@components/Images/FlyingIpad'
import { AppContent } from '@components/Layout'

const NotFound: FC = () => {
  return (
    <>
      <Helmet>
        <title>عالم زيدو - محتوى غير موجود</title>
      </Helmet>

      <AppContent className="bg-theatre pages_notfound_appcontent">
        <div className="w-full h-full flex flex-col items-center justify-center">
          <h1 className="mt-4 text-3xl pages_notfound_text">محتوى غير موجود</h1>
          <div className="mt-4 pages_notfound_svg_container">
            <FlyingIpad />
          </div>
        </div>
      </AppContent>
    </>
  )
}

export default NotFound
