import React, { FC, ReactNode } from 'react'

const InputMobileContainer: FC<{
  title: string
  children: ReactNode
  icon?: JSX.Element
  bg?: string
}> = ({ title, icon, children, bg }) => {
  return (
    <div
      className={`border rounded-3xl flex flex-col input_mobile_container ${
        bg || 'bg-[#D1E9F9]'
      } py-2 w-[290px] lg:w-full lg:px-4`}
    >
      <div className="flex gap-x-2 mx-2 items-start input_mobile_container-icon">
        {icon}
        <p className="text-2xl font-extralight text-[#2C2D30] lg:w-full input_mobile_container-title">
          {title}
        </p>
      </div>
      <hr className="border border-dashed border-black opacity-20 w-full my-2 input_mobile_container-hr" />
      {children}
    </div>
  )
}

export default InputMobileContainer
