import { INotification } from '@src/types/api.interface'
import { FetchError } from '@utils/FetchError'
import useSWR from 'swr'

export const useNotificatiion = () => {
  const url = '/notifications/me'

  const { data, error } = useSWR<INotification[], FetchError>(url)

  return {
    notifications: data,
    error,
    isLoadingNotification: !error && !data,
  }
}
