import { useMemo } from 'react'
import useSWR from 'swr'
import { IDailyMeeting } from '@src/types/api.interface'
import { FetchError } from '@utils/FetchError'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'

export function useDailyMeeting(): {
  dailyMeeting?: IDailyMeeting
  error?: FetchError
  isLoadingDailyMeeting: boolean
} {
  const { kidProfile: profile } = useProfile()
  const url = `/maqraa/${profile._id}/daily-meeting`

  const { data, error } = useSWR<IDailyMeeting, FetchError>(url, {
    shouldRetryOnError: false,
  })

  return useMemo(
    () => ({
      dailyMeeting: data,
      error,
      isLoadingDailyMeeting: !data && !error,
    }),
    [data, error]
  )
}
