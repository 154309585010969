import { FetchError } from '@utils/FetchError'
import useSwr from 'swr'

interface IIpLocation {
  countryInfo: ICountryInfo
}
interface ICountryInfo {
  countryName: string
  symbol: string
  iso: string
}

export function useIpLocation(): {
  countryInfo: ICountryInfo
  error?: FetchError
  isLoadingIpLocation: boolean
} {
  const url = `/location`

  const { data, error } = useSwr<IIpLocation, FetchError>(url)

  return {
    countryInfo: data?.countryInfo,
    error,
    isLoadingIpLocation: !error && !data,
  }
}
