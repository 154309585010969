import dayjs from 'dayjs'
import 'dayjs/locale/ar'

dayjs.locale('ar')

export function formatScheduleDate(
  date: number | string,
  hour12?: boolean
): string {
  const d = new Date(date)

  return d.toLocaleString('ar', {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    hour12: hour12 ?? false,
    minute: '2-digit',
  })
}

export function getDaysRemaining(dateString: string): number {
  const unlockingTimeDate = dayjs(dateString)
  const daysRemaining = unlockingTimeDate.diff(dayjs(), 'day')
  return daysRemaining
}

export const dateTimeFormatNotification = (dateString: string): string => {
  const now = dayjs()
  const notificationDate = dayjs(dateString)
  const diffInMinutes = now.diff(notificationDate, 'minute')
  const diffInHours = now.diff(notificationDate, 'hour')

  if (now.isSame(notificationDate, 'day')) {
    if (diffInMinutes < 1) {
      return 'الآن'
    } else if (diffInMinutes < 60) {
      return `منذ ${diffInMinutes} دقيقة`
    } else if (diffInHours < 24) {
      const minutes = diffInMinutes % 60
      const hours = Math.floor(diffInMinutes / 60)
      const minutesString = minutes > 0 ? `${minutes} دقيقة` : ''
      return `منذ ${hours} ساعة ${minutesString}`
    }
  }

  const result = formatScheduleDate(dateString, true)

  const [weekday, day, month, _, time, suffix] = result.split(' ')

  return `${weekday} ${day} ${month} ${time} ${suffix}`
}
