/* eslint-disable jsx-a11y/alt-text */
import { ProgressBar, Step } from '@components/StepProgressBar'
import SVG from 'react-inlinesvg'
import Config from '@config/index'
import { FC } from 'react'

const greenTickSVG = `${Config.publicURL}/images/v3/greenTickBtn.svg`

interface IconProps {
  accomplished: boolean
}

const GreenTickIcon = () => (
  <div className="step_progress_bar_page-green-tick-icon-wrapper flex justify-center items-center relative h-8">
    <span className="step_progress_bar_page-green-tick-icon-span absolute w-4 h-4 bg-white rounded-full z-0" />
    <SVG
      className="step_progress_bar_page-green-tick-icon-svg w-8 h-8 z-10"
      src={greenTickSVG}
    />
  </div>
)

const Icon: FC<IconProps> = ({ accomplished }: IconProps) => {
  const isGrayScale = { filter: `grayscale(${accomplished ? 0 : 100}%)` }
  return (
    <div
      className="step_progress_bar_page-icon-wrapper flex justify-center items-center relative h-8"
      style={isGrayScale}
    >
      {accomplished ? <GreenTickIcon /> : null}
    </div>
  )
}

interface StepProgressBarProps {
  steps: string[]
  percent: number
}

const StepProgressBar = ({ steps, percent }: StepProgressBarProps) => {
  return (
    <div className="step_progress_bar_page-progressbar-wrapper">
      <ProgressBar
        filledBackground="linear-gradient(to left, #263D9A, #4FC4F9, #96B929)"
        hasStepZero={false}
        percent={percent}
        unfilledBackground="#00000036"
      >
        {steps.map((step, index) => {
          return (
            <Step index={index} key={step} transition="scale">
              {({ accomplished }) => (
                <div
                  className={`step_progress_bar_page-step-${step} flex flex-col flex-1 mt-5`}
                >
                  <div
                    className={`step_progress_bar_page-step-${step}-icon-wrapper -mt-5`}
                  >
                    <Icon accomplished={accomplished ? percent > 0 : null} />
                  </div>
                  <p
                    className={`step_progress_bar_page-step-text-${step} pt-2 text break-words absolute top-8 text-center`}
                  >
                    {step}
                  </p>
                </div>
              )}
            </Step>
          )
        })}
      </ProgressBar>
    </div>
  )
}

export default StepProgressBar
