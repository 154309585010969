import { ReactNode } from 'react'
import { Keyboard, Mousewheel, Navigation, Pagination } from 'swiper'
import { Swiper } from 'swiper/react'

const KidCardsSlider = ({
  children,
  pagination,
  slidesPerView,
  loop,
  centeredSlides,
  visibilityFullFit,
  autoResize,
  spaceBetween,
}: {
  children?: ReactNode
  isSingleChild?: boolean
  centerInsufficientSlides?: boolean
  pagination?: boolean
  slidesPerView?: number
  loop?: boolean
  centeredSlides?: boolean
  visibilityFullFit?: boolean
  autoResize?: boolean
  spaceBetween?: number
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const generalSettings: any = {
    spaceBetween,
    centeredSlides,
    slidesPerView,
    visibilityFullFit,
    autoResize,
    loop,
    pagination,
    keyboard: true,
    mousewheel: true,
    grabCursor: false,
    navigation: {
      nextEl: '.swiper-button-prev',
      prevEl: '.swiper-button-next',
    },
    modules: [Pagination, Navigation, Keyboard, Mousewheel],
  }

  return (
    <div className="h-full relative container  mx-auto component_layout_kid_Card_slider_swiper_Container">
      <Swiper
        className="component_layout_kid_Card_swiper_tag"
        spaceBetween={spaceBetween}
        {...generalSettings}
        centerInsufficientSlides
        updateOnWindowResize
      >
        {children}
      </Swiper>
      <div className="swiper-button-next -right-2 lg:right-6 xl:right-1.5  w-8 h-9 lg:h-16 lg:w-14" />
      <div className="swiper-button-prev -left-2 lg:left-6 xl:left-1.5  w-8 h-9 lg:h-16 lg:w-14" />
    </div>
  )
}

export default KidCardsSlider
