export const TYPOGRAPHY = {
  WEBSITE_TITLE: 'عالم زيدو - إنشاء حساب',
  NEW_USER: 'مستخدم جديد',
  EMAIL_PLACEHOLDER: 'البريد الإلكتروني',
  PASSWORD_PLACEHOLDER: 'كلمة المرور',
  I_AGREE_TO: 'أوافق على',
  TERMS_OF_USE: 'شروط الاستخدام',
  PRIVACY_POLICY: 'سياسية الخصوصية',
  AND: 'و',
  OR: 'او',
  CREATE_ACCOUNT: 'أنشىء حساب',
  ACCOUNT_CREATE: 'إنشاء حساب',
  HAVE_ACCOUNT: 'لديك حساب',
  SIGN_IN: 'تسجيل دخول',
  WELCOME_TITLE: 'مرحباً بك في عالم زيدو',
}
