/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoginType } from '@src/types/api.interface'
import { sendGaToFirebase } from './sendGaToFirebase'

export function login(props: {
  level: number
  type: LoginType
  parentId?: string
}) {
  const event: any = {
    event: 'login',
    eventCategory: 'Login',
    eventAction:
      props.type === 'password' ? `Login by password` : 'Login by token',
    eventLabel: `Level ${props.level}`,
    data: {
      parentId: props.parentId ?? '',
    },
  }
  window.dataLayer?.push(event)
  sendGaToFirebase(event)
}

export function register(props: {
  level: number
  type: LoginType
  parentId: string
}) {
  const event: any = {
    event: 'registeration',
    eventCategory: 'Registration',
    data: {
      parentId: props.parentId,
    },
    eventAction:
      props.type === 'password' ? `Login by password` : 'Login by token',
    eventLabel: `Level ${props.level}`,
  }
  window.dataLayer?.push(event)
  sendGaToFirebase(event)
}

export function createChild({
  level,
  parentId,
  childId,
}: {
  level: string | number
  creationDate: string
  parentId: string
  childId: string
}) {
  const event: any = {
    event: 'child_creation',
    eventCategory: 'Kids',
    eventAction: 'child creation',
    data: {
      level,
      date: new Date().toUTCString(),
      parentId,
      childId,
    },

    eventLabel: `child creation`,
  }
  window.dataLayer?.push(event)
  sendGaToFirebase(event)
}

export function editChild({
  level,
  parentId,
  childId,
}: {
  level: string | number
  creationDate: string
  parentId: string
  childId: string
}) {
  const event: any = {
    event: 'child_edit',
    eventCategory: 'Kids',
    eventAction: 'child edit',
    data: {
      level,
      date: new Date().toUTCString(),
      parentId,
      childId,
    },

    eventLabel: `child edit`,
  }
  window.dataLayer?.push(event)
  sendGaToFirebase(event)
}
