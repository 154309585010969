import Config from '@config/index'

export const CHOOSE_PACKAGE_TEXT = {
  WEBSITE_TITLE: 'عالم زيدو - الباقات',
  TITLE: 'أطلق العنان لخيال أطفالك من خلال عالم زيدو ',
  SUBTITLE:
    'استمتع بوصولهم إلى كافة أنواع المحتوى الحصري التي نقدمها من خلال الإشتراك. ',
  RECOMMENDED: 'يوصى به',
  SUBSCRIBE: 'اشترك الان',
  DISCOUNT: 'خصم',
  HEADER_TITLE: 'الاشتراك',
  DOWNLOAD_NOW: 'حمل التطبيق الان',
}

export const CHOOSE_PACKAGE_FEATURES = [
  {
    id: '1',
    title: 'القصص',
    icon: `${Config.publicURL}/images/v3/packages-stories.svg`,
  },
  {
    id: '2',
    title: 'الالعاب',
    icon: `${Config.publicURL}/images/v3/packages-games.svg`,
  },
  {
    id: '3',
    title: 'الكتب التفاعلية',
    icon: `${Config.publicURL}/images/v3/packages-interactive-books.svg`,
  },
  {
    id: '4',
    title: 'الانشطة و المسابقات',
    icon: `${Config.publicURL}/images/v3/packages-activities-competitions.svg`,
  },
  {
    id: '5',
    title: 'الرسوم المتحركة',
    icon: `${Config.publicURL}/images/v3/packages-animation-movies.svg`,
  },
  {
    id: '6',
    title: 'الاناشيد',
    icon: `${Config.publicURL}/images/v3/packages-anasheed.svg`,
  },
]

export const CHOOSE_PACKAGE_IMAGES = {
  cardBackground: `${Config.publicURL}/images/v3/package-highlight.svg`,
  cardBackgroundLight: `${Config.publicURL}/images/v3/package-highlight-light.svg`,
  cardCheckGreen: `${Config.publicURL}/images/v3/packages-check-green.svg`,
  cardCheckBlue: `${Config.publicURL}/images/v3/packages-check-blue.svg`,
}

export const CHOOSE_PACKAGE_BENEFITS = [
  'وصول إلى كافة أنواع المحتوى الحصري',
  'تقارير لحظية عن تقدم طفلك',
  'دليل المُربي لكل وحدة تربوية',
  'آمن 100% ولا يحتوى على إعلانات',
  'متوفر على الويب وعلى الهاتف',
]
