import React, { FC, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { AppContent } from '@components/Layout'
import 'animate.css'

import 'react-toastify/dist/ReactToastify.css'

import { useMobileView } from '@hooks/useMobileView'
import VideoPlayer from '@components/videoplayer'
import 'swiper/css'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Link, useHistory } from 'react-router-dom'
import { MESSAGE_LOGIN, MESSAGE_REGISTER } from '@src/constants'
import { Capacitor } from '@capacitor/core'
import { GtagEvents } from '@utils/gtag-events'

const VIDEO_DATA = [
  {
    title: 'التعريف بمنهج عالم زيدو ؟',
    url: 'https://files.zadapps.info/zidoworld.com/streams/intro-video/intro-video-1.mp4',
    bg: 'https://assets.zidoworld.com/prod/intro/intro-bg-1.png',
  },
  {
    title: 'كيف استفيد من عالم زيدو ؟',
    url: 'https://files.zadapps.info/zidoworld.com/streams/intro-video/intro-video-2.mp4',
    bg: 'https://assets.zidoworld.com/prod/intro/intro-bg-2.png',
  },
  {
    title: 'كيف اشترك في عالم زيدو ؟',
    url: '',
    bg: '',
  },
]

const isIOS = Capacitor.getPlatform() === 'ios'

const Home: FC = () => {
  // TODO: find out what can be put instead of type any for element that are being rendered
  const logoContainer = useRef(null)

  const swiperRef = useRef(null)

  const isMovileView = useMobileView()
  const [selectedVideoIndex, setSelectedVideoIndex] = useState(0)
  const history = useHistory()

  useEffect(() => {
    // act as splash
    setTimeout(() => {
      if (logoContainer.current) {
        logoContainer.current?.classList.remove('hidden')
        logoContainer.current?.classList.add('animate__animated')
      }

      setTimeout(() => {
        // buttonContainer.current.classList.remove('hidden')
        // buttonContainer.current.classList.add('inline-flex')
        logoContainer.current?.classList.add('animate__fadeInUp')
        logoContainer.current?.classList.add('animate__animated')
        // [(parentButton, kidButton)].map((s, i) => {
        //   setTimeout(() => {
        //     s.current.classList.remove('opacity-0')
        //     s.current.classList.add('inline-flex')
        //     s.current.classList.add('animate__animated')
        //     s.current.classList.add('animate__bounceIn')
        //   }, 500 * (i + 1))
        // })
      }, 1000)
    }, 1000)
    GtagEvents.customEvent({ event: 'Intropage_initiation' })
  }, [logoContainer])

  const goToParentPage = (link: string) => {
    GtagEvents.customEvent({ event: 'Intropage_regstart' })
    setTimeout(() => {
      if (isIOS) {
        history.push('/parent', {
          type: 'local',
          link,
          from: history.location.pathname,
        })
      } else {
        history.push(link)
      }
    }, 1000)
  }

  return (
    <>
      <Helmet>
        <title>عالم زيدو - الصفحة الرئيسية</title>
      </Helmet>
      <AppContent
        className="bg-no-repeat bg-cover bg-v3 pages_home_appcontent"
        haveFooter={!isMovileView}
      >
        <div className="flex flex-col pb-3  px-6 lg:px-12 xl:px-16 pages_home_container">
          <div className="flex w-full pages_home_typography justify-end mt-4 lg:mt-6 xl:ml-14 gap-3 lg:gap-4 ">
            <div
              className="cursor-pointer pages_home_typography_register bg-v3-green rounded-3xl text-lg lg:text-xl text-center text-[#2B3C94] hover:text-white px-[16px] md:px-[22px] py-[8px] lg:px-[40px] lg:py-[11px] shadow-[3px_3px_0px_0px_#5E7426]"
              onClick={() => goToParentPage('/register')}
            >
              {MESSAGE_REGISTER}
            </div>

            <Link
              className="cursor-pointer border pages_home_typography_login hover:text-white hover:border-white border-v3-green rounded-3xl text-lg lg:text-xl text-center text-v3-green px-[16px] py-[8px] lg:px-[22px] lg:py-[11px]"
              to="/login"
            >
              {MESSAGE_LOGIN}
            </Link>
          </div>

          {isMovileView ? (
            <div className="w-full flex-col pages_home_mobile_swiper_container items-center justify-center mt-5">
              <Swiper
                className="pages_home_mobile_swiper"
                loop
                onSlideChange={(swiper) => {
                  setSelectedVideoIndex(swiper.realIndex)
                }}
                ref={swiperRef}
                slidesPerView={1}
                watchSlidesProgress
                // centeredSlides
              >
                {VIDEO_DATA.filter((video) => video?.url).map(
                  (video, index) => {
                    return (
                      <SwiperSlide
                        className="self-center pages_home_mobile_swiper_slide"
                        key={index}
                      >
                        <div className="h-[65vh] md:h-[70vh] min-w-[65vw] rounded-2xl border-[#6579C7] border-4 overflow-hidden">
                          <VideoPlayer
                            autoplay={false}
                            className="pages_home_mobile_swiper_video_player"
                            episode={video}
                            from="home"
                            playingVideo={selectedVideoIndex === index}
                            src={video?.url ?? VIDEO_DATA[1]?.url}
                            thumbnail={video.bg}
                          />
                        </div>
                      </SwiperSlide>
                    )
                  }
                )}
              </Swiper>
              <div className="flex w-full pages_home_videodata_container gap-x-2 justify-center items-center mt-3">
                {VIDEO_DATA.filter((video) => video?.url).map(
                  (video, index) => {
                    const isSelected = selectedVideoIndex === index
                    return (
                      <div
                        className={` rounded-full pages_home_selected_video_child ${
                          isSelected
                            ? 'w-4 h-4 bg-v3-green'
                            : 'w-2 h-2 bg-[#B9B9B9]'
                        } `}
                        key={index}
                      />
                    )
                  }
                )}
              </div>
            </div>
          ) : (
            // Web Component
            <div className="flex h-[75vh] pages_home_web_container flex-shrink-0 w-full gap-x-10 justify-center xl:justify-between items-center mt-5">
              <div className=" w-[75%] h-[75vh] flex items-center justify-center ">
                <div className="h-full w-[95%] pages_home_web_video_container xl:w-full my-3 rounded-2xl border-4 border-[#6579C7] overflow-hidden">
                  <VideoPlayer
                    autoplay={false}
                    className="pages_home_web_video"
                    episode={VIDEO_DATA}
                    from="home"
                    src={
                      VIDEO_DATA[selectedVideoIndex]?.url ?? VIDEO_DATA[0]?.url
                    }
                    thumbnail={VIDEO_DATA[selectedVideoIndex]?.bg}
                  />
                </div>
              </div>

              <div className="w-[25%] max-w-[250px] pages_home_web_videodata_container xl:max-w-none h-full flex flex-col items-center justify-between my-2">
                {VIDEO_DATA.map((video, index) => {
                  const isSelected = index === selectedVideoIndex
                  return (
                    <div
                      className={`${
                        video?.url ? 'cursor-pointer' : 'opacity-40'
                      } text-white w-full bg-black h-[31%] pages_home_web_videodata_selected_container text-xl text-center flex items-center justify-center rounded-xl border-4 bg-cover bg-center bg-no-repeat ${
                        isSelected ? 'border-[#9CCDF7]' : 'border-none'
                      }`}
                      key={video.title}
                      onClick={() => {
                        if (video?.url) {
                          setSelectedVideoIndex(index)
                        }
                      }}
                      style={{ backgroundImage: `url(${video?.bg})` }}
                    >
                      {!video.url ? video.title : null}
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </AppContent>
    </>
  )
}

export default Home
