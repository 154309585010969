import { useEffect } from 'react'

interface MouseEvent {
  pageX: number
  preventDefault: () => void
}

const useDraggableSlider = (): void => {
  useEffect(() => {
    let mouseDown = false
    let startX: number, scrollLeft: number
    const slider = document.querySelector<HTMLDivElement>(
      '#draggable-container'
    )

    const startDragging = (e: MouseEvent) => {
      mouseDown = true
      startX = e.pageX - (slider?.offsetLeft || 0)
      scrollLeft = slider?.scrollLeft || 0
    }

    const stopDragging = () => {
      mouseDown = false
    }

    const move = (e: MouseEvent) => {
      e.preventDefault()
      if (!mouseDown || !slider) {
        return
      }
      const x = e.pageX - (slider.offsetLeft || 0)
      const scroll = x - startX
      slider.scrollLeft = scrollLeft - scroll
    }

    if (slider) {
      slider.addEventListener('mousemove', move, false)
      slider.addEventListener('mousedown', startDragging, false)
      slider.addEventListener('mouseup', stopDragging, false)
      slider.addEventListener('mouseleave', stopDragging, false)
    }

    return () => {
      if (slider) {
        slider.removeEventListener('mousemove', move, false)
        slider.removeEventListener('mousedown', startDragging, false)
        slider.removeEventListener('mouseup', stopDragging, false)
        slider.removeEventListener('mouseleave', stopDragging, false)
      }
    }
  }, [])
}

export default useDraggableSlider
