import { ToggleButtonProps } from './type'

const useToggleButton = (props: ToggleButtonProps) => {
  const { toggleFirst, toggleSecond, value, onChange } = props
  const handleClick = (newValue: string) => {
    onChange(newValue)
  }
  return {
    handleClick,
    toggleFirst,
    toggleSecond,
    value,
    onChange,
  }
}

export default useToggleButton
