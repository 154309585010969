import { MouseEvent } from 'react'
import { IContentModalProps } from './types'

const useContentModal = (props: IContentModalProps) => {
  const { content } = props

  const handleBgWhiteClick = (e: MouseEvent<HTMLDivElement>) => {
    // Stop the click event propagation to prevent onClose from being triggered
    e.stopPropagation()
  }

  return {
    handleBgWhiteClick,
    content,
  }
}

export default useContentModal
