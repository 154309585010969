import { ISeries } from '@src/types/api.interface'
import { FetchError } from '@utils/FetchError'
import useSwr from 'swr'

export function useAllSeries() {
  const url = '/series/all'

  const { data, error } = useSwr<ISeries[], FetchError>(url)

  return {
    data,
    error,
    isLoadingAllSeries: !error && !data,
  }
}
