import BreadCrumb from '@components/BreadCrumb'
import { ContentTitle } from '@components/ContentTitle'
import { AppBar } from '@components/Layout/AppBar'
import { AppContent } from '@components/Layout/AppContent'
import { useMobileView } from '@hooks/useMobileView'
import Card from '@components/Card'
import { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import Section from './Section'
import { SECTIONS, TEXT } from './data'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'

const CurriculumHome: FC = () => {
  const isMobileView = useMobileView()

  const { parentProfile } = useProfile()

  return (
    <>
      <Helmet>
        <title>{TEXT.WEBSITE_TITLE}</title>
      </Helmet>

      {isMobileView || !parentProfile ? (
        <AppBar
          fixed
          hideToggleButton
          showBackButton={true}
          showLogoutButton
          showParentBackButton
          title=""
        />
      ) : null}

      <AppContent
        className="bg-no-repeat bg-cover bg-center bg-v3 overflow-y-scroll h-screen hide-scroll pages_curriculimhome_appcontent"
        haveFooter={!isMobileView}
        haveHeader={!isMobileView}
        headerTitle={!isMobileView ? TEXT.TITLE : null}
      >
        {isMobileView || !parentProfile ? (
          <ContentTitle
            className="pages_curriculimhome_contentntitle"
            noShadow
            notBold
            textColor="text-white"
            title={TEXT.TITLE}
          />
        ) : (
          <BreadCrumb title={TEXT.TITLE} />
        )}

        <div className="flex container mx-auto justify-center pages_curriculimhome_container">
          <Card className="flex flex-col w-full mt-6 px-6 py-3 justify-center mb-9 pb-10 pages_curriculimhome_card">
            <div className="flex flex-col items-center text-center flex-1 pages_curriculimhome_typography">
              <h2 className="text-v3-blue-800 text-4xl pages_curriculimhome_intro_text">
                {TEXT.INTRO.TITLE}
              </h2>
              <p className="text-xl pt-1 px-7 md:px-10 lg:px-24 xl:px-52 pages_curriculimhome_para_text">
                {TEXT.INTRO.PARAGRAPH}
              </p>
            </div>

            {SECTIONS.map((section) => (
              <Section key={section.title} {...section} />
            ))}
          </Card>
        </div>
      </AppContent>
    </>
  )
}

export default CurriculumHome
