import React, { useEffect, FC, useState } from 'react'

import { Link, useLocation } from 'react-router-dom'
import { AppBar, AppContent } from '@components/Layout'
import { ContentTitle } from '@components/ContentTitle'

import Helper from '@utils/Helpers'
import { AppProgressBar } from '@components/Layout/ProgressBar/AppProgressBar'
import { IEpisodeListItem, ISeriesEpisodes } from '@src/types/api.interface'
import { Helmet } from 'react-helmet-async'
import Popup from '@components/Popup'
import { usePurchases } from '@hooks/use-purchases'
import {
  COMING_SOON_MESSAGE,
  HAVENT_FINISHED_VIDEOS,
  MESSAGE_UNCOMPLETED_EPISODE,
  moreThanThreeDays,
  REGISTER_NOW_MESSAGE,
  REQUIRES_REGISTRATION_MESSAGE,
  REQUIRES_SUBSCRIBE_MESSAGE,
  SUBSCRIBE_NOW_MESSAGE,
  THIS_CONTENT_MESSAGE,
  UNITES_LIST,
  UNLOCK_CONTENT_MESSAGE,
  UNLOCK_ONE_DAY,
  UNLOCK_TWO_DAYS,
} from '@src/constants/messages'
import { useEpisodeInSeries } from '@hooks/data/fetchers/use-episodes-in-series'
import { logEvent } from '@utils/firebase-analytics'
import ZidoPointer from '@components/zidoPendingUnit'
import { useProfile } from '@contexts/ProfileContext/ProfileContext'
import HelperProfile from '@utils/helpers/helper.profile'
import { LOCKED_ICON_NEW } from '@utils/constants'
import { useScheduleKids } from '@hooks/data/fetchers/use-schedule-kids'

import GoldPlayIcon from '@components/Icons/GoldPlayIcon'
import SmileLockIcon from '@components/Icons/SmileLockIcon'
import { useMobileView } from '@hooks/useMobileView'
import ScrollContainer from 'react-indiana-drag-scroll'
import { useCompletedEpisodes } from '@hooks/data/fetchers/use-completed-episodes'

interface LocationState {
  seriesData: ISeriesEpisodes
}

const Series: FC = () => {
  const location = useLocation<LocationState>()

  const { scheduleKid } = useScheduleKids()

  const [seriesData] = useState<ISeriesEpisodes>(
    location.state.seriesData ?? null
  )

  const { episodes, isLoadingEpisodes } = useEpisodeInSeries(seriesData._id)

  const [isVisable, setIsVisable] = useState(false)

  const [hasProAccess, setHasProAccess] = useState(false)

  const [loading, setLoading] = useState(true)

  const [isZidoPending, setIsZidoPending] = useState(false)

  const [daysRemaining, setDaysRemaining] = useState(0)

  const purchases = usePurchases()

  const { completedEpisodes = [] } = useCompletedEpisodes()

  const { profileType, kidProfile } = useProfile()

  const [newEpisode, setNewEpisode] = useState<
    Array<IEpisodeListItem & { isWatched?: boolean }>
  >([])

  const [isFreeEpisode, setIsFreeEpisode] = useState(false)

  const [isUncompleteEpisode, setIsUncompleteEpisode] = useState(false)

  const isAnon = HelperProfile.getProfileType() === 'anon'

  const selectedImage = Helper.MediaURL(seriesData?.image) || ''
  const style = {
    '--image-url': `url(${selectedImage ?? '/public/images/v3/bgoverlay.svg'})`,
  } as React.CSSProperties

  useEffect(() => {
    purchases
      .isHavingProAccess(kidProfile?.level === 1 ? 'level-1' : 'level-2')
      .then(setHasProAccess)
      .then(() => setLoading(false))
  }, [purchases, kidProfile?.level])

  useEffect(() => {
    logEvent('open_series')
  }, [])

  useEffect(() => {
    if (
      Array.isArray(episodes) &&
      Array.isArray(completedEpisodes) &&
      episodes?.length > 0
    ) {
      const data = episodes
        .sort((a, b) => a.order - b.order)
        .filter((a) => !a?.isAnon)
        .map((oneEpisode) => {
          const isWatched = completedEpisodes.includes(
            oneEpisode._id.toString()
          )
          return {
            ...oneEpisode,
            isWatched,
          }
        })
      setNewEpisode(data)
    }
  }, [episodes, completedEpisodes])

  useEffect(() => {
    const days = scheduleKid?.activeSchedule?.period - scheduleKid?.daysPeriod
    if (
      newEpisode.length > 0 &&
      scheduleKid?.locked &&
      newEpisode.some(({ isWatched }) => !isWatched)
    ) {
      setDaysRemaining(days)
      setIsZidoPending(true)
    } else {
      setIsZidoPending(false)
    }
  }, [scheduleKid, newEpisode])

  const onEpisodeClick = (value: boolean, isWatched?: boolean) => {
    if (!scheduleKid?.locked && !isWatched) setIsUncompleteEpisode(true)
    if (!scheduleKid?.activeSchedule?.enabled && !isWatched) {
      setIsUncompleteEpisode(true)
    }
    setIsFreeEpisode(value)
    setIsVisable(true)
  }

  const isMobileView = useMobileView()

  if (profileType !== 'child') return
  return (
    <>
      {/* black overlay */}
      <div className="fixed inset-0 bg-black opacity-20" />

      <Helmet>
        <title>عالم زيدو - الصفحة الرئيسية من المستوى 1</title>
      </Helmet>
      {loading || isLoadingEpisodes ? <AppProgressBar /> : null}
      <AppBar
        backButtonLocation="/series-home"
        fixed
        hideToggleButton
        showBackButton={true}
        showKidAvatar
        showParentBackButton
        title={seriesData.title}
        titleCenter
        withBgTheatre
      />
      <AppContent
        className="bg-cover bg-center bg-no-repeat bg-dimming-overlay-[image:var(--image-url)]"
        style={style}
      >
        <ContentTitle
          fixedPosition
          noShadow
          notBold
          textColor="text-white"
          title={isMobileView ? seriesData.title : 'منهج زيدو'}
        />

        {/* Series Info */}
        <div className="relative z-10 min-h-[87vh] flex flex-col justify-end mr-6 lg:mr-8 text-white">
          {!loading && episodes?.length > 0 ? (
            <div className="flex flex-col gap-y-2">
              {isMobileView ? (
                <span className="text-xl mb-3">
                  {seriesData.episodes.filter((e) => !e.isAnon).length} وحدات
                </span>
              ) : (
                <>
                  <img
                    alt=""
                    className="w-52 h-40 "
                    src={seriesData.thumbnail}
                  />
                  <p className="text-xl w-1/3">
                    {UNITES_LIST[seriesData.order]}
                  </p>
                  <span className="text-5xl w-1/3">{seriesData.title}</span>
                  <span className="text-xl w-1/3">
                    {seriesData.episodes.filter((e) => !e.isAnon).length} وحدات
                  </span>
                  <span className="text-xl mb-3 max-w-4xl w-1/3">
                    {seriesData.description}
                  </span>
                </>
              )}
            </div>
          ) : null}

          {!loading ? (
            episodes?.length > 0 ? (
              <Episodes
                data={newEpisode}
                hasProAccess={hasProAccess}
                isPending={
                  scheduleKid?.activeSchedule?.enabled ? isZidoPending : false
                }
                onEpisodeClick={onEpisodeClick}
              />
            ) : episodes?.length === 0 ? (
              <div className="h-[82vh] lg:h-[86vh] w-full flex items-center justify-center">
                <span className="text-xl md:text-2xl lg:text-5xl">
                  {COMING_SOON_MESSAGE}
                </span>
              </div>
            ) : (
              <></>
            )
          ) : null}
        </div>
      </AppContent>
      {isZidoPending && scheduleKid && scheduleKid?.activeSchedule?.enabled ? (
        <ZidoPointer />
      ) : null}
      {isAnon ? (
        <Popup
          acceptLink="/login"
          buttonText={REGISTER_NOW_MESSAGE}
          message={REQUIRES_REGISTRATION_MESSAGE}
          onClose={() => setIsVisable(false)}
          title=""
          visible={isVisable}
        />
      ) : (
        <CustomPopup
          days={daysRemaining}
          hasProAccess={hasProAccess}
          isFreeEpisode={isFreeEpisode}
          onPopupClose={() => {
            setIsVisable(false)
            setIsFreeEpisode(false)
            setIsUncompleteEpisode(false)
          }}
          pending={scheduleKid?.activeSchedule?.enabled ? isZidoPending : false}
          uncomplete={isUncompleteEpisode}
          visible={isVisable}
        />
      )}
    </>
  )
}

export default Series

const Episodes: FC<{
  data: Array<IEpisodeListItem & { isWatched?: boolean }>
  hasProAccess: boolean
  onEpisodeClick: (value: boolean, isWatched?: boolean) => void
  isPending?: boolean
}> = (props) => {
  return (
    <ScrollContainer className="flex overflow-scroll hide-scroll gap-x-3 lg:gap-x-6 pages_series_scroller_container">
      {props.data.map(function (episode, idx, arr) {
        let locked = true
        if (HelperProfile.getProfileType() === 'anon') {
          locked = !episode.isAnon
        } else if (props.hasProAccess || episode.isFree) {
          if (idx === 0) {
            locked = false
          } else {
            if (Array.isArray(arr) && arr[idx - 1]?.isWatched) {
              locked = episode.isWatched ? false : props.isPending
            } else {
              locked = true
            }
          }
        } else {
          locked = true
        }
        return (
          <div
            className="animate-fadein flex last:ml-3 pages_series_scroller_thumb_container"
            key={episode?._id}
          >
            <EpisodeThumbnail
              data={episode}
              index={idx}
              locked={locked}
              onEpisodeClick={() =>
                props.onEpisodeClick(episode?.isFree, arr[idx - 1]?.isWatched)
              }
            />
          </div>
        )
      })}
    </ScrollContainer>
  )
}

const EpisodeThumbnail: FC<{
  data: IEpisodeListItem
  locked?: boolean
  onEpisodeClick: () => void
  index: number
}> = (props) => {
  const url =
    '/episode/' +
    props.data.slug +
    '/chapters?key=' +
    (Number(props.index || 0) + 1)
  const locked = props.locked

  return (
    <div className="flex flex-col w-[193px] pages_series_scroller_thumb_episode_container">
      <div
        className={`relative text-center overflow-hidden rounded-2xl ${
          !props.data.thumbnail && 'bg-black'
        }`}
      >
        {locked ? (
          <LockedVideoOverlay newIcon setIsVisable={props.onEpisodeClick} />
        ) : null}
        <Link
          className="pages_series_scroller_thumb_episode_link_container"
          to={locked ? '' : url}
        >
          <img
            alt={props.data.slug}
            className=" aspect-square pages_series_scroller_episode_link_img"
            draggable="false"
            src={Helper.MediaURL(props.data.thumbnail)}
          />

          <div className="w-full px-2 justify-between flex absolute bottom-2 pages_series_scroller_episode_link_title_container">
            <span className=" w-max-[50%]  border rounded-2xl bg-[#2E3531] text-white text-base truncate px-1 text-center flex items-center">
              {props.data.title}
            </span>
            <GoldPlayIcon />
          </div>
        </Link>
      </div>
    </div>
  )
}

const LockedVideoOverlay: FC<{
  setIsVisable: (isVisible: boolean) => void
  newIcon?: boolean
}> = (props) => {
  return (
    <div
      className="cursor-pointer z-10 pages_series_scroller_episode_locked_vid_overlay absolute w-full h-full bg-black  rounded-2xl bg-opacity-40 flex items-center justify-center"
      onClick={() => props.setIsVisable(true)}
    >
      {props.newIcon ? (
        <SmileLockIcon />
      ) : (
        <img
          alt=""
          className="w-1/2 h-1/2 max-w-max max-h-max pages_series_scroller_locked_vid_img"
          draggable="false"
          src={LOCKED_ICON_NEW}
        />
      )}
    </div>
  )
}

const CustomPopup: FC<{
  days: number
  pending: boolean
  hasProAccess: boolean
  isFreeEpisode: boolean
  onPopupClose: () => void
  visible: boolean
  uncomplete?: boolean
}> = (props) => {
  let title = ''
  let message = ''
  let buttonText = ''
  let acceptLink = ''

  // TODO: Revisit this implementation.
  if (!props.hasProAccess && !props.isFreeEpisode) {
    title = THIS_CONTENT_MESSAGE
    message = REQUIRES_SUBSCRIBE_MESSAGE
    buttonText = SUBSCRIBE_NOW_MESSAGE
    acceptLink = '/parent?redirect=subscribe'
  } else if (props.pending) {
    title =
      props.days === 1
        ? UNLOCK_ONE_DAY
        : props.days === 2
        ? UNLOCK_TWO_DAYS
        : moreThanThreeDays(props.days)
    message = UNLOCK_CONTENT_MESSAGE
  } else if (props.uncomplete) {
    title = THIS_CONTENT_MESSAGE
    message = MESSAGE_UNCOMPLETED_EPISODE
  } else {
    title =
      props.hasProAccess || props.isFreeEpisode ? '' : THIS_CONTENT_MESSAGE
    message =
      props.hasProAccess || props.isFreeEpisode
        ? HAVENT_FINISHED_VIDEOS
        : REQUIRES_SUBSCRIBE_MESSAGE

    buttonText =
      !props.hasProAccess && !props.isFreeEpisode && SUBSCRIBE_NOW_MESSAGE
    acceptLink =
      !props.hasProAccess &&
      !props.isFreeEpisode &&
      '/parent?redirect=subscribe'
  }

  return (
    <Popup
      acceptLink={acceptLink}
      buttonText={buttonText}
      message={message}
      onClose={props.onPopupClose}
      title={title}
      visible={props.visible}
    />
  )
}
