import Config from '@config/index'
import {
  COMPETITION_TITLE,
  CURRICULUM_TITLE,
  MAQRAA_TITLE,
} from '@src/constants'
import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

interface FeatureCardProps {
  reference: string
}

const getFeatureInfo = (reference: string) => {
  let classes = ''
  const commonTextClasses =
    'text-[11px] sm:text-base lg:text-2xl bottom-4 lg:bottom-10'
  let textClasses = ''
  let title = ''

  switch (reference) {
    case 'maqraa':
      classes = 'order-3 '
      textClasses = `${commonTextClasses} text-v3-green-900`
      title = MAQRAA_TITLE
      break
    case 'competition':
      classes = 'order-1'
      textClasses = `${commonTextClasses} text-v3-orange-400`
      title = COMPETITION_TITLE
      break
    case 'curriculum':
      classes = 'order-2'
      textClasses =
        'lg:leading-[30px] bottom-4  lg:bottom-14 text-[24px] lg:text-[35px]  text-v3-blue-300'
      title = CURRICULUM_TITLE
      break
    default:
      break
  }

  return { classes, textClasses, title }
}

const FeatureCard = ({ reference }: FeatureCardProps) => {
  const { classes, textClasses, title } = getFeatureInfo(reference)
  const imgSrc = `${Config.publicURL}/images/v3/${reference}.svg`
  const history = useHistory()
  const [imageLoaded, setImageLoaded] = useState(false)

  const openMaqraa =
    (reference: string) =>
    (e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (reference === 'maqraa') {
        e.preventDefault()
        const maqraaLink = process.env.REACT_APP_MAQRAA_URL || null
        if (maqraaLink) {
          history.push('/parent', {
            type: 'openBrowser',
            link: maqraaLink,
          })
        }
      }
    }

  return (
    <Link
      className={`relative ${classes} component_feature_card_link_container`}
      onClick={openMaqraa(reference)}
      to={`/${reference === 'curriculum' ? 'series-home' : reference}`}
    >
      <img
        alt={reference}
        className=" w-full h-full component_feature_card_link_img"
        onLoad={() => setImageLoaded(true)}
        src={imgSrc}
      />

      {imageLoaded ? (
        <p
          className={`absolute left-0 right-0 text-center component_feature_card_link_p ${textClasses}`}
        >
          {title}
        </p>
      ) : null}
    </Link>
  )
}

export default FeatureCard
