import React from 'react'
import { JSX } from 'react/jsx-runtime'

const VideoIcon = (
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
) => {
  return (
    <svg
      className="video_icon-svg"
      height="9"
      viewBox="0 0 13 9"
      width="13"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        clipRule="evenodd"
        d="M1.2.835h10.797c.32 0 .578.237.578.53V8.42c0 .292-.259.53-.578.53H1.2c-.32 0-.579-.238-.579-.53V1.364c0-.292.26-.529.579-.529zm.81 1.456c1.24.232 2.84.372 4.588.372 1.748 0 3.348-.14 4.588-.372.12-.023.232.06.232.172v4.152c0 .112-.111.195-.232.172-1.24-.232-2.84-.372-4.588-.372-1.747 0-3.348.14-4.588.372-.12.023-.232-.06-.232-.172V2.463c0-.112.112-.195.232-.172zm.581 5.437a.565.565 0 01.537-.353c.312 0 .566.233.566.518 0 .286-.254.518-.566.518a.565.565 0 01-.537-.353h-.813c-.1 0-.18-.074-.18-.165 0-.09.08-.165.18-.165h.813zm.331.165c0 .104.092.188.206.188.113 0 .205-.084.205-.188 0-.103-.092-.187-.205-.187-.114 0-.206.084-.206.187zm1.362-.165h7.134c.1 0 .18.074.18.165 0 .09-.08.165-.18.165H4.284c-.1 0-.18-.074-.18-.165 0-.091.08-.165.18-.165zm1.193-2.246v-1.4c0-.109.12-.184.231-.143l1.905.7a.15.15 0 010 .287l-1.905.7c-.11.04-.23-.034-.23-.144z"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default VideoIcon
