import { useMemo } from 'react'
import useSWR, { KeyedMutator } from 'swr'
import { IRank } from '@src/types/api.interface'
import { FetchError } from '@utils/FetchError'

export function useRanks(challengeName: string | undefined): {
  ranks?: IRank[]
  error?: FetchError
  isLoadingRanks: boolean
  mutate: KeyedMutator<IRank[]>
} {
  const url = challengeName ? `/competitions/${challengeName}/scores` : null

  const { data, error, mutate } = useSWR<IRank[], FetchError>(url)

  return useMemo(
    () => ({
      ranks: data,
      error,
      isLoadingRanks: !!url && !data && !error,
      mutate,
    }),
    [data, error, mutate, url]
  )
}
