import React, { FC, ReactElement, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { LocationDescriptor } from 'history'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

interface ModalButtonProps {
  goTo?: LocationDescriptor
  icon?: ReactElement
  text: string
  onClick?: () => void
  className?: string
  textColor?: string
  bgColor?: string
  type?: 'button' | 'submit'
  formId?: string
  disabled?: boolean
}

const Modal: FC<{
  isOpen: boolean
  close: () => void
  icon?: string | ReactElement
  title?: string | ReactElement
  message?: string | ReactElement
  button?: ModalButtonProps
  children?: React.ReactNode | string
}> = (props) => {
  useEffect(() => {
    if (props.isOpen) {
      document.body?.classList.add('modal-open')
      document.body.style.top = `-${window.scrollY}px`
    } else {
      // When the modal is hidden, we want to remain at the top of the scroll position
      document.body?.classList.remove('modal-open')
      const scrollY = document.body.style.top
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }

    return () => {
      document.body?.classList.remove('modal-open')
      const scrollY = document.body.style.top
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [props.isOpen])

  return (
    <div
      className={`z-[100] overflow-auto px-6 fixed top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center modal_container ${
        props.isOpen ? '' : 'translate-y-[2000px] opacity-0 pointer-events-none'
      } transition-all`}
    >
      <div className="container max-w-2xl overflow-visible relative modal_container-cls-btn-wrpr">
        <button
          className="bg-[#f6590e] text-white font-bold text-4xl rounded-full w-16 h-16 absolute -top-4 -left-4 shadow-modal-close modal_container-cls-btn"
          onClick={props.close}
          type="button"
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <div className="bg-white rounded-3xl px-4 sm:px-8 py-4 sm:py-4 overflow-auto text-[#4a4a4a] shadow-[#212121] shadow-2xl flex flex-col items-center modal_container-header-wrpr">
          <header className="flex items-center justify-center mb-2 modal_container-header">
            <h2 className="text-[#d2d832] text-2xl m-0 flex flex-col items-center modal_container-h2">
              {props.icon ? (
                <span className="text-5xl modal_container-icon-wrpr">
                  {typeof props.icon === 'string' ? (
                    <i
                      aria-hidden="true"
                      className={`${props.icon} modal_container-${props.icon}`}
                    />
                  ) : (
                    props.icon
                  )}
                </span>
              ) : null}
              {props.title ? (
                <div className="modal_container-title">{props.title}</div>
              ) : (
                ''
              )}
            </h2>
          </header>
          {props.message ? (
            <section className="text-xl mb-2 modal_container-message">
              {props.message}
            </section>
          ) : null}
          {props.children}
          <footer className="modal_container-footer">
            {props.button ? <ModalButton {...props.button} /> : null}
          </footer>
        </div>
      </div>
    </div>
  )
}

export default Modal

const ModalButton: FC<ModalButtonProps> = (props) => {
  const bgColor = props.bgColor ?? 'bg-[#32c8e8]'
  const textColor = props.textColor ?? 'text-white'

  if (props.goTo) {
    return (
      <Link className="modal_container-btn-link" to={props.goTo}>
        <button
          className={`${bgColor} ${textColor} font-bold text-xl px-6 pt-2 pb-4 flex flex-col items-center justify-center rounded-3xl modal_container-mdl-btn ${
            props.className ?? ''
          }`}
          onClick={props.onClick}
          type="button"
        >
          {props.icon != null ? (
            <span className="text-3xl block modal_container-span-icon">
              {props.icon}
            </span>
          ) : null}
          <span className="modal_container-txt">{props.text}</span>
        </button>
      </Link>
    )
  }

  return (
    <button
      className={`${bgColor} ${textColor} font-bold text-xl px-6 pt-2 pb-4 flex flex-col items-center justify-center rounded-3xl modal_container-dsbld-btn ${
        props.className ?? ''
      } disabled:bg-gray-200 disabled:text-gray-400 disabled:shadow-none`}
      disabled={props.disabled}
      form={props.formId}
      onClick={props.onClick}
      // eslint-disable-next-line react/button-has-type
      type={props?.type ?? 'button'}
    >
      {props.icon ? (
        <span className="text-3xl block modal_container-dsbld-icon">
          {props.icon}
        </span>
      ) : null}
      <span className="modal_container-dsbld-txt">{props.text}</span>
    </button>
  )
}
