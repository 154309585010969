import { IChallengeNew } from '@src/types/api.interface'
import { useEffect, useState } from 'react'
import { boyAvatar, girlAvatar, pointIcon, starCircle } from '../TopCompetitor'
import { TYPOGRAPHY } from '../constants'
import { LeaderBoardComponent } from './LeaderBoardComponent'

const { POINT_TEXT } = TYPOGRAPHY
interface LeaderBoardProps {
  kids: IChallengeNew[]
  kidId: string
}

export const LeaderBoard: React.FC<LeaderBoardProps> = ({ kids, kidId }) => {
  const [firstFiveRankedKid, setFirstFiveRankedKid] = useState<IChallengeNew[]>(
    []
  )

  useEffect(() => {
    if (kids?.length) {
      setFirstFiveRankedKid(kids.slice(0, 5))
    }
  }, [kids])

  return (
    <div className="leader_board_item-container">
      <div className="leader_board_item-wrapper w-[378px] xl:min-h-[526px] mx-auto xl:w-[640px] mb-6 lg:mb-0 py-8 h-auto rounded-[32px] border-8 border-[#ffffff57] bg-[#ffffff2e]	flex justify-center flex-col items-center gap-y-[34px] xl:gap-y-0">
        {firstFiveRankedKid?.length ? (
          <div
            className={`leader_board_item-container-${firstFiveRankedKid[0]?.id} flex xl:flex-col xl:mb-[17px] gap-x-8 items-center`}
          >
            <div
              className={
                kidId === firstFiveRankedKid[0]?.id
                  ? 'bg-v3-green rounded-xl p-4'
                  : ''
              }
            >
              <div
                className={`leader_board_item-wrapper-${firstFiveRankedKid[0]?.id} bg-v3-orange-200 relative  w-[83px] h-[83px] xl:w-[115px] xl:h-[121px] rounded-full mx-auto`}
              >
                <div
                  className={`leader_board_item-img-wrapper-${firstFiveRankedKid[0]?.id} bg-v3-brown-100 absolute -top-1.5 w-[83px] h-[83px] xl:w-[115px] xl:h-[121px]  rounded-full flex items-center justify-center`}
                >
                  <img
                    alt="boy avatar"
                    className={`leader_board_item-img-${firstFiveRankedKid[0]?.id} w-[54px] h-[64px] xl:w-[76px] xl:h-[90px]`}
                    src={
                      firstFiveRankedKid[0]?.gender === 'male'
                        ? boyAvatar
                        : girlAvatar
                    }
                  />
                </div>
                <img
                  alt="Star with circle"
                  className={`leader_board_item-star-circle-${firstFiveRankedKid[0]?.id} h-[30px] w-[30px] xl:w-[43px] xl:h-[43px] absolute bottom-0 left-0`}
                  src={starCircle}
                />
                <span
                  className={`leader_board_item-rank-${firstFiveRankedKid[0]?.id} bg-v3-orange-600 border border-white h-[38px] w-[38px] xl:h-[43px] xl:w-[43px] text-white text-[41px] absolute -top-[5px] -right-4 drop-shadow-[0px_4px_4px_rgba(0,0,0,0.25)] rounded-full flex items-center justify-center`}
                >
                  {firstFiveRankedKid[0]?.rank}
                </span>
              </div>
              <div
                className={`leader_board_item-nickname-wrapper-${firstFiveRankedKid[0]?.id} flex flex-col gap-y-2`}
              >
                <p
                  className={`leader_board_item-nickname-${firstFiveRankedKid[0]?.id} text-white text-center text-3xl`}
                >
                  {firstFiveRankedKid[0]?.nickname}
                </p>
                <div
                  className={`leader_board_item-score-container-${firstFiveRankedKid[0]?.id} border-[3px] px-6 lg:py-px flex items-center justify-end gap-x-2 border-yellow-100 w-[160px] xl:w-[187px] mx-auto bg-v3-green-200 rounded-[25px]`}
                >
                  <div
                    className={`leader_board_item-score-wrapper-${firstFiveRankedKid[0]?.id} flex flex-col xl:flex-row xl:gap-x-2 xl:items-baseline`}
                  >
                    <p
                      className={`leader_board_item-sore-${firstFiveRankedKid[0]?.id} text-center text-v3-blue-500 text-[58px] leading-[30px] xl:text-5xl font-light`}
                    >
                      {firstFiveRankedKid[0]?.score}
                    </p>
                    <p
                      className={`leader_board_item-point-text-${firstFiveRankedKid[0]?.id} text-center text-v3-blue-500 text-lg leading-[21px] xl:leading-5 font-light`}
                    >
                      {POINT_TEXT}
                    </p>
                  </div>
                  <img
                    alt="point icon"
                    className={`leader_board_item-point-icon-${firstFiveRankedKid[0]?.id} w-[17px] h-[19px] xl:w-[37px] xl:h-[40px]`}
                    src={pointIcon}
                  />
                </div>
              </div>
            </div>
            {firstFiveRankedKid?.length > 1 ? (
              <div className="leader_board_item-divider hidden xl:block my-9 border border-dashed w-[580px] border-v3-gray-450" />
            ) : null}
          </div>
        ) : null}

        <div className="leader_board_item-kids-wrapper flex flex-row gap-x-8 xl:gap-x-10 items-center">
          {firstFiveRankedKid?.length
            ? firstFiveRankedKid
                ?.slice(1)
                ?.map((kid, index) => (
                  <LeaderBoardComponent
                    index={index}
                    isHighlighted={kid.id === kidId}
                    key={index}
                    kid={kid}
                  />
                ))
            : ''}
        </div>
      </div>
    </div>
  )
}
